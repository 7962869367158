import { Paper } from '@mui/material';
import styled from 'styled-components';

export const PaperStyled = styled(Paper)`
    font-family: Poppins !important;
    font-weight: 500 !important;
    background: white !important;
    color: #5E6278 !important;
    font-size: ${props => props.fontSize === 13 ? '14px !important' : `${props.fontSize}px !important`};
    li {
        padding-left: ${props => props.paddingsmall ? '5px !important' : ''};
        font-size: ${props => props.fontSize === 13 ? '14px !important' : `${props.fontSize}px !important`}
    };
    @media (max-width: 1200px) {
        li {
            ${props => props.paddingsmall ? '10px !important' : ''};
            font-size: ${props => props.fontSize === 13 ? '14px !important' : `${props.fontSize}px !important`}
        };
    }
`

export const NoOptionsText = styled.div`
    font-weight: 500;
    color: #a1a5b6
`

export const InputStyled = styled.input`
    padding-left: ${props => !props.noPadding ? '35px' : ''};
    padding-right: ${props => !props.noPadding ? '5px' : ''};
    height: ${props => props.fontSize === 9 ? '30px !important' : props.fontSize === 11 ? '40px !important' : 'var(--input-height) !important'};
`