export const deleteSpacesInKeys = (object) => {
    Object.keys(object).forEach(function(key) {
        var newKey = key.replace(/\s+/g, '');
        if (object[key] && typeof object[key] === 'object') {
            deleteSpacesInKeys(object[key]);
        }
        if (key !== newKey) {
            object[newKey] = object[key];
            delete object[key];
        }
    });
}