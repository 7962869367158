import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const BOLRender = ({rowData, id}) => {
    const BOLS = useSelector<RootState>(({pages}) => pages.BOLS, shallowEqual) as any

    return (
        <div>{rowData.BOL ? rowData.BOL : BOLS.find(BOL => BOL.id === id)?.BOL}</div>
    )
}

export default BOLRender