import React, {useEffect} from 'react'
import _ from 'lodash' 
import { FreightTotalsProps } from '../../../pages/ltl/models';
import { getFreightInfoTotals } from '../../../helpers/getFreightInfoTotals';

export const FreightTotals = ({freightInfoTotals, freightInfo, setDataFormik, freightInfoUOM, setData, forViewShipment, forDrayage}: FreightTotalsProps) => {

    useEffect(() => {
        if(forViewShipment){
            setData(getFreightInfoTotals(freightInfoTotals, freightInfo, freightInfoUOM))
        }else{
            setDataFormik('freightInfoTotals', getFreightInfoTotals(freightInfoTotals, freightInfo, freightInfoUOM))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freightInfo])

    return (
        <div className={`mb-5 ${forViewShipment ? 'col-12 col-md-11' : !forDrayage ? 'col-md-12 col-xl-7' : 'col-12 col-md-4'} p-3`} style={{backgroundColor: '#E4E6EF', borderRadius: '0.475rem'}}>
            <div className="row align-items-center p-3">
                <div className={`${forDrayage ? 'col-6' : 'col-2'} d-md-flex text-center`}>
                    <div className="me-sm-3">Pieces</div>
                    <div className="text-danger">{freightInfoTotals?.totalPieces}</div>
                </div>
                <div className={`${forDrayage ? 'col-6' : 'col-4'} d-md-flex text-center`}>
                    <div className="me-sm-3">Total Weight</div>
                    <div className="text-danger">{_.round(freightInfoTotals?.totalWeight)}{freightInfoTotals?.symbol}</div>
                </div>
                {!forDrayage ? 
                    <>
                        <div className="col-3 d-md-flex text-center">
                            <div className="me-sm-3">CFT</div>
                            <div className="text-danger">{_.ceil(freightInfoTotals?.totalCFT || '', 2)}</div>
                        </div>
                        <div className="col-3 d-md-flex text-center">
                            <div className="me-sm-3">Density</div>
                            <div className="text-danger">{freightInfoTotals?.totalDensity}</div>
                        </div>
                    </>
                    : null
                }   
                
            </div>
        </div>
    )
}
