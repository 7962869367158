import { IconButton } from "@mui/material";
import styled from "styled-components";
import ClearIcon from '@mui/icons-material/Clear';

export const IconButtonStyled = styled(IconButton)`
    &.Mui-disabled {
        background-color: ${props => (props?.alldisabled === 'false' || props?.disabled) ? (props?.disabled && props?.alldisabled === 'true') ? '#969799' : 'white !important' : '' };
    };
    &.MuiButtonBase-root {
        height: 27.5px;
        width: 27.5px;
    }
`

export const IconButtonClearStyled = styled(IconButton)`
    &.MuiIconButton-root {
        padding: 4px !important
    }
`

export const ClearIconStyled = styled(ClearIcon)`
    &.MuiSvgIcon-root {
        color: rgb(161, 165, 183) !important
    }
`