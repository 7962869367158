import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

export const initialValues = {
  BOLId: '',
  vendorId: '',
  vendorSCAC: '',
  dispatched: false,
  quoteNumber: '', 
  BOLNumber: '',
  PRONmbr: '',
  billTo: {
    referenceNumber: '',
    emails: ''
  },
  shipper: {
    id: '',
    address: '',
    address1: '',
    country: 'US',
    city: '',
    name: '',
    state: '',
    zipcode: '',
    phone: '',
    emails: [],
    contact: '',
    contactPhone: '',
    referenceNumber: '',
    UNLOCode: {}
  },
  consignee: {
    id: '',
    address: '',
    address1: '',
    country: 'US',
    city: '',
    name: '',
    state: '',
    zipcode: '',
    phone: '',
    emails: [],
    contact: '',
    contactPhone: '',
    referenceNumber: '',
    UNLOCode: {}
  },
  freightInfo: [{
      id: uuidv4(),
      qty: '',
      dimType: {
        name: "PALLET",
        value: "PLT"
      },
      STC: '',
      weight: '',
      each: 'Each',
      L: '',
      W: '',
      H: '',
      class: '',
      commodity: '',
      nmfc: '',
      weightus: '',
      Lus: '',
      Wus: '',
      Hus: '',
      totalCft: '',
      hazmat: false,
      UN: '',
      UNPKGGroup: ''
  }],
  freightInfoUOM: {
      value: 'US',
      oldValue: ''
  },
  freightInfoTotals: {
      totalPieces: 0,
      totalWeight: 0,
      totalCFT: 0,
      totalDensity: 0,
      symbol: 'lbs'
  },
  accessorials: {
      origin: [],
      destination: [],
      other: []
  },
  pickupDate: {
      type: 'PickupOn',
      date: null,
      from: null,
      to: null
  },
  deliveryDate: {
      type: 'DeliveryBy',
      date: null,
      from: null,
      to: null
  },
  specialInstructions: '',
  shipmentNotes: '',
  otherInfo: {
    broker: {
      name: '',
      contact: '',
      phone: '',
      country: 'US',
      instructions: '',
    },
    BKGRefNumber: '',
    BKGValue: '',
    refNumber1: '',
    refNumber2: '',
    incoTerm: '',
  },
  carrierInformation: {
    name: '',
    rateId: '',
    transitDays: '',
    vendorId: null
  },
  emergencyContact: '',
  emergencyPhone: '',
  commodityInsurance: '',
  insuranceAmount: null
}
  
  const validatePhoneNumberFormik = (value) => {
    if(value === null){
      return true
    }else if(typeof value === 'string'){
      if(isPossiblePhoneNumber(value)){
          return true
      }else{
          return false
      }
    }else{
      return true
    }
  }

  Yup.addMethod<Yup.DateSchema>(Yup.date, "lessThanToday", function (message) {
    return this.test("test-name", message, function(value: any) {
      const { path, createError } = this
      return ( value < new Date().setHours(0,0,0,0) ? createError({message, path}) : value )
    })
  })

  Yup.addMethod<Yup.StringSchema>(Yup.string, "moreThanZero", function (message) {
    return this.test("test-name", message, function(value: any) {
      const { path, createError } = this
      return( typeof value === 'string' ? (parseInt(value) ? value : createError({message, path})) : value > 0 ? value : createError({message, path}))
    })
  })


  export const validationSchema = (values) => {
    return (
      Yup.object().shape({
        shipper: Yup.object({
          name: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
          address: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
          contact: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
          city: Yup.string().required('Required'),
          country: Yup.string().required('Required'),
          state: Yup.string().required('Required'),
          zipcode: Yup.string().required('Required'),
          isSL: Yup.boolean(),
          isZipcode: Yup.boolean(),
          phone: values.dispatchShipment && !values.shipper?.contactPhone && Yup.string().required("Required").test("validPhoneNumber", "Invalid phone", (value) => (
            validatePhoneNumberFormik(value)
          )),
          contactPhone: values.dispatchShipment && !values.shipper?.phone && Yup.string().required("Required").test("validPhoneNumber", "Invalid phone", (value) => (
            validatePhoneNumberFormik(value)
          ))
        }),
        consignee: Yup.object({
          name: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
          address: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
          contact: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
          city: Yup.string().required('Required'),
          country: Yup.string().required('Required'),
          state: Yup.string().required('Required'),
          zipcode: Yup.string().required('Required'),
          isSL: Yup.boolean(),
          isZipcode: Yup.boolean(),
          phone: values.dispatchShipment && !values.consignee?.contactPhone && Yup.string().required("Required").test("validPhoneNumber", "Invalid phone", (value) => (
            validatePhoneNumberFormik(value)
          )), 
          contactPhone: values.dispatchShipment && !values.consignee?.phone && Yup.string().required("Required").test("validPhoneNumber", "Invalid phone", (value) => (
            validatePhoneNumberFormik(value)
          ))
        }),
        freightInfo: Yup.array().of(
          Yup.object().shape(values.mode.code !== 'DRAY' ? 
          values.mode.code !== 'VOL' ? {
            commodity: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
            L: values?.requireDimensions ? (Yup.string() as any).moreThanZero('Required').required() : Yup.string(),
            W: values?.requireDimensions ? (Yup.string() as any).moreThanZero('Required').required() : Yup.string(),
            H: values?.requireDimensions ? (Yup.string() as any).moreThanZero('Required').required() : Yup.string(),
            qty: Yup.string().required("Required"),
            weight: Yup.string().required("Required"),
            nmfc: Yup.string().test('length', 'Must be 8 numbers or empty', (value) => {
              if(value && /\s/g.test(value)){
                return false
              }else{
                return true
              }
            }),
          }
          : {
            commodity: values.dispatchShipment ? Yup.string().required("Required") : Yup.string(),
            L: Yup.string(),
            W: Yup.string(),
            H: Yup.string(),
            qty: Yup.string().required("Required"),
            weight: Yup.string().required("Required"),
            nmfc: Yup.string().test('length', 'Must be 8 numbers or empty', (value) => {
              if(value && /\s/g.test(value)){
                return false
              }else{
                return true
              }
            }),
          } : {
            qty: Yup.string().required("Required"),
            weight: Yup.string().required("Required")
          })
        ),
        pickupDate: values.dispatchShipment ? Yup.object().shape({
          date: Yup.date().required("Required"),
          from: Yup.string().required("Required").matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm),
          to: Yup.string().required("Required").matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm)
        }) : Yup.object().shape({}),
        deliveryDate: values.dispatchShipment ? Yup.object().shape({
            date: Yup.date().required("Required"),
        }) : Yup.object().shape({}),
      })
    )
  }