import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import QuoteBreakdownModal from '../../components/LTLandVolumeTLPages/Modals/QuoteBreakdownModal';
import HistorySelect from '../../components/QuotesGridPage/History/HistorySelect';
import Table from '../../components/QuotesGridPage/Table/Table'
import Toolbar from '../../components/QuotesGridPage/Table/Toolbar';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { dollarUSLocale } from '../../helpers/dollarUSLocale';
import { numberWithCommas } from '../../helpers/numberWithCommas';
import { isTokenExp } from '../../helpers/isTokenExp';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../../setup';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';

const GET_QUOTES = '/api/v1/database/quote'
let cancelToken

export const QuotesGrid = () => {
  const dispatch = useDispatch()
  let {setHistoryElement} = usePageData()
  const [quotes, setQuotes] = useState([])
  const [filters, setFilters] = useState({
    search: '',
    from: null,
    to: null,
    type: ['ALL'],
    bookedOnly: false
  })
  const [loading, setLoading] = useState(false)
  const [openQuoteBreakdownModal, setOpenQuoteBreakdownModal] = useState(false)
  const [quoteBreakdownModalData, setQuoteBreakdownModalData] = useState({})
  const [filtering, setFiltering] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [totalResults, setTotalResults] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const resetValues = useSelector<RootState>(({pages}) => pages?.resetValues) as boolean

  const totalRender = (rowData) => {
    const callQuoteData = () => {
      setLoading(true)
      axios.get(`${GET_QUOTES}/${rowData.quoteId}`).then((results) => {
        let result = results.data.data.results

        result.name = result.vendor.name
        result.serviceLevel = result.vendor.serviceLevel
        result.rateBreakdown = result.totalBreakdown.map(totalBreakdown => {
          return ({
            total: totalBreakdown.total,
            name: totalBreakdown.description
          })
        })
        result.iconUrl = rowData.vendor.iconUrl

        setQuoteBreakdownModalData(result)
        setLoading(false)
        setOpenQuoteBreakdownModal(true)
      }).catch(error => {
        setLoading(false)
        checkErrorAPICall(error, dispatch, 'Quotes')
      })
    }

    return (
      <button
        style={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            textDecoration: 'underline'
        }}
        onClick={() => {
          callQuoteData()
        }}
      >
        {dollarUSLocale.format(rowData.total)}
      </button>
    )
  }

  const weightRender = (rowData) => {
    return (
      <div>
        {numberWithCommas(rowData.totalWeight)}
      </div>
    )
  }

  const [columns, setColumns] = useState([
    { title: 'Quote#', field: 'quoteNumber', hidden: false},
    { title: 'Date', field: 'date', type: 'date', dateSetting: { locale: "en-US" }, hidden: false},
    { title: 'Type', field: 'rateType', hidden: false},
    { title: 'Origin', field: 'originZipcode', hidden: false},
    { title: 'Destination', field: 'destinationZipcode', hidden: false},
    { title: 'Pieces', field: 'totalPieces', hidden: false},
    { title: 'Weight', field: 'totalWeight', render: weightRender, hidden: false},
    { title: 'Carrier', field: 'carrier', hidden: false},
    { title: 'Service', field: 'serviceLevel', hidden: false},
    { title: 'Transit', field: 'transitDays', hidden: false},
    { title: 'Total', field: 'total', type: 'currency', render: totalRender, hidden: false},
    { title: 'Quoted By', field: 'quotedBy', hidden: true},
  ])

  const defaultCall = (filtersProps) => {
    setLoading(true)

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = axios.CancelToken.source()

    axios.get(GET_QUOTES, {
      cancelToken: cancelToken.token,
      params: {
        dateFrom: filtersProps.from && format(filtersProps.from, 'yyyy-MM-dd'),
        dateTo: filtersProps.to && format(filtersProps.to, 'yyyy-MM-dd'),
        page: pageNumber + 1,  
        limit: resetValues ? 25 : rowsPerPage,
        'sort[]': 'date:desc'
      }
    }).then((results) => {
      let resultsArray = results.data.data.results

      resultsArray.map(result => {
          return (
              result.rateType = result.vendor.rateType,
              result.carrier = result.vendor.name,
              result.serviceLevel = result.vendor.serviceLevel,
              result.transitDays = result.vendor.transitDays
          )
      })
      setTotalResults(results.data.data.pagingDetails.totalResults)
      setQuotes(resultsArray)
      setLoading(false)
    }).catch(error => {
      isTokenExp()
      setLoading(false)
      checkErrorAPICall(error, dispatch, 'Quotes')
    })
  }

  const updateFilters = (filtersProps: {pageNumber?: number}) => {
    if(isNaN(filters.from) || isNaN(filters.to) || clicked){

    }else {
      setLoading(true)

      if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.')
      }
  
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source()

      axios.get(GET_QUOTES, {
        cancelToken: cancelToken.token,
        params: filters.bookedOnly ? {
          search: filters.search.trim(),
          dateFrom: filters.from && format(filters.from, 'yyyy-MM-dd'),
          dateTo: filters.to && format(filters.to, 'yyyy-MM-dd'),
          rateType:  filters.type[0] === 'ALL' ? [] : filters.type,
          bookedOnly: true,
          page: filtersProps.pageNumber + 1 || pageNumber + 1,
          limit: rowsPerPage,
          'sort[]': 'date:desc'
        } : {
          search: filters.search.trim(),
          dateFrom: filters.from && format(filters.from, 'yyyy-MM-dd'),
          dateTo: filters.to && format(filters.to, 'yyyy-MM-dd'),
          rateType:  filters.type[0] === 'ALL' ? [] : filters.type,
          page: filtersProps.pageNumber + 1 || pageNumber + 1,
          limit: rowsPerPage,
          'sort[]': 'date:desc'
        }
      }).then((results) => {
          let resultsArray = results.data.data.results

          resultsArray.map(result => {
            return (
            result.rateType = result.vendor.rateType,
            result.carrier = result.vendor.name,
            result.serviceLevel = result.vendor.serviceLevel,
            result.transitDays = result.vendor.transitDays
            )
          })
          setTotalResults(results.data.data.pagingDetails.totalResults)
          setQuotes(resultsArray)
  
          if(resultsArray.length){
            let newSearch = {id: uuidv4(), ...filters, hashId: `${JSON.stringify(filters.search)}${JSON.stringify(filters.from ? filters.from.toDateString() : '')}${JSON.stringify(filters.to ? filters.to.toDateString() : '')}${JSON.stringify(filters.type)}${JSON.stringify(filters.bookedOnly)}`}
            let existingSearches = JSON.parse(localStorage.getItem("historyQuotesGrid")) || []
            let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
        
            if(isDuplicated){
        
            }else{
                existingSearches.unshift(newSearch);
                localStorage.setItem("historyQuotesGrid", JSON.stringify(existingSearches));
            }
          }
          setLoading(false)
      }).catch(error => {
          setLoading(false)
          checkErrorAPICall(error, dispatch, 'Quotes')
      })
    }
  }

  const updateFiltersFirstCall = () => {
    if(isNaN(filters.from) || isNaN(filters.to)){

    }else {
        setLoading(true)

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
    
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
    
        axios.get(GET_QUOTES, {
            cancelToken: cancelToken.token,
            params: filters.bookedOnly ? {
                search: filters.search.trim(),
                dateFrom: filters.from && format(filters.from, 'yyyy-MM-dd'),
                dateTo: filters.to && format(filters.to, 'yyyy-MM-dd'),
                rateType:  filters.type[0] === 'ALL' ? [] : filters.type,
                bookedOnly: true,
                page: 1,
                limit: rowsPerPage,
                'sort[]': 'date:desc'
            } : {
                search: filters.search.trim(),
                dateFrom: filters.from && format(filters.from, 'yyyy-MM-dd'),
                dateTo: filters.to && format(filters.to, 'yyyy-MM-dd'),
                rateType:  filters.type[0] === 'ALL' ? [] : filters.type,
                page: 1,
                limit: rowsPerPage,
                'sort[]': 'date:desc'
            }
        }).then((results) => {
            let resultsArray = results.data.data.results

            resultsArray.map(result => {
                return (
                result.rateType = result.vendor.rateType,
                result.carrier = result.vendor.name,
                result.serviceLevel = result.vendor.serviceLevel,
                result.transitDays = result.vendor.transitDays
                )
            })
            setTotalResults(results.data.data.pagingDetails.totalResults)
            setQuotes(resultsArray)
    
            if(resultsArray.length){
                let newSearch = {id: uuidv4(), ...filters, hashId: `${JSON.stringify(filters.search)}${JSON.stringify(filters.from ? filters.from.toDateString() : '')}${JSON.stringify(filters.to ? filters.to.toDateString() : '')}${JSON.stringify(filters.type)}${JSON.stringify(filters.bookedOnly)}`}
                let existingSearches = JSON.parse(localStorage.getItem("historyQuotesGrid")) || []
                let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
            
                if(isDuplicated){
            
                }else{
                    existingSearches.unshift(newSearch);
                    localStorage.setItem("historyQuotesGrid", JSON.stringify(existingSearches));
                }
            }
            setClicked(false)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            checkErrorAPICall(error, dispatch, 'Quotes')
        })
    }
  }

  useEffect(() => {
    setHistoryElement(
      <HistorySelect
        setFilters={setFilters}
        historyFor='QuotesGrid'
      />
    )
    setFiltering(true)
    setFilters({
      ...filters,
      from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      to: new Date()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(resetValues){
      setPageNumber(0)
      setRowsPerPage(25)
      setFilters({
        search: '',
        from: new Date().setMonth(new Date().getMonth() - 1),
        to: new Date(),
        type: ['ALL'],
        bookedOnly: false
      })
      defaultCall({from: new Date().setMonth(new Date().getMonth() - 1), to: new Date()})
      dispatch({type: actionTypes.SetResetValues, payload: false})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  return (
    <>
      <PageTitle>Quotes Grid</PageTitle>
      <Toolbar
        columns={columns}
        setColumns={setColumns}
        filters={filters}
        setFilters={setFilters}
        quotes={quotes}
        setLoading={setLoading}
        setFiltering={setFiltering}
        defaultCall={defaultCall}
        updateFiltersFirstCall={updateFiltersFirstCall}
        setClicked={setClicked}
        setPageNumber={setPageNumber}
      />
      <Table
        columns={columns}
        data={quotes}
        loading={loading}
        setLoading={setLoading}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNumber={pageNumber}
        totalResults={totalResults}
        tableFor='Quotes'
        filters={filters}
        filtering={filtering}
        defaultCall={defaultCall}
        updateFilters={updateFilters}
      />
      <QuoteBreakdownModal
        open={openQuoteBreakdownModal}
        setOpen={setOpenQuoteBreakdownModal}
        data={quoteBreakdownModalData}
      />
    </>
  )
}