import { FormsEditProps } from '../../../pages/vendors/models'
import { InputStyled } from '../../Inputs.styled'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const AdditionalInformation = ({formik}: FormsEditProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <>
            <div className="row align-items-center">
                <div className="col-12 col-sm-2 col-md-1 mb-0 mb-sm-5 mb-md-0">
                    <label className='form-label'>MC#</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('additionalInformation.MC#')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1 mb-0 mb-sm-5 mb-md-0">
                    <label className='form-label'>EIN#</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('additionalInformation.EIN#')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>AP Code</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='new-password'
                        {...formik.getFieldProps('additionalInformation.APCode')}
                        fontSize={fontSize}
                    />
                </div>
            </div>

            <div className="row align-items-center mt-5 mb-5">
                <div className="col-12 col-sm-2 col-md-1 mb-0 mb-sm-5 mb-md-0">
                    <label className='form-label'>US Bond#</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('additionalInformation.USBond#')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1 mb-0 mb-sm-5 mb-md-0">
                    <label className='form-label'>SCAC</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('additionalInformation.SCAC')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>DOT#</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('additionalInformation.DOT#')}
                        fontSize={fontSize}
                    />
                </div>
            </div>
        </>
    )
}

export default AdditionalInformation