import SearchInputToolbar from '../../LTLandVolumeTLPages/SearchInputToolbar'
import SelectExportType from '../../LTLandVolumeTLPages/SelectExportType/SelectExportType'
import ShowHideColumns from './ShowHideColumns'
import DateInput from '../DateInput';
import SelectType from './SelectType';
import { useEffect } from 'react';
import { ToolbarProps } from '../../../pages/quotes-grid/models';
import { ToolbarStyled } from '../../Toolbar.styled';

const Toolbar = ({columns, setColumns, quotes, setLoading, filters, setFilters, setFiltering, defaultCall, updateFiltersFirstCall, setClicked, setPageNumber}: ToolbarProps) => {
    const handleSearch = (e) => {
        setFilters({...filters, search: e.target.value})
    }

    useEffect(() => {
        defaultCall({from: new Date().setMonth(new Date().getMonth() - 1), to: new Date()})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filter = () => {
        setClicked(true)
        setFiltering(true)
        updateFiltersFirstCall()
    }

    return (
        <ToolbarStyled className="row mb-8 align-items-center">
            <div className="col-5 col-sm-3 col-md-2 col-lg-2">
                <SearchInputToolbar
                    searchTable={filters.search}
                    handleSearch={handleSearch}
                    className="w-100"
                    filter={filter}
                />
            </div>

            <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                <div className="row">
                    <div className="col-6 col-sm-4 d-flex align-items-center pe-0">
                        <div className='me-3'>From</div>
                        <div className='w-100'>
                            <DateInput
                                data={filters}
                                setData={setFilters}
                                dateFor='from'
                            />
                        </div>                        
                    </div>
                    <div className="col-6 col-sm-4 d-flex align-items-center">
                        <div className='me-3'>To</div>
                        <div className='w-100'>
                            <DateInput
                                data={filters}
                                setData={setFilters}
                                dateFor='to'
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 mt-4 mt-sm-0 d-flex align-items-center">
                        <div className='me-3'>Type</div>
                        <SelectType
                            filters={filters}
                            setFilters={setFilters}
                            types={[
                                {value: 'ALL', name: 'All'},
                                {value: 'LTL', name: 'LTL'},
                                {value: 'SP', name: 'Small Package'},
                                {value: 'VOL', name: 'Volume/TL'},
                                {value: 'DRAY', name: 'Drayage'}
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="col-3 col-sm-2 col-xl-1 mt-4 mt-xl-0">
                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        id='bookedOnly'
                        checked={filters.bookedOnly}
                        onChange={() =>
                            setFilters({...filters, bookedOnly: !filters.bookedOnly})
                        }
                    />
                    <label className='form-check-label' htmlFor='bookedOnly'>Booked Only</label>
                </div>
            </div>

            <div className="col-7 col-sm-2 mt-5 mt-xl-0 d-flex align-items-center">
                <div
                    className='btn btn-secondary me-3'
                    onClick={() => {
                        setPageNumber(0)
                        setFilters({
                            search: '',
                            from: null,
                            to: null,
                            type: ['ALL'],
                            bookedOnly: false
                        })
                        setClicked(true)
                        setFiltering(false)
                        defaultCall({})
                    }}
                >
                    Clear
                </div>
                <div
                    className='btn btn-primary'
                    onClick={() => {
                        filter()
                    }}
                >
                    Filter
                </div>
            </div>
            
            <div className="col-2 col-sm-8 col-xl-1 mt-5 mt-xl-0 d-flex align-items-center justify-content-end">
                <div className="me-3">
                    <ShowHideColumns
                        columns={columns}
                        setColumns={setColumns}
                        disabled={quotes.length ? false : true}
                    />
                </div>
                <SelectExportType
                    fileName="Quotes"
                    columns={columns.filter(column => column.hidden === false)}
                    dataColumns={quotes}
                    disabled={quotes.length ? false : true}
                    setLoading={setLoading}
                />
            </div>
        </ToolbarStyled>
  )
}

export default Toolbar