import { FormsEditProps } from '../../../pages/vendors/models'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const CarrierRemarks = ({formik}: FormsEditProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6 mb-5 mb-md-0">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" id="quotes" checked={formik.values.carrierRemarks.displayMessageQuotes} {...formik.getFieldProps('carrierRemarks.displayMessageQuotes')}/>
                                <label className="form-check-label" htmlFor="quotes">
                                    Display below message when displaying quotes
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <textarea
                                style={{height: fontSize < 13 ? 100 : 150}}
                                className="form-control"
                                autoComplete='off'
                                {...formik.getFieldProps('carrierRemarks.messageQuotes')}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" id="bol" checked={formik.values.carrierRemarks.displayMessageBOL} {...formik.getFieldProps('carrierRemarks.displayMessageBOL')}/>
                                <label className="form-check-label" htmlFor="bol">
                                    Print below message on the BOL
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <textarea
                                style={{height: fontSize < 13 ? 100 : 150}}
                                className="form-control"
                                autoComplete='off'
                                {...formik.getFieldProps('carrierRemarks.messageBOL')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    }

export default CarrierRemarks