import { Backdrop, Fade, Modal } from '@mui/material'
import { useState } from 'react';
import { LinearProgressStyled } from '../../ProgressBarRender/ProgressBarRender.styled';
import { tableStyles } from '../../TableStyles';
import { PaperModal, Title } from '../Modals.styled';
import { modalStyles } from '../ModalStyles';

const MissingDataModal = ({open, setOpen, numberOfZipcodesMissing}) => {
    const classes = modalStyles();
    const classesTable = tableStyles()
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal fontSize={fontSize} widthWhenFontSmall={400} className={classes.paperSmall}>
                    <>
                        <div className="row justify-content-center p-8">
                            <div className="col-12 d-flex mb-7 justify-content-center">
                                <Title fontSize={fontSize}>Missing {numberOfZipcodesMissing} city/states. Updating</Title>
                            </div>
                            <LinearProgressStyled
                                className={classesTable.linearProgress}
                            />
                        </div>
                    </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default MissingDataModal