export const LandingPage = [
    {
        path: '/home',
        value: 'Dashboard'
    },
    {
        path: '/quote/ltl',
        value: 'LTL'
    },
    {
        path: '/quote/volume-tl',
        value: 'TL'
    },
    {
        path: '/quote/drayage',
        value: 'DRAY'
    },
    /* {
        path: '/quote/batch-quoting',
        value: 'BATCH'
    }, */
    {
        path: '/trackings',
        value: 'TRACKING'
    },
    {
        path: '/invoices',
        value: 'INVOICES'
    },
    {
        path: '/book/new-booking',
        value: 'BOOKING'
    }
]