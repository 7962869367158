import axios from "axios"
import { format } from "date-fns"
import moment from "moment"
import store from "../../setup/redux/Store"
import { actionTypes } from "../modules/auth/redux/PagesRedux"
const CREATE_SHIPMENT = '/applet/v1/book'
const SAVE_QUOTE = `/applet/v1/rate/save`

export const createShipmentsBatchBooking = async (rowData, newArray, index, handleDataOnChange) => {
    axios.post(SAVE_QUOTE, {
        rateId: rowData?.Rate?.id,
        originShippingLocationId: 0,
        destinationShippingLocationId: 0
      }).then(resultQuote => {
        axios.post(CREATE_SHIPMENT, {
          shipper: {
            name: rowData.ShipperName,
            email: rowData.ShipperEmail,
            contact: rowData.ShipperContact,
            phone: rowData.ShipperPhone,
            address: rowData.ShipperAddress,
            referenceNumber: rowData['ShipperRef#'],
            address1: rowData.ShipperAddress,
            state: rowData.ShipperState,
            zipcode: rowData.ShipperZipcode,
            city: rowData.ShipperCity,
            country: 'US',
          },
          consignee: {
            name: rowData.ConsigneeName,
            email: rowData.ConsigneeEmail,
            contact: rowData.ConsigneeContact,
            phone: rowData.ConsigneePhone,
            address: rowData.ConsigneeAddress,
            referenceNumber: rowData['ConsigneeRef#'],
            address1: rowData.ConsigneeAddress,
            state: rowData.ConsigneeState,
            zipcode: rowData.ConsigneeZipcode,
            city: rowData.ConsigneeCity,
            country: 'US',
          },
          "lineItems": [{
            qty: rowData.Pieces,
            weight: rowData.Weight,
            weightType: "total",
            nmfc: rowData.NMFC,
            class: rowData.Class,
            length: rowData.Length,
            width: rowData.Width,
            height: rowData.Height,
            commodity: rowData.Commodity
          }],
          "quoteNumber": resultQuote.data?.data?.results?.quoteNumber,
          "pickupInformation": {
            "date": rowData.PickupDate ? format(new Date(rowData.PickupDate), 'yyyy-MM-dd') ? format(new Date(rowData.PickupDate), 'yyyy-MM-dd') : '' : '',
            "timeFrom": rowData.PickupTimeFrom.length === 3 
              ? `0${rowData.PickupTimeFrom[0]}:${rowData.PickupTimeFrom[1]}${rowData.PickupTimeFrom[2]}` : rowData.PickupTimeFrom ? (rowData.PickupTimeFrom.length > 5 ? moment(rowData.PickupTimeFrom, 'HH:mm:ss').format('HH:mm') : rowData.PickupTimeFrom.includes(':') ? rowData.PickupTimeFrom.length === 4 ? `0${rowData.PickupTimeFrom[0]}:${rowData.PickupTimeFrom[2]}${rowData.PickupTimeFrom[3]}` : rowData.PickupTimeFrom || "" : rowData.PickupTimeFrom.slice(0, 2) + ":" + rowData.PickupTimeFrom.slice(2))
              : '',
            "timeTo": rowData.PickupTimeTo.length === 3 
            ? `0${rowData.PickupTimeTo[0]}:${rowData.PickupTimeTo[1]}${rowData.PickupTimeTo[2]}` : rowData.PickupTimeTo ? (rowData.PickupTimeTo.length > 5 ? moment(rowData.PickupTimeTo, 'HH:mm:ss').format('HH:mm') : rowData.PickupTimeTo.includes(':') ? rowData.PickupTimeTo.length === 4 ? `0${rowData.PickupTimeTo[0]}:${rowData.PickupTimeTo[2]}${rowData.PickupTimeTo[3]}` : rowData.PickupTimeTo || "" : rowData.PickupTimeTo.slice(0, 2) + ":" + rowData.PickupTimeTo.slice(2))
            : '',
          },
          'accessorialsList': rowData.Accessorials
        }).then(result => {
          let BOLS = [...store.getState().pages.BOLS]
          BOLS.push({id: rowData.id, BOL: result?.data?.data?.results[0]?.BOLNmbr, BOLId: result?.data?.data?.results[0]?.BOLId})
          store.dispatch({type: actionTypes.SetBOLS, payload: BOLS})

          newArray[index].BOL = result?.data?.data?.results[0]?.BOLNmbr
          newArray[index].BOLId = result?.data?.data?.results[0]?.BOLId

          if(newArray.filter(d => d.BOL).length === newArray.length){
            handleDataOnChange(newArray)
          }
        }).catch(error => {
          
        })
      }).catch(error => {
          
      })
}