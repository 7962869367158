import { DivPaperStyled, ModalStyled, Title } from '../../../components/Modals.styled';
import { KTSVG } from '../../../../_metronic/helpers';
import { modalStyles } from '../../../components/ModalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { Backdrop, Fade } from '@mui/material';

const ErrorsInPageModal = ({open, setOpen, lockedBOL}) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    return (
      <ModalStyled
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        fontSize={fontSize}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
      >
        <Fade in={open}>
            <DivPaperStyled className={classes.paper}>
                <div className="row">
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <KTSVG path='/media/icons/bootstrap/x-circle.svg' className='svg-icon svg-icon-3x text-danger'/>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-5">                                
                                <Title>{lockedBOL ? 'You do not have permission to book. Please contact your sales representative for further information.' : 'The highlighted fields are required. Please complete and try again.'}</Title>
                            </div>
                        </div>
                        <div className="row mt-1 mb-3">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
            </DivPaperStyled>
        </Fade>
    </ModalStyled>
  )
}

export default ErrorsInPageModal