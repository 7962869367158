import React from 'react'
import { DivPaperStyled, ModalStyled } from '../../Modals.styled'
import { modalStyles } from '../../ModalStyles';
import { RootState } from '../../../../setup';
import { useSelector } from 'react-redux';
import { Backdrop, Fade, IconButton, Tooltip } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';

const AccessorialsModal = ({open, setOpen}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DivPaperStyled className={classes.paperStopsModal}>
                        <div className="row modal-header p-0 pb-2 mb-5">
                            <div className="col-8">
                                <h5 className="modal-title">Accessorials</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <>
                            <div className='row'>
                                <div className="col-12 d-flex justify-content-end align-items-center mb-4">
                                    
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                    </DivPaperStyled>
                </Fade>
            </ModalStyled>
        </>
    )
}

export default AccessorialsModal