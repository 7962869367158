import { Modal, Backdrop, Fade, Paper } from '@mui/material';
import { modalStyles } from '../ModalStyles';
import MaterialTable from '@material-table/core';
import { dollarUSLocale } from '../../../helpers/dollarUSLocale';
import { v4 as uuidv4 } from 'uuid';
import { Remarks, RowStyled, Title, Total } from './QuoteBreakdownModal.styled';
import { DivStyled, PaperModal } from '../Modals.styled';
import { useState } from 'react';
import { QuoteBreakdownModalProps } from '../../../pages/book/models';
import { tableStyles } from '../../TableStyles';
import { KTSVG } from '../../../../_metronic/helpers';

const QuoteBreakdownModal = ({open, setOpen, data}: QuoteBreakdownModalProps) => {
    const classesModal = modalStyles()
    const classesTable = tableStyles()
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    const handleCloseModal = () => {
        setOpen(false)
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesModal.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal className={classesModal.paper}>
                    <DivStyled fontSize={fontSize}>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleCloseModal}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                        </div>
                        <>
                            <div className="row mb-6 mt-3">
                                <div className="col-6 col-sm-7">
                                    {data?.iconUrl ? <img alt="icon" src={data.iconUrl} width='90px' className='ms-5'></img> : null}
                                </div>
                                <div className="col-6 col-sm-5">
                                    <Title>{data.name}</Title><br/>
                                    <span>{data.serviceLevel}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mb-3">
                                    <Title>Quote Breakdown</Title>
                                </div>
                            </div>
                            <RowStyled className="row">
                                <div className="col-12">
                                    <MaterialTable
                                        style={{boxShadow: 'none'}}
                                        columns={[
                                            { title: 'Description', field: 'name', cellStyle: {width: 350, minWidth: 350}},
                                            { title: 'Amount', field: 'total', type: 'currency'},
                                        ]}
                                        data={data?.billToData?.breakdown?.length ? 
                                            data?.billToData?.breakdown?.map(rateBreakdown => (
                                                {
                                                    ...rateBreakdown,
                                                    id: uuidv4()
                                                }
                                            )) :
                                            data?.rateBreakdown?.map(rateBreakdown => (
                                                {
                                                    ...rateBreakdown,
                                                    id: uuidv4()
                                                }
                                            ))
                                        }
                                        options={{
                                            thirdSortClick: false,
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            search: false,
                                            paging: false,
                                            filtering: false,
                                            searchFieldAlignment: 'left',
                                            draggable: false
                                        }}
                                        components={{
                                            Container: (props) => <Paper className={classesTable.tableQuoteBreakdown} {...props}/>,
                                        }}
                                    />
                                </div>
                            </RowStyled>
                            <div className="row align-items-end mt-5">
                                <div className="col-9 d-flex justify-content-end">
                                    <Title>Total</Title>
                                </div>
                                <div className="col-3 d-flex justify-content-end">
                                    <Total>{dollarUSLocale.format(data?.rateBreakdown?.filter(rate => rate.name === 'Markup').length ? data.billTo || data.total : data.total)}</Total>
                                </div>
                            </div>
                            <div className="row mt-8 mb-3 d-flex align-items-start">
                                <div className="col-12 col-sm-9">
                                    {data.rateRemarks?.length ? <Remarks>
                                        {
                                            data.rateRemarks?.map(remark => {
                                                if(remark?.includes('href')){
                                                    return <div key={remark} dangerouslySetInnerHTML={{ __html: remark }} />
                                                }else{
                                                    return null
                                                }
                                            })
                                        }
                                        { data?.rateRemarks?.filter(r => !r.includes('href'))?.length ?
                                            <div>
                                                **
                                                {data?.rateRemarks?.filter(r => !r.includes('href'))?.reduce(function (p, d, i) {
                                                    return p + (p.slice(-1) !== '.' ? '. ' : ' ') + d;
                                                })}
                                            </div>
                                        : null }
                                    </Remarks> : null}
                                </div>
                                <div className="col-12 col-sm-3 mt-5 mt-sm-0 d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                    </DivStyled>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default QuoteBreakdownModal