import styled from 'styled-components';

export const AppStyled = styled.div`
    div {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    label {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px`}
    }; 
    span {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    input {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    textarea {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    ul {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    li {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    p {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    th {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    td {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    button {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    & .font-big{
        font-size: 20px !important
    };
    & .font-normal{
        font-size: 14px !important;
        height: 27px
    };
    & .email{
        font-size: 11px !important;
    };
    & .svg-icon-2 {
        font-size: 18px !important 
    }
`