import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled'
import { Backdrop, Fade } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers'
import { modalStyles } from '../ModalStyles'
import { RootState } from '../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../modules/auth/models/UserModel'
import SchedulePickupModal from './SchedulePickupModal'
import { useState } from 'react'

const SmallPackageOptionsModal = ({open, setOpen, smallPackageOptions, dispatchAction}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [openSchedulePickupModal, setOpenSchedulePickupModal] = useState(false)

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DivPaperStyled className={classes.paperShipmentDispatched}>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                        </div>
                            <>
                                <div className="row justify-content-center">
                                    <div className="col-12 d-flex justify-content-center">
                                        <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-5">
                                        <Title>Please select carrier option below, labels will print following information</Title>
                                    </div>
                                </div>
                                <div className="row mt-1 mb-3">
                                    <div className='col-12 d-flex justify-content-center'>
                                        <button
                                            className='btn btn-primary me-2'
                                            onClick={() => {
                                                setOpen(false)
                                                setOpenSchedulePickupModal(true)
                                            }}
                                        >
                                            Schedule Pickup
                                        </button>
                                        {
                                            smallPackageOptions?.dropToCarrier
                                            ?   <button
                                                    className={`btn btn-primary ${smallPackageOptions?.pickupAlreadyScheduled ? 'me-2' : ''}`}
                                                    onClick={() => {
                                                        setOpen(false)
                                                        dispatchAction({makeEDI: user?.allowEDI, smallPackageHandling: 'DROP TO CARRIER'})
                                                    }}
                                                >
                                                    Drop to carrier
                                                </button>
                                            : null
                                        }
                                        {
                                            smallPackageOptions?.pickupAlreadyScheduled
                                            ?   <button
                                                    className='btn btn-primary'
                                                    onClick={() => {
                                                        setOpen(false)
                                                        dispatchAction({makeEDI: user?.allowEDI, smallPackageHandling: 'PICKUP ALREADY SCHEDULED'})
                                                    }}
                                                >
                                                    Pickup Already scheduled
                                                </button>
                                            : null
                                        }
                                    </div>
                                </div>
                            </>
                    </DivPaperStyled>
                </Fade>
            </ModalStyled>
            <SchedulePickupModal
                open={openSchedulePickupModal}
                setOpen={setOpenSchedulePickupModal}
                dispatchAction={dispatchAction}
            />
        </>
    )
}

export default SmallPackageOptionsModal