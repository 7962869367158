import { Backdrop, Fade, Paper } from '@mui/material';
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { modalStyles } from '../ModalStyles'
import MaterialTable from '@material-table/core';
import { tableStyles } from '../LTLandVolumeTLPages/Tables/TableStyles';
import { TrackingModalProps } from '../../pages/track/models';
import ConfirmationModal from './ConfirmationModal';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ModalStyled } from '../Modals.styled';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { RootState } from '../../../setup';
import { columnsModel } from '../PagesModels';
import { shallowEqual } from 'react-redux';
import { UserModel } from '../../modules/auth/models/UserModel';

const GET_TRACK = '/api/v1/track'

const TrackingModal = ({open, setOpen, historyTrackings, setHistoryTrackings, rowData}: TrackingModalProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any
    const dispatch = useDispatch()
    const classesModal = modalStyles({})
    const classesTable = tableStyles()
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [loadingRefresh, setLoadingRefresh] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [hasTrackings, setHasTrackings] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    const RemarksRender = (rowData) => {
        let rowDataCopy = {...rowData}
        delete rowDataCopy['tableData']

        return (
            <div className='d-flex align-items-center'>
                <span className='me-3'>{rowData?.remarks}</span>
            </div>
        )
    }

    const [columns, setColumns] = useState([
        { title: 'Date', field: 'date', type: 'date', dateSetting: { locale: "en-US" }},
        { title: 'Code', field: 'code'},
        { title: 'Remarks', field: 'remarks', render: RemarksRender, cellStyle: {width: 500, minWidth: 500}},
    ])

    const refreshTracking = () => {
        setOpenConfirmModal(true)
        setLoadingRefresh(true)

        axios.get(`${GET_TRACK}/${rowData?.BOLId}/API`)
        .then(() => {
            setLoadingRefresh(false)
            setHasTrackings(true)
        }).catch(error => {
            if(error?.response?.data?.error?.message !== 'Carrier did not provide any tracking for this shipment'){
                checkErrorAPICall(error, dispatch, 'Tracking')
                setOpenConfirmModal(false)
            }else{
                setHasTrackings(false)
                setLoadingRefresh(false)
            }
        })
    }

    useEffect(() => {
        setColumns([
            { title: 'Date', field: 'date', type: 'date', dateSetting: { locale: "en-US" }},
            { title: 'Code', field: 'code'},
            { title: 'Remarks', field: 'remarks', render: RemarksRender, cellStyle: {width: 460, minWidth: 460}}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyTrackings])
    
    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classesModal.paperEditModalSL} style={{visibility: 'visible', opacity: '100%'}}>
                        <div className="row modal-header p-0 pb-2">
                            <div className="col-5">
                                <h5 className="modal-title">Tracking History</h5>
                            </div>
                            <div className="col-5 d-flex justify-content-end">
                                <h5 className="m-0">BOL #{rowData?.BOLNumber} {(rowData?.PRONmbr || rowData?.carrierPRO) && `- PRO# ${rowData?.PRONmbr || rowData?.carrierPRO}`}</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                            </div>
                        </div>
                        <>
                            <div className="row mt-7">
                            </div>
                            <div className="row" style={{maxHeight: 450, overflow: 'auto'}}>
                                <div className="col-12">
                                    <MaterialTable
                                        style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                                        columns={columns as columnsModel}
                                        data={historyTrackings}
                                        isLoading={loadingTable}
                                        options={{
                                            thirdSortClick: false,
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            filtering: false,
                                            draggable: false,
                                            paging: false,
                                            rowStyle: (data, idx) => idx%2 !== 0 ? {
                                                backgroundColor: '#f4f4f4',
                                            } : {
                                                backgroundColor: '#ffff',
                                            },
                                        }}
                                        components={{
                                            Container: (props) => <Paper className={classesTable.tableQuotesGrid} {...props} />,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3 mt-7">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-secondary me-4"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>                                    
                                    {user?.allowCarrierTracking && rowData?.dispatched && (
                                        <button
                                            className='btn btn-primary d-flex align-items-center'
                                            onClick={() => refreshTracking()}
                                        >
                                            Refresh Tracking
                                        </button>
                                    )}
                                </div>
                            </div>
                        </>
                    </div>
                </Fade>
            </ModalStyled>
            <ConfirmationModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
                loadingRefresh={loadingRefresh}
                hasTrackings={hasTrackings}
                rowData={rowData}
                setHistoryTrackings={setHistoryTrackings}
                setLoadingTable={setLoadingTable}
            />
        </>
    )
}

export default TrackingModal