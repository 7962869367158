import { MenuItem, TextField } from '@mui/material'
import React from 'react'
import { useStyles } from '../../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectTypeProps } from '../../../pages/quotes-grid/models';
import { stylesMUIInput } from '../../stylesMUIInput';
import { RootState } from '../../../../setup';
import { useSelector } from 'react-redux';

const SelectType = ({filters, setFilters, className, types}: SelectTypeProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({fontSize: fontSize})

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    const onChangeType = (e) => {
        let value = e.target.value

        if(value.length === 0){
            value = ['ALL']
        }

        if(value.length > 1 && value[0] === 'ALL'){
            value = value.filter(value => value !== 'ALL')
        }else if(value.length > 1 && value[value.length - 1] === 'ALL'){
            value = ['ALL']
        }else{
            if(value.length === 6){
                value = ['ALL']
            }
        }

        setFilters({...filters, type: value})
    }

    return (
        <TextField
            select
            label=" "
            value={filters.type}
            onChange={onChangeType}
            className={`${classes.selectMUI} ${className}`}
            InputProps={{
                style: stylesMUIInput(fontSize),
            }}
            SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
                MenuProps: menuProps,
                multiple: true
            }}
            InputLabelProps={{shrink: false}}
        >
            {types.map((type, index) => <MenuItem key={index} value={type.value}>{type.name}</MenuItem>)}
        </TextField>
    )
}

export default SelectType