export const stylesMUIInput = (fontSize) => {
    return {
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
        paddingRight: 6,
        background: 'white',
        color: "#5E6278",
        height: fontSize === 9 ? 30 : fontSize === 11 ? 40 : 'var(--input-height)'
    }
}