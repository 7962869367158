import { Backdrop, Fade, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { modalStyles } from './ModalStyles';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as auth from '../modules/auth/redux/AuthRedux'
import { login } from '../modules/auth/redux/AuthCRUD';
import { DivPaperStyled } from './Modals.styled';

const loginSchema = Yup.object().shape({
    username: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Username is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(50, 'Maximum 50 characters')
      .required('Password is required'),
})
  
const initialValues = {
    rememberMe: false,
    username: '',
    password: '',
}

const SessionExpiredModal = () => {
    const classes = modalStyles({});
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const tokenExp = useSelector<any>(({auth}) => auth.isTokenExp)
    const [open, setOpen] = useState(false)

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        validateOnChange: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setTimeout(() => {
                login(values.username, values.password)
                .then(({data}) => {
                    setLoading(false)
                    dispatch(auth.actions.login(data.data.accessToken, values.rememberMe, data.data.exp))
                    setOpen(false)
                })
                .catch((error) => {
                    setLoading(false)
                    setSubmitting(false)
                    setStatus('The login detail is incorrect')
                })
            }, 1000)
        },
    })
    
    useEffect(() => {
        if(tokenExp){
            setOpen(true)
        }else{
            setOpen(false)
        }
    }, [tokenExp])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row p-10">
                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_login_signin_form'
                    >
                        {/* begin::Heading */}
                        <div className='text-center mb-10'>
                            <h1 className='text-dark mb-3'>Session expired. Please re-login</h1>
                        </div>
                        {/* begin::Heading */}

                        {formik.status && (
                            <div className='mb-lg-7 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )
                        }

                        {/* begin::Form group */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
                            <input
                            placeholder='Email'
                            {...formik.getFieldProps('username')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {'is-invalid': formik.touched.username && formik.errors.username},
                                {
                                'is-valid': formik.touched.username && !formik.errors.username,
                                }
                            )}
                            type='email'
                            name='username'
                            autoComplete='off'
                            />
                            {formik.touched.username && formik.errors.username && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>{formik.errors.username}</span>
                            </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group */}
                        <div className='fv-row mb-8'>
                            <div className='d-flex justify-content-between mt-n5'>
                            <div className='d-flex flex-stack mb-2'>
                                {/* begin::Label */}
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                                {/* end::Label */}
                            </div>
                            </div>
                            <input
                            placeholder='Password'
                            type='password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                'is-invalid': formik.touched.password && formik.errors.password,
                                },
                                {
                                'is-valid': formik.touched.password && !formik.errors.password,
                                }
                            )}
                            />
                            {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>{formik.errors.password}</span>
                                </div>
                            </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* Remember Me checkbox */}
                        <div className='d-flex justify-content-center mb-8'>

                            <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" {...formik.getFieldProps('rememberMe')} name="rememberMe" id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Remember me
                            </label>
                            </div>
                        </div>

                        {/* begin::Action */}
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_in_submit'
                                className='btn btn-lg btn-primary w-100 mb-5'
                                disabled={loading || !formik.isValid}
                            >
                            {!loading && <span className='indicator-label'>Sign In</span>}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            </button>
                            
                        </div>
                        {/* end::Action */}
                        </form>
                    </div>
                </DivPaperStyled>
            </Fade>
        </Modal>
    )
}

export default SessionExpiredModal