import {useState} from 'react'
import { Select, MenuItem, Tooltip } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useStyles } from '../../components/LTLandVolumeTLPages/SelectIconStyles';
import { HistorySelectProps } from './models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const HistorySelect = ({setSearch, historyFor}: HistorySelectProps) => {
    const recentSearches = JSON.parse(localStorage.getItem(`history${historyFor}`)) || []
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({disabled: false, fontSize: fontSize})
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const menuProps = {
        classes: {
            list: classes.listHistory
        }
    }

    const setAllData = (e) => {
        let search = recentSearches.filter(search => search.id === e.target.value)[0]

        setSearch(search.search)
    }
    
    return (
        <div className='d-flex align-items-center justify-content-center'>
            <Tooltip title="History" open={tooltipOpen}>
                <Select
                    IconComponent={HistoryIcon}
                    className={classes.select}
                    style={{ cursor: 'pointer'}}
                    variant='standard'
                    displayEmpty
                    onChange={(e) => setAllData(e)}
                    value=""
                    MenuProps={menuProps}
                    onMouseEnter={() => window.innerWidth > 575 && setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    onOpen={() => setTooltipOpen(false)}
                >
                    {!recentSearches?.length && <MenuItem disabled>No recent searches</MenuItem>}
                    {
                        recentSearches?.map((search, index) => {
                            return <MenuItem key={index} value={search.id}>
                               <div>{search.search}</div>
                            </MenuItem>
                        })
                    }
                </Select>
            </Tooltip>
        </div>
    )
}

export default HistorySelect