import axios from 'axios'
import { useEffect, useState } from 'react'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch } from 'react-redux';
import Toolbar from '../../components/ShippingLocationsPage/Toolbar'
import Table from '../../components/ShippingLocationsPage/Table'
import HistorySelect from '../shipping-items/HistorySelect';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';

let cancelToken
const GET_SHIPPINGLOCATIONS = '/api/v1/database/shippinglocation'

interface props {
  pageNumberProp?: number
}

export const ShippingLocations = () => {
  const dispatch = useDispatch()
  let {setHistoryElement} = usePageData()
  const [results, setResults] = useState([]) as any
  const [totalResults, setTotalResults] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState('') 
  const [loading, setLoading] = useState(false)

  const addressRender = (rowData) => {
    return (
      <div>{`${rowData.contact} ${rowData.address}. ${rowData.address1}`}</div>
    )
  }

  const activeRender = (rowData) => {
    return (
      <div style={{textAlign: 'center'}}><CircleIcon style={{color: rowData.active ? '#47BE7D' : '#F1416C'}}/></div>
    )
  }

  const emailRender = (rowData) => {
    return (
      rowData.email?.split(';')?.map((emailSingle) => {
        return <div>{emailSingle}</div>
      })
    )
  }

  const [columns, setColumns] = useState([
    { title: 'Code', field: 'code', hidden: false},
    { title: 'Name', field: 'name', hidden: false},
    { title: 'Address', field: 'contact', render: addressRender, hidden: true, cellStyle: {width: 160, minWidth: 160}},
    { title: 'City', field: 'city', hidden: false},
    { title: 'State', field: 'state', hidden: false},
    { title: 'Zipcode', field: 'zipcode', hidden: false},
    { title: 'Contact', field: 'contact', hidden: false},
    { title: 'Contact Phone', field: 'contactPhone', hidden: false},
    { title: 'Email', field: 'email', render: emailRender, hidden: false},
    { title: 'Open', field: 'opentime', hidden: true},
    { title: 'Close', field: 'closetime', hidden: true},
    { title: 'Created', field: 'dateSaved', type: 'date', dateSetting: { locale: "en-US" }, hidden: true},
    { title: 'User', field: 'createdByUser', hidden: true},
    { title: 'Active', field: 'active', render: activeRender, sorting: false, hidden: false, headerStyle: {textAlign: 'center'}},
  ])

  const getShippingLocations = (props: props) => {
    setLoading(true)

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = axios.CancelToken.source()

    axios.get(GET_SHIPPINGLOCATIONS, {
      cancelToken: cancelToken.token,
      params: {
        page: pageNumber + 1,
        limit: rowsPerPage,
        search: search.trim()
      }
    }).then((results) => {
      setResults(results.data.data.results)
      setTotalResults(results.data.data.pagingDetails.totalResults)
      setLoading(false)
    }).catch(error => {
      checkErrorAPICall(error, dispatch, 'Shipping Locations')
    })
  }

  useEffect(() => {
    getShippingLocations({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, search, rowsPerPage])

  useEffect(() => {
    setHistoryElement(
      <HistorySelect
        setSearch={setSearch}
        historyFor='ShippingLocations'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle>Shipping Locations</PageTitle>
      <Toolbar
        search={search}
        setSearch={setSearch}
        columns={columns}
        setColumns={setColumns}
        results={results}
        setPageNumber={setPageNumber}
        setLoading={setLoading}
        tableFor='ShippingLocations'
        getData={getShippingLocations}
        isSL={true}
      />
      <Table
        loading={loading}
        columns={columns}
        results={results}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalResults={totalResults}
        tableFor='ShippingLocations'
        getData={getShippingLocations}
      />
    </>
  )
}
