import { shallowEqual, useSelector } from 'react-redux';
import { ProgressBarRenderProps } from '../../pages/book/models';
import { tableStyles } from '../TableStyles';
import { LinearProgressStyled } from './ProgressBarRender.styled';
import { memo } from 'react';
import { RootState } from '../../../setup';

const ProgressBarRender = memo(({rowData, id}: ProgressBarRenderProps) => {
    const progress = useSelector<RootState>(({pages}) => pages.progress, shallowEqual) as any
    const classesTable = tableStyles()
    
    return (
        <>
            <div
                style={{
                    color: (rowData.progress === 100 ? rowData.progress : progress.find(p => p.id === id)?.progress || 0) === 100 ? '#47BE7C' : '#009EF7',
                    fontWeight: 500
                }}
            >
                {parseInt((rowData.progress === 100 ? rowData.progress : progress.find(p => p.id === id)?.progress || 0)?.toString() || '0')}%
            </div>
            <LinearProgressStyled
                variant="determinate"
                value={rowData.progress === 100 ? rowData.progress : progress.find(p => p.id === id)?.progress || 0}
                className={(rowData.progress === 100 ? rowData.progress : progress.find(p => p.id === id)?.progress || 0) === 100 ? classesTable.linearProgressFinished : classesTable.linearProgress}
            /> 
        </>
    )
})

export default ProgressBarRender