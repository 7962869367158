import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen, disableSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import axios from 'axios'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useLocation } from 'react-router-dom'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const accessTokenLS = JSON.parse(localStorage.getItem('primus-key'))?.accessToken
  const customerInfo = useSelector(({auth}: any) => auth.customerInfo)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenByUrl = queryParams.get('token'); 

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken(accessTokenLS ? JSON.parse(accessTokenLS) : accessToken as string)
          .catch(error => {
            if(error?.response?.status === 401){
              dispatch(auth.actions.isTokenExp(true))
            }
          })
          
          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if(!customerInfo?.portalMainLogo){
      let allUrl = window.location.origin
      let url = `${allUrl}/applet/v1/settings`.replace("portal", "api") 

      // console.log('aca')
      if(allUrl?.includes('portal')){
        axios.get(url).then((results) => {
          dispatch({
            type: auth.actionTypes.setCustomerInfo,
            payload: {
              portalMainLogo: results.data.data.results?.portalMainLogo || '/media/logos/ShipprimusLogoWhiteBG.png',
              portalBackground: results.data.data.results?.portalBackground.includes('#') ? results.data.data.results?.portalBackground : results.data.data.results?.portalBackground ? `url(${results.data.data.results?.portalBackground})` : `url(${toAbsoluteUrl('/media/background/Default-BGImage.jpg')})`,
              isDark: results.data.data.results?.isDark,
              customFavIcon: results.data.data.results?.customFavIcon
            }
          })
          if(!tokenByUrl) {
            disableSplashScreen()
          }
        }).catch((error) => {
          dispatch({
            type: auth.actionTypes.setCustomerInfo,
            payload: {
              portalMainLogo: '/media/logos/ShipprimusLogoWhiteBG.png',
              portalBackground: `url(${toAbsoluteUrl('/media/background/Default-BGImage.jpg')})`,
              isDark: true,
              customFavIcon: '/defaultFavicon.ico'
            }
          })
          disableSplashScreen()
        })
      }else{
        dispatch({
          type: auth.actionTypes.setCustomerInfo,
          payload: {
            portalMainLogo: '/media/logos/ShipprimusLogoWhiteBG.png',
            portalBackground: `url(${toAbsoluteUrl('/media/background/Default-BGImage.jpg')})`,
            isDark: true,
            customFavIcon: '/defaultFavicon.ico'
          }
        })
        if(!tokenByUrl) {
          disableSplashScreen()
        }
      }
    }

    if (accessTokenLS || accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
