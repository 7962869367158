import { Backdrop, Fade, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { FooterStyled, PaperModal, Title } from '../Modals.styled';
import { modalStyles } from '../ModalStyles';
import { KTSVG } from '../../../../_metronic/helpers';

const InvalidZipcodesModal = ({open, setOpen, data, setOpenWarningCallsModal, setOpenConfirmModal, allData, newData}) => {
    const classes = modalStyles();
    const [rowsWithInvalidZipcodeEdited, setRowsWithInvalidZipcodeEdited] = useState([])
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    const handleClose = () => {
        setOpen(false)

        if(data.length > 500){
            setOpenWarningCallsModal(true)
        }else{
            setOpenConfirmModal(true)
        }
    }

    useEffect(() => {
        if(open){
            let indexes = []

            allData.forEach((singleData, index) => {
                let dataWithSameShipNumber = (newData.length ? newData : data).filter(a => a.ShipmentNmb === singleData.ShipmentNmb)[0]
                if((!singleData.OriginCity || !singleData.OriginState || !singleData.DestinationCity || !singleData.DestinationState) && singleData.ShipmentNmb && dataWithSameShipNumber){
                    singleData.OriginCity = dataWithSameShipNumber.OriginCity || ''
                    singleData.OriginState = dataWithSameShipNumber.OriginState || ''
                    singleData.DestinationCity = dataWithSameShipNumber.DestinationCity || '' 
                    singleData.DestinationState = dataWithSameShipNumber.DestinationState || ''
                }
                
                if(!singleData.OriginCity || !singleData.OriginState || !singleData.DestinationCity || !singleData.DestinationState){
                    indexes.push(index + 2)
                }
            })

            setRowsWithInvalidZipcodeEdited(indexes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal fontSize={fontSize} widthWhenFontSmall={400} className={classes.paperSmall}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/exclamation-circle.svg' className='svg-icon svg-icon-3x text-warning'/>
                                </div>
                                <div className="col-12 mt-5 d-flex justify-content-center">
                                    <Title fontSize={fontSize}>Invalid zipcodes in {rowsWithInvalidZipcodeEdited.length === 1 ? 'row' : 'rows'} {rowsWithInvalidZipcodeEdited.join(", ")}</Title>
                                </div>
                            </div>
                            <FooterStyled fontSize={fontSize} className="row mt-5 mb-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </FooterStyled>
                        </div>
                    </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default InvalidZipcodesModal