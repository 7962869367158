import React, { useState, useEffect } from 'react'
import MaterialTable from '@material-table/core';
import { Paper } from '@mui/material';
import axios from 'axios'; 
import { useDispatch, useSelector } from 'react-redux';
import { KTSVG } from '../../../../_metronic/helpers';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { RootState } from '../../../../setup';
import { stylesMUIInput } from '../../stylesMUIInput';
import { CircularProgressWhiteStyled, MTableBodyRowStyled } from '../../QuotesGridPage/Table/Table.styled';
import { useStyles } from '../UNTable/UNTableStyles';
import SearchInputToolbar from '../SearchInputToolbar';
import CircleIcon from '@mui/icons-material/Circle';
import { v4 as uuidv4 } from 'uuid';
import GetClassWarningModal from './GetClassWarningModal';
import RequireClassITModal from './RequireClassITModal';

let cancelToken
const GET_NMFCS = 'api/v1/database/nmfc/search'
const GET_NMFC = 'api/v1/database/nmfc/item'

const NMFCTable = ({ open, selectNMFC, handleClose, searchFormik }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [tableResults, setTableResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [selectedId, setSelectedId] = useState('')
    const [openGetClassWarningModal, setOpenGetClassWarningModal] = useState(false)
    const [openRequireClassITModal, setOpenRequireClassITModal] = useState(false)

    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const storedRender = (rowData) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CircleIcon style={{ color: rowData.stored === false ? '#F1416C' : '#47BE7D' }} />
            </div>
        )
    }

    const classRender = (rowData) => {
        return (
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {
                    rowData.itemClass ? rowData.itemClass :
                    <button
                        className='btn btn-primary d-flex align-items-center justify-content-center'
                        style={{width: 109.72}}
                        disabled={rowData.loading}
                        onClick={() => {
                            setSelectedId(rowData.id)
                        }}
                    >
                        {rowData.loading ? <CircularProgressWhiteStyled style={{height: 16, width: 16}} className='ms-2'/> : 'Get Class'}
                    </button>
                }
            </div>
        )
    }

    const noteRender = (rowData) => {
        return (
            <>
              {rowData.noteDescription 
                ? <>
                    {rowData.noteDescription?.includes('<br>') ?
                        <div dangerouslySetInnerHTML={{ __html: rowData.noteDescription }} />
                        : <span>{rowData.noteDescription}</span>
                    }
                </>
                : null
              }
            </>
        )
    }

    const descriptionRender = (rowData) => {
        return (
            <div className='row'>
                <div className='col-12'>
                    {rowData.itemDescription?.includes('<br>') ?
                        <div dangerouslySetInnerHTML={{ __html: rowData.itemDescription}} />
                        : <span>{rowData.itemDescription}</span>
                    }
                </div>
                {rowData.subDescription ? <div className='col-12'>Sub Description: {rowData.subDescription}</div> : null}
            </div>
        )
    }

    const [columns] = useState([
        {title: 'NMFC', field: 'itemNumber',},
        {title: 'Stored', sorting: false, headerStyle: {textAlign: 'center'}, render: storedRender,},
        {title: 'Class', field: 'itemClass', sorting: false, render: classRender, cellStyle: {width: 120, minWidth: 120}, headerStyle: {textAlign: 'center', width: 120, minWidth: 120}},
        {title: 'Group', field: 'groupDescription', cellStyle: {width: 200, minWidth: 200}, headerStyle: {width: 200, minWidth: 200}},
        {title: 'Description', field: 'itemDescription', render: descriptionRender, cellStyle: {width: 300, minWidth: 300}, headerStyle: {width: 300, minWidth: 300}},
        {title: 'Note', field: 'noteDescription', render: noteRender, cellStyle: {width: 300, minWidth: 300}, headerStyle: {width: 300, minWidth: 300}},
    ])

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    const selectNMFCTable = (e, rowData) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                if(rowData?.itemNumber?.length === 9){
                    selectNMFC(rowData)
                }else{
                    setOpenGetClassWarningModal(true)
                }
                
                break;
        }
       
    }

    useEffect(() => {
        if(open){
            if(search){
                setLoading(true)

                if (typeof cancelToken != typeof undefined) {
                    cancelToken.cancel('Operation canceled due to new request.')
                }
                cancelToken = axios.CancelToken.source()

                axios.get(GET_NMFCS, {
                    cancelToken: cancelToken.token,
                    params: {
                        keyword: search
                    }
                }).then(response => {
                    let NMFCS = response.data.data.results?.NMFCCodes.map((codes) => ({...codes, id: uuidv4()}))

                    NMFCS?.forEach((nmfc, index)=> {
                        if(NMFCS?.filter(n => n.itemNumber === nmfc.itemNumber).length > 1){
                            let duplicated = NMFCS?.filter(n => n.itemNumber === nmfc.itemNumber)
                            NMFCS = NMFCS?.filter(n => n.itemNumber !== nmfc.itemNumber)
                            NMFCS?.splice(index + 1, 0, ...duplicated)
                        }
                    })

                    setTableResults(NMFCS)
                    setLoading(false)
                }).catch(error => {
                    if(error?.response?.data?.error?.message === 'Empty classIT credentials.'){
                        setOpenRequireClassITModal(true)
                        setLoading(false)
                    }else{  
                        checkErrorAPICall(error, dispatch, 'NMFC Numbers')
                    }
                    
                })
            }else{
                setTableResults([])
                setLoading(false)
            }
        }else{
            setTimeout(() => {
                setTableResults([])
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, search])

    useEffect(() => {
      if(searchFormik){
        setSearch(searchFormik)
      }
    }, [searchFormik])

    useEffect(() => {
        if(selectedId){
            setTableResults(tableResults.map((r) => {
                if(r.id === selectedId){
                    return ({
                        ...r,
                        loading: true
                    })
                }else{
                    return r
                }
            }))

            const itemSelected = tableResults.find(result => result.id === selectedId)

            axios.get(`${GET_NMFC}/${itemSelected?.itemNumber}`).then(response => {
                let newArray = [...tableResults]
                let index = tableResults.map(result => result.id).indexOf(selectedId)
                let newItems = []

                if(response.data.data.results.subItems.length){
                    if(newArray.filter(result => result.itemNumber === itemSelected.itemNumber).length > 1){
                        newItems = response.data.data.results.subItems.map((subItem) => ({
                            ...itemSelected,
                            itemNumber: `${itemSelected.itemNumber}-${subItem.subNumber}`,
                            itemClass: subItem.subClass,
                            itemDescription: response.data.data.results.itemDescription,
                            subDescription: subItem.subDescription,
                            noteDescription: response.data.data.results.noteDescription,
                            groupDescription: newArray.filter(result => result.itemNumber === itemSelected.itemNumber)[0].groupDescription,
                            id: uuidv4()
                        }))
                    }else{
                        newItems = response.data.data.results.subItems.map((subItem) => ({
                            ...itemSelected,
                            itemNumber: `${itemSelected.itemNumber}-${subItem.subNumber}`,
                            itemClass: subItem.subClass,
                            itemDescription: response.data.data.results.itemDescription,
                            subDescription: subItem.subDescription,
                            noteDescription: response.data.data.results.noteDescription,
                            id: uuidv4()
                        }))
                    }
                }else{
                    const items = tableResults.filter(result => result.itemNumber === itemSelected.itemNumber)
                    index = tableResults.map(result => result.id).indexOf(items[0].id)
                    let subItem = response.data.data.results

                    newItems = items.map((item) => ({
                        ...item,
                        itemNumber: subItem.itemNumber,
                        itemClass: subItem.itemClass,
                        noteDescription: subItem.noteDescription || item.noteDescription,
                        itemDescription: subItem.itemDescription || item.itemDescription,
                    })) 
                }
                
                
                newArray = newArray.filter(result => result.itemNumber !== itemSelected.itemNumber)
                newArray.splice(index, 0, ...newItems)
                setTableResults(newArray)

                setTableResults(newArray.map((r) => {
                    if(r.id === selectedId){
                        return ({
                            ...r,
                            loading: true
                        })
                    }else{
                        return r
                    }
                }))
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'NMFC Number')
            }) 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId])

    return (
        <div>
            {/* <div className="row modal-header p-0 pb-2 mb-5">
                <div className="col-8">
                    <h5 className="modal-title">NMFCs Lookup</h5>
                </div>
                <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                </div>
            </div> */}
            <div className='bg-secondary rounded-top py-2 px-4 mb-4'>
                <div className="row d-flex justify-content-between p-0 border-bottom border-light my-2">
                    <div className="col-8">
                        <h5 className="modal-title">NMFCs Lookup</h5>
                    </div>                
                    <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </div>
                <div className="col-5 col-sm-3 col-md-2 mb-8">
                    <SearchInputToolbar
                        searchTable={search}
                        handleSearch={handleSearch}
                        className="w-100"
                        results={tableResults}
                    />
                </div>
            </div>
            <div className="col-12" style={{maxHeight: window.innerHeight < 550 ? 300 : window.innerHeight < 580 ? 380 : window.innerHeight < 645 ? 450 : 550, overflow: 'scroll'}}>
                <MaterialTable
                    isLoading={loading}
                    data={tableResults}
                    columns={columns as any}
                    style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                    options={{
                        search: false,
                        toolbar: false,
                        thirdSortClick: false,
                        actionsColumnIndex: -1,
                        paging: false,
                        filtering: false,
                        draggable: false,
                        rowStyle: (data, idx) => idx%2 !== 0 ? {
                            backgroundColor: '#f4f4f4',
                        } : {
                            backgroundColor: '#ffff',
                        },
                        headerStyle: { position: 'sticky', top: 0 },
                        searchFieldStyle: {
                            ...stylesMUIInput(fontSize),
                            marginTop: 10,
                            marginBottom: 10,
                            minWidth: '190px'
                        }
                    }}
                    components={{
                        Container: (props) => <Paper className={classes.table} {...props}/>,
                        Row: rowProps => <MTableBodyRowStyled {...rowProps} onRowClick={(e) => selectNMFCTable(e, rowProps.data)} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,
                    }}
                />
            </div>
            <GetClassWarningModal
                open={openGetClassWarningModal}
                setOpen={setOpenGetClassWarningModal}
            />
            <RequireClassITModal
                open={openRequireClassITModal}
                setOpen={setOpenRequireClassITModal}
            />
        </div>
    )
}

export default NMFCTable