import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateInputProps } from '../../pages/quotes-grid/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { useState } from 'react';
import { shortCutsDateInput } from '../../helpers/shortCutsDateInput';

const DateInput = ({data, setData, dateFor, inputForHome}: DateInputProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const useStyles = makeStyles({
        textField: {
            backgroundColor: 'white',
            border: (isNaN(data[dateFor])) ? '1px solid #F1416C !important' : '1px solid #E4E6EF !important',
            borderRadius: '0.475rem',
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "& .MuiOutlinedInput-root": {
                height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
                fontFamily: 'Poppins !important',
                fontSize: 14,
                fontWeight: 500,
                color:"#5E6278 !important",
            },
            "& .MuiIconButton-root": {
                padding: 5
            }
        }
    })
    const [TDown, setTDown] = useState(false)
    const [minusDown, setMinusDown] = useState(false)
    const [numberDown, setNumberDown] = useState('')

    const classes = useStyles()

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns as any}>
                <DatePicker
                    PaperProps={{
                        sx: {
                            "& .MuiPaper-root .Mui-selected":{
                                backgroundColor: "#009EF7 !important",
                            },
                            "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#009EF7 !important",
                                color: 'white !important',
                                fontSize: fontSize !== 13 ? fontSize : 11
                            },
                            "& .MuiButtonBase-root-MuiPickersDay-root":{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: "#a1a5b6",
                                fontSize: 11
                            },
                            "& .MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)": {
                                border: "1px solid #5E6278"
                            },
                            "& .MuiTypography-root": {
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: "#5E6278",
                                fontSize: fontSize !== 13 ? fontSize : 12
                            },
                            "& button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled)": {
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: fontSize !== 13 ? fontSize : 11,
                                color: '#5E6278'
                            },
                            "& .Mui-selected":{
                                color: 'white !important',
                                backgroundColor: "#009EF7 !important",
                            },
                            "& .MuiCalendarPicker-root": {
                                color: "#5E6278", 
                            }
                        },
                    }}
                    value={data[dateFor]}
                    onChange={(newValue) => {
                        if(inputForHome){
                            setData(`date.${dateFor}`, newValue)
                        }else{
                            setData({
                                ...data,
                                [dateFor]: newValue
                            })
                        }
                    }} 
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            className={classes.textField}
                            style={{width: '100%'}}
                            autoComplete='off'
                            onKeyDown={(e) => {
                                shortCutsDateInput(e.key, dateFor, setNumberDown, numberDown, setMinusDown, minusDown, setTDown, TDown, (name, value) => {
                                    setData({
                                        ...data,
                                        [name]: value
                                    })
                                })
                            }}
                            onKeyUp={(e) => {
                                if(e.key === 't'){
                                    setTDown(false)
                                }else if(e.key === '-'){
                                    setMinusDown(false)
                                }
                            }}
                        />
                    }
                    inputFormat="MM/dd/yy"
                    mask='__/__/__'
                />
                {!inputForHome && (isNaN(data[dateFor])) && <small className="text-danger ms-1">Invalid date</small>}
            </LocalizationProvider>
        </>
    )
}

export default DateInput