import { MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useStyles } from '../../LTLandVolumeTLPages/SelectIconStyles'
import HistoryIcon from '@mui/icons-material/History';
import { format } from 'date-fns';
import { HistorySelectProps } from '../../../pages/quotes-grid/models';
import { SelectStyled } from './HistorySelect.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const HistorySelect = ({setFilters, historyFor}: HistorySelectProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({disabled: false, fontSize: fontSize})
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const recentSearches = JSON.parse(localStorage.getItem(historyFor === 'QuotesGrid' ? 'historyQuotesGrid' : historyFor === 'TrackingGrid' ? 'historyTrackingGrid' : historyFor === 'InvoicesGrid' && 'historyInvoicesGrid')) || []

    const menuProps = {
        classes: {
            list: classes.list
        }
    }

    const setSearch = (e) => {
        delete e.target.value.hashId
        delete e.target.value.id
        
        setFilters({
            ...e.target.value,
            from: e.target.value.from ? new Date(e.target.value.from) : null,
            to: e.target.value.to ? new Date(e.target.value.to) : null
        })
    }

    return (
        <div className='d-flex align-items-center justify-content-center'>
            <Tooltip title="History" open={tooltipOpen}>
                <SelectStyled
                    IconComponent={HistoryIcon}
                    className={classes.select}
                    variant='standard'
                    displayEmpty
                    onChange={(e) => setSearch(e)}
                    value=""
                    MenuProps={menuProps}
                    onMouseEnter={() => window.innerWidth > 575 && setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    onOpen={() => setTooltipOpen(false)}
                >
                    {!recentSearches?.length && <MenuItem disabled>No recent searches</MenuItem>}
                    {recentSearches.map((search, index) => {
                        return <MenuItem key={index} value={search}>
                            {historyFor === 'QuotesGrid' && <div>{search.search && `${search.search} - `} {search.from && <><b>From:</b> {format(new Date(search.from), 'MM/dd/yyyy')}, </>} {search.to && <><b>To:</b> {format(new Date(search.to), 'MM/dd/yyyy')} - </>} {<><b>Types:</b> {search?.type?.map((type, i) => <span key={i}>{`${type}${i === search.type.length - 1 ? '' : ', '}`}</span>)}{search.bookedOnly && <b> Booked only</b>}</>}</div>}
                            {historyFor === 'TrackingGrid' && <div>{search.search && `${search.search} - `} {search.from && <><b>From:</b> {format(new Date(search.from), 'MM/dd/yyyy')}, </>} {search.to && <><b>To:</b> {format(new Date(search.to), 'MM/dd/yyyy')} - </>} {<><b>Status:</b> {search?.type?.map((type, i) => <span key={i}>{`${type}${i === search.type.length - 1 ? '' : ', '}`}</span>)}{search?.bol && <span>,<b> BOL #</b>{search?.bol}</span>}</>}</div>}
                            {historyFor === 'InvoicesGrid' && <div>{search?.invoiceNumber && <span><b>INV# </b>{search?.invoiceNumber}, </span>}{search?.BOLNumber && <span><b>BOL #</b>{search?.BOLNumber}, </span>}{search.from && <><b>From:</b> {format(new Date(search.from), 'MM/dd/yyyy')}, </>} {search.to && <><b>To:</b> {format(new Date(search.to), 'MM/dd/yyyy')}, </>} {<><b>Status:</b> {search?.status === 'All' ? 'Paid/Unpaid' : search?.status}</>}</div>}
                        </MenuItem>
                    })}
                </SelectStyled>
            </Tooltip>
        </div>
    )
}

export default HistorySelect