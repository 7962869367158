import MaterialTable from '@material-table/core'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Paper, TablePagination } from '@mui/material';
import { tableStyles } from '../LTLandVolumeTLPages/Tables/TableStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { KTSVG } from '../../../_metronic/helpers';
import EditModalSL from './Modals/EditOrAddModal';
import EditModalSI from '../ShippingItemsPage/EditOrAddModal'
import DeleteSLModal from './Modals/DeleteSLModal';
import ActivateModal from './Modals/ActivateModal';
import { TableProps } from '../../pages/shipping-locations/models';
import DeleteVendorModal from '../VendorsPage/DeleteVendorModal';
import EditModalVendors from '../VendorsPage/EditModal/EditModal';
import axios from 'axios';
import { RootState } from '../../../setup';
import { useSelector } from 'react-redux';
import { columnsModel } from '../PagesModels';
import DeleteSIModal from '../ShippingItemsPage/DeleteSIModal';
import { MTableBodyRowStyled } from '../QuotesGridPage/Table/Table.styled';
import { formatPhone } from '../../helpers/formatPhone';

const GET_VENDOR = '/api/v1/database/vendor'

const Table = ({loading, setLoading, columns, results, pageNumber, setPageNumber, rowsPerPage, setRowsPerPage, totalResults, getData, tableFor, forModal, setShippingLocation, setNewValuesWhenRateCleared, setOpenClearRateToEditModal, formikValues, setOpen, formFor}: TableProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    let classes = tableStyles()
    const [openSLEditModal, setOpenSLEditModal] = useState(false)
    const [openSIEditModal, setOpenSIEditModal] = useState(false)
    const [openVendorsEditModal, setOpenVendorsEditModal] = useState(false)
    const [openDeleteSLModal, setOpenDeleteSLModal] = useState(false)
    const [openDeleteSIModal, setOpenDeleteSIModal] = useState(false)
    const [openDeleteVendorModal, setOpenDeleteVendorModal] = useState(false)
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const [rowData, setRowData] = useState({})

    const openShipment = (e, rowData) => {
        switch (e.detail) {
          case 1:
            break;
          case 2:
            if(formikValues?.values?.carrierInformation?.name && (rowData?.zipcode?.toString() && formikValues?.values[formFor]?.zipcode?.toString() !== rowData?.zipcode?.toString())){
                setOpenClearRateToEditModal(true)
                setNewValuesWhenRateCleared({
                    valuesFor: `shippingLocation ${formFor}`,
                    values: {
                        name: rowData?.name || "",
                        address: rowData?.address || "",
                        address1: rowData?.address1 || "",
                        phone: formatPhone(rowData?.phone, formikValues.values[formFor]?.country) || "",
                        contact: rowData?.contact || "",
                        contactPhone: formatPhone(rowData?.contactPhone, formikValues.values[formFor].country) || "",
                        emails: rowData?.email?.includes(';') ? rowData?.email?.split(';') : rowData?.email?.split() ? rowData?.email?.split(', ') : [] || "",
                        id: rowData?.id || "",
                        city: rowData?.city || "",
                        country: rowData?.country || "US",
                        state: rowData?.state || "",
                        zipcode: rowData?.zipcode || ""
                    }
                })
                setOpen(false)
            }else{
                setShippingLocation(rowData)
            }
            
            break;
        }
      }

    return (
        <>
            <MaterialTable
                isLoading={loading}
                style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                columns={columns as columnsModel}
                data={results}
                options={{
                    thirdSortClick: false,
                    actionsColumnIndex: -1,
                    toolbar: false,
                    draggable: false,
                    filtering: false,
                    rowStyle: (data, idx) => idx%2 !== 0 ? {
                        backgroundColor: '#f4f4f4'
                    } : {
                        backgroundColor: '#ffff',
                    },
                    pageSizeOptions: [10,25,50,100],
                    pageSize: rowsPerPage,
                    maxBodyHeight: fontSize >= 13 ? `${window.innerHeight - 355}px` : fontSize === 9 ? `${window.innerHeight - 330}px` : `${window.innerHeight - 340}px`,
                    headerStyle: {                        
                        backgroundColor: "#eceff4"
                    },
                }}
                actions={!forModal ? [
                    tableFor !== 'Vendors' ? {
                        icon: () => <EditIcon style={{color: '#009EF7', width: 21, height: 21}}/>,
                        tooltip: 'Edit',
                        onClick: (event, rowData: any) => {
                            setRowData(rowData)
                            tableFor === 'ShippingLocations' ? setOpenSLEditModal(true) : setOpenSIEditModal(true)
                        }
                    } : {
                        icon: () => <EditIcon style={{color: '#009EF7', width: 21, height: 21}}/>,
                        tooltip: 'Edit',
                        onClick: (event, rowData: any) => {
                            setLoading(true)
                            axios.get(`${GET_VENDOR}/${rowData.id}`)
                            .then((results) => {
                                setLoading(false)
                                setRowData(results.data.data.results)
                                setOpenVendorsEditModal(true)
                            })
                        }
                    },
                    tableFor === 'ShippingLocations' ? 
                    (rowData: any) => ({
                        icon: () => rowData?.active ? <DeleteIcon style={{color: '#F1416C', width: 21, height: 21}}/> : <KTSVG className="svg-icon svg-icon-1 mt-1" style={{color: '#47BE7D'}} path='/media/icons/bootstrap/arrow-counterclockwise.svg'/>,
                        tooltip: rowData?.active ? 'Delete' : 'Activate',
                        onClick: (event, rowData: any) => {
                            rowData?.active
                            ? setOpenDeleteSLModal(true)
                            : setOpenActivateModal(true)
                            setRowData(rowData)
                        }
                    }) : null,
                    tableFor === 'ShippingItems' ? 
                    (rowData: any) => ({
                        icon: () => <DeleteIcon style={{color: '#F1416C', width: 21, height: 21}}/>,
                        tooltip: 'Delete',
                        onClick: (event, rowData: any) => {
                            setRowData(rowData)
                            setOpenDeleteSIModal(true)
                        }
                    }) : null,
                    tableFor === 'Vendors' ? {
                        icon: () => <DeleteIcon style={{color: '#F1416C', width: 21, height: 21}}/>,
                        tooltip: 'Delete',
                        onClick: (event, rowData: any) => {
                            setRowData(rowData)
                            setOpenDeleteVendorModal(true)
                        }
                    } : null,
                ] : []}
                localization={{
                    pagination: {
                        labelRows: '',
                        labelRowsPerPage: tableFor === 'ShippingLocations' ? 'Shipping Locations per page:' : tableFor === 'ShippingItems' ? 'Shipping Items per page:' : 'Vendors per page:',
                    },
                    body:{ emptyDataSourceMessage:<p style={{marginTop:'60px', textAlign:'center', fontSize: 13}}>No records to display</p> }
                }}
                onPageChange={(page) => setPageNumber(page)}
                onRowsPerPageChange={(number) => setRowsPerPage(number)}
                components={{
                    Container: (props) => <Paper className={classes.tableQuotesGrid} {...props} />,
                    Row: rowProps => <MTableBodyRowStyled {...rowProps} onRowClick={(e) => tableFor === 'ShippingLocations' && openShipment(e, rowProps.data)} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")}/>,
                    Pagination: props => <TablePagination {...props} count={totalResults} page={pageNumber} onPageChange={(e, page) => setPageNumber(page)}/>
                }}
            />
            <DeleteVendorModal
                open={openDeleteVendorModal}
                setOpen={setOpenDeleteVendorModal}
                rowSelected={rowData}
                getData={getData}
            />
            <DeleteSLModal
                open={openDeleteSLModal}
                setOpen={setOpenDeleteSLModal}
                rowSelected={rowData}
                getData={getData}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                results={results}
            />
            <DeleteSIModal
                open={openDeleteSIModal}
                setOpen={setOpenDeleteSIModal}
                rowSelected={rowData}
                getData={getData}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                results={results}
            />
            <ActivateModal
                open={openActivateModal}
                setOpen={setOpenActivateModal}
                rowSelected={rowData}
                getData={getData}
            />
            <EditModalSL
                open={openSLEditModal}
                setOpen={setOpenSLEditModal}
                rowSelected={rowData}
                setRowSelected={setRowData}
                getData={getData}
                addOrEdit='edit'
            />
            <EditModalSI
                open={openSIEditModal}
                setOpen={setOpenSIEditModal}
                rowSelected={rowData}
                setRowSelected={setRowData}
                getData={getData}
                addOrEdit='edit'
            />
            <EditModalVendors
                open={openVendorsEditModal}
                setOpen={setOpenVendorsEditModal}
                rowSelected={rowData}
                setRowSelected={setRowData}
                getData={getData}
            />
        </>
    )
}

export default Table