import React, {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../_metronic/helpers'
import axios from 'axios';
import { format } from 'date-fns';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { useDispatch } from 'react-redux';
import { isTokenExp } from '../../helpers/isTokenExp';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TypeOptions } from '../../data/TypeOptions';
import { CountryOptions } from '../../data/CountryOptions';
import moment from 'moment';
import { DivStyled } from './Search.styled';

const GET_BOOKINGS = '/applet/v1/book'
const GET_INVOICES = '/applet/v1/invoice'
const GET_BOOk = '/applet/v1/book'

let cancelToken

const Search: FC = () => {
  const [searchInput, setSearchInput] = useState('')
  const [bookings, setBookings] = useState([])
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const bookingsElement = useRef<HTMLDivElement | null>(null)
  const invoicesElement = useRef<HTMLDivElement | null>(null)
  const toolbarElement = useRef<HTMLDivElement | null>(null)
  const clearElement = useRef<HTMLDivElement | null>(null)
  const spinnerElement = useRef<HTMLDivElement | null>(null)
  const content = useRef<HTMLDivElement | null>(null)

  const handleSearchChange = (e) => {
    const value = e.target.value
    const regex = /^[A-Za-z0-9 ]*$/
    if(value.match(regex) || value === ""){
      setSearchInput(e.target.value)
    }
  }

  const getDataCall = async (params, url, callFor) => {
    try {
      const results = await axios.get(url, {
        cancelToken: cancelToken.token,
        params
      })
      if(callFor === 'Bookings'){
        setBookings(results.data.data.results)
      }else{
        setInvoices(results.data.data.results)
      }
    } catch (error) {
      setLoading(false)
      if(error.message === 'Network Error') {
        dispatch({type: actionTypes.SetAxiosErrors, payload: `Network Error on ${callFor}, check your internet connection and try again`})
      }else{
        if(error?.response?.data?.error?.message.length){
          if(error?.response?.status === 401){
            isTokenExp()
          }else{
            dispatch({type: actionTypes.SetAxiosErrors, payload: error?.response?.data?.error?.message})
          }
        }
      }
    }

    setLoading(false)
  }

  const selectBOL = (result) => {
    let bookCall = {} as any
    let book = result
    
    axios.get(`${GET_BOOk}/${result.BOLId}`)
    .then((results) => {
      bookCall = results.data.data.results

      let payload = {      
        BOLDocumentURL: bookCall.BOLDocumentURL,
        BOLId: bookCall.BOLId,
        vendorId: bookCall?.vendor?.id,
        vendorSCAC: bookCall?.vendor?.SCAC,
        BOLNumber: book.BOLNumber,
        BOLPrefix: bookCall.BOLPrefix,
        PRONmbr: book.carrierPRO,
        thirdPartyReferenceNumber: book?.billPartyReferenceNumber,
        quoteNumber: bookCall?.accountingInformation?.customerQuoteNumber,
        fromSearch: true,
        additionalInformation: bookCall.additionalInformation,
        brokerInformation: bookCall.brokerInformation,
        specialInstructions: bookCall?.BOLInstructions,
        carrierInformation: {
          name: book.carrierName || '',
          total: parseFloat(book.totalDue) || '',
          transitDays: bookCall.vendor.transitDays || '',
          serviceLevel: book.carrierServiceLevel || '',
          rateRemarks: [],
          iconUrl: bookCall.vendor.icon || '',
          rateId: bookCall?.vendor?.id?.toString(),
        },
        pickupDate: {
          type: bookCall?.pickupInformation?.type === 'PO' ? 'PickupOn' : 'PickupBy',
          date: bookCall?.pickupInformation?.date ? new Date(bookCall?.pickupInformation?.date?.replace(/-/g, '/')) : "",
          from: bookCall?.pickupInformation?.timeFrom ? moment(bookCall?.pickupInformation?.timeFrom, "HH:mm:ss").format("HH:mm") : "",
          to: bookCall?.pickupInformation?.timeTo ? moment(bookCall?.pickupInformation?.timeTo, "HH:mm:ss").format("HH:mm") : ""
        },
        deliveryDate: {
          type: bookCall?.deliveryInformation?.type === 'DO' ? 'DeliveryOn' : 'DeliveryBy',
          date: bookCall?.deliveryInformation?.date ? new Date(bookCall?.deliveryInformation?.date?.replace(/-/g, '/')) : "",
          from: bookCall?.deliveryInformation?.timeFrom ? moment(bookCall?.deliveryInformation?.timeFrom, "HH:mm:ss").format("HH:mm") : "",
          to: bookCall?.deliveryInformation?.timeTo ? moment(bookCall?.deliveryInformation?.timeTo, "HH:mm:ss").format("HH:mm") : ""
        },
        dispatched: book.dispatched,
        accessorials: bookCall.accessorials,
        freightInfoUOM: {
          value: bookCall.UOM,
          oldValue: ''
        },
        freightInfo: bookCall?.freightInfo?.map(freight => {
          return {
            ...freight,
            dimType: TypeOptions.find(type => type.value === freight.dimType),
            L: freight.length || null,
            W: freight.width || null,
            H: freight.height || null,
            weightus: bookCall.UOM === 'US' ? freight?.weight : freight?.weight / 0.45359237,
            Lus: bookCall.UOM === 'US' ? freight?.length : freight?.length / 2.54,
            Wus: bookCall.UOM === 'US' ? freight?.width : freight?.width / 2.54,
            Hus: bookCall.UOM === 'US' ? freight?.height : freight?.height / 2.54,
            class: freight?.class?.toString(),
            each: freight.weightType === 'each' ? 'Each' : 'Total',
          }
        }),
        shipper: {
          address: bookCall.shipper.address1,
          address1: bookCall.shipper.address2,
          country: bookCall?.shipper?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === bookCall?.shipper?.country)?.Code || "US" : bookCall?.shipper?.country,
          city: bookCall?.shipper?.city,
          name: bookCall?.shipper?.name,
          state: bookCall?.shipper?.state,
          zipcode: bookCall?.shipper?.zipCode,
          email: bookCall.shipper?.email,
          phone: bookCall.shipper?.phone,
          contactPhone: bookCall.shipper?.contactPhone,
          contact: bookCall.shipper?.contact,
          referenceNumber: bookCall.shipper?.referenceNumber,
          id: bookCall?.shipper?.id,
        },
        consignee: {
          address: bookCall.consignee.address1,
          address1: bookCall.consignee.address2,
          country: bookCall?.consignee?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === bookCall?.consignee?.country)?.Code || "US" : bookCall?.consignee?.country,
          city: bookCall?.consignee?.city,
          name: bookCall?.consignee?.name,
          state: bookCall?.consignee?.state,
          zipcode: bookCall?.consignee?.zipCode,
          phone: bookCall.consignee?.phone,
          email: bookCall.consignee?.email,
          contactPhone: bookCall.consignee?.contactPhone,
          contact: bookCall.consignee?.contact,
          referenceNumber: bookCall.consignee?.referenceNumber,
          id: bookCall?.shipper?.id,
        },
      }
      
      dispatch({type: actionTypes.SetQuoteValues, payload: []})
      dispatch({type: actionTypes.SetNewBookingValues, payload: payload})
      navigate('/book/new-booking')
    })
  }

  useEffect(() => {    
    SearchComponent.createInsance('#kt_header_search')

    suggestionsElement.current!.classList.add('d-none')

    const regex = /^\d+$/

    if(searchInput.length >= 4){
      setLoading(true)
      
      getDataCall({search: searchInput}, GET_BOOKINGS, 'Bookings')

      if(searchInput.match(regex)){
        getDataCall({invoiceNumber: parseInt(searchInput)}, GET_INVOICES, 'Invoices')
      }

      if(bookings.length < 1 && invoices.length < 1){
        emptyElement.current!.classList.remove('d-none')
      }
    }else if(searchInput.length >= 2 && searchInput.match(regex)){
      setLoading(true)
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.')
      }
      cancelToken = axios.CancelToken.source()

      getDataCall({invoiceNumber: parseInt(searchInput)}, GET_INVOICES, 'Invoices')
      
      if(bookings.length < 1 && invoices.length < 1){
        emptyElement.current!.classList.remove('d-none')
      }
    }else {
      setLoading(false)
      setBookings([])
      setInvoices([])
      emptyElement.current!.classList.add('d-none')
      resultsElement.current!.classList.add('d-none')
      bookingsElement.current!.classList.add('d-none')
      invoicesElement.current!.classList.add('d-none')
    }

    if(searchInput === ''){
      toolbarElement.current!.classList.add('d-none')
    }else{
      toolbarElement.current!.classList.add('d-none')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  useEffect(() => {
    if(searchInput.length >= 4){
      if(bookings.length){
        resultsElement.current!.classList.remove('d-none')
        bookingsElement.current!.classList.remove('d-none')
        emptyElement.current!.classList.add('d-none')
      }else if(invoices.length){
        resultsElement.current!.classList.remove('d-none')
        invoicesElement.current!.classList.remove('d-none')
        emptyElement.current!.classList.add('d-none')
      }else{
        emptyElement.current!.classList.remove('d-none')
        bookingsElement.current!.classList.add('d-none')
        invoicesElement.current!.classList.add('d-none')
        resultsElement.current!.classList.add('d-none')
      }
    }else if(searchInput.length >= 2){
      if(invoices.length){
        resultsElement.current!.classList.remove('d-none')
        invoicesElement.current!.classList.remove('d-none')
        emptyElement.current!.classList.add('d-none')
      }else{
        resultsElement.current!.classList.add('d-none')
        invoicesElement.current!.classList.add('d-none')
        emptyElement.current!.classList.remove('d-none')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, bookings])

  useEffect(() => {
    if(loading === true){
      spinnerElement.current!.classList.remove('d-none')
      clearElement.current!.classList.add('d-none')
    }else{
      spinnerElement.current!.classList.add('d-none')
      if(searchInput === ''){
        clearElement.current!.classList.add('d-none')
      }else{
        clearElement.current!.classList.remove('d-none')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchInput])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          ref={content}
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                onChange={handleSearchChange}
                value={searchInput}
                className='form-control form-control-flush ps-10'
                placeholder='Search...'
                data-kt-search-element='input'
                onKeyPress={(e) => {
                  if(e.key === 'Enter'){
                    e.preventDefault()
                    if(searchInput.length >= 4){
                      navigate('/trackings')
                      dispatch({type: actionTypes.SetTrackingsFilter, payload: {search: searchInput}})
                    }
                  }
                }} 
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
                ref={spinnerElement}
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
                ref={clearElement}
                onClick={() => {
                  setSearchInput('')
                  setBookings([])
                  setInvoices([])
                }}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>

              <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
                ref={toolbarElement}
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
                </div>

                <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2' />
                </div>
              </div>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                <div ref={bookingsElement} className='d-none'>
                  <h3
                    className='fs-5 text-muted m-0 pt-5 pb-5'
                    data-kt-search-element='category-title'
                  >
                    Bookings
                  </h3>
                  {
                    bookings?.map((result, index) => {
                      return (
                        <DivStyled
                          onClick={() => {
                            dispatch({type: actionTypes.SetBookingOrInvoice, payload: result})
                            selectBOL(result)
                          }}
                          key={index}
                          className='row d-flex text-dark align-items-center mb-5'
                        >
                          <div className="col-12">
                            <div className='row'>
                              <div className="col-12">
                                <div className='row align-items-center'>
                                  <span className='fs-6 fw-bold col-9 p-0'>BOL #{result.BOLNumber} {result.carrierPRO && `/ PRO #${result.carrierPRO}`}</span>
                                </div>
                              </div>
                              <div className="col-12 p-0">
                                <span className='fs-7 fw-bold text-muted p-0'>Date: {format(new Date(result.bookDate).setHours(0,0,0,0), 'MM/dd/yy')} {result.originZipcode ? '-' : ''} {} {result.destinationZipcode ? 'to' : ''} {result.destinationZipcode}</span>
                              </div>
                            </div>
                          </div>
                        </DivStyled>
                      )
                    })
                  }
                </div>
                
                <div ref={invoicesElement} className='d-none'>
                  <h3
                    className='fs-5 text-muted m-0 pt-5 pb-5'
                    data-kt-search-element='category-title'
                  >
                    Invoices
                  </h3>
                  {
                    invoices?.map((result, index) => {
                      return (
                        <div
                          onClick={() => dispatch({type: actionTypes.SetBookingOrInvoice, payload: result})}
                          key={index}
                          className='row d-flex text-dark align-items-center mb-5 ms-3'
                        >
                          <div className="col-12">
                            <div className='row'>
                              <div className="col-12">
                                <div className='row align-items-center'>
                                  <span className='fs-6 fw-bold col-9 p-0'>INV# {result.invoiceNumber} / BOL #{result.BOLNumber}</span>
                                  <div className='col-3 pe-9 pe-lg-5 d-flex justify-content-end'>
                                    <IconButton size="small">
                                      <a href={result.url}><KTSVG className="svg-icon svg-icon-2" style={{color: '#0095E8'}} path='/media/icons/bootstrap/file-earmark-pdf-fill.svg'/></a>
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 p-0">
                                <span className='fs-7 fw-bold text-muted p-0'>Date: {format(new Date(result.dueDate).setHours(0,0,0,0), 'MM/dd/yy')} - {result.paid ? 'Paid' : 'Pending'}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              <div className='d-flex flex-stack fw-bold mb-4'>
                <span className='text-muted fs-6 me-2'>Recently Searched:</span>
              </div>

              <div className='scroll-y mh-200px mh-lg-325px'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/electronics/elc004.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      BoomApp by Keenthemes
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#45789</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra001.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      "Kept API Project Meeting
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#84050</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra006.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      "KPI Monitoring App Launch
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#84250</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra002.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      Project Reference FAQ
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#67945</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/communication/com010.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      "FitPro App Development
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#84250</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/finance/fin001.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      Shopix Mobile App
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#45690</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra002.svg'
                        className='svg-icon-2 svg-icon-primary'
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                      "Landing UI Design" Launch
                    </a>
                    <span className='fs-7 text-muted fw-bold'>#24005</span>
                  </div>
                </div>
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No results found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='attachment'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bolder btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>Users</span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bolder btn-primary'>Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {Search}
