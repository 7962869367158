import CircleIcon from '@mui/icons-material/Circle';
import { format } from 'date-fns';
import moment from 'moment';

const shipperDetailsRender = (rowData) => {
    return (
      <>
        <div>{rowData.shipperName}</div>
        <div>{rowData.originCity && `${rowData.originCity},`} {rowData.originState} {rowData.originZipcode}</div>
        <div>{rowData.shipperReferenceNumber && `Ref# ${rowData.shipperReferenceNumber}`}</div>
      </>
    )
}

const consigneeDetailsRender = (rowData) => {
    return (
        <>
        <div>{rowData.consigneeName}</div>
        <div>{rowData.destinationCity && `${rowData.destinationCity},`} {rowData.destinationState} {rowData.destinationZipcode}</div>
        <div>{rowData.consigneeReferenceNumber && `Ref# ${rowData.consigneeReferenceNumber}`}</div>
        </>
    )
}

const weightRender = (rowData) => {
    return (
        <>
        <div>{rowData.weight}{rowData.UOM ? 'lbs' : 'kgs'}</div>
        </>
    )
}

const invoicedRender = (rowData) => {
    return (
        <div style={{textAlign: 'center'}}>
        <CircleIcon style={{color: rowData.invoiced ? '#47BE7D' : '#F1416C'}}/>
        </div>
    )
}

const dispatchedRender = (rowData) => {
    return (
        <div style={{textAlign: 'center'}}>
        <CircleIcon style={{color: rowData.dispatched ? '#47BE7D' : '#F1416C'}}/>
        </div>
    )
}

const dateRender = (rowData, dateFor) => {
    return (
        <>
            <div className='d-flex align-items-center'>
                {rowData[dateFor] ? moment(rowData[dateFor] || null).format('MM/DD/YY') : ''} 
            </div>
        </> 
    )
}

const createdRender = (rowData) => {
    return format(new Date(rowData.bookDate || null), 'MM/dd/yy')
}

function insertAtIndex(str, substring, index) {
    return str.slice(0, index) + substring + str.slice(index);
}

const totalRender = (rowData) => {
    return rowData?.totalDue?.includes('*') ? <div style={{fontStyle: 'italic'}}>{insertAtIndex(rowData?.totalDue, '$', 2)}</div> : rowData?.totalDue !== 'N/A' ? `$${rowData?.totalDue}` : rowData?.totalDue
}

export const columnsTrackingGrid = [
    { title: 'BOL#', field: 'BOLNumber', hidden: false},
    { title: 'Mode', field: 'shipmentMode', hidden: true},
    { title: 'Created', field: 'bookDate', render: createdRender, type: 'date', dateSetting: { locale: "en-US" }, hidden: true},
    { title: 'Est. PU', field: 'estimatedPickupDate', render: rowData => dateRender(rowData, 'estimatedPickupDate'), hidden: false, cellStyle: {width: 50, minWidth: 50, maxWidth: 50}},
    { title: 'Est. DEL', field: 'estimatedDeliveryDate', render: rowData => dateRender(rowData, 'estimatedDeliveryDate'), hidden: false, cellStyle: {width: 50, minWidth: 50, maxWidth: 50}},
    { title: 'Act. PU', field: 'actualPickupDate', render: rowData => dateRender(rowData, 'actualPickupDate'), hidden: true, cellStyle: {width: 50, minWidth: 50, maxWidth: 50}},
    { title: 'Act. DEL', field: 'actualDeliveryDate', render: rowData => dateRender(rowData, 'actualDeliveryDate'), hidden: true, cellStyle: {width: 50, minWidth: 50, maxWidth: 50}},
    { title: 'Shipper Name', field: 'shipperName', hidden: false, cellStyle: {width: 160, minWidth: 160}},
    { title: 'Shipper Details', field: 'shipperDetails', render: shipperDetailsRender ,hidden: true, hideInSelect: true},
    { title: 'Shipper City', field: 'originCity', hidden: true, hideInSelect: true},
    { title: 'Shipper State', field: 'originState', hidden: true, hideInSelect: true},
    { title: 'Shipper Zipcode', field: 'originZipcode', hidden: true, hideInSelect: true},
    { title: 'Shipper Ref#', field: 'shipperReferenceNumber', hidden: true, hideInSelect: true},
    { title: 'Consignee Name', field: 'consigneeName', hidden: false, cellStyle: {width: 160, minWidth: 160}},
    { title: 'Consignee Details', field: 'consigneeDetails', render: consigneeDetailsRender ,hidden: true, hideInSelect: true},
    { title: 'Consignee City', field: 'destinationCity', hidden: true, hideInSelect: true},
    { title: 'Consignee State', field: 'destinationState', hidden: true, hideInSelect: true},
    { title: 'Consignee Zipcode', field: 'destinationZipcode', hidden: true, hideInSelect: true},
    { title: 'Consignee Ref#', field: 'consigneeReferenceNumber', hidden: true, hideInSelect: true},
    { title: 'Pieces', field: 'pieces', hidden: true},
    { title: 'Weight', field: 'weight', render: weightRender, hidden: true},
    { title: 'Class', field: 'class', hidden: true},
    { title: 'Carrier', field: 'carrierName', hidden: false, cellStyle: {width: 150, minWidth: 150, maxWidth: 150}},
    { title: 'Service', field: 'carrierServiceLevel', hidden: true, cellStyle: {width: 150, minWidth: 150, maxWidth: 150}},
    { title: 'Carrier Ref#', field: 'carrierPRO', hidden: false, cellStyle: {width: 150, minWidth: 150, maxWidth: 150}},
    { title: 'Quote#', field: 'customerQuoteNumber', hidden: true},
    { title: 'Total', field: 'totalDue', render: totalRender, hidden: false},
    { title: 'Bill To Ref', field: 'billPartyReferenceNumber', hidden: true},
    { title: 'Invoiced', field: 'invoiced', sorting: false, render: invoicedRender, headerStyle: {textAlign: 'center'}, hidden: true},
    { title: 'Dispatched', field: 'dispatched', sorting: false, render: dispatchedRender, headerStyle: {textAlign: 'center'}, hidden: true},
    { title: 'Status', field: 'lastStatus', hidden: false, cellStyle: {width: 150, minWidth: 150, maxWidth: 150}}
]