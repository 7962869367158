import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../_metronic/helpers';
import { Pulse } from '../LTLandVolumeTLPages/Modals/SaveModal.styled';
import { modalStyles } from '../ModalStyles';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModalProps } from '../../pages/track/models';
import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { RootState } from '../../../setup';

const HISTORYTRACKINGS = '/api/v1/track'

const ConfirmationModal = ({open, setOpen, loadingRefresh, hasTrackings, rowData, setHistoryTrackings, setLoadingTable}: ConfirmationModalProps) => {
    const dispatch = useDispatch()
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleCloseModal = () => {
        setOpen(false)
        if(hasTrackings){
            refreshHistoryTracking()
        }
    }

    const refreshHistoryTracking = () => {
        setLoadingTable(true)
        axios.get(`${HISTORYTRACKINGS}/${rowData.BOLId}`).then(results => {
            setHistoryTrackings(results.data.data.results)
            setLoadingTable(false)
          }).catch(error => {
            setLoadingTable(false)
            checkErrorAPICall(error, dispatch, 'History Trackings')
        })
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleCloseModal}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        {
                            loadingRefresh
                            ? <div className="row justify-content-center align-items-center" style={{height: '150px'}}>
                                <div className="col-12 text-center">
                                    <span className='spinner-border text-primary mb-3'/>
                                    <Pulse>Contacting Carrier...</Pulse>
                                </div>
                            </div>
                            : <>
                                <div className="row justify-content-center">
                                    {
                                        hasTrackings
                                        ? <>
                                            <div className="col-12 d-flex justify-content-center">
                                                <KTSVG path='/media/icons/bootstrap/check-circle.svg' className='svg-icon svg-icon-3x text-success'/>
                                            </div>
                                            <div className="col-12 mt-5 d-flex justify-content-center">
                                                <Title>New tracking information found!</Title>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <p style={{fontSize: 16}}>date - remarks</p>
                                            </div>
                                        </>
                                        : <>
                                            <div className="col-12 d-flex justify-content-center">
                                                <KTSVG path='/media/icons/bootstrap/exclamation-circle.svg' className='svg-icon svg-icon-3x text-warning'/>
                                            </div>
                                            <div className="col-12 mt-5 d-flex justify-content-center text-center">
                                                <Title>The Carrier has not provided any new information for this shipment</Title>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="row mt-3 mb-5">
                                    <div className="col-12 d-flex justify-content-center">
                                        <button
                                            className="btn btn-secondary"
                                            onClick={handleCloseModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ConfirmationModal