import clsx from 'clsx'
import {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu, Search} from '../../../partials'
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { Tooltip } from '@mui/material';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      <div className={clsx('d-flex align-items-stretch')}>
        <Search />
      </div>
    
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com006.svg'
            className={'svg-icon-3x'}
            style={{filter: 'invert(60%) sepia(88%) saturate(5278%) hue-rotate(178deg) brightness(105%) contrast(100%)'}}
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <Tooltip title='Log Out'>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            onClick={() => logout()}
          >
            <LogoutIcon
              className={toolbarButtonIconSizeClass}
              style={{
                color: '#009EF7',
                height: 22.75,
                width: 22.75
              }}
            />
          </div>
        </Tooltip>
      </div>

    </div>
  )
}

export {Topbar}
