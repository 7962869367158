import { actionTypes } from "../modules/auth/redux/PagesRedux"
import { isTokenExp } from "./isTokenExp"

export function checkErrorAPICall(error, dispatch, apiCallTo) {
    if(error.message === 'Network Error') {
        dispatch({type: actionTypes.SetAxiosErrors, payload: `Network Error on ${apiCallTo}, check your internet connection and try again`})
      }else{
        if(error?.response?.data?.error?.message.length){
          if(error?.response?.status === 401){
            isTokenExp()
          }else{
            dispatch({type: actionTypes.SetAxiosErrors, payload: error?.response?.data?.error?.message})
          }
        }
      }
}