import { dollarUSLocale } from '../../../helpers/dollarUSLocale';

const CarrierInformation = ({values, setOpenQuoteBreakdownModal, vendorName, styleForBox}: any) => {
  return (
    <>
        <div className="col-12 mb-3">
            <label className='col-form-label'>Carrier Information</label>
        </div>
        <div className="col-12 mb-3">
            <div className="row">
            <div className={values?.carrierInformation?.iconUrl ? 'col-12 col-md-2 d-flex align-items-center' : 'd-none'}>
                <img alt="icon" src={values?.carrierInformation?.iconUrl} style={{width: 120}}/>
            </div>
            <div className={`${values?.carrierInformation?.iconUrl ? 'col-12 col-md-10' : 'col-12'}`}>
                <div className="row d-flex align-items-end mb-2">
                <div className="col-9">
                    <h4 className='text-dark fw-bolder mb-0'>
                        {values?.carrierInformation?.name}
                    </h4>
                </div>
                <div className="col-3 d-flex justify-content-end align-items-end">
                    <h1
                        className='text-dark fw-bolder mb-0'
                        style={{cursor: values?.carrierInformation?.rateBreakdown?.length ? 'pointer' : 'text'}}
                        onClick={() => values?.carrierInformation?.rateBreakdown?.length && setOpenQuoteBreakdownModal(true)}
                    >
                        {dollarUSLocale.format(parseFloat(values?.carrierInformation?.total)) || '$0.00'}
                    </h1>
                </div>
                </div>
                <div className="row mb-2 pt-1 pb-1 d-flex align-items-center" style={{background: 'rgb(228, 230, 239)', borderRadius: '0.475rem', ...styleForBox}}>
                    <div className="col-4 d-flex align-items-center">
                        <h4 style={{fontSize: 13}} className="mb-0">
                            {values?.carrierInformation?.serviceLevel}
                        </h4>
                    </div>
                    <div className="col-3">
                        <div className='col-form-label p-0'><b style={{color: '#181C32'}}>Transit:</b> {values?.carrierInformation?.transitDays}</div>
                    </div>
                    <div className="col-3">
                        <div className='col-form-label p-0'><b style={{color: '#181C32'}}>Quote Number:</b> {values?.quoteNumber || '-'}</div>
                    </div>
                </div>
                <div className="row">
                    {
                        values?.carrierInformation?.rateRemarks?.length ? values?.carrierInformation?.rateRemarks[0] && 
                            <div className="col-12">
                            <label className='col-form-label p-0'>
                                <b style={{color: '#181C32'}} className='me-2'>Rate remarks:</b>
                                <>
                                    {values?.carrierInformation?.rateRemarks.map(remark => {
                                    if(remark.includes('href') || remark.includes('<br>') || remark.includes('&nbsp;') || remark.includes('span')){
                                        return <div key={remark} dangerouslySetInnerHTML={{ __html: remark }} />
                                    }else{
                                        return <span key={remark}>{remark}</span>
                                    }
                                    })}
                                </>
                            </label>
                            </div>
                        : null
                    }
                </div>
                {vendorName && (
                    <div className="row mt-5">
                        <h2>Vendor: {vendorName}</h2>
                    </div>
                )}
            </div>
            </div>
        </div>
    </>
  )
}

export default CarrierInformation