import React, { useEffect, useState } from 'react'
import { ModalStyled } from '../../Modals.styled'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { modalStyles } from '../../ModalStyles'
import { Backdrop, CircularProgress, Fade, MenuItem, TextField, IconButton } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { stylesMUIInput } from '../../stylesMUIInput'
import { useStyles } from '../../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import { useFormik } from 'formik'
import axios from 'axios'
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall'
import { UploadDocumentProps } from '../../../pages/track/models'
import { FileUploader } from "react-drag-drop-files";
import { SelectStyled } from '../../Inputs.styled'
import { fileIcon } from '../../../helpers/fileIcon'
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

const GET_DOCUMENTS_TYPES = '/applet/v1/document/filetype'
const GET_UPLOAD_DOCUMENT = '/applet/v1/document/upload'
const DOCUMENTS = '/applet/v1/document'

const UploadDocument = ({open, setOpen, selectedRow, setDocuments}: UploadDocumentProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classesModal = modalStyles({fontSize: fontSize})
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any
    const [loading, setLoading] = useState(false)
    const [documentsType, setDocumentsType] = useState([])
    const classes = useStyles({props: {}})
    const dispatch = useDispatch()

    const handleChange = (files) => {
        let filesLocal = [...formik.values.files]
        filesLocal.push(...files)

        formik.setFieldValue('files', filesLocal.map((f) => {
            return ({file: f.file ? f.file : f, type: formik.values.files.find(file => file.id === f.id)?.type || '', id: uuidv4()})
        }))
    };

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            formik.handleReset(initialValues)
        }, 500);
    }

    let callsDid = 0

    const uploadDocument = (values) => {
        setLoading(true)
        const formData = new FormData();

        formData.append('type', documentsType.find(f => f.name === values.type)?.code)
        formData.append('file', values.file)
        formData.append('BOLNumber', tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber)

        axios.post(GET_UPLOAD_DOCUMENT, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(results => {
            callsDid = callsDid + 1
            if(callsDid === formik.values.files.length){
                axios.get(`${DOCUMENTS}/${selectedRow?.BOLId}`).then(results => {
                    setDocuments(results.data.data.results)
                    handleClose()
                    setLoading(false)
                }).catch(error => {
                    handleClose()
                    setLoading(false)
                    checkErrorAPICall(error, dispatch, 'Get Documents')
                })
            } 
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Upload Document')
            setLoading(false)
        })
    }

    const initialValues = {
        files: [],
        callsDid: 0
    }

    const validationSchema = Yup.object().shape({
        files: Yup.array().of(
            Yup.object({
                file: Yup.string(),
                type: Yup.string().required('File type is required')
            })
        )
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            values.files.forEach(file => {
                uploadDocument(file)
            });
        },
    })

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    const getDocumentsType = () => {
        axios.get(GET_DOCUMENTS_TYPES).then(results => {
            setDocumentsType(results.data.data?.results)
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'History Tracking')
        })
    }

    useEffect(() => {
        if(open){
            getDocumentsType()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesModal.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classesModal.paperUploadDocumentsModal} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Upload documents for BOL #{tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber}</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <div className="row mb-5">
                            <div className='mb-5'><FileUploader classes='borderInputFiles iconInputFiles labelInputFiles' handleChange={handleChange} multiple={true} name="file" label='Upload or drop files here' hoverTitle='Drop here'/></div>
                            {
                                formik.values.files?.map((file, index) => {
                                    return (
                                        <>
                                            <div className="col-6 col-md-7 d-flex align-items-center mb-3">
                                                <div className='me-2'>
                                                    {fileIcon(file.file.name)}
                                                </div>
                                                {file.file.name}
                                            </div>
                                            <div className="col-6 col-md-5 mb-3 d-flex align-items-center justify-content-end">
                                                <SelectStyled
                                                    {...formik.getFieldProps(`files[${index}].type`)}
                                                    className={`${(formik.errors?.files?.length > 0 && file.type === '') ? classes.selectError : classes.select} me-2`}
                                                    displayEmpty={true}
                                                    inputProps={{
                                                        IconComponent: KeyboardArrowDownIcon,
                                                        MenuProps: menuProps,
                                                        style: stylesMUIInput(fontSize),
                                                    }}
                                                    renderValue={value => value?.length ? value : 'Select Type'}
                                                    style={{
                                                        color: file.type ? '#5E6278' : '#A1A5B6',
                                                        maxWidth: 188.95
                                                    }}
                                                    fontSize={fontSize}
                                                >
                                                    {documentsType?.map((document) => (
                                                        <MenuItem key={document.code} value={document?.name}>{document?.name}</MenuItem>
                                                    ))}
                                                </SelectStyled>
                                                <IconButton
                                                    onClick={() => {
                                                        formik.setFieldValue('files', formik.values.files?.filter((f) => f.id !== file.id))
                                                    }}
                                                >
                                                    <DeleteIcon style={{color: '#F1416C', width: 21, height: 21}}/>
                                               </IconButton>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className="row mb-3 mt-5">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-secondary me-4"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                                <button
                                    className="btn btn-primary d-flex align-items-center"
                                    onClick={() => formik.handleSubmit()}
                                    disabled={formik.values.files.length ? false : true}
                                >
                                    {loading ? 'Uploading' : 'Upload All'}{loading && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </Fade>
        </ModalStyled>
  )
}

export default UploadDocument