import { format } from "date-fns"

export const normalizeParams = (values, vendorId, mode, selectedUn) => {
    const data = values

    return {
        'rateTypesList[]': mode?.code === 'DRAY' ? 'DRAY' : '',
        vendorId: vendorId,
        originCity: data.shipper.city,
        originState: data.shipper.state,
        originZipcode: data.shipper.zipcode?.trim(),
        originCountry: data.shipper.country,
        destinationCity: data.consignee.city,
        destinationState: data.consignee.state,
        destinationZipcode: data.consignee.zipcode?.trim(),
        destinationCountry: data.consignee.country === 'USA' ? 'US' : data.consignee.country,
        freightInfo: JSON.stringify(
            data.freightInfo.map((freight) => {
                return mode?.shipmentCategory === 'Drayage' ? {
                    qty: parseFloat(freight.qty),
                    weight: parseFloat(freight.weight),
                    weightType: "each",
                    length: 1,
                    width: 1,
                    height: 1,
                    class: 0,
                    dimTypeDrayage: freight.container,
                    hazmat: false,
                    stack: false,
                    overweight: false,
                    reefer: false,
                    residentialDelivery: false,
                    householdGoods: false,
                    liquor: false,
                    storageYard: false,
                    CYDepot: false,
                    UN: freight?.UN,                    
                    UNDescription: selectedUn?.description,
                    UNHAZClass:  selectedUn?.HAZClass,
                    UNPKGGroup: selectedUn?.PKGGroup,
                    UNLabels: selectedUn?.labels,
                } : {
                    qty: parseFloat(freight.qty),
                    weight: parseFloat(freight.weight),
                    weightType: freight.each === "Each" ? "each" : "total",
                    length: parseFloat(freight.L) || 0,
                    width: parseFloat(freight.W) || 0,
                    height: parseFloat(freight.H) || 0,
                    class: parseFloat(freight.class) || 0,
                    hazmat: freight.hazmat,
                    commodity: freight.commodity,
                    dimType: freight.dimType.value,
                    nmfc: freight.nmfc,
                    UN: freight?.UN,                    
                    UNDescription: selectedUn?.description,
                    UNHAZClass:  selectedUn?.HAZClass,
                    UNPKGGroup: selectedUn?.PKGGroup,
                    UNLabels: selectedUn?.labels,
                    stack: (freight.stack === 'No' || !freight.stack) ? false : true,
                    stackAmount: (freight.stack === 'No' || !freight.stack) ? null : parseInt(freight.stack),
                }
            })
        ),
        UOM: data.freightInfoUOM.value,
        'accessorialsList[]': [
            ...data.accessorials.destination.map(acc => acc.code),
            ...data.accessorials.origin.map(acc => acc.code),
            ...data.accessorials.other.map(acc => acc.code)
        ].join('|'),
        pickupDate: format(data.pickupDate.date, "yyyy-MM-dd"),
        commodityInsurance: data?.commodityInsurance,
        insuranceAmount: data?.insuranceAmount,
        insuranceFreight: data?.insuranceFreight,
        insuranceAddOn: data?.insuranceAddOn
    }
}