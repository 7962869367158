import { Alert } from "@mui/material"
import axios from "axios"
import { checkCountry } from "../checkCountry"
import { getCheaper } from "./getCheaper"
import store from "../../../setup/redux/Store"
import { actionTypes } from "../../modules/auth/redux/PagesRedux"
import { createShipmentsBatchBooking } from "../createShipmentsBatchBooking"
import { format } from "date-fns"

const GET_RATES = '/applet/v1/rate'
let errorShowed = false

export const call = async (row, rows, vendor, markups, vendorsToUse, setData, callsDid, allCalls, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers) => {
  let arrayOfIds = []
  rows.forEach((shipment) => {
    if(invalidZipcodes.includes(shipment.ShipperZipcode?.toString()) || invalidZipcodes.includes(shipment.ConsigneeZipcode?.toString())){
      arrayOfIds.push(shipment.id)
    }
  })

  if(row.progress !== 100 && !arrayOfIds.includes(row.id) && row.ShipperCity && row.ShipperState && row.ShipperZipcode){
    await axios.get(GET_RATES, {
      cancelToken: source.token,
      params: {
        vendorId: vendor.id || "",
        originZipcode: row.ShipperZipcode || "",
        originCity: row.ShipperCity || "",
        originCountry: checkCountry(row.ShipperZipcode) || "",
        originState: row.ShipperState || "",
        destinationZipcode: row.ConsigneeZipcode || "",
        destinationCity: row.ConsigneeCity || "",
        destinationCountry: checkCountry(row.ConsigneeZipcode) || "",
        destinationState: row.ConsigneeState || "",
        UOM: 'US',
        pickupDate: row.PickupDate ? format(new Date(row.PickupDate), 'yyyy-MM-dd') ? format(new Date(row.PickupDate), 'yyyy-MM-dd') : '' : '',
        freightInfo: row.FreightInfo ? JSON.stringify(row.FreightInfo.map(freight => {
          return freight.Class === "" ? {
            qty: freight.Pieces,
            weight: freight.Weight,
            weightType: 'total',
            length: freight.Length,
            width: freight.Width,
            height: freight.Height,
            hazmat: (["y", "yes"].indexOf(freight.Hazmat?.toLowerCase()) > -1) ? true : false,
            nmfc: freight.NMFC,
            commodity: freight.Commodity,
          } : {
            qty: freight.Pieces,
            weight: freight.Weight,
            class: freight.Class,
            weightType: 'total',
            length: freight.Length,
            width: freight.Width,
            height: freight.Height,
            hazmat: (["y", "yes"].indexOf(freight.Hazmat?.toLowerCase()) > -1) ? true : false,
            nmfc: freight.NMFC,
            commodity: freight.Commodity,
          }
          })) : JSON.stringify([
            row.Class === "" ? {
              qty: row.Pieces,
              weight: row.Weight,
              weightType: 'total',
              length: row.Length,
              width: row.Width,
              height: row.Height,
              hazmat: (["y", "yes"].indexOf(row.Hazmat?.toLowerCase()) > -1) ? true : false,
              nmfc: row.NMFC,
              commodity: row.Commodity,
            } : {
              qty: row.Pieces,
              weight: row.Weight,
              class: row.Class,
              weightType: 'total',
              length: row.Length,
              width: row.Width,
              height: row.Height,
              hazmat: (["y", "yes"].indexOf(row.Hazmat?.toLowerCase()) > -1) ? true : false,
              nmfc: row.NMFC,
              commodity: row.Commodity,
            }
          ]) || [],
        'accessorialsList[]': row.Accessorials?.join('|')
      }
    }).then(response => {
      callsDid.push(vendor.name)

      if(allCalls.length > 500){
        if(
          callsDid?.filter(callDid => callDid === vendor.name).length !== 0
          && callsDid?.filter(callDid => callDid === vendor.name).length + rowsWithInvalidZipcode.length === allCalls?.filter(singleCall => singleCall.vendor.name === vendor.name).length
        ){
          enqueueSnackbar(
            'This is a success message!',
            {
              autoHideDuration: 4000,
              variant: 'success',
              content: (key) => (
                <Alert onClose={() => closeSnackbar(key)} variant='filled' severity={"success"} sx={{ fontSize: 11, fontFamily: 'Poppins', width: '100%', alignItems: 'center', backgroundColor: '#43a047'}}>
                  {vendor.name} Processed
                </Alert>
              ),
            }
          )
        }
      }

      let index2 = rows.findIndex((a) => a.id === row.id)
      
      let newArray = [...rows]
      newArray[index2].Rates = [...newArray[index2].Rates || [], ...response.data?.data?.results?.filter(result => !('error' in result))]

      let sum = (newArray[index2].progress || 0) + (100 / vendorsToUse.length)
      newArray[index2].progress = sum >= 99 ? 100 : sum

      let arrayOfProgresses = progress 
      let item = {id: newArray[index2].id, progress: newArray[index2].progress}
      arrayOfProgresses.push(item)

      let newProgress = []
      let uniqueObject = {};
      let objId = ''
  
      for (let i in arrayOfProgresses) {
        objId = arrayOfProgresses[i]['id'];

        uniqueObject[objId] = arrayOfProgresses[i];
      }
  
      for (let i in uniqueObject) {
        newProgress.push(uniqueObject[i]);
      }

      progress = newProgress
      store.dispatch({type: actionTypes.SetProgress, payload: progress})

      ///////////////////////////////////////////////
      if(newArray[index2].Rates.length){
        getCheaper(newArray, index2, markups)
      }else{
        if(newArray[index2].progress >= 99){
          newArray[index2].Carrier = 'No rates'
        }
      }
      let arrayOfCarriers = carriers 

      let carrier = {id: newArray[index2].id, rate: {
        Rate: newArray[index2].Rate,
        Carrier: newArray[index2].Carrier,
        Transit: newArray[index2].Transit,
        Total: newArray[index2].Total
      }}
      
      arrayOfCarriers.push(carrier)
      let newCarriers = []
      let uniqueObject2 = {}
      let objId2 = ''
  
      for (let i in arrayOfCarriers) {
        objId2 = arrayOfCarriers[i]['id'];
  
        uniqueObject2[objId2] = arrayOfCarriers[i];
      }
  
      for (let i in uniqueObject2) {
        newCarriers.push(uniqueObject2[i]);
      }
      carriers = newCarriers

      store.dispatch({type: actionTypes.SetCheaperCarriers, payload: carriers})

      if(progress.map(pr => pr.progress).find(n => n < 100) ? false : true){
        handleDataOnChange(newArray)
      }

      let rowData = newArray[index2]
      if(newArray[index2].progress === 100){
        createShipmentsBatchBooking(rowData, newArray, index2, handleDataOnChange)
      }
    }).catch(error => {
      let index2 = rows.findIndex((a) => a.id === row.id)

      if(!axios.isCancel(error)){
        callsDid.push(vendor.name)
        let newArray = [...rows]
        
        let sum = (newArray[index2].progress || 0) + (100 / vendorsToUse.length)
        newArray[index2].progress = sum >= 99 ? 100 : sum

        let arrayOfProgresses = progress 
        let item = {id: newArray[index2].id, progress: newArray[index2].progress}
        arrayOfProgresses.push(item)

        let newProgress = []
        let uniqueObject = {};
        let objId = ''
    
        for (let i in arrayOfProgresses) {
          objId = arrayOfProgresses[i]['id'];

          uniqueObject[objId] = arrayOfProgresses[i];
        }
    
        for (let i in uniqueObject) {
          newProgress.push(uniqueObject[i]);
        }

        progress = newProgress
        store.dispatch({type: actionTypes.SetProgress, payload: progress})

        if(progress.map(pr => pr.progress).find(n => n < 100) ? false : true){
          handleDataOnChange(newArray)
        }

        let rowData = newArray[index2]
        if(newArray[index2].progress === 100){
          createShipmentsBatchBooking(rowData, newArray, index2, handleDataOnChange)
        }
      }else{
        setLoadingRates(false)
      }

      if(error.message === 'Network Error' && !errorShowed){
        errorShowed = true
        enqueueSnackbar(
          'This is a network Error!',
          {
            autoHideDuration: 4000,
            content: (key) => (
              <Alert onClose={() => closeSnackbar(key)} variant='filled' severity={"error"} sx={{ fontSize: 11, fontFamily: 'Poppins', width: '100%', alignItems: 'center', backgroundColor: '#E42E5B'}}>
                Network Error on Get Rates, check your internet connection and try again
              </Alert>
            ),
          }
        )
      }
    })
  }
}