import { useEffect, useState } from 'react'
import Toolbar from '../ShippingLocationsPage/Toolbar'
import Table from '../ShippingLocationsPage/Table'
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'
import { useDispatch } from 'react-redux'

let cancelToken
const GET_SHIPPINGLOCATIONS = '/api/v1/database/shippinglocation'
interface props {
    pageNumberProp?: number
}

const SLTable = ({setShippingLocation, open, setOpen, setOpenClearRateToEditModal, setNewValuesWhenRateCleared, formikValues, formFor, handleClose, notStylized, data}) => {
    const dispatch = useDispatch()
    const [totalResults, setTotalResults] = useState(0)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [search, setSearch] = useState('')
    const [pageNumber, setPageNumber] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [columns, setColumns] = useState([
        { title: 'Code', field: 'code'},
        { title: 'Name', field: 'name'},
        { title: 'Address', field: 'address'},
        { title: 'City', field: 'city'},
        { title: 'State', field: 'state'},
        { title: 'Zipcode', field: 'zipcode'},
    ])

    const getShippingLocations = (props: props) => {
        setLoading(true)
    
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.')
        }
    
        cancelToken = axios.CancelToken.source()
    
        axios.get(GET_SHIPPINGLOCATIONS, {
          cancelToken: cancelToken.token,
          params: {
            page: pageNumber + 1,
            limit: rowsPerPage,
            search: search.trim(),
            country: data?.Country,
          }
        }).then((results) => {
          setResults(results.data.data.results)
          setTotalResults(results.data.data.pagingDetails.totalResults)
          setLoading(false)
        }).catch(error => {
          checkErrorAPICall(error, dispatch, 'Shipping Locations')
        })
    }


    useEffect(() => {
        if(open){
            getShippingLocations({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, pageNumber, search, rowsPerPage])

    return (
        <>
        <div className='bg-secondary rounded-top py-2 px-4 mb-4'>
            <div className="row d-flex justify-content-between p-0 border-bottom border-light my-2">
                <div className="col-8">
                    <h5 className="modal-title">Shipping Locations Lookup</h5>
                </div>                
                <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                    />
                </div>
            </div>
            <Toolbar
              search={search}
              setSearch={setSearch}
              columns={columns}
              setColumns={setColumns}
              results={results}
              setPageNumber={setPageNumber}
              setLoading={setLoading}
              tableFor='ShippingLocations'
              getData={getShippingLocations}
              forModal={false}
              notStylized={notStylized}
            />
        </div>
            <Table
              loading={loading}
              columns={columns}
              results={results}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalResults={totalResults}
              tableFor='ShippingLocations'
              getData={getShippingLocations}
              forModal={true}
              setShippingLocation={setShippingLocation}
              setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
              setOpenClearRateToEditModal={setOpenClearRateToEditModal}
              formikValues={formikValues}
              setOpen={setOpen}
              formFor={formFor}
            />
        </>
    )
}

export default SLTable