import { EquipmentsProps } from "../../../../pages/vendors/models"

const Equipments = ({truckTypes, equipmentsSelected, setEquipmentsSelected}: EquipmentsProps) => {
    return (
        <>
            <div className="row">
                {
                    truckTypes.map((type) => {
                        return (
                            <div className="col-4 mb-6 d-flex align-items-center" key={type.name}>
                                <div className="form-check form-check-custom form-check-solid form-check-sm">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={type.name}
                                        id={type.name}
                                        checked={equipmentsSelected?.filter(e => e.id === type.id).length > 0}
                                        onChange={(e) => {
                                            if(e.target.checked){
                                                setEquipmentsSelected([...equipmentsSelected, {id: type.id}])
                                            }else{
                                                setEquipmentsSelected(equipmentsSelected?.filter(e => e.id !== type.id))
                                            }
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor={type.name}>
                                        {type.name.toUpperCase()}
                                    </label>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
        </>
    )
}

export default Equipments