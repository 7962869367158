import { useEffect, useState } from 'react'
import ZipcodesWithCountry from '../LTLandVolumeTLPages/ZipcodesWithCountry'
import PhoneInput from '../ShippingLocationsPage/PhoneInput'
import SearchShippingLocation from './SearchShippingLocation'
import { v4 as uuidv4 } from 'uuid';
import { CountryOptions } from '../../data/CountryOptions';
import { Autocomplete, CircularProgress, InputAdornment, TextField, Tooltip } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { OriginAndDestinationProps } from '../../pages/book/models';
import { ReactMultiEmailStyled2 } from './OriginAndDestination.styled';
import { ChipStyled } from '../LTLandVolumeTLPages/AccesorialsComponent/Accesorials.styled';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { RootState } from '../../../setup/redux/RootReducer';
import { formatPhoneUSA } from '../../helpers/formatPhoneUSA';
import { clearDialCode } from '../../helpers/clearDialCode';
import { InputStyled } from '../Inputs.styled';
import { quoteValuesModel, shippingLocationModel } from '../PagesModels';
import { KTSVG } from '../../../_metronic/helpers';
import { NoOptionsText, PaperStyled } from '../LTLandVolumeTLPages/LTLAndVolumeTLPages.styled';
import { makeStyles } from '@mui/styles';

let cancelToken
const SHIPPINGLOCATION = '/api/v1/database/shippinglocation'
const UNLOCODES = '/api/v1/database/unlocodes'

const OriginAndDestination = ({formFor, formik, user, disabled, setOpenClearRateToEditModal, newValuesWhenRateCleared, setNewValuesWhenRateCleared, formikDispatch, dispatchShip, mode}: OriginAndDestinationProps) => {
    const resetValues = useSelector<RootState>(({pages}) => pages?.resetValues) as boolean
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const dispatch = useDispatch()
    const [zipcodeLocal, setZipcodeLocal] = useState('')
    const [zipcode, setZipcode] = useState({
        City: '',
        Country: 'US',
        Name: '',
        State: '',
        Zipcode: '',
        isSL: false,
        isZipcode: false,
        isPort: false,
        id: uuidv4(),
    })
    const [shippingLocation, setShippingLocation] = useState({} as shippingLocationModel | any)
    const [shippingLocationLocal, setShippingLocationLocal] = useState('')
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [UNLOCodes, setUNLOCodes] = useState([])
    const [UNLOCodeLocal, setUNLOCodeLocal] = useState('')
    const [loadingUNLOCodes, setLoadingUNLOCodes] = useState(false)
    const [openUNLOCode, setOpenUNLOCode] = useState(false)
    const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
    const quoteValues = useSelector<RootState>(({pages}) => pages.quoteValues) as quoteValuesModel

    const useStylesAutocomplete = makeStyles({
        textField: {
            backgroundColor: 'white',
            border: '1px solid #E4E6EF !important',
            borderRadius: '0.475rem',
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#5E6278"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
            "& .MuiOutlinedInput-root":{
                color: "#5E6278"
            },
            "& .Mui-disabled": {
                backgroundColor: '#EFF2F5',
            }
        },
        height: {
            height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
        }
    })

    const classesAutocomplete = useStylesAutocomplete()
    
    /* const [referenceNumberTypes] = useState(['PO#', 'SO#', 'BOL#', 'HAWB#', "MAWB#", "OTHER"]) */

    /* const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    const addReferenceNumber = () => {
        formik.setFieldValue(`${formFor}.referenceNumber`, [
            ...formik.values[formFor].referenceNumber,
            {
                type: 'PO#',
                number: '',
                id: uuidv4()
            }
        ])
    }

    const deleteReferenceNumber = (id) => {
        formik.setFieldValue(`${formFor}.referenceNumber`, [
            ...formik.values[formFor].referenceNumber.filter(singleRef => singleRef.id !== id)
        ])
    }
    
    const checkProperties = (obj) => {
        let objectCopy = {...obj}

        const arrayReferenceNumbers = objectCopy.referenceNumber?.map(({type, id, ...keepAttrs}) => keepAttrs)
        let isEmptyReferenceNumbers =  false
        arrayReferenceNumbers?.forEach((object) => 
            isEmptyReferenceNumbers = !Object?.values(object)?.some((x: any) => {
                return x !== null && x !== '' && x?.length !== 0
            }
        ))

        delete objectCopy['country']
        delete objectCopy['referenceNumber']

        const isEmpty = !Object?.values(objectCopy)?.some((x: any) => {
            return x !== null && x !== '' && x?.length !== 0
        });

        return isEmpty && isEmptyReferenceNumbers
    } */

    const clearForm = () => {
        setShippingLocation({})
        setShippingLocationLocal('')
        formik.setFieldValue(`${formFor}.name`, "")
        formik.setFieldValue(`${formFor}.address`, "")
        formik.setFieldValue(`${formFor}.address1`, "")
        formik.setFieldValue(`${formFor}.phone`, null)
        formik.setFieldValue(`${formFor}.contact`, "")
        formik.setFieldValue(`${formFor}.contactPhone`, null)
        formik.setFieldValue(`${formFor}.emails`, [])
        formik.setFieldValue(`${formFor}.id`, "")
        if(!formik.values.carrierInformation?.name){
            formik.setFieldValue(`${formFor}.city`, "")
            formik.setFieldValue(`${formFor}.country`, "US")
            formik.setFieldValue(`${formFor}.state`, "")
            formik.setFieldValue(`${formFor}.zipcode`, "")
            setZipcode({
                ...zipcode,
                City: '',
                Country: 'US',
                Name: '',
                State: '',
                Zipcode: '',
            })
            setZipcodeLocal('')
        }

        formik.setFieldValue(`${formFor}.referenceNumber`, '')
    }

    const saveShippingLocation = () => {
        if(formik.errors[formFor]?.phone || formik.errors[formFor]?.contactPhone || formik.errors[formFor]?.emails?.length){
        }else{
            if(shippingLocation?.id){
                setSaving(true)
                editOrAddSL(formik.values[formFor], 'edit')
            }else if(formik.values[formFor].name){
                setSaving(true)
                editOrAddSL(formik.values[formFor], 'add')
            }
        }
    }

    const editOrAddSL = (values, editOrAdd) => {
        if(editOrAdd === 'edit'){
            axios.put(`${SHIPPINGLOCATION}/${values?.id}`, {
                name: values.name,
                address: values.address,
                address1: values.address1,
                phone: values.country === 'CAN' || values.country === 'USA' || values.country === 'US' || values.country === 'CA' ? formatPhoneUSA(values.phone, shippingLocation.country) : clearDialCode(values.phone || null, (shippingLocation.country || 'USA')),
                email: values?.emails?.length ? values?.emails?.join(', ') : "",
                city: values.city,
                state: values.state,
                country: values.country || "US",
                zipcode: values.zipcode,
                contact: values.contact,
                contactPhone: values.country === 'CAN' || values.country === 'USA' || values.country === 'US' || values.country === 'CA' ? formatPhoneUSA(values.contactPhone, shippingLocation.country) : clearDialCode(values.contactPhone|| null, (shippingLocation.country || 'USA')),
            }).then(() => {
                setSaving(false)
                setSaved(true)
                setTimeout(function(){
                    setSaved(false)
                }, 2000);
            }).catch(error => {
                setSaving(false)
                checkErrorAPICall(error, dispatch, 'Quotes')
            })
        }else{
            axios.post(SHIPPINGLOCATION, {
                name: values.name,
                address: values.address,
                address1: values.address1,
                phone: values.country === 'CAN' || values.country === 'USA' || values.country === 'US' || values.country === 'CA' ? formatPhoneUSA(values.phone, shippingLocation.country) : clearDialCode(values.phone || null, (shippingLocation.country || 'USA')),
                email: values?.emails?.length ? values?.emails?.join(', ') : "",
                city: values.city,
                state: values.state,
                country: values.country || "US",
                zipcode: values.zipcode,
                contact: values.contact,
                contactPhone: values.country === 'CAN' || values.country === 'USA' || values.country === 'US' || values.country === 'CA' ? formatPhoneUSA(values.contactPhone, shippingLocation.country) : clearDialCode(values.contactPhone|| null, (shippingLocation.country || 'USA')),
                active: true
            }).then((results) => {
                setSaving(false)
                setSaved(true)
                formik.setFieldValue(`${formFor}.id`, results.data.data.results?.id)
                const result = results.data.data.results

                setShippingLocation({
                    ...shippingLocation,
                    address: result?.address,
                    address1: result?.address1,
                    city: result?.city,
                    contact: result?.contact,
                    contactPhone: result?.contactPhone,
                    country: result?.country,
                    email: result?.email,
                    name: result?.name,
                    phone: result?.phone,
                    state: result?.state,
                    zipcode: result?.zipcode,
                    id: result?.id
                })
                setTimeout(function(){
                    setSaved(false)
                }, 2000);
            }).catch(error => {
                setSaving(false)
                checkErrorAPICall(error, dispatch, 'Quotes')
            })
        }
    }

    let numberOfChips = window.innerWidth >= 1700 ? 4 : window.innerWidth <= 475 ? 1 : 3

    useEffect(() => {
        if(quoteValues.length === 0){
            if(formFor === 'shipper'){
                setShippingLocationLocal(newBookingValues?.shipper?.name)
                setZipcode({
                    City: newBookingValues?.shipper?.city,
                    Country: newBookingValues?.shipper?.country == 'USA' ? 'US' : newBookingValues?.shipper?.country,
                    Name: newBookingValues?.shipper?.name,
                    State: newBookingValues?.shipper?.state,
                    Zipcode: newBookingValues?.shipper?.zipcode,
                    isSL: false,
                    isZipcode: true,
                    isPort: false,
                    id: uuidv4(),
                })
            }else{
                setShippingLocationLocal(newBookingValues?.consignee?.name)
                setZipcode({
                    City: newBookingValues?.consignee?.city,
                    Country: newBookingValues?.consignee?.country == 'USA' ? 'US' : newBookingValues?.consignee?.country,
                    Name: newBookingValues?.consignee?.name,
                    State: newBookingValues?.consignee?.state,
                    Zipcode: newBookingValues?.consignee?.zipcode,
                    isSL: false,
                    isZipcode: newBookingValues?.consignee?.isZipcode,
                    isPort: newBookingValues?.consignee?.isPort,
                    id: uuidv4(),
                })
            }            
            setShippingLocation(formFor === 'shipper'
                ? {...newBookingValues?.shipper, address: newBookingValues?.shipper?.address1, address1: newBookingValues?.shipper?.address2, zipcode: newBookingValues?.shipper?.zipcode}
                : {...newBookingValues?.consignee, address: newBookingValues?.consignee?.address1, address1: newBookingValues?.consignee?.address2, zipcode: newBookingValues?.consignee?.zipcode}
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBookingValues, resetValues])

    useEffect(() => {
        if(UNLOCodes.length > 0 && openUNLOCode === false){
            setUNLOCodeLocal('')
            formik.setFieldValue(`${formFor}.UNLOCode`, {})

            setLoadingUNLOCodes(true)
            formik.setFieldValue(`${formFor}.UNLOCode`, UNLOCodes[0])
            setUNLOCodeLocal(UNLOCodes[0].code)
            setUNLOCodes([])
            setLoadingUNLOCodes(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UNLOCodes])

    useEffect(() => {
        if(zipcode.isPort && quoteValues.length === 0){
            formik.setFieldValue(`shipper`, {
                ...formik.values.shipper,
                country: 'US',
                city: '',
                state: '',
                zipcode: '',
            })
            formik.setFieldValue(`consignee`, {
                ...formik.values.consignee,
                country: 'US',
                city: '',
                name: '',
                state: '',
                zipcode: '',
                isZipcode: false
            })           
            setZipcode({
                ...zipcode,
                City: zipcode.City,
                Country: 'US',
                Name: '',
                State: '',
                Zipcode: '',
                isSL: false,
                isZipcode: false,
                isPort: true,
            })
            // setZipcodeLocal(`${zipcode.City}`)
            // setZipcodeLocal(`${zipcode.Name}`) 
        }
        if(zipcode.isPort && quoteValues.length !== 0) {
            setZipcode({
                ...zipcode,
                City: zipcode.City,
                Country: 'US',
                Name: '',
                State: '',
                Zipcode: '',
                isSL: false,
                isZipcode: false,
                isPort: true,
            })
            // setZipcodeLocal(`${zipcode.City}`)
            // setZipcodeLocal(`${zipcode.Name}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])

    return (
        <div className='row'>
            <div className="col-2">
                <label className='col-form-label lh-1'>Name</label>
            </div>
            <div className="col-10">
                <SearchShippingLocation
                    formik={formik}
                    formFor={formFor}
                    zipcode={zipcode}
                    setZipcode={setZipcode}
                    setZipcodeLocal={setZipcodeLocal}
                    shippingLocation={shippingLocation}
                    setShippingLocation={setShippingLocation}
                    shippingLocationLocal={shippingLocationLocal}
                    setShippingLocationLocal={setShippingLocationLocal}
                    disabled={disabled}
                    error={dispatchShip ? formikDispatch?.errors[formFor]?.name : ''}
                    setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                    newValuesWhenRateCleared={newValuesWhenRateCleared}
                    setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
                />
                {dispatchShip && formikDispatch?.errors[formFor]?.name ? <small className="text-danger ms-2 mt-1">Required</small> : null}
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>Address</label>
            </div>
            <div className="col-10 mt-4">
                <InputStyled
                    type="text"
                    autoComplete='new-password'
                    {...formik.getFieldProps(`${formFor}.address`)}
                    maxLength="120"
                    fontSize={fontSize}
                    className={`form-control ${disabled && 'disabled'}`}
                    disabled={disabled}
                    error={dispatchShip ? formikDispatch?.errors[formFor]?.address : ''}
                    onChange={(e) => {
                        formik.setFieldValue(`${formFor}.address`, e.target.value)
                        if(shippingLocation?.id){
                            setShippingLocation({
                                ...shippingLocation,
                                id: null
                            })
                            formik.setFieldValue(`${formFor}.id`, null)
                        }
                    }}
                />
                {dispatchShip && formikDispatch?.errors[formFor]?.address ? <small className="text-danger ms-2 mt-1">Required</small> : null}
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>Address 2</label>
            </div>
            <div className="col-10 mt-4">
                <InputStyled
                    type="text"
                    className={`form-control ${disabled && 'disabled'}`}
                    autoComplete='new-password'
                    {...formik.getFieldProps(`${formFor}.address1`)}
                    maxLength="120"
                    fontSize={fontSize}
                    disabled={disabled}
                    onChange={(e) => {
                        formik.setFieldValue(`${formFor}.address1`, e.target.value)
                        if(shippingLocation?.id){
                            setShippingLocation({
                                ...shippingLocation,
                                id: null
                            })
                            formik.setFieldValue(`${formFor}.id`, null)
                        }
                    }}
                />
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>C&#8204;ity</label>
            </div>
            <div className="col-10 mt-4">
                <ZipcodesWithCountry
                    valueName={formFor}
                    zipcodeLocal={zipcodeLocal}
                    setZipcodeLocal={setZipcodeLocal}
                    setDataFormik={formik.setFieldValue}
                    data={zipcode}
                    setData={setZipcode}
                    countryOptions={CountryOptions}
                    userData={user}
                    error={formik?.errors[formFor]?.zipcode || formikDispatch?.errors[formFor]?.zipcode}
                    touched={formik?.touched[formFor]?.zipcode || dispatchShip}
                    setFieldTouched={formik.setFieldTouched}
                    forNewBooking={true}
                    formFor={formFor}
                    disabled={disabled}
                    setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                    formikValues={formik.values}
                    mode={mode}
                    setFieldError={formik.setFieldError}
                    shippingLocation={shippingLocation}
                    setShippingLocation={setShippingLocation}
                />
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>Phone</label>
            </div>
            <div className="col-10 mt-4">
                <div className="row">
                    <div className={mode?.shipmentCategory === 'Air' && !formik.values[formFor]?.country?.includes('US') ? 'col-6' : 'col-12'}>
                        <PhoneInput
                            value={formik.values[formFor].phone || ""}
                            setDataFormik={formik.setFieldValue}
                            name={`${formFor}.phone`}
                            countryCode={formik.values[formFor]?.country == 'Port' ? 'US' : formik.values[formFor]?.country || "US"}
                            selectSmall={true}
                            deleteSelectCountry={true}
                            disabled={disabled}
                            error={formik.errors[formFor]?.phone ? formik.errors[formFor]?.phone : (dispatchShip ? formikDispatch?.errors[formFor]?.phone : '')}
                        />
                        {!formik?.errors[formFor]?.phone && (dispatchShip && formikDispatch?.errors[formFor]?.phone) ? <small className="text-danger ms-2 mt-1">Required</small> : null}
                    </div>
                    {
                        mode?.shipmentCategory === 'Air' && !formik.values[formFor]?.country?.includes('US') ?
                        <>
                            <div className="col-2 d-none d-sm-flex">
                                <label className='col-form-label lh-1'>UNLOCode</label>
                            </div>
                            <div className="col-6 col-sm-4">
                                <Autocomplete
                                    disabled={disabled}
                                    autoSelect
                                    autoComplete={false}
                                    autoHighlight={true}
                                    className='dropdownContainer'
                                    getOptionLabel={(option) => option.code || ""}
                                    options={UNLOCodes}
                                    selectOnFocus
                                    handleHomeEndKeys
                                    forcePopupIcon={false}
                                    inputValue={UNLOCodeLocal}
                                    onChange={(event, value) => {
                                        formik.setFieldValue(`${formFor}.UNLOCode`, value)
                                    }}
                                    onInputChange={(e, value) =>  {
                                        setUNLOCodeLocal(value)

                                        if(value?.length){
                                            setLoadingUNLOCodes(true)
                                            if (typeof cancelToken != typeof undefined) {
                                                cancelToken.cancel('Operation canceled due to new request.')
                                            }
                                            
                                            cancelToken = axios.CancelToken.source()
    
                                            axios.get(UNLOCODES, {
                                                cancelToken: cancelToken.token,
                                                params: {
                                                    country: formik.values[formFor]?.country,
                                                    search: value
                                                }
                                            }).then((results) => {
                                                setUNLOCodes(results.data.data.results)
                                                setLoadingUNLOCodes(false)
                                            })
                                        }else{
                                            formik.setFieldValue(`${formFor}.UNLOCode`, {})
                                            setUNLOCodeLocal('')
                                            setUNLOCodes([])
                                        }
                                    }}
                                    clearOnBlur={false}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            return false;
                                        }
                                    }}
                                    renderInput={params => {
                                        return (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                autoComplete='new-password'
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {loadingUNLOCodes ? <div className='d-flex align-items-center'><CircularProgress color="inherit" size={15} /></div> : <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon svg-icon-6' style={{color: '#a1a5b7'}}/>}
                                                        </InputAdornment>
                                                    ),
                                                    className: classesAutocomplete.height,
                                                    style: {fontSize: 14, fontFamily: 'Poppins', fontWeight: 500, paddingRight: 9, color:"#5E6278 !important", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}
                                                }}
                                                className={classesAutocomplete.textField}
                                            />
                                        )
                                    }}
                                    PaperComponent={({ children }) => (
                                        <PaperStyled fontSize={fontSize}>
                                            {children}
                                        </PaperStyled>
                                    )}
                                    noOptionsText={<NoOptionsText>No options</NoOptionsText>}
                                    onOpen={() => {
                                        setOpenUNLOCode(true)
                                        setUNLOCodes([])
                                    }}
                                    onClose={() => {
                                        setLoadingUNLOCodes(false)
                                        setOpenUNLOCode(false)
                                        setUNLOCodes([])
                                    }}
                                />
                            </div>
                        </>
                        : null
                    }
                    
                </div>
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>Contact</label>
            </div>
            <div className="col-10 col-sm-4 mt-4 pe-3 pe-sm-0">
                <InputStyled
                    type="text"
                    className={`form-control ${disabled && 'disabled'}`}
                    autoComplete='off'
                    {...formik.getFieldProps(`${formFor}.contact`)}
                    fontSize={fontSize}
                    disabled={disabled}
                    error={dispatchShip ? formikDispatch?.errors[formFor]?.contact : ''}
                />
                {dispatchShip && formikDispatch?.errors[formFor]?.contact ? <small className="text-danger ms-2 mt-1">Required</small> : null}
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1 ps-0 ps-sm-5'>Phone</label>
            </div>
            <div className="col-10 col-sm-4 mt-4 ps-6 ps-sm-0" style={{paddingLeft: '0px'}}>
                <PhoneInput
                    value={formik.values[formFor]?.contactPhone || ""}
                    setDataFormik={formik.setFieldValue}
                    name={`${formFor}.contactPhone`}
                    countryCode={formik.values[formFor]?.country == 'Port' ? 'US' : formik.values[formFor]?.country || "US"}
                    selectSmall={true}
                    className="ps-0"
                    deleteSelectCountry={true}
                    disabled={disabled}
                    error={formik.errors[formFor]?.contactPhone ? formik.errors[formFor]?.contactPhone : (dispatchShip ? formikDispatch?.errors[formFor]?.contactPhone : '')}
                />
                {!formik?.errors[formFor]?.contactPhone && (dispatchShip && formikDispatch?.errors[formFor]?.contactPhone) ? <small className="text-danger ms-2 mt-1">Required</small> : null}
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>Email</label>
            </div>
            <div className="col-10 mt-4">
                <ReactMultiEmailStyled2
                    disabled={disabled}
                    enable={(emailCnt) => !disabled}
                    propsEmails={formik.values[formFor].emails || []}
                    onChange={(_emails: string[]) => {
                        formik.setFieldValue(`${formFor}.emails`, _emails)
                    }}
                    getLabel={(email, index, removeEmail) => (
                        index === numberOfChips ? '...'  : index > numberOfChips ? null :
                        <ChipStyled
                            chipforemail="true"
                            disabled={disabled}
                            key={index}
                            tabIndex={-1}
                            onDelete={() => {
                                if(!disabled){
                                    removeEmail(index)
                                }
                            }}
                            label={email}
                            className='me-1 mb-1'
                            style={{height: fontSize < 13 && 25}}
                        />
                    )}
                    onFocus={() => disabled && (document?.activeElement as any)?.blur()}
                />
            </div>
            <div className="col-2 mt-4">
                <label className='col-form-label lh-1'>{window.innerWidth < 1225 ? 'Ref#' : 'Reference#'}</label>
            </div>
            <div className='col-10 mt-4'>
                <Tooltip title={formik?.values[formFor]?.referenceNumber || ''}>
                    <InputStyled
                        type="text"
                        className={`form-control ${disabled && 'disabled'}`}
                        autoComplete='off'
                        {...formik.getFieldProps(`${formFor}.referenceNumber`)}
                        maxLength="100"
                        fontSize={fontSize}
                        disabled={disabled}
                    />
                </Tooltip>
                {/* {
                    formik.values[formFor].referenceNumber?.map((singleRef, index) => (
                        <div className={`row d-flex align-items-center ${index !== 0 && 'mt-4'}`} key={index}>
                            <div className="col-4">
                                <TextFieldStyled
                                    disabled={disabled}
                                    select
                                    label=" "
                                    className={`${classes.selectMUI}`}
                                    InputProps={{
                                        style: stylesMUIInput(fontSize),
                                    }}
                                    SelectProps={{
                                        IconComponent: KeyboardArrowDownIcon,
                                        MenuProps: menuProps
                                    }}
                                    InputLabelProps={{shrink: false}}
                                    {...formik.getFieldProps(`${formFor}.referenceNumber[${index}].type`)}
                                >
                                    {
                                        referenceNumberTypes?.map((type, index) => (
                                            <MenuItem key={index} value={type}>{type}</MenuItem>
                                        ))
                                    }
                                </TextFieldStyled>
                            </div>
                            <div className="col-5 col-sm-6">
                                <Tooltip title={formik?.values[formFor]?.referenceNumber[index]?.number || ''}>
                                    <InputStyled
                                        type="text"
                                        className={`form-control ${disabled && 'disabled'}`}
                                        autoComplete='off'
                                        {...formik.getFieldProps(`${formFor}.referenceNumber[${index}].number`)}
                                        maxLength="120"
                                        fontSize={fontSize}
                                        disabled={disabled}
                                    />
                                </Tooltip>
                            </div>
                            <div className="col-3 col-sm-2 d-flex align-items-center">
                                <div onClick={() => !disabled && addReferenceNumber()} style={{cursor: !disabled ? 'pointer' : 'default'}} className="me-2">
                                    <KTSVG path='/media/icons/bootstrap/plus-circle-fill.svg' className='svg-icon svg-icon-1 text-primary'/>
                                </div>
                                {
                                    index > 0 &&
                                    <div onClick={() => !disabled && deleteReferenceNumber(singleRef?.id)} style={{cursor: !disabled ? 'pointer' : 'default'}}>
                                        <KTSVG path='/media/icons/bootstrap/dash-circle-fill.svg' className='svg-icon svg-icon-1 text-danger'/>
                                    </div>
                                }
                            </div>
                        </div>
                    ))
                } */}
            </div>
            <div className='pe-3 ps-3'>
                <hr style={{color: '#a7adce'}} className='mt-4 mb-4'/>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-end">
                <button
                    onClick={() => clearForm()}
                    className='btn btn-secondary me-4'
                    disabled={disabled}
                >
                    Clear
                </button>
                <button
                    className={`btn ${saved ? 'btn-success' : 'btn-primary'} d-flex align-items-center`}
                    style={{
                        cursor: saved ? 'auto' : 'pointer',
                        transition: 'background-color 0.5s ease'
                    }}
                    type='submit'
                    onClick={() => saveShippingLocation()}
                    disabled={!formik.values[formFor].name || disabled}
                >
                    {saving ? 'Saving' : saved ? 'Saved' : (shippingLocation?.id ? 'Update' : 'Create')}{saving && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                </button>
            </div>
        </div>
    )
}

export default OriginAndDestination