import { makeStyles } from "@mui/styles";

export const tableStyles = makeStyles({
    table: {
        "& .selectedRow": {
            backgroundColor: 'red !important',
            background: 'red !important'
        },
        "& .MuiTableCell-root": {
            fontFamily: 'Poppins',
            fontSize: '12px',
            color: "black !important",
            border: 'none',
            padding: '6px 10px !important',
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        "& .Mui-active": {
            color: "black !important",
        },
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            margin: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        },
        "& .MuiTablePagination-root": {
            justifyContent: 'start !important'
        },
    },
    tableOpenClaims: {
        "& .MuiBox-root":{
            height: '300px !important'
        },
        "& .MuiTableCell-root":{
            fontFamily: 'Poppins',
            fontSize: '12px',
            color: "black !important",
            border: 'none',
            padding: '6px 9px',
            position: 'sticky !important'
        },
        "& .MuiTableRow-root": {
            position: 'sticky !important'
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        "& .Mui-active": {
            color: "black !important",
        },
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            marginLeft: 9,
            marginBottom: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiIconButton-root": {
            width: '35px !important',
            height: '35px !important'
        },
        "&:hover .hoverRow":{
            filter: `brightness(96%)`
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        },
        "& .MuiTablePagination-root": {
            justifyContent: 'start !important'
        }
    },
    tableQuotesGrid: {
        "& .MuiTableCell-root":{
            fontFamily: 'Poppins',
            fontSize: '12px',
            color: "black !important",
            border: 'none',
            padding: '6px 9px',
            position: 'sticky !important',
            border: '1px solid #ebebeb'
        },
        "& .MuiTableRow-root": {
            position: 'sticky !important',
            verticalAlign: 'top !important',
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        "& .Mui-active": {
            color: "black !important",
        },
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            marginLeft: 9,
            marginBottom: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiIconButton-root": {
            width: '35px !important',
            height: '35px !important'
        },
        "&:hover .hoverRow":{
            filter: `brightness(96%)`
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        },
        "& .MuiTablePagination-root": {
            justifyContent: 'start !important'
        }
    },
    linearProgress: {
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#009EF7 !important"
        }
    },
    linearProgressFinished: {
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#47BE7C !important"
        }
    }
})