import styled from 'styled-components';

export const Title = styled.div`
    font-size: ${props => `${props.fontSize > 13 && props.fontSize > 15 ? props.fontSize : props.fontSize < 13 ? props.fontSize : 15}px !important`};
    font-weight: 500;
    text-align: center;
`

export const Subtitle = styled.div`
    font-size: ${props => `${props.fontSize === 13 ? 14 : props.fontSize}px !important`};
    text-align: center
`

export const BigSubtitle = styled.div`
    font-size: ${props => `${props.fontSize > 13 && props.fontSize > 14 ? props.fontSize : props.fontSize < 13 ? props.fontSize : 14}px !important`};
    text-align: center
`

export const PaperModal = styled.div`
    visibility: visible;
    opacity: 100%;
    min-width: ${props => `${props.fontSize <= 13 ? '' : props.widthWhenFontBig || ''}px !important`};
    max-width: ${props => `${props.fontSize < 13 ? props.widthWhenFontSmall : '' || ''}px !important`};
`

export const LinkStyled = styled.a`
    color: white
`

export const FooterStyled = styled.div`
    button, a {
        font-size: ${props => `${props.fontSize === 13 ? '' : props.fontSize}px`}
    }
`

export const DivStyled = styled.div`
    h5, button, label, input, div, span {
        font-size: ${props => `${props.fontSize === 13 ? '' : props.fontSize}px !important`}
    };
`