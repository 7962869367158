import { format } from "date-fns"
import { CountryOptions } from "../../data/CountryOptions"
import { formatPhoneUSA } from "../formatPhoneUSA"
import moment from "moment"

export const normalizeParamsShipment = (data, mode) => {
    let locations = ['shipper', 'consignee'].map((location) => {
        return ({[location]: {
          "id": data[location]?.id || null,
          "name": data[location]?.name,
          "referenceNumber": data[location].referenceNumber,
          "address1": data[location]?.address,
          "address2": data[location]?.address1,
          "city": data[location]?.city,
          "state": data[location]?.state,
          "zipCode": data[location]?.zipcode?.trim(),
          "country": data[location]?.country?.length === 3 ? CountryOptions.filter(con => con.CodePrimus === data[location]?.country)[0].Code : data[location]?.country,
          "phone": (data[location]?.country?.includes('US') || data[location]?.country?.includes('CA')) ? formatPhoneUSA(data[location]?.phone, 'USA') || '' : data[location]?.phone,
          "fax": "",
          "email": data[location]?.emails?.length ? data[location]?.emails?.join(', ') : "",
          "contact": data[location]?.contact,
          "contactPhone": (data[location]?.country?.includes('US') || data[location]?.country?.includes('CA')) ? formatPhoneUSA(data[location]?.contactPhone, 'USA') || '' : data[location]?.contactPhone,
          "UNLOCode": data[location]?.UNLOCode?.code || '',
          "UNLOCodeCity": data[location]?.UNLOCode?.name || ''
        }})
    })
  
    return {
      modeId: mode?.id,
      shipper: locations[0].shipper,
      consignee: locations[1].consignee,
      thirdPartyReferenceNumber: data?.billTo?.referenceNumber,
      thirdPartyEmail: data?.billTo?.emails?.join(', '),
      insuranceAmount: data?.insuranceAmount,
      insuranceFreight: data?.insuranceFreight || false,
      insuranceAddOn: data?.insuranceAddOn || false,
      commodityInsurance: data?.commodityInsurance,
      PRONmbr: data?.PRONmbr,
      "quoteNumber": data?.quoteNumber || null,
      "lineItems": data?.freightInfo[0]?.qty ? data?.freightInfo?.map((freight) => {
        return mode?.shipmentCategory === 'Drayage' ? {
          qty: parseFloat(freight.qty),
          weight: parseFloat(freight.weight),
          weightType: "each",
          class: 0,
          length: 1,
          width: 1,
          height: 1,
          dimTypeDrayage: freight.container,
          hazmat: false,
          stack: false,
          overweight: false,
          reefer: false,
          residentialDelivery: false,
          householdGoods: false,
          liquor: false,
          storageYard: false,
          CYDepot: false,
          UN: freight?.UN,
          UNPKGGroup: freight?.UNPKGGroup,
        }
        : {
          "qty": freight.qty,
          "weight": freight.weight,
          "weightType": freight.each?.toLowerCase(),
          "length": parseFloat(freight.L) || 0,
          "width": parseFloat(freight.W) || 0,
          "height": parseFloat(freight.H) || 0,
          "dimType": freight.dimType?.value,
          "STC": freight.STC,
          "class": parseFloat(freight.class) || 0,
          "hazmat": freight.hazmat,
          "nmfc": freight.nmfc,
          "commodity": freight.commodity,
          "UN": freight?.UN,
          "UNPKGGroup": freight?.UNPKGGroup,
          "stack": (freight.stack === 'No' || !freight.stack) ? false : true,
          "stackAmount": (freight.stack === 'No' || !freight.stack) ? null : parseInt(freight.stack) ? parseInt(freight.stack) : parseInt(freight.stackAmount),
        }
      }) : [],
      "UOM": data?.freightInfoUOM?.value,
      "pickupInformation": {
        "date": data?.pickupDate?.date ? format(data?.pickupDate?.date || null, 'yyyy-MM-dd') : "",
        "type": data?.pickupDate?.type === 'PickupOn' ? "PO" : "PB",
        "timeFrom": data?.pickupDate?.from && (data?.pickupDate?.from.length > 5 ? moment(data?.pickupDate?.from, 'HH:mm:ss').format('HH:mm') : data?.pickupDate?.from.includes(':') ? data?.pickupDate?.from || "" : data?.pickupDate?.from.slice(0, 2) + ":" + data?.pickupDate?.from.slice(2)),
        "timeTo": data?.pickupDate?.to && (data?.pickupDate?.to.length > 5 ? moment(data?.pickupDate?.to, 'HH:mm:ss').format('HH:mm') : data?.pickupDate?.to.includes(':') ? data?.pickupDate?.to || "" : data?.pickupDate?.to.slice(0, 2) + ":" + data?.pickupDate?.to.slice(2)),
      },
      "deliveryInformation": {
        "date": data?.deliveryDate?.date ? format(data?.deliveryDate?.date || null, 'yyyy-MM-dd') : "",
        "type": data?.deliveryDate?.type === 'DeliveryOn' ? "DO" : "DB",
        "timeFrom": data?.deliveryDate?.from && (data?.deliveryDate?.from.length > 5 ? moment(data?.deliveryDate?.from, 'HH:mm:ss').format('HH:mm') : data?.deliveryDate?.from.includes(':') ? data?.deliveryDate?.from || "" : data?.deliveryDate?.from.slice(0, 2) + ":" + data?.deliveryDate?.from.slice(2)),
        "timeTo": data?.deliveryDate?.to && (data?.deliveryDate?.to.length > 5 ? moment(data?.deliveryDate?.to, 'HH:mm:ss').format('HH:mm') : data?.deliveryDate?.to.includes(':') ? data?.deliveryDate?.to || "" : data?.deliveryDate?.to.slice(0, 2) + ":" + data?.deliveryDate?.to.slice(2)),
      },
      "BOLInstructions": data?.specialInstructions,
      "shipmentNotes": data?.shipmentNotes,
      "additionalInformation": {
        "shipmentReference1": data?.otherInfo?.refNumber1,
        "shipmentReference2": data?.otherInfo?.refNumber2,
        "BKGRef": data?.otherInfo?.BKGRefNumber,
        "incoTerm": data?.otherInfo?.incoTerm,
      },
      "brokerInformation": {
        "name": data?.otherInfo.broker.name,
        "contact": data?.otherInfo.broker.contact,
        "phone": data?.otherInfo.broker.phone,
        "notes": data?.otherInfo.broker.instructions,
        "customsValue": data?.otherInfo?.BKGValue
      },
      "accessorialsList": [...data?.accessorials.origin.map((acc) => acc.code), ...data?.accessorials.destination.map((acc) => acc.code), ...data?.accessorials.other.map((acc) => acc.code)],
      "emergencyContact": data.emergencyContact,
      "emergencyPhone": data.emergencyPhone,
    }
}