import axios from "axios"
import { isTokenExp } from "./isTokenExp"

const GET_RATES = '/applet/v1/rate'
const GET_RATES_SUB_VENDOR = '/applet/v1/rate/p2p'

export const keepWaitingVendors = (user, values, rates, ratesVOL, linearProgress, setLinearProgress, vendorsTimedOut, setVendorsTimedOut, vendorsProcessed, setVendorsProcessed, setRatesStopped, enabledVendors, setRates, setRatesVOL, setOpenConfirmTimedOutModal, source, normalizeParams) => {
    let array = [...rates]
    let arrayVOL = [...ratesVOL]
    let progress = linearProgress
    let callsFinishedLocal = 0
    const enabledVendorsLTL = vendorsTimedOut.filter(vendor => ((values.LTL && vendor.type === 'LTL') || (values.courier && vendor.type === 'SP') || (values.AIR && vendor.type === 'INTL')))
    const enabledVendorsVOL = vendorsTimedOut.filter(vendor => vendor.type === 'VOL')
    const enabledVendorsFiltered = [...vendorsTimedOut] 
    let vendorsProcessedLocal = typeof vendorsProcessed === 'string' ? (vendorsProcessed || 0) : vendorsProcessed.length
    let vendorsTimedOutLocal = []

    setRatesStopped(false)

    enabledVendorsFiltered.forEach((vendor) => {
      axios.get(!vendor?.carrierCode ? GET_RATES : GET_RATES_SUB_VENDOR, {
        cancelToken: source.token,
        params: !vendor?.carrierCode ? normalizeParams(vendor.id) : {carrierCode: vendor?.carrierCode, ...normalizeParams(vendor.id)} ,
        timeout: 30000,
      }).then(response => {
        response.data.data.results?.filter(result => !result.error).forEach((newRate) => {
          if(vendor.type === 'VOL'){
            if(newRate.SCAC && user.SCACFilteringEnabled){
              let duplicated = arrayVOL.find(oldRate => oldRate?.SCAC === newRate?.SCAC && oldRate?.serviceLevel.toLowerCase() === newRate?.serviceLevel.toLowerCase() && oldRate.id !== newRate.id) || {} as any
  
              let vendorNew = enabledVendorsVOL?.find(vendor => vendor?.id === newRate?.vendorId)
              let vendorDuplicated = duplicated.vendorId ? enabledVendorsVOL.find(vendor => vendor?.id === duplicated?.vendorId) : {id: '', is3PL: false, isDirectAccount: false}
    
              let eliminateDuplicated = arrayVOL.filter(rate => JSON.stringify(rate) !== JSON.stringify(duplicated))
              let eliminateNew = arrayVOL.filter(rate => JSON.stringify(rate) !== JSON.stringify(newRate))
    
              if(vendorNew?.id && vendorDuplicated?.id){
                if(vendorNew.is3PL && (!vendorDuplicated.is3PL && duplicated.total >= newRate.total)){
                  arrayVOL = [...eliminateDuplicated, newRate]
                }else if(vendorNew.is3PL && vendorDuplicated.is3PL){
                  if(newRate.total >= duplicated.total){
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }else{
                    arrayVOL = [...eliminateDuplicated, newRate]
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.isDirectAccount){
                  if(newRate.total >= duplicated.total){
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }else{
                    arrayVOL.push(newRate)
                    arrayVOL = eliminateDuplicated
                  }
                }else if(vendorNew.is3PL && vendorDuplicated.isDirectAccount){
                  if(duplicated.total > (newRate.total + parseFloat(user.SCACFilteringVarianceUSD)) && duplicated.total > (newRate.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    arrayVOL.push(newRate)
                    arrayVOL = eliminateDuplicated
                  }else{
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.is3PL){
                  if(newRate.total <= (duplicated.total + parseFloat(user.SCACFilteringVarianceUSD)) && newRate.total <= (duplicated.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    arrayVOL.push(newRate)
                    arrayVOL = eliminateDuplicated
                  }else{
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }
                }
              }else{
                arrayVOL.push(newRate)
              }
            }else{
              arrayVOL.push(newRate)
            }
          }else{
            if(newRate.SCAC && user.SCACFilteringEnabled){
              let duplicated = array.find(oldRate => oldRate?.SCAC === newRate?.SCAC && oldRate?.serviceLevel.toLowerCase() === newRate?.serviceLevel.toLowerCase() && oldRate.id !== newRate.id) || {} as any
  
              let vendorNew = enabledVendorsLTL?.find(vendor => vendor?.id === newRate?.vendorId)
              let vendorDuplicated = duplicated.vendorId ? enabledVendorsLTL.find(vendor => vendor?.id === duplicated?.vendorId) : {id: '', is3PL: false, isDirectAccount: false}
    
              let eliminateDuplicated = array.filter(rate => JSON.stringify(rate) !== JSON.stringify(duplicated))
              let eliminateNew = array.filter(rate => JSON.stringify(rate) !== JSON.stringify(newRate))
    
              if(vendorNew?.id && vendorDuplicated?.id){
                if(vendorNew.is3PL && (!vendorDuplicated.is3PL && duplicated.total >= newRate.total)){
                  array = [...eliminateDuplicated, newRate]
                }else if(vendorNew.is3PL && vendorDuplicated.is3PL){
                  if(newRate.total >= duplicated.total){
                    array.push(duplicated)
                    array = eliminateNew
                  }else{
                    array = [...eliminateDuplicated, newRate]
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.isDirectAccount){
                  if(newRate.total >= duplicated.total){
                    array.push(duplicated)
                    array = eliminateNew
                  }else{
                    array.push(newRate)
                    array = eliminateDuplicated
                  }
                }else if(vendorNew.is3PL && vendorDuplicated.isDirectAccount){
                  if(duplicated.total > (newRate.total + parseFloat(user.SCACFilteringVarianceUSD)) && duplicated.total > (newRate.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    array.push(newRate)
                    array = eliminateDuplicated
                  }else{
                    array.push(duplicated)
                    array = eliminateNew
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.is3PL){
                  if(newRate.total <= (duplicated.total + parseFloat(user.SCACFilteringVarianceUSD)) && newRate.total <= (duplicated.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    array.push(newRate)
                    array = eliminateDuplicated
                  }else{
                    array.push(duplicated)
                    array = eliminateNew
                  }
                }
              }else{
                array.push(newRate)
              }
            }else{
              array.push(newRate)
            }
          }
        })

        if(vendor.type === 'VOL'){
          setRatesVOL([
            ...arrayVOL
          ])
        }else{
          setRates([
            ...array
          ])
        }

        progress = progress + (100 / enabledVendors.length)
        setLinearProgress(progress)
        vendorsProcessedLocal = (parseInt(vendorsProcessedLocal) + 1).toString()
        setVendorsProcessed(vendorsProcessedLocal)
      }).catch(error => {
        if(error.toString().includes('timeout')){
          vendorsTimedOutLocal = [...vendorsTimedOutLocal, vendor]
          setVendorsTimedOut(vendorsTimedOutLocal)
        }else if(error.message !== 'Operation canceled by the user.'){
          progress = progress + (100 / enabledVendors.length)
          setLinearProgress(progress)
          vendorsProcessedLocal = (parseInt(vendorsProcessedLocal) + 1).toString()
          setVendorsProcessed(vendorsProcessedLocal)
        }

        if(error?.response?.data?.error?.message.length){
          if(error?.response?.status === 401){
            setRatesStopped(true)
            isTokenExp()
          }
        }
      }).finally(() => {
        callsFinishedLocal = callsFinishedLocal + 1
        if(callsFinishedLocal === (enabledVendorsFiltered.length)){
          if(vendorsTimedOutLocal?.length){
            setOpenConfirmTimedOutModal(true)
          }
          setRatesStopped(true)
        }
      })
    })
}