import styled from 'styled-components';

export const ContainerStyled = styled.div`
    th, div {
        font-size: ${props => `${props.fontSize === 13 ? 12 : props.fontSize}px !important`};
    };
    td, div {
        font-size: ${props => `${props.fontSize}px !important`};
    }
`

