import { ReactMultiEmail } from 'react-multi-email-4agile';
import styled from 'styled-components';

export const ReactMultiEmailStyled = styled(ReactMultiEmail)`
    width: 100% !important;
    border: ${(props) => !props.error ? '1px solid #E4E6EF' : '1px solid #F1416C'};
    background-color: ${(props) => props.disabled ? '#EFF2F5' : 'white'};
    border-radius: 0.475rem;
    height: 100% !important;
    min-height: 53.13px;
    font-size: 14.13px;
    color: #5E6278;
    font-weight: 500;
    padding: 9px;
    padding-bottom: 6.75px;
    input {
        background: ${(props) => props.disabled ? '#EFF2F5' : 'white'};
        border: none !important;
        color: #5E6278;
        font-size: 14.13px;
        font-weight: 500
    }
    textarea:focus, input:focus{
        outline: none;
    }
    &.focused{
        transition: border 0.25s ease-out;
        border: ${(props) => !props.error ? !props.disabled && '1px solid #B5B5C3' : '1px solid #F1416C'};
    }
    div[data-tag="true"] {
        margin-left: 2px;
        width: fit-content;
        display: inline-flex;
    }
    div[data-tag]:first-child {
        margin-left: 2px;
    }
`

export const ReactMultiEmailStyled2 = styled(ReactMultiEmail)`
    width: 100% !important;
    border: ${(props) => !props.error ? '1px solid #E4E6EF' : '1px solid #F1416C'};
    background-color: ${(props) => props.disabled ? '#EFF2F5' : 'white'};
    border-radius: 0.475rem;
    //height: var(--input-height);
    min-height: var(--input-height);
    font-size: 14.13px;
    color: #5E6278;
    font-weight: 500;
    padding: 0 9px;
    //padding-bottom: 6.75px;
    input {
        background: ${(props) => props.disabled ? '#EFF2F5' : 'white'};
        border: none !important;
        color: #5E6278;
        font-size: 14.13px;
        font-weight: 500
    }
    textarea:focus, input:focus{
        outline: none;
    }
    &.focused{
        transition: border 0.25s ease-out;
        border: ${(props) => !props.error ? !props.disabled && '1px solid #B5B5C3' : '1px solid #F1416C'};
    }
    div[data-tag="true"] {
        margin-left: 2px;
        width: fit-content;
        display: inline-flex;
    }
    div[data-tag]:first-child {
        margin-left: 2px;
    }
`