import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { RootState } from '../../../setup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faTruckRampBox } from '@fortawesome/free-solid-svg-icons'
import { faBoxesPacking } from '@fortawesome/free-solid-svg-icons'
import { faTruckFast } from '@fortawesome/free-solid-svg-icons'

const Card = ({className, description, color, filters, reports, reportName, allowTrack}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
        style={{
          backgroundColor: color,
          borderRadius: '0.625rem',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.03)'
        }}
      >
        <div className='card-header pt-5'>
          <div className='card-title d-flex flex-column'>
            <h1 className={`${fontSize < 13 ? 'fs-1hx' : 'fs-2hx'} fw-bold text-white me-2 lh-1 ls-n2 d-flex align-items-center`}>
              {
                description === 'Shipments Created' ?
                <FontAwesomeIcon icon={faTruckFast} fontSize={fontSize < 13 ? 18 : 20} className='me-3'/>
                : description === 'Picking Up Today' ?
                  <FontAwesomeIcon icon={faBoxesPacking} fontSize={fontSize < 13 ? 18 : 20} className='me-3'/>
                : description === 'Delivering' ?
                <FontAwesomeIcon icon={faTruckRampBox} fontSize={fontSize < 13 ? 18 : 20} className='me-3'/>
                : <FontAwesomeIcon icon={faExclamationTriangle} fontSize={fontSize < 13 ? 18 : 20} className='me-3'/>
              }
              {reportName === 'delayed' ? (reports.delayed + reports.exceptions) || '0' : (reports[reportName] || '0')}
            </h1>
            <h1 className={`text-white opacity-75 pt-1 fw-semibold ${fontSize < 13 ? 'fs-8' : fontSize > 13 ? 'fs-4' : 'fs-6'} mt-1`} style={{fontWeight: 500}}>{description}</h1>
          </div>
        </div>
        
        <div className='card-body d-flex align-items-end pt-2'>
            {allowTrack && (
                  <button
                    onClick={() => {
                      navigate('/trackings')
                      dispatch({type: actionTypes.SetTrackingsFilter, payload: filters})
                    }}
                    className="text-white d-flex align-items-center"
                    style={{cursor: 'pointer', background: 'none', border: 'none', padding: 0}}
                  >
                    <ArrowForwardIosIcon className='text-white me-1' style={{fontSize: fontSize < 13 ? 10 : 13}}/>
                    <h3 className={`text-white ${fontSize < 13 ? 'fs-9' : fontSize > 13 ? 'fs-5' :'fs-7'} m-0`} style={{fontWeight: 500}}>See all</h3>
                  </button>
              )}
        </div>        
      </div>
    )
}

export default Card
  