import { SelectUOMProps } from "../../../pages/ltl/models"
import styled from 'styled-components';

const SelectUOM = ({setDataFormik, freightInfoUOM, freightInfo, freightInfoTotals, disabled, hazContact, setOpenHazContactModal}: SelectUOMProps) => {
    const onChangeUOM = (value, oldValue) => {
        setDataFormik('freightInfoUOM', {
            value: value,
            oldValue: oldValue,
        })

        if (value === 'US') {
            if (oldValue === 'METRIC') {
                // conversion to US from metric
                freightInfo.forEach((freight, index) => {
                    let { weight, L, H, W } = freight
                    let newInfoData = [...freightInfo]
                    newInfoData[index].weight = weight / 0.45359237
                    newInfoData[index].L = L / 2.54
                    newInfoData[index].H = H / 2.54
                    newInfoData[index].W = W / 2.54
                    setDataFormik('freightInfo', newInfoData)
                })
            } else if (oldValue === 'MIXED') {
                // conversion to US from Kgs / in
                freightInfo.forEach((freight, index) => {
                    let { weight } = freight
                    let newInfoData = [...freightInfo]
                    newInfoData[index].weight = weight / 0.45359237
                    setDataFormik('freightInfo', newInfoData)
                })
            } else {
                // no conversion Mantain US
            }
            setDataFormik('freightInfoTotals', {
                ...freightInfoTotals,
                symbol: 'lbs',
            })
        } else if (value === 'METRIC') {
            if (oldValue === 'US') {
                // conversion to Metric from US
                freightInfo.forEach((freight, index) => {
                    let { weight, L, H, W } = freight
                    let newInfoData = [...freightInfo]
                    newInfoData[index].weight = weight * 0.45359237
                    newInfoData[index].L = L * 2.54
                    newInfoData[index].H = H * 2.54
                    newInfoData[index].W = W * 2.54
                    setDataFormik('freightInfo', newInfoData)
                })

            } else if (oldValue === 'MIXED') {
                // conversion to Metric from Kgs / in
                freightInfo.forEach((freight, index) => {
                    let { L, H, W } = freight
                    let newInfoData = [...freightInfo]
                    newInfoData[index].L = L * 2.54
                    newInfoData[index].H = H * 2.54
                    newInfoData[index].W = W * 2.54
                    setDataFormik('freightInfo', newInfoData)
                })
            } else {
                // no conversion Mantain Metric
            }
            setDataFormik('freightInfoTotals', {
                ...freightInfoTotals,
                symbol: 'kgs',
            })
        } else {
            // Selected Kgs/ in
            if (oldValue === 'US') {
                // conversion to Kgs/in from US
                freightInfo.forEach((freight, index) => {
                    let { weight } = freight
                    let newInfoData = [...freightInfo]
                    newInfoData[index].weight = weight * 0.45359237
                    setDataFormik('freightInfo', newInfoData)
                })
            } else if (oldValue === 'METRIC') {
                // conversion to Kgs / in from METRIC
                freightInfo.forEach((freight, index) => {
                    let { L, H, W } = freight
                    let newInfoData = [...freightInfo]
                    newInfoData[index].L = L / 2.54
                    newInfoData[index].H = H / 2.54
                    newInfoData[index].W = W / 2.54
                    setDataFormik('freightInfo', newInfoData)
                })
            } else {
                // no conversion Mantain Kgs/in
            }
            setDataFormik('freightInfoTotals', {
                ...freightInfoTotals,
                symbol: 'kgs',
            })
        }
    }

    return (
        
        <SelectUOMWrapper className='mb-5 col-md-12 col-xl-5 p-3 select-uom-wrapper'>        
            <div className="col-3 col-xl-2 d-flex justify-content-center justify-content-xl-end form-check form-check-custom form-check-solid form-check-sm">
                <div className="me-5">
                    UOM
                </div>
            </div>
            <div className={`col-5 ${hazContact ? 'col-sm-3' : 'col-sm-5'} col-xl-2 form-check form-check-custom form-check-solid form-check-sm`}>
                <input
                    disabled={disabled}
                    className="form-check-input white-bg"
                    type="radio"
                    name="radioUOM"
                    value="US"
                    id="US"
                    onChange={(e) => onChangeUOM(e.target.value, freightInfoUOM?.value)}
                    checked={freightInfoUOM?.value === "US"}
                />
                <label className="form-check-label" htmlFor="US" style={{cursor: disabled ? 'default' : 'pointer'}}>
                    lb/in
                </label>
            </div>
            <div className={`col-4 ${hazContact ? 'col-sm-3' : 'col-sm-4'} col-xl-4 form-check form-check-custom form-check-solid form-check-sm`}>
                <input
                    disabled={disabled}
                    className="form-check-input white-bg"
                    type="radio"
                    name="radioUOM"
                    value="METRIC"
                    id="METRIC"
                    onChange={(e) => onChangeUOM(e.target.value, freightInfoUOM?.value)}
                    checked={freightInfoUOM?.value === "METRIC"}
                />
                <label className="form-check-label" htmlFor="METRIC" style={{cursor: disabled ? 'default' : 'pointer'}}>
                    kg/cm
                </label>
            </div>
            {
                hazContact ? 
                <div className="col-12 col-sm-3 col-xl-4">
                    <button
                        className="btn btn-primary"
                        disabled={!freightInfo.map((f) => f.hazmat).includes(true)}
                        onClick={() => setOpenHazContactModal(true)}
                    >
                        Haz. contact
                    </button>
                </div> : null
            }
        
        </SelectUOMWrapper>
        
    )
}

export default SelectUOM

export const SelectUOMWrapper = styled.div`
    display: flex;
    background-color: rgb(228,230,239);
    border-radius: 0.475rem;
`