export const markupCalculations = (allVendorsMarkup, min, max, rate, cheaper, markup, newArray, index) => {
    if(allVendorsMarkup?.carrier?.name && allVendorsMarkup.rate && (!markup?.carrier?.name || !markup.rate)){
      min = parseInt(allVendorsMarkup?.min.replace(",", ""))
      max = parseInt(allVendorsMarkup?.max.replace(",", ""))
      rate = parseInt(allVendorsMarkup?.rate.replace(",", ""))

      if(allVendorsMarkup.type === 'Flat'){
        newArray[index].BillTo = cheaper.total + rate
        newArray[index].Profit = rate
      }else if(allVendorsMarkup.type === 'Profit'){
          newArray[index].BillTo = cheaper.total * (1 + (rate / 100))
          newArray[index].Profit = (cheaper.total * (1 + (rate / 100))) - cheaper.total
      }else if(allVendorsMarkup.type === 'GP'){
          newArray[index].BillTo = cheaper.total / (1 - (rate / 100))
          newArray[index].Profit = (cheaper.total / (1 - (rate / 100))) - cheaper.total
      }

      if(min && (newArray[index].Profit < min)){
          newArray[index].BillTo = cheaper.total + min
          newArray[index].Profit = min
      }else if(max && (newArray[index].Profit > max)){
          newArray[index].BillTo = cheaper.total + max
          newArray[index].Profit = max
      }
    }else if(markup && markup?.rate){
      if(markup.type === 'Flat'){
        newArray[index].BillTo = cheaper.total + rate
        newArray[index].Profit = rate
      }else if(markup.type === 'Profit'){
          newArray[index].BillTo = cheaper.total * (1 + (rate / 100))
          newArray[index].Profit = (cheaper.total * (1 + (rate / 100))) - cheaper.total
      }else if(markup.type === 'GP'){
          newArray[index].BillTo = cheaper.total / (1 - (rate / 100))
          newArray[index].Profit = (cheaper.total / (1 - (rate / 100))) - cheaper.total
      }

      if(min && (newArray[index].Profit < min)){
          newArray[index].BillTo = cheaper.total + min
          newArray[index].Profit = min
      }else if(max && (newArray[index].Profit > max)){
          newArray[index].BillTo = cheaper.total + max
          newArray[index].Profit = max
      }
    }else if(!markup?.rate && markup?.min && markup?.type === 'Flat'){
      newArray[index].BillTo = cheaper.total + min
      newArray[index].Profit = min
    }else if(allVendorsMarkup?.carrier?.name && !allVendorsMarkup.rate && allVendorsMarkup.min && allVendorsMarkup.type === 'Flat'){
      min = parseInt(allVendorsMarkup?.min.replace(",", ""))
      max = parseInt(allVendorsMarkup?.max.replace(",", ""))
      rate = parseInt(allVendorsMarkup?.rate.replace(",", ""))

      newArray[index].BillTo = cheaper.total + min
      newArray[index].Profit = min
    }else{
      newArray[index].BillTo = 0
      newArray[index].Profit = 0
    }
  }