export const openMaps = (newBookingValues, values) => {
    let params = {
        BOLId: values.BOLId || '',
        carrierId: newBookingValues.vendorId || values.vendorId,
        carrierSCAC: newBookingValues.vendorSCAC || values.vendorSCAC,
        shipperAddress: values.shipper.address,
        shipperZipcode: values.shipper.zipcode,
        shipperCity: values.shipper.city,
        shipperState: values.shipper.state,
        shipperCounty: values.shipper.country,
        consigneeAddress: values.consignee.address,
        consigneeZipcode: values.consignee.zipcode,
        consigneeCity: values.consignee.city,
        consigneeState: values.consignee.state,
        consigneeCounty: values.consignee.country
    };
    Object.keys(params).forEach(key => params[key] === undefined && delete params[key]);
    var esc = encodeURIComponent;
    var query = Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');

    window.open(
        'https://dev.shipprimus.com/mapPrimus/maprest.php?' + query,
        '_blank'
    );
}