import { CountryDialCode } from "../data/CountryDialCode"
import { CountryOptions } from "../data/CountryOptions"

export const clearDialCode = (phoneNumberString, country) => {
    let countryCode = CountryOptions?.find(c => c.CodePrimus === country)?.Code || 'US'
    if(phoneNumberString?.includes(CountryDialCode.find(countryOne => countryOne.code === countryCode)?.dial_code)){
        return phoneNumberString.replace(CountryDialCode.find(countryOne => countryOne.code === countryCode)?.dial_code, '')
    }else{
        return phoneNumberString
    }
}