import { makeStyles } from '@mui/styles';

export const modalStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root': {
            zIndex: -1
        },
        margin: 20,
    },
    paper: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 500,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperEquipmentService: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 350,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperAddStops: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 585,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperStopsModal: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 1000,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperBOLModal: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 400,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperShipmentDispatched: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 600,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperSaveQuote: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 600,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperEditModalSL: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: parseInt(props.fontSize) > 13 && parseInt(props.fontSize) < 19 ? 1000 : parseInt(props.fontSize) >= 19 ? 1200 : 940,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperEditModalSI: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: parseInt(props.fontSize) > 13 ? 1500 : 1350,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperUNLookup: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 1000,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperNMFCLookup: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 1200,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperViewShipmentModal: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 1100,
        width: '100%',
        minHeight: '200px',
        maxHeight: 658,
        height: '100%',
        padding: 15
    }),
    paperDocumentsModal: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 700,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperEmailDocumentsModal: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 900,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperUploadDocumentsModal: (props: any) => ({
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 620,
        width: '100%',
        minHeight: '200px',
        padding: 15
    }),
    paperEditModalRestrictions: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 600,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
    paperRatesModal: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 1000,
        width: '100%',
        minHeight: '200px',
        padding: 15
    },
}));