import { useState } from 'react'
import DeleteRowsConfirmModal from '../Modals/DeleteRowsConfirmModal/DeleteRowsConfirmModal'
import { ContainerStyled } from './Footer.styled';
import { FooterProps } from '../../pages/book/models';
import AccessorialsModal from '../BatchQuotingPage/AccessorialsModal/AccessorialsModal';

const Footer = ({loadingRates, totalRows, uploadedRows, deleteAllTableData}: FooterProps) => {
    const [openDeleteRowsConfirmModal, setOpenDeleteRowsConfirmModal] = useState(false)
    const [openAccessorialsModal, setOpenAccessorialsModal] = useState(false)
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    return (
        <>
            <ContainerStyled className="row mt-6 align-items-center" fontSize={fontSize}>
                {
                    totalRows && uploadedRows ? 
                    <>
                        <div className={`col-12 col-md-5 d-flex align-items-center`} style={{color: '#3F4254'}}>
                            <div className='me-5 ps-3'>{uploadedRows === totalRows ? `${uploadedRows} ${uploadedRows === 1 ? 'row' : 'rows'} imported` : `${uploadedRows} ${uploadedRows === 1 ? 'row' : 'rows'} of ${totalRows} imported`}</div>
                            <button
                                className={`${loadingRates && 'disabled'} btn btn-danger me-5`}
                                onClick={() => setOpenDeleteRowsConfirmModal(true)}
                            >
                                Clear Table
                            </button>
                        </div>
                    </> : null
                }
            </ContainerStyled>
            <DeleteRowsConfirmModal
                open={openDeleteRowsConfirmModal}
                setOpen={setOpenDeleteRowsConfirmModal}
                deleteAllTableData={deleteAllTableData}
            />
            <AccessorialsModal
                open={openAccessorialsModal}
                setOpen={setOpenAccessorialsModal}
            />
        </>
    )
}

export default Footer