import MaterialTable from '@material-table/core'
import { Backdrop, Fade, Paper } from '@mui/material'
import { format } from 'date-fns'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { KTSVG } from '../../../_metronic/helpers'
import { dollarUSLocale } from '../../helpers/dollarUSLocale'
import { UserModel } from '../../modules/auth/models/UserModel'
import { tableStyles } from '../LTLandVolumeTLPages/Tables/TableStyles'
import { modalStyles } from '../ModalStyles'
import { InvoiceBreakdownModalProps } from '../../pages/invoices/models';
import { v4 as uuidv4 } from 'uuid';
import { BoldSpan } from './ShippingItemsPage.styled'
import { DivPaperStyled, ModalStyled, SmallTextItalic } from '../Modals.styled'

const InvoiceBreakdownModal = ({open, setOpen, data}: InvoiceBreakdownModalProps) => {
    const classesModal = modalStyles({})
    const classesTable = tableStyles()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesModal.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classesModal.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row mb-6 mt-3">
                                <div className="col-6 col-sm-7 d-flex align-items-center">
                                    <img alt='icon' src={user?.companyLogo ? user.companyLogo : process.env.PUBLIC_URL+'/media/logos/ShipprimusLogoWhiteBG.png'} width='100px' className='ms-5'/>
                                </div>
                                <div className="col-6 col-sm-5">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <BoldSpan>Invoice#</BoldSpan>
                                                </div>
                                                <div className="col-6">
                                                    <span>{data.invoiceNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="row align-items-center">
                                                <div className="col-6">
                                                    <BoldSpan>Issue Date:</BoldSpan>
                                                </div>
                                                <div className="col-6">
                                                    <span>{format(new Date(data?.issueDate || null), 'MM/dd/yyyy')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <BoldSpan>BOL#</BoldSpan>
                                                </div>
                                                <div className="col-6">
                                                    <span>{data.BOLNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <BoldSpan>Status:</BoldSpan>
                                                </div>
                                                <div className="col-6">
                                                    <span><b className={data.paid ? 'text-success' : 'text-danger'}>{data.paid ? 'PAID' : 'UNPAID'}</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mb-3">
                                    <BoldSpan>Invoice Breakdown</BoldSpan>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <MaterialTable
                                        style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                                        columns={[
                                            { title: 'Description', field: 'description', cellStyle: {width: 170, minWidth: 170}},
                                            { title: 'Qty', field: 'qty'},
                                            { title: 'Rate', field: 'rate', type: 'currency'},
                                            { title: 'Subtotal', field: 'total', type: 'currency'}
                                        ]}
                                        data={data.invoiceBreakdown?.map(rateBreakdown => (
                                            {
                                                ...rateBreakdown,
                                                id: uuidv4()
                                            }
                                        ))}
                                        options={{
                                            thirdSortClick: false,
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            search: false,
                                            paging: false,
                                            filtering: false,
                                            searchFieldAlignment: 'left',
                                            draggable: false,
                                            rowStyle: (data, idx) => idx%2 !== 0 ? {
                                              backgroundColor: '#f4f4f4',
                                            } : {
                                              backgroundColor: '#ffff',
                                            },
                                        }}
                                        components={{
                                            Container: (props) => <Paper className={classesTable.table} {...props}/>,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-end mt-5">
                                <div className="col-9 d-flex justify-content-end">
                                    <BoldSpan>Total</BoldSpan>
                                </div>
                                <div className="col-3 d-flex justify-content-end">
                                    <span style={{paddingRight: 10}}>{dollarUSLocale.format(data.total)}</span>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <SmallTextItalic className='ms-2'>*{data.invoiceRemarks}</SmallTextItalic>
                                </div>
                            </div>
                            <div className="row mt-8 mb-3">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className="btn btn-secondary me-4" onClick={handleClose}>
                                        Close
                                    </button>
                                    <a className="btn btn-primary" href={data.url}>
                                        Download PDF
                                    </a>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default InvoiceBreakdownModal