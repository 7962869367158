export const isPositiveInteger = (num) => {
    if (typeof num !== 'string') {
        return false;
    }

    const number = Number(num);

    if (Number.isInteger(number) && number > 0) {
        return true;
    }

    return false;
}