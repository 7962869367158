import { deleteSpacesInKeys } from "./deleteSpacesInKeys"
import { fileHasAllKeysBooking } from "./fileHasAllKeysBooking"
import { normalizeCell } from "./normalizeCell"
import { searchCityAndStateMissing } from "./searchCityAndStateMissing"
import { v4 as uuidv4 } from 'uuid';
import { validationsBooking } from "./validationsBooking";
import { autoCalculateClass } from "./autoCalculateClass";

export const uploadFileByPasteBooking = (data, setData, allData, columns, setOpenNoInfoFoundModal, setErrors, setOpenErrorsInDataModal, allShipmentsArray, setOpenUploadFileModal, setOpenAppendDataModal, setFileName, setFileNameToUse, setNewData, setTotalRows, setUploadedRows, vendorsToUse, setOpenWarningCallsModal, setOpenConfirmModal, setOpenInvalidDataModal, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setNumberOfZipcodesMissing, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes) => {
    if(fileHasAllKeysBooking(columns[0])){
        if(!columns.length){
            setOpenNoInfoFoundModal(true)
        }else{
            let dataValid = []
            let errorsArray = []

            if(data.length){          
                columns.forEach((cell, index) => {
                    deleteSpacesInKeys(cell)
                    validationsBooking(cell, index, errorsArray, allData)
                    autoCalculateClass(cell.Pieces, cell.Weight, cell.Length, cell.Width, cell.Height, cell)
                })

                if(errorsArray.length){
                    setOpenUploadFileModal(false)
                    setErrors(errorsArray)
                    setOpenErrorsInDataModal(true)
                    dataValid = []
                }else{
                    if(columns.length > 500){
                        setOpenWarningCallsModal(true)
                    }else{
                        setOpenAppendDataModal(true)
                    }
                    setFileNameToUse("")
                    setNewData(columns)
                }
            }

            if(!data.length){
                setFileName("")
                setTotalRows(columns.length)
                columns.forEach((cell, index) => {
                    deleteSpacesInKeys(cell)
                    validationsBooking(cell, index, errorsArray, allShipmentsArray)
                    autoCalculateClass(cell.Pieces, cell.Weight, cell.Length, cell.Width, cell.Height, cell)

                    if(errorsArray.length){
                        setErrors(errorsArray)
                        setOpenErrorsInDataModal(true)
                        dataValid = []
                    }else{
                        normalizeCell(cell)
                        if(isNaN(cell.Class)){
                            dataValid.push({...cell, Class: ""})
                        }else{
                            dataValid.push(cell)
                        }
                    }
                });

                if(dataValid.length > 500){
                    setOpenWarningCallsModal(true)
                }else{
                    if(errorsArray.length === 0 && dataValid.length){
                        setUploadedRows(dataValid.length)
                        setRowsWithInvalidZipcode([])
                        searchCityAndStateMissing(dataValid, data, setData, 'replace', rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setOpenWarningCallsModal, setOpenConfirmModal, setNumberOfZipcodesMissing, vendorsToUse, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes)
                    }else{
                        setData([])
                    }

                    setData(dataValid.map((singleData) => {
                        return {
                            ...singleData,
                            id: uuidv4()
                        }
                    }));
                }
            }
        }
    }else{
        setOpenInvalidDataModal(true)
    }
}