import { useEffect, useState } from 'react'
import ReportBuilder from '../home/ReportBuilder'
import { useFormik } from 'formik'
import { PageTitle } from '../../../_metronic/layout/core'
import { useLocation, useNavigate } from 'react-router-dom'
import MaterialTable, { MTableBodyRow } from '@material-table/core'
import { Paper } from '@mui/material'
import { tableStyles } from '../../components/LTLandVolumeTLPages/Tables/TableStyles'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import axios from 'axios'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { actionTypes } from '../../modules/auth/redux/PagesRedux'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'

const DELIVERIES = '/api/v1/bookings/report/deliveries'
const CARRIERBREAKDOWN = '/api/v1/bookings/report/carrierbreakdown'
const MODEBREAKDOWN = '/api/v1/bookings/report/shipmentbreakdown'
const OPENCLAIMS = '/applet/v1/book/openclaims'

const ReportsDetailed = () => {
    const seeMoreReportsClicked = useSelector<RootState>(({pages}) => pages.seeMoreReportsClicked) as number
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [reports] = useState([
        {title: 'Carrier Performance', url: 'carrier-performance'},
        {title: 'Shipment Mode Breakdown', url: 'shipment-mode-breakdown'},
        {title: 'Shipments by Carrier', url: 'shipments-by-carrier'},
        {title: 'Open Claims', url: 'open-claims'},
    ])
    const location = useLocation()
    const classes = tableStyles()
    const [reportType, setReportType] = useState('')
    const [loading, setLoading] = useState(false)

    const [columnsToUse, setColumnsToUse] = useState([])
    const [dataToUse, setDataToUse] = useState([])

    let calculatePercent = (quantity, total) => {
        return ((quantity / total) * 100).toFixed(2);
    }

    const percentageRender = (rowData, percentageFor) => {
        return (
            <>
                {calculatePercent(rowData[percentageFor], rowData[`total${percentageFor.charAt(0).toUpperCase() + percentageFor.slice(1)}`])}%
            </>
        )
    }

    const percentageOnTimeRender = (rowData, percentageFor) => {
        return (
            <>
                {rowData.percentOntime}%
            </>
        )
    }

    const [columnsOnTime] = useState([
        { title: 'Code', field: 'carrierSCAC'},
        { title: 'Ontime', field: 'ontime'},
        { title: 'Delay', field: 'delay'},
        { title: 'Percent', field: 'percentOntime', render: percentageOnTimeRender},
    ])

    const [columnsModeBreakdown] = useState([
        { title: 'Shipment Mode', field: 'shipmentModeName'},
        { title: 'Quantity', field: 'quantity'},
    ])

    const [columnsCarrierBreakdown] = useState([
        { title: 'SCAC Code', field: 'carrierSCAC'},
        { title: 'Name', field: 'carrierName', cellStyle: {width: 300, minWidth: 300}, headerStyle: {width: 300, minWidth: 300}},
        { title: 'BOLs', field: 'quantity'},
        { title: 'Percentage', field: 'quantity', render: rowData => percentageRender(rowData, 'quantity')},
        { title: 'Weight', field: 'weight'},
        { title: 'Percentage', field: 'weight', render: rowData => percentageRender(rowData, 'weight')},
        { title: 'Pieces', field: 'pieces'},
        { title: 'Percentage', field: 'pieces', render: rowData => percentageRender(rowData, 'pieces')},
    ])

    const [columnsOpenClaims] = useState([
        { title: 'BOL #', field: 'BOLNumber'},
        { title: 'Category', field: 'claimTypeName'},
        { title: 'Claim Amount', field: 'claimAmount', type: 'currency'},
    ])

    const initialValues = {
        date: {
          from: new Date(new Date().setDate(new Date().getDate() - 30)),
          to: new Date()
        },
        groupBy: 'Week',
        excludeWeekends: false
    }
    
    const formik = useFormik({
        initialValues,
        validateOnChange: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            axios.get(
                reportType === 'carrier-performance' ? DELIVERIES :
                reportType === 'shipment-mode-breakdown' ? MODEBREAKDOWN :
                reportType === 'shipments-by-carrier' ? CARRIERBREAKDOWN :
                reportType === 'open-claims' ? OPENCLAIMS : '',
            {
                params: {
                    dateFrom: moment(formik.values.date.from).format('YYYY-MM-DD'),
                    dateTo: moment(formik.values.date.to).format('YYYY-MM-DD'),
                    excludeWeekends: formik.values.excludeWeekends
                }
            }).then((results) => {
                setLoading(false)
                if(reportType === 'open-claims'){
                    setDataToUse(results.data.data.results)
                }else if(reportType === 'carrier-performance'){
                    setDataToUse(results.data.data.results.extra.mostUsed.map(r => ({...r, id: uuidv4()})))
                }else if(reportType === 'shipments-by-carrier'){
                    let data = results.data.data.results.report.map(r => ({...r, id: uuidv4()}))
                    setDataToUse(
                        data.map((d) => {
                            return {
                                ...d,
                                totalQuantity: data.map(a => a.quantity).reduce((partialSum, a) => partialSum + a, 0),
                                totalWeight: data.map(a => a.weight).reduce((partialSum, a) => partialSum + a, 0),
                                totalPieces: data.map(a => a.pieces).reduce((partialSum, a) => partialSum + a, 0)
                            }
                        })
                    )
                }else{
                    setDataToUse(results.data.data.results.report.map(r => ({...r, id: uuidv4()})))
                }
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Report')
            })
        },
    })

    useEffect(() => {
        setReportType(location.pathname.split("/reports/").pop())
        let reportType = location.pathname.split("/reports/").pop()
        
        setColumnsToUse(
            reportType === 'carrier-performance' ? columnsOnTime :
            reportType === 'shipment-mode-breakdown' ? columnsModeBreakdown :
            reportType === 'shipments-by-carrier' ? columnsCarrierBreakdown :
            reportType === 'open-claims' ? columnsOpenClaims : []
        )
        
        if(seeMoreReportsClicked){
            formik.handleSubmit()
            dispatch({type: actionTypes.SetSeeMoreReportsClicked, payload: false})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <>
            <PageTitle>Reports</PageTitle>
            <div className='d-flex align-items-center mb-3'>
                <KeyboardArrowLeftIcon style={{color: '#181C32'}}/>
                <div
                    className='btn btn-link p-2'
                    onClick={() => navigate('../reports')}
                >
                    All reports
                </div>
            </div>
            <ReportBuilder
                formik={formik}
                initialValues={initialValues}
                title={reports.filter(r => r.url === reportType)[0]?.title}
                setDataToUse={setDataToUse}
            />
            <div className='mt-4'>
                <MaterialTable
                    isLoading={loading}
                    style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                    columns={columnsToUse as any}
                    data={dataToUse}
                    options={{
                        thirdSortClick: false,
                        actionsColumnIndex: -1,
                        toolbar: false,
                        draggable: false,
                        filtering: false,
                        rowStyle: (data, idx) => idx%2 !== 0 ? {
                            backgroundColor: '#f4f4f4',
                        } : {
                            backgroundColor: '#ffff',
                        },
                        pageSizeOptions: [10, 25, 50, 100],
                        pageSize: 10
                    }}
                    components={{
                        Container: (props) => <Paper className={`${classes.tableQuotesGrid} w-100`} {...props} />,
                        Row: rowProps => <MTableBodyRow key={rowProps.index} {...rowProps} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,
                    }}
                />
            </div>
        </>
    )
}

export default ReportsDetailed