import { Button } from '@mui/material';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
    height: 36px !important;
    width: 36px !important;
    min-width: 36px !important;
    font-family: Poppins !important;
    color: #181C32 !important;
    border-radius: 0.475rem !important;
    & .MuiTouchRipple-root {
        color: #a5daf7 !important
    };
    &:hover {
        background-color: #F1FAFF !important;
        color: #019EF7 !important
    }
`