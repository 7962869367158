import { PageTitle } from '../../../_metronic/layout/core'
import { LinkStyled } from './Reports.styled';
import { useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { UserModel } from '../../modules/auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';

export const Reports = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
  const navigate = useNavigate()
  const [reports] = useState([
    {title: 'Carrier Performance', url: 'carrier-performance'},
    {title: 'Shipment Mode Breakdown', url: 'shipment-mode-breakdown'},
    {title: 'Shipments by Carrier', url: 'shipments-by-carrier'},
    {title: 'Open Claims', url: 'open-claims'},
    {title: 'Credit Info', url: '/invoices'}
  ])
  
  return (
    <>
      <PageTitle>Reports</PageTitle>
      {
        reports.map((report, index) => {
          return (
            <LinkStyled
              disabled={report.title === 'Credit Info' && !user?.displayInvoiceInformation}
              fontSize={fontSize}
              key={index}
              onClick={() => {
                if(report.title === 'Credit Info' ){
                  if(user?.displayInvoiceInformation){
                    navigate(report.url)
                  }
                }else{
                  navigate(`../reports/${report.url}`)
                }
              }}
            >
              <div className='w-75'>
                {report.title}
              </div>
              <div className='d-flex justify-content-end w-25'>
                <KeyboardArrowRightIcon style={{color: report.title === 'Credit Info' && !user?.displayInvoiceInformation ? '#5d6070' : '#181C32'}}/>
              </div>
            </LinkStyled>
          )
        })
      }
    </>
  )
}
