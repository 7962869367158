import { DivPaperStyled, ModalStyled } from '../../Modals.styled';
import { Backdrop, Fade, MenuItem } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { modalStyles } from '../../ModalStyles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import SearchShippingLocation from '../SearchShippingLocation';
import { useEffect, useState } from 'react';
import { shippingLocationModel } from '../../PagesModels';
import { InputStyled, SelectStyled } from '../../Inputs.styled';
import { setTimeout } from 'timers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { stylesMUIInput } from '../../stylesMUIInput';
import { useStyles } from '../../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles';
import TimeInput from '../../TimeInput/TimeInput';
import PhoneInput from '../../ShippingLocationsPage/PhoneInput';
import DateInput from '../../LTLandVolumeTLPages/DateInput';
import ZipcodesWithCountry from '../../LTLandVolumeTLPages/ZipcodesWithCountry';
import { CountryOptions } from '../../../data/CountryOptions';
import { UserModel } from '../../../modules/auth/models/UserModel';
import * as Yup from 'yup';
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { formatPhoneUSA } from '../../../helpers/formatPhoneUSA';

const AddNewStopModal = ({open, setOpen, stops, setStops}) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = modalStyles({});
    const classesInputs = useStyles({
        fontSize: fontSize
    })
    const [zipcodeLocal, setZipcodeLocal] = useState('')
    const [zipcode, setZipcode] = useState({
        City: '',
        Country: 'US',
        Name: '',
        State: '',
        Zipcode: '',
        isSL: false,
        isZipcode: true,
        isPort: false,
        id: uuidv4(),
    })
    const [shippingLocation, setShippingLocation] = useState({} as shippingLocationModel | any)
    const [shippingLocationLocal, setShippingLocationLocal] = useState('')
    const [time, setTime] = useState({             
        from: null,     
        to: null
    })
    const [errorLocal, setErrorLocal] = useState({
        from: false,
        to: false
    })
    const [touchedDate, setTouchedDate] = useState({
        date: false,
        PODDate: false
    })

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            formik.resetForm()
            setZipcode({
                City: '',
                Country: 'US',
                Name: '',
                State: '',
                Zipcode: '',
                isSL: false,
                isZipcode: true,
                isPort: false,
                id: uuidv4(),
            })
            setZipcodeLocal('')
            setShippingLocationLocal('')
            setShippingLocation({})
            setTime({             
                from: null,     
                to: null
            })
            setErrorLocal({             
                from: null,     
                to: null
            })
        }, 200);
    }

    const initialValues = {
        name: '',
        reference: '',
        type: '',
        date: null,
        from: '',
        to: '',
        address: '',
        address1: '',
        city: '',
        country: '',
        state: '',
        zipcode: '',
        phone: '',
        contact: '',
        contactPhone: '',
        notes: '',
        PODDate: null,
        signedBy: ''
    }

    const validatePhoneNumberFormik = (value) => {
        if(value === null){
          return true
        }else if(typeof value === 'string'){
          if(isPossiblePhoneNumber(value)){
              return true
          }else{
              return false
          }
        }else{
          return true
        }
      }

    const validationSchema = Yup.object().shape({
        city: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        zipcode: Yup.string().required('Required'),
        from: Yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm),
        to: Yup.string().matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm),
        date: Yup.date().nullable(),
        PODDate: Yup.date().nullable(),
        phone: Yup.string().nullable(true).test("validPhoneNumber", "Invalid phone", (value) => (
            validatePhoneNumberFormik(value)
        )),
        contactPhone: Yup.string().nullable(true).test("validPhoneNumber", "Invalid phone", (value) => (
            validatePhoneNumberFormik(value)
        ))
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            if(!errorLocal.from && !errorLocal.to){
                setStops([
                    ...stops,
                    {
                        ...values,
                        stopNumber: stops.length + 1, id: uuidv4(),
                        phone: values.country === 'US' ? formatPhoneUSA(values.phone, values.country) : values.phone,
                        contactPhone: values.country === 'US' ? formatPhoneUSA(values.contactPhone, values.country) : values.phone,
                    }
                ])
                handleClose()
            }
        },
    })

    const menuProps = {
        classes: {
            list: classesInputs.selectPaperList
        }
    }

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            city: zipcode.City,
            country: zipcode.Country,
            state: zipcode.State,
            zipcode: zipcode.Zipcode,
        }) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcode])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paperAddStops}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Add new stop</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <div style={{maxHeight: 600, overflowY: 'scroll', overflowX: 'hidden'}}>
                            <div className='row'>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>Name</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <SearchShippingLocation
                                        formik={formik}
                                        zipcode={zipcode}
                                        setZipcode={setZipcode}
                                        setZipcodeLocal={setZipcodeLocal}
                                        shippingLocation={shippingLocation}
                                        setShippingLocation={setShippingLocation}
                                        shippingLocationLocal={shippingLocationLocal}
                                        setShippingLocationLocal={setShippingLocationLocal}
                                        forStops={true}
                                    />
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>{window.innerWidth > 455 ? 'Reference' : 'Ref#'}</label>
                                </div>
                                <div className="col-5 col-sm-6 mb-4">
                                    <InputStyled
                                        type="text"
                                        className={`form-control`}
                                        autoComplete='off'
                                        {...formik.getFieldProps('reference')}
                                        maxLength="100"
                                        fontSize={fontSize}
                                    />
                                </div>
                                <div className="col-2 col-sm-1 mb-4">
                                    <label className='col-form-label'>Type</label>
                                </div>
                                <div className="col-3 mb-4">
                                    <SelectStyled
                                        value={formik.values?.type}
                                        name='type'
                                        onChange={formik.handleChange} 
                                        className={classesInputs.select}
                                        displayEmpty={true}
                                        inputProps={{
                                            IconComponent: KeyboardArrowDownIcon,
                                            MenuProps: menuProps,
                                            style: stylesMUIInput(fontSize),
                                        }}
                                        style={{
                                            color: '#5E6278',
                                        }}
                                        fontSize={fontSize}
                                    >
                                        {
                                            ['Pickup', 'Delivery', 'Both']?.map((types, index) => {
                                                return <MenuItem key={index} value={types}>{types}</MenuItem>
                                            })
                                        }
                                    </SelectStyled>
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>Date</label>
                                </div>
                                <div className="col-4 mb-4">
                                    <DateInput
                                        setDataFormik={formik.setFieldValue}
                                        pickupDate={formik.values?.date}
                                        name='date'
                                        allowPastDates={true}
                                        touched={touchedDate.date}
                                        setTouched={(boolean) => setTouchedDate({...touchedDate, date: boolean})}
                                        error={formik.errors.date}
                                    />
                                    <div>
                                        {formik.errors.date && touchedDate.date
                                            ? <small className="text-danger">Invalid date</small>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-3 mb-4">
                                    <TimeInput
                                        error={false}
                                        data={time?.from}
                                        setData={(value) => setTime({...time, from: value})}
                                        name='from'
                                        setFormikData={formik.setFieldValue}
                                        className="normalFont"
                                        errorLocal={errorLocal}
                                        setErrorLocal={setErrorLocal}
                                        time='from'
                                    />
                                    <div>
                                        {errorLocal.from
                                            ? <small className="text-danger">Invalid time</small>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-3 mb-4">
                                    <TimeInput
                                        data={time?.to}
                                        setData={(value) => setTime({...time, to: value})}
                                        name='to'
                                        setFormikData={formik.setFieldValue}
                                        className="normalFont"
                                        errorLocal={errorLocal}
                                        setErrorLocal={setErrorLocal}
                                        time='to'
                                    />
                                    <div>
                                        {errorLocal.to
                                            ? <small className="text-danger">Invalid time</small>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>Address</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <InputStyled
                                        type="text"
                                        className={`form-control`}
                                        autoComplete='new-password'
                                        {...formik.getFieldProps('address')}
                                        maxLength="100"
                                        fontSize={fontSize}
                                    />
                                </div>
                                <div className="col-2">
                                
                                </div>
                                <div className="col-10 mb-4">
                                    <InputStyled
                                        type="text"
                                        className={`form-control`}
                                        autoComplete='new-password'
                                        {...formik.getFieldProps('address1')}
                                        maxLength="100"
                                        fontSize={fontSize}
                                    />
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>C&#8204;ity</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <ZipcodesWithCountry
                                        zipcodeLocal={zipcodeLocal}
                                        setZipcodeLocal={setZipcodeLocal}
                                        setDataFormik={formik.setFieldValue}
                                        data={zipcode}
                                        setData={setZipcode}
                                        countryOptions={CountryOptions}
                                        userData={user}
                                        setFieldTouched={formik.setFieldTouched}
                                        error={formik?.errors?.zipcode}
                                        touched={formik?.touched?.zipcode}
                                        formikValues={formik.values}
                                        forStops={true}
                                    />
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>Phone</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <PhoneInput
                                        value={formik.values.phone || ""}
                                        setDataFormik={formik.setFieldValue}
                                        name='phone'
                                        countryCode={formik.values.country || 'US'}
                                        selectSmall={true}
                                        deleteSelectCountry={true}
                                    />
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>Contact</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <InputStyled
                                        type="text"
                                        className={`form-control`}
                                        autoComplete='off'
                                        {...formik.getFieldProps('contact')}
                                        maxLength="100"
                                        fontSize={fontSize}
                                    />
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label pb-0'>Contact Phone</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <PhoneInput
                                        value={formik.values.contactPhone || ""}
                                        setDataFormik={formik.setFieldValue}
                                        name='contactPhone'
                                        countryCode={formik.values.country || 'US'}
                                        selectSmall={true} 
                                        deleteSelectCountry={true}
                                    />
                                </div>
                                <div className="col-2 mb-4">
                                    <label className='col-form-label'>Notes</label>
                                </div>
                                <div className="col-10 mb-4">
                                    <textarea
                                        className="form-control"
                                        style={{height: 119}}
                                        autoComplete='off'
                                        {...formik.getFieldProps('notes')}
                                    />
                                </div>
                                <div className="col-2">
                                    <label className='col-form-label'>POD Date</label>
                                </div>
                                <div className="col-4">
                                    <DateInput
                                        setDataFormik={formik.setFieldValue}
                                        pickupDate={formik.values?.PODDate}
                                        name='PODDate'
                                        allowPastDates={true}
                                        touched={touchedDate.PODDate}
                                        setTouched={(boolean) => setTouchedDate({...touchedDate, PODDate: boolean})}
                                        error={formik.errors.PODDate}
                                    />
                                    <div>
                                        {formik.errors.PODDate && touchedDate.PODDate
                                            ? <small className="text-danger">Invalid date</small>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div className="col-6 d-flex">
                                    <label className='col-form-label me-2 w-50'>Signed By</label>
                                    <InputStyled
                                        type="text"
                                        className={`form-control`}
                                        autoComplete='off'
                                        {...formik.getFieldProps('signedBy')}
                                        maxLength="100"
                                        fontSize={fontSize}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 mb-3">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-danger me-4"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={() => {
                                        setTouchedDate({
                                            date: true,
                                            PODDate: true
                                        })

                                        formik.handleSubmit()
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default AddNewStopModal