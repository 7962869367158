/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../../setup/redux/RootReducer';
import { actionTypes } from '../../../../app/modules/auth/redux/PagesRedux';
import { disableSplashScreen } from '../../../../_metronic/layout/core'

const AsideDefault: FC = () => {
  const {config, classes, setLayout} = useLayout()
  const {aside} = config
  const dispatch = useDispatch()
  const [active, setActive] = useState((localStorage.getItem('minimizedMenu') === 'false' ? false : localStorage.getItem('minimizedMenu') === 'true' && true) || false)
  const customerInfo = useSelector(({auth}: any) => auth.customerInfo)  
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {  
    if(!customerInfo.isDark) {   
      config.aside.theme = 'light';
      setLayout(config)
      disableSplashScreen();
    }
    localStorage.setItem('minimizedMenu', active.toString())
    dispatch({type: actionTypes.SetMinimizedMenu, payload: active})
    if(active){
      document.body.setAttribute('data-kt-aside-minimize', 'on')
    }else{
      document.body.setAttribute('data-kt-aside-minimize', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])
  
  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto pt-3 pb-3' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/home' style={{width: '85%'}}>
            {
              user && 
              <img
                alt='Logo'
                className='logo'
                src={customerInfo?.portalMainLogo ? customerInfo?.portalMainLogo : process.env.PUBLIC_URL+'/media/logos/ShipprimusLogo.png'}
                style={{width: '100%'}}
              />
            }
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/home' style={{width: '85%'}}>
            {
              user && 
              <img
                alt='Logo'
                className='logo'
                src={customerInfo?.portalMainLogo ? customerInfo?.portalMainLogo : process.env.PUBLIC_URL+'/media/logos/ShipprimusLogo.png'}
                style={{width: '100%'}}
              />
            }
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className={`btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle ${active ? 'active' : ''}`}
            /* className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle' */
            data-kt-toggle='true'
           /*  data-kt-toggle-state='active' */
            onClick={() => setActive(!active)}
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
        
      {/* end::Footer */}
    </div>
  )
}

export {AsideDefault}
