import { useEffect, useState } from "react"
import { ToolbarProps } from "../../pages/shipping-locations/models"
import SearchInputToolbar from "../LTLandVolumeTLPages/SearchInputToolbar"
import SelectExportType from "../LTLandVolumeTLPages/SelectExportType/SelectExportType"
import ShowHideColumns from "../QuotesGridPage/Table/ShowHideColumns"
import AddNewSL from './Modals/EditOrAddModal';
import AddNewSI from '../ShippingItemsPage/EditOrAddModal';
import { ToolbarStyled } from "../Toolbar.styled"

const Toolbar = ({ search, setSearch, columns, setColumns, results, setPageNumber, setLoading, tableFor, getData, forModal, notStylized, isSL }: ToolbarProps) => {
    const [openAddNewSLModal, setOpenAddNewSLModal] = useState(false)
    const [openAddNewSIModal, setOpenAddNewSIModal] = useState(false)
    const [columnsToDownload, setColumnsToDownload] = useState([])

    const handleSearch = (e) => {
        setPageNumber(0)
        setSearch(e.target.value)
    }

    useEffect(() => {
        setColumnsToDownload(columns?.filter(column => (column?.hidden === false || column?.hidden === undefined)))
    }, [columns])

    useEffect(() => {
        if(isSL) {
            if (columnsToDownload?.find(column => column.title === 'Address')) {
                if (columnsToDownload?.find(column => column.title === 'Address 2')) {

                } else {
                    let columnsToDownloadCopy = [...columnsToDownload]
                    let indexAddress = columnsToDownloadCopy?.map(col => col.title)?.indexOf('Address')
                    let columnAddress2 = { title: 'Address 2', field: 'address1', hidden: false, cellStyle: { width: 160, minWidth: 160 } }
                    columnsToDownloadCopy?.splice(indexAddress + 1, 0, columnAddress2)
                    if (columnsToDownload?.find(column => column.title === 'Main Phone')) {
                    } else {
                        columnsToDownloadCopy?.splice(6, 0, { title: 'Main Phone', field: 'phone', hidden: false })
                    }
                    setColumnsToDownload(columnsToDownloadCopy)
                }
            } else {
                let address = columns?.find(column => column.title === 'Address')
                let columnsCopy = [...columns?.filter(column => (column?.hidden === false || column?.hidden === undefined))]

                if (address) {
                    delete address['field']
                }

                columnsCopy?.splice(2, 0, { ...address, field: 'address', render: undefined })
                columnsCopy?.splice(6, 0, { title: 'Main Phone', field: 'phone', hidden: false })
                setColumnsToDownload(columnsCopy)
            }
        }
    }, [columnsToDownload])

    return (
        <>
            <ToolbarStyled forModal={forModal} notStylized={notStylized} className="row mb-2 align-items-center">
                <div className="col-5 col-sm-3 col-md-2">
                    <SearchInputToolbar
                        searchTable={search}
                        handleSearch={handleSearch}
                        className="w-100"
                        results={results}
                        tableFor={tableFor}
                    />
                </div>
                {
                    !forModal ?
                        <div className="col-7 col-sm-9 col-md-10 d-flex align-items-center justify-content-end">
                            {
                                (tableFor === 'ShippingLocations' || tableFor === 'ShippingItems' || tableFor === 'Vendors') &&
                                <button
                                    className="btn btn-primary me-5"
                                    onClick={() => {
                                        if (tableFor === 'ShippingLocations') {
                                            setOpenAddNewSLModal(true)
                                        } else if (tableFor === 'ShippingItems') {
                                            setOpenAddNewSIModal(true)
                                        } else {
                                            setOpenAddNewVendorModal(true)
                                        }
                                    }}
                                >
                                    Add New
                                </button>
                            }
                            <div className="me-3">
                                <ShowHideColumns
                                    columns={columns}
                                    setColumns={setColumns}
                                    disabled={results.length ? false : true}
                                />
                            </div>
                            <SelectExportType
                                fileName={tableFor}
                                columns={columnsToDownload?.length ? columnsToDownload : columns.filter(column => (column?.hidden === false || column?.hidden === undefined))}
                                dataColumns={results}
                                disabled={results.length ? false : true}
                                setLoading={setLoading}
                            />
                        </div>
                        : null
                }
            </ToolbarStyled>
            <AddNewSL
                open={openAddNewSLModal}
                setOpen={setOpenAddNewSLModal}
                addOrEdit='add'
                getData={getData}
                results={results}
            />
            <AddNewSI
                open={openAddNewSIModal}
                setOpen={setOpenAddNewSIModal}
                addOrEdit='add'
                getData={getData}
                results={results}
            />
        </>
    )
}

export default Toolbar