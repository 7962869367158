import { MTableBodyRow } from '@material-table/core';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const MTableBodyRowStyled = styled(MTableBodyRow)`
    cursor: default !important;
    filter: ${props => props.selected ? 'brightness(96%)' : ''}
`

export const CircularProgressStyled = styled(CircularProgress)`
    & .MuiCircularProgress-svg {
        color: #7239EA !important
    }
`

export const CircularProgressWhiteStyled = styled(CircularProgress)`
    & .MuiCircularProgress-svg {
        color: white !important
    }
`
