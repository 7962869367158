import { modalStyles } from '../ModalStyles';
import { Modal, Fade, Backdrop } from '@mui/material';
import { UploadFileModalProps } from '../../../pages/batchquoting-page/models';
import { DivStyled, FooterStyled, PaperModal } from '../Modals.styled';
import { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

const UploadFileModal = ({open, setOpen, fileError, setFileError, fileName, handleUploadFileClick, hiddenFileInput, loadingRates, handleUploadFileChange}: UploadFileModalProps) => {
    const classes = modalStyles();
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            setFileError('')
        }, 500);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal fontSize={fontSize} widthWhenFontBig={600} className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleClose}>
                                 <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <DivStyled fontSize={fontSize}>
                            <div className="row mt-0 mt-sm-7 mb-13 align-items-center justify-content-center">
                                <div className="col-12 col-sm-3 d-flex justify-content-sm-start justify-content-center">
                                    <label className="col-form-label">Upload File</label>
                                </div>
                                <div className="col-8 col-sm-6 d-flex justify-content-center align-items-center">
                                    <label className={`${!fileName && !fileError && 'text-muted'} ${fileError && 'text-danger'}`}>{fileError ? fileError : fileName ? fileName : 'No file uploaded'}</label>
                                </div>
                                <div className="col-4 col-sm-3 d-flex justify-content-end">
                                    <button onClick={handleUploadFileClick} className={`${loadingRates && 'disabled'} btn btn-primary`}>Browse</button>
                                    <input type="file" style={{display: 'none'}} ref={hiddenFileInput} onChange={handleUploadFileChange} accept=".csv, .xls, .xlsx"/>
                                </div>
                            </div>
                        </DivStyled>
                        <FooterStyled fontSize={fontSize} className="row mb-3">
                            <div className="col-12 d-flex justify-content-end">
                               {/*  <LinkStyled className="btn btn-primary me-4" href='/media/zip/Templates.zip' download>Download Templates</LinkStyled> */}
                                <button
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </FooterStyled>
                    </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default UploadFileModal