import { Select, TextField } from "@mui/material";
import styled from "styled-components";
import PhoneInputReact from 'react-phone-number-input/input';
import { NumericFormat, PatternFormat } from "react-number-format";
import { TimePicker } from "antd";

export const TextFieldStyled = styled(TextField)`
    & .MuiInputBase-root {
        background-color: ${props => props.disabled ? '#EFF2F5 !important' : 'white'};
        font-family: Poppins !important;
        font-weight: 500 !important;
        color: #5E6278 !important;
        padding-right: 21px !important
    }
`

export const SelectStyled = styled(Select)`
    height: ${props => props.fontSize === 9 ? '30px' : props.fontSize === 11 ? '40px' : 'var(--input-height)'};
    &.MuiOutlinedInput-root {
        background-color: ${props => props.disabled ? '#EFF2F5 !important' : 'white'};
    };
    &.MuiInputBase-root {
        padding-left: 0px !important
    }
`

export const NumberFormatStyled = styled(NumericFormat)`
    height: ${props => props.fontSize === 9 ? '30px' : props.fontSize === 11 ? '40px' : 'var(--input-height)'};
    background-color: ${props => props.disabled ? '#EFF2F5 !important' : 'white'}
`

export const PatternFormatStyled = styled(PatternFormat)`
    height: ${props => props.fontSize === 9 ? '30px' : props.fontSize === 11 ? '40px' : 'var(--input-height)'};
    background-color: ${props => props.disabled ? '#EFF2F5 !important' : 'white'}
`

export const InputStyled = styled.input`
    height: ${props => props.fontSize === 9 ? '30px !important' : props.fontSize === 11 ? '40px !important' : 'var(--input-height) !important'};
    border-color: ${props => props.error && '#F1416C !important'};
`

export const PhoneInputReactStyled = styled(PhoneInputReact)`
    height: ${props => props.fontSize === 9 ? '30px !important' : props.fontSize === 11 ? '40px !important' : 'var(--input-height) !important'};
`

export const TimePickerStyled = styled(TimePicker)`
    height: ${props => props.fontSize === 9 ? '30px !important' : props.fontSize === 11 ? '40px !important' : 'var(--input-height) !important'};
    border-color: ${props => props.error && '#F1416C !important'};
    &.ant-picker-disabled {
        background-color: #EFF2F5;
        color: #5e6278 !important
    }
    &.ant-picker-input>input[disabled] {
        color: #5e6278 !important
    }
`