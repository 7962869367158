import { Backdrop, Fade, Paper, TablePagination } from '@mui/material'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { modalStyles } from '../../ModalStyles'
import MaterialTable from '@material-table/core';
import { tableStyles } from '../Tables/TableStyles';
import CircleIcon from '@mui/icons-material/Circle';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import SearchInputToolbar from '../SearchInputToolbar';
import { ShippingItemsModalProps } from '../../../pages/shipping-items/models';
import { ModalStyled } from '../../Modals.styled';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { RootState } from '../../../../setup';
import { columnsModel } from '../../PagesModels';
import { MTableBodyRowStyled } from '../../QuotesGridPage/Table/Table.styled';

let cancelToken
const GET_SHIPPINGITEMS = '/applet/v1/database/shippingitem'

const ShippingItemsModal = ({open, setOpen, setDataFormik, index}: ShippingItemsModalProps) => {
    const dispatch = useDispatch()
    const classesTable = tableStyles()
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [results, setResults] = useState([])
    const [totalResults, setTotalResults] = useState(0)
    const [pageNumber, setPageNumber] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classesModal = modalStyles({fontSize: fontSize})

    const hazmatRender = (rowData) => {
        return (
          <div style={{textAlign: 'center'}}>
            {
              rowData.hazmat ?
              <>
                <div className='mb-2'><CircleIcon style={{color: '#47BE7D'}}/></div>
                <div>{`UN# ${rowData.UN}`}</div>
              </>
              : <CircleIcon style={{color: '#F1416C'}}/>
            }
          </div>
        )
    }

    const [columns] = useState([
        { title: 'Name', field: 'name', hidden: false},
        { title: 'Weight', field: 'weight', hidden: false},
        { title: 'Length', field: 'length', hidden: false},
        { title: 'Width', field: 'width', hidden: false},
        { title: 'Height', field: 'height', hidden: false},
        { title: 'Class', field: 'class', hidden: false},
        { title: 'Hazmat', field: 'hazmat', render: hazmatRender, sorting: false, hidden: false, headerStyle: {textAlign: 'center'}},
    ])

    const handleClose = () => {
        setOpen(false)
        setPageNumber(0)
        setRowsPerPage(10)
        setSearch('')
    }

    const handleSearch = (e) => {
        setPageNumber(0)
        setSearch(e.target.value)
    }

    const setData = (rowData) => {
        setDataFormik(`freightInfo[${index}].weight`, parseFloat(rowData.weight))
        setDataFormik(`freightInfo[${index}].L`, parseFloat(rowData.length))
        setDataFormik(`freightInfo[${index}].W`, parseFloat(rowData.width))
        setDataFormik(`freightInfo[${index}].H`, parseFloat(rowData.height))
        setDataFormik(`freightInfo[${index}].Lus`, parseFloat(rowData.length))
        setDataFormik(`freightInfo[${index}].Wus`, parseFloat(rowData.width))
        setDataFormik(`freightInfo[${index}].Hus`, parseFloat(rowData.height))
        setDataFormik(`freightInfo[${index}].class`, rowData.class)
        setDataFormik(`freightInfo[${index}].hazmat`, rowData.hazmat)
        setDataFormik(`freightInfo[${index}].UN`, rowData.UN)
        setDataFormik(`freightInfo[${index}].nmfc`, rowData.NMFC)
        setDataFormik(`freightInfo[${index}].commodity`, rowData.name)
        handleClose()
    }

    const selectShippingItem = (e, rowData) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                setData(rowData)
                break;
        }
    }

    useEffect(() => {
        if(open){
            setLoading(true)

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('Operation canceled due to new request.')
            }
        
            cancelToken = axios.CancelToken.source()
        
            axios.get(GET_SHIPPINGITEMS, {
                cancelToken: cancelToken.token,
                params: {
                    page: pageNumber + 1,
                    limit: rowsPerPage,
                    search: search.trim()
                }
            }).then((results) => {
                setResults(results.data.data.results)
                setTotalResults(results.data.data.pagingDetails.totalResults)
                setLoading(false)
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Shipping Items')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, pageNumber, rowsPerPage, search])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesModal.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classesModal.paperEditModalSI} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Shipping Items</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <div className='mb-6 w-75'>
                            <SearchInputToolbar
                                searchTable={search}
                                handleSearch={handleSearch}
                                className='w-50 w-sm-25'
                            />
                        </div>
                        <div style={{maxHeight: 500, overflow: 'auto'}}>
                            <MaterialTable
                                isLoading={loading}
                                style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                                columns={columns as columnsModel}
                                data={results}
                                options={{
                                    thirdSortClick: false,
                                    actionsColumnIndex: -1,
                                    toolbar: false,
                                    filtering: false,
                                    draggable: false,
                                    rowStyle: (data, idx) => idx%2 !== 0 ? {
                                        backgroundColor: '#f4f4f4',
                                    } : {
                                        backgroundColor: '#ffff',
                                    },
                                    pageSizeOptions: [10,25,50,100],
                                    pageSize: rowsPerPage
                                }}
                                localization={{
                                pagination: {
                                    labelRows: '',
                                    labelRowsPerPage: 'Shipping Items per page:',
                                }
                                }}
                                onPageChange={(page) => setPageNumber(page)}
                                onRowsPerPageChange={(number) => setRowsPerPage(number)}
                                components={{
                                    Container: (props) => <Paper className={classesTable.tableQuotesGrid} {...props} />,
                                    Row: rowProps => <MTableBodyRowStyled {...rowProps} onRowClick={(e) => selectShippingItem(e, rowProps.data)} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,
                                    Pagination: props => <TablePagination {...props} count={totalResults} page={pageNumber} onPageChange={(e, page) => setPageNumber(page)}/>
                                }}
                            />
                        </div>
                        <div className="row mb-3 mt-5">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default ShippingItemsModal