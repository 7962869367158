export const shortCutsDateInput = (key, name, setNumberDown, numberDown, setMinusDown, minusDown, setTDown, TDown, setDataFormik) => {
    if (parseInt(key)) {
        setNumberDown(key)
    } else {
        setNumberDown('')
    }

    if (key === 't' || key === 'T') {
        setDataFormik(name, new Date())
        setNumberDown('')
        setTDown(true)
    } else if (TDown && parseInt(key) >= 0 && numberDown && parseInt(numberDown + key) <= 30) {
        setDataFormik(name, new Date(minusDown ? new Date().setDate(new Date().getDate() - (parseInt(numberDown + key))) : new Date().setDate(new Date().getDate() + (parseInt(numberDown + key)))))
    } else if (TDown && parseInt(key)) {
        setDataFormik(name, new Date(minusDown ? new Date().setDate(new Date().getDate() - parseInt(key)) : new Date().setDate(new Date().getDate() + parseInt(key))))
    } else if (key === '-') {
        setMinusDown(true)
    }
}