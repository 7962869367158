import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { AccessorialsProps } from '../../pages/drayage/models';
import AccessorialsModal from '../AccessorialsModal';
import EditIcon from '@mui/icons-material/Edit';
import { ItalicText } from './DrayagePage.styled';

const Accessorials = ({accessorialsSelected, index, handleChange}: AccessorialsProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [openAccModal, setOpenAccModal] = useState(false)
    const [valueRequired, setValueRequired] = useState({})
    const [weight, setWeight] = useState('')
    const [accessorials] = useState([
        'HAZ',
        'REEF',
        'RESI',
        'HOGO',
        'OWT'
    ])

    const [accessorialsToShow, setAccessorialsToShow] = useState([])

    useEffect(() => {       
        let accessorialsArray = [] 
        accessorials.forEach(accessorial => {
            if(user.enabledAccessorials?.filter(acc => acc.code === accessorial && acc.rateTypes?.includes('DRAY'))){
                let acc = user?.enabledAccessorials?.filter(acc => acc.code === accessorial && acc.rateTypes?.includes('DRAY'))[0]
                if(acc?.code || acc?.name){
                    accessorialsArray?.push(acc)
                }
            }
        })

        setAccessorialsToShow(accessorialsArray)
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [])

    return (
        <>
            <div className='row align-items-center'>
                <div className='col-12 d-none d-md-flex align-items-center'>
                    {
                        accessorialsToShow.map(accessorial => (
                            <div className="form-check form-check-custom form-check-solid form-check-sm me-4" key={accessorial?.code}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={`freightInfo[${index}].accessorials`}
                                    onChange={(e) => {
                                        if(e.target.checked){
                                            if((typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired).required : accessorial?.valueRequired.required)){
                                                setValueRequired(typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired) : accessorial?.valueRequired)
                                                setOpenAccModal(true)
                                            }
                                        }
                                        handleChange(e)
                                    }}
                                    value={accessorial?.code}
                                    checked={accessorialsSelected?.filter(acc => acc === accessorial?.code)?.length ? true : false}
                                    id={`${accessorial?.nameShort}${index}`}
                                />
                                <label className="form-check-label" htmlFor={`${accessorial?.nameShort}${index}`}>
                                    {accessorial?.nameShort === 'Over Weight' ? 'Overweight' : accessorial?.nameShort}
                                </label>
                                {
                                    accessorialsSelected?.filter(acc => acc === accessorial?.code)?.length && (typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired).required : accessorial?.valueRequired.required)
                                    ? 
                                    <>
                                        <ItalicText className='m-0 ms-3 me-1'>{weight}</ItalicText>
                                        <Tooltip title='Edit values'>
                                            <IconButton
                                                onClick={() => {
                                                    setValueRequired(typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired) : accessorial?.valueRequired)
                                                    setOpenAccModal(true)
                                                }}
                                                size="small"
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    : null
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="col-12 d-flex d-md-none justify-content-center justify-content-sm-start align-items-center mt-5">
                    {
                        accessorialsToShow.filter(acc => acc?.code === 'HAZ' || acc?.code === 'REEF' || acc?.code === 'RESI').map(accessorial => (
                            <div className={`form-check form-check-custom form-check-solid form-check-sm ${accessorial?.code === 'RESI' ? 'me-0' : 'me-4'}`} key={accessorial?.code}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={`freightInfo[${index}].accessorials`}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    value={accessorial?.code}
                                    checked={accessorialsSelected?.filter(acc => acc === accessorial?.code)?.length ? true : false}
                                    id={`${accessorial?.nameShort}${index}`}
                                />
                                <label className="form-check-label" htmlFor={`${accessorial?.nameShort}${index}`}>
                                    {accessorial?.nameShort}
                                </label>
                            </div>
                        ))
                    }
                </div>
                <div className="col-12 d-flex d-md-none align-items-center justify-content-center justify-content-sm-start mb-5 mt-5">
                    {
                        accessorialsToShow.filter(acc => acc?.code === 'HOGO' || acc?.code === 'OWT').map(accessorial => (
                            <div className={`form-check form-check-custom form-check-solid form-check-sm ${accessorial?.code === 'OWT' ? 'me-0' : 'me-4'}`} key={accessorial?.code}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={`freightInfo[${index}].accessorials`}
                                    onChange={(e) => {
                                        if(e.target.checked){
                                            if((typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired)?.required : accessorial?.valueRequired?.required)){
                                                setValueRequired(typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired) : accessorial?.valueRequired)
                                                setOpenAccModal(true)
                                            }
                                        }
                                        handleChange(e)
                                    }}
                                    value={accessorial?.code}
                                    checked={accessorialsSelected?.filter(acc => acc === accessorial?.code)?.length ? true : false}
                                    id={`${accessorial?.nameShort}${index}`}
                                />
                                <label className="form-check-label" htmlFor={`${accessorial?.nameShort}${index}`}>
                                    {accessorial?.nameShort === 'Over Weight' ? 'Overweight' : accessorial?.nameShort}
                                </label>
                                {
                                    accessorialsSelected?.filter(acc => acc === accessorial?.code)?.length && (typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired)?.required : accessorial?.valueRequired?.required)
                                    ?
                                    <>
                                        <ItalicText className='m-0 ms-3 me-1'>{weight}</ItalicText>
                                        <Tooltip title='Edit values'>
                                            <IconButton
                                                onClick={() => {
                                                    setValueRequired(typeof accessorial?.valueRequired === 'string' ? JSON.parse(accessorial?.valueRequired) : accessorial?.valueRequired)
                                                    setOpenAccModal(true)
                                                }}
                                                size="small"
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    : null
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <AccessorialsModal
                open={openAccModal}
                setOpen={setOpenAccModal}
                data={valueRequired}
                setWeight={setWeight}
                weight={weight}
            />
        </>
    )
}

export default Accessorials