import clsx from 'clsx'
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core/LayoutProvider'
import {usePageData} from '../../../core/PageData'

const DefaultTitle: FC = () => {
  const {HistoryElement, pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const {config, classes} = useLayout()

  const [allowedHistory] = useState([
    'LTL',
    'Volume/TL',
    'Drayage',
    'Quotes Grid',
    'Tracking Grid',
    'Invoices Grid',
    'Vendors',
    'Shipping Items',
    'Shipping Locations'
  ])
  
  return (
    <div
      id='kt_page_title'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
      className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <>
          <h1 className={allowedHistory.includes(pageTitle) ? `col-11 d-flex align-items-center text-dark fw-bolder my-1 fs-3 ${pageTitle !== 'Home' && 'pe-lg-5'}` : pageTitle === 'New Booking' ? `col-4 col-lg-12 d-flex align-items-center text-dark fw-bolder my-1 fs-3 pe-lg-5` : `d-flex align-items-center text-dark fw-bolder my-1 fs-3 ${pageTitle !== 'Home' && 'pe-lg-5'}`}>
            {pageTitle}
            {pageDescription && config.pageTitle && config.pageTitle.description && (
              <>
                <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
              </>
            )}
          </h1>
          {allowedHistory.includes(pageTitle) && <div className='col-1 d-flex justify-content-end'>{HistoryElement}</div>}
          {/* {
            pageTitle === 'New Booking' && <div className="col-8 d-flex d-lg-none align-items-center justify-content-end">
              <h5 className='mb-0 me-2' style={{fontSize: 14}}>Bill To</h5> <h5 className='mb-0' style={{fontSize: 14, fontWeight: 400}}>{user?.billToInformation.name}</h5>
            </div>
          } */}
        </>
      )}
      {/* end::Title */}
      {pageBreadcrumbs &&
        pageBreadcrumbs.length > 0 &&
        config.pageTitle &&
        config.pageTitle.breadCrumbs && (
          <>
            {config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'></span>
            )}
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              {Array.from(pageBreadcrumbs).map((item, index) => (
                <li
                  className={clsx('breadcrumb-item', {
                    'text-dark': !item.isSeparator && item.isActive,
                    'text-muted': !item.isSeparator && !item.isActive,
                  })}
                  key={`${item.path}${index}`}
                >
                   {/* <h5>hola</h5> */}
                  {!item.isSeparator ? (
                    <Link className='text-muted text-hover-primary' to={item.path}>
                      <h5 className='text-muted text-hover-primary mb-0' style={{fontSize: 12.35, fontWeight: 500}}>{item.title}</h5>
                    </Link>
                  ) : (
                    <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  )}
                </li>
              ))}
              <li>
                <h5 className='breadcrumb-item text-dark mb-0' style={{fontSize: 12.35, fontWeight: 500}}>{pageTitle}</h5>
              </li>
            </ul>
          </>
        )}
    </div>
  )
}

export {DefaultTitle}
