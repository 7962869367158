import {useState, useEffect} from 'react'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import { SwitchLTL } from '../../components/LTLandVolumeTLPages/SwitchLTL';
import { useFormik } from 'formik';
import { CountryOptions } from '../../data/CountryOptions';
import ZipcodesWithCountry from '../../components/LTLandVolumeTLPages/ZipcodesWithCountry';
import { UserModel } from '../../modules/auth/models/UserModel';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import FreightInformation from '../../components/LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformation';
import { FreightTotals } from '../../components/LTLandVolumeTLPages/FreightInfo/FreightTotals';
import { v4 as uuidv4 } from 'uuid';
import SelectUOM from '../../components/LTLandVolumeTLPages/FreightInfo/SelectUOM';
import * as Yup from "yup"
import DateInput from '../../components/LTLandVolumeTLPages/DateInput';
import axios from 'axios';
import { format } from 'date-fns';
import { OriginOrDestinationObject, RowTableObject } from './models';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { RatesTable } from '../../components/LTLandVolumeTLPages/Tables/RatesTable/RatesTable';
import Accessorials from '../../components/LTLandVolumeTLPages/AccesorialsComponent/Accessorials';
import HistorySelect from '../../components/LTLandVolumeTLPages/HistoryComponent/HistorySelect';
import { useParams } from 'react-router-dom';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { marginSize } from '../../helpers/marginSize';
import { TypeOptions } from '../../data/TypeOptions';
import ConfirmTimedOutVendorsModal from '../../components/LTLandVolumeTLPages/Modals/ConfirmTimedOutVendorsModal';
import { getRates } from '../../helpers/getRates';
import { keepWaitingVendors } from '../../helpers/keepWaitingVendors';
import NoVendorsSetUpModal from '../../components/Modals/NoVendorsSetUpModal';
import InsuranceRequiredModal from '../book/NewBooking/InsuranceRequiredModal';

interface freightErrors {
  qty: string,
  weight: string,
  nmfc: string,
  L: string,
  W: string,
  H: string
}

let cancelToken = axios.CancelToken
let source = cancelToken.source()
const GET_LNFT = '/api/v1/tools/lnft'
const GET_LANEDISTANCE = '/api/v1/tools/distancematrix'
const GET_QUOTE = '/api/v1/database/quote'

export const LTL = () => {
  const resetValues = useSelector<RootState>(({pages}) => pages?.resetValues) as boolean
  const [openHazContactModal, setOpenHazContactModal] = useState(false)
  const [origin, setOrigin] = useState<OriginOrDestinationObject>({
    City: "",
    Country: "",
    Name: "",
    State: "",
    Zipcode: "",
    isSL: false,
    isZipcode: false,
    id: null
  })
  const [destination, setDestination] = useState<OriginOrDestinationObject>({
    City: "",
    Country: "",
    Name: "",
    State: "",
    Zipcode: "",
    isSL: false,
    isZipcode: false,
    id: null
  })
  const [rates, setRates] = useState([] as Array<RowTableObject>)
  const [ratesVOL, setRatesVOL] = useState([] as Array<RowTableObject>)
  const [ratesVOLCheaper, setRatesVOLCheaper] = useState(0)
  const [VOLQuoteIsCheaper, setVOLQuoteIsCheaper] = useState(false)
  const [linearProgress, setLinearProgress] = useState(0);
  const [showTable, setShowTable] = useState(false)
  const [touchedPickupDate, setTouchedPickupDate] = useState(false)
  const [zipcodeOrigin, setZipcodeOrigin] = useState('')
  const [zipcodeDestination, setZipcodeDestination] = useState('')
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [ratesStopped, setRatesStopped] = useState(false)
  const [loadVOLQuotes, setLoadVOLQuotes] = useState(undefined)
  const [needRateType, setNeedRateType] = useState(false)
  const [laneDistance, setLaneDistance] = useState("")
  const [actionClicked, setActionClicked] = useState(false)
  const [LNFT, setLNFT] = useState(0)
  const dispatch = useDispatch()
  let {setHistoryElement} = usePageData()
  let { id } = useParams();
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
  const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
  const [selectedUn, setSelectedUn] = useState({})
  const [enabledVendors, setEnabledVendors] = useState([])
  const [vendorsProcessed, setVendorsProcessed] = useState('0')
  const [vendorsTimedOut, setVendorsTimedOut] = useState([])
  const [openConfirmTimedOutModal, setOpenConfirmTimedOutModal] = useState(false)
  const [openNoVendorsSetUpModal, setOpenNoVendorsSetUpModal] = useState(false)
  const [openInsuranceRequiredModal, setOpenInsuranceRequiredModal] = useState(false)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const initialValues = {
    courier: true,
    LTL: true,
    AIR: true,
    origin: {
      City: "",
      Country: "",
      Name: "",
      State: "",
      Zipcode: "",
      isSL: false,
      isZipcode: false,
      id: null
    },
    destination: {
      City: "",
      Country: "",
      Name: "",
      State: "",
      Zipcode: "",
      isSL: false,
      isZipcode: false,
      id: null
    },
    pickupDate: new Date(),
    accessorials: {
      origin: [],
      destination: [],
      other: []
    },
    freightInfo: [{
      id: uuidv4(),
      qty: '',
      dimType: {
        name: "PALLET",
        value: "PLT"
      },
      STC: '',
      weight: '',
      each: 'Each',
      L: '',
      W: '',
      H: '',
      class: '',
      commodity: '',
      nmfc: '',
      weightus: '',
      Lus: '',
      Wus: '',
      Hus: '',
      totalCft: '',
      hazmat: false,
      UN: '',
      stack: null,
      volume: ''
    }],
    freightInfoUOM: {
      value: 'US',
      oldValue: ''
    },
    freightInfoTotals: {
      totalPieces: 0,
      totalWeight: 0,
      totalCFT: 0,
      totalDensity: 0,
      symbol: 'lbs'
    },
    insuranceAmount: null,
    insuranceFreight: false,
    insuranceAddOn: false,
    commodityInsurance: ''
  }

  Yup.addMethod<Yup.DateSchema>(Yup.date, "lessThanToday", function (message) {
    return this.test("test-name", message, function(value: any) {
      const { path, createError } = this
      return( value < new Date().setHours(0,0,0,0) ? createError({message, path}) : value )
    })
  })

  Yup.addMethod<Yup.StringSchema>(Yup.string, "moreThanZero", function (message) {
    return this.test("test-name", message, function(value: any) {
      const { path, createError } = this
      return( typeof value === 'string' ? (parseInt(value) ? value : createError({message, path})) : value > 0 ? value : createError({message, path}))
    })
  })

  const validationSchema = Yup.object().shape({
    origin: Yup.object({
      City: Yup.string().required('Required'),
      Country: Yup.string().required('Required'),
      Name: Yup.string(),
      State: Yup.string().required('Required'),
      Zipcode: Yup.string().required('Required'),
      isSL: Yup.boolean(),
      isZipcode: Yup.boolean()
    }),
    destination: Yup.object({
      City: Yup.string().required('Required'),
      Country: Yup.string().required('Required'),
      Name: Yup.string(),
      State: Yup.string().required('Required'),
      Zipcode: Yup.string().required('Required'),
      isSL: Yup.boolean(),
      isZipcode: Yup.boolean()
    }),
    freightInfo: Yup.array().of(
      Yup.object().shape({
        qty: Yup.string().required("Required"),
        weight: Yup.string().required("Required"),
        L: user?.requireDimensions ? (Yup.string() as any).moreThanZero('Required').required() : Yup.string(),
        W: user?.requireDimensions ? (Yup.string() as any).moreThanZero('Required').required() : Yup.string(),
        H: user?.requireDimensions ? (Yup.string() as any).moreThanZero('Required').required() : Yup.string(),
        nmfc: Yup.string().test('length', 'Must be 8 numbers or empty', (value) => {
          if(value && /\s/g.test(value)){
            return false
          }else{
            return true
          }
        })
      })
    ),
    pickupDate: (Yup.date() as any).lessThanToday('Pickup date cannot be in the past').required()
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      if(!values.LTL && !values.courier && !values.AIR){
        setNeedRateType(true)
        setShowTable(false)
        setRates([])
        setRatesVOL([])
        setLinearProgress(0)
        setRatesStopped(false)
        setVOLQuoteIsCheaper(false)
        setRatesVOLCheaper(0)
        setLoadVOLQuotes(undefined)
        return
      }

      if([...formik?.values?.accessorials?.origin, ...formik?.values?.accessorials?.destination, ...formik?.values?.accessorials?.other].filter(a => a.code === 'INS')?.length && (!formik.values.commodityInsurance || !formik.values.insuranceAmount)){
          setOpenInsuranceRequiredModal(true)
      }else{
          let enabledVendorsLTL = user.enabledVendors.filter(vendor => ((values.LTL && vendor.type === 'LTL') || (values.courier && vendor.type === 'SP') || (values.AIR && vendor.type === 'INTL')))
          let enabledVendorsVOL = user.enabledVendors.filter(vendor => (
              values.freightInfoTotals.totalWeight > (values.freightInfoTotals.symbol === 'lbs' ? 5000 : 2268) || values.freightInfoTotals.totalCFT > 200
              ? vendor.type === 'VOL' : null
          ))
          let enabledVendorsFiltered = [...enabledVendorsLTL, ...enabledVendorsVOL]
          setNeedRateType(false)
          source = cancelToken.source()

          let subVendors = []
          if(!enabledVendorsFiltered.length){
            if(values.courier){
              user.enabledVendors.forEach(vendor => {
                let subVendorsSP = vendor?.subVendors?.map(subVendor => ({...subVendor, id: vendor.id})).filter(subVendor => subVendor?.carrierType === 'SP')
                if(subVendorsSP.length){
                  subVendors.push(...subVendorsSP)
                  enabledVendorsFiltered = subVendors
                  enabledVendorsLTL.push(vendor)
                }
              })
            }

            if(values.LTL){
              user.enabledVendors.forEach(vendor => {
                let subVendorsLTL = vendor?.subVendors?.map(subVendor => ({...subVendor, id: vendor.id})).filter(subVendor => subVendor?.carrierType === 'LTL')
                if(subVendorsLTL.length){
                  subVendors.push(...subVendorsLTL)
                  enabledVendorsFiltered = subVendors
                  enabledVendorsLTL.push(vendor)
                }
              })
            }

            if(values.freightInfoTotals.totalWeight > (values.freightInfoTotals.symbol === 'lbs' ? 5000 : 2268) || values.freightInfoTotals.totalCFT > 200){
              user.enabledVendors.forEach(vendor => {
                let subVendorsVOL = vendor?.subVendors?.map(subVendor => ({...subVendor, id: vendor.id})).filter(subVendor => subVendor?.carrierType === 'VOL')
                if(subVendorsVOL.length){
                  subVendors.push(...subVendorsVOL)
                  enabledVendorsFiltered = subVendors
                  enabledVendorsVOL.push(vendor)
                }
              })
            }

            if(values.AIR){
              user.enabledVendors.forEach(vendor => {
                let subVendorsAIR = vendor?.subVendors?.map(subVendor => ({...subVendor, id: vendor.id})).filter(subVendor => subVendor?.carrierType === 'AIR')
                if(subVendorsAIR.length){
                  subVendors.push(...subVendorsAIR)
                  enabledVendorsFiltered = subVendors
                  enabledVendorsLTL.push(vendor)
                }
              })
            }
          }

          if(enabledVendorsFiltered?.length){
            getRates(values, selectedAcc, enabledVendorsFiltered, enabledVendorsLTL, enabledVendorsVOL, setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, setRatesVOL, setLinearProgress, setRatesStopped, setVOLQuoteIsCheaper, setRatesVOLCheaper, setLoadVOLQuotes, source, normalizeParams, 'historyLTL')
          }else{
            setOpenNoVendorsSetUpModal(true)
          }

          getLNFTandDistance(values)
      }
      
    }
  })

  const normalizeParams = (vendorId) => {
    const data = formik.values

    return {
      vendorId: vendorId,
      originCity: data.origin.City,
      originState: data.origin.State,
      originZipcode: data.origin.Zipcode,
      originCountry: data.origin.Country,
      destinationCity: data.destination.City,
      destinationState: data.destination.State,
      destinationZipcode: data.destination.Zipcode,
      destinationCountry: data.destination.Country,
      freightInfo: JSON.stringify(
        data.freightInfo.map((freight: any) => {
          return {
            qty: parseFloat(freight.qty),
            weight: parseFloat(freight.weight),
            weightType: freight.each === "Each" ? "each" : "total",
            length: parseFloat(freight.L),
            width: parseFloat(freight.W),
            height: parseFloat(freight.H),
            class: parseFloat(freight.class) || 0,
            hazmat: freight.hazmat,
            commodity: freight.commodity,
            dimType: freight.dimType.value,
            stack: (freight.stack === 'No' || !freight.stack) ? false : true,
            stackAmount: (freight.stack === 'No' || !freight.stack) ? null : parseInt(freight.stack),
            nmfc: freight.nmfc,
            volume: parseFloat(freight.volume) || null,
            UN : freight?.UN,
            UNDescription: selectedUn?.description,
            UNHAZClass:  selectedUn?.HAZClass,
            UNPKGGroup: selectedUn?.PKGGroup,
            UNLabels: selectedUn?.labels,
          }
        })
      ),
      UOM: data.freightInfoUOM.value,
      'accessorialsList[]': [
        ...data.accessorials.destination.map(acc => acc.code),
        ...data.accessorials.origin.map(acc => acc.code),
        ...data.accessorials.other.map(acc => acc.code)
      ].join('|'),
      pickupDate: format(data.pickupDate, "yyyy-MM-dd"),
      commodityInsurance: data.commodityInsurance,
      insuranceAmount: data.insuranceAmount,
      insuranceFreight: data.insuranceFreight,
      insuranceAddOn: data.insuranceAddOn
    }
  }

  const getLNFTandDistance = (values) => {
    setLNFT(0)
    setLaneDistance("")

    const getLNFT = axios.get(GET_LNFT, {params: {
      returnLayout: false,
      companyDefaultTruck: true,
      freightInfo: JSON.stringify(
        values.freightInfo.map((freight) => {
          return {
            qty: parseFloat(freight.qty),
            weight: parseFloat(freight.weight),
            weightType: freight.each === "Each" ? "each" : "total",
            length: parseFloat(freight.L) || 1,
            width: parseFloat(freight.W) || 1,
            height: parseFloat(freight.H) || 1,
            class: parseFloat(freight.class) || 0,
            hazmat: freight.hazmat,
            commodity: freight.commodity,
            dimType: freight.dimType.value,
            stack: false
          }
        })
      ),
      UOM: values.freightInfoUOM.value,
    }}).then(response => response.data.data.results)

    const getDistance = axios.get(GET_LANEDISTANCE, {params: {
      'origins[]': values.origin.Zipcode,
      'destinations[]': values.destination.Zipcode,
      units: 'imperial'
    }}).then(response => response.data.data.results?.length ?  response.data.data.results[0] : "")

    Promise.all([getLNFT, getDistance]).then(values => {
      setLNFT(values[0]?.LNFT)
      setLaneDistance(values[1]?.distance?.text)
    })
  }

  useEffect(() => {
    let average = 0

    if(linearProgress >= 100 || ratesStopped){
        const firstRates = rates.sort((a, b) => a.total - b.total).slice(0, 5);

        average = firstRates.reduce(function (avg, value, index, {length}) {
            return avg + value.total / length
        }, 0);
        
        const ratesVOLCheaper = ratesVOL.filter(rate => rate.total < average)

        if(ratesVOLCheaper.length){
            setVOLQuoteIsCheaper(true)
            let cheaper = ratesVOLCheaper.reduce((prev, current) => { 
                return (prev.total < current.total) ? prev : current  })
            setRatesVOLCheaper(cheaper.total)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linearProgress, ratesStopped])

  useEffect(() => {
    if(loadVOLQuotes === true){
        setRates([...rates, ...ratesVOL])
        setVOLQuoteIsCheaper(false)
    }else{
        setRatesVOL([])
        setVOLQuoteIsCheaper(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadVOLQuotes])

  const [count, setCount] = useState(0)
  const [hasAccessorials, setHasAccessorials] = useState(false)

  useEffect(() => {
    if(hasAccessorials){
      if(count === 0){
        if(formik.values.origin.Zipcode && formik.values.destination.Zipcode && formik.values.freightInfo[0].qty && formik.values.freightInfo[0].weight && id && (formik.values.accessorials.other.length || formik.values.accessorials.destination.length || formik.values.accessorials.origin.length)){
          setCount(count + 1)
          const enabledVendorsLTL = user.enabledVendors.filter(vendor => ((formik.values.LTL && vendor.type === 'LTL') || (formik.values.courier && vendor.type === 'SP') || (formik.values.AIR && vendor.type === 'INTL')))
          const enabledVendorsVOL = user.enabledVendors.filter(vendor => (
              formik.values.freightInfoTotals.totalWeight > (formik.values.freightInfoTotals.symbol === 'lbs' ? 5000 : 2268) || formik.values.freightInfoTotals.totalCFT > 200
              ? vendor.type === 'VOL' : null
          ))
          const enabledVendorsFiltered = [...enabledVendorsLTL, ...enabledVendorsVOL]
          source = cancelToken.source()
          getRates(formik.values, selectedAcc, enabledVendorsFiltered, enabledVendorsLTL, enabledVendorsVOL, setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, setRatesVOL, setLinearProgress, setRatesStopped, setVOLQuoteIsCheaper, setRatesVOLCheaper, setLoadVOLQuotes, source, normalizeParams, 'historyLTL')
          getLNFTandDistance(formik.values)
        }
      }
    }else{
      if(count === 0){
        if(formik.values.origin.Zipcode && formik.values.destination.Zipcode && formik.values.freightInfo[0].qty && formik.values.freightInfo[0].weight && id){
          setCount(count + 1)
          const enabledVendorsLTL = user.enabledVendors.filter(vendor => ((formik.values.LTL && vendor.type === 'LTL') || (formik.values.courier && vendor.type === 'SP') || (formik.values.AIR && vendor.type === 'INTL')))
          const enabledVendorsVOL = user.enabledVendors.filter(vendor => (
              formik.values.freightInfoTotals.totalWeight > (formik.values.freightInfoTotals.symbol === 'lbs' ? 5000 : 2268) || formik.values.freightInfoTotals.totalCFT > 200
              ? vendor.type === 'VOL' : null
          ))
          const enabledVendorsFiltered = [...enabledVendorsLTL, ...enabledVendorsVOL]
          source = cancelToken.source()
          getRates(formik.values, selectedAcc, enabledVendorsFiltered, enabledVendorsLTL, enabledVendorsVOL, setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, setRatesVOL, setLinearProgress, setRatesStopped, setVOLQuoteIsCheaper, setRatesVOLCheaper, setLoadVOLQuotes, source, normalizeParams, 'historyLTL')
          getLNFTandDistance(formik.values)
        }
      }
    }
    
    if(id ? linearProgress > 50 : linearProgress > 0){
      source.cancel('Operation canceled by the user.')
      setRatesStopped(true)
      setLinearProgress(0)
      setRates([])
      setNeedRateType(false)
      setRatesVOL([])
      setVOLQuoteIsCheaper(false)
      setRatesVOLCheaper(0)
      setLoadVOLQuotes(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.freightInfo, formik.values.origin, formik.values.destination, formik.values.accessorials, formik.values.pickupDate])

  useEffect(() => {
    setLNFT(0)
    setLaneDistance("")
  }, [formik.values.freightInfo, formik.values.origin, formik.values.destination])

  useEffect(() => {
    formik.setFieldValue(`accessorials.origin`, selectedAcc.filter(acc => acc.category === 'Origin'))
    formik.setFieldValue(`accessorials.destination`, selectedAcc.filter(acc => acc.category === 'Destination'))
    formik.setFieldValue(`accessorials.other`, selectedAcc.filter(acc => acc.category === 'Other'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAcc])

  const getCode = (value) => {
    return value.length === 3 ? CountryOptions.find(country => country.CodePrimus === value).Code : value
  }
  
  useEffect(() => {
    if(id){      
      axios.get(`${GET_QUOTE}/${id}`).then(results => {
        let quoteInfo = results.data.data.results

        if(quoteInfo.accessorialsList.length){
          setHasAccessorials(true)
        }
        
        if(quoteInfo.shipper.name.length){
          axios.get('/api/v1/database/shippinglocation', {
            params: {
                search: quoteInfo.shipper.name,
                active: true
            }
          }).then(results => {
            if(results.data.data.results[0].name === quoteInfo.shipper.name){
              setOrigin({
                City: quoteInfo?.shipper.city,
                Country: getCode(quoteInfo?.shipper.country),
                Name: quoteInfo?.shipper.name,
                State: quoteInfo?.shipper.state,
                Zipcode: quoteInfo?.shipper.zipCode,
                id: quoteInfo.shipper.id || '',
                isPort: false,
                isSL: true,
                isZipcode: false,
              })
              setZipcodeOrigin(`${quoteInfo?.shipper?.name}. ${quoteInfo?.shipper?.city}, ${quoteInfo?.shipper.state} ${quoteInfo?.shipper.zipCode}`)
    
              setDestination({
                City: quoteInfo?.consignee.city,
                Country: getCode(quoteInfo?.consignee.country),
                Name: undefined,
                State: quoteInfo?.consignee.state,
                Zipcode: quoteInfo?.consignee.zipCode,
                id: quoteInfo?.consignee.id || '',
                isPort: false,
                isSL: false,
                isZipcode: true,
              })
              setZipcodeDestination((`${quoteInfo.consignee.city}${quoteInfo.consignee.city ? ', ' : ''}${quoteInfo.consignee.state ? quoteInfo.consignee.state+' ' : ''}${quoteInfo.consignee.zipCode}`))
            }
          }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Shipping Location')
          })
        }else if(quoteInfo.consignee.name.length){
          axios.get('/api/v1/database/shippinglocation', {
            params: {
                search: quoteInfo.consignee.name,
                active: true
            }
          }).then(results => {
            if(results.data.data.results[0].name === quoteInfo.consignee.name){
              formik.setFieldValue('destination', {
                City: quoteInfo?.consignee.city,
                Country: getCode(quoteInfo?.consignee.country),
                Name: quoteInfo?.consignee.name,
                State: quoteInfo?.consignee.state,
                Zipcode: quoteInfo?.consignee.zipCode,
                id: quoteInfo.consignee.id || '',
                isPort: false,
                isSL: true,
                isZipcode: false,
              })
              setZipcodeDestination(`${quoteInfo?.consignee?.name}. ${quoteInfo?.consignee?.city}, ${quoteInfo?.consignee.state} ${quoteInfo?.consignee.zipCode}`)
    
              formik.setFieldValue('origin', {
                City: quoteInfo?.shipper.city,
                Country: getCode(quoteInfo?.shipper.country),
                Name: undefined,
                State: quoteInfo?.shipper.state,
                Zipcode: quoteInfo?.shipper.zipCode,
                id: quoteInfo.shipper.id || '',
                isPort: false,
                isSL: false,
                isZipcode: true,
              })
              setZipcodeOrigin((`${quoteInfo?.shipper.city}${quoteInfo?.shipper.city ? ', ' : ''}${quoteInfo?.shipper.state ? quoteInfo?.shipper.state+' ' : ''}${quoteInfo?.shipper.zipCode}`))
            }
          }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Shipping Location')
          })
        }else{
          formik.setFieldValue('origin', {
            City: quoteInfo?.shipper.city,
            Country: getCode(quoteInfo?.shipper.country),
            Name: undefined,
            State: quoteInfo?.shipper.state,
            Zipcode: quoteInfo?.shipper.zipCode,
            id: '',
            isPort: false,
            isSL: false,
            isZipcode: true,
          })
          setOrigin({
            City: quoteInfo?.shipper.city,
            Country: quoteInfo?.shipper.country.length === 3 ? CountryOptions.filter(con => con.CodePrimus === quoteInfo?.shipper.country)[0].Code : quoteInfo?.shipper.country,
            Name: undefined,
            State: quoteInfo?.shipper.state,
            Zipcode: quoteInfo?.shipper.zipCode,
            id: null,
            isPort: false,
            isSL: false,
            isZipcode: true,
          })
          setZipcodeOrigin((`${quoteInfo?.shipper.city}${quoteInfo?.shipper.city ? ', ' : ''}${quoteInfo?.shipper.state ? quoteInfo?.shipper.state+' ' : ''}${quoteInfo?.shipper.zipCode}`))

          formik.setFieldValue('destination', {
            City: quoteInfo?.consignee.city,
            Country: getCode(quoteInfo?.consignee.country),
            Name: undefined,
            State: quoteInfo?.consignee.state,
            Zipcode: quoteInfo?.consignee.zipCode,
            id: '',
            isPort: false,
            isSL: false,
            isZipcode: true,
          })
          setDestination({
            City: quoteInfo?.consignee.city,
            Country: quoteInfo?.consignee.country.length === 3 ? CountryOptions.filter(con => con.CodePrimus === quoteInfo?.consignee.country)[0].Code : quoteInfo?.consignee.country,
            Name: undefined,
            State: quoteInfo?.consignee.state,
            Zipcode: quoteInfo?.consignee.zipCode,
            id: null,
            isPort: false,
            isSL: false,
            isZipcode: true,
          })
          setZipcodeDestination((`${quoteInfo.consignee.city}${quoteInfo.consignee.city ? ', ' : ''}${quoteInfo.consignee.state ? quoteInfo.consignee.state+' ' : ''}${quoteInfo.consignee.zipCode}`))
        }

        formik.setFieldValue('freightInfo', quoteInfo?.freightInfo.map(quote => {          
          return ({
            H: quote.height,
            Hus: '',
            L: quote.length,
            Lus: '',
            STC: '',
            UN: quote.UN,
            W: quote.width,
            Wus: '',
            class: quote.class,
            commodity: quote.commodity,
            dimType: TypeOptions.find(type => type.value === quote.dimType),
            each: quote.weightType.charAt(0).toUpperCase() + quote.weightType?.slice(1)?.toLowerCase(),
            hazmat: quote.hazmat,
            id: uuidv4(),
            nmfc: quote.nmfc,
            qty: quote.qty,
            totalCft: parseInt(quote.qty)*(Number(quote.length)*Number(quote.width)*Number(quote.height)/1728),
            weight: quote.weight,
            weightus: ''
          })
        }))

        formik.setFieldValue('freightInfoUOM', {value: quoteInfo.UOM, oldValue: ''})

        let accessorials = []
        quoteInfo.accessorialsList.forEach(accessorial => {
          let acc = user.enabledAccessorials.filter(enabledAcc => enabledAcc.code === accessorial)
          accessorials.push(...acc)
        })

        if(quoteInfo.commodityInsurance){
          let acc = user.enabledAccessorials.filter(enabledAcc => enabledAcc.code === 'INS')
          accessorials.push(...acc)
        }

        formik.setFieldValue(`accessorials.origin`, accessorials.filter(acc => acc.category === 'Origin'))
        formik.setFieldValue(`accessorials.destination`, accessorials.filter(acc => acc.category === 'Destination'))
        formik.setFieldValue(`accessorials.other`, accessorials.filter(acc => acc.category === 'Other'))

        formik.setFieldValue('commodityInsurance', quoteInfo.commodityInsurance || '')
        formik.setFieldValue('insuranceAddOn', quoteInfo.insuranceAddOn || false)
        formik.setFieldValue('insuranceAmount', quoteInfo.insuranceAmount || '')
        formik.setFieldValue('insuranceFreight', quoteInfo.insuranceFreight || false)
        setSelectedAcc(accessorials)
      }).catch(error => {
        checkErrorAPICall(error, dispatch, 'Shipping Location')
      })
    }
    
    setHistoryElement(<HistorySelect
      historyFor={'LTL'}
      setDataFormik={formik.setFieldValue}
      setSelectedAcc={setSelectedAcc}
      setOrigin={setOrigin}
      setDestination={setDestination}
    />)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const setDefaultValues = () => {
    ['shipper', 'consignee'].forEach((location) => {
      formik.setFieldValue(location === 'shipper' ? 'origin' : 'destination', {
        City: newBookingValues[location]?.city || '',
        Country: newBookingValues[location]?.country || '',
        Name: newBookingValues[location]?.name || '',
        State: newBookingValues[location]?.state || '',
        Zipcode: newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "",
        id: newBookingValues[location]?.id || '',
        isPort: false,
        isSL: newBookingValues[location]?.name ? true : false,
        isZipcode: !newBookingValues[location]?.name ? true : false,
      })

      if(newBookingValues[location]?.id){
        if(location === 'shipper'){
          setOrigin({
            City: newBookingValues[location]?.city || '',
            Country: newBookingValues[location]?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === newBookingValues[location]?.country)?.Code || "US" : newBookingValues[location]?.country,
            Name: newBookingValues[location]?.name || '',
            State: newBookingValues[location]?.state || '',
            Zipcode: newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "",
            id: newBookingValues[location]?.id || '',
            isPort: false,
            isSL: newBookingValues[location]?.name ? true : false,
            isZipcode: !newBookingValues[location]?.name ? true : false,
          })
          setZipcodeOrigin(`${newBookingValues[location]?.name}. ${newBookingValues[location]?.city}, ${newBookingValues[location]?.state} ${newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode}`)
        }else{
          setDestination({
            City: newBookingValues[location]?.city || '',
            Country: newBookingValues[location]?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === newBookingValues[location]?.country)?.Code || "US" : newBookingValues[location]?.country,
            Name: newBookingValues[location]?.name || '',
            State: newBookingValues[location]?.state || '',
            Zipcode: newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "",
            id: newBookingValues[location]?.id || '',
            isPort: false,
            isSL: newBookingValues[location]?.name ? true : false,
            isZipcode: !newBookingValues[location]?.name ? true : false,
          })
          setZipcodeDestination(`${newBookingValues[location]?.name}. ${newBookingValues[location]?.city}, ${newBookingValues[location]?.state} ${newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode}`)
        }
      }
      
      formik.setFieldValue(`freightInfo`, newBookingValues?.freightInfo.length
        ? newBookingValues?.freightInfo
        : [{
          id: uuidv4(),
          qty: '',
          dimType: {
          name: "PALLET",
          value: "PLT"
          },
          STC: '',
          weight: '',
          each: 'Each',
          L: '',
          W: '',
          H: '',
          class: '',
          commodity: '',
          nmfc: '',
          weightus: '',
          Lus: '',
          Wus: '',
          Hus: '',
          totalCft: '',
          hazmat: false,
          UN: ''
        }]
      )

      let accessorials = []
      if(newBookingValues?.accessorials?.length){
        newBookingValues?.accessorials?.forEach(accessorial => {
          let acc = user.enabledAccessorials.filter(enabledAcc => enabledAcc.code === accessorial)
          accessorials.push(...acc)
        })
      }
  
      formik.setFieldValue(`accessorials.origin`, accessorials.filter(acc => acc.category === 'Origin'))
      formik.setFieldValue(`accessorials.destination`, accessorials.filter(acc => acc.category === 'Destination'))
      formik.setFieldValue(`accessorials.other`, accessorials.filter(acc => acc.category === 'Other'))
      setSelectedAcc(accessorials)
    })
  }

  useEffect(() => {
    if(resetValues){
      formik.resetForm()
      setOrigin({
        City: "",
        Country: "",
        Name: "",
        State: "",
        Zipcode: "",
        isSL: false,
        isZipcode: false,
        id: null
      })
      setZipcodeOrigin('')
      setDestination({
        City: "",
        Country: "",
        Name: "",
        State: "",
        Zipcode: "",
        isSL: false,
        isZipcode: false,
        id: null
      })
      setZipcodeDestination('')
      setDefaultValues()
      setShowTable(false)
      dispatch({type: actionTypes.SetResetValues, payload: false})
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  useEffect(() => {
    setDefaultValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBookingValues, resetValues])

  useEffect(() => {
    if(formik.errors?.origin?.Zipcode){
      if(formik.values.origin.Zipcode){
        formik.setFieldError(`origin.Zipcode`, '')
      }
    }

    if(formik.errors?.destination?.Zipcode){
      if(formik.values.destination.Zipcode){
        formik.setFieldError(`destination.Zipcode`, '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])

  useEffect(() => {
    if(!formik?.values?.freightInfo[0]?.UN || !formik?.values?.freightInfo[0]?.hazmat || 
      formik?.values?.freightInfo[0]?.UN && formik?.values?.freightInfo[0]?.UN !== selectedUn?.UNNumber) {
      setSelectedUn({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.freightInfo]);
  
  return (
    <div style={{marginRight: window.innerWidth > 991 && (fontSize === 9 ? 300 : fontSize === 11 ? 210 : 0)}}>
      <PageTitle>LTL</PageTitle>
      <form
        onSubmit={formik.handleSubmit}
      >
        <div className="form-group row">
          <label className={`col-xl-1 col-form-label ${marginSize(fontSize, 'mt-2','')}`}>Origin</label>
          <ZipcodesWithCountry
            zipcodeLocal={zipcodeOrigin}
            setZipcodeLocal={setZipcodeOrigin}
            valueName={'origin'}
            setDataFormik={formik.setFieldValue}
            formikValues={formik.values}
            data={origin}
            setData={setOrigin}
            countryOptions={CountryOptions}
            userData={user}
            error={formik?.errors?.origin?.Zipcode}
            touched={formik?.touched?.origin}
            setFieldTouched={formik.setFieldTouched}
            setFieldError={formik.setFieldError}
          />
          <label className={`col-xl-1 col-form-label ${marginSize(fontSize, 'mt-2','')}`}>Destination</label>
          <ZipcodesWithCountry
            zipcodeLocal={zipcodeDestination}
            setZipcodeLocal={setZipcodeDestination}
            valueName={'destination'}
            setDataFormik={formik.setFieldValue}
            data={destination}
            setData={setDestination}
            countryOptions={CountryOptions}
            userData={user}
            error={formik?.errors?.destination?.Zipcode}
            touched={formik?.touched?.destination}
            setFieldTouched={formik.setFieldTouched}
            setFieldError={formik.setFieldError}
          />
          <label className={`col-xl-1 col-form-label ${marginSize(fontSize, 'mt-2','')}`}>Pickup Date</label>
          <div className={`col-sm-8 col-md-7 col-xl-3 mb-2 mb-xl-0`}>
            <DateInput
              setDataFormik={formik.setFieldValue}
              pickupDate={formik.values.pickupDate}
              error={formik.errors.pickupDate}
              touched={touchedPickupDate}
              setTouched={setTouchedPickupDate}
              allowPastDates={false}
            />
            {(formik.errors.pickupDate && touchedPickupDate) && <small className="text-danger ms-1">
              {(formik.errors.pickupDate as string)?.includes('must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).') && 'Invalid date'}
              {formik.errors.pickupDate === 'Pickup date cannot be in the past' && formik.errors.pickupDate}
              {!formik.values.pickupDate && 'Required'}
            </small>}
          </div>
        </div> 
        {fontSize >= 13 && <div className={`form-group row`}>
          <label className="col-sm-12 col-xl-1 col-form-label">Freight Info</label>
        </div>}
        {
          formik.values.freightInfo?.map((freight, index) => {
            return(
              <div className={`form-group row align-items-start ${index === formik.values.freightInfo.length - 1 && 'mb-2'}`} key={freight.id}>
                {fontSize < 13 && <div className="col-12 col-xl-1 mt-5">
                  {index === 0 && <label className="col-form-label">Freight Info</label>}
                </div>}
                <div className={`${fontSize < 13 ? 'col-12 col-xl-11 mt-5' : 'col-12'}`}>
                  <div className="row g-0">
                    <FreightInformation
                      index={index}
                      freight={freight}
                      freightInfo={formik.values.freightInfo}
                      freightInfoUOM={formik.values.freightInfoUOM}
                      handleChange={formik.handleChange}
                      setDataFormik={formik.setFieldValue}
                      formik={formik}
                      errors={formik.errors.freightInfo && (formik.errors.freightInfo[index] as freightErrors)}
                      touched={formik?.touched?.freightInfo && formik?.touched?.freightInfo[index]}
                      setOpenHazContactModal={setOpenHazContactModal}
                      setSelectedUn={setSelectedUn}
                      selectedUn={selectedUn}
                    />
                  </div>
                </div>
              </div>
            )
          })
        }
        <div className={`form-group row g-0 ${marginSize(fontSize, 'mb-2', 'mb-xl-3')}`}>
          <FreightTotals 
            freightInfoTotals={formik.values.freightInfoTotals}
            freightInfo={formik.values.freightInfo}
            freightInfoUOM={formik.values.freightInfoUOM}
            setDataFormik={formik.setFieldValue}
          />
          <SelectUOM
            freightInfoUOM={formik.values.freightInfoUOM}
            setDataFormik={formik.setFieldValue}
            freightInfo={formik.values.freightInfo}
            freightInfoTotals={formik.values.freightInfoTotals}
          />
        </div>
        {/* ${marginSize(fontSize, 'mb-10', 'mb-5')} */}
        <div className="form-group row align-items-center">
          <label className={`col-xl-1 col-sm-12 col-form-label`}>Accessorials</label>
          <div className={`col-xl-6 col-sm-12`} style={{position: 'relative'}}>
            <Accessorials formikValues={formik.values} setFormikValues={formik.setFieldValue} selectedAcc={selectedAcc} setSelectedAcc={setSelectedAcc} accessorialsList={user?.enabledAccessorials?.filter(accessorial => accessorial.rateTypes?.includes('LTL'))?.filter(accessorial => accessorial.code !== 'HAZ')} actionClicked={actionClicked}/>
          </div>

          <div className="col-xl-5 col-sm-12 mt-4 mt-xl-0 d-flex justify-content-end align-items-center">
            {
              needRateType
              ? <span className="text-danger me-5">Please select at least 1 rate type and try again</span>
              : null
            }
            <div className='w-25 d-flex justify-content-end'>
              <SwitchLTL {...formik.getFieldProps('courier')} title="Courier"/>
            </div>
            <div className='w-25 d-flex justify-content-end'>
              <SwitchLTL {...formik.getFieldProps('LTL')} title="LTL"/>
            </div>

            <div className='w-25 d-flex justify-content-end'>
              <SwitchLTL {...formik.getFieldProps('AIR')} title="AIR"/>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-end mt-3">
            {
              LNFT && laneDistance && !needRateType
              ? <div className='col-12 col-md-8 col-sm-6 d-flex justify-content-end'>
                  <div style={{color: '#3F4254'}}>
                    <span style={{fontWeight: 500}}>Aprox. LNFT:</span> {LNFT} - <span style={{fontWeight: 500}}>Distance:</span> {laneDistance}
                  </div>
              </div>
              : null
            }

            <div className="col-12 col-md-4 col-sm-6 d-flex justify-content-end mt-7 mt-sm-0">
              <button
                type="button"
                className='btn btn-danger me-4'
                onClick={() => {
                  formik.resetForm()
                  setShowTable(false)
                  setZipcodeOrigin('')
                  setZipcodeDestination('')
                  setSelectedAcc([])
                  formik.setErrors({})
                  formik.setTouched({})
                }}
              >
                Clear
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={() => {
                  setActionClicked(!actionClicked)
                  setVendorsProcessed([])
                  setTouchedPickupDate(true)
                }}
              >
                Get Rates
              </button>
            </div>
        </div>
      </form>
      {
        showTable ? 
        <RatesTable
          vendorsFiltered={enabledVendors}
          vendorsProcessed={vendorsProcessed}
          tableFor={'LTL'}
          ratesStopped={ratesStopped}
          setRatesStopped={setRatesStopped}
          source={source}
          linearProgress={linearProgress}
          rates={rates}
          origin={formik?.values?.origin}
          destination={formik?.values?.destination}
          ratesCheaper={ratesVOLCheaper}
          quoteIsCheaper={VOLQuoteIsCheaper}
          setLoadQuotes={setLoadVOLQuotes}
          values={formik.values}
        />
        : null
      }
      <InsuranceRequiredModal
        open={openInsuranceRequiredModal}
        setOpen={setOpenInsuranceRequiredModal}
      />
      <ConfirmTimedOutVendorsModal
        open={openConfirmTimedOutModal}
        setOpen={setOpenConfirmTimedOutModal}
        vendorsTimedOut={vendorsTimedOut}
        keepWaitingVendors={() => {
          source = cancelToken.source()
          keepWaitingVendors(user, formik.values, rates, ratesVOL, linearProgress, setLinearProgress, vendorsTimedOut, setVendorsTimedOut, vendorsProcessed, setVendorsProcessed, setRatesStopped, enabledVendors, setRates, setRatesVOL, setOpenConfirmTimedOutModal, source, normalizeParams)
        }}
        formikValues={formik.values}
      />
      <NoVendorsSetUpModal
        open={openNoVendorsSetUpModal}
        setOpen={setOpenNoVendorsSetUpModal}
      />
  </div>
  )
}