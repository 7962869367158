import { Fade, Backdrop } from '@mui/material';
import UNTable from '../UNTable/UNTable';
import { UNLookupModalProps } from '../../../pages/ltl/models';
import { modalStyles } from '../../ModalStyles';
import { ModalStyled } from '../../Modals.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const UNLookupModal = ({open, setOpen, setUNNumber, setSelectedUn, setOpenHazContactModal}: UNLookupModalProps) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paperUNLookup} style={{visibility: 'visible', opacity: '100%'}}>
                    <UNTable
                        setUNNumber={setUNNumber}
                        setSelectedUn={setSelectedUn}
                        open={open}
                        setOpen={setOpen}
                        setOpenHazContactModal={setOpenHazContactModal}
                        handleClose={handleClose}
                    />                      
                    <div className="row mb-3 mt-5">                        
                        <div className="col-12 d-flex justify-content-end">
                            <button
                                className="btn btn-secondary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default UNLookupModal