import { ModalStyled } from '../../Modals.styled';
import { modalStyles } from '../../ModalStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { KTSVG } from '../../../../_metronic/helpers';
import { Backdrop, Fade, MenuItem, TextField, CircularProgress } from '@mui/material';
import { InputStyled } from '../LTLAndVolumeTLPages.styled';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { stylesMUIInput } from '../../stylesMUIInput';
import { useStyles } from '../FreightInfo/FreightInformation/FreightInformationStyles';
import axios from 'axios';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';

const GET_COMMODITIES = '/api/v1/tools/redkik/commodities'

const InsuranceModal = ({open, setOpen, formikValues, setFormikValues}) => {
    const dispatch = useDispatch()
    const classes = modalStyles({})
    const classesInput = useStyles({props: {}})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [commodities, setCommodities] = useState([])
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        commodity: '',
        insuranceAmount: null,
        insuranceFreight: false,
        insuranceAddOn: false
    })
    const [errorCargoValue, setErrorCargoValue] = useState(false)
    const [errorCommodity, setErrorCommodity] = useState(false)

    const handleCloseModal = () => {
        setOpen(false)
        setTimeout(() => {
            setValues({
                commodity: '',
                insuranceAmount: null,
                insuranceFreight: false,
                insuranceAddOn: false
            })
            setErrorCargoValue(false)
            setErrorCommodity(false)
        }, 300);
    }

    const saveInsurance = () => {
        if(!user?.redkikEnabled){
            if(!values?.insuranceAmount){
                setErrorCargoValue(true)
            }else{
                setFormikValues('insuranceAmount', values?.insuranceAmount)
                setFormikValues('insuranceFreight', values?.insuranceFreight)
                setFormikValues('insuranceAddOn', values?.insuranceAddOn)
                setFormikValues('commodityInsurance', values?.commodity)
                setErrorCargoValue(false)
                setErrorCommodity(false)
                setOpen(false)
            }
        }else{
            if(!values?.insuranceAmount && values.commodity){
                setErrorCargoValue(true)
            }else if(values?.insuranceAmount && !values.commodity){
                setErrorCommodity(true)
            }else if(!values?.insuranceAmount && !values.commodity){
                setErrorCommodity(true)
                setErrorCargoValue(true)
            }else{
                setFormikValues('insuranceAmount', values?.insuranceAmount)
                setFormikValues('insuranceFreight', values?.insuranceFreight)
                setFormikValues('insuranceAddOn', values?.insuranceAddOn)
                setFormikValues('commodityInsurance', values?.commodity)
                setErrorCargoValue(false)
                setErrorCommodity(false)
                setOpen(false)
            }
        }
    }

    useEffect(() => {
      if(open){
        if(!commodities.length){
            setLoading(true)
            axios.get(GET_COMMODITIES).then((results) => {
                setLoading(false)
                setCommodities(results.data.data.results)
            }).catch(error => {
                setLoading(false)
                checkErrorAPICall(error, dispatch, 'Commodities')
            })
        }
        
        setValues({
            commodity: formikValues?.commodityInsurance,
            insuranceAmount: formikValues?.insuranceAmount,
            insuranceFreight: formikValues?.insuranceFreight,
            insuranceAddOn: formikValues?.insuranceAddOn
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const menuProps = {
        classes: {
            list: classesInput.selectPaperList
        }
    }

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paperSaveQuote} style={{visibility: 'visible', opacity: '100%'}}>
                        <div className="row modal-header p-0 pb-2 row align-items-center">
                            <div className="col-6 d-flex align-items-center">
                                <h5>Insurance</h5>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <div
                                    className="btn btn-icon btn-sm btn-active-light-primary"
                                    onClick={handleCloseModal}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                        </div>
                        <>
                            {
                                loading
                                ? <div className="row mt-5 mb-5">
                                    <div className="col-12 d-flex justify-content-center">
                                        <CircularProgress style={{height: 40, width: 40, color: '#019ef7'}}/>
                                    </div>
                                </div>
                                : <div className="row mt-4">
                                    {
                                        user?.redkikEnabled ?
                                            <div className="col-12 d-flex align-items-center mb-4">
                                                <div className="me-5">Commodity</div>
                                                <TextField
                                                    select
                                                    label=" "
                                                    value={values?.commodity}
                                                    onChange={(e) => {
                                                        setValues({...values, [e.target.name]: e.target.value})
                                                        if(e.target.value){
                                                            setErrorCommodity(false)
                                                        }
                                                    }}
                                                    name='commodity'
                                                    className={`${errorCommodity ? classesInput.selectMUIError : classesInput.selectMUI}`}
                                                    InputProps={{
                                                        style: stylesMUIInput(fontSize),
                                                    }}
                                                    SelectProps={{
                                                        IconComponent: KeyboardArrowDownIcon,
                                                        MenuProps: menuProps
                                                    }}
                                                    InputLabelProps={{shrink: false}}
                                                >
                                                    {commodities.map((commodity, index) => {
                                                        return <MenuItem key={index} value={commodity.id}>{commodity.name}</MenuItem>
                                                    })}
                                                </TextField>
                                            </div>
                                        : null
                                    }
                                    <div className="col-12 col-sm-6 d-flex align-items-center">
                                        <div className="w-50 me-4">Cargo value</div>
                                        <InputStyled
                                            type="number"
                                            className={`form-control ${errorCargoValue ? 'form-control-error' : false} me-3`}
                                            autoComplete='off'
                                            fontSize={fontSize}
                                            value={values.insuranceAmount}
                                            name='insuranceAmount'
                                            onChange={(e) => {
                                                setValues({...values, [e.target.name]: e.target.value})
                                                if(e.target.value){
                                                    setErrorCargoValue(false)
                                                }
                                            }}
                                            noPadding={true}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6 mt-4 mt-sm-0 d-flex align-items-center">
                                        <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='addFreightCost'
                                                name='insuranceFreight'
                                                checked={values.insuranceFreight}
                                                onChange={(e) => setValues({...values, [e.target.name]: e.target.checked})}
                                            />
                                            <label className='form-check-label me-4' htmlFor='addFreightCost'>Add Freight Cost</label>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='10AddOn'
                                                name='insuranceAddOn'
                                                checked={values.insuranceAddOn}
                                                onChange={(e) => setValues({...values, [e.target.name]: e.target.checked})}
                                            />
                                            <label className='form-check-label' htmlFor='10AddOn'>10% Add On</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row mt-4 mb-3">
                                <div className="col-12 d-flex align-items-center mb-4">
                                    <div style={{overflowWrap: 'anywhere', fontStyle: 'italic', fontWeight: 500}}>{user?.insuranceDisclaimer}</div>
                                </div>
                                <div className="col-12 mt-4 mt-sm-0 d-flex justify-content-end align-items-center">
                                    {(errorCargoValue || errorCommodity) ? <div style={{fontStyle: 'italic'}} className='text-danger me-5'>Highlighted fields are required</div> : null}
                                    <button className="btn btn-secondary me-4 " onClick={handleCloseModal}>
                                        Close
                                    </button>
                                    <button className="btn btn-primary" onClick={saveInsurance}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </Fade>
            </ModalStyled>
        </>
    )
}

export default InsuranceModal