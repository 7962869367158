/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import { Link } from 'react-router-dom';
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../helpers'
import ChangePasswordModal from './ChangePasswordModal';

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className={'svg-icon-3x'}
                style={{filter: 'invert(60%) sepia(88%) saturate(5278%) hue-rotate(178deg) brightness(105%) contrast(100%)'}}
              />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {user?.firstName} {user?.lastName}
              </div>
              <div className='fw-bold text-muted email fs-9'>
                {user?.username.toLowerCase()}
              </div>
              <div className='fw-bold text-muted email fs-9'>
                {`${user?.billToInformation?.name} (${user?.billToInformation?.code})`}
              </div>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        {/* <div className='menu-item px-5'>
          <Link to={'/settings/defaults'} className='menu-link px-5'>
            Defaults
          </Link>
        </div> */}

        <div className='menu-item px-5'>
          <a onClick={() => setOpenChangePasswordModal(true)} className='menu-link px-5'>
            <span className='menu-text'>Change Password</span>
          </a>
        </div>

        <div className='menu-item px-5 my-1'>
          <Link to='/account/settings' className='menu-link px-5'>
            Portal Settings
          </Link>
        </div>

        {/* <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Log Out
          </a>
        </div> */}
      </div>
      <ChangePasswordModal
        open={openChangePasswordModal}
        setOpen={setOpenChangePasswordModal}
      />
    </>
  )
}

export {HeaderUserMenu}
