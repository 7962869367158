import React, { useEffect, useState } from 'react'
import { DivPaperStyled, ModalStyled } from '../Modals.styled'
import { useSelector } from 'react-redux';
import { modalStyles } from '../ModalStyles';
import { RootState } from '../../../setup';
import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../_metronic/helpers';
import { InputStyled, PhoneInputReactStyled } from '../Inputs.styled';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { formatPhoneUSA } from '../../helpers/formatPhoneUSA';
import { formatPhone } from '../../helpers/formatPhone';

const HazContactModal = ({open, setOpen, setFormikValues, formikValues}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [values, setValues] = useState({
        contact: '',
        phone: ''
    })
    const [focusedPhone, setFocusedPhone] = useState(false)

    useEffect(() => {
      if(!open){
        setTimeout(() => {
            setValues({
                contact: '',
                phone: ''
            })
            setFocusedPhone(false)
        }, 100);
      }else{
        setValues({
            contact: formikValues.emergencyContact,
            phone: formatPhone(formikValues.emergencyPhone, 'USA')
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Haz Contact</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={() => setOpen(false)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x" 
                            />
                        </div>
                    </div>
                        <>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className='me-3'>Contact</div>
                                <InputStyled
                                    type="text"
                                    autoComplete='off'
                                    name='contact'
                                    value={values.contact}
                                    onChange={(e) => setValues({...values, [e.target.name]: e.target.value})}
                                    className='form-control me-3'
                                    fontSize={fontSize}
                                />
                                <div className='me-3'>Phone</div>
                                <PhoneInputReactStyled
                                    international={false}
                                    country={'US'}
                                    value={values.phone || null}
                                    onChange={(value,) => setValues({...values, phone: value})}
                                    autoComplete='new-password'
                                    className='form-control'
                                    style={{
                                        borderTopLeftRadius: '0.475rem',
                                        borderBottomLeftRadius: '0.475rem',
                                        borderColor: (values.phone && !isPossiblePhoneNumber(values.phone) && focusedPhone) ? '#F1416C' : '#E4E6EF',
                                    }}
                                    onBlur={() => setFocusedPhone(true)}
                                    fontSize={fontSize}
                                />
                            </div>
                            <div className="row mt-6 mb-3">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            if(isPossiblePhoneNumber(values.phone)){
                                                setFormikValues('emergencyContact', values.contact)
                                                setFormikValues('emergencyPhone', formatPhoneUSA(values.phone, 'USA'))
                                                setOpen(false)
                                            }
                                        }}
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default HazContactModal