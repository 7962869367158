import clsx from 'clsx'
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/helpers/AssetHelpers';
import { SearchInputToolbarProps } from '../../pages/ltl/models';
import { v4 as uuidv4 } from 'uuid';
import { InputStyled } from './LTLAndVolumeTLPages.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const SearchInputToolbar = ({searchTable, handleSearch, className, results, tableFor, filter}: SearchInputToolbarProps) => {
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

  return (
    <div className="d-flex align-items-center">
        <SVG src={toAbsoluteUrl('/media/icons/bootstrap/search.svg')} className='svg-icon' style={{position: 'absolute', marginLeft: 15, color: '#5E6278', height: 13, width: 13}}/>
        <InputStyled
          name="search"
          value={searchTable}
          onChange={handleSearch}
          id="Search"
          type="search"
          className={clsx(className, "form-control")}
          placeholder="Search"
          autoComplete="off"
          onBlur={(e) => {
            let search = e.target.value
            if(results?.length && search){
              let newSearch = {id: uuidv4(), search, hashId: `${JSON.stringify(search.trim().toLowerCase())}`}
              let existingSearches = JSON.parse(localStorage.getItem(`history${tableFor}`)) || []
              let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
          
              if(isDuplicated){
          
              }else{
                  existingSearches.unshift(newSearch);
                  localStorage.setItem(`history${tableFor}`, JSON.stringify(existingSearches));
              }
            }
          }}
          fontSize={fontSize}
          onKeyPress={(e) => {
            if(e.code === "Enter" && filter){
              handleSearch(e)
              filter()
            }
          }}
        />
    </div>
  )
}

export default SearchInputToolbar