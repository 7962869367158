import React from 'react'
import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { Backdrop, Fade } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers'
import { modalStyles } from '../ModalStyles'
import { BigSubtitle } from '../Modals/Modals.styled'

const ClearRateToEditModal = ({open, setOpen, setFormikValues, newValuesWhenRateCleared, setNewValuesWhenRateCleared}) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="row d-flex align-items-center justify-content-center">
                            <Title>Changing this value will clear the carrier rates obtained previously</Title>
                            <BigSubtitle>Do you wish to continue?</BigSubtitle>
                        </div>
                        <div className="row mt-6 mb-3">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                        setFormikValues(`carrierInformation`, {})
                                        setFormikValues(`url`, '')
                                        setFormikValues(`quoteNumber`, '')
                                        setFormikValues(`carrierInformation`, {})
                                        if(newValuesWhenRateCleared.valuesFor.includes('shippingLocation')){
                                            if(newValuesWhenRateCleared.valuesFor.includes('shipper')){
                                                setFormikValues(`shipper`, newValuesWhenRateCleared.values)
                                            }else{
                                                setFormikValues(`consignee`, newValuesWhenRateCleared.values)
                                            }
                                        }
                                        setOpen(false)
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setOpen(false)
                                        setNewValuesWhenRateCleared({valuesFor: '', values: undefined})
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ClearRateToEditModal