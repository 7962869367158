import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';

export const TabStyled = styled(Tab)`
    font-family: Poppins !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    min-height: 0px !important;
    padding: 10.75px 13px !important;
    &.Mui-selected {
        color: #009EF7 !important;
    };
    &.MuiButtonBase-root {
        color: #3F4254;
    };
`

export const TabsStyled = styled(Tabs)`
    min-height: 0px !important;
    &. MuiTabs-scroller {
        overflow: hidden !important;
    };
    & span {
        &.MuiTabs-indicator {
            background-color: #009EF7 !important;
        }
    }
`