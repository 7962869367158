import { Alert, Snackbar } from '@mui/material';
import {Suspense} from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../setup/redux/RootReducer';
import { actionTypes } from './modules/auth/redux/PagesRedux';

const App = () => {
  const axiosError = useSelector<RootState>(({pages}) => pages.axiosError)
  const axiosSuccess = useSelector<RootState>(({pages}) => pages.axiosSuccess)
  const initialNewBookingValues = useSelector<RootState>(({pages}) => pages.initialNewBooking) as any

  const [open, setOpen] = useState(false);
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if(axiosError !== '' || axiosSuccess !== ''){
      setOpen(true)
    }
  }, [axiosError, axiosSuccess])

  useEffect(() => {
    if(location.pathname !== '/book/new-booking'){
      dispatch({type: actionTypes.SetQuoteValues, payload: []})
      dispatch({type: actionTypes.SetTracking, payload: []})
      dispatch({type: actionTypes.SetNewBookingValues, payload: initialNewBookingValues})
    }
    
    if(location.pathname !== '/trackings'){
      dispatch({type: actionTypes.SetTrackingsFilter, payload: []})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleClose = () => {
    setTimeout(() => {
      dispatch({type: actionTypes.SetAxiosErrors, payload: ''})
      dispatch({type: actionTypes.SetAxiosSuccess, payload: ''})
    }, 500);
    setOpen(false);
  };

  useEffect(() => {
    dispatch({type: actionTypes.SetFontSize, payload: parseInt(localStorage.getItem('fontSize'))})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={9000} onClose={handleClose}>
              <Alert onClose={handleClose} variant='filled' severity={axiosError ? "error" : "success"} sx={{ width: '100%', alignItems: 'center', backgroundColor: axiosError ? '#e42e5b' : '#47BE7D'}}>
                {axiosError || axiosSuccess}
              </Alert>
            </Snackbar>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}