/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import { KTSVG } from '../../../../_metronic/helpers'
import { DivRelative, IconButtonStyled } from './Login.styled'
import axios from 'axios'
import { actionTypes } from '../redux/PagesRedux'
import { LandingPage } from '../../../data/LandingPage'
import { useNavigate, useLocation } from 'react-router-dom'
import { disableSplashScreen, enableSplashScreen } from '../../../../_metronic/layout/core'
import { TypeOptions } from '../../../data/TypeOptions'
import { initialValues as initialValuesNewBooking } from '../../../helpers/createNewBookingFormik';
import { getUserByToken } from '../redux/AuthCRUD'
import { jwtDecode } from "jwt-decode";

const FORGOT_PASSWORD = '/applet/v1/password/forgot'
const DEFAULTS = '/applet/v1/defaults'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  rememberMe: false,
  username: '',
  password: '',
}

export function Login() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenByUrl = queryParams.get('token'); 

  const [loading, setLoading] = useState(false)
  const [loadingForgotPassword, setLoadingForgotPassword] = useState(false)
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate();

  const forgotPassword = () => {
    setLoadingForgotPassword(true)
    axios.post(FORGOT_PASSWORD, {
      username: formik.values.username
    })
    .then(({data}) => {
      setLoadingForgotPassword(false)
      formik.setStatus('Successful. An email will be sent to your Office requesting a password reset.')
      setTimeout(function(){
        formik.setStatus('')
      }, 8000);
    })
    .catch((error) => {
      setLoadingForgotPassword(false)
      if(error.message === 'Network Error') {
        dispatch({type: actionTypes.SetAxiosErrors, payload: 'Network Error on Shipping Items, check your internet connection and try again'})
      }else{
        if(error?.response?.data?.error?.message.length){
          formik.setStatus(error?.response?.data?.error?.message)
          setTimeout(function(){
            formik.setStatus('')
          }, 8000);
        }
      }
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.username, values.password)
          .then(({data}) => {
            enableSplashScreen()
            setLoading(false)
            dispatch(auth.actions.login(data.data.accessToken, values.rememberMe, data.data.exp))

            // GET DEFAULTS
            axios.get(DEFAULTS).then((results) => {
              let defaults = results.data.data.results

              let payload = {
                ...initialValuesNewBooking,
                freightInfo: [{
                    ...initialValuesNewBooking.freightInfo[0],
                    dimType: defaults?.packageType ? TypeOptions.find(type => type.value === defaults?.packageType) : {
                      name: "PALLET",
                      value: "PLT"
                    },
                    L: defaults?.length,
                    W: defaults?.width,
                    H: defaults?.height,
                    Lus: defaults?.length || null,
                    Wus: defaults?.width || null,
                    Hus: defaults?.height || null,
                    commodity: defaults?.commodity
                }],
                accessorials: defaults.accessorials,
                shipper: {
                  ...defaults.defaultBookingShipperLocation,
                  country: defaults.defaultBookingShipperLocation?.country || ''
                },
                consignee: {
                  ...defaults.defaultBookingConsigneeLocation,
                  country: defaults.defaultBookingConsigneeLocation?.country || ''
                },
                pickupDate: {
                  type: 'PickupOn',
                  date: defaults?.pickupDateToday ? new Date() : null,
                  from: defaults?.pickupTimeFrom || '',
                  to: defaults?.pickupTimeTo || ''
                },
                deliveryDate: {
                  type: 'DeliveryBy',
                  date: null,
                  from: null,
                  to: null
                },
                pickupDateToday: defaults?.pickupDateToday
              }
      
              dispatch({type: actionTypes.SetNewBookingValues, payload: payload})
              dispatch({type: actionTypes.SetInitialNewBooking, payload: payload})
              dispatch({type: actionTypes.SetDefaults, payload: {...results.data.data.results, landing: LandingPage.find(page => page.value === results.data.data.results.landingPage || 'Dashboard')?.path || '/home'}})
              navigate(LandingPage.find(page => page.value === results.data.data.results.landingPage)?.path || '/home');
              disableSplashScreen()
            })
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const autoLoginJWT = async () => {
    enableSplashScreen()
    const decoded = jwtDecode(tokenByUrl)    
    await getUserByToken(tokenByUrl)
    .then(response => {  
      dispatch({type: auth.actions.fulfillUser, payload: {response}})
      dispatch(auth.actions.login(tokenByUrl, false, decoded?.exp))
      disableSplashScreen()
    })
    .catch(error => {
      if(error?.response?.status === 401){
        dispatch(auth.actions.isTokenExp(true))
      }
    })
    
  }

  useEffect(() => {
    if(tokenByUrl) {
      autoLoginJWT()
    } 
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className={`text-center ${window.innerHeight < 676 ? '' : 'mb-10'}`}>
        <h1 className={`text-dark ${window.innerHeight < 676 ? '' : 'mb-3'}`}>Login</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div
          className={
            `mb-lg-7 alert ${
              formik.status === 'Successful. An email will be sent to your Office requesting a password reset.' || formik.status === 'Your user has multiple Shipping Locations attached. Our support team will determine your Office to reset your password.'
              ? 'alert-success'
              : 'alert-danger'
            }`
          }
        >
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )
      }

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='email'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <DivRelative className={`fv-row ${window.innerHeight < 676 ? 'mb-4' : 'mb-8'}`}>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>

        <input
          placeholder='Password'
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          style={{
            backgroundPosition: 'right 40px center'
          }}
        />

        <IconButtonStyled
          onClick={() => setShowPassword(!showPassword)}
          size='small'
        >
          <KTSVG path={`/media/icons/bootstrap/${showPassword ? 'eye-slash-fill.svg' : 'eye-fill.svg'}`} className='svg-icon svg-icon-2'/>
        </IconButtonStyled>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </DivRelative>
      {/* end::Form group */}

      {/* Remember Me checkbox */}
      <div className={`d-flex justify-content-center ${window.innerHeight < 676 ? 'mb-4' : 'mb-8'}`}>
        <div className="form-check form-check-custom form-check-solid">
          <input className="form-check-input" type="checkbox" {...formik.getFieldProps('rememberMe')} name="rememberMe" id="flexCheckDefault"/>
          <label className="form-check-label" htmlFor="flexCheckDefault">
              Remember me
          </label>
        </div>
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign In</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        
      </div>

      <div className='text-center'>
        <span
          className='link-primary fs-6 fw-bolder'
          style={{
            marginLeft: '5px',
            cursor: formik.values.username ? 'pointer' : 'default',
            color: !formik.values.username && '#83C4FA'
          }}
          onClick={() => {
            if(formik.values.username){
              forgotPassword()
            }
          }}
        >
          Forgot Password?
          {loadingForgotPassword && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </span>
      </div>
      {/* end::Action */}
    </form>
  )
}
