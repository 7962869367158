import { Autocomplete, InputAdornment, MenuItem, TextField, Select, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ZipcodesWithCountryProps } from '../../pages/ltl/models';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { isTokenExp } from '../../helpers/isTokenExp';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { KTSVG } from '../../../_metronic/helpers';
import { NoOptionsText, PaperStyled } from './LTLAndVolumeTLPages.styled';
import { RootState } from '../../../setup/redux/RootReducer';
import { portsModel } from '../PagesModels';
import ShippingLocationsModal from '../ShippingLocationsModal/ShippingLocationsModal';
import { IconButtonStyled } from './ZipcodesWithCountry.styled';

let cancelToken
const GET_ZIPCODES = '/api/v1/tools/zipcodes'
const GET_SL = '/api/v1/database/shippinglocation'

const ZipcodesWithCountry = React.memo(({data, setData, countryOptions, setDataFormik, valueName, userData, error, touched, setFieldTouched, zipcodeLocal, setZipcodeLocal, drayage, editSL, editVendor, forNewBooking, formFor, disabled, setOpenClearRateToEditModal, formikValues, mode, forStops, forDrayage, setFieldError, shippingLocation, setShippingLocation}: ZipcodesWithCountryProps) => {
    const ports = useSelector<any>((store) => store.pages.drayagePorts) as portsModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [openShippingLocationsModal, setOpenShippingLocationsModal] = useState(false)

    const useStyles = makeStyles({
        textField: {
            backgroundColor: 'white',
            border: (error && touched) ? '1px solid #F1416C !important' : '1px solid #E4E6EF !important',
            borderTopRightRadius: '0.475rem',
            borderBottomRightRadius: '0.475rem',
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#5E6278"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
            "& .MuiOutlinedInput-root":{
                color: "#5E6278"
            },
            "& .Mui-disabled": {
                backgroundColor: '#EFF2F5',
            }
        },
        select: !forNewBooking ? {
            backgroundColor: '#EFF2F5',
            border: '1px solid #E4E6EF',
            borderRight: 'none',
            borderRadius: '0.475rem !important',
            borderTopRightRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
            height: fontSize === 9 ? '32px' : fontSize === 11 ? '42px' : 'var(--input-height)',
            minWidth: '74px',
            width: '100%',
            '& fieldset': {
                border: 'none',
            },
        } : {
            backgroundColor: '#EFF2F5',
            border: '1px solid #E4E6EF',
            borderRight: 'none',
            borderRadius: '0.475rem !important',
            borderTopRightRadius: '0 !important',
            borderBottomRightRadius: '0 !important',
            height: fontSize === 9 ? '32px' : fontSize === 11 ? '42px' : 'var(--input-height)',
            minWidth: '',
            maxWidth: '71px',
            width: '100%',
            '& fieldset': {
                border: 'none',
            }
        },
        height: {
            height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
        },
        list: {
            "& li": {
                fontFamily: 'Poppins',
                color: '#63627D',
                fontWeight: 500,
                fontSize: fontSize
            },
        },
    })

    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const classes = useStyles()

    const menuProps = {
        classes: {
            list: classes.list
        }
    }

    const getData = async () => {
        function isCharacterALetter(char){
            return (/[a-zA-Z]/).test(char)
        }        
        // setDataFormik(`${formFor}.city`, data.City || "")

        let firstLetter = zipcodeLocal.charAt(0)

        //CALLS
        setLoading(true)

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
      
        cancelToken = axios.CancelToken.source()

        if(data.Country === 'Port'){
            setOptions(ports.filter(port => port.name?.toLowerCase().includes(zipcodeLocal?.toLowerCase()) || port.postalCode?.toLowerCase().includes(zipcodeLocal?.toLowerCase())))
        }else{
            try {
                const zipcodes = await axios.get(GET_ZIPCODES, {
                    cancelToken: cancelToken.token,
                    params: {
                        search: zipcodeLocal,
                        searchBy: !isCharacterALetter(firstLetter) && (data.Country === 'US' || data.Country === 'MX') ? 'zipcode' : 'all',
                        countryCode: data.Country,
                        limit: 50
                    }
                })
    
                const shippingLocations = !editSL && !forNewBooking ? await axios.get(GET_SL, {
                    cancelToken: cancelToken.token,
                    params: {
                        search: zipcodeLocal,
                        country: data.Country,
                        active: true
                    }
                }) : {} as any
                
                //SAVE RESULTS
                const resultsZipcodes = zipcodes?.data?.data?.results || []
                const resultsSL = shippingLocations?.data?.data?.results || []
                
                //NORMALIZE OBJECTS
                const shippingLocationsNormalized = resultsSL.map(result => {
                    return {
                        ...result,
                        literal: `${result?.name}. ${result?.city}, ${result.state} ${result.zipcode}`,
                        postalCode: result?.zipcode,
                        stateCode: result?.state,
                        cityAlias: result?.city,
                        name: result?.name,
                        isSL: true
                    }
                })
                const zipcodesNormalized = resultsZipcodes.map(result => {
                    return {
                        ...result,
                        isZipcode: true
                    }
                })
                  
                let arrayJoined = (editSL || forNewBooking ? zipcodesNormalized : [...zipcodesNormalized, ...shippingLocationsNormalized])
                
                //SAVE NORMALIZED OBJECTS IN OPTIONS ARRAY
                setOptions(arrayJoined)
            } catch (error) {
                setLoading(false)
                if(error.message === 'Network Error') {
                    dispatch({type: actionTypes.SetAxiosErrors, payload: 'Network Error on Zipcodes, check your internet connection and try again'})
                }else{
                    if(error?.response?.data?.error?.message.length){
                        if(error?.response?.status === 401){
                            isTokenExp()
                        }else{
                            dispatch({type: actionTypes.SetAxiosErrors, payload: error?.response?.data?.error?.message})
                        }
                    }
                }
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        if(editSL){
            setDataFormik('country', data?.Country)
            setDataFormik('city', data?.City)
            setDataFormik('state', data?.State)
            setDataFormik('zipcode', data?.Zipcode)
        }else if(editVendor){
            setDataFormik(`${valueName}.city`, data?.City)
            setDataFormik(`${valueName}.state`, data?.State)
            setDataFormik(`${valueName}.zipcode`, data?.Zipcode)
            setDataFormik(`${valueName}.country`, data?.Country)
        }else if(!forNewBooking){
            setDataFormik(valueName, data?.Zipcode ? data : {})
            if(data.Zipcode){
                setZipcodeLocal(data.isZipcode
                    ? (`${data.City}${data.City ? ', ' : ''}${data.State ? data.State+' ' : ''}${data.Zipcode}`)
                    : data.isSL ? (`${data.Name}. ${data.City}, ${data.State} ${data.Zipcode}`) : (`${data.Name}, ${data.State} ${data.Zipcode}`)
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if(!data?.Country && !data?.Country?.length){
            setData({
                ...data,
                Country: getCountryOptionsAllowed().length > 0
                            ? (getCountryOptionsAllowed().find(country => country?.Code === 'US') ? 'US' : getCountryOptionsAllowed()[0].Code)
                            : 'US'
            })
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.Country])

    useEffect(() => {
        if(zipcodeLocal && zipcodeLocal.length >= 4){
            if(zipcodeLocal !== (data.isZipcode
                ? (`${data.City}${data.City ? ', ' : ''}${data.State ? data.State+' ' : ''}${data.Zipcode}`)
                : data.isSL ? (`${data?.Name}. ${data?.City}, ${data.State} ${data.Zipcode}`) : (`${data?.City}, ${data.State} ${data.Zipcode}`))
                // : data.isSL ? (`${data?.Name}. ${data?.City}, ${data.State} ${data.Zipcode}`) : (`${data?.Name}, ${data.State} ${data.Zipcode}`))
            ){
                getData()
            }
        }else{
            setOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipcodeLocal])

    useEffect(() => {
        if(data.Zipcode && data.Zipcode.length){
            setZipcodeLocal(data.isZipcode
                ? (`${data.City}${data.City ? ', ' : ''}${data.State ? data.State+' ' : ''}${data.Zipcode}`)
                : data.isSL ? (`${data?.Name}. ${data?.City}, ${data.State} ${data.Zipcode}`) : (`${data?.City} , ${data.State} ${data.Zipcode}`) //,
                // : data.isSL ? (`${data?.Name}. ${data?.City}, ${data.State} ${data.Zipcode}`) : (`${data?.Name} , ${data.State} ${data.Zipcode}`) //,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.Zipcode])

    useEffect(() => {
        if(options.length > 0 && open === false){
            setLoading(true)
            setData({
                ...data,
                'Zipcode': options[0]?.postalCode,
                'State': options[0]?.stateCode,
                'City': options[0]?.cityAlias || options[0]?.city,
                'isSL': options[0]?.isSL || false,
                'isPort': options[0]?.isPort || false,
                'isZipcode': options[0]?.isZipcode || false,
                'Name': options[0]?.name || undefined,
                'id': options[0].id
            })

            if(valueName){
                setFieldError(`${valueName}.Zipcode`, '')
            }
            
            if(forNewBooking){
                setDataFormik(`${formFor}.city`, options[0]?.cityAlias || options[0]?.city || "")
                setDataFormik(`${formFor}.state`, options[0]?.stateCode || "")
                setDataFormik(`${formFor}.zipcode`, options[0]?.postalCode || "")
                setDataFormik(`${formFor}.name`, options[0]?.name || "")
            }
            setOptions([])
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    const getCountryOptionsAllowed = () => {
        let defaultCountries = userData.defaultCountries
        let countriesArrayLocal = []
        defaultCountries?.forEach(country => {
            let allowedCountry = countryOptions?.find(c => c.CodePrimus === country)
            countriesArrayLocal?.push(allowedCountry)
        })
        return countriesArrayLocal
    }

    useEffect(() => {
        getCountryOptionsAllowed()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {/* col-xl-5 col-md-7 col-sm-8 mb-2 */}
            <div className={editSL || editVendor || forNewBooking || forStops ? "" : !forDrayage ? 'col-sm-8 col-md-7 col-xl-3' : 'col-xl-5 col-md-7 col-sm-8 mb-5'}>
                <div className='d-flex'>
                    <div style={{width: !forNewBooking ? '100px' : ''}}>
                        <Select
                            readOnly={formikValues?.carrierInformation?.name ? true : false}
                            onClick={() => { 
                                if(formikValues?.carrierInformation?.name && !disabled){
                                    setOpenClearRateToEditModal(true)
                                }
                            }}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    Country: e.target.value,
                                    City: '',
                                    Name: '',
                                    State: '',
                                    Zipcode: '',
                                    isSL: false,
                                    isZipcode: false,
                                    isPort: e.target.value == 'Port' ? true : false,
                                })
                                setZipcodeLocal('')
                                if(forNewBooking){
                                    setDataFormik(`${formFor}.country`, e.target.value !== 'Port' ? e.target.value : 'US'  || "US")
                                }
                                if(shippingLocation?.id){
                                    setShippingLocation({
                                        ...shippingLocation,
                                        id: null
                                    })
                                    setDataFormik(`${formFor}.id`, null)
                                }
                            }}
                            defaultValue={data?.Country || (getCountryOptionsAllowed().length > 0
                                        ? (getCountryOptionsAllowed().find(country => country?.Code === 'US') ? 'US' : getCountryOptionsAllowed()[0]?.Code)
                                        : 'US')}
                            renderValue={() => data?.isPort ? <DirectionsBoatIcon className="text-success" style={{height: '20px !important', width: '20px !important'}}/> : <img className='m-0' alt='flag' src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.Country}.svg`} width="24"/>}
                            className={`${classes.select} selectCountry`}
                            MenuProps={menuProps}
                            inputProps={{ tabIndex: 1 }}
                            value={data?.Country || 'US'}
                            disabled={disabled}
                        >
                            {
                                mode?.shipmentCategory === 'Drayage' || drayage ? 
                                    ['US', 'Port'].map((country, index) => {
                                        return (<MenuItem key={index} value={country}>{country === 'US' ? 'United States' : country}</MenuItem>)
                                    })
                                : getCountryOptionsAllowed().length > 0
                                ? getCountryOptionsAllowed()?.map((country, index) => {
                                    return (<MenuItem key={index} value={country?.Code}>{country?.Name}</MenuItem>)
                                })
                                : countryOptions?.map((country, index) => {
                                    return (<MenuItem key={index} value={country?.Code}>{country?.Name}</MenuItem>)
                                })
                            }
                        </Select>
                    </div>
                    <div className="w-100">
                        <Autocomplete
                            readOnly={formikValues?.carrierInformation?.name ? true : false}
                            disabled={disabled}
                            onBlur={() => setFieldTouched(valueName, true)}
                            autoSelect
                            autoComplete={false}
                            autoHighlight={true}
                            className='dropdownContainer w-100'
                            getOptionLabel={(option) => option.literal || ""}
                            options={options}
                            selectOnFocus
                            handleHomeEndKeys
                            forcePopupIcon={false}
                            inputValue={zipcodeLocal}
                            onOpen={() => {
                                setLoading(true)
                                setOpen(true)
                            }}
                            onClose={() => {
                                setLoading(false)
                                setOpen(false)
                                setOptions([])
                            }}
                            clearOnBlur={false}
                            onInputChange={(e, value) =>  {
                                if(shippingLocation?.id){
                                    setShippingLocation({
                                        ...shippingLocation,
                                        id: null
                                    })
                                    setDataFormik(`${formFor}.id`, null)
                                }

                                if(e?.type === "change"){
                                    setZipcodeLocal(value)
                                }

                                if(value === "" || value.length < 10){
                                    setData({...data,
                                        'Zipcode': undefined,
                                        'State': undefined,
                                        'City': undefined,
                                        'isSL': undefined,
                                        'isZipcode': undefined,
                                        'isPort': data?.Country == 'Port' ? true : undefined,
                                        'Name': undefined,
                                        'id': undefined
                                    })
                                }

                                if(value === ''){
                                    if(forNewBooking){
                                        setDataFormik(`${formFor}.city`, "")
                                        setDataFormik(`${formFor}.state`, "")
                                        setDataFormik(`${formFor}.zipcode`, "")
                                    }
                                }
                            }}
                            onChange={(event, value) => {
                                setData({...data, 
                                    'Zipcode': value?.postalCode,
                                    'State': value?.stateCode,
                                    // 'City': value?.cityAlias || value?.city,
                                    'City': value?.isPort ? value?.name : (value?.cityAlias || value?.city),
                                    'isSL': value?.isSL || false,
                                    'isZipcode': value?.isZipcode || false,
                                    'isPort': value?.isPort || false,
                                    'Name': value?.isPort ? undefined : value?.name ,
                                    'id': value?.id,
                                    'CountryPort': value?.country,
                                })
                                if(valueName){
                                    setFieldError(`${valueName}.Zipcode`, '')
                                }

                                if(forNewBooking){
                                    setDataFormik(`${formFor}.city`, value?.isPort ? value?.name : (value?.cityAlias || value?.city  || ""))
                                    setDataFormik(`${formFor}.state`, value?.stateCode || "")
                                    setDataFormik(`${formFor}.zipcode`, value?.postalCode || "")
                                    if(data.Country === 'Port'){
                                        setDataFormik(`${formFor}.country`, value?.country)
                                    }
                                }
                            }}
                            onKeyPress={(event) => {
                                if(valueName) {
                                    setFieldError(`${valueName}.Zipcode`, '')
                                }
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    return false;
                                }
                            }}
                            renderInput={params => {
                                return (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        autoComplete='new-password'
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButtonStyled tabIndex={-1} disabled={loading || forNewBooking || forStops} alldisabled={disabled ? 'true' : 'false'} onClick={() => setOpenShippingLocationsModal(true)}>
                                                        {loading ? <div className='d-flex align-items-center'><CircularProgress color="inherit" size={15} /></div> : <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon svg-icon-6' style={{color: '#a1a5b7'}}/>}
                                                    </IconButtonStyled>
                                                </InputAdornment>
                                            ),
                                            className: classes.height,
                                            style: {fontSize: 14, fontFamily: 'Poppins', fontWeight: 500, paddingRight: 9, color:"#5E6278 !important", borderTopLeftRadius: 0, borderBottomLeftRadius: 0},
                                        }}
                                        className={classes.textField}
                                        onClick={() => {
                                            if(formikValues?.carrierInformation?.name && !disabled){
                                                setOpenClearRateToEditModal(true)
                                            }
                                        }}
                                    />
                                );
                            }}
                            renderOption={(props, option) => (
                                <li key={option} {...props}>
                                    {option.isZipcode && <KTSVG path='/media/icons/bootstrap/geo-alt.svg' className='svg-icon svg-icon-5 me-2 text-primary'/>}
                                    {option.isSL && <KTSVG path='/media/icons/bootstrap/building.svg' className='svg-icon svg-icon-5 me-2 text-danger'/>}
                                    {option.isPort && <DirectionsBoatIcon className="me-1 text-success" style={{height: '18px', width: '18px'}}/>}
                                    {option.literal}
                                </li>
                            )}
                            PaperComponent={({ children }) => (
                                <PaperStyled fontSize={fontSize}>
                                    {children}
                                </PaperStyled>
                            )}
                            noOptionsText={<NoOptionsText>No options</NoOptionsText>}
                        />
                    </div>
                </div>
                <div className='d-flex'>
                    <div style={{width: '92px'}}>
                        
                    </div>
                    <div>
                        {error && touched
                            ? <small className="text-danger">{error}</small>
                            : null
                        }
                    </div>
                </div>
            </div>
            <ShippingLocationsModal
                open={openShippingLocationsModal}
                setOpen={setOpenShippingLocationsModal}
                data={data}
                setData={setData}
            />
        </>
    );
});

export default ZipcodesWithCountry;