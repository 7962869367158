import {useState} from 'react';
import { MenuItem, Select, Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import { useStyles } from '../SelectIconStyles';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { SelectExportTypeProps } from '../../../pages/ltl/models';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { RootState } from '../../../../setup';

const GET_SHIPPINGLOCATIONS = '/api/v1/database/shippinglocation'
const GET_SHIPPINGITEMS = '/applet/v1/database/shippingitem'
const GET_QUOTES = '/api/v1/database/quote'
const GET_INVOICES = '/applet/v1/invoice'
const GET_TRACKINGS = '/applet/v1/book'
const GET_VENDORS = '/applet/v1/database/vendor'

const SelectExportType = ({columns, dataColumns, fileName, disabled, setLoading}: SelectExportTypeProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({disabled: false, fontSize: fontSize})
    const dispatch = useDispatch()
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const menuProps = {
        classes: {
            list: classes.list
        }
    }

    const downloadFile = (value) => {
        if(value === 'pdf'){
            ExportPdf(columns, dataColumns, fileName)
        }else if(value === 'csv'){
            ExportCsv(columns, dataColumns, fileName)
        }else{
            setLoading(true)
            axios.get(fileName === 'ShippingLocations' ? GET_SHIPPINGLOCATIONS : fileName === 'ShippingItems' ? GET_SHIPPINGITEMS : fileName === 'Invoices' ? GET_INVOICES : fileName === 'Trackings' ? GET_TRACKINGS : fileName === 'Vendors' ? GET_VENDORS : GET_QUOTES)
            .then((results) => {
                if(value === 'allpdf'){
                    ExportPdf(columns, results.data.data.results, fileName)
                }else{
                    ExportCsv(columns, results.data.data.results, fileName)
                }
                setLoading(false)
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Shipping Locations')
            })
        }
    }

    return (
        <Tooltip title={fileName !== 'BatchBooking' && fileName !== 'Rates' ? "Export Data" : "Export All"} open={tooltipOpen}>
            {
                fileName !== 'BatchBooking' && fileName !== 'Rates'
                ? <Select
                    IconComponent={DownloadIcon}
                    className={classes.select}
                    style={{ cursor: 'pointer' }}
                    variant='standard'
                    displayEmpty
                    onChange={(e, value) => downloadFile(e.target.value)}
                    value=""
                    MenuProps={menuProps}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    onOpen={() => setTooltipOpen(false)}
                    disabled={disabled}
                >
                    {fileName === 'ShippingLocations' ? null : <MenuItem value="allpdf">Export All Data to PDF</MenuItem>}
                    <MenuItem value="allcsv">Export All Data to CSV</MenuItem>
                </Select>
                : <Select
                    IconComponent={DownloadIcon}
                    className={classes.select}
                    style={{ cursor: 'pointer' }}
                    variant='standard'
                    displayEmpty
                    onChange={(e, value) => downloadFile(e.target.value)}
                    value=""
                    MenuProps={menuProps}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    onOpen={() => setTooltipOpen(false)}
                    disabled={disabled}
                >
                    <MenuItem value="pdf">Export PDF</MenuItem>
                    <MenuItem value="csv">Export CSV</MenuItem>
                </Select>
            }
        </Tooltip>
    )
}

export default SelectExportType