import { Backdrop, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';
import { ConfirmReRunQuoteProps } from '../../../pages/quotes-grid/models';
import { DivPaperStyled, ModalStyled, Paragraph, Title } from '../../Modals.styled';
import { modalStyles } from '../../ModalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const ConfirmReRunQuote = ({open, setOpen, rowData}: ConfirmReRunQuoteProps) => {
    const classes = modalStyles({});
    let navigate = useNavigate();
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 d-flex justify-content-center mb-1 mt-5">
                                    <Title>Are you sure you want to re-run this quote?</Title>
                                </div>
                                <div className="col-12 d-flex justify-content-center">
                                    <Paragraph style={{fontStyle: 'italic'}}>Please note you will be redirected to another screen</Paragraph>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            if(rowData.rateType === 'DRAYAGE'){
                                                navigate(`../drayage/${rowData.quoteId}`, { replace: true })
                                            }else if(rowData.rateType === 'VOLUME'){
                                                navigate(`../volume-tl/${rowData.quoteId}`, { replace: true })
                                            }else{
                                                navigate(`../ltl/${rowData.quoteId}`, { replace: true })
                                            }
                                            
                                            setOpen(false)
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ConfirmReRunQuote