import styled from 'styled-components';

export const CardStyled = styled.div`
    background-color: ${props => props.color === 'blue' ? '#e2f5ff' : props.color === 'red' ? '#ffe0e7' : '#FFFDF5'};
    border-left: ${props => props.color === 'blue' ? '4px solid #009ef7' : props.color === 'red' ? '4px solid rgb(241, 65, 108)' : '4px solid #ffdd57'} ;
    border-radius: 0.475rem;
    width: 145px;
    padding: 8px;
    h4 {
        color: ${props => props.color === 'blue' ? '#0575b2' : props.color === 'red' ? 'rgb(241, 65, 108)' : '#685d33'}
    };
    h5 { 
        color: ${props => props.color === 'blue' ? '#0575b2' : props.color === 'red' ? 'rgb(241, 65, 108)' : '#685d33'};
        margin-bottom: 0px;
        display: flex;
        align-items: center;
    };
    color: ${props => props.color === 'blue' ? '#0575b2' : props.color === 'red' ? 'rgb(241, 65, 108)' : '#685d33'}
`