import { Backdrop, CircularProgress, Fade } from '@mui/material'
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../setup';
import { KTSVG } from '../../../../_metronic/helpers';
import { CountryOptions } from '../../../data/CountryOptions';
import { UserModel } from '../../../modules/auth/models/UserModel';
import ZipcodesWithCountry from '../../LTLandVolumeTLPages/ZipcodesWithCountry';
import { modalStyles } from '../../ModalStyles';
import PhoneInput from '../PhoneInput';
import axios from 'axios';
import { EditModalProps } from '../../../pages/shipping-locations/models';
import '../PhoneInput.css'
import '../antd.css'
import moment from 'moment'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import * as Yup from 'yup'
import { DivPaperStyled, ModalStyled, Scroll, SmallTextItalic } from '../../Modals.styled';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import TimeInput from '../../TimeInput/TimeInput';
import { formatPhone } from '../../../helpers/formatPhone';
import { formatPhoneUSA } from '../../../helpers/formatPhoneUSA';
import { clearDialCode } from '../../../helpers/clearDialCode';
import { InputStyled } from '../../Inputs.styled';

const SHIPPINGLOCATION = '/api/v1/database/shippinglocation'

const EditOrAddModal = ({open, setOpen, rowSelected, setRowSelected, getData, addOrEdit, results}: EditModalProps) => {
    const dispatch = useDispatch()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [currentIdRowToEdit, setCurrentIdRowToEdit] = useState()
    const [zipcode, setZipcode] = useState('')
    const [addOrEditLocal, setAddOrEditLocal] = useState(addOrEdit)
    const [city, setCity] = useState({
        City: "",
        Country: "",
        Name: "",
        State: "",
        Zipcode: "",
        isSL: false,
        isZipcode: false,
        id: null
    })
    const [openTime, setOpenTime] = useState(null)
    const [closeTime, setCloseTime] = useState(null)
    const [errorLocal, setErrorLocal] = useState({
        from: false,
        to: false
    })
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = modalStyles({fontSize: fontSize})

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            if(addOrEdit === 'edit'){
                setRowSelected({})
            }else{
                formik.handleReset(initialValues)
            }
        }, 500);
    }

    const initialValues = {
        name: '',
        code: '',
        address: '',
        address1: '',
        country: 'US',
        city: '',
        state: '',
        zipcode: '',
        phone: null,
        fax: null,
        contact: '',
        contactPhone: null,
        email: '',
        open: null,
        close: null,
        remarks: '',
        alertUser: false
    }

    Yup.addMethod<Yup.StringSchema>(Yup.string, "notInvalid", function (message) {
        return this.test("test-name", message, function(value: any) {
          const { path, createError } = this
          return value !== 'Invalid date' ? true : createError({message, path})
        })
    })

    const validationSchema = Yup.object().shape({
        phone: (Yup.string() as any).nullable(true).test("validPhoneNumber", "Invalid phone", (value) => {
            if(value === null){
                return true
            }else if(typeof value === 'string'){
                if(isPossiblePhoneNumber(value)){
                    return true
                }else{
                    return false
                }
            }
        }),
        fax: (Yup.string() as any).nullable(true).test("validPhoneNumber", "Invalid phone", (value) => {
            if(value === null){
                return true
            }else if(typeof value === 'string'){
                if(isPossiblePhoneNumber(value)){
                    return true
                }else{
                    return false
                }
            }
        }),
        contactPhone: (Yup.string() as any).nullable(true).test("validPhoneNumber", "Invalid phone", (value) => {
            if(value === null){
                return true
            }else if(typeof value === 'string'){
                if(isPossiblePhoneNumber(value)){
                    return true
                }else{
                    return false
                }
            }
        }),
        name: Yup.string().required('Name is required'),
        open: (Yup.string() as any).notInvalid().nullable(),
        close: (Yup.string() as any).notInvalid().nullable(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            addOrEditSL(values)
        }
    })
    
    useEffect(() => {
        if(addOrEdit === 'edit'){
            formik.setValues({
                name: rowSelected.name,
                code: rowSelected.code,
                address: rowSelected.address,
                address1: rowSelected.address1,
                country: rowSelected?.country,
                city: rowSelected.city,
                state: rowSelected.state,
                zipcode: rowSelected.state,
                phone: formatPhone(rowSelected.phone, rowSelected?.country) || null,
                fax: formatPhone(rowSelected.fax, rowSelected?.country) || null,
                contact: rowSelected.contact,
                contactPhone: formatPhone(rowSelected.contactPhone, rowSelected?.country) || null,
                email: rowSelected.email,
                open: rowSelected.opentime,
                close: rowSelected.closetime,
                remarks: rowSelected.remarks,
                alertUser: rowSelected.alertUser
            })
            setCity({
                ...city,
                Country: CountryOptions?.find(c => c.CodePrimus === rowSelected?.country)?.Code || 'US',
                City: rowSelected.city,
                State: rowSelected.state,
                Zipcode: rowSelected.zipcode,
                isZipcode: true,
                id: null
            })
            setOpenTime(rowSelected.opentime ? moment(rowSelected.opentime, 'HH:mm') : null)
            setCloseTime(rowSelected.closetime ? moment(rowSelected.closetime, 'HH:mm') : null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelected])

    useEffect(() => {
        formik.setFieldValue('country', city?.Country)
        formik.setFieldValue('city', city.City)
        formik.setFieldValue('state', city.State)
        formik.setFieldValue('zipcode', city.Zipcode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city])

    useEffect(() => {
        if(!open) {
            setAddOrEditLocal(addOrEdit)            
        }
    }, [open])

    const addOrEditSL = (values) => {        
        setSaving(true)
        let currentSelected = rowSelected ? rowSelected?.id : currentIdRowToEdit
        axios({
            method: addOrEditLocal === 'add' ? 'post' : 'put',
            url: addOrEditLocal === 'add' ? SHIPPINGLOCATION : `${SHIPPINGLOCATION}/${currentSelected}`,            
            data: {
                code: values.code,
                name: values.name,
                address: values.address,
                address1: values.address1,
                phone: values?.country === 'US' || values?.country === 'CA' ? formatPhoneUSA(values.phone, (rowSelected?.country || formik.values.country)) : clearDialCode(values.phone || null, (rowSelected?.country || formik.values.country)),
                fax: values?.country === 'US' || values?.country === 'CA' ? formatPhoneUSA(values.fax, (rowSelected?.country || formik.values.country)) : clearDialCode(values.fax || null, (rowSelected?.country || formik.values.country)),
                email: values?.email || "",
                city: values.city,
                state: values.state,
                country: values?.country,
                zipcode: values.zipcode,
                active: rowSelected?.active === false || rowSelected?.active === true ? rowSelected?.active : true,
                contact: values.contact,
                contactPhone: values?.country === 'US' || values?.country === 'CA' ? formatPhoneUSA(values.contactPhone, (rowSelected?.country || formik.values.country)) : clearDialCode(values.contactPhone|| null, (rowSelected?.country || formik.values.country)),
                opentime: values.open || "",
                closetime: values.close || "",
                remarks: values.remarks,
                alertUser: values.alertUser
            }
        }).then((response) => {            
            setAddOrEditLocal('edit')
            if(response?.data?.data) {
                setCurrentIdRowToEdit(response?.data?.data?.results?.id)
            }
            setSaving(false)
            setSaved(true)
            setTimeout(function(){
                setSaved(false)
            }, 2000);
            getData({})
            // setOpen(false)
        }).catch(error => {
            setSaving(false)
            checkErrorAPICall(error, dispatch, `${addOrEdit} Shipping Location`)
        })
    }
     
    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paperEditModalSL}>
                    <div className="row modal-header p-0 pb-2">
                        <div className="col-8">
                            <h5 className="modal-title">{addOrEdit === 'add' ? 'Add New Shipping Location' : 'Edit Shipping Location'}</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                        <>
                            <Scroll>
                                <div className="row align-items-center mb-7 mt-5">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Name</label>
                                    </div>
                                    <div className="col-12 col-sm-7 mb-7 mb-sm-0">
                                        <InputStyled
                                            type="text"
                                            className="form-control"
                                            autoComplete='off'
                                            {...formik.getFieldProps('name')}
                                            style={{ 
                                                borderColor: formik?.errors?.name && formik?.touched?.name ? '#F1416C' : '#E4E6EF'
                                            }}
                                            fontSize={fontSize}
                                        />
                                        {
                                            formik?.errors?.name && formik?.touched?.name &&
                                            <small className='text-danger'>{formik?.errors.name}</small>
                                        }
                                    </div>
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Code</label>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <InputStyled
                                            type="text"
                                            className="form-control"
                                            autoComplete='off'
                                            {...formik.getFieldProps('code')}
                                            fontSize={fontSize}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Address</label>
                                    </div>
                                    <div className="col-12 col-sm-7 mb-7 mb-sm-0">
                                        <InputStyled
                                            type="text"
                                            className="form-control"
                                            autoComplete='new-password'
                                            {...formik.getFieldProps('address')}
                                            fontSize={fontSize}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <InputStyled
                                            type="text"
                                            className="form-control"
                                            autoComplete='new-password'
                                            {...formik.getFieldProps('address1')}
                                            fontSize={fontSize}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>C&#8204;ity</label>
                                    </div>
                                    <div className="col-12 col-sm-11">
                                        <ZipcodesWithCountry
                                            zipcodeLocal={zipcode}
                                            setZipcodeLocal={setZipcode}
                                            setDataFormik={formik?.setFieldValue}
                                            data={city}
                                            setData={setCity}
                                            countryOptions={CountryOptions}
                                            userData={user}
                                            error={''}
                                            touched={{}}
                                            setFieldTouched={() => {}}
                                            editSL={true}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Phone</label>
                                    </div>
                                    <div className="col-12 col-sm-5 mb-7 mb-sm-0">
                                        <PhoneInput
                                            value={formik.values.phone}
                                            setDataFormik={formik.setFieldValue}
                                            name='phone'
                                            countryCode={formik.values?.country}
                                            deleteSelectCountry={false}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Fax</label>
                                    </div>
                                    <div className="col-12 col-sm-5">
                                        <PhoneInput
                                            value={formik.values.fax}
                                            setDataFormik={formik.setFieldValue}
                                            name='fax'
                                            countryCode={formik.values?.country}
                                            deleteSelectCountry={false}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Contact</label>
                                    </div>
                                    <div className="col-12 col-sm-5 mb-7 mb-sm-0">
                                        <InputStyled
                                            type="text"
                                            className="form-control"
                                            autoComplete='off'
                                            {...formik.getFieldProps('contact')}
                                            fontSize={fontSize}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Phone</label>
                                    </div>
                                    <div className="col-12 col-sm-5">
                                        <PhoneInput
                                            value={formik.values.contactPhone}
                                            setDataFormik={formik.setFieldValue}
                                            name='contactPhone'
                                            countryCode={formik.values.country}
                                            deleteSelectCountry={false}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Email</label>
                                    </div>
                                    <div className="col-12 col-sm-11 mb-7 mb-sm-0">
                                        <InputStyled
                                            type="text"
                                            className="form-control"
                                            autoComplete='off'
                                            {...formik.getFieldProps('email')}
                                            fontSize={fontSize}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-12 col-sm-1">
                                        <label className='form-label'>Hours</label>
                                    </div>
                                    <div className="col-12 col-sm-11">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-1">
                                                <label className='form-label'>Open</label>
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <TimeInput
                                                    error={formik.errors?.open && formik.touched?.open}
                                                    data={openTime}
                                                    setData={setOpenTime}
                                                    name="open"
                                                    setFormikData={formik.setFieldValue}
                                                    errorLocal={errorLocal}
                                                    setErrorLocal={setErrorLocal}
                                                    time='from'
                                                />
                                            </div>
                                            <div className="col-12 col-sm-1">
                                                <label className='form-label'>Close</label>
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <TimeInput
                                                    error={formik.errors?.close && formik.touched?.close}
                                                    data={closeTime}
                                                    setData={setCloseTime}
                                                    name="close"
                                                    setFormikData={formik.setFieldValue}
                                                    errorLocal={errorLocal}
                                                    setErrorLocal={setErrorLocal}
                                                    time='to'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-sm-1 mb-3 mb-sm-0">
                                    </div>
                                    <div className="col-12 col-sm-11">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-sm-1">
                                            </div>
                                            <div className="col-12 col-sm-5 mb-7 mb-sm-0">
                                                <small className="text-danger">{((formik.errors?.open && formik.touched?.open) || errorLocal.from) && 'Invalid time'}</small>
                                            </div>
                                            <div className="col-12 col-sm-1">
                                            </div>
                                            <div className="col-12 col-sm-5">
                                                <small className="text-danger">{((formik.errors?.close && formik.touched?.close) || errorLocal.to) && 'Invalid time'}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center mb-7">
                                    <div className="col-12 col-md-1">
                                        <label className='form-label'>Remarks</label>
                                    </div>
                                    <div className="col-12 col-md-11">
                                        <textarea
                                            style={{height: 60}}
                                            className="form-control"
                                            rows={2}
                                            autoComplete='off'
                                            {...formik.getFieldProps('remarks')}
                                        />
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-0 col-md-1"></div>
                                    <div className="col-11">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input" type="checkbox" id="alertUser" {...formik.getFieldProps('alertUser')} checked={formik.getFieldProps('alertUser').value}/>
                                            <label className="form-check-label" htmlFor="alertUser">
                                                Show remarks when selecting
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </Scroll>
                            <div className="row mb-3 mt-7">
                                <div className="col-12 col-sm-8 mb-7 mb-sm-0 d-flex align-items-center">
                                    {addOrEdit === 'edit' && <SmallTextItalic>*Created on {format(new Date(rowSelected?.dateSaved || null), 'MM/dd/yyyy')} by {rowSelected?.createdByUser}</SmallTextItalic>}
                                </div>
                                <div className="col-12 col-md-4 d-flex justify-content-end">
                                    <button
                                        className="btn btn-secondary me-4"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className={`btn ${saved ? 'btn-success' : 'btn-primary'} d-flex align-items-center`}
                                        style={{
                                            cursor: saved ? 'auto' : 'pointer',
                                            transition: 'background-color 0.5s ease'
                                        }}
                                        type='submit'
                                        onClick={() => {
                                            formik.handleSubmit()
                                        }}
                                    >
                                        {saving ? 'Saving' : saved ? 'Saved' : 'Save'}{saving && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default EditOrAddModal