import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

export const useStyles = makeStyles({
    select: props => ({
        backgroundColor: props.disabled ? '#EFF2F5' : 'white',
        border: '1px solid #E4E6EF !important',
        borderRadius: '0.475rem',
        width: '100%',
        '& fieldset': {
            border: 'none',
        },
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
    }),
    selectError: props => ({
        backgroundColor: props.disabled ? '#EFF2F5' : 'white',
        border: '1px solid #f1416c !important',
        borderRadius: '0.475rem',
        width: '100%',
        '& fieldset': {
            border: 'none',
        },
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 500,
    }),
    selectMUI: {
        width: '100%',
        "& .MuiSelect-select": {
            paddingRight: '16px !important'
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E4E6EF !important',
            borderRadius: '0.475rem',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E4E6EF !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5E6278 !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#B5B5C3 !important',
            borderWidth: '1px !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root":{
            color: "#5E6278 !important"
        },
        "& .MuiInputLabel-root": {
            color: "#a1a5b6",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#5E6278 !important",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            color: "#cccfdb !important"
        },
        "& .Mui-disabled": {
            background: '#EFF2F5 !important'
        }
    },
    selectMUIError: {
        width: '100%',
        "& .MuiSelect-select": {
            paddingRight: '16px !important'
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#F1416C !important',
            borderRadius: '0.475rem',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#F1416C !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5E6278 !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#F1416C !important',
            borderWidth: '1px !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root":{
            color: "#5E6278 !important"
        },
        "& .MuiInputLabel-root": {
            color: "#a1a5b6",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#5E6278 !important",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            color: "#cccfdb !important"
        },
        "& .Mui-disabled": {
            background: '#EFF2F5 !important'
        }
    },
    textFieldMUI: {
        width: '100%',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E4E6EF !important',
            borderRadius: '0.475rem',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E4E6EF !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5E6278 !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#B5B5C3 !important',
            borderWidth: '1px !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root":{
            fontWeight: 500,
            fontSize: 14,
            fontFamily: 'Poppins',
            color: "#5E6278 !important",
            height: 53.13
        },
        "& .MuiInputLabel-root": {
            color: "#a1a5b6",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#5E6278 !important",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            color: "#cccfdb !important"
        }
    },
    timePickerMUI: {
        width: '100%',
        /* "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E4E6EF',
            borderRadius: '0.475rem',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#E4E6EF',
            borderRadius: '0.475rem',
        }, */
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5E6278 !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: '#B5B5C3',
            borderWidth: '1px !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root":{
            fontWeight: 500,
            fontSize: 14,
            fontFamily: 'Poppins',
            color: "#5E6278 !important",
            height: 53.13
        },
        "& .MuiInputLabel-root": {
            color: "#a1a5b6",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#5E6278 !important",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            color: "#cccfdb !important"
        },
        "& .MuiButtonBase-root": {
            display: 'none'
        },
        "& .MuiInputBase-root": {
            backgroundColor: 'white',
            borderRadius: '0.475rem',
        },
        "& .Mui-disabled": {
            backgroundColor: '#EFF2F5 !important'
        },
    },
    textFieldMUIWithoutBorder: {
        width: '100%',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 'none',
            borderRadius: '0.475rem',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: 'none',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5E6278 !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 'none',
            borderWidth: '1px !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root":{
            fontWeight: 500,
            fontSize: 14,
            fontFamily: 'Poppins',
            color: "#5E6278 !important",
            height: 53.13,
            border: '1px solid #E4E6EF',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root.Mui-focused":{
            border: '1px solid #B5B5C3',
            borderRadius: '0.475rem',
        },
        "& .MuiInputLabel-root": {
            color: "#a1a5b6",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#5E6278 !important",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            color: "#cccfdb !important",
        },
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: '#EFF2F5 !important'
        },
    },
    textFieldError: {
        width: '100%',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#F1416C !important',
            borderRadius: '0.475rem',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#F1416C !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#5E6278 !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: '1px !important',
            borderRadius: '0.475rem',
        },
        "& .MuiOutlinedInput-root":{
            fontWeight: 500,
            fontSize: 14,
            fontFamily: 'Poppins',
            color: "#5E6278 !important"
        },
        "& .MuiInputLabel-root": {
            color: "#F1416C",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#F1416C !important",
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 500,
        },
        "& .MuiInputLabel-root.Mui-disabled": {
            color: "#cccfdb !important"
        }
    },
    selectPaperList: {
        "& li": {
            fontFamily: 'Poppins',
            color: '#63627D',
            fontWeight: 500,
            fontSize: (props: any) => props.fontSize === 13 ? 14 : props.fontSize
        },
    },
})

export const DivContainerStyled = styled.div`
    background-color: rgb(228, 230, 239);
    border-radius: 0.475rem;
    border: 1px solid #E4E6EF;
    margin-left: 0px;
    padding: 10px 20px;
    margin-right: 0px;
    margin-bottom: 10px;
`