import { CardStyled } from '../../pages/home/Home.styled'

const CardRanking = ({color, children}: any) => {
  return (
    <CardStyled color={color}>
        {children}    
    </CardStyled>
  )
}

export default CardRanking