import { useEffect, useState } from 'react'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import Table from '../../components/QuotesGridPage/Table/Table';
import Toolbar from '../../components/TrackingGridPage/Toolbar';
import HistorySelect from '../../components/QuotesGridPage/History/HistorySelect';
import { columnsTrackingGrid } from '../../components/TrackingGridPage/columnsTrackingGrid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { defaultsModel } from '../../components/PagesModels';
import ShipmentDispatchedModal from '../../components/NewBookingPage/ShipmentDispatchedModal';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { UserModel } from '../../modules/auth/models/UserModel';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { format } from 'date-fns';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

let cancelToken
const GET_TRACKINGS = '/applet/v1/book'

export const TrackingGrid = () => {
  const dispatch = useDispatch()
  let {setHistoryElement} = usePageData()
  const [loading, setLoading] = useState(false)
  const [totalResults, setTotalResults] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [trackings, setTrackings] = useState([])
  const [filters, setFilters] = useState({
    search: '',
    from: null,
    to: null,
    type: ['ALL'],
    bol: ''
  })
  const [columns, setColumns] = useState([])
  const [clicked, setClicked] = useState(false)
  const [filtering, setFiltering] = useState(false)
  const defaults = useSelector<RootState>(({pages}) => pages.defaults) as defaultsModel
  const trackingsFilter = useSelector<RootState>(({pages}) => pages.trackingsFilter) as any
  const openDispatchedModal = useSelector<RootState>(({pages}) => pages.openDispatchedModal) as any
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const defaultCall = (object?: any) => {
    setLoading(true)

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = axios.CancelToken.source()

    axios.get(GET_TRACKINGS, {
        cancelToken: cancelToken.token,
        params: (trackingsFilter?.pickupDate || trackingsFilter?.deliveryDate || trackingsFilter?.type || trackingsFilter?.dateFrom) && !object?.emptyValues ? 
        {
            page: object?.pageNumber + 1 || pageNumber + 1,
            limit: rowsPerPage,
            pickupDate: trackingsFilter?.pickupDate,
            deliveryDate: trackingsFilter?.deliveryDate,
            dateFrom: trackingsFilter?.dateFrom ? format(trackingsFilter?.dateFrom, 'yyyy-MM-dd') : object?.to && format(object?.to, 'yyyy-MM-dd'),
            'columns[]': [...columnsTrackingGrid.map(col => col.field === 'shipmentMode' ? 'shipmentModeName' : col.field), 'customerQuoteAmount', 'BOLId'].join('|'),
            dateTo: object?.to && format(object?.to, 'yyyy-MM-dd'),
            'lastStatus[]': trackingsFilter?.type?.length && trackingsFilter?.type[0] === 'DELAYED/EXCEPTION' ? 'DLY|EXPT' : ''
        } :
        {
            dateFrom: object?.from && format(object?.from, 'yyyy-MM-dd'),
            dateTo: object?.to && format(object?.to, 'yyyy-MM-dd'),
            search: trackingsFilter?.search ? trackingsFilter?.search : '',
            page: object?.pageNumber + 1 || pageNumber + 1,
            limit: rowsPerPage,
            'columns[]': [...columnsTrackingGrid.map(col => col.field === 'shipmentMode' ? 'shipmentModeName' : col.field), 'customerQuoteAmount', 'BOLId'].join('|'),
            'lastStatus[]': trackingsFilter?.type?.length && trackingsFilter?.type[0] === 'DELAYED/EXCEPTION' ? 'DLY|EXPT' : ''
        }
    }).then((results) => {
        setTotalResults(results.data.data.pagingDetails.totalResults)
        setTrackings(results.data.data.results)
        setLoading(false)
    }).catch(error => {
      setLoading(false)
      checkErrorAPICall(error, dispatch, 'Trackings')
    })
  }

  const updateFilters = (filtersProps: {pageNumber?: number}) => {
    if(isNaN(filters.from) || isNaN(filters.to) || clicked){

    }else {
      setLoading(true)

      if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.')
      }
  
      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source()
  
      axios.get(GET_TRACKINGS, {
          cancelToken: cancelToken.token,
          params: {
            page: filtersProps.pageNumber + 1 || pageNumber + 1,
            limit: rowsPerPage,
            search: filters.search.trim(),
            dateFrom: filters.from && format(filters.from, 'yyyy-MM-dd'),
            dateTo: filters.to && format(filters.to, 'yyyy-MM-dd'),
            pickedUp: filters.type.includes('PICKED UP') ? true : null,
            inTransit: filters.type.includes('IN TRANSIT') ? true : null,
            dispatched: filters.type.includes('DISPATCHED') ? true : filters.type.includes('NOT DISPATCHED') ? false : null,
            delivered: filters.type.includes('DELIVERED') ? true : null,
            BOLNumber: [filters.bol],
            'columns[]': [...columnsTrackingGrid.map(col => col.field === 'shipmentMode' ? 'shipmentModeName' : col.field), 'customerQuoteAmount', 'BOLId'].join('|'),
            'lastStatus[]': filters?.type?.length && trackingsFilter?.type?.length && trackingsFilter?.type[0] === 'DELAYED/EXCEPTION' ? 'DLY|EXPT' : ''
          }
      }).then((results) => {
        setTotalResults(results.data.data.pagingDetails.totalResults)
        setTrackings(results.data.data.results)
        setLoading(false)

        if(results.data.data.results.length){
          let newSearch = {id: uuidv4(), ...filters, hashId: `${JSON.stringify(filters.search)}${JSON.stringify(filters.from ? filters.from.toDateString() : '')}${JSON.stringify(filters.to ? filters.to.toDateString() : '')}${JSON.stringify(filters.type)}${JSON.stringify(filters.bol)}`}
          let existingSearches = JSON.parse(localStorage.getItem("historyTrackingGrid")) || []
          let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
      
          if(isDuplicated){
      
          }else{
            existingSearches.unshift(newSearch);
            localStorage.setItem("historyTrackingGrid", JSON.stringify(existingSearches));
          }
        }
      }).catch(error => {
          setLoading(false)
          checkErrorAPICall(error, dispatch, 'Trackings')
      })
    }
  }

  useEffect(() => {
    if(user) {
      setHistoryElement(
        <HistorySelect
          setFilters={setFilters}
          historyFor='TrackingGrid'
        />
      )

      let bookings = defaults?.bookings
      let newArray = []
    
      if(trackingsFilter?.search){
        setFilters({
          ...filters,
          search: trackingsFilter?.search
        })
      }else if(trackingsFilter?.type?.length === 1){
        setFilters({
          ...filters,
          type: trackingsFilter.type || ['ALL'],
          from: trackingsFilter.dateFrom || null,
        })
      }

      if(bookings?.length){
        bookings?.forEach(element => {
          newArray.push({...columnsTrackingGrid.filter(a => a.field === element.name)[0], hidden: typeof element.hidden !== 'boolean' ? columnsTrackingGrid.filter(a => a.field === element.name)[0].hidden : element.hidden})
        })
        
        if(!user?.displayInvoiceInformation){
          setColumns(newArray.filter(column => column.title !== 'Total'))
        }else{
          setColumns(newArray)
        }
      }else{
        if(!user?.displayInvoiceInformation){
          setColumns(columnsTrackingGrid.filter(column => column.title !== 'Total'))
        }else{
          setColumns(columnsTrackingGrid)
        }
      }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if(trackingsFilter?.search || trackingsFilter?.pickupDate || trackingsFilter?.deliveryDate || trackingsFilter?.type || trackingsFilter?.dateFrom){
      setPageNumber(0)
      setFilters({
        ...filters,
        search: trackingsFilter?.search || '',
        type: trackingsFilter.type || ['ALL'],
        from: trackingsFilter.dateFrom || null,
        to: null
      })
      defaultCall({pageNumber: 0})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingsFilter])

  return (
    <>
      <PageTitle>Tracking Grid</PageTitle>      
      <Toolbar
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        setColumns={setColumns}
        trackings={trackings}
        setTrackings={setTrackings}
        setLoading={setLoading}
        rowsPerPage={rowsPerPage}
        setPageNumber={setPageNumber}
        setTotalResults={setTotalResults}
        defaultCall={defaultCall}
        setClicked={setClicked}
        setFiltering={setFiltering}
        defaults={defaults}
      />
      <Table
        columns={columns}
        setColumns={setColumns}
        data={trackings}
        loading={loading}
        setLoading={setLoading}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNumber={pageNumber}
        totalResults={totalResults}
        tableFor='Trackings'
        defaults={defaults}
        updateFilters={updateFilters}
        defaultCall={defaultCall}
        filtering={filtering}
        filters={filters}
      />
      <ShipmentDispatchedModal
        open={openDispatchedModal}
        setOpen={(boolean) => dispatch({type: actionTypes.SetOpenDispatchedModal, payload: boolean})}
      />
    </>
  )
}