import {useState} from 'react'
import { Select, MenuItem, Tooltip } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useStyles } from '../SelectIconStyles';
import _ from 'lodash';
import { HistorySelectProps } from '../../../pages/ltl/models';
import { CountryOptions } from '../../../data/CountryOptions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { actionTypes } from '../../../modules/auth/redux/PagesRedux';

const HistorySelect = ({setDataFormik, setSelectedAcc, historyFor, setOrigin, setDestination}: HistorySelectProps) => {
    const recentSearches = JSON.parse(localStorage.getItem(`history${historyFor}`)) || []
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({disabled: false, fontSize: fontSize})
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const dispatch = useDispatch()

    const menuProps = {
        classes: {
            list: historyFor === 'LTL' ? classes.listHistoryLTL : classes.listHistory
        }
    }

    const setAllDataInFormik = (e) => {
        let search = recentSearches.filter(search => search.id === e.target.value)[0]

        if(historyFor === 'Drayage'){
            setDataFormik('insuranceAmount', search.insuranceAmount)
            setDataFormik('insuranceAddOn', search.insuranceAddOn)
            setDataFormik('insuranceFreight', search.insuranceFreight)
            setDataFormik('commodityInsurance', search.commodityInsurance)
            setDataFormik('freightInfo', search.freightInfo)
            setDataFormik('origin', {...search.origin, Country: search.origin.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.origin.Country)[0].Code : search.origin.Country})
            setDataFormik('destination', {...search.destination, Country: search.destination.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.destination.Country)[0].Code : search.destination.Country})
            setOrigin({...search.origin, Country: search.origin.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.origin.Country)[0].Code : search.origin.Country})
            setDestination({...search.destination, Country: search.destination.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.destination.Country)[0].Code : search.destination.Country})
            setSelectedAcc(search.selectedAcc)
        }else{
            setDataFormik('insuranceAmount', search.insuranceAmount)
            setDataFormik('insuranceAddOn', search.insuranceAddOn)
            setDataFormik('insuranceFreight', search.insuranceFreight)
            setDataFormik('commodityInsurance', search.commodityInsurance)
            setDataFormik('freightInfo', search.freightInfo)
            setDataFormik('freightInfoTotals', search.freightInfoTotals)
            setDataFormik('freightInfoUOM', search.freightInfoUOM)
            setDataFormik('origin', {...search.origin, Country: search.origin.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.origin.Country)[0].Code : search.origin.Country})
            setDataFormik('destination', {...search.destination, Country: search.destination.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.destination.Country)[0].Code : search.destination.Country})
            setDataFormik('courier', search.courier)
            setDataFormik('LTL', search.LTL)
            setOrigin({...search.origin, Country: search.origin.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.origin.Country)[0].Code : search.origin.Country})
            setDestination({...search.destination, Country: search.destination.Country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === search.destination.Country)[0].Code : search.destination.Country})
            setDataFormik('accessorials', search.accessorials)
            setSelectedAcc([...search.accessorials.destination, ...search.accessorials.origin, ...search.accessorials.other])
        }

        setTimeout(() => {
            dispatch({type: actionTypes.SetHistoryUsed, payload: false})
        }, 100);
    }
    
    return (
        <div className='d-flex align-items-center justify-content-center'>
            <Tooltip title="History" open={tooltipOpen}>
                <Select
                    IconComponent={HistoryIcon}
                    className={classes.select}
                    style={{ cursor: 'pointer'}}
                    variant='standard'
                    displayEmpty
                    onChange={(e) => {
                        dispatch({type: actionTypes.SetHistoryUsed, payload: true})
                        setAllDataInFormik(e)
                    }}
                    value=""
                    MenuProps={menuProps}
                    onMouseEnter={() => window.innerWidth > 575 && setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    onOpen={() => setTooltipOpen(false)}
                >
                    {!recentSearches?.length && <MenuItem disabled>No recent searches</MenuItem>}
                    {
                        historyFor === 'Drayage'
                        ? recentSearches?.map((search, index) => {
                            return <MenuItem key={index} value={search.id}>
                                <b className="me-1">{search.origin?.Zipcode}</b> <div className="me-1">to</div> <b className="me-1">{search.destination?.Zipcode}</b>
                            </MenuItem>
                        })
                        : recentSearches?.map((search, index) => {
                            let accessorialsCodes = [...search.accessorials.destination?.map(acc => acc.code), ...search.accessorials.origin?.map(acc => acc.code), ...search.accessorials.other?.map(acc => acc.code)]
                            return <MenuItem key={index} value={search.id}>
                                <b className="me-1">{search.origin.Zipcode}</b> <div className="me-1">to</div> <b className="me-1">{search.destination.Zipcode}, </b> <b className='me-1'>{search.freightInfoTotals.totalPieces}</b> <div className="me-1">pcs @</div> <b className='me-1'>{_.round(search.freightInfoTotals.totalWeight)}</b> <div>{search.freightInfoTotals.symbol}</div>{accessorialsCodes.length ? <div className='me-1'>, Accessorials:</div> : null} {accessorialsCodes?.map((acc, i) => <b className='me-1' key={i}>{acc}</b>)} {historyFor === 'LTL' && <b className="ms-1">- {search.LTL && 'LTL'}{search.courier && (search.LTL ? ', Courier' : 'Courier')}{search.AIR && (search.courier || search.LTL ? ', AIR' : 'AIR')}</b>}
                            </MenuItem>
                        })
                    }
                </Select>
            </Tooltip>
        </div>
    )
}

export default HistorySelect