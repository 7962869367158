import { TextField } from '@mui/material';
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles';
import { MinMaxFormProps } from '../../pages/vendors/models';
import { stylesMUIInput } from '../stylesMUIInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { NumericFormat } from 'react-number-format';

const MinMaxForm = ({data, setData}: MinMaxFormProps) => {
    const classes = useStyles({props: {}})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <>
            <div className="mb-3">
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    allowNegative={false}
                    value={data.pieces}
                    name='pieces'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    thousandSeparator={','}
                    decimalScale={0}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>
            
            <div className="mb-3">
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    allowNegative={false}
                    value={data.pieceWeight}
                    name='pieceWeight'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    thousandSeparator={','}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>

            <div className="mb-3">
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    value={data.totalWeight}
                    name='totalWeight'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    allowNegative={false}
                    thousandSeparator={','}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>

            <div className="mb-3">
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    value={data.length}
                    name='length'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    allowNegative={false}
                    thousandSeparator={','}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>

            <div className="mb-3">
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    value={data.width}
                    name='width'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    allowNegative={false}
                    thousandSeparator={','}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>

            <div className="mb-3">
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    value={data.height}
                    name='height'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    allowNegative={false}
                    thousandSeparator={','}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>

            <div className='mb-3'>
                <NumericFormat
                    customInput={TextField}
                    label=" "
                    InputLabelProps={{shrink: false}}
                    autoComplete="off"
                    className={`${classes.textFieldMUI} minMaxInput`}
                    value={data.CFT}
                    name='CFT'
                    onChange={(e) => {
                        setData({
                            ...data,
                            [e.target.name]: parseFloat(e.target.value.replace(/,/g, ''))
                        })
                    }}
                    allowNegative={false}
                    thousandSeparator={','}
                    decimalScale={2}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 999)
                    }}
                    InputProps={{
                        style: stylesMUIInput(fontSize)
                    }}
                />
            </div>
        </>
    )
}

export default MinMaxForm