import { Backdrop, Fade, Paper } from '@mui/material';
import { modalStyles } from '../../ModalStyles';
import { tableStyles }  from '../Tables/TableStyles';
import { KTSVG } from '../../../../_metronic/helpers';
import MaterialTable from '@material-table/core';
import { dollarUSLocale } from '../../../helpers/dollarUSLocale';
import { QuoteBreakdownModalProps } from '../../../pages/ltl/models';
import { v4 as uuidv4 } from 'uuid';
import { ModalStyled } from '../../Modals.styled';
import { DivContainerStyled } from '../../DrayagePage/DrayagePage.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const QuoteBreakdownModal = ({open, setOpen, data}: QuoteBreakdownModalProps) => {
    const classesTable = tableStyles()
    const classesModal = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleCloseModal = () => {
        setOpen(false)
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesModal.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivContainerStyled className={classesModal.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleCloseModal}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row mb-6 mt-3">
                                <div className="col-6 col-sm-7">
                                    {data?.iconUrl ? <img alt="icon" src={data?.iconUrl} width='90px' className='ms-5'></img> : null}
                                </div>
                                <div className="col-6 col-sm-5">
                                    <span style={{fontWeight: 500}}>{data?.name}</span><br/>
                                    <span>{data?.serviceLevel}</span>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mb-3">
                                    <span style={{fontWeight: 500}}>Quote Breakdown</span>
                                </div>
                            </div>
                            <div className="row" style={{maxHeight: 450, overflow: 'auto'}}>
                                <div className="col-12">
                                    <MaterialTable
                                        style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                                        columns={[
                                            { title: 'Description', field: 'name'},
                                            { title: 'Amount', field: 'total', type: 'currency'},
                                        ]}
                                        data={data?.rateBreakdown?.map(rateBreakdown => (
                                            {
                                                ...rateBreakdown,
                                                id: uuidv4()
                                            }
                                        ))}
                                        options={{
                                            thirdSortClick: false,
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            search: false,
                                            paging: false,
                                            filtering: false,
                                            searchFieldAlignment: 'left',
                                            draggable: false,
                                            rowStyle: (data, idx) => idx%2 !== 0 ? {
                                              backgroundColor: '#f4f4f4',
                                            } : {
                                              backgroundColor: '#ffff',
                                            },
                                        }}
                                        components={{
                                            Container: (props) => <Paper className={classesTable.table} {...props}/>,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-end mt-5">
                                <div className="col-9 d-flex justify-content-end">
                                    <span style={{fontWeight: 500}}>Total</span>
                                </div>
                                <div className="col-3 d-flex justify-content-end">
                                    <span style={{paddingRight: 15}}>{dollarUSLocale.format(data?.total)}</span>
                                </div>
                            </div>
                            <div className="row mt-8 mb-3">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                </DivContainerStyled>
            </Fade>
        </ModalStyled>
    )
}

export default QuoteBreakdownModal