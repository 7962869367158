import { Routes, Route } from 'react-router-dom';
import { LTL } from '../ltl/LTL';
import { VolumeTL } from '../volume-tl/VolumeTL';
import { QuotesGrid } from '../quotes-grid/QuotesGrid';
import { UserModel } from '../../modules/auth/models/UserModel';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import { Drayage } from '../drayage/Drayage';

export const Quote = () => {

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  
  return (
    <Routes>
      {
        user?.allowQuoting ? 
        <>
          {
            user?.enabledVendors?.filter(vendor => vendor.type === 'LTL' || vendor.type === "SP" || vendor.type === "INTL")?.length ? 
            <>
              <Route
                path='ltl'
                element={ <LTL/> }
              />
              <Route
                path='ltl/:id'
                element={ <LTL/> }
              />
            </>
            : null
          }
          {
            user?.enabledVendors?.filter(vendor => vendor.type === 'VOL')?.length ? 
            <>
              <Route
                path='volume-tl'
                element={ <VolumeTL/> }
              />
              <Route
                path='volume-tl/:id'
                element={ <VolumeTL/> }
              />
            </>
            : null
          }
          {
            user?.enabledVendors?.filter(vendor => vendor.type === "DRAY")?.length ? 
            <>
              <Route
                path='drayage'
                element={ <Drayage/> }
              />
              <Route
                path='drayage/:id'
                element={ <Drayage/> }
              />
            </>
            : null
          }
          {/* {
            user?.allowBatchQuoting ? 
              <Route
                path='batch-quoting'
                element={ <BatchQuoting/> }
              />
            : null
          } */}
        </> : null
      }
      <Route
        path='grid-quotes'
        element={ <QuotesGrid/> }
      />
    </Routes>
  )
}