import { useEffect, useState } from 'react'
import { DivPaperStyled, ModalStyled } from '../Modals.styled'
import { KTSVG } from '../../../_metronic/helpers'
import { Backdrop, CircularProgress, Fade } from '@mui/material'
import { modalStyles } from '../ModalStyles'
import { RootState } from '../../../setup'
import { useDispatch, useSelector } from 'react-redux'
import { InputStyled } from '../Inputs.styled'
import axios from 'axios'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'

const BOOK = '/applet/v1/book'

const ChangeBOLNumberModal = ({open, setOpen, BOLId, BOLNumberProps, setFormikValues}) => {
    const dispatch = useDispatch()
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [loading, setLoading] = useState(false)
    const [BOLNumber, setBOLNumber] = useState(BOLNumberProps)

    const changeBOL = () => {
        setLoading(true)
        axios.put(`${BOOK}/${BOLId}`, {
            BOLNmbr: BOLNumber,
        }).then(results => {
            setLoading(false)
            setOpen(false)
            setFormikValues('BOLNumber', BOLNumber)
        }).catch(error => {
            setLoading(false)
            checkErrorAPICall(error, dispatch, 'Get Trackings')
        })
    }

    useEffect(() => {
        if(open){
            setBOLNumber(BOLNumberProps)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])
    
    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paperBOLModal}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Change BOL#</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={() => setOpen(false)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x" 
                            />
                        </div>
                    </div>
                        <>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className='me-3'>BOL#</div>
                                <InputStyled
                                    type="text"
                                    autoComplete='off'
                                    name='BOLNumber'
                                    value={BOLNumber}
                                    onChange={(e) => setBOLNumber(e.target.value)}
                                    className='form-control'
                                    fontSize={fontSize}
                                />
                            </div>
                            <div className="row mt-6 mb-3">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => changeBOL()}
                                    >
                                        Save {loading && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ChangeBOLNumberModal