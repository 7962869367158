import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const FreightInformation = () => {
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any

    return (
        <div className='col-12'>
            <div className="row">
                <div className="col-12">
                    <label className='col-form-label'>Freight Info</label>
                </div>
            </div>
            <div className="row d-none d-md-flex">
                <div className="col-2">
                    <div className="row">
                        <label className='col-form-label col-3'>QTY</label>
                        <label className='col-form-label col-6'>Dim Type</label>
                        <label className='col-form-label col-3'>STC</label>
                    </div>
                    {
                        tracking?.freightInfo?.map((freight) => {
                            return (
                                <div className="row" key={freight.id}>
                                    <label className='col-3 d-flex justify-content-md-end'>{freight?.qty}</label>
                                    <label className='col-6'>{freight?.dimType?.name}</label>
                                    <label className='col-3'>{freight?.STC}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-4">
                    <div className="row">
                        <label className='col-form-label col-7'>Commodity</label>
                        <label className='col-form-label d-flex justify-content-md-end col-2'>Weight</label>
                        <label className='col-form-label col-3'>Type</label>
                    </div>
                    {
                        tracking?.freightInfo?.map((freight) => {
                            return (
                                <div className="row" key={freight.id}>
                                    <label className='col-7'>{freight?.commodity}</label>
                                    <label className='col-2 d-flex justify-content-md-end'>{freight?.weight}</label>
                                    <label className='col-3'>{freight?.each}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-3">
                    <div className="row">
                        <label className='col-form-label d-flex justify-content-md-end col-3'>Length</label>
                        <label className='col-form-label d-flex justify-content-md-end col-3'>Width</label>
                        <label className='col-form-label d-flex justify-content-md-end col-3'>Height</label>
                        <label className='col-form-label d-none d-md-flex justify-content-md-end col-3'>Class</label>
                    </div>
                    {
                        tracking?.freightInfo?.map((freight) => {
                            return (
                                <div className="row" key={freight.id}>
                                    <label className='col-3 d-flex justify-content-md-end'>{freight?.L}</label>
                                    <label className='col-3 d-flex justify-content-md-end'>{freight?.W}</label>
                                    <label className='col-3 d-flex justify-content-md-end'>{freight?.H}</label>
                                    <label className='col-3 d-none d-md-flex justify-content-md-end'>{freight?.class}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col-2">
                    <div className="row">
                        <label className='col-form-label d-md-none d-flex col-4'>Class</label>
                        <label className='col-form-label col-8 d-flex justify-content-md-end'>NMFC</label>
                        <label className='col-form-label col-4 d-flex justify-content-md-end'>UN#</label>
                    </div>
                    {
                        tracking?.freightInfo?.map((freight) => {
                            return (
                                <div className="row" key={freight.id}>
                                    <label className='col-4 d-md-none d-flex'>{freight?.class}</label>
                                    <label className='col-8 d-flex justify-content-md-end'>{freight?.nmfc}</label>
                                    <label className='col-4 d-flex justify-content-md-end'>{freight?.UN}</label>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="row d-md-none d-flex">
                {
                    tracking?.freightInfo?.map((freight) => {
                        return (
                            <Fragment key={freight.id}>
                                <div className="col-12">
                                    <div className="row">
                                        <label className='col-form-label col-4 labelDarker'>QTY</label>
                                        <label className='col-form-label col-4 labelDarker'>Dim Type</label>
                                        <label className='col-form-label col-4 labelDarker'>STC</label>
                                    </div>
                                    <div className="row">
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.qty}</label>
                                        <label className='col-4'>{freight?.dimType?.name}</label>
                                        <label className='col-4'>{freight?.STC}</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <label className='col-form-label col-4 labelDarker'>Commodity</label>
                                        <label className='col-form-label d-flex justify-content-md-end col-4 labelDarker'>Weight</label>
                                        <label className='col-form-label col-4 labelDarker'>Type</label>
                                    </div>
                                    <div className="row">
                                        <label className='col-4'>{freight?.commodity}</label>
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.weight}</label>
                                        <label className='col-4'>{freight?.each}</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <label className='col-form-label d-flex justify-content-md-end col-4 labelDarker'>Length</label>
                                        <label className='col-form-label d-flex justify-content-md-end col-4 labelDarker'>Width</label>
                                        <label className='col-form-label d-flex justify-content-md-end col-4 labelDarker'>Height</label>
                                        <label className='col-form-label d-none d-md-flex justify-content-md-end col-3'>Class</label>
                                    </div>
                                    <div className="row">
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.L}</label>
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.W}</label>
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.H}</label>
                                        <label className='col-3 d-none d-md-flex justify-content-md-end'>{freight?.class}</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row">
                                        <label className='col-form-label d-md-none d-flex col-4 labelDarker'>Class</label>
                                        <label className='col-form-label col-4 d-flex justify-content-md-end labelDarker'>NMFC</label>
                                        <label className='col-form-label col-4 d-flex justify-content-md-end labelDarker'>UN#</label>
                                    </div>
                                    <div className="row">
                                        <label className='col-4 d-md-none d-flex'>{freight?.class}</label>
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.nmfc}</label>
                                        <label className='col-4 d-flex justify-content-md-end'>{freight?.UN}</label>
                                    </div>
                                </div>
                                <hr style={{color: '#a7adce'}} className='mt-3 mb-3'/>
                            </Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FreightInformation