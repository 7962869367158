import { Backdrop, Fade, Modal } from '@mui/material';
import { useState } from 'react'
import { FooterStyled, PaperModal, Title } from '../Modals.styled';
import { modalStyles } from '../ModalStyles';
import { KTSVG } from '../../../../_metronic/helpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actionTypes } from '../../../modules/auth/redux/PagesRedux';
import { RootState } from '../../../../setup';
import { createShipmentsBatchBooking } from '../../../helpers/createShipmentsBatchBooking';

const CancelProcressModal = ({open, setOpen, source, data, setData, invalidZipcodes}) => {
    const dispatch = useDispatch()
    const classes = modalStyles();
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)
    const progress = useSelector<RootState>(({pages}) => pages.progress, shallowEqual) as any

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    const cancelProcess = () => {
        source.cancel('Operation canceled by the user.')
        setOpen(false)

        let arrayOfIds = []
        data.forEach((shipment, index) => {
            if(invalidZipcodes.includes(shipment.ShipperZipcode.toString()) || invalidZipcodes.includes(shipment.ConsigneeZipcode.toString())){
                arrayOfIds.push(shipment.id)
            }
        })

        dispatch({
            type: actionTypes.SetProgress,
            payload: progress.map((p) => {
                return({
                    ...p, progress: 100
                })
            })
        })

        setData(data.map((singleData, index) => {
            if(index === 0) {
                return {
                    ...singleData,
                    progress: 100,
                }
            }else{
                if(!arrayOfIds.includes(singleData.id)){
                    return {
                        ...singleData,
                        progress: 100
                    }
                }else{
                    return singleData
                }
            }
            
        }))

        let newArray = data

        newArray.forEach((arr, index) => {
            createShipmentsBatchBooking(arr, newArray, index, setData)
        })
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal fontSize={fontSize} widthWhenFontSmall={400} className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                            </div>
                            <div className="col-12 mt-5 d-flex justify-content-center">
                                <Title fontSize={fontSize}>Are you sure you want to cancel process?</Title>
                            </div>
                        </div>
                        <FooterStyled fontSize={fontSize} className="row mt-5 mb-5">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => cancelProcess()}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </FooterStyled>
                    </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default CancelProcressModal