import { modalStyles } from '../ModalStyles';
import { Modal, Fade, Backdrop } from '@mui/material';
import { InvalidDataModalProps } from '../../../pages/batchquoting-page/models';
import { BigSubtitle, FooterStyled, LinkStyled, PaperModal, Title } from '../Modals.styled';
import { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

const InvalidDataModal = ({open, setOpen}: InvalidDataModalProps) => {
    const classes = modalStyles();
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/x-circle.svg' className='svg-icon svg-icon-3x text-danger'/>
                                </div>
                                <div className="col-12 mt-5 d-flex justify-content-center">
                                    <Title fontSize={fontSize}>Unable to process: Invalid Format</Title>
                                </div>
                                <div className="col-12 mt-3 d-flex justify-content-center">
                                    <BigSubtitle fontSize={fontSize}>Please use our template files and try again.</BigSubtitle>
                                </div>
                            </div>
                            <div className="row mt-5 mb-3">
                                <FooterStyled fontSize={fontSize} className="col-12 d-flex justify-content-center">
                                    <LinkStyled className="btn btn-primary me-4" href='https://shipprimus.com/templates/TemplatesBatchQuoteV2.zip' download>Download Templates</LinkStyled>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        Close
                                    </button>
                                </FooterStyled>
                            </div>
                        </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default InvalidDataModal