import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { ShippingLocations } from '../shipping-locations/ShippingLocations';
import { ShippingItems } from '../shipping-items/ShippingItems';
import { Vendors } from '../vendors/Vendors';
import { Defaults } from '../defaults/Defaults';

export const Settings = () => {
  return (
    <Routes>
      <Route path='shipping-locations' element={ <ShippingLocations/> } />
      <Route path='shipping-items' element={ <ShippingItems/> } />
      <Route path='vendors' element={ <Vendors/> } />
      <Route path='defaults' element={ <Defaults/> } />
    </Routes>
  )
}
