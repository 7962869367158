import _ from 'lodash';
import store from '../../setup/redux/Store';

export const autoCalculateClass = (qty, weight, length, width, height, data) => {
    let user = store?.getState()?.auth?.user

    if(!user?.allowClassSelection && qty && (length || width || height)){
        let L = length
        let H = width
        let W = height
        let totalCft = parseInt(qty)*(Number(L)*Number(W)*Number(H)/1728)

        if(totalCft){
            if(weight){
                let localDensity = _.ceil((parseInt(weight)*parseInt(qty))/parseFloat(totalCft))

                user?.densityRules?.forEach((density) => {
                    let from = density.densityFrom
                    let to = density.densityTo
                    if(localDensity >= from && (to !== -1 ? localDensity <= to : true)){
                        data.Class = density.class
                    }
                })
            }
        }
    }
}