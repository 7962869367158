import { KTSVG } from "../../_metronic/helpers";

export const fileIcon = (fileName) => {
    let fileType = fileName?.substring(fileName?.indexOf('.') + 1)

    if(fileType === 'pdf'){
        return <KTSVG className="svg-icon svg-icon-3" style={{color: '#3F4254'}} path='/media/icons/bootstrap/files/pdf.svg'/>
    }else if(fileType === 'xls' || fileType === 'xlsx'){
        return <KTSVG className="svg-icon svg-icon-3" style={{color: '#3F4254'}} path='/media/icons/bootstrap/files/excel.svg'/>
    }else if(fileType === 'doc' || fileType === 'docx'){
        return <KTSVG className="svg-icon svg-icon-3" style={{color: '#3F4254'}} path='/media/icons/bootstrap/files/word.svg'/>
    }else if(fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg'){
        return <KTSVG className="svg-icon svg-icon-3" style={{color: '#3F4254'}} path='/media/icons/bootstrap/files/image.svg'/>
    }else if(fileType === 'zip' || fileType === 'rar'){
        return <KTSVG className="svg-icon svg-icon-3" style={{color: '#3F4254'}} path='/media/icons/bootstrap/files/compressed.svg'/>
    }else {
        return <KTSVG className="svg-icon svg-icon-3" style={{color: '#3F4254'}} path='/media/icons/bootstrap/files/default.svg'/>
    }
}