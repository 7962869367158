import React, {useState} from 'react'
import SearchInputToolbar from '../../SearchInputToolbar';
import SelectExportType from '../../SelectExportType/SelectExportType';
import { ToolbarProps } from '../../../../pages/ltl/models';
import { TextField, MenuItem, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useStyles } from '../../FreightInfo/FreightInformation/FreightInformationStyles';

import clsx from 'clsx'
import ClearIcon from '@mui/icons-material/Clear';
import { stylesMUIInput } from '../../../stylesMUIInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';

const Toolbar = ({tableFor, searchTable, handleSearch, rateTypeSelected, setRateTypeSelected, rateTypes, columns, dataColumns, cancelCalls, linearProgress, ratesStopped, setRatesStopped, quoteIsCheaper, setLoadQuotes, ratesCheaper}: ToolbarProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [buttonColor, setButtonColor] = useState('btn-danger')
    const classes = useStyles({props: {}})
    
    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    return (
        <div className="row d-flex align-items-center mt-6">
            <div className={quoteIsCheaper ? "col-6 col-md-3" : "col-4"}>
                <SearchInputToolbar className="w-md-50 w-sm-75" searchTable={searchTable} handleSearch={handleSearch}/>
            </div>
            {
                !quoteIsCheaper
                ? <div className="col-4 d-flex justify-content-center">
                    <Tooltip title={linearProgress >= 100 || ratesStopped ? "" : "Stop Get Rates"}>
                        <button
                            style={{
                                cursor: linearProgress >= 100 || ratesStopped ? 'auto' : 'pointer',
                                opacity: linearProgress >= 100 || ratesStopped ? '0' : '1',
                                transition: (linearProgress >= 100 && "opacity 0.5s ease-in") || (ratesStopped && "opacity 2.5s ease-in"),
                            }}
                            className={`w-100 w-md-50 w-xl-25 btn ${buttonColor} ${ratesStopped ? 'disabled' : ''} d-flex justify-content-center align-items-center`}
                            onClick={() => {
                                cancelCalls()
                                setRatesStopped(true)
                                setButtonColor('btn-success')
                                setTimeout(() => {
                                    setButtonColor('btn-danger')
                                }, 2550);
                            }}
                        >
                            {ratesStopped ? 'Stopped!' : <><ClearIcon fontSize="small" className="me-1"/>Stop</>}
                        </button>
                    </Tooltip>                    
                </div>
                : <div className="col-12 col-md-6 order-5 order-md-0 order-md-12 mt-7 mt-md-0 d-flex justify-content-center">
                    <div style={{fontSize: 14, backgroundColor: '#E4E6EF', borderRadius: '0.475rem'}} className="p-2">
                        <span className="me-3">We've found some competitive {tableFor === 'LTL' ? 'Volume/TL' : 'LTL'} quotes from other providers starting at <b>{ratesCheaper}</b>. Do you wish to see them in the results?</span>
                        <button
                            className="btn btn-primary btn-sm me-1 mt-1"
                            onClick={() => {
                                setLoadQuotes(true)
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="btn btn-danger btn-sm mt-1" 
                            onClick={() => {
                                setLoadQuotes(false)
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>
            }
            <div className={quoteIsCheaper ? "col-6 col-md-3 d-flex justify-content-end" : "col-4 d-flex justify-content-end"}>
                <TextField
                    select
                    label=" "
                    value={rateTypeSelected}
                    onChange={(e) => {
                        setRateTypeSelected(e.target.value)
                    }}
                    className={clsx(classes.selectMUI, 'w-md-50 w-sm-75 me-3')}
                    InputProps={{
                        style: stylesMUIInput(fontSize),
                    }}
                    SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        MenuProps: menuProps
                    }}
                    InputLabelProps={{shrink: false}}
                >
                    <MenuItem value='ALL RATES'>All Rates</MenuItem>
                    {
                        rateTypes.sort((a, b) => a.value?.localeCompare(b.value)).map((rateType, index) => (
                            <MenuItem key={index} value={rateType.value}>{rateType.name}</MenuItem>
                        ))
                    }
                </TextField>
                
                <SelectExportType
                    fileName='Rates'
                    columns={columns}
                    dataColumns={dataColumns}
                />
            </div>
        </div>
    )
}

export default Toolbar