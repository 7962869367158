import { Backdrop, Fade } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers';
import { EmailSentModalProps } from '../../pages/shipping-items/models';
import { DivPaperStyled, ModalStyled, Paragraph, Title } from '../Modals.styled';
import { modalStyles } from '../ModalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const EmailSentModal = ({open, setOpen}: EmailSentModalProps) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/check-circle.svg' className='svg-icon svg-icon-3x text-success'/>
                                </div>
                                <div className="col-12 mt-5 d-flex justify-content-center">
                                    <Title>Inquiry sent</Title>
                                </div>
                                <div className="col-12 d-flex justify-content-center">
                                    <Paragraph>Our team will review the information and will contact you shortly</Paragraph>
                                </div>
                            </div>
                            <div className="row mt-1 mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => setOpen(false)}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default EmailSentModal