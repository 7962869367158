export const checkCountry = (zipcode) => {
    let US = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
    let CA = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);

    let zipcodeNumber = zipcode

    if (zipcode?.toString().length < 5) {
        if (US.test(zipcode?.toString()?.padStart(5, '0'))) {
            zipcodeNumber = zipcode?.toString().padStart(5, '0')
        }
    }

    if (US.test(zipcodeNumber?.toString())) {
        return 'US';
    }

    if (CA.test(zipcodeNumber?.toString())) {
        return 'CA';
    }

    return null;
}