import { ModalStyled } from '../../Modals.styled'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { Backdrop, CircularProgress, Fade } from '@mui/material'
import { modalStyles } from '../../ModalStyles'
import { KTSVG } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall'

const CONTACTS = '/applet/v1/database/contacts'

export const LookupContactsModal = ({open, setOpen, setFormikValues, emails}) => {
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classesModal = modalStyles({fontSize: fontSize})
    const [contacts, setContacts] = useState([])
    const [contactsSelected, setContactsSelected] = useState([])
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(open){
            setLoading(true)
            axios.get(CONTACTS).then((results) => {
                setContacts(results.data.data.results)
                setContactsSelected(emails || [])
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                checkErrorAPICall(error, dispatch, 'Contacts')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classesModal.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classesModal.paper} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Contacts</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div> 
                    </div>
                    <>
                        <div className="row">
                            <div className={`col-12 ${loading || !contacts.length ? 'd-flex justify-content-center' : ''}`}>
                                {!loading ? contacts.length ? contacts.map((contact) => {
                                    return (
                                        <div className="form-check form-check-custom form-check-solid form-check-sm mb-4">
                                            <input
                                                className="form-check-input me-2"
                                                type="checkbox"
                                                id={contact.email}
                                                checked={contactsSelected?.filter(c => c === contact?.email)?.length ? true : false}
                                                value={contact.email}
                                                onChange={(e) => {
                                                    if(e.target.checked){
                                                        setContactsSelected([...contactsSelected, e.target.value])
                                                    }else{
                                                        setContactsSelected(contactsSelected.filter(c => c !== e.target.value))
                                                    }
                                                }}
                                            />
                                            <label htmlFor={contact.email} style={{cursor: 'pointer'}}>
                                                {contact.firstName} {contact.lastName} - {contact.email} - {contact.type}
                                            </label>
                                        </div>
                                    )
                                }) : <div className='mt-4 mb-4'>No results</div> : <CircularProgress style={{color: '#009EF7'}}/>}
                            </div>
                        </div>
                        <div className="row mb-3 mt-5">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-secondary me-4"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setFormikValues('to', contactsSelected)
                                        handleClose()
                                    }}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </Fade>
        </ModalStyled>
    )
}
