import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import axios from 'axios';
import Toolbar from '../../components/ShippingLocationsPage/Toolbar';
import Table from '../../components/ShippingLocationsPage/Table';
import CircleIcon from '@mui/icons-material/Circle';
import ActivateDesactivateModal from '../../components/VendorsPage/ActivateDesactivateModal';
import RestrictionsModal from '../../components/VendorsPage/RestrictionsModal';
import HistorySelect from '../shipping-items/HistorySelect';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { rowDataProps } from './models';

let cancelToken
const GET_VENDORS = '/applet/v1/database/vendor'

export const Vendors = () => {
  const dispatch = useDispatch()
  let {setHistoryElement} = usePageData()
  const [results, setResults] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [openActivateDesactivateModal, setOpenActivateDesactivateModal] = useState(false)
  const [openRestrictionsModal, setOpenRestrictionsModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  const APIRender = (rowData) => {
    return (
      <div style={{textAlign: 'center'}}>
        <CircleIcon style={{color: rowData.APIEnabled ? '#47BE7D' : '#F1416C'}}/>
      </div>
    )
  }

  const activeRender = (rowData) => {
    return (
      <div className='d-flex justify-content-center'>
        <div className="form-check form-switch form-check-custom form-check-solid">
          <input
            style={{backgroundColor: rowData.enabled ? '#47BE7D' : '#e4e6ef'}}
            className="form-check-input h-20px w-30px"
            type="checkbox"
            checked={rowData.enabled}
            onChange={() => {
              setSelectedRow(rowData)
              setOpenActivateDesactivateModal(true)
            }}
          />
        </div>
      </div>
    )
  }

  const typeRender = (rowData) => {    
    return (
      <div>
        {rowData.type?.map((type, index) => <span key={index} className='me-1'>{type.code}{index === rowData.type?.length - 1 ? '' : ','}</span>)}
      </div>
    )
  }
  
  const restrictionsRender = (rowData: rowDataProps) => {
    let restrictions = false

    Object.entries(rowData.APICredentials?.restrictions?.maximums || {}).forEach(([key, value]) => {
      if(value > 0){
        restrictions = true
      }
    });

    Object.entries(rowData.APICredentials?.restrictions?.minimums || {}).forEach(([key, value]) => {
      if(value > 0){
        restrictions = true
      }
    });
    
    return (
      <div className='d-flex justify-content-center'>
        <div className="form-check form-switch form-check-custom form-check-solid">
          <input
            style={{backgroundColor: restrictions ? '#47BE7D' : '#e4e6ef'}}
            className="form-check-input h-20px w-30px"
            type="checkbox"
            checked={restrictions}
            onChange={() => {
              setSelectedRow(rowData)
              setOpenRestrictionsModal(true)
            }}
          />
        </div>
      </div>
    )
  }

  const [columns, setColumns] = useState([
    { title: 'Code', field: 'code', hidden: false},
    { title: 'Name', field: 'name', hidden: false},
    { title: 'MC#', field: 'MCNumber', hidden: false},
    { title: 'Type', field: 'type', render: typeRender, hidden: false},
    { title: 'API', field: 'APIEnabled', render: APIRender, sorting: false, headerStyle: {textAlign: 'center'}, hidden: false},
    { title: 'Restrictions', field: '', render: restrictionsRender, sorting: false, headerStyle: {textAlign: 'center'}, hidden: false},
    { title: 'Active', field: 'enabled', render: activeRender, sorting: false, headerStyle: {textAlign: 'center'}, hidden: false},
  ])

  const getVendors = () => {
    setLoading(true)

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()

    axios.get(GET_VENDORS, {
      cancelToken: cancelToken.token,
      params: {
        includeAPICredentials: true,
        includeBillToInformation: true,
        includeEquipment: true,
        includeServiceArea: true,
        page: pageNumber + 1,
        limit: rowsPerPage,
        search: search.trim()
      }
    }).then((results) => {
      setResults(results.data.data.results)
      setTotalResults(results.data.data.pagingDetails.totalResults)
      setLoading(false)
    }).catch(error => {
      checkErrorAPICall(error, dispatch, 'Vendors')
    })
  }

  useEffect(() => {
    getVendors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, search, rowsPerPage])

  useEffect(() => {
    setHistoryElement(
      <HistorySelect
        setSearch={setSearch}
        historyFor='Vendors'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle>Vendors</PageTitle>
      <Toolbar
        search={search}
        setSearch={setSearch}
        columns={columns}
        setColumns={setColumns}
        results={results}
        setPageNumber={setPageNumber}
        setLoading={setLoading}
        tableFor='Vendors'
      />
      <Table
        loading={loading}
        setLoading={setLoading}
        columns={columns}
        results={results}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalResults={totalResults}
        getData={getVendors}
        tableFor='Vendors'
      />
      <ActivateDesactivateModal
        open={openActivateDesactivateModal}
        setOpen={setOpenActivateDesactivateModal}
        selectedRow={selectedRow}
        getVendors={getVendors}
      />
      <RestrictionsModal
        open={openRestrictionsModal}
        setOpen={setOpenRestrictionsModal}
        selectedRow={selectedRow}
        getVendors={getVendors}
      />
    </>
  )
}
