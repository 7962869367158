import moment from 'moment'
import { PageTitle } from '../../../_metronic/layout/core'
import Card from '../../components/HomePage/Card'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { ChartStyled } from '../reports/Reports.styled'
import CardRanking from '../../components/HomePage/CardRanking'
import { CircularProgress, Paper } from '@mui/material'
import MaterialTable, { MTableBodyRow } from '@material-table/core'
import { tableStyles } from '../../components/LTLandVolumeTLPages/Tables/TableStyles'
import { useNavigate } from 'react-router-dom'
import { UserModel } from '../../modules/auth/models/UserModel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'
import { actionTypes } from '../../modules/auth/redux/PagesRedux'

const DELIVERIES = '/api/v1/bookings/report/deliveries'
const CARRIERBREAKDOWN = '/api/v1/bookings/report/carrierbreakdown'
const MODEBREAKDOWN = '/api/v1/bookings/report/shipmentbreakdown'
const OPENCLAIMS = '/applet/v1/book/openclaims'
const CREDITINFO = '/api/v1/report/creditstatus'
const REPORTS = '/api/v1/bookings/report/quantitysummary'

export const Home = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const classes = tableStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [dataOnTime, setDataOnTime] = useState([])
  const [rankingOnTime, setRankingOnTime] = useState({bestRanking: [], worstRanking: []})
  const [dataModeBreakdown, setDataModeBreakdown] = useState([])
  const [rankingModeBreakdown, setRankingModeBreakdown] = useState([])
  const [dataCarrierBreakdown, setDataCarrierBreakdown] = useState([])
  const [rankingCarrierBreakdown, setRankingCarrierBreakdown] = useState([])
  const [dataCreditInfo, setDataCreditInfo] = useState([] as any)
  const [dataOpenClaims, setDataOpenClaims] = useState([])
  const [loadingOnTime, setLoadingOnTime] = useState(false)
  const [loadingModeBreakdown, setLoadingModeBreakdown] = useState(false)
  const [loadingCarrierBreakdown, setLoadingCarrierBreakdown] = useState(false)
  const [loadingOpenClaims, setLoadingOpenClaims] = useState(false)
  const [reports, setReports] = useState(false)
  const isMinimized = useSelector<RootState>(({pages}) => pages.minimizedMenu) as number

  const [columnsOpenClaims] = useState([
    { title: 'BOL #', field: 'BOLNumber'},
    { title: 'Category', field: 'claimTypeName'},
    { title: 'Claim Amount', field: 'claimAmount'},
  ])

  const options = {
    pieHole: 0.4,
    legend: 'none',
    colors: ['#009EF7', 'rgb(241, 65, 108)', '#50CD89', '#6F42C1', '#F1BC00', '#FB7A21', '#e56be5', '#ff9f7f', '#92e5db', '#e093ff', '#4552e0', '#278dd1', '#7fbf3f', '#fcff7c', '#FFDD57', '#ed2a2a', '#d8008c', '#198754'],
  };

  const getDeliveries = () => {
    setLoadingOnTime(true)
    axios.get(DELIVERIES, {
      params: {
        dateFrom: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
      }
    }).then((results) => {
      setLoadingOnTime(false)
      setRankingOnTime({
        bestRanking: results.data.data.results.extra.bestRanking.sort((a, b) => b.percentOntime - a.percentOntime).sort((a, b) => b.total - a.total).slice(0, 3),
        worstRanking: results.data.data.results.extra.worstRanking.sort((a, b) => b.percentDelay - a.percentDelay).sort((a, b) => b.total - a.total).slice(0, 3),
      })
      setDataOnTime(results.data.data.results.report)
    }).catch(error => {
      setLoadingOnTime(false)
      checkErrorAPICall(error, dispatch, 'Deliveries')
    })
  }

  const getModeBreakdown = () => {
    setLoadingModeBreakdown(true)
    axios.get(MODEBREAKDOWN, {
      params: {
        dateFrom: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
      }
    }).then((results) => {
      setLoadingModeBreakdown(false)
      setRankingModeBreakdown(results.data.data.results.extra.bestRanking)
      setDataModeBreakdown(results.data.data.results.report)
    }).catch(error => {
      setLoadingModeBreakdown(false)
      checkErrorAPICall(error, dispatch, 'Shipping Items')
    })
  }

  const getCarrierBreakdown = () => {
    setLoadingCarrierBreakdown(true)
    axios.get(CARRIERBREAKDOWN, {
      params: {
        dateFrom: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
      }
    }).then((results) => {
      setLoadingCarrierBreakdown(false)
      setRankingCarrierBreakdown(results.data.data.results.extra.bestRanking)
      setDataCarrierBreakdown(results.data.data.results.report)
    }).catch(error => {
      setLoadingCarrierBreakdown(false)
      checkErrorAPICall(error, dispatch, 'Carrier Breakdown')
    })
  }

  const getOpenClaims = () => {
    setLoadingOpenClaims(true)
    axios.get(OPENCLAIMS, {
      params: {
        dateFrom: moment(new Date(new Date().setDate(new Date().getDate() - 30))).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD'),
      }
    }).then((results) => {
      setLoadingOpenClaims(false)
      setDataOpenClaims(results.data.data.results)
    }).catch(error => {
      setLoadingOpenClaims(false)
      checkErrorAPICall(error, dispatch, 'Open Claims')
    })
  }

  const getCreditInformation = () => {
    axios.get(CREDITINFO).then((results) => {
      setDataCreditInfo(results.data.data.results)
    }).catch(error => { 
      checkErrorAPICall(error, dispatch, 'Credit Information')
    })
  }

  const getReports = () => {
    axios.get(REPORTS).then((results) => {
      setReports(results?.data?.data?.results[0])
    }).catch(error => { 
      checkErrorAPICall(error, dispatch, 'Get Reports')
    })
  }

  useEffect(() => {
    getDeliveries()
    getCarrierBreakdown()
    getModeBreakdown()
    getOpenClaims()
    getCreditInformation()
    getReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle>Home</PageTitle>
      <div className="row">
        <div className="col-12 col-md-3 col-sm-6 h-100">
          <Card
            className='h-md-50 mb-5 mb-xl-10'
            description='Shipments Created'
            color='#50CD89'
            filters={{dateFrom: new Date()}}
            reports={reports}
            reportName='shipmentsCreated'
            allowTrack={user?.allowTrack}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-6 h-100">
          <Card
            className='h-md-50 mb-5 mb-xl-10'
            description='Picking Up Today'
            color='#009EF7'
            filters={{pickupDate: moment(new Date()).format('YYYY-MM-DD')}}
            reports={reports}
            reportName='pickingUp'
            allowTrack={user?.allowTrack}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-6 h-100">
          <Card
            className='h-md-50 mb-5 mb-xl-10'
            description='Delivering'
            color='#6f42c1'
            filters={{deliveryDate: moment(new Date()).format('YYYY-MM-DD')}}
            reports={reports}
            reportName='delivering'
            allowTrack={user?.allowTrack}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-6 h-100">
          <Card
            allowTrack={user?.allowTrack}
            className='h-md-50 mb-5 mb-xl-10'
            description='Delayed/Exception'
            color='#F1416C'
            filters={{dateFrom: new Date().setMonth(new Date().getMonth()-1), type: ['DELAYED/EXCEPTION']}}
            reports={reports}
            reportName='delayed'
          />
        </div>
        <div className={`${!isMinimized && window.innerWidth < 1600 && window.innerWidth > 1200 ? 'col-6' : 'col-xl-4 col-md-6 col-12'} pe-6 ps-6 mb-6`}>
          <div className="row bg-white p-2 borderAndShadow">
            <div className="col-12 d-flex align-items-center">
              <label className='col-form-label w-75'>On Time Deliveries</label>
              <div className="w-25 d-flex justify-content-end">
                <div
                  className='btn btn-link'
                  onClick={() => {
                    navigate('../reports/carrier-performance')
                    dispatch({type: actionTypes.SetSeeMoreReportsClicked, payload: true})
                  }}
                >
                  See more
                </div>
              </div>
            </div>
            {
              dataOnTime?.filter(a => a.quantity > 0).length && !loadingOnTime ? 
              <>
                <div className="col-12 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <ChartStyled
                    chartType="PieChart"
                    width="220px"
                    height="300px"
                    data={[
                      ["Delivery", "Quantity"],
                      ...dataOnTime?.map(report => {
                        return [
                          report.delivery,
                          report.quantity
                        ]
                      })
                    ]}
                    options={options}
                  />
                </div>
                <div className="col-sm-5 col-12 d-flex align-items-center justify-content-center">
                  <div className="row">
                    {
                      rankingModeBreakdown?.map((ranking, index) => (
                        <div className={`col-12 d-flex justify-content-center justify-content-sm-start`} key={ranking.shipmentModeName}>
                        </div>
                      ))
                    }
                    <div className="col-12 d-flex justify-content-center justify-content-sm-start">
                      <CardRanking color='blue'>
                        <h5 className='mb-2'>Top 3 Ontime</h5>
                        <div>
                          {
                            rankingOnTime?.bestRanking?.map(ranking => (
                              <div className="d-flex align-items-center" key={ranking.carrierSCAC}>
                                <h5 className="me-1">{ranking.carrierSCAC}</h5>
                                {ranking.percentOntime}% ({ranking.total})
                              </div>
                            ))
                          }
                        </div>
                      </CardRanking>
                    </div>
                    <div className="col-12 mt-3 d-flex justify-content-center justify-content-sm-start mb-8 mb-sm-0">
                      <CardRanking color='red'>
                        <h5 className='mb-2'>Top 3 Delayed</h5>
                        <div>
                          {
                            rankingOnTime?.worstRanking?.map(ranking => (
                              <div className="d-flex align-items-center" key={ranking.carrierSCAC}>
                                <h5 className="me-1">{ranking.carrierSCAC}</h5>
                                {ranking.percentDelay}% ({ranking.total})
                              </div>
                            ))
                          }
                        </div>
                      </CardRanking>
                    </div>
                  </div>
                </div>
              </>
              : loadingOnTime ? <div className='d-flex align-items-center justify-content-center' style={{height: 300}}><CircularProgress style={{color: '#3f4254'}} size={30}/></div> : <div className='d-flex align-items-center justify-content-center' style={{height: 300}}>No results</div>
            }
          </div>
        </div>
        <div className={`${!isMinimized && window.innerWidth < 1600 && window.innerWidth > 1200 ? 'col-6' : 'col-xl-4 col-md-6 col-12'} pe-6 ps-6 mb-6`}>
          <div className="row bg-white p-2 borderAndShadow">
            <div className="col-12 d-flex align-items-center">
              <label className='col-form-label w-75'>Shipment Mode Breakdown</label>
              <div className="w-25 d-flex justify-content-end">
                <div
                  className='btn btn-link'
                  onClick={() => {
                    navigate('../reports/shipment-mode-breakdown')
                    dispatch({type: actionTypes.SetSeeMoreReportsClicked, payload: true})
                  }}
                >
                  See more
                </div>
              </div>
            </div>
            {
              dataModeBreakdown?.filter(a => a.quantity > 0).length && !loadingModeBreakdown ? 
              <>
                <div className="col-12 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <ChartStyled
                    chartType="PieChart"
                    width="220px"
                    height="300px"
                    data={[
                      ['Shipment Mode', "Quantity"],
                      ...dataModeBreakdown.map(report => {
                        return [
                          report.shipmentModeName,
                          report.quantity
                        ]
                      })
                    ]}
                    options={options}
                  />
                </div>
                <div className="col-12 col-sm-5 d-flex align-items-center justify-content-center">
                  <div className="row">
                    {
                      rankingModeBreakdown?.map((ranking, index) => (
                        <div className={`col-12 d-flex justify-content-center justify-content-sm-start ${index !== 0 && 'mt-3'} ${index === rankingModeBreakdown.length - 1 && 'mb-8 mb-sm-0'}`} key={ranking.shipmentModeName}>
                          <CardRanking color={index === 0 ? 'blue' : index === 1 ? 'red' : 'yellow'}>
                            <h5>#{index + 1} {ranking?.shipmentModeName}</h5>
                            <div>
                              <div className="d-flex">
                                <h5 className="me-1">{ranking?.quantity}</h5><div>Shipments</div>
                              </div>
                            </div>
                          </CardRanking>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </>
              : loadingModeBreakdown ? <div className='d-flex align-items-center justify-content-center' style={{height: 300}}><CircularProgress style={{color: '#3f4254'}} size={30}/></div> : <div className='d-flex align-items-center justify-content-center' style={{height: 300}}>No results</div>
            }
          </div>
        </div>
        <div className={`${!isMinimized && window.innerWidth < 1600 && window.innerWidth > 1200 ? 'col-6' : 'col-xl-4 col-md-6 col-12'} pe-6 ps-6 mb-6`}>
          <div className="row bg-white p-2 borderAndShadow">
            <div className="col-12 d-flex align-items-center">
              <label className='col-form-label w-75'>Shipments by Carrier Breakdown</label>
              <div className="w-25 d-flex justify-content-end">
                <div
                  className='btn btn-link'
                  onClick={() => {
                    navigate('../reports/shipments-by-carrier')
                    dispatch({type: actionTypes.SetSeeMoreReportsClicked, payload: true})
                  }}
                >
                  See more
                </div>
              </div>
            </div>
            {
              dataModeBreakdown?.filter(a => a.quantity > 0).length && !loadingCarrierBreakdown ? 
              <>
                <div className="col-12 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <ChartStyled
                    chartType="PieChart"
                    width="220px"
                    height="300px"
                    data={[
                      ['Carrier', "Quantity"],
                      ...dataCarrierBreakdown?.map(report => {
                        return [
                          report.carrierSCAC,
                          report.quantity
                        ]
                      })
                    ]}
                    options={options}
                  />
                </div>
                <div className="col-12 col-sm-5 d-flex align-items-center justify-content-center">
                  <div className="row">
                    {
                      rankingCarrierBreakdown?.map((ranking, index) => (
                        <div className={`col-12 d-flex justify-content-center justify-content-sm-start ${index !== 0 && 'mt-3'} ${index === 2 && 'mb-8 mb-sm-0'}`} key={ranking.carrierSCAC}>
                          <CardRanking color={index === 0 ? 'blue' : index === 1 ? 'red' : 'yellow'}>
                            <h5>#{index + 1} {ranking?.carrierSCAC}</h5>
                            <div>
                              <div className="d-flex">
                                <h5 className="me-1">{ranking?.quantity}</h5><div>Shipments</div>
                              </div>
                            </div>
                          </CardRanking>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </>
              : loadingCarrierBreakdown ? <div className='d-flex align-items-center justify-content-center' style={{height: 300}}><CircularProgress style={{color: '#3f4254'}} size={30}/></div> : <div className='d-flex align-items-center justify-content-center' style={{height: 300}}>No results</div>
            }
          </div>
        </div>
        <div className={`${!isMinimized && window.innerWidth < 1600 && window.innerWidth > 1200 ? 'col-6' : 'col-xl-4 col-md-6 col-12'} pe-6 ps-6 mb-6`}>
          <div className="row bg-white p-2 borderAndShadow">
            <div className="col-12 d-flex align-items-center">
              <label className='col-form-label w-75'>Open Claims</label>
              <div className="w-25 d-flex justify-content-end">
                <div
                  className='btn btn-link'
                  onClick={() => {
                    navigate('../reports/open-claims')
                    dispatch({type: actionTypes.SetSeeMoreReportsClicked, payload: true})
                  }}
                >
                  See more
                </div>
              </div>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center justify-content-sm-start" style={{height: '300px !important'}}>
              <MaterialTable
                isLoading={loadingOpenClaims}
                style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                columns={columnsOpenClaims as any}
                data={dataOpenClaims}
                options={{
                  thirdSortClick: false,
                  actionsColumnIndex: -1,
                  toolbar: false,
                  draggable: false,
                  filtering: false,
                  paging: false,
                  rowStyle: (data, idx) => idx%2 !== 0 ? {
                    backgroundColor: '#f4f4f4',
                  } : {
                    backgroundColor: '#ffff',
                  }
                }}
                components={{
                  Container: (props) => <Paper className={`${classes.tableOpenClaims} w-100`} {...props} />,
                  Row: rowProps => <MTableBodyRow key={rowProps.index} {...rowProps} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,
                }}
              />
            </div>
          </div>
        </div>

        <div className={`${!isMinimized && window.innerWidth < 1600 && window.innerWidth > 1200 ? 'col-6' : 'col-xl-4 col-md-6 col-12'} pe-6 ps-6 mb-6`}>
          <div className="row bg-white p-2 borderAndShadow">
            <div className="col-12 d-flex align-items-center">
              <label className='col-form-label w-75'>Credit Info</label>
              <div className="w-25 d-flex justify-content-end">
                {user?.displayInvoiceInformation
                ? <div
                    className='btn btn-link'
                    onClick={() => {
                      navigate('../invoices')
                    }}
                  >
                    See more
                  </div> 
                : null}
              </div>
            </div>
            {
              dataCreditInfo ? 
              <>
                <div className="col-12 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <ChartStyled
                    chartType="PieChart"
                    width="220px"
                    height="300px"
                    data={[
                      ['Information', "USD"],
                      ['Credit Limit', dataCreditInfo.creditAvailable < 0 ? 0 : dataCreditInfo.creditAvailable],
                      ['Total Due', dataCreditInfo.invoicesDue],
                      ['Total In Transit', dataCreditInfo.inTransit]
                    ]}
                    options={options}
                  />
                  <div style={{position: 'relative', left: -124, width: 0}}>
                    {dataCreditInfo.creditLimit < 0 ? 0 : Math.ceil(((dataCreditInfo.invoicesDue + dataCreditInfo.inTransit)/dataCreditInfo.creditLimit * 100)) > 100 ? 100 : Math.ceil(((dataCreditInfo.invoicesDue + dataCreditInfo.inTransit)/dataCreditInfo.creditLimit * 100))}%
                  </div>
                </div>
                <div className="col-12 col-sm-5 d-flex align-items-center justify-content-center">
                  <div className="row">
                    <div className='col-12 d-flex justify-content-center justify-content-sm-start'>
                      <CardRanking color='blue'>
                        <h5>Credit Limit</h5>
                        <div>
                          <div className="d-flex">
                            <h5 className="me-1">{dataCreditInfo.creditLimit}</h5><div>USD</div>
                          </div>
                        </div>
                      </CardRanking>
                    </div>
                    <div className='col-12 d-flex justify-content-center justify-content-sm-start mt-3'>
                      <CardRanking color='red'>
                        <h5>Total Due</h5>
                        <div>
                          <div className="d-flex">
                            <h5 className="me-1">{dataCreditInfo.invoicesDue}</h5><div>USD</div>
                          </div>
                        </div>
                      </CardRanking>
                    </div>
                    <div className='col-12 d-flex justify-content-center justify-content-sm-start mt-3 mb-8 mb-sm-0'>
                      <CardRanking color='yellow'>
                        <h5>Total In Transit</h5>
                        <div>
                          <div className="d-flex">
                            <h5 className="me-1">{dataCreditInfo.inTransit}</h5><div>USD</div>
                          </div>
                        </div>
                      </CardRanking>
                    </div>
                  </div>
                </div>
              </>
              : <div className='d-flex align-items-center justify-content-center' style={{height: 300}}>No results</div>
            } 
          </div>
        </div>
      </div>
    </>
  )
}
