import { DivPaperStyled, ModalStyled, Title } from '../../Modals.styled';
import { modalStyles } from '../../ModalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';


const ConfirmDuplicateShipmentModal = ({open, setOpen, duplicateShipmentInfo, setDuplicateShipmentInfo, rowData, selectBOL}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const duplicateBooking = () => {
        selectBOL(rowData, true)
    }

    return (
      <ModalStyled
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          fontSize={fontSize}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
              timeout: 500,
          }}
      >
        <Fade in={open}>
            <DivPaperStyled className={classes.paper}>
                <div className="row">
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <Title>Are you sure you want to duplicate this shipment?</Title>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='copyBOLSpecialInstructions'
                                        onChange={e => {
                                            setDuplicateShipmentInfo({
                                                ...duplicateShipmentInfo,
                                                copyBOLSpecialInstructions: e.target.checked ? true : false
                                            })
                                        }}
                                    />
                                    <label
                                        className='form-check-label fw-bold text-gray-700 fs-6'
                                        htmlFor='copyBOLSpecialInstructions'
                                    >
                                       Copy over BOL Special Instructions
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-2 mb-4">
                                <div className='form-check form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='copyExternalNotes'
                                        onChange={e => {
                                            setDuplicateShipmentInfo({
                                                ...duplicateShipmentInfo,
                                                copyExternalNotes: e.target.checked ? true : false
                                            })
                                        }}
                                    />
                                    <label
                                        className='form-check-label fw-bold text-gray-700 fs-6'
                                        htmlFor='copyExternalNotes'
                                    >
                                       Copy over External Notes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-1 mb-3">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                        duplicateBooking()
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </>
            </DivPaperStyled>
        </Fade>
    </ModalStyled>
  )
}

export default ConfirmDuplicateShipmentModal