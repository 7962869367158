import { DivPaperStyled, ModalStyled, Title } from '../../../components/Modals.styled';
import { modalStyles } from '../../../components/ModalStyles';
import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';

const ConfirmClearRateModal = ({open, setOpen, setFormikValues}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
      <ModalStyled
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          fontSize={fontSize}
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
              timeout: 500,
          }}
      >
        <Fade in={open}>
            <DivPaperStyled className={classes.paper}>
                <div className="row">
                    <div className="d-flex align-items-center justify-content-end">
                        <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                            </div>
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <Title>Are you sure you want to clear rate?</Title>
                            </div>
                        </div>
                        <div className="row mt-1 mb-3">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                      setFormikValues(`carrierInformation`, {})
                                      setFormikValues(`url`, '')
                                      setFormikValues(`quoteNumber`, '')
                                      setFormikValues(`vendorId`, '')
                                      setFormikValues(`vendorSCAC`, '')
                                      setOpen(false)
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </>
            </DivPaperStyled>
        </Fade>
    </ModalStyled>
  )
}

export default ConfirmClearRateModal