import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const IconButtonStyled = styled(IconButton)`
    position: absolute !important;
    top: 39px !important;
    right: 2% !important
`

export const DivRelative = styled.div`
    position: relative;
`