import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeStyles } from '@mui/styles';
import { PickupDateProps } from '../../pages/ltl/models';
import { useEffect, useState } from 'react';
import { RootState } from '../../../setup/redux/RootReducer';
import { useSelector } from 'react-redux';
import { shortCutsDateInput } from '../../helpers/shortCutsDateInput';

const DateInput = ({setDataFormik, pickupDate, error, touched, setTouched, name, allowPastDates, minDate}: PickupDateProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
    const useStyles = makeStyles({
        textField: {
            backgroundColor: 'white',
            border: error && touched ? '1px solid #F1416C !important' : '1px solid #E4E6EF !important',
            borderRadius: '0.475rem',
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "& .MuiOutlinedInput-root": {
                height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
                fontFamily: 'Poppins !important',
                fontSize: 14,
                fontWeight: 500,
                color:"#5E6278 !important",
            },
            "& .Mui-disabled": {
                background: '#EFF2F5 !important'
            }
        }
    })
    const [TDown, setTDown] = useState(false)
    const [minusDown, setMinusDown] = useState(false)
    const [numberDown, setNumberDown] = useState('')
    const classes = useStyles()
    
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns as any}>
                <DatePicker
                    disabled={newBookingValues.dispatched}
                    PaperProps={{
                        sx: {
                            "& .MuiPaper-root .Mui-selected": {
                                backgroundColor: "#009EF7 !important",
                            },
                            "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#009EF7 !important",
                                color: 'white !important',
                                fontSize: fontSize !== 13 ? fontSize : 11
                            },
                            "& .MuiButtonBase-root-MuiPickersDay-root": {
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: "#a1a5b6",
                                fontSize: fontSize !== 13 ? fontSize : 11
                            },
                            "& .MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)": {
                                border: "1px solid #5E6278"
                            },
                            "& .MuiTypography-root": {
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                color: "#5E6278",
                                fontSize: fontSize !== 13 ? fontSize : 12
                            },
                            "& button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled)": {
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: fontSize !== 13 ? fontSize : 11,
                                color: '#5E6278'
                            },
                            "& .Mui-selected": {
                                color: 'white !important',
                                backgroundColor: "#009EF7 !important",
                            },
                            "& .MuiCalendarPicker-root": {
                                color: "#5E6278", 
                            }
                        },
                    }}
                    value={pickupDate || null}
                    onChange={(newValue) => {
                        setDataFormik(name ? name : 'pickupDate', newValue)
                        if(!newValue){
                            setTouched(false)
                        }
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            className={`${classes.textField} w-100 ${name}`}
                            value={pickupDate}
                            autoComplete='off'
                            onBlur={() => {
                                if(pickupDate){
                                    setTouched(true)
                                }
                            }}
                            onKeyDown={(e) => {
                                shortCutsDateInput(e.key, name ? name : 'pickupDate', setNumberDown, numberDown, setMinusDown, minusDown, setTDown, TDown, setDataFormik)
                            }}
                            onKeyUp={(e) => {
                                if(e.key === 't'){
                                    setTDown(false)
                                }else if(e.key === '-'){
                                    setMinusDown(false)
                                }
                            }}
                        />
                    }
                    minDate={allowPastDates ? minDate ? minDate : null : new Date()}
                    inputFormat="MM/dd/yy"
                    mask='__/__/__'
                />
            </LocalizationProvider>
        </>
    )
}

export default DateInput