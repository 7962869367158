import { call } from "./call";

export const make3PLCalls = async(finalArray3PL, rows, markups, vendorsToUse, setData, callsDid, allCalls, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers) => {
    for (let i = 0; i < finalArray3PL.length; i++) {
        const groupOfCalls = finalArray3PL[i].map(async(callSingle) => {
            return call(callSingle.shipmentRow, rows, callSingle.vendor, markups, vendorsToUse, setData, callsDid, allCalls, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers)
        })

        await Promise.all(groupOfCalls)

        continue;
    }
}