import React, {useEffect, useState} from 'react'
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { TypeOptions } from '../../../../data/TypeOptions';
import { FreightInformationProps } from '../../../../pages/ltl/models';
import { TextField, InputAdornment, IconButton, MenuItem, Autocomplete, CircularProgress, Tooltip } from '@mui/material';
import { useStyles, DivContainerStyled } from './FreightInformationStyles';
import UNLookupModal from '../../UNLookupModal/UNLookupModal';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../../../setup/redux/RootReducer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { KTSVG } from '../../../../../_metronic/helpers';
import ShippingItemsModal from '../ShippingItemsModal';
import { SelectStyled, TextFieldStyled, NumberFormatStyled, InputStyled } from '../../../Inputs.styled';
import { PaperStyled } from '../../LTLAndVolumeTLPages.styled';
import { makeStyles } from '@mui/styles';
import { stylesMUIInput } from '../../../stylesMUIInput';
import { quoteValuesModel } from '../../../PagesModels';
import InputMask from  'react-input-mask'
import NMFCLookupModal from '../../NMFCLookupModal/NMFCLookupModal';
import axios from 'axios';
import { checkErrorAPICall } from '../../../../helpers/checkErrorAPICall';
import RequireClassITModal from '../../NMFCTable/RequireClassITModal';

const GET_NMFCS = 'api/v1/database/nmfc/search'

const FreightInformation = React.memo(({setDataFormik, freightInfo, index, freight, freightInfoUOM, handleChange, formik, errors, touched, disabled, freightForNewBooking, formikValues, setOpenClearRateToEditModal, formikDispatch, dispatchShip, mode, setOpenHazContactModal, setSelectedUn, selectedUn}: FreightInformationProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const hazEnabled = user.enabledAccessorials.find(accessorial => accessorial.code === 'HAZ')
    const [densityOptions, setDensityOptions] = useState([])
    const [openUNModal, setOpenUNModal] = useState(false)
    const [openNMFCModal, setOpenNMFCModal] = useState(false)
    const [openShippingItemsModal, setOpenShippingItemsModal] = useState(false)
    const [typeLocal, setTypeLocal] = useState('')
    const [loadingNMFC, setLoadingNMFC] = useState(false)
    const [openRequireClassITModal, setOpenRequireClassITModal] = useState(false)
    const props = {
        fontSize: fontSize,
        disabled: !user?.allowClassSelection,
    }
    const classes = useStyles(props)
    const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
    const quoteValues = useSelector<RootState>(({pages}) => pages.quoteValues) as quoteValuesModel
    const historyUsed = useSelector<RootState>(({pages}) => pages.historyUsed)
    const dispatch = useDispatch()

    const useHeightStyles = makeStyles({
        height: {
            height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
        }
    })
    const classesHeight = useHeightStyles()

    const poundsForEver = (value, valueType = '', freightInfoUOM) => {
        if (freightInfoUOM.value === 'US'){
            return parseFloat(value)
        } else {
            return parseFloat(valueType === 'weight' ? (value/0.45359237).toString() : (value/2.54).toString())
        }
    }

    const calculateVolume = (freightInfoItem) => {
        if (freightInfoItem.qty > 0 && freightInfoItem.Lus > 0 && freightInfoItem.Wus > 0 && freightInfoItem.Hus > 0) {
            let QTY = parseInt(freightInfoItem.qty);
            let L = parseFloat(freightInfoItem.Lus);
            let W = parseFloat(freightInfoItem.Wus);
            let H = parseFloat(freightInfoItem.Hus);
            let cubic = L * W * H;
    
            if (freightInfoUOM.value !== 'US') {
                cubic /= 1000000; // m3
            } else {
                cubic /= 1728; // ft3
            }
    
            return (cubic * QTY).toFixed(2);
        } else if (user?.requireDimensions === false) {
            return 1;
        } else {
            return freightInfoItem.volume;
        }
    }
    
    const addNewFreight = () => {
        const newData = {
            id: uuidv4(), 
            qty: '',
            dimType: freightForNewBooking ?
            newBookingValues?.freightInfo[0]?.dimType || {
                name: "PALLET",
                value: "PLT"
            }
            : {
                name: "PALLET",
                value: "PLT"
            },
            L: freightForNewBooking ? newBookingValues?.freightInfo[0]?.L || '' : '',
            W: freightForNewBooking ? newBookingValues?.freightInfo[0]?.W || '' : '',
            H: freightForNewBooking ? newBookingValues?.freightInfo[0]?.H || '' : '',
            commodity: freightForNewBooking ? newBookingValues?.freightInfo[0]?.commodity || '' : '',
            STC: '',
            weight: '',
            each: 'Each',
            class: '',
            nmfc: '',
            weightus: '',
            Lus: '',
            Wus: '',
            Hus: '',
            stack: null,
            totalCft: '',
            hazmat: false,
            UN: '',
            volume: '',
        }
        const freightNewArray = [...freightInfo, newData]
        setDataFormik('freightInfo', freightNewArray)
    }

    const removeFreight = (id) => {
        let newArray = freightInfo.filter(data => data.id !== id)
        setDataFormik('freightInfo', newArray)
    }

    useEffect(() => {
        // Calc the CFT
        if(freight.qty && (freight.Lus || freight.Wus || freight.Hus)){
            let L = freight.Lus
            let H = freight.Hus
            let W = freight.Wus
            let locals = parseInt(freight.qty)*(Number(L)*Number(W)*Number(H)/1728)
            setDataFormik(`freightInfo[${index}].totalCft`, locals)
        }

        // Calc the Volume
        setDataFormik(`freightInfo[${index}].volume`, calculateVolume(freight))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freight.L, freight.H, freight.W, freight.qty, freight.each])

    useEffect(() => {
        let arrayOfDensities = user?.densityRules?.map(density => density.class)
        setDensityOptions(arrayOfDensities.sort((a, b) => parseFloat(a) - parseFloat(b)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.densityRules])

    useEffect(()=> {
        // Compute Class Select Element of Local Density
        if(freight.totalCft){
            if(freight.weight){
                let localDensity = _.ceil((freight.each === 'Each') ? (parseInt(freight.weightus)*parseInt(freight.qty))/parseFloat(freight.totalCft) : parseFloat(freight.weightus)/parseFloat(freight.totalCft), 2) 

                user?.densityRules?.forEach((density) => {
                    let from = density.densityFrom
                    let to = density.densityTo
                    if(localDensity >= from && (to !== -1 ? localDensity <= to : true)){
                        if(quoteValues.length === 0 && !historyUsed){
                            setDataFormik(`freightInfo[${index}].class`, density.class)
                        }
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freight.totalCft, freight.weight, freight.each])


    useEffect(()=> {
            let UNIndex = freightInfo.findIndex(freightInfoUN => freightInfoUN.UN === selectedUn.UNNumber)
            if(freight.UN){                
                setDataFormik(`freightInfo[${UNIndex}].UNPKGGroup`, selectedUn?.PKGGroup)                    
            }
            else if(!selectedUn.PKGGroup) {
                setDataFormik(`freightInfo[${UNIndex}].UNPKGGroup`, '')
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [freight.UN, selectedUn?.PKGGroup])

    const hasChanged = (property) => {
        let value = formik?.values?.freightInfo[index]&&[property]
        let initialValue = formik?.initialValues&&[property]

        return _.isEqual(value, initialValue)
    }

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    return (
        <>
            <DivContainerStyled>
            <div style={{display: 'flex', flexWrap: 'wrap'}} className="mb-3">
                <div style={{flex: 0.3}} className="me-2 d-flex align-items-center mt-4">
                    <IconButton onClick={() => setOpenShippingItemsModal(true)} disabled={disabled}>
                        <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: "#5E6278"}}/>
                    </IconButton>
                </div>
                <div style={{flex: 1, minWidth: 60}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <NumberFormatStyled
                        disabled={disabled}
                        placeholder="QTY"
                        className={errors?.qty && touched?.qty && hasChanged('qty') ? `form-control form-control-error ${disabled && 'disabled'}` : `form-control ${disabled && 'disabled'}`}
                        autoComplete="off"
                        value={freight.qty}
                        name={`freightInfo[${index}].qty`}
                        allowNegative={false}
                        readOnly={formikValues?.carrierInformation?.name ? true : false}
                        onClick={() => {
                            if(formikValues?.carrierInformation?.name){
                                setOpenClearRateToEditModal(true)
                            }
                        }}
                        onChange={(e) => {
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].qty`,
                                    value: parseInt(e.target.value.replace(/,/g, '')) || ''
                                }
                            })
                        }}
                        thousandSeparator={','}
                        decimalScale={0}
                        isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === '' || (floatValue >= 0 && floatValue <= 99)
                        }}
                        onBlur={() => formik.setFieldTouched(`freightInfo[${index}].qty`, true)}
                        fontSize={fontSize}
                    />
                    {errors?.qty && touched?.qty && hasChanged('qty')
                        ? <small className="text-danger">{errors.qty}</small>
                        : null
                    }
                </div>
                <div style={{flex: 1, minWidth: 75, maxWidth: 75}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <Autocomplete
                        isOptionEqualToValue={(option, value) => option?.name === value?.name}
                        inputValue={typeLocal || ""}
                        value={freight.dimType || {name: "PALLET", value: "PLT"}}
                        options={TypeOptions || []}
                        disabled={disabled}
                        autoSelect
                        disableClearable
                        autoComplete={false}
                        autoHighlight={true}
                        className={classes.selectMUI}
                        onChange={(e, value) => {
                            formik.setFieldValue(`freightInfo[${index}].dimType`, value)
                        }}
                        onInputChange={(e, value) => {
                            setTypeLocal(value || "")
                        }}
                        renderOption={(props,option) => {
                            return (
                                <li {...props}>{option.name}</li>
                            )
                        }}
                        getOptionLabel={(option) => option?.value || ""}
                        style={{
                            fontSize: '14px !important',
                            fontFamily: 'Poppins !important',
                            fontWeight: '500 !important',
                            background: 'white',
                            color: "#5E6278",
                            height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)'
                        }}
                        popupIcon={<KeyboardArrowDownIcon />}
                        renderInput={(params) => {
                            return <TextFieldStyled
                                {...params}
                                label=" "
                                InputLabelProps={{shrink: false}}
                                InputProps={{
                                    ...params.InputProps,
                                    className: classesHeight.height,
                                    style: {fontSize: 14, fontFamily: 'Poppins', fontWeight: 500, paddingRight: 9, color:"#5E6278 !important", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}
                                }}
                            />
                        }}
                        PaperComponent={({ children }) => (
                            <PaperStyled fontSize={fontSize} paddingsmall='true' style={{width: 100}}>
                                {children}
                            </PaperStyled>
                        )}
                        noOptionsText={<div style={{fontWeight: 500, color: "#a1a5b6"}}>No options</div>}
                    />
                </div>
                <div style={{flex: 1, minWidth: 100}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <InputStyled
                        disabled={disabled}
                        className={`form-control ${disabled && 'disabled'}`}
                        type="text"
                        autoComplete='off'
                        placeholder="STC"
                        value={freight.STC}
                        onChange={handleChange}
                        name={`freightInfo[${index}].STC`}
                        fontSize={fontSize}
                    />
                </div>
                <div style={{flex: 3, minWidth: 100}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <Tooltip title={freight?.commodity || ''}>
                        <InputStyled
                            disabled={disabled}
                            type="text"
                            autoComplete='off'
                            placeholder="COMMODITY"
                            value={freight?.commodity}
                            onChange={handleChange}
                            name={`freightInfo[${index}].commodity`}
                            className={`form-control ${disabled && 'disabled'}`}
                            fontSize={fontSize}
                            error={dispatchShip ? formikDispatch?.errors?.freightInfo?.length && formikDispatch?.errors?.freightInfo[index]?.commodity : ''}
                        />
                    </Tooltip>
                    {dispatchShip && formikDispatch?.errors?.freightInfo?.length && formikDispatch?.errors?.freightInfo[index].commodity ? <small className="text-danger mt-1">Required</small> : null}
                </div>
                <div style={{flex: 1.2, minWidth: 80, maxWidth: 80}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <NumberFormatStyled
                        disabled={disabled}
                        type="tel"
                        className={errors?.weight && touched?.weight && hasChanged('weight') ? `form-control form-control-error ${disabled && 'disabled'}` : `form-control ${disabled && 'disabled'}`}
                        placeholder="Weight"
                        autoComplete="off"
                        value={freight.weight ? _.round(Number(freight.weight)) : ''}
                        name={`freightInfo[${index}].weight`}
                        readOnly={formikValues?.carrierInformation?.name ? true : false}
                        onClick={() => {
                            if(formikValues?.carrierInformation?.name){
                                setOpenClearRateToEditModal(true)
                            }
                        }}
                        allowNegative={false}
                        onChange={(e) => {
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].weight`,
                                    value: parseInt(e.target.value.replace(/,/g, '')) || ''
                                }
                            })
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].weightus`,
                                    value: poundsForEver(parseFloat(e.target.value.replace(/,/g, '')), 'weight', freightInfoUOM)
                                }
                            })
                        }}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                        }}
                        onBlur={() => formik.setFieldTouched(`freightInfo[${index}].weight`, true)}
                        fontSize={fontSize}
                    />
                    {errors?.weight && touched?.weight && hasChanged('weight')
                        ? <small className="text-danger">{errors.weight}</small>
                        : null
                    }
                </div>
                <div style={{flex: 1, minWidth: 100}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <TextFieldStyled
                        disabled={disabled}
                        select
                        label=" "
                        value={freight.each || 'Each'}
                        onChange={handleChange}
                        name={`freightInfo[${index}].each`}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        <MenuItem value="Each">Each</MenuItem>
                        <MenuItem value="Total">Total</MenuItem>
                    </TextFieldStyled>
                </div>
                <div style={{flex: 1, minWidth: 50}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <NumberFormatStyled
                        disabled={disabled}
                        placeholder="L"
                        className={errors?.L && touched?.L && hasChanged('L') ? `form-control form-control-error ${disabled && 'disabled'}` : `form-control ${disabled && 'disabled'}`}
                        autoComplete="off"
                        value={freight.L ? _.round(Number(freight.L)) : ''}
                        name={`freightInfo[${index}].L`}
                        readOnly={formikValues?.carrierInformation?.name ? true : false}
                        onClick={() => {
                            if(formikValues?.carrierInformation?.name){
                                setOpenClearRateToEditModal(true)
                            }
                        }}
                        allowNegative={false}
                        onChange={(e) => {
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].L`,
                                    value: parseFloat(e.target.value.replace(/,/g, '')) || ''
                                }
                            })
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].Lus`,
                                    value: poundsForEver(parseFloat(e.target.value.replace(/,/g, '')), 'L', freightInfoUOM) || ''
                                }
                            })
                        }}
                        thousandSeparator={','}
                        isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                        }}
                        fontSize={fontSize}                        
                    />
                </div>
                <div style={{flex: 1, minWidth: 50}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <NumberFormatStyled
                        disabled={disabled}
                        placeholder="W"
                        className={errors?.W && touched?.W && hasChanged('W') ? `form-control form-control-error ${disabled && 'disabled'}` : `form-control ${disabled && 'disabled'}`}
                        autoComplete="off"
                        value={freight.W ? _.round(Number(freight.W)) : ''}
                        name={`freightInfo[${index}].W`}
                        readOnly={formikValues?.carrierInformation?.name ? true : false}
                        onClick={() => {
                            if(formikValues?.carrierInformation?.name){
                                setOpenClearRateToEditModal(true)
                            }
                        }}
                        allowNegative={false}
                        onChange={(e) => {
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].W`,
                                    value: parseFloat(e.target.value.replace(/,/g, '')) || ''
                                }
                            })
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].Wus`,
                                    value: poundsForEver(parseFloat(e.target.value.replace(/,/g, '')), 'W', freightInfoUOM) || ''
                                }
                            })
                        }}
                        thousandSeparator={','}
                        isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                        }}
                        style={{height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)'}}
                        fontSize={fontSize}
                    />
                </div>
                <div style={{flex: 1, minWidth: 50}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <NumberFormatStyled
                        disabled={disabled}
                        placeholder="H"
                        className={errors?.H && touched?.H && hasChanged('H') ? `form-control form-control-error ${disabled && 'disabled'}` : `form-control ${disabled && 'disabled'}`}
                        autoComplete="off"
                        value={freight.H ? _.round(Number(freight.H)) : ''}
                        name={`freightInfo[${index}].H`}
                        readOnly={formikValues?.carrierInformation?.name ? true : false}
                        onClick={() => {
                            if(formikValues?.carrierInformation?.name){
                                setOpenClearRateToEditModal(true)
                            }
                        }}
                        allowNegative={false}
                        onChange={(e) => {
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].H`,
                                    value: parseFloat(e.target.value.replace(/,/g, '')) || ''
                                }
                            })
                            handleChange({
                                ...e,
                                target: {
                                    name: `freightInfo[${index}].Hus`,
                                    value: poundsForEver(parseFloat(e.target.value.replace(/,/g, '')), 'H', freightInfoUOM) || ''
                                }
                            })
                        }}
                        thousandSeparator={','}
                        isAllowed={(values) => {
                            const { formattedValue, floatValue } = values;
                            return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                        }}
                        fontSize={fontSize}
                    />
                </div>
                <div style={{flex: 1, minWidth: 70, maxWidth: 70}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <SelectStyled
                        disabled={!user?.showStackOptions || disabled}
                        value={parseInt(freight.stack) ? freight.stack : 'No'}
                        name={`freightInfo[${index}].stack`}
                        onChange={handleChange} 
                        className={classes.select}
                        displayEmpty={true}
                        renderValue={value => value?.length ? value : 'No'}
                        inputProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps,
                            style: stylesMUIInput(fontSize),
                        }}
                        style={{
                            color: freight.stack !== 'No' && freight.stack ? '#5E6278' : '#A1A5B6',
                        }}
                        fontSize={fontSize}
                    >
                        {
                            ['No', '2', '3', '4', '5', '6', '7', '8', '9']?.map((stack, index) => {
                                return <MenuItem key={index} value={stack}>{stack}</MenuItem>
                            })
                        }
                    </SelectStyled>
                </div>
                <div style={{flex: 1, minWidth: 80, maxWidth: 80}} className="me-2 mb-2 mb-xl-0 mt-4">
                    <InputStyled
                        disabled={disabled}
                        type="number"
                        autoComplete="off"
                        placeholder="VOL"
                        value={freight.volume}
                        onChange={(e) => {
                            setDataFormik(`freightInfo[${index}].L`, null)
                            setDataFormik(`freightInfo[${index}].W`, null)
                            setDataFormik(`freightInfo[${index}].H`, null)
                            setDataFormik(`freightInfo[${index}].Lus`, null)
                            setDataFormik(`freightInfo[${index}].Wus`, null)
                            setDataFormik(`freightInfo[${index}].Hus`, null)
                            handleChange(e)
                        }}
                        name={`freightInfo[${index}].volume`}
                        className={`form-control ${disabled && 'disabled'}`}
                        fontSize={fontSize}
                    />
                </div>
                <div style={{flex: 1, minWidth: fontSize <= 13 ? 90 : 110, maxWidth: fontSize <= 13 ? 90 : 110}} className="me-2 mb-2 mb-xl-0 mt-md-4">
                    <SelectStyled
                        disabled={!user?.allowClassSelection || disabled}
                        value={freight.class || ''}
                        name={`freightInfo[${index}].class`}
                        readOnly={formikValues?.carrierInformation?.name ? true : false}
                        onClick={() => {
                            if(formikValues?.carrierInformation?.name){
                                setOpenClearRateToEditModal(true)
                            }
                        }}
                        onChange={handleChange}
                        className={classes.select}
                        displayEmpty={true}
                        renderValue={value => value?.length ? value : 'CLS'}
                        inputProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps,
                            style: stylesMUIInput(fontSize),
                        }}
                        style={{
                            color: freight.class ? '#5E6278' : '#A1A5B6',
                        }}
                        fontSize={fontSize}
                    >
                        {
                            densityOptions?.map((density, index) => {
                                return <MenuItem key={index} value={density}>{density}</MenuItem>
                            })
                        }
                    </SelectStyled>
                </div>
                <div style={{flex: 2, minWidth: 100, maxWidth: 140}} className='mt-md-4'>
                    <InputMask
                        onChange={handleChange}
                        value={freight.nmfc}
                        mask="999999-99"
                        maskChar=" "
                        disabled={disabled}
                    >
                        {() => <TextField
                            name={`freightInfo[${index}].nmfc`}
                            autoComplete="off"
                            className={errors?.nmfc && touched?.nmfc && hasChanged('nmfc') ? classes.textFieldError : classes.textFieldMUIWithoutBorder}
                            placeholder="NMFC"
                            onKeyDown={(e) => {
                                if(e.key === 'Enter' || e.key === 'Tab'){
                                    e.preventDefault()
                                    setLoadingNMFC(true)
                                    formik.setFieldTouched(`freightInfo[${index}].nmfc`, true)

                                    if(user?.allowClassIT){
                                        axios.get(GET_NMFCS, {
                                            params: {
                                                keyword: e.target.value
                                            }
                                        }).then(response => {
                                            let NMFC = response.data.data.results?.NMFCCodes[0]
                                            if(NMFC?.itemNumber === e.target.value){
                                                formik.setFieldValue(`freightInfo[${index}].nmfc`, NMFC?.itemNumber)
                                                formik.setFieldValue(`freightInfo[${index}].class`, NMFC?.itemClass)
                                                formik.setFieldValue(`freightInfo[${index}].commodity`, NMFC?.itemDescription)
                                            }
                                        }).catch(error => {
                                            if(error?.response?.data?.error?.message === 'Empty classIT credentials.'){
                                                setOpenRequireClassITModal(true)
                                                setLoadingNMFC(false)
                                            }else{  
                                                checkErrorAPICall(error, dispatch, 'NMFC Numbers')
                                            }
                                        }).finally(() => {
                                            setLoadingNMFC(false)
                                        })
                                    }
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {
                                            user?.allowClassIT ? 
                                            <InputAdornment position="end" style={{margin: 0}}>
                                                <IconButton
                                                    disabled={disabled || loadingNMFC}
                                                    onClick={(e) => {
                                                        formik.setFieldValue(`freightInfo[${index}].nmfcSearch`, formik.values?.freightInfo[index].nmfc)
                                                        setOpenNMFCModal(true)
                                                    }}>
                                                    {loadingNMFC ? <div className='d-flex align-items-center'><CircularProgress color="inherit" size={15} /></div> : <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: !disabled ? "#5E6278" : 'rgba(0, 0, 0, 0.23)'}}/>}
                                                </IconButton>
                                            </InputAdornment>
                                            : null
                                        }
                                    </>
                                ),
                                style: stylesMUIInput(fontSize)
                            }}
                        />}
                    </InputMask>
                </div>
                {
                    hazEnabled ?
                    <>
                        <div style={{flex: 0.5, minWidth: 40, maxWidth: 40}} className="me-2 mb-2 mb-xl-0 d-flex align-items-center justify-content-center">
                            <div className="form-check form-check-custom form-check-solid form-check-sm d-flex flex-column">
                                <label style={{cursor: disabled ? 'default' : 'pointer'}} htmlFor={index.toString()}>
                                    Haz
                                </label>
                                <input
                                    disabled={disabled}
                                    className="form-check-input white-bg"
                                    type="checkbox"
                                    onChange={(e) => {
                                        handleChange(e)
                                        e.target.checked === false && setDataFormik(`freightInfo[${index}].UN`, "")
                                    }}
                                    checked={freight.hazmat}
                                    name={`freightInfo[${index}].hazmat`}
                                    id={index.toString()}
                                />
                            </div>
                        </div>                        
                        {/* <div style={{flex: 1.3, minWidth: 100, maxWidth: 100}} className="me-2 mb-2 mb-xl-0 mt-md-4">                            
                                <NumberFormatStyled
                                    name={`freightInfo[${index}].UN`}
                                    onChange={handleChange}
                                    customInput={TextField}
                                    isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 9999)
                                    }}
                                    allowNegative={false}
                                    disabled={!freight.hazmat || disabled}
                                    autoComplete="off"
                                    className={classes.textFieldMUIWithoutBorder}
                                    placeholder="UN#"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{margin: 0}}>
                                                <IconButton disabled={!freight.hazmat || disabled} onClick={() => setOpenUNModal(true)}>
                                                    <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: freight.hazmat && !disabled ? "#5E6278" : 'rgba(0, 0, 0, 0.23)'}}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: stylesMUIInput(fontSize)
                                    }}
                                    value={freight.UN}
                                    fontSize={fontSize}
                                />
                            </div> */}
                        {
                            true ? 
                            <div style={{flex: 1.3, minWidth: 100, maxWidth: 100}} className="me-2 mb-2 mb-xl-0 mt-md-4">                                                       
                                <NumberFormatStyled
                                    name={`freightInfo[${index}].UN`}
                                    onChange={handleChange}
                                    customInput={TextField}
                                    isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 9999)
                                    }}
                                    allowNegative={false}
                                    disabled={!freight.hazmat || disabled}
                                    autoComplete="off"
                                    className={classes.textFieldMUIWithoutBorder}
                                    placeholder="UN#"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{margin: 0}}>
                                                <IconButton disabled={!freight.hazmat || disabled} onClick={() => setOpenUNModal(true)}>
                                                    <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: freight.hazmat && !disabled ? "#5E6278" : 'rgba(0, 0, 0, 0.23)'}}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: stylesMUIInput(fontSize)
                                    }}
                                    value={freight.UN}
                                    fontSize={fontSize}
                                />
                            </div> : null
                        }
                    </>
                    : null
                }
                {
                    formik.values.stops?.stops?.length && mode?.shipmentCategory === 'Volume' ?
                        <div style={{flex: 1.3, minWidth: 190, maxWidth: 190}} className={`me-2 mb-2 mb-xl-0 ${!hazEnabled ? 'ms-2' : ''}`}>
                            <SelectStyled
                                defaultValue=""
                                disabled={disabled}
                                value={formik.values.freightInfo[index].stopNumber || ''}
                                onChange={handleChange}
                                name={`freightInfo[${index}].stopNumber`}
                                className={classes.select}
                                displayEmpty={true}
                                renderValue={value => {
                                    if(value){
                                        const stop = formik.values.stops?.stops?.find(s => s.stopNumber === value)
                                        if(window.innerWidth > 990){
                                            return (
                                                <div className="row">
                                                    <div className="col-12" style={{fontWeight: 600}}>Stop #{stop.stopNumber}</div>
                                                    <div className="col-12">{`${stop.city}, ${stop.state} ${stop.zipcode}`}</div>
                                                </div>
                                            )
                                        }else{
                                            return value
                                        }
                                    }else{
                                        if(window.innerWidth > 480){
                                            return 'Stop#'
                                        }else{
                                            return 'STP'
                                        }
                                    }
                                }}
                                inputProps={{
                                    IconComponent: KeyboardArrowDownIcon,
                                    MenuProps: menuProps,
                                    style: stylesMUIInput(fontSize),
                                }}
                                style={{
                                    color: freight.stopNumber ? '#5E6278' : '#A1A5B6',
                                }}
                                fontSize={fontSize}
                            >
                                {
                                    formik.values.stops?.stops.map((stop) => {
                                        return <MenuItem value={stop.stopNumber}>
                                            <div className="row">
                                                <div className="col-12" style={{fontWeight: 600}}>Stop #{stop.stopNumber}</div>
                                                <div className="col-12">{`${stop.city}, ${stop.state} ${stop.zipcode}`}</div>
                                            </div>
                                        </MenuItem>
                                    })
                                }
                            </SelectStyled>
                        </div>
                    : null
                }
                <div className="d-flex align-items-center" style={{flex: 1, minWidth: 50}}>
                    <div
                        onClick={() => {
                            if(!disabled){
                                addNewFreight()
                            }
                        }}
                        style={{cursor: disabled ? 'default' : 'pointer'}}
                        className={`me-2 mb-2 mb-xl-0 mt-md-4 ${!hazEnabled ? 'ms-2' : ''}`}
                    >
                        <KTSVG path='/media/icons/bootstrap/plus-circle-fill.svg' className='svg-icon svg-icon-1 text-primary' style={{verticalAlign: 'bottom', marginRight: '10px', minHeight: '30px'}}/>
                    </div>
                    {
                        index > 0 &&
                        <div
                            onClick={() => {
                                if(!disabled){
                                    removeFreight(freight.id)
                                }
                            }}
                            style={{cursor: disabled ? 'default' : 'pointer'}}
                            className="me-2 mb-2 mb-xl-0 mt-md-4"
                        >
                        <KTSVG path='/media/icons/bootstrap/dash-circle-fill.svg' className='svg-icon svg-icon-1 text-danger' style={{verticalAlign: 'bottom', marginRight: '10px', minHeight: '30px'}}/>
                        </div>
                    }
                </div>
            </div>
            </DivContainerStyled>
            <ShippingItemsModal
                open={openShippingItemsModal}
                setOpen={setOpenShippingItemsModal}
                setDataFormik={setDataFormik}
                index={index}
            />
            <UNLookupModal 
                open={openUNModal}
                setOpen={setOpenUNModal}
                setUNNumber={value => setDataFormik(`freightInfo[${index}].UN`, value)}
                setOpenHazContactModal={setOpenHazContactModal}
                setSelectedUn={setSelectedUn}
            />
            <NMFCLookupModal
                open={openNMFCModal}
                setOpen={setOpenNMFCModal}
                setFormikValues={setDataFormik}
                searchFormik={formik.values.freightInfo[index].nmfcSearch}
                index={index}
            />
            <RequireClassITModal
                open={openRequireClassITModal}
                setOpen={setOpenRequireClassITModal}
            />
        </>
    )
})

export default FreightInformation