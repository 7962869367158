import { TextField, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { CountryOptions } from '../../../data/CountryOptions'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { useStyles } from '../../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import ZipcodesWithCountry from '../../LTLandVolumeTLPages/ZipcodesWithCountry'
import PhoneInput from '../../ShippingLocationsPage/PhoneInput'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { FormsEditProps } from '../../../pages/vendors/models';
import EquipmentModal from './EquipmentServiceArea/EquipmentModal';
import ServiceAreaModal from './EquipmentServiceArea/ServiceAreaModal';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { InputStyled } from '../../Inputs.styled';
import { stylesMUIInput } from '../../stylesMUIInput';

const GET_VENDORSTYPES = '/api/v1/system/vendortype'
const GET_SHIPMENTSMODES = '/api/v1/shipmentmodes'

const MainInformation = ({formik, rowSelected, vendorsTypes, setVendorsTypes}: FormsEditProps) => {
    const dispatch = useDispatch()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [zipcode, setZipcode] = useState('')
    const [city, setCity] = useState({
        City: formik.values.mainInformation.city,
        Country: formik.values.mainInformation.country,
        Name: "",
        State: formik.values.mainInformation.state,
        Zipcode: formik.values.mainInformation.zipcode,
        isSL: false,
        isZipcode: true,
        id: null
    })
    const [openEquipmentModal, setOpenEquipmentModal] = useState(false)
    const [openServiceAreaModal, setOpenServiceAreaModal] = useState(false)
    const [shipmentsModes, setShipmentsModes] = useState([])
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({fontSize: fontSize})

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    useEffect(() => {
        if(vendorsTypes.length === 0){
            axios.get(GET_VENDORSTYPES)
            .then((results) => {
                setVendorsTypes(results.data.data.results)
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Get Vendors Types')
            })
        }
        
        if(shipmentsModes.length === 0){
            axios.get(GET_SHIPMENTSMODES)
            .then((results) => {
                setShipmentsModes(results.data.data.results)
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Get Shipment Modes')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(vendorsTypes.length > 0){
            formik.setFieldValue('mainInformation.vendorType', (rowSelected.type?.length && rowSelected?.type[0]?.code) || '')
        }

        if(shipmentsModes.length > 0){
            formik.setFieldValue('mainInformation.shipmentMode', {id: rowSelected.shipmentMode.id} || {})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorsTypes, shipmentsModes])

    return (
        <>
            <div className="row align-items-center">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Name</label>
                </div>
                <div className="col-12 col-sm-10 col-md-5 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('mainInformation.name')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Terms</label>
                </div>
                <div className="col-12 col-sm-4 col-md-2 mb-5 mb-sm-0">
                    <TextField
                        select
                        label=" "
                        {...formik.getFieldProps('mainInformation.terms')}
                        value={formik.values.mainInformation.terms || ''}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        <MenuItem value="Prepaid">Prepaid</MenuItem>
                        <MenuItem value="Collect">Collect</MenuItem>
                    </TextField>
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Bill to</label>
                </div>
                <div className="col-12 col-sm-4 col-md-2">
                    <TextField
                        select
                        label=" "
                        {...formik.getFieldProps('mainInformation.billTo')}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        <MenuItem value="Shipper">Shipper</MenuItem>
                        <MenuItem value="Consignee">Consignee</MenuItem>
                        <MenuItem value="Third Party">Third Party</MenuItem>
                    </TextField>
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Address</label>
                </div>
                <div className="col-7 col-sm-6 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='new-password'
                        {...formik.getFieldProps('mainInformation.address')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-5 col-sm-4 col-md-2 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='new-password'
                        {...formik.getFieldProps('mainInformation.address1')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>C&#8204;ity</label>
                </div>
                <div className="col-12 col-sm-10 col-md-5">
                    <ZipcodesWithCountry
                        zipcodeLocal={zipcode}
                        setZipcodeLocal={setZipcode}
                        setDataFormik={formik?.setFieldValue}
                        data={city}
                        setData={setCity}
                        countryOptions={CountryOptions}
                        userData={user}
                        error={''}
                        touched={{}}
                        setFieldTouched={() => {}}
                        editVendor
                        valueName='mainInformation'
                    />
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Phone</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <PhoneInput
                        value={formik.values.mainInformation.phone}
                        setDataFormik={formik.setFieldValue}
                        name='mainInformation.phone'
                        countryCode={formik.values.mainInformation.country}
                        deleteSelectCountry={false}
                    />
                </div>
                <div className="col-12 col-sm-2">
                    <label className='form-label'>Contact Person</label>
                </div>
                <div className="col-12 col-sm-4 col-md-2 ps-3 ps-md-0 mb-5 mb-sm-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('mainInformation.contactPerson')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Email</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3">
                    <TextField
                        label=" "
                        type="text"
                        className={formik.errors.mainInformation?.email && formik.touched.mainInformation?.email ? classes.textFieldError : classes.textFieldMUI}
                        autoComplete='off'
                        {...formik.getFieldProps('mainInformation.email')}
                        InputLabelProps={{shrink: false}}
                        onBlur={() => formik.setFieldTouched('mainInformation.email', true)}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                    />
                    {formik.errors.mainInformation?.email && formik.touched.mainInformation?.email
                        ? <small className="text-danger ms-1">Invalid Email</small>
                        : null
                    }
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Vendor Type</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3">
                    <TextField
                        select
                        label=" "
                        {...formik.getFieldProps('mainInformation.vendorType')}
                        value={formik.values.mainInformation.vendorType || ''}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        {
                            vendorsTypes.map(vendorType => {
                                return (
                                    <MenuItem key={vendorType.name} value={vendorType.name}>{vendorType.name}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </div>
                <div className="col-12 col-sm-2 mt-5 mt-sm-0">
                    <label className='form-label'>Shipment Mode</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3 ps-3 ps-md-0">
                    <TextField
                        select
                        label=" "
                        onChange={(e) => {
                            formik.setFieldValue('mainInformation.shipmentMode', {id: e.target.value})
                        }}
                        value={formik.values.mainInformation.shipmentMode.id === 0 ? 0 : formik.values.mainInformation.shipmentMode.id || ''}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        {
                            shipmentsModes.map(shipmentMode => {
                                return (
                                    <MenuItem key={shipmentMode.id} value={shipmentMode.id}>{shipmentMode.name}</MenuItem>
                                )
                            })
                        }
                    </TextField>
                </div>
                <div className="col-12 col-md-3 mt-5 mt-md-0 d-sm-flex d-md-block d-xl-flex align-items-center">
                    <button
                        className='btn btn-primary me-4 w-100'
                        onClick={() => setOpenEquipmentModal(true)}
                    >
                        Equipment
                    </button>
                    <button
                        className='btn btn-primary mt-2 mt-sm-0 mt-md-2 mt-xl-0 w-100'
                        onClick={() => setOpenServiceAreaModal(true)}
                    >
                        Service Area
                    </button>
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Remarks</label>
                </div>
                <div className="col-12 col-sm-10 col-md-11">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('mainInformation.remarks')}
                        fontSize={fontSize}
                    />
                </div>
            </div>

            <EquipmentModal
                open={openEquipmentModal}
                setOpen={setOpenEquipmentModal}
                formik={formik}
            />

            <ServiceAreaModal
                open={openServiceAreaModal}
                setOpen={setOpenServiceAreaModal}
                formik={formik}
            />
        </>
    )
}

export default MainInformation