import { modalStyles } from '../ModalStyles';
import { Modal, Fade, Backdrop } from '@mui/material';
import { FooterStyled, PaperModal, Title } from '../Modals.styled';
import { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

const WarningCallsModal = ({open, setOpen, vendors}) => {
    const classes = modalStyles()
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <PaperModal fontSize={fontSize} widthWhenFontSmall={600} widthWhenFontBig={800} className={classes.paperWarningCallsModal}>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                        </div>
                            <>
                                <div className="row justify-content-center">
                                    <div className="col-12 d-flex justify-content-center">
                                        <KTSVG path='/media/icons/bootstrap/x-circle.svg' className='svg-icon svg-icon-3x text-danger'/>
                                    </div>
                                    <div className="col-12 mt-5 d-flex justify-content-center">
                                        <Title fontSize={fontSize}>
                                            You can't process this because there must be no more than 500 rows
                                        </Title>
                                    </div>
                                </div>
                                <FooterStyled fontSize={fontSize} className="row mt-5 mb-3">
                                    <div className="col-12 d-flex justify-content-end">
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </FooterStyled>
                            </>
                    </PaperModal>
                </Fade>
            </Modal>
        </>
    )
}

export default WarningCallsModal