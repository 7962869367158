import { Button } from '@mui/material';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
    height: 36px !important;
    width: 36px !important;
    min-width: 36px !important;
    font-family: Poppins !important;
    color: #6C6D6E !important;
    border-radius: 0.475rem !important;
    & .MuiTouchRipple-root {
        color: #aaaaaa !important
    };
    &:hover {
        background-color: #EBEEF0 !important;
    }
`

export const DivStyled = styled.div`
    div, input, p, button {
        font-size: ${props => `${props.fontSize === 13 ? '' : props.fontSize}px !important`};
    };
   /*  background-color: #d3e1f1;
    border-bottom: 1px solid #99bce8;
    border-top: 1px solid #99bce8;
    background-image: -webkit-linear-gradient(top, #dfe9f5,#d3e1f1); */
`