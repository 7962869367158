import React, { useState } from 'react'
import { TabStyled, TabsStyled } from '../NewBookingPage/NewBooking.styled'
import { RootState } from '../../../setup'
import { useSelector } from 'react-redux'

const Tabs = () => {
    const [tab, setTab] = useState(0)
   /*  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number */
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <>
            <TabsStyled value={tab} onChange={handleChange} aria-label="basic tabs example" style={{overflow: 'scroll'}}>
                <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Special Instructions" {...a11yProps(0)} />
                <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Shipment Notes" {...a11yProps(1)} />
                <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Other Info" {...a11yProps(2)} />
            </TabsStyled>
            <div className={`${tab === 0 ? 'd-flex' : 'd-none'} mt-4`}>
                {tracking.specialInstructions}
            </div>
            <div className={`${tab === 1 ? 'd-flex' : 'd-none'} mt-4`}>
                {tracking.shipmentNotes}
            </div>
            <div className={`${tab === 2 ? 'd-flex' : 'd-none'} mt-4`}>
                <div className="row d-flex align-items-center">
                    {
                        (!tracking?.shipper?.country?.includes('US') || !tracking?.consignee?.country?.includes('US')) &&
                        <>
                            <div className="col-3"> 
                                <label className='col-form-label'>Broker Name</label>
                            </div>
                            <div className="col-9">
                                {tracking?.brokerInformation?.name}
                            </div>
                            <div className="col-3">
                                <label className='col-form-label'>Broker Contact</label>
                            </div>
                            <div className="col-9">
                                {tracking?.brokerInformation?.contact}
                            </div>
                            <div className="col-3">
                                <label className='col-form-label'>Broker Phone</label>
                            </div>
                            <div className="col-9">
                                {tracking?.brokerInformation?.phone}
                            </div>
                            <div className="col-3">
                                <label className='col-form-label'>Instructions</label>
                            </div>
                            <div className="col-9">
                                {tracking?.brokerInformation?.notes}
                            </div>
                        </>
                    }
                    <div className="col-3">
                        <label className='col-form-label'>BKG Ref#</label>
                    </div>
                    <div className="col-9">
                        {tracking?.additionalInformation?.BKGRef}
                    </div>
                    <div className="col-3">
                        <label className='col-form-label'>BKG Value</label>
                    </div>
                    <div className="col-9">
                        {tracking?.brokerInformation?.customValue}
                    </div>
                    <div className="col-3">
                        <label className='col-form-label'>Ref. #1</label>
                    </div>
                    <div className="col-9">
                        {tracking?.additionalInformation?.shipmentReference1}
                    </div>
                    <div className="col-3">
                        <label className='col-form-label'>Ref. #2</label>
                    </div>
                    <div className="col-9">
                        {tracking?.additionalInformation?.shipmentReference2}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tabs