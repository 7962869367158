import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    select: {
        "& .MuiSvgIcon-root": {
            transform: 'none',
            fontSize: '24px',
            color: (props: {disabled: boolean, fontSize: number}) => props.disabled ? '#3F4254' : '#3F4254 !important'
        },
        "& .MuiSelect-select": {
            paddingRight: '0px !important',
            paddingLeft: '9px !important'
        },
        "&::before": {
            border: 'none !important',
        },
        "&::after": {
            border: 'none !important',
        },
    },
    selectTable: {
        "& .MuiSvgIcon-root": {
            transform: 'none',
            fontSize: '24px',
            color: (props: {disabled: boolean, fontSize: number}) => props.disabled ? '#3F4254' : '#3F4254 !important'
        },
        "& .MuiSelect-select": {
            paddingRight: '0px !important',
            paddingLeft: '9px !important',
            background: 'none !important'
        },
        "&::before": {
            border: 'none !important',
        },
        "&::after": {
            border: 'none !important',
        },
    },
    list: {
        paddingBottom: 4,
        "& .MuiTypography-root": {
            fontSize: (props) => props.fontSize,
            fontFamily: 'Poppins !important',
            fontWeight: 500,
        },
        "& .MuiCheckbox-root.Mui-checked":{
            color: 'rgb(0, 158, 247)'
        },
        "& li": {
            fontSize: (props) => props.fontSize,
            fontFamily: 'Poppins',
            color: '#63627D',
            fontWeight: 500,
            "&:focus": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
                "&:hover":{
                    backgroundColor: "#e5eefa"
                }
            }
        },
        overflowX: 'scroll'
    },
    listHistory: {
        "& .MuiTypography-root": {
            fontFamily: 'Poppins !important',
            fontWeight: 500,
        },
        "& .MuiCheckbox-root.Mui-checked":{
            color: 'rgb(0, 158, 247)'
        },
        "& li": {
            maxWidth: 800,
            minWidth: 100,
            fontFamily: 'Poppins',
            color: '#63627D',
            fontWeight: 500,
            "&:focus": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
                "&:hover":{
                    backgroundColor: "#e5eefa"
                }
            }
        },
        overflowX: 'scroll',
    },
    listHistoryLTL: {
        "& .MuiTypography-root": {
            fontFamily: 'Poppins !important',
            fontWeight: 500,
        },
        "& .MuiCheckbox-root.Mui-checked":{
            color: 'rgb(0, 158, 247)'
        },
        "& li": {
            maxWidth: 800,
            minWidth: 600,
            fontFamily: 'Poppins',
            color: '#63627D',
            fontWeight: 500,
            "&:focus": {
                backgroundColor: "rgba(25, 118, 210, 0.08)",
                "&:hover":{
                    backgroundColor: "#e5eefa"
                }
            }
        },
        overflowX: 'scroll',
    }
})