import { dollarUSLocale } from '../../helpers/dollarUSLocale'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const TotalRender = ({rowData, header, setQuoteBreakdownModalData, setOpenQuoteBreakdownModal, loadingRates}) => {
    const cheaperCarriers = useSelector<RootState>(({pages}) => pages.cheaperCarriers, shallowEqual) as any

    let transit = cheaperCarriers?.length && cheaperCarriers?.find(carrier => carrier.id === rowData.id) && cheaperCarriers?.find(carrier => carrier.id === rowData.id).rate[header.replace(/\s/g, '')]

    return (
        <span
            className='d-flex align-items-center justify-content-end'
            style={{
                cursor: rowData.Total && rowData.Carrier !== 'No rates' && rowData.Carrier !== undefined ? 'pointer' : 'text',
            }}
            onClick={() => {
                if(rowData.Total && rowData.Carrier !== 'No rates' && rowData.Carrier !== undefined){
                    if(header === 'Bill To'){
                        setQuoteBreakdownModalData({
                            ...rowData?.Rate,
                            rateBreakdown: [
                                ...rowData?.Rate?.rateBreakdown,
                                {
                                    name: 'Markup', total: rowData.Profit
                                }
                            ],
                            billTo: rowData.BillTo,
                            billToData: rowData.Rate?.billTo
                        })
                    }else{
                        setQuoteBreakdownModalData(rowData?.Rate)
                    }
                    setOpenQuoteBreakdownModal(true)
                }
            }}
        >
            {
                (
                    header === 'Bill To' ? dollarUSLocale.format(rowData.BillTo || 0) : rowData.progress !== 100 ? transit && dollarUSLocale.format(cheaperCarriers?.find(carrier => carrier.id === rowData.id).rate[header.replace(/\s/g, '')] || '$0.00') : dollarUSLocale.format(rowData.Total || 0)
                ) || '$0.00'
            }
        </span>
    )
}

export default TotalRender