import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const OriginAndDestination = ({data}) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <div className='row d-flex align-items-center'>
            <div className="col-3 col-md-2">
                <label className='col-form-label'>Name</label>
            </div>
            <div className="col-9 col-md-10">
                {data?.name}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>Address</label>
            </div>
            <div className="col-9 col-md-4 mt-4">
                {data?.address}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>Address 2</label>
            </div>
            <div className="col-9 col-md-4 mt-4">
                {data?.address1}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>C&#8204;ity</label>
            </div>
            <div className="col-9 col-md-4 mt-4">
                {data?.country && <img src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${data?.country}.svg`} height='16px' alt="LogoCountry" className='me-1'/>}{data?.city}{data?.state || data?.zipcode ? ',' : ''} {data?.state} {data?.zipcode}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>Phone</label>
            </div>
            <div className="col-9 col-md-4 mt-4">
                {data?.phone}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>Contact</label>
            </div>
            <div className="col-9 col-md-4 mt-4">
                {data?.contact}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>Phone</label>
            </div>
            <div className="col-9 col-md-4 mt-4">
                {data?.contactPhone}
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>Email</label>
            </div>
            <div className="col-9 col-md-10 mt-4">
                {data?.emails?.includes(';') ? data?.emails?.replaceAll(';', ', ') : data?.emails?.replaceAll(',', ', ')} 
            </div>
            <div className="col-3 col-md-2 mt-4">
                <label className='col-form-label'>{window.innerWidth < 1225 || fontSize > 13 ? 'Ref#' : 'Reference#'}</label>
            </div>
            <div className='col-9 col-md-10 mt-4'>
                {data?.referenceNumber}
            </div>
            <div className='pe-3 ps-3'>
                <hr style={{color: '#a7adce'}} className='mt-4 mb-4'/>
            </div>
        </div>
  )
}

export default OriginAndDestination