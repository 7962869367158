import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled'
import { Backdrop, Fade } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers'
import { modalStyles } from '../ModalStyles'
import { RootState } from '../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../modules/auth/models/UserModel'
import { BigSubtitle } from '../Modals/Modals.styled'

const SchedulePickupModal = ({open, setOpen, dispatchAction}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DivPaperStyled className={classes.paper}>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                        </div>
                            <>
                                <div className="row justify-content-center">
                                    <div className="col-12 d-flex justify-content-center">
                                        <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-5">
                                        <Title>Scheduling the pickup may incur additional fees depending on your contract.</Title>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        <BigSubtitle>Do you wish to continue?</BigSubtitle>
                                    </div>
                                </div>
                                <div className="row mt-1 mb-3">
                                    <div className='col-12 d-flex justify-content-center'>
                                        <button
                                            className='btn btn-primary me-2'
                                            onClick={() => {
                                                setOpen(false)
                                                dispatchAction({makeEDI: user?.allowEDI, smallPackageHandling: 'SCHEDULE PICKUP'})
                                            }}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className='btn btn-danger me-2'
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </>
                    </DivPaperStyled>
                </Fade>
            </ModalStyled>
        </>
    )
}

export default SchedulePickupModal