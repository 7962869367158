import React, { useEffect, useState } from 'react'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import Toolbar from '../../components/ShippingLocationsPage/Toolbar'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import Table from '../../components/ShippingLocationsPage/Table'
import HistorySelect from './HistorySelect';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { resultsModel } from './models';

let cancelToken
const GET_SHIPPINGITEMS = '/applet/v1/database/shippingitem'

interface props {
  pageNumberProp?: number
}

export const ShippingItems = () => {
  const dispatch = useDispatch()
  let {setHistoryElement} = usePageData()
  const [results, setResults] = useState([] as resultsModel | any)
  const [totalResults, setTotalResults] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [search, setSearch] = useState('') 
  const [loading, setLoading] = useState(false)

  const hazmatRender = (rowData) => {
    return (
      <div style={{textAlign: 'center'}}>
        {
          rowData.hazmat ?
          <>
            <div className='mb-2'><CircleIcon style={{color: '#47BE7D'}}/></div>
            <div>{`UN# ${rowData.UN}`}</div>
          </>
          : <CircleIcon style={{color: '#F1416C'}}/>
        }
      </div>
    )
  }

  const referenceRender = (rowData) => {
    return (
      <>
        <div>{rowData.reference1}</div>
        <div>{rowData.reference2}</div>
      </>
    )
  }
 
  const [columns, setColumns] = useState([
    { title: 'Code', field: 'code', hidden: false},
    { title: 'Name', field: 'name', hidden: false, cellStyle: {width: 220, minWidth: 220}},
    { title: 'NMFC', field: 'NMFC', hidden: false},
    { title: 'Weight', field: 'weight', hidden: false},
    { title: 'Length', field: 'length', hidden: false},
    { title: 'Width', field: 'width', hidden: false},
    { title: 'Height', field: 'height', hidden: false},
    { title: 'Class', field: 'class', hidden: false},
    { title: 'Hazmat', field: 'hazmat', render: hazmatRender, sorting: false, hidden: false, headerStyle: {textAlign: 'center'}},
    { title: 'Description', field: 'description', hidden: false},
    { title: 'Reference#', field: 'reference1', render: referenceRender, hidden: false},
  ])

  const getShippingItems = (props: props) => {
    setLoading(true)

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }

    cancelToken = axios.CancelToken.source()

    axios.get(GET_SHIPPINGITEMS, {
      cancelToken: cancelToken.token,
      params: {
        page: props.pageNumberProp + 1 || pageNumber + 1,
        limit: rowsPerPage,
        search: search.trim()
      }
    }).then((results) => {
      setResults(results.data.data.results)
      setTotalResults(results.data.data.pagingDetails.totalResults)
      setLoading(false)
    }).catch(error => {
      checkErrorAPICall(error, dispatch, 'Shipping Items')
    })
  }

  useEffect(() => {
    getShippingItems({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, search, rowsPerPage])

  useEffect(() => {
    setHistoryElement(
      <HistorySelect
        setSearch={setSearch}
        historyFor='ShippingItems'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle>Shipping Items</PageTitle>
      <Toolbar
        search={search}
        setSearch={setSearch}
        columns={columns}
        setColumns={setColumns}
        results={results}
        setPageNumber={setPageNumber}
        setLoading={setLoading}
        tableFor='ShippingItems'
        getData={getShippingItems}
        isSL={false}
      />
      <Table
        loading={loading}
        columns={columns}
        results={results}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalResults={totalResults}
        getData={getShippingItems}
        tableFor='ShippingItems'
      />
    </>
  )
}
