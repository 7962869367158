import { Autocomplete, CircularProgress, InputAdornment, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { NoOptionsText, PaperStyled } from '../LTLandVolumeTLPages/LTLAndVolumeTLPages.styled'
import axios from 'axios';
import { CountryOptions } from '../../data/CountryOptions';
import { SearchShippingLocationProps } from '../../pages/book/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import { formatPhone } from '../../helpers/formatPhone';
import { quoteValuesModel } from '../PagesModels';
import { ClearIconStyled, IconButtonClearStyled, IconButtonStyled } from '../LTLandVolumeTLPages/ZipcodesWithCountry.styled';
import ShippingLocationsModal from '../ShippingLocationsModal/ShippingLocationsModal';

let cancelToken
const GET_SL = '/api/v1/database/shippinglocation'

const SearchShippingLocation = ({formik, formFor, setZipcode, zipcode, setZipcodeLocal, shippingLocation, setShippingLocation, shippingLocationLocal, setShippingLocationLocal, inputForDefaults, disabled, error, setOpenClearRateToEditModal, newValuesWhenRateCleared, setNewValuesWhenRateCleared, forStops}: SearchShippingLocationProps) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [openShippingLocationsModal, setOpenShippingLocationsModal] = useState(false)
    const quoteValues = useSelector<RootState>(({pages}) => pages.quoteValues) as quoteValuesModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any

    const useStyles = makeStyles({
        textField: {
            backgroundColor: 'white',
            border: error ? '1px solid #F1416C !important' : '1px solid #E4E6EF !important',
            borderRadius: '0.475rem',
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#5E6278"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
            "& .MuiOutlinedInput-root":{
                color: "#5E6278"
            },
            "& .MuiInputBase-input": {
                paddingLeft: '4px !important',
            },
            "& .Mui-disabled": {
                backgroundColor: '#EFF2F5',
            }
        },
        height: {
            height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
        },
    })

    const classes = useStyles()

    useEffect(() => {
        if(inputForDefaults || quoteValues.length !== 0){
            if(!formik?.values.carrierInformation?.name){
                formik.setFieldValue(`${formFor}.city`, shippingLocation?.city || "")
                formik.setFieldValue(`${formFor}.country`, shippingLocation?.country || "US")
                formik.setFieldValue(`${formFor}.state`, shippingLocation?.state || "")
                formik.setFieldValue(`${formFor}.zipcode`, shippingLocation?.zipcode || "")
            }

            formik.setFieldValue(`${formFor}.name`, shippingLocation?.name || "")
            formik.setFieldValue(`${formFor}.address`, shippingLocation?.address || "")
            formik.setFieldValue(`${formFor}.address1`, shippingLocation?.address1 || "")
            formik.setFieldValue(`${formFor}.phone`, formatPhone(shippingLocation?.phone, formik?.values[formFor]?.country) || "")
            formik.setFieldValue(`${formFor}.contact`, shippingLocation?.contact || "")
            formik.setFieldValue(`${formFor}.contactPhone`, formatPhone(shippingLocation?.contactPhone, formik?.values[formFor]?.country) || "")
            formik.setFieldValue(`${formFor}.emails`, shippingLocation?.email?.includes(';') ? shippingLocation?.email?.split(';') : shippingLocation?.email?.split()[0] ? shippingLocation?.email?.split(', ') : [] || "")
            formik.setFieldValue(`${formFor}.id`, shippingLocation?.id || "")
        }

        if(!formik?.values.carrierInformation?.name){
            setZipcode({
                ...zipcode,
                City: shippingLocation?.city || "",
                Country: shippingLocation?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === shippingLocation?.country)?.Code || "US" : shippingLocation?.country,
                State: shippingLocation?.state || "",
                Zipcode: (shippingLocation?.zipcode ? shippingLocation?.zipcode : shippingLocation?.zipCode) || "",
                isZipcode: shippingLocation?.isZipcode,
            })
        }

        if(!formik?.values[formFor]?.zipcode){
            setZipcodeLocal('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingLocation])

    useEffect(() => {
        if(options.length > 0 && open === false){
            if(formik?.values?.carrierInformation?.name && options[0]?.zipcode?.toString() && formik?.values[formFor]?.zipcode?.toString() !== options[0]?.zipcode?.toString()){
                setOpenClearRateToEditModal(true)
                setNewValuesWhenRateCleared({
                    valuesFor: `shippingLocation ${formFor}`,
                    values: {
                        name: options[0]?.name || "",
                        address: options[0]?.address || "",
                        address1: options[0]?.address1 || "",
                        phone: formatPhone(options[0]?.phone, formik.values[formFor].country) || "",
                        contact: options[0]?.contact || "",
                        contactPhone: formatPhone(options[0]?.contactPhone, formik.values[formFor].country) || "",
                        emails: options[0]?.email?.includes(';') ? options[0]?.email?.split(';') : options[0]?.email?.split()[0] ? options[0]?.email?.split(', ') : [] || "",
                        id: options[0]?.id || "",
                        city: options[0]?.city || "",
                        country: options[0]?.country || "US",
                        state: options[0]?.state || "",
                        zipcode: options[0]?.zipcode || ""
                    }
                })
            }else{
                setLoading(true)
                setShippingLocation(options[0])
                setShippingLocationLocal(options[0].name)
                if(!forStops){
                    formik.setFieldValue(`${formFor}.name`, options[0]?.name || "")
                    formik.setFieldValue(`${formFor}.address`, options[0]?.address || "")
                    formik.setFieldValue(`${formFor}.address1`, options[0]?.address1 || "")
                    formik.setFieldValue(`${formFor}.phone`, formatPhone(options[0]?.phone, formik?.values[formFor].country) || "")
                    formik.setFieldValue(`${formFor}.contact`, options[0]?.contact || "")
                    formik.setFieldValue(`${formFor}.contactPhone`, formatPhone(options[0]?.contactPhone, formik?.values[formFor].country) || "")
                    formik.setFieldValue(`${formFor}.emails`, options[0]?.email?.includes(';') ? options[0]?.email?.split(';') : options[0]?.email?.split()[0] ? options[0]?.email?.split(', ') : [] || "")
                    formik.setFieldValue(`${formFor}.id`, options[0]?.id || "")
                    formik.setFieldValue(`${formFor}.city`, options[0]?.city || "")
                    formik.setFieldValue(`${formFor}.country`, options[0]?.country || "US")
                    formik.setFieldValue(`${formFor}.state`, options[0]?.state || "")
                    formik.setFieldValue(`${formFor}.zipcode`, options[0]?.zipcode || "")
                }else{
                    formik.setFieldValue(`name`, options[0]?.name || "")
                    formik.setFieldValue(`address`, options[0]?.address || "")
                    formik.setFieldValue(`address1`, options[0]?.address1 || "")
                    formik.setFieldValue(`phone`, formatPhone(options[0]?.phone, formik?.values.country) || "")
                    formik.setFieldValue(`contact`, options[0]?.contact || "")
                    formik.setFieldValue(`contactPhone`, formatPhone(options[0]?.contactPhone, formik?.values.country) || "")
                    formik.setFieldValue(`id`, options[0]?.id || "")
                    formik.setFieldValue(`city`, options[0]?.city || "")
                    formik.setFieldValue(`country`, options[0]?.country || "US")
                    formik.setFieldValue(`state`, options[0]?.state || "")
                    formik.setFieldValue(`zipcode`, options[0]?.zipcode || "")
                }
                
                setOptions([])
                setLoading(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        if(quoteValues.length !== 0){
            if(formFor === 'shipper'){
                setZipcode({
                    ...zipcode,
                    City: quoteValues?.origin?.City,
                    Country: quoteValues.origin?.isPort ? 'Port' : quoteValues?.origin?.Country || 'US',
                    // Name: quoteValues.origin?.isPort ? (`${quoteValues?.origin?.Name}`) : (`${quoteValues?.origin?.City}`),
                    State: quoteValues?.origin?.State,
                    Zipcode: quoteValues?.origin?.Zipcode,
                    isPort: quoteValues.origin?.isPort,
                })

                formik.setFieldValue(`${formFor}.city`, quoteValues?.origin?.City)
                formik.setFieldValue(`${formFor}.country`, quoteValues?.origin?.Country)
                formik.setFieldValue(`${formFor}.state`, quoteValues?.origin?.State)
                formik.setFieldValue(`${formFor}.zipcode`, quoteValues?.origin?.Zipcode)

                if(quoteValues?.origin?.Name && !quoteValues.origin?.isPort){
                    setLoading(true)
                    axios.get(`${GET_SL}/${quoteValues?.origin?.id}`)
                    .then(result => {
                        const shipping = result?.data?.data?.results
                        setShippingLocation({
                            ...shipping,
                            literal: `${shipping?.name}. ${shipping?.city}, ${shipping.state} ${shipping.zipcode}`,
                            postalCode: shipping?.zipcode,
                            stateCode: shipping?.state,
                            cityAlias: shipping?.city,
                            name: shipping?.name,
                            isSL: true
                        })
                        setShippingLocationLocal(shipping?.name)
                        setLoading(false)
                    })
                }
            }else{
                setZipcode({
                    ...zipcode,
                    City: quoteValues?.destination?.City,
                    Country: quoteValues.destination?.isPort ? 'Port' : quoteValues?.destination?.Country || 'US',
                    Name: quoteValues.destination?.isPort ? (`${quoteValues?.destination?.Name}, ${quoteValues?.destination?.State} ${quoteValues?.destination?.Zipcode}`) : (`${quoteValues?.destination?.City}${quoteValues?.destination?.City ? ', ' : ''}${quoteValues?.destination?.State ? quoteValues?.destination?.State+' ' : ''}${quoteValues?.destination?.Zipcode}`),
                    State: quoteValues?.destination?.State,
                    Zipcode: quoteValues?.destination?.Zipcode,
                    isPort: quoteValues.destination?.isPort
                })

                formik.setFieldValue(`${formFor}.city`, quoteValues?.destination?.City)
                formik.setFieldValue(`${formFor}.country`, quoteValues?.destination?.Country)
                formik.setFieldValue(`${formFor}.state`, quoteValues?.destination?.State)
                formik.setFieldValue(`${formFor}.zipcode`, quoteValues?.destination?.Zipcode)

                if(quoteValues?.destination?.Name && !quoteValues.destination?.isPort){
                    setLoading(true)
                    axios.get(`${GET_SL}/${quoteValues?.destination?.id}`)
                    .then(result => {
                        const shipping = result?.data?.data?.results
                        setShippingLocation({
                            ...shipping,
                            literal: `${shipping?.name}. ${shipping?.city}, ${shipping.state} ${shipping.zipcode}`,
                            postalCode: shipping?.zipcode,
                            stateCode: shipping?.state,
                            cityAlias: shipping?.city,
                            name: shipping?.name,
                            isSL: true
                        })
                        setShippingLocationLocal(shipping?.name)
                        setLoading(false)
                    })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteValues])

    useEffect(() => {
        if(tracking.length !== 0){
            if(formFor === 'shipper'){
                setZipcode({
                    ...zipcode,
                    City: tracking?.shipper?.city,
                    Country: tracking?.shipper?.country,
                    Name: (`${tracking?.shipper?.city}${tracking?.shipper?.city ? ', ' : ''}${tracking?.shipper?.state ? tracking?.shipper?.state+' ' : ''}${tracking?.shipper?.zipcode}`),
                    State: tracking?.shipper?.state,
                    Zipcode: tracking?.shipper?.zipcode
                })
    
                setShippingLocation({
                    ...shippingLocation,
                    country: tracking?.shipper?.country,
                    literal: `${tracking?.shipper?.name}. ${tracking?.shipper?.city}, ${tracking?.shipper?.state} ${tracking?.shipper?.zipcode}`,
                    postalCode: tracking?.shipper?.zipcode,
                    stateCode: tracking?.shipper?.state,
                    cityAlias: tracking?.shipper?.city,
                    name: tracking?.shipper?.name,
                    isSL: true
                })
                setShippingLocationLocal(tracking?.shipper?.name)
            }else{
                setZipcode({
                    ...zipcode,
                    City: tracking?.consignee?.city,
                    Country: tracking?.consignee?.country,
                    // Name: (`${tracking?.consignee?.city}${tracking?.consignee?.city ? ', ' : ''}${tracking?.consignee?.state ? tracking?.consignee?.state+' ' : ''}${tracking?.consignee?.zipcode}`),
                    State: tracking?.consignee?.state,
                    Zipcode: tracking?.consignee?.zipcode
                })
    
                setShippingLocation({
                    ...shippingLocation,
                    country: tracking?.shipper?.country,
                    literal: `${tracking?.consignee?.name}. ${tracking?.consignee?.city}, ${tracking?.consignee?.state} ${tracking?.consignee?.zipcode}`,
                    postalCode: tracking?.consignee?.zipcode,
                    stateCode: tracking?.consignee?.state,
                    cityAlias: tracking?.consignee?.city,
                    name: tracking?.consignee?.name,
                    isSL: true
                })
                setShippingLocationLocal(tracking?.consignee?.name)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracking])

    useEffect(() => {
        if(!formik?.values?.carrierInformation?.name){
            if(newValuesWhenRateCleared?.values){
                if(newValuesWhenRateCleared?.valuesFor?.includes('shipper') && formFor === 'shipper'){
                    setShippingLocation(newValuesWhenRateCleared?.values)
                    setShippingLocationLocal(newValuesWhenRateCleared?.values?.name)
                    setNewValuesWhenRateCleared({valuesFor: '', values: undefined})
                }else if(newValuesWhenRateCleared?.valuesFor?.includes('consignee') && formFor === 'consignee'){
                    setShippingLocation(newValuesWhenRateCleared?.values)
                    setShippingLocationLocal(newValuesWhenRateCleared?.values?.name)
                    setNewValuesWhenRateCleared({valuesFor: '', values: undefined})
                }
                
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValuesWhenRateCleared?.values, formik.values?.carrierInformation])
 
    return (
        <>
            <Autocomplete
                disabled={disabled}
                autoSelect
                autoComplete={false}
                autoHighlight={true}
                className="dropdownContainer w-100"
                getOptionLabel={(option) => option?.literal || ""}
                freeSolo={true}
                options={options || []}
                selectOnFocus
                handleHomeEndKeys
                forcePopupIcon={false}
                inputValue={shippingLocationLocal || ''}
                onOpen={() => {
                    setLoading(true)
                    setOpen(true)
                }}
                onClose={() => {
                    setLoading(false)
                    setOptions([])
                    setOpen(false)
                }}
                clearOnBlur={false}
                onInputChange={(e, value) =>  {
                    if(e?.type === "change"){
                        if(shippingLocation?.id){
                            setShippingLocation({
                                ...shippingLocation,
                                id: null
                            })
                            formik.setFieldValue(`${formFor}.id`, null)
                        }

                        setShippingLocationLocal(value || "")
                        if(forStops){
                            formik.setFieldValue(`name`, value || "")
                        }else{
                            formik.setFieldValue(`${formFor}.name`, value || "")
                        }

                        if(value?.length >= 3){
                            if (typeof cancelToken != typeof undefined) {
                                cancelToken.cancel('Operation canceled due to new request.')
                            }
                        
                            cancelToken = axios.CancelToken.source()
                
                            setLoading(true)
                            axios.get(GET_SL, {
                                cancelToken: cancelToken.token,
                                params: {
                                    search: shippingLocationLocal,
                                    active: true
                                }
                            }).then(result => {
                                const shippingLocationsNormalized = result?.data?.data?.results.map(result => {
                                    return {
                                        ...result,
                                        literal: `${result?.name}. ${result?.city}, ${result.state} ${result.zipcode}`,
                                        postalCode: result?.zipcode,
                                        stateCode: result?.state,
                                        cityAlias: result?.city,
                                        name: result?.name,
                                        isSL: true
                                    }
                                })
                                setOptions(shippingLocationsNormalized)
                                setLoading(false)
                            })
                        }
                    }
                }}
                onChange={(event, value) => {
                    if((!value?.zipcode ? !value?.zipcode && event.type !== 'blur' : true) && formik?.values?.carrierInformation?.name && formik.values[formFor]?.zipcode?.toString() !== value?.zipcode?.toString()){
                        setOpenClearRateToEditModal(true)
                        setNewValuesWhenRateCleared({
                            valuesFor: `shippingLocation ${formFor}`,
                            values: {
                                name: options[0]?.name || "",
                                address: options[0]?.address || "",
                                address1: options[0]?.address1 || "",
                                phone: formatPhone(options[0]?.phone, formik.values[formFor].country) || "",
                                contact: options[0]?.contact || "",
                                contactPhone: formatPhone(options[0]?.contactPhone, formik.values[formFor].country) || "",
                                emails: options[0]?.email?.includes(';') ? options[0]?.email?.split(';') : options[0]?.email?.split()[0] ? options[0]?.email?.split(', ') : [] || "",
                                id: options[0]?.id || "",
                                city: options[0]?.city || "",
                                country: options[0]?.country || "US",
                                state: options[0]?.state || "",
                                zipcode: options[0]?.zipcode || ""
                            }
                        })
                    }else{
                        setShippingLocationLocal(value?.name || value || "")
                        if(typeof value === 'string' && value !== ''){
        
                        }else{
                            if(!forStops){
                                formik.setFieldValue(`${formFor}.name`, value?.name || "")
                                formik.setFieldValue(`${formFor}.address`, value?.address || "")
                                formik.setFieldValue(`${formFor}.address1`, value?.address1 || "")
                                formik.setFieldValue(`${formFor}.phone`, formatPhone(value?.phone, formik?.values[formFor].country) || "")
                                formik.setFieldValue(`${formFor}.contact`, value?.contact || "")
                                formik.setFieldValue(`${formFor}.contactPhone`, formatPhone(value?.contactPhone, formik?.values[formFor].country) || "")
                                formik.setFieldValue(`${formFor}.emails`, value?.email?.includes(';') ? value?.email?.split(';') : value?.email?.split()[0] ? value?.email?.split(', ') : [] || "")
                                formik.setFieldValue(`${formFor}.id`, value?.id || "")
                                formik.setFieldValue(`${formFor}.city`, value?.city || "")
                                formik.setFieldValue(`${formFor}.country`, value?.country || "US")
                                formik.setFieldValue(`${formFor}.state`, value?.state || "")
                                formik.setFieldValue(`${formFor}.zipcode`, value?.zipcode || "")
                            }else{
                                formik.setFieldValue(`name`, value?.name || "")
                                formik.setFieldValue(`address`, value?.address || "")
                                formik.setFieldValue(`address1`, value?.address1 || "")
                                formik.setFieldValue(`phone`, formatPhone(value?.phone, formik?.values.country) || "")
                                formik.setFieldValue(`contact`, value?.contact || "")
                                formik.setFieldValue(`contactPhone`, formatPhone(value?.contactPhone, formik?.values.country) || "")
                                formik.setFieldValue(`id`, value?.id || "")
                                formik.setFieldValue(`city`, value?.city || "")
                                formik.setFieldValue(`country`, value?.country || "US")
                                formik.setFieldValue(`state`, value?.state || "")
                                formik.setFieldValue(`zipcode`, value?.zipcode || "")
                            }

                            setShippingLocation(value || {})
                        }
                    }
                }}
                onKeyPress={(event) => {
                    if(event.key === 'Enter'){
                        event.preventDefault();
                        return false;
                    }
                }}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            fullWidth
                            autoComplete='new-password'
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end" className='d-flex align-items-center'>
                                        {
                                            shippingLocation?.id ? 
                                            <IconButtonClearStyled
                                                onClick={() => {
                                                    if(formik?.values?.carrierInformation?.name && formik.values[formFor]?.zipcode?.toString()){
                                                        setOpenClearRateToEditModal(true)
                                                        setNewValuesWhenRateCleared({
                                                            valuesFor: `shippingLocation ${formFor}`,
                                                            values: {
                                                                name: options[0]?.name || "",
                                                                address: options[0]?.address || "",
                                                                address1: options[0]?.address1 || "",
                                                                phone: formatPhone(options[0]?.phone, formik.values[formFor].country) || "",
                                                                contact: options[0]?.contact || "",
                                                                contactPhone: formatPhone(options[0]?.contactPhone, formik.values[formFor].country) || "",
                                                                emails: options[0]?.email?.includes(';') ? options[0]?.email?.split(';') : options[0]?.email?.split()[0] ? options[0]?.email?.split(', ') : [] || "",
                                                                id: options[0]?.id || "",
                                                                city: options[0]?.city || "",
                                                                country: options[0]?.country || "US",
                                                                state: options[0]?.state || "",
                                                                zipcode: options[0]?.zipcode || ""
                                                            }
                                                        })
                                                    }else{
                                                        setShippingLocation({})
                                                        setShippingLocationLocal('')
                                                        if(!forStops){
                                                            formik.setFieldValue(`${formFor}.name`, "")
                                                            formik.setFieldValue(`${formFor}.address`, "")
                                                            formik.setFieldValue(`${formFor}.address1`, "")
                                                            formik.setFieldValue(`${formFor}.phone`, "")
                                                            formik.setFieldValue(`${formFor}.contact`, "")
                                                            formik.setFieldValue(`${formFor}.contactPhone`, "")
                                                            formik.setFieldValue(`${formFor}.emails`, "")
                                                            formik.setFieldValue(`${formFor}.id`, "")
                                                            formik.setFieldValue(`${formFor}.city`, "")
                                                            formik.setFieldValue(`${formFor}.country`, "US")
                                                            formik.setFieldValue(`${formFor}.state`, "")
                                                            formik.setFieldValue(`${formFor}.zipcode`, "")
                                                        }else{
                                                            formik.setFieldValue(`name`, "")
                                                            formik.setFieldValue(`address`, "")
                                                            formik.setFieldValue(`address1`, "")
                                                            formik.setFieldValue(`phone`, "")
                                                            formik.setFieldValue(`contact`, "")
                                                            formik.setFieldValue(`contactPhone`, "")
                                                            formik.setFieldValue(`id`, "")
                                                            formik.setFieldValue(`city`, "")
                                                            formik.setFieldValue(`country`, "US")
                                                            formik.setFieldValue(`state`, "")
                                                            formik.setFieldValue(`zipcode`, "")
                                                        }
                                                    }
                                                }}
                                                style={{padding: '2px !important'}}
                                            >
                                                <ClearIconStyled style={{color: 'rgb(161, 165, 183) !important'}}/>
                                            </IconButtonClearStyled>
                                            : null 
                                        }
                                        <IconButtonStyled disabled={loading} onClick={() => setOpenShippingLocationsModal(true)}>
                                            {loading ? <div className='d-flex align-items-center'><CircularProgress color="inherit" size={15}/></div> : <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon svg-icon-6' style={{color: '#a1a5b7'}}/>}
                                        </IconButtonStyled>
                                    </InputAdornment>
                                ),
                                className: classes.height,
                                style: {fontSize: 14, fontFamily: 'Poppins', fontWeight: 500, paddingRight: 9, color:"#5E6278 !important"},
                                inputProps: {
                                    ...params.inputProps,
                                    maxLength: 120
                                }
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                            className={classes.textField}
                        />
                    );
                }}
                renderOption={(props, option) => (
                    <li key={option} {...props}>
                        <KTSVG path='/media/icons/bootstrap/building.svg' className='svg-icon svg-icon-5 me-2 text-danger'/>
                        {option.literal}
                    </li>
                )}
                PaperComponent={({ children }) => (
                    <PaperStyled fontSize={fontSize}>
                        {children}
                    </PaperStyled>
                )}
                noOptionsText={<NoOptionsText>No options</NoOptionsText>}
            />
            <ShippingLocationsModal
                open={openShippingLocationsModal}
                setOpen={setOpenShippingLocationsModal}
                setData={setShippingLocation}
                setFormikValues={formik.setFieldValue}
                formikValues={formik}
                setShippingLocationLocal={setShippingLocationLocal}
                formFor={formFor}
                forStops={forStops || false}
                setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
            />
        </>
    )
}

export default SearchShippingLocation