import { makeStyles } from '@mui/styles';

export const modalStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root': {
            zIndex: -1
        },
        margin: 20
    },
    paper: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 500,
        width: '100%',
        minHeight: '200px',
        padding: 15,
        transition: 'max-width 0.3s, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        color: '#3f4254'
    },
    paperBigger: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 600,
        width: '100%',
        minHeight: '200px',
        padding: 15,
        color: '#3f4254'
    },
    paperSmall: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 500,
        width: '100%',
        minHeight: '100px',
        padding: 15,
        transition: 'max-width 0.3s, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        color: '#3f4254'
    },
    paperWarningCallsModal: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 660,
        width: '100%',
        minHeight: '200px',
        padding: 15,
        color: '#3f4254'
    },
    paperEditModalCarriers: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 800,
        width: '100%',
        minHeight: '200px',
        padding: 15,
        color: '#3f4254'
    },
    paperEditModalMarkup: {
        borderRadius: '4px',
        backgroundColor: 'white',
        maxWidth: 900,
        width: '100%',
        minHeight: '200px',
        padding: 15,
        transition: 'max-width 0.3s, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
        color: '#3f4254'
    },
}));