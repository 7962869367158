/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import { RootState } from '../../setup/redux/RootReducer';
import { LandingPage } from '../data/LandingPage'
import axios from 'axios'
import { actionTypes } from '../modules/auth/redux/PagesRedux'
import { initialValues } from '../helpers/createNewBookingFormik'
import { TypeOptions } from '../data/TypeOptions'
import { CountryOptions } from '../data/CountryOptions';
import { UserModel } from '../../app/modules/auth/models/UserModel'

const {PUBLIC_URL} = process.env
const DEFAULTS = '/applet/v1/defaults'

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<any>(({auth}) => auth.accessToken, shallowEqual)
  const dispatch = useDispatch()
  const defaults = useSelector<RootState>(({pages}) => pages.defaults) as any
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const accessTokenLS = JSON.parse(localStorage.getItem('primus-key'))?.accessToken  
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const getCountryOptionsAllowed = () => {
    let defaultCountries = user?.defaultCountries
    let countriesArrayLocal = []
    defaultCountries?.forEach(country => {
        let allowedCountry = CountryOptions?.find(c => c.CodePrimus === country)
        countriesArrayLocal?.push(allowedCountry)
    })
    return countriesArrayLocal
  }  

  useEffect(() => {
    let otherCountry = getCountryOptionsAllowed().find(c => c.CodePrimus === 'USA') ? 'USA' : getCountryOptionsAllowed()[0]?.CodePrimus || 'USA'
    
    if(defaults.length === 0 && (accessTokenLS ? JSON.parse(accessTokenLS) : accessToken)){
      axios.get(DEFAULTS).then((results) => {
        let defaults = results.data.data.results

        if(defaults.bookings) {
          const foundTotal = defaults.bookings.find((element) => element.name == 'totalDue');
          if(!foundTotal) {
            defaults.bookings.push({name: 'totalDue', order: 26, hidden: false})
            axios.post(DEFAULTS, {
              ...defaults,
              "bookings": defaults.bookings.map((column, index) => (
                  {
                    "name": column.name,
                    "order": index,
                    "hidden": column.hidden,
                  }
              ))
            })
          }          
        }
        
        if(!user?.allowTrack && defaults.landingPage == 'TRACKING') {
          defaults.landingPage = 'Dashboard'
          axios.post(DEFAULTS, {
            ...defaults
          })

          dispatch({type: actionTypes.SetDefaults, payload: {...defaults, landingPage: 'Dashboard'}})
        }
        
        dispatch({type: actionTypes.SetDefaults, payload: {...defaults, landing: LandingPage.find(page => page.value === defaults.landingPage || 'Dashboard')?.path || '/home'}})

        let payload = {
          ...initialValues,
          freightInfo: [{
              ...initialValues.freightInfo[0],
              dimType: defaults?.packageType ? TypeOptions.find(type => type.value === defaults?.packageType) : {
                name: "PALLET",
                value: "PLT"
              },
              L: defaults?.length,
              W: defaults?.width,
              H: defaults?.height,
              Lus: defaults?.length,
              Wus: defaults?.width,
              Hus: defaults?.height,
              commodity: defaults?.commodity
          }],
          accessorials: defaults.accessorials,
          shipper: {
            ...defaults.defaultBookingShipperLocation,
            country: defaults.defaultBookingShipperLocation?.country || otherCountry
          },
          consignee: {
            ...defaults.defaultBookingConsigneeLocation,
            country: defaults.defaultBookingConsigneeLocation?.country || otherCountry
          },
          pickupDate: {
            type: 'PickupOn',
            date: defaults?.pickupDateToday ? new Date() : null,
            from: defaults?.pickupTimeFrom ,
            to: defaults?.pickupTimeTo
          },
          deliveryDate: {
            type: 'DeliveryBy',
            date: null,
            from: null,
            to: null
          },
          pickupDateToday: defaults?.pickupDateToday
        }

        dispatch({type: actionTypes.SetNewBookingValues, payload: payload})

        dispatch({type: actionTypes.SetInitialNewBooking, payload: payload})
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthorized ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={LandingPage.find(page => page.value === defaults.landingPage)?.path || '/home'} />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
