import store from "../../setup/redux/Store"
import { checkCountry } from "./checkCountry"
import { isNumber } from "./isNumber"
import { isPositiveInteger } from "./isPositiveInteger"

export const validationsBooking = (data, index, errorsArray) => {
    const user = store.getState().auth?.user
    const requiredData = user?.requireDimensions ? user?.allowClassSelection ? ['ShipperZipcode', 'ConsigneeZipcode', 'Pieces', 'Weight', 'Class', 'Length', 'Width', 'Height'] : ['ShipperZipcode', 'ConsigneeZipcode', 'Pieces', 'Weight', 'Length', 'Width', 'Height'] : user?.allowClassSelection ? !user?.requireDimensions ? ['ShipperZipcode', 'ConsigneeZipcode', 'Pieces', 'Weight', 'Class'] : ['ShipperZipcode', 'ConsigneeZipcode', 'Pieces', 'Weight'] : ['ShipperZipcode', 'ConsigneeZipcode', 'Pieces', 'Weight']
    let required = []
    let regexDate = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$")
    let regexTime = new RegExp(/(^$)|^([0-1]?[0-9]|2[0-3]):?[0-5][0-9]$/)

    requiredData.forEach(key => {
      if(data[key] === ''){
        required.push(key)
      }
    })

    if(required.length){
      errorsArray.push(`${required.join(", ")} required in row ${index+2}`)
      return true
    }

    if(!isPositiveInteger(data.Pieces?.toString())){
      return true
    }else if(data.NMFC !== '' ? (!data.NMFC.includes('-') || data.NMFC.length !== 9) : false){
      errorsArray?.push(`NMFC invalid in row ${index+2}`)
      return true
    }else if(!regexTime.test(data.PickupTimeFrom)){
      errorsArray?.push(`Pickup time from invalid in row ${index+2}`)
      return true
    }else if(!regexTime.test(data.PickupTimeTo)){
      errorsArray?.push(`Pickup time to invalid in row ${index+2}`)
      return true
    }else if(!regexDate.test(data.PickupDate)){
      errorsArray?.push(`Pickup date invalid in row ${index+2}`)
      return true
    }else if(!isNumber(data.Weight?.toString())){
      errorsArray?.push(`Weight invalid in row ${index+2}`)
      return true
    }else if(!required.find(req => req === 'ShipperZipcode')?.length && !checkCountry(data.ShipperZipcode)){
      errorsArray?.push(`Shipper Zipcode invalid in row ${index+2}`)
      return true
    }else if(!required.find(req => req === 'ConsigneeZipcode')?.length && !checkCountry(data.ConsigneeZipcode)){
      errorsArray?.push(`Consignee Zipcode invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.Hazmat?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Hazmat invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.LiftgateOrigin?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Liftgate Origin invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.LiftgateDestination?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Liftgate Destination invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.InsideOrigin?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Inside Origin invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.InsideDestination?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Inside Destination invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.ResidentialOrigin?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Residential Origin invalid in row ${index+2}`)
      return true
    }else if(!(["y", "yes", "n", "no", ""].indexOf(data.ResidentialDestination?.toString().toLowerCase()) > -1)){
      errorsArray?.push(`Residential Destination invalid in row ${index+2}`)
      return true
    }
    return false
  }