import styled from 'styled-components';

export const ItalicText = styled.label`
    font-style: italic
`

export const DivPointer = styled.div`
    cursor: pointer
`

export const DivContainerStyled = styled.div`
    background-color: rgb(228, 230, 239);
    border-radius: 0.475rem;
    border: 1px solid  #E4E6EF;
    margin-left: 0px;
    margin-right: 0px
`