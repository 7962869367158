import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    table: {
        "&:hover .hoverRow":{
            filter: `brightness(96%)`
        },
        "& .MuiTableCell-root":{
            fontFamily: 'Poppins',
            fontSize: '12px',
            color: "black !important",
            // border: 'none',
            padding: '6px 9px',
            position: 'sticky !important',
            border: '1px solid #ebebeb'
        },
        "& .MuiTableRow-root": {
            position: 'sticky !important'
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        "& .Mui-active": {
            color: "black !important",
        },
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            marginLeft: 9,
            marginBottom: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiIconButton-root": {
            width: '35px !important',
            height: '35px !important'
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        },
        "& .MuiTablePagination-root": {
            justifyContent: 'start !important'
        },
        "& .MuiInputBase-root": {
            padding: '9.75px 13px',
            border: '1px solid #E4E6EF !important',
            borderRadius: '0.475rem',
            "&::before": {
                border: 'none !important',
            },
            "&::after": {
                border: 'none !important',
            },
            "& .MuiButtonBase-root":{
                padding: '0px'
            }
        }
    },
})