export const isNumber = (num) => {
    if (typeof num !== 'string') {
        return false;
    }

    const number = Number(num);

    if (!isNaN(number) && number > 0) {
        return true;
    }

    return false;
}