import { TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { container } from '../../data/Container';
import _ from 'lodash';
import { KTSVG } from '../../../_metronic/helpers'
import { ContainerProps } from '../../pages/drayage/models';
import AddOrDeleteFreight from './AddOrDeleteFreight';
import Accessorials from './Accessorials';
import { DivContainerStyled } from './DrayagePage.styled';
import { NumberFormatStyled } from '../Inputs.styled';
import { stylesMUIInput } from '../stylesMUIInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { useState } from 'react';
import UNLookupModal from '../LTLandVolumeTLPages/UNLookupModal/UNLookupModal';

const Container = ({index, freight, handleChange, setData, setSelectedUn, freightInfo, errors, touched, setFieldTouched, forNewBooking }: ContainerProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({props: {}})
    const [openUNModal, setOpenUNModal] = useState(false)
    const [openHazContactModal, setOpenHazContactModal] = useState(false)

    const [freightInfoAccessorials] = useState([
        {name: 'Overweight'},
        {name: 'Reefer'},
        {name: 'Residential Delivery'},
        {name: 'Household Goods'},
        {name: 'Liquor'},
        {name: 'Storage Yard'},
        {name: 'CY/Depot'},
    ])

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    const poundsForEver = (value, valueType = '', freightInfoUOM) => {
        if (freightInfoUOM?.value === 'US'){
            return parseFloat(value)
        } else {
            return parseFloat(valueType === 'weight' ? (value/0.45359237).toString() : (value/2.54).toString())
        }
    }

    return (
        <DivContainerStyled className={`row align-items-center px-5 ${forNewBooking ? 'w-100 mb-2' : ''}`}>            
            <div className={`${forNewBooking ? 'col-12 col-sm-5' : 'col-12 col-xl-5'} d-flex align-items-center px-0 py-2`}>
                <KTSVG
                    path="/media/icons/bootstrap/container-shipping.svg"
                    className="svg-icon svg-icon-2x me-2"
                />
                <TextField
                    select
                    label=" "
                    value={freight.container || 20}
                    onChange={handleChange}
                    name={`freightInfo[${index}].container`}
                    className={`${classes.selectMUI} me-1 w-25`}
                    InputProps={{
                        style: stylesMUIInput(fontSize),
                    }}
                    SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        MenuProps: menuProps
                    }}
                    InputLabelProps={{shrink: false}}
                >
                    {container.map((cont, index) => {
                        return <MenuItem key={index} value={cont?.value}>{cont?.name}</MenuItem>
                    })}
                </TextField>
                <NumberFormatStyled
                    className='form-control me-1 w-50'
                    style={{
                        borderColor: errors?.qty && touched?.qty ? '#F1416C' : '#E4E6EF',
                    }}
                    placeholder="QTY"
                    id={errors?.qty && touched?.qty ? 'errorInput' : ''}
                    autoComplete="off"
                    value={freight.qty}
                    name={`freightInfo[${index}].qty`}
                    allowNegative={false}
                    onChange={(e) => {
                        handleChange({
                            ...e,
                            target: {
                                name: `freightInfo[${index}].qty`,
                                value: parseInt(e.target?.value.replace(/,/g, '')) || ''
                            }
                        })
                    }}
                    thousandSeparator={','}
                    decimalScale={0}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 99)
                    }}
                    onBlur={() => setFieldTouched(`freightInfo[${index}].qty`, true)}
                    fontSize={fontSize}
                />
                <NumberFormatStyled
                    className='form-control me-1 w-50'
                    style={{
                        borderColor: errors?.weight && touched?.weight ? '#F1416C' : '#E4E6EF',
                        textOverflow: 'ellipsis',
                        width: '30%'
                    }}
                    id={errors?.weight && touched?.weight ? 'errorInput' : ''}
                    placeholder="Weight"
                    autoComplete="off"
                    value={freight.weight ? _.round(Number(freight.weight)) : ''}
                    name={`freightInfo[${index}].weight`}
                    allowNegative={false}
                    onChange={(e) => {
                        handleChange({
                            ...e,
                            target: {
                                name: `freightInfo[${index}].weight`,
                                value: parseInt(e.target?.value.replace(/,/g, '')) || ''
                            }
                        })
                        handleChange({
                            ...e,
                            target: {
                                name: `freightInfo[${index}].weightus`,
                                value: poundsForEver(parseFloat(e.target?.value.replace(/,/g, '')), 'weight', freight.UOM)
                            }
                        })
                    }}
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    /* isNumericString */
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 99999)
                    }}
                    onBlur={() => setFieldTouched(`freightInfo[${index}].weight`, true)}
                    fontSize={fontSize}
                />
                {/* <TextField
                    select
                    label=" "
                    value={freight?.UOM?.value || 'US'}
                    onChange={(e) => onChangeUOM(e.target?.value, freightInfo[index].UOM?.value)}
                    className={`${classes.selectMUI} me-xl-1 w-25`}
                    InputProps={{
                        style: stylesMUIInput(fontSize),
                    }}
                    SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        MenuProps: menuProps
                    }}
                    InputLabelProps={{shrink: false}}
                >
                    <MenuItem value={'US'}>lbs</MenuItem>
                    <MenuItem value={'METRIC'}>kg</MenuItem>
                </TextField> */}
            </div>
            {
                forNewBooking ?
                <div className="col-6">
                    <div className='row align-items-center'>
                        {/* <div className='col-3 d-flex align-items-center mb-2 mb-sm-0 ps-0 ps-sm-1'>                            
                            <Accessorials
                                handleChange={handleChange}
                                accessorialsSelected={freightInfo[index].accessorials}
                                index={index}
                            /> 
                        </div> */}

                        <div style={{flex: 0.5, minWidth: 40, maxWidth: 40}} className="me-2 mb-2 mb-xl-0 d-flex align-items-center justify-content-center">
                            <div className="form-check form-check-custom form-check-solid form-check-sm d-flex flex-column mb-5">
                                <label style={{cursor: true ? 'default' : 'pointer'}} htmlFor={index.toString()}>
                                    Haz
                                </label>
                                <input
                                    disabled={false}
                                    className="form-check-input white-bg"
                                    type="checkbox"
                                    onChange={(e) => {
                                        handleChange(e)
                                        e.target.checked === false && setData(`freightInfo[${index}].UN`, "")
                                    }}
                                    checked={freight.hazmat}
                                    name={`freightInfo[${index}].hazmat`}
                                    id={index.toString()}
                                />
                            </div>
                        </div> 


                        <div className="col-3">
                                <NumberFormatStyled
                                    name={`freightInfo[${index}].UN`}
                                    onChange={handleChange}
                                    customInput={TextField}
                                    isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === '' || (floatValue >= 0 && floatValue <= 9999)
                                    }}
                                    allowNegative={false}
                                    disabled={!freight.hazmat}
                                    autoComplete="off"
                                    className={classes.textFieldMUIWithoutBorder}
                                    placeholder="UN#"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" style={{margin: 0}}>
                                                <IconButton disabled={false} onClick={() => setOpenUNModal(true)}>
                                                    <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: freight.hazmat ? "#5E6278" : 'rgba(0, 0, 0, 0.23)'}}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: stylesMUIInput(fontSize)
                                    }}
                                    value={freight.UN}
                                    fontSize={fontSize}
                                />
                            </div>


                        <div className='col-6 d-flex align-items-center mb-2 mb-sm-0 ps-0 ps-sm-1'>
                            <TextField
                                select
                                label=" "
                                value={freight.freightInfoAccessorials || []}
                                onChange={(e) => {
                                    handleChange({
                                        ...e,
                                        target: {
                                            name: `freightInfo[${index}].freightInfoAccessorials`,
                                            value: e.target.value
                                        }
                                    })
                                }}
                                className={`${classes.selectMUI}`}
                                InputProps={{
                                    style: stylesMUIInput(fontSize),
                                }}
                                SelectProps={{
                                    IconComponent: KeyboardArrowDownIcon,
                                    MenuProps: menuProps,
                                    multiple: true
                                }}
                                InputLabelProps={{shrink: false}}
                            >
                                {freightInfoAccessorials.map((acc, index) => <MenuItem key={index} value={acc.name}>{acc.name}</MenuItem>)}
                            </TextField>
                        </div>
                    </div>
                </div>
                : <div className="col-12 col-sm-11 col-xl-6 my-lg-5">
                    <Accessorials
                        handleChange={handleChange}
                        accessorialsSelected={freightInfo[index].accessorials}
                        index={index}
                    /> 
                </div>
            }
            
            <div className={`${forNewBooking ? 'col-2 col-sm-1' : 'col-12 col-sm-1'} d-flex ${forNewBooking ? 'justify-content-start' : 'justify-content-center justify-content-sm-end'} my-5 my-xl-0`}>
                <AddOrDeleteFreight
                    index={index}
                    setDataFormik={setData}
                    freightInfo={freightInfo}
                    freight={freight}
                />
            </div>

            <UNLookupModal
                open={openUNModal}
                setOpen={setOpenUNModal}
                setUNNumber={value => setData(`freightInfo[${index}].UN`, value)}
                setSelectedUn={setSelectedUn}
                setOpenHazContactModal={setOpenHazContactModal}
            />
        </DivContainerStyled>
        
    )
}

export default Container