import { Backdrop, CircularProgress, Fade, IconButton } from '@mui/material'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { modalStyles } from '../ModalStyles'
import ClearIcon from '@mui/icons-material/Clear';
import { fileIcon } from '../../helpers/fileIcon'
import EmailSentModal from './EmailSentModal'
import * as Yup from 'yup';
import { RequestInformationModalProps } from '../../pages/shipping-items/models'
import { DivPaperStyled, ModalStyled } from '../Modals.styled'
import { DivFileStyled } from './ShippingItemsPage.styled'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'
import { RootState } from '../../../setup'

const SENDEMAILINVOICES = '/applet/v1/invoice/inquiry'
const SENDEMAILTRACKINGS = '/applet/v1/track/inquiry'

const RequestInformationModal = ({open, setOpen, rowData, inquiryFor}: RequestInformationModalProps) => {
    const dispatch = useDispatch()
    const classesModal = modalStyles({})
    const [loading, setLoading] = useState(false)
    const [filesError, setFilesError] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [inquiryTypes] = useState(
        inquiryFor === 'Invoices' ?
        ['General', 'Dispute', 'Payment']
        : ['General', 'Claim', 'Updated Tracking']
    )
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)

        setTimeout(() => {
            formik.setValues({
                inquiryType: 'General',
                message: '',
                files: null
            })
            formik.setErrors({})
            formik.setTouched({})
            setFilesError(false)
        }, 500);
    }

    const initialValues = {
        inquiryType: 'General',
        message: '',
        files: null
    }

    const validationSchema = Yup.object().shape({
        inquiryType: Yup.string().required('Please select at least 1 option'),
        message: Yup.string().required('Message is required'),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            sendEmail()
        }
    })

    const hiddenFileInput = React.useRef(null);

    const handleUploadFileClick = () => {
        hiddenFileInput.current.click();
    };

    const handleUploadFileChange = e => {
        if (Array.from(e.target.files)?.length > 5) {
            e.preventDefault();
            setFilesError(true)
            setTimeout(() => {
                setFilesError(false)
            }, 5000);
            return;
        }else{
            const array = formik.values.files?.length ? [...formik.values.files, ...Array.from(e.target.files)] : Array.from(e.target.files)
            const noDuplicated = Array.from(new Map(array.map((m: {name: string}) => [m.name, m])).values())

            if(noDuplicated.length > 5){
                setFilesError(true)
                    setTimeout(() => {
                        setFilesError(false)
                }, 5000);
            }else{
                formik.setFieldValue('files', noDuplicated)
                setFilesError(false)
            }
        }
    };

    const sendEmail = () => {
        setLoading(true)

        const formData = new FormData();

        var arr = formik.values.files;
        for (var i = 0; i < arr?.length; i++) {
            formData.append('attachments[]', arr[i]);
        }
        formData.append('message', formik.values.message)
        formData.append('type', formik.values.inquiryType === 'Claim' ? 'Claim/Dispute' : formik.values.inquiryType === 'General' ? 'Other' : formik.values.inquiryType)

        axios.post(`${inquiryFor === 'Invoices' ? SENDEMAILINVOICES : SENDEMAILTRACKINGS}/${inquiryFor === 'Invoices' ? rowData.invoiceId : rowData.BOLId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(() => {
            setLoading(false)
            handleClose()
            setOpenConfirmModal(true)
        }).catch(error => {
            setLoading(false)
            checkErrorAPICall(error, dispatch, 'Send Email')
        })
    }

    const deleteFile = (fileName) => {
        setFilesError(false)
        formik.setFieldValue('files', formik.values.files.filter((file: {name: string}) => file.name !== fileName))
    }

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DivPaperStyled className={classesModal.paperEditModalSL}>
                        <div className="row modal-header p-0 pb-2">
                            <div className="col-5">
                                <h5 className="modal-title">Inquiry</h5>
                            </div>
                            <div className="col-5 d-flex justify-content-end">
                                <h5 className="m-0">{inquiryFor === 'Invoices' ? `INV# ${rowData.invoiceNumber}` : `BOL #${rowData.BOLNumber}`}</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <>
                            <div className="row mt-7">
                                <div className="col-sm-2 mb-2 mb-sm-0">
                                    <label className='form-label'>Type of Inquiry</label>
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        {
                                            inquiryTypes.map((type, index) => {
                                                return (
                                                    <div className="col-4" key={index}>
                                                        <div className="form-check form-check-custom form-check-solid">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="radioInquiry"
                                                                value={type}
                                                                id={type}
                                                                onChange={(e) => formik.setFieldValue('inquiryType', e.target.value)}
                                                                checked={formik.values.inquiryType === type}
                                                            />
                                                            <label className="form-check-label" htmlFor={type}>
                                                                {type}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-10">
                                    {formik.touched.inquiryType && formik.errors.inquiryType && <small className='text-danger mt-3'>{formik.errors.inquiryType}</small>}
                                </div>
                            </div>
                            <div className="row mt-7 align-items-center">
                                <div className="col-sm-2">
                                    <label className='form-label'>Message</label>
                                </div>
                                <div className="col-sm-10">
                                    <textarea
                                        className="form-control"
                                        style={{height: 80}}
                                        autoComplete='off'
                                        {...formik.getFieldProps('message')}
                                    />
                                </div>
                                <div className="col-2"></div>
                                <div className="col-10">
                                    {formik.touched.message && formik.errors.message && <small className='text-danger mt-3'>{formik.errors.message}</small>}
                                </div>
                            </div>
                            <div className="row mt-7 align-items-center">
                                <div className="col-sm-2">
                                    <label className='form-label'>Documents</label>
                                </div>
                                <div className="col-10 col-sm-8 p-0">
                                    <div className={filesError ? 'text-danger ps-3' : formik.values.files?.length > 0 ? 'd-none' : 'text-muted ps-3'}>{filesError ? 'You can select maximum 5 files' : 'No files uploaded'}</div>
                                    {formik.values.files?.map((file, index) => {
                                        return (
                                            <DivFileStyled key={index} className='row ms-3 mb-2 p-1 align-items-center'>
                                                <div className="col-10 d-flex align-items-center">
                                                    <div className='me-2'>
                                                        {fileIcon(file.name)}
                                                    </div>
                                                    <label>
                                                        {file.name}
                                                    </label>
                                                </div>
                                                <div className="col-2 d-flex justify-content-end">
                                                    <IconButton className='p-1' onClick={() => deleteFile(file.name)}>
                                                        <ClearIcon fontSize="small"/>
                                                    </IconButton>
                                                </div>
                                            </DivFileStyled>
                                        )
                                    })}
                                </div>
                                <div className="col-2 d-flex justify-content-end">
                                    <button onClick={handleUploadFileClick} className='btn btn-secondary'>Browse</button>
                                    <input
                                        type="file"
                                        multiple
                                        style={{display: 'none'}}
                                        ref={hiddenFileInput}
                                        onChange={handleUploadFileChange}
                                        accept='.pdf, .xls, .xlsx, .doc, .docx, .png, .jpg, .jpeg, .tiff, .gif, .zip, .rar'
                                    />
                                </div>
                            </div>
                            <div className="row mb-3 mt-7">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-secondary me-4"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className='btn btn-primary d-flex align-items-center'
                                        type='submit'
                                        onClick={() => {
                                            formik.handleSubmit()
                                        }}
                                    >
                                        {loading ? 'Sending' : 'Send'}{loading && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                    </button>
                                </div>
                            </div>
                        </>
                    </DivPaperStyled>
                </Fade>
            </ModalStyled>
            <EmailSentModal
                open={openConfirmModal}
                setOpen={setOpenConfirmModal}
            />
        </>
    )
}

export default RequestInformationModal