import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { useSelector, shallowEqual } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/redux/RootReducer';

export const AsideMenuMain: React.FC = () => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    return (
        <>
            <AsideMenuItem
                to='/home'
                icon='/media/icons/bootstrap/house-door.svg'
                title="Home"
            />
            <AsideMenuItemWithSub
                to='/quote'
                icon='/media/icons/bootstrap/ui-checks-grid.svg'
                title="Quote"
            >
                {
                    user?.allowQuoting ?
                    <>
                        {
                            user?.enabledVendors.filter(vendor => vendor.type === 'LTL' || vendor.type === "SP" || vendor.type === "INTL").length ? 
                            <AsideMenuItem
                                to='quote/ltl'
                                hasBullet={true}
                                title="LTL"
                            />
                            : null
                        }
                        {
                            user?.enabledVendors.filter(vendor => vendor.type === 'VOL').length ? 
                            <AsideMenuItem
                                to='quote/volume-tl'
                                hasBullet={true}
                                title="Volume/TL"
                            />
                            : null
                        }
                        {
                            user?.enabledVendors?.filter(vendor => vendor.type === "DRAY")?.length ? 
                            <AsideMenuItem
                                to='quote/drayage'
                                hasBullet={true}
                                title="Drayage"
                            />
                            : null
                        }
                    </>
                    : null
                }
                <AsideMenuItem
                    to='quote/grid-quotes'
                    hasBullet={true}
                    title="Quotes Grid"
                />
            </AsideMenuItemWithSub>
            
            {
                user?.allowBooking
                ? <AsideMenuItemWithSub
                    to="/book"
                    title="Book"
                    icon='/media/icons/bootstrap/book.svg'
                >
                    {
                        user?.allowBooking ?
                        <AsideMenuItem
                            to='/book/new-booking'
                            hasBullet={true}
                            title="New Booking"
                        /> : null
                    }
                    
                    {
                        user?.allowBatchBooking ?
                        <AsideMenuItem
                            to='/book/batch-booking'
                            hasBullet={true}
                            title="Batch Booking"
                        /> : null
                    } 
                </AsideMenuItemWithSub>
                : null
            }
           
            {
                user?.allowTrack ?
                    <AsideMenuItem
                        to='/trackings'
                        title='Tracking'
                        icon='/media/icons/bootstrap/pin-map.svg'
                    />
                : null
            }
            {
                user?.displayInvoiceInformation ?
                    <AsideMenuItem
                        to='/invoices'
                        icon='/media/icons/bootstrap/receipt.svg'
                        title="Invoices"
                    /> : null
            }
            <AsideMenuItem
                to='/reports'
                icon='/media/icons/bootstrap/pie-chart.svg'
                title="Reports"
            />
            <AsideMenuItemWithSub
                to='/lookups'
                icon='/media/icons/bootstrap/journal.svg'
                title="Lookups"
            >
                <AsideMenuItem
                    to='lookups/shipping-locations'
                    title="Shipping Locations"
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='lookups/shipping-items'
                    title="Shipping Items"
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='lookups/vendors'
                    title="Vendors"
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            {
                (user?.office?.phone || user?.office?.email || user?.office?.name) &&
                <div className='menu-item'>
                    <div className='menu-content'>
                        <div className='separator mx-1 my-4'></div>
                    </div>
                </div>
            }

            <div style={{color: '#9899ac', paddingLeft: 25}} className='mt-4 menu-title'>
                {(user?.office?.phone || user?.office?.email || user?.office?.name) && <div style={{fontWeight: 500}}>Contact Info:</div>}
                {user?.office?.name ? <>{user?.office?.name}<br/></> : null}
                {user?.office?.email ? <>{user?.office?.email}<br/></> : null}
                {user?.office?.phone ? <>{user?.office?.phone}</> : null}
            </div>
        </>
    )
}
