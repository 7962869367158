import {useState, useEffect, Fragment} from 'react'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import { useFormik } from 'formik';
import { CountryOptions } from '../../data/CountryOptions';
import ZipcodesWithCountry from '../../components/LTLandVolumeTLPages/ZipcodesWithCountry';
import { UserModel } from '../../modules/auth/models/UserModel';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import * as Yup from "yup"
import axios from 'axios';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { RatesTable } from '../../components/LTLandVolumeTLPages/Tables/RatesTable/RatesTable';
import { OriginOrDestinationObject, RowTableObject } from '../ltl/models';
import { v4 as uuidv4 } from 'uuid';
import HistorySelect from '../../components/LTLandVolumeTLPages/HistoryComponent/HistorySelect';
import Container from '../../components/DrayagePage/Container';
import { useParams } from 'react-router-dom';
import AccessorialsDropDown from '../../components/LTLandVolumeTLPages/AccesorialsComponent/Accessorials';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { marginSize } from '../../helpers/marginSize';
import SelectUOM from '../../components/LTLandVolumeTLPages/FreightInfo/SelectUOM';
import { FreightTotals } from '../../components/LTLandVolumeTLPages/FreightInfo/FreightTotals';
import { getRates } from '../../helpers/getRates';
import { keepWaitingVendors } from '../../helpers/keepWaitingVendors';
import ConfirmTimedOutVendorsModal from '../../components/LTLandVolumeTLPages/Modals/ConfirmTimedOutVendorsModal';
import NoVendorsSetUpModal from '../../components/Modals/NoVendorsSetUpModal';
import InsuranceRequiredModal from '../book/NewBooking/InsuranceRequiredModal';

let cancelToken = axios.CancelToken
let source = cancelToken.source()
const GET_LNFT = '/api/v1/tools/lnft'
const GET_LANEDISTANCE = '/api/v1/tools/distancematrix'
const GET_QUOTE = '/api/v1/database/quote'

export const Drayage = () => {
  const resetValues = useSelector<RootState>(({pages}) => pages?.resetValues) as boolean
  const [origin, setOrigin] = useState<OriginOrDestinationObject>({
    City: "",
    Country: "",
    Name: "",
    State: "",
    Zipcode: "",
    isSL: false,
    isZipcode: false,
    isPort: false,
    id: null
  })
  const [destination, setDestination] = useState<OriginOrDestinationObject>({
    City: "",
    Country: "",
    Name: "",
    State: "",
    Zipcode: "",
    isSL: false,
    isZipcode: false,
    isPort: false,
    id: null
  })
  const [rates, setRates] = useState([] as Array<RowTableObject>)
  const [linearProgress, setLinearProgress] = useState(0);
  const [showTable, setShowTable] = useState(false)
  const [zipcodeOrigin, setZipcodeOrigin] = useState('')
  const [zipcodeDestination, setZipcodeDestination] = useState('')
  const [selectedAcc, setSelectedAcc] = useState([]);
  const [ratesStopped, setRatesStopped] = useState(false)
  const [laneDistance, setLaneDistance] = useState("")
  const [LNFT, setLNFT] = useState(0)
  const [count, setCount] = useState(0)
  const [start, setStart] = useState(false)
  const [actionClicked, setActionClicked] = useState(false)
  const [selectedUn, setSelectedUn] = useState({});
  const dispatch = useDispatch()
  const ports: any = useSelector<any>((store) => store.pages.drayagePorts) as any
  let {setHistoryElement} = usePageData()
  let { id } = useParams();
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
  const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
  const [enabledVendors, setEnabledVendors] = useState([])
  const [vendorsProcessed, setVendorsProcessed] = useState('0')
  const [vendorsTimedOut, setVendorsTimedOut] = useState([])
  const [openConfirmTimedOutModal, setOpenConfirmTimedOutModal] = useState(false)
  const [openNoVendorsSetUpModal, setOpenNoVendorsSetUpModal] = useState(false)
  const [openInsuranceRequiredModal, setOpenInsuranceRequiredModal] = useState(false)

  const initialValues = {
    origin: {
      City: "",
      Country: "",
      Name: "",
      State: "",
      Zipcode: "",
      isSL: false,
      isZipcode: false,
      isPort: false,
      id: null,
      CountryPort: ''
    },
    destination: {
      City: "",
      Country: "",
      Name: "",
      State: "",
      Zipcode: "",
      isSL: false,
      isZipcode: false,
      isPort: false,
      id: null,
      CountryPort: ''
    },
    freightInfo: [{
      id: uuidv4(),
      container: 20,
      qty: '',
      weightus: '',
      weight: '',
      UN: '',
      UNPKGGroup: '',
      hazmat: false,
      overweight: false,
      reefer: false,
      residentialDelivery: false,
      householdGoods: false,
      liquor: false,
      storageYard: false,
      CYDepot: false,
      UOM: {
        value: 'US',
        oldValue: ''
      },
      accessorials: [],
    }],
    insuranceAmount: null,
    insuranceFreight: false,
    insuranceAddOn: false,
    commodityInsurance: '',
    freightInfoTotals: {
      totalPieces: 0,
      totalWeight: 0,
      totalCFT: 0,
      totalDensity: 0,
      symbol: 'lbs'
    },
    freightInfoUOM: {
      value: 'US',
      oldValue: ''
    },
  }

  const validationSchema = Yup.object().shape({
    origin: Yup.object({
      Zipcode: Yup.string().required('Required'),
    }),
    destination: Yup.object({
      Zipcode: Yup.string().required('Required'),
    }),
    freightInfo: Yup.array().of(
      Yup.object().shape({
        qty: Yup.string().required("Required"),
        weight: Yup.string().required("Required")
      })
    ),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
     if(selectedAcc.filter(a => a.code === 'INS')?.length && (!formik.values.commodityInsurance || !formik.values.insuranceAmount)){
          setOpenInsuranceRequiredModal(true)
      }else{
      	  const enabledVendorsFiltered = user.enabledVendors.filter(vendor => ((vendor.type === 'DRAY')))
	      source = cancelToken.source()

	      if(enabledVendorsFiltered?.length){
	        getRates(values, selectedAcc, enabledVendorsFiltered, enabledVendorsFiltered, [], setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, () => {}, setLinearProgress, setRatesStopped, () => {}, () => {}, () => {}, source, normalizeParams, 'historyDrayage')
	      }else{
	        setOpenNoVendorsSetUpModal(true)
	      }

	      getLNFTandDistance(values)
      }
      
    }
  })

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const normalizeParams = (vendorId) => {
    const data = formik.values
    let accessorials = []

    data.freightInfo?.forEach(freight => {
      freight?.accessorials?.forEach(acc => {
        if(!accessorials?.includes(acc)){
          accessorials = [...accessorials, acc]
        }
      })
    })

    return {
      'rateTypesList[]': 'DRAY',
      vendorId: vendorId,
      originCity: data.origin.City,
      originState: data.origin.State,
      originZipcode: data.origin.Zipcode,
      originCountry: data.origin.Country === 'Port' ? data.origin.CountryPort : data.origin.Country,
      destinationCity: data.destination.City,
      destinationState: data.destination.State,
      destinationZipcode: data.destination.Zipcode,
      // destinationCountry: data.destination.Country === 'Port' ? data.destination.CountryPort : data.destination.Country,
      destinationCountry: data.destination.Country === 'Port' ? 'US' : data.destination.Country,
      freightInfo: JSON.stringify(
        data.freightInfo.map((freight: any) => {          
          return {
            qty: parseFloat(freight.qty),
            weight: parseFloat(freight.weight),
            weightType: "each",
            length: parseFloat(freight.L) || 1,
            width: parseFloat(freight.W) || 1,
            height: parseFloat(freight.H) || 1,
            class: parseFloat(freight.class) || 0,
            dimTypeDrayage: freight.container,
            UN : freight?.UN,
            UNDescription: selectedUn?.description,
            UNHAZClass:  selectedUn?.HAZClass,
            UNPKGGroup: selectedUn?.PKGGroup,
            UNLabels: selectedUn?.labels,
            hazmat: typeof freight.hazmat == 'object' ? true : freight.hazmat,
            stack: false,
            overweight: freight.freightInfoAccessorials?.includes("Overweight"),
            reefer: freight.freightInfoAccessorials?.includes("Reefer"),
            residentialDelivery: freight.freightInfoAccessorials?.includes("Residential Delivery"),
            householdGoods: freight.freightInfoAccessorials?.includes("Household Goods"),
            liquor: freight.freightInfoAccessorials?.includes("Liquor"),
            storageYard: freight.freightInfoAccessorials?.includes("Storage Yard"),
            CYDepot: freight.freightInfoAccessorials?.includes("CY/Depot"),
          }
        })
      ),
      UOM: 'US',
      'accessorialsList[]': [...accessorials, ...selectedAcc.map(acc => acc.code)].join('|'),
      commodityInsurance: data.commodityInsurance,
      insuranceAmount: data.insuranceAmount,
      insuranceFreight: data.insuranceFreight,
      insuranceAddOn: data.insuranceAddOn
    }
  }

  const getLNFTandDistance = (values) => {
    setLNFT(0)
    setLaneDistance("")

    const getLNFT = axios.get(GET_LNFT, {params: {
      returnLayout: false,
      companyDefaultTruck: true,
      freightInfo: JSON.stringify(
        formik.values.freightInfo.map((freight: any) => {
          return {
            qty: parseFloat(freight.qty),
            weight: parseFloat(freight.weight),
            weightType: freight.each === "Each" ? "each" : "total",
            length: parseFloat(freight.L) || 1,
            width: parseFloat(freight.W) || 1,
            height: parseFloat(freight.H) || 1,
            class: parseFloat(freight.class) || 0,
            hazmat: freight.hazmat,
            stack: false,
          }
        })
      ),
      UOM: 'US',
    }}).then(response => response.data.data.results)

    const getDistance = axios.get(GET_LANEDISTANCE, {params: {
      'origins[]': values.origin.Zipcode,
      'destinations[]': values.destination.Zipcode,
      units: 'imperial'
    }}).then(response => response.data.data.results?.length ?  response.data.data.results[0] : "")

    Promise.all([getLNFT, getDistance]).then(values => {
      setLNFT(values[0]?.LNFT)
      setLaneDistance(values[1]?.distance?.text)
    })
  }

  useEffect(() => {
    if(selectedAcc.length){
      if(count === 0){
        if(formik.values.origin.Zipcode && formik.values.destination.Zipcode && formik.values.freightInfo[0].qty && formik.values.freightInfo[0].weight && id && selectedAcc.length){
          setCount(count + 1)
          const enabledVendorsFiltered = user.enabledVendors.filter(vendor => ((vendor.type === 'DRAY')))
          source = cancelToken.source()
          getRates(formik.values, selectedAcc, enabledVendorsFiltered, enabledVendorsFiltered, [], setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, () => {}, setLinearProgress, setRatesStopped, () => {}, () => {}, () => {}, source, normalizeParams, 'historyDrayage')
          getLNFTandDistance(formik.values)
        }
      }
    }else{
      if(count === 0){
        if(formik.values.origin.Zipcode && formik.values.destination.Zipcode && formik.values.freightInfo[0].qty && formik.values.freightInfo[0].weight && id){
          setCount(count + 1)
          const enabledVendorsFiltered = user.enabledVendors.filter(vendor => ((vendor.type === 'DRAY')))
          source = cancelToken.source()
          getRates(formik.values, selectedAcc, enabledVendorsFiltered, enabledVendorsFiltered, [], setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, () => {}, setLinearProgress, setRatesStopped, () => {}, () => {}, () => {}, source, normalizeParams, 'historyDrayage')
          getLNFTandDistance(formik.values)
        }
      }
    }
    
    if(linearProgress > 0){
      source.cancel('Operation canceled by the user.')
      setRatesStopped(true)
      setLinearProgress(0)
      setRates([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.origin, formik.values.destination, formik.values.freightInfo])

  useEffect(() => {
    setLNFT(0)
    setLaneDistance("")
  }, [formik.values.origin, formik.values.destination])

  useEffect(() => {
    if(!ports.length){
      axios.get('/api/v1/tools/ports').then(result => {
        let results = result.data.data.results
        const portsNormalized = results?.map(result => {
          return {
            name: result.name,
            postalCode: result.zipcode,
            stateCode: result.state,
            cityAlias: result.city,
            literal: `${result?.name}, ${result.state} ${result.zipcode}`,
            isPort: true,
            country: result.country,
            isZipcode: false,
            isSL: false
          }
        })
        dispatch({type: actionTypes.SetDrayagePorts, payload: portsNormalized})
        setStart(true)
      }).catch(error => {
        checkErrorAPICall(error, dispatch, 'Ports')
      })
    }else{
      setStart(true)
    }
    
    setHistoryElement(<HistorySelect
      historyFor={'Drayage'}
      setDataFormik={formik.setFieldValue}
      setSelectedAcc={setSelectedAcc}
      setOrigin={setOrigin}
      setDestination={setDestination}
    />)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(id && start){
      axios.get(`${GET_QUOTE}/${id}`).then(results => {
        let quoteInfo = results.data.data.results

        if(quoteInfo?.accessorialsList?.filter(acc => acc !== 'OWT' && acc !== 'HAZ' && acc !== 'REEF' && acc !== 'RESI' && acc !== 'HOGO').length){
          let accessorials = quoteInfo?.accessorialsList?.filter(acc => acc !== 'OWT' && acc !== 'HAZ' && acc !== 'REEF' && acc !== 'RESI' && acc !== 'HOGO')
          let accessorialsArrayData = []

          accessorials.forEach(accessorial => {
            accessorialsArrayData.push(user?.enabledAccessorials.filter(acc => acc.code === accessorial)[0])
          })
          
          setSelectedAcc(accessorialsArrayData)
        }
        
        if(ports.filter(port => port.name === quoteInfo.shipper.city).length){
          formik.setFieldValue('origin', {
            City: quoteInfo?.shipper.city,
            Country: quoteInfo?.shipper.country,
            Name: quoteInfo?.shipper.city,
            State: quoteInfo?.shipper.state,
            Zipcode: quoteInfo?.shipper.zipCode,
            id: '',
            isPort: true,
            isSL: false,
            isZipcode: false
          })
          setZipcodeOrigin((`${quoteInfo?.shipper?.city}, ${quoteInfo?.shipper.state} ${quoteInfo?.shipper.zipCode}`))

          formik.setFieldValue('destination', {
            City: quoteInfo?.consignee.city,
            Country: quoteInfo?.consignee.country,
            Name: undefined,
            State: quoteInfo?.consignee.state,
            Zipcode: quoteInfo?.consignee.zipCode,
            id: '',
            isPort: false,
            isZipcode: true,
            isSL: false
          })
          setZipcodeDestination((`${quoteInfo?.consignee.city}${quoteInfo?.consignee.city ? ', ' : ''}${quoteInfo?.consignee.state ? quoteInfo?.consignee.state+' ' : ''}${quoteInfo?.consignee.zipCode}`))

        }else if(ports.filter(port => port.name === quoteInfo.consignee.city).length){
          formik.setFieldValue('destination', {
            City: quoteInfo?.consignee.city,
            Country: quoteInfo?.consignee.country,
            Name: quoteInfo?.consignee.name,
            State: quoteInfo?.consignee.state,
            Zipcode: quoteInfo?.consignee.zipCode,
            id: '',
            isPort: true,
            isSL: false,
            isZipcode: false
          })
          setZipcodeDestination((`${quoteInfo?.consignee?.city}, ${quoteInfo?.consignee?.state} ${quoteInfo?.consignee?.zipCode}`))

          formik.setFieldValue('origin', {
            City: quoteInfo?.shipper.city,
            Country: quoteInfo?.shipper.country,
            Name: undefined,
            State: quoteInfo?.shipper.state,
            Zipcode: quoteInfo?.shipper.zipCode,
            id: '',
            isPort: false,
            isZipcode: true,
            isSL: false
          })
          setZipcodeOrigin((`${quoteInfo?.shipper.city}${quoteInfo?.shipper.city ? ', ' : ''}${quoteInfo?.shipper.state ? quoteInfo?.shipper.state+' ' : ''}${quoteInfo?.shipper.zipCode}`))
        }else{
          formik.setFieldValue('destination', {
            City: quoteInfo?.consignee.city,
            Country: quoteInfo?.consignee.country,
            Name: undefined,
            State: quoteInfo?.consignee.state,
            Zipcode: quoteInfo?.consignee.zipCode,
            id: '',
            isPort: false,
            isZipcode: true,
            isSL: false
          })
          setZipcodeDestination((`${quoteInfo?.consignee.city}${quoteInfo?.consignee.city ? ', ' : ''}${quoteInfo?.consignee.state ? quoteInfo?.consignee.state+' ' : ''}${quoteInfo?.consignee.zipCode}`))
          formik.setFieldValue('origin', {
            City: quoteInfo?.shipper.city,
            Country: quoteInfo?.shipper.country,
            Name: undefined,
            State: quoteInfo?.shipper.state,
            Zipcode: quoteInfo?.shipper.zipCode,
            id: '',
            isPort: false,
            isZipcode: true,
            isSL: false
          })
          setZipcodeOrigin((`${quoteInfo?.shipper.city}${quoteInfo?.shipper.city ? ', ' : ''}${quoteInfo?.shipper.state ? quoteInfo?.shipper.state+' ' : ''}${quoteInfo?.shipper.zipCode}`))
        }

        formik.setFieldValue('freightInfo', quoteInfo?.freightInfo.map(quote => {
          return ({
            id: uuidv4(),
            container: 20,
            qty: quote.qty,
            weightus: '',
            weight: quote.weight,
            UOM: {
              value: quoteInfo.UOM,
              oldValue: ''
            },
            accessorials: quoteInfo?.accessorialsList
          })
        }))
        
      }).catch(error => {
        checkErrorAPICall(error, dispatch, 'Get Quote')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start])

  const setDefaultValues = () => {
    ['shipper', 'consignee'].forEach((location) => {
      formik.setFieldValue(location === 'shipper' ? 'origin' : 'destination', {
        City: newBookingValues[location]?.city || '',
        Country: newBookingValues[location]?.country || '',
        Name: newBookingValues[location]?.name || '',
        State: newBookingValues[location]?.state || '',
        Zipcode: newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "",
        id: newBookingValues[location]?.id || '',
        isPort: false,
        isSL: newBookingValues[location]?.name ? true : false,
        isZipcode: !newBookingValues[location]?.name ? true : false,
      })

      if(newBookingValues[location]?.id){
        if(location === 'shipper'){
          setOrigin({
            City: newBookingValues[location]?.city || '',
            Country: newBookingValues[location]?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === newBookingValues[location]?.country)?.Code || "US" : newBookingValues[location]?.country,
            Name: newBookingValues[location]?.name || '',
            State: newBookingValues[location]?.state || '',
            Zipcode: newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "",
            id: newBookingValues[location]?.id || '',
            isPort: false,
            isSL: newBookingValues[location]?.name ? true : false,
            isZipcode: !newBookingValues[location]?.name ? true : false,
          })
          setZipcodeOrigin(`${newBookingValues[location]?.name}. ${newBookingValues[location]?.city}, ${newBookingValues[location]?.state} (${newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode})`)
        }else{
          setDestination({
            City: newBookingValues[location]?.city || '',
            Country: newBookingValues[location]?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === newBookingValues[location]?.country)?.Code || "US" : newBookingValues[location]?.country,
            Name: newBookingValues[location]?.name || '',
            State: newBookingValues[location]?.state || '',
            Zipcode: newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "",
            id: newBookingValues[location]?.id || '',
            isPort: false,
            isSL: newBookingValues[location]?.name ? true : false,
            isZipcode: !newBookingValues[location]?.name ? true : false,
          })
          setZipcodeDestination(`${newBookingValues[location]?.name}. ${newBookingValues[location]?.city}, ${newBookingValues[location]?.state} (${newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode})`)
        }
      }
      
      let accessorials = []
      if(newBookingValues?.accessorials?.length){
        newBookingValues?.accessorials?.forEach(accessorial => {
          let acc = user.enabledAccessorials.filter(enabledAcc => enabledAcc.code === accessorial)
          accessorials.push(...acc)
        })
      }
  
      formik.setFieldValue(`accessorials.origin`, accessorials.filter(acc => acc.category === 'Origin'))
      formik.setFieldValue(`accessorials.destination`, accessorials.filter(acc => acc.category === 'Destination'))
      formik.setFieldValue(`accessorials.other`, accessorials.filter(acc => acc.category === 'Other'))
      setSelectedAcc(accessorials)
    })
  }

  useEffect(() => {
    if(resetValues){
      formik.resetForm()
      setOrigin({
        City: "",
        Country: "",
        Name: "",
        State: "",
        Zipcode: "",
        isSL: false,
        isZipcode: false,
        id: null
      })
      setZipcodeOrigin('')
      setDestination({
        City: "",
        Country: "",
        Name: "",
        State: "",
        Zipcode: "",
        isSL: false,
        isZipcode: false,
        id: null
      })
      setZipcodeDestination('')
      setDefaultValues()
      setShowTable(false)
      dispatch({type: actionTypes.SetResetValues, payload: false})
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  useEffect(() => {
    setDefaultValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBookingValues, resetValues])

  useEffect(() => {
    if(!formik?.values?.freightInfo[0]?.UN || !formik?.values?.freightInfo[0]?.hazmat || 
      formik?.values?.freightInfo[0]?.UN && formik?.values?.freightInfo[0]?.UN !== selectedUn?.UNNumber) {
      setSelectedUn({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.freightInfo]);

  useEffect(() => {
    if(formik.errors?.origin?.Zipcode){
      if(formik.values.origin.Zipcode){
        formik.setFieldError(`origin.Zipcode`, '')
      }
    }

    if(formik.errors?.destination?.Zipcode){
      if(formik.values.destination.Zipcode){
        formik.setFieldError(`destination.Zipcode`, '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])

  return (
    <div style={{marginRight: window.innerWidth > 991 && (fontSize === 9 ? 300 : fontSize === 11 ? 210 : 0)}}>
      <PageTitle>Drayage</PageTitle>

      <form
        onSubmit={formik.handleSubmit}
      >
        <div className="form-group row">
          <label className={`col-xl-1 col-form-label ${marginSize(fontSize, 'mt-2','')}`}>Origin</label>
          <ZipcodesWithCountry
            drayage={true}
            zipcodeLocal={zipcodeOrigin}
            setZipcodeLocal={setZipcodeOrigin}
            valueName={'origin'}
            setDataFormik={formik.setFieldValue}
            data={origin}
            secondData={destination}
            setData={setOrigin}
            countryOptions={CountryOptions}
            userData={user}
            error={formik?.errors?.origin?.Zipcode}
            touched={formik?.touched?.origin}
            setFieldTouched={formik.setFieldTouched}
            forDrayage={true}
            setFieldError={formik.setFieldError}
          />
          <label className={`col-xl-1 col-form-label ${marginSize(fontSize, 'mt-2','')}`}>Destination</label>
          <ZipcodesWithCountry
            drayage={true}
            zipcodeLocal={zipcodeDestination}
            setZipcodeLocal={setZipcodeDestination}
            valueName={'destination'}
            setDataFormik={formik.setFieldValue}
            data={destination}
            secondData={origin}
            setData={setDestination}
            countryOptions={CountryOptions}
            userData={user}
            error={formik?.errors?.destination?.Zipcode}
            touched={formik?.touched?.destination}
            setFieldTouched={formik.setFieldTouched}
            forDrayage={true}
            setFieldError={formik.setFieldError}
          />
        </div> 

        <div className="form-group row align-items-center">
          {formik.values.freightInfo.map((freight, index) => (
            <Fragment key={freight.id}>
              <label className={`col-12 col-xl-1 col-form-label ${marginSize(fontSize, 'mb-2 mb-xl-6', 'mb-1')}`}>{index === 0 && 'Freight Info'}</label>
              <div className={`col-12 col-xl-11 ${marginSize(fontSize, 'mb-6', 'mb-1')}`} >
                <Container
                  index={index}
                  freight={freight}
                  forNewBooking={true}
                  handleChange={formik.handleChange}
                  setData={formik.setFieldValue}
                  setSelectedUn={setSelectedUn}
                  selectedUn={selectedUn}
                  setDataFormik={formik.setFieldValue}
                  freightInfo={formik.values.freightInfo}
                  errors={formik.errors.freightInfo && (formik.errors.freightInfo[index])}
                  touched={formik?.touched?.freightInfo && formik?.touched?.freightInfo[index]}
                  setFieldTouched={formik.setFieldTouched}
                />
              </div>
            </Fragment>
          ))}
        </div>
        <div className={`form-group row ${marginSize(fontSize, 'mb-2', 'mb-xl-3')} ms-1 me-1`}>          
          <FreightTotals
            freightInfoTotals={formik.values.freightInfoTotals}
            freightInfo={formik.values.freightInfo as any}
            freightInfoUOM={formik.values.freightInfoUOM}
            setDataFormik={formik.setFieldValue}
            forDrayage={true}
          />
          <SelectUOM
            freightInfoUOM={formik.values.freightInfoUOM}
            setDataFormik={formik.setFieldValue}
            freightInfo={formik.values.freightInfo as any}
            freightInfoTotals={formik.values.freightInfoTotals}
          />
        </div>

        <div className="form-group row align-items-center">
          <label className={`col-xl-1 col-sm-12 col-form-label`}>Accessorials</label>
          <div className={`col-xl-6 col-sm-12`} style={{position: 'relative'}}>
            <AccessorialsDropDown
              formikValues={formik.values}
              setFormikValues={formik.setFieldValue}
              selectedAcc={selectedAcc}
              setSelectedAcc={setSelectedAcc}
              // accessorialsList={user?.enabledAccessorials?.filter(acc => !(acc.code === 'OWT' || acc.code === 'HAZ' || acc.code === 'REEF' || acc.code === 'RESI' || acc.code === 'HOGO') && acc.rateTypes?.includes('DRAY'))?.filter(accessorial => accessorial.code !== 'HAZ')}
              accessorialsList={user?.enabledAccessorials?.filter(accessorial => accessorial.rateTypes?.includes('LTL'))?.filter(accessorial => accessorial.code !== 'HAZ')}
              actionClicked={actionClicked}
            />
          </div> 
        </div>

        <div className="row align-items-center">
          {
            <div className='col-12 col-sm-7'>
              {
                LNFT && laneDistance
                ? 
                <div style={{color: '#3F4254'}}>
                  <span style={{fontWeight: 500}}>Aprox. LNFT:</span> {LNFT} - <span style={{fontWeight: 500}}>Distance:</span> {laneDistance}
                </div>
                : null
              }
            </div>
          }
          <div className="col-12 col-sm-5 mt-3 mt-xl-0 d-flex justify-content-end">
              <button
                type="button"
                className='btn btn-danger me-4'
                onClick={() => {
                  formik.resetForm()
                  setShowTable(false)
                  setZipcodeOrigin('')
                  setZipcodeDestination('')
                  setSelectedAcc([])
                  formik.setErrors({})
                  formik.setTouched({})
                }}
              >
                Clear
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                onClick={() => {
                  setActionClicked(!actionClicked)
                }}
              >
                Get Rates
              </button>
          </div>
        </div>
      </form>
      {
        showTable ?
        <RatesTable
          vendorsFiltered={enabledVendors}
          vendorsProcessed={vendorsProcessed}
          tableFor={'Drayage'}
          ratesStopped={ratesStopped}
          setRatesStopped={setRatesStopped}
          source={source}
          linearProgress={linearProgress}
          rates={rates}
          origin={formik?.values?.origin}
          destination={formik?.values?.destination}
          values={formik.values}
          selectedAcc={selectedAcc}
        />
        : null
      }
      <ConfirmTimedOutVendorsModal
        open={openConfirmTimedOutModal}
        setOpen={setOpenConfirmTimedOutModal}
        vendorsTimedOut={vendorsTimedOut}
        keepWaitingVendors={() => {
          source = cancelToken.source()
          keepWaitingVendors(user, formik.values, rates, [], linearProgress, setLinearProgress, vendorsTimedOut, setVendorsTimedOut, vendorsProcessed, setVendorsProcessed, setRatesStopped, enabledVendors, setRates, () => {}, setOpenConfirmTimedOutModal, source, normalizeParams)
        }}
        formikValues={formik.values}
      />
      <NoVendorsSetUpModal
        open={openNoVendorsSetUpModal}
        setOpen={setOpenNoVendorsSetUpModal}
      />
      <InsuranceRequiredModal
        open={openInsuranceRequiredModal}
        setOpen={setOpenInsuranceRequiredModal}
      />
  </div>
  )
}