import { useEffect, useState } from 'react';
import PhoneInput from '../ShippingLocationsPage/PhoneInput';
import { TabsStyled, TabStyled } from './NewBooking.styled';
import { InputStyled, SelectStyled } from '../Inputs.styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { stylesMUIInput } from '../stylesMUIInput';
import { IconButton, MenuItem } from '@mui/material';
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles';
import axios from 'axios';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TextField } from '@mui/material'
import StopsModal from './Stops/StopsModal';

const TRUCKTYPES = '/api/v1/system/trucktype'
const ICONTERMS = 'api/v1/database/incoterms'

const Tabs = ({formik, mode}) => {
    const [tab, setTab] = useState(0)
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
    const [equipments, setEquipments] = useState([])
    const [openStopsModal, setOpenStopsModal] = useState(false)
    const [incoTermsList, setIncoTermList] = useState([]);
    const [selectedIncoTerms, setSelectedIncoTerms] = useState('');
    const classes = useStyles({
        fontSize: fontSize
    })

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    }

    const selectInconTerms = (selected) => {
        formik.setFieldValue('otherInfo.incoTerm', selected);        
   }

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    useEffect(() => {
        if(mode?.shipmentCategory === 'Volume'){
            axios.get(TRUCKTYPES).then(results => {
                setEquipments(results.data.data.results)
              }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Get Truck Types')
            })
        }else{
            if(tab === 3){
                setTab(0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode])


    useEffect(() => {        
            axios.get(ICONTERMS).then(results => {                
                setIncoTermList(results.data.data.results);               
              }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Get Inco Terms Error')
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {        
        setSelectedIncoTerms(formik.values.otherInfo.incoTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.otherInfo.incoTerm])

    return (
        <>
            <TabsStyled value={tab} onChange={handleChange} aria-label="basic tabs example">
                <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Special Instructions" {...a11yProps(0)} />
                <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Shipment Notes" {...a11yProps(1)} />
                <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Other Info" {...a11yProps(2)} />
                {mode?.shipmentCategory === 'Volume' ? <TabStyled style={{textTransform: 'none'}} disableTouchRipple={true} label="Stops" {...a11yProps(3)} /> : null}
            </TabsStyled>
            <div className={`${tab === 0 ? 'd-flex' : 'd-none'} mt-4`}>
                <textarea
                    className="form-control"
                    style={{height: 119}}
                    autoComplete='off'
                    {...formik.getFieldProps('specialInstructions')}
                    disabled={newBookingValues?.dispatched}
                />
            </div>
            <div className={`${tab === 1 ? 'd-flex' : 'd-none'} mt-4`}>
                <textarea
                    className="form-control"
                    style={{height: 119}}
                    autoComplete='off'
                    {...formik.getFieldProps('shipmentNotes')}
                    disabled={newBookingValues?.dispatched}
                />
            </div>
            <div className={`${tab === 2 ? 'd-flex' : 'd-none'} row mt-4`}>
                {
                    (!formik.values.shipper.country?.includes('US') || !formik.values.consignee.country?.includes('US')) &&
                    <>
                        <div className="col-3">
                            <label className='col-form-label'>Broker Name</label>
                        </div>
                        <div className="col-9">
                            <input
                                className="form-control"
                                autoComplete='off'
                                {...formik.getFieldProps('otherInfo.broker.name')}
                                disabled={newBookingValues?.dispatched}
                            />
                        </div>
                        <div className="col-3 mt-4">
                            <label className='col-form-label'>Broker Contact</label>
                        </div>
                        <div className="col-9 mt-4">
                            <input
                                className="form-control"
                                autoComplete='off'
                                {...formik.getFieldProps('otherInfo.broker.contact')}
                                disabled={newBookingValues?.dispatched}
                            />
                        </div>
                        <div className="col-3 mt-4">
                            <label className='col-form-label'>Broker Phone</label>
                        </div>
                        <div className="col-9 mt-4">
                            <PhoneInput
                                value={formik.values.otherInfo.broker.phone || ""}
                                setDataFormik={formik.setFieldValue}
                                name={'otherInfo.broker.phone'}
                                countryCode={formik.values.otherInfo.broker.country || "US"}
                                deleteSelectCountry={false}
                                disabled={newBookingValues?.dispatched}
                            />
                        </div>
                        <div className="col-3 mt-4 mb-4">
                            <label className='col-form-label'>Instructions</label>
                        </div>
                        <div className="col-9 mt-4 mb-4">
                            <textarea
                                className="form-control"
                                style={{height: 119}}
                                autoComplete='off'
                                {...formik.getFieldProps('otherInfo.broker.instructions')}
                                disabled={newBookingValues?.dispatched}
                            />
                        </div>
                    </>
                }
                <div className="col-3">
                    <label className='col-form-label'>BKG Ref#</label>
                </div>
                <div className="col-9">
                    <InputStyled
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('otherInfo.BKGRefNumber')}
                        fontSize={fontSize}
                        disabled={newBookingValues?.dispatched}
                    />
                </div>
                <div className="col-3 mt-4">
                    <label className='col-form-label'>BKG Value</label>
                </div>
                <div className="col-9 mt-4">
                    <InputStyled
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('otherInfo.BKGValue')}
                        maxLength={12}
                        fontSize={fontSize}
                        disabled={newBookingValues?.dispatched}
                    />
                </div>
                <div className="col-3 mt-4">
                    <label className='col-form-label'>Ref. #1</label>
                </div>
                <div className="col-9 mt-4">
                    <InputStyled
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('otherInfo.refNumber1')}
                        maxLength="120"
                        fontSize={fontSize}
                        disabled={newBookingValues?.dispatched}
                    />
                </div>
                <div className="col-3 mt-4">
                    <label className='col-form-label'>Ref. #2</label>
                </div>
                <div className="col-9 mt-4">
                    <InputStyled
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('otherInfo.refNumber2')}
                        maxLength="120"
                        fontSize={fontSize}
                        disabled={newBookingValues?.dispatched}
                    />
                </div>
                {incoTermsList && (
                    <>
                    <div className="col-3 mt-4">
                        <label className='col-form-label'>IncoTerms</label>
                    </div>
                    <div className="col-9 mt-4">                        
                        <TextField
                            select
                            label=" "
                            value={selectedIncoTerms}
                            name={'otherInfo.incoTerm'}                            
                            onChange={(e) => {
                                selectInconTerms(e.target.value)
                            }}
                            className={`${classes.selectMUI}`}
                            InputProps={{
                                style: stylesMUIInput(fontSize),
                            }}
                            SelectProps={{
                                IconComponent: KeyboardArrowDownIcon,
                                MenuProps: menuProps,
                                multiple: false
                            }}
                            InputLabelProps={{shrink: false}}
                        >
                            {incoTermsList.map((incoTerm, index) => <MenuItem key={index}  value={incoTerm.code}>{incoTerm.name}</MenuItem>)}
                        </TextField>
                    </div>
                    </>
                )}
            </div>
            <div className={`${tab === 3 ? 'd-flex' : 'd-none'} row mt-4`}>
                <div className="col-12 col-sm-2">
                    <label className='col-form-label'>Service type</label>
                </div>
                <div className="col-12 col-sm-5">
                    <SelectStyled
                        value={formik.values?.stops?.serviceType ? formik.values?.stops?.serviceType : 'Exclusive'}
                        name='stops.serviceType'
                        onChange={formik.handleChange} 
                        className={classes.select}
                        displayEmpty={true}
                        inputProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps,
                            style: stylesMUIInput(fontSize),
                        }}
                        style={{
                            color: '#5E6278',
                        }}
                        fontSize={fontSize}
                    >
                        {
                            ['Exclusive', 'Partial']?.map((stack, index) => {
                                return <MenuItem key={index} value={stack}>{stack}</MenuItem>
                            })
                        }
                    </SelectStyled>
                </div>
                <div className="col-12 col-sm-2">
                    <label className='col-form-label'>Stops</label>
                </div>
                <div className="col-12 col-sm-3 d-flex align-items-center">
                    <input
                        className="form-control me-1"
                        readOnly={true}
                        autoComplete='off'
                        value={formik.values?.stops?.stops?.length || ''}
                        disabled={newBookingValues?.dispatched}
                    />
                    <div>
                        <IconButton onClick={() => setOpenStopsModal(true)}>
                            <AccessTimeIcon/>
                        </IconButton>
                    </div>
                </div>

                {/* //////////////////////////////////////////////////// */}

                <div className="col-12 col-sm-2 mt-0 mt-sm-4">
                    <label className='col-form-label'>Equipment</label>
                </div>
                <div className="col-12 col-sm-5 mt-0 mt-sm-4">
                    <TextField
                        select
                        label=" "
                        value={formik.values?.stops?.equipment ? formik.values?.stops?.equipment : []}
                        name='stops.equipment'
                        onChange={formik.handleChange}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps,
                            multiple: true
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        {
                            equipments?.map((equipment, index) => {
                                return <MenuItem key={index} value={equipment.name}>{equipment.name}</MenuItem>
                            })
                        }
                    </TextField>
                </div>
                <div className="col-12 col-sm-2 mt-0 mt-sm-4">
                    <label className='col-form-label'>Length</label>
                </div>
                <div className="col-12 col-sm-3 d-flex align-items-center mt-0 mt-sm-4">
                    <input
                        type="number"
                        className="form-control me-4"
                        autoComplete='off'
                        {...formik.getFieldProps('stops.length')}
                        disabled={newBookingValues?.dispatched}
                    />
                    <div className="me-4">ft.</div>
                </div>
            </div>
            <StopsModal
                open={openStopsModal}
                setOpen={setOpenStopsModal}
                formik={formik}
            />
        </>
    )
}

export default Tabs