import styled, { keyframes}  from 'styled-components';

const rotate = keyframes`
  0% {
    filter: opacity(0%);
  }
  20% {
    filter: opacity(90%);
  }
  40% {
    filter: opacity(0%);
  }
  60% {
    filter: opacity(90%);
  }
  75% {
    filter: opacity(0%);
  }
  90% {
    filter: opacity(90%);
  }
    100% {
    filter: opacity(0%);
    }
`;

export const Pulse = styled.p`
    animation: ${rotate} 2s linear infinite;
    font-size: 18px;
    color: #009EF7
`