import styled from 'styled-components';

export const ButtonStyled = styled.button`
    cursor: ${props => (props.saved ? 'auto' : 'pointer')};
    transition: background-color 0.5s ease
`

export const BoldSpan = styled.span`
    font-weight: 500
`

export const DivFileStyled = styled.div`
    border-radius: 0.475rem;
    background-color: #E4E6EF
`