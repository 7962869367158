import DateInput from '../../components/QuotesGridPage/DateInput'

const ReportBuilder = ({formik, initialValues, title, setDataToUse}) => {   
    return (
        <>
            <div className="col-12">
                <label className='col-form-label pt-0' style={{fontSize: 18}}>Report Details : {title}</label>
            </div>
            <div className="col-12 d-none d-sm-block">
                <div className="row">
                    <div className="col-2">
                        <label className='col-form-label pb-0'>Date From</label>
                    </div>
                    <div className="col-2">
                        <label className='col-form-label pb-0'>Date To</label>
                    </div>
                </div>
            </div>
            <div className="col-12">
            <div className="row align-items-center">
                <div className="col-6 col-sm-2">
                    <label className='col-form-label'>
                        <DateInput
                            data={formik.values.date}
                            dateFor='from'
                            setData={(a, b) => formik.setFieldValue(a, b)}
                            inputForHome={true}
                        />
                    </label>
                </div>
                <div className="col-6 col-sm-2">
                    <label className='col-form-label'>
                        <DateInput
                            data={formik.values.date}
                            dateFor='to'
                            setData={(a, b) => formik.setFieldValue(a, b)}
                            inputForHome={true}
                        />
                    </label>
                </div>
                <div className="col-12 col-sm-8 d-flex align-items-center">
                    <label className='form-check form-check-solid me-5'>
                        <span className='form-check-label'>
                            Exclude Weekends
                        </span>
                        <input className='form-check-input' type='checkbox' checked={formik.values.excludeWeekends} onChange={formik.handleChange} name="excludeWeekends"/>
                    </label>
                    <button onClick={() => formik.handleSubmit()} className='btn btn-primary me-4' type="submit">Run Report</button>
                    <button
                        onClick={() => { 
                            formik.handleReset(initialValues)
                            setDataToUse([])
                        }}
                        className='btn btn-secondary'
                    >
                        Clear
                    </button>
                </div>
            </div>
            </div>
        </>
    )
}

export default ReportBuilder