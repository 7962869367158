import { DivPaperStyled, ModalStyled, Title } from '../../Modals.styled'
import { modalStyles } from '../../ModalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers';

const ConfirmTimedOutVendorsModal = ({open, setOpen, vendorsTimedOut, keepWaitingVendors, formikValues}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <Title>The below vendors haven't finished rating:</Title>
                                </div>
                                <div className="col-12 d-flex justify-content-center mb-3" style={{maxHeight: 450, overflow: 'scroll'}}>
                                    {vendorsTimedOut.map((vendor) => (
                                        <>- {vendor?.name || vendor?.carrierName}<br/></>
                                    ))}
                                </div>
                                <div className="col-12 d-flex justify-content-center mb-1">
                                    <Title>Do you wish to keep waiting?</Title>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            keepWaitingVendors()
                                            setOpen(false)
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ConfirmTimedOutVendorsModal