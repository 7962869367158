import { useEffect, useState } from 'react'
import { DivPaperStyled, ModalStyled } from '../../Modals.styled'
import { Backdrop, Fade, IconButton, Paper, Tooltip } from '@mui/material'
import { KTSVG } from '../../../../_metronic/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { modalStyles } from '../../ModalStyles'
import AddNewStopModal from './AddNewStopModal'
import MaterialTable from '@material-table/core'
import { tableStyles } from '../../LTLandVolumeTLPages/Tables/TableStyles'
import DeleteIcon from '@mui/icons-material/Delete';
import { MTableBodyRowStyled } from '../../QuotesGridPage/Table/Table.styled'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteStopConfirmModal from '../../Modals/DeleteStopConfirmModal/DeleteStopConfirmModal'

const StopsModal = ({open, setOpen, formik}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [openAddNewStopModal, setOpenAddNewStopModal] = useState(false)
    const [stops, setStops] = useState([])
    const classesTable = tableStyles()
    const [columns] = useState([
        {title: 'Stop #', field: 'stopNumber'},
        {title: 'Name', field: 'name'},
        {title: 'Country', field: 'country'},
        {title: 'Zipcode', field: 'zipcode'},
        {title: 'City', field: 'city'},
        {title: 'Contact', field: 'contact'},
        {title: 'Contact Phone', field: 'contactPhone', cellStyle: {width: 150, minWidth: 150}, headerStyle: {width: 150, minWidth: 150}},
        {title: 'Notes', field: 'notes'}
    ])    
    const [selectedRowId, setSelectedRowId] = useState('')
    const [openDeleteStopConfirmModal, setOpenDeleteStopConfirmModal] = useState(false)
    const [rowData, setRowData] = useState({id: ''})

    useEffect(() => {
        formik.setFieldValue('stops.stops', stops)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stops])

    const moveRows = (array, fromIndex, toIndex) => {
        const arrayCopy = [...array];
        const element = arrayCopy.splice(fromIndex, 1)[0];

        arrayCopy.splice(toIndex, 0, element);

        return arrayCopy.map((a, idx) => ({...a, stopNumber: idx + 1}));
    }

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DivPaperStyled className={classes.paperStopsModal}>
                        <div className="row modal-header p-0 pb-2 mb-5">
                            <div className="col-8">
                                <h5 className="modal-title">Stops</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <>
                            <div className='row'>
                                <div className="col-12 d-flex justify-content-end align-items-center mb-4">
                                    <Tooltip title={!selectedRowId || stops.length === 1 || selectedRowId === stops[0]?.id ? '' : "Move up"}>
                                        <IconButton
                                            className="me-2"
                                            style={{width: 35.5, height: 35.5}}
                                            disabled={!selectedRowId || stops.length === 1 || selectedRowId === stops[0]?.id}
                                            onClick={() => {
                                                setStops(moveRows(stops, stops?.map(e => e?.id).indexOf(selectedRowId), stops?.map(e => e?.id).indexOf(selectedRowId) - 1))
                                            }}
                                        >
                                            <ArrowUpwardIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={!selectedRowId || stops.length === 1 || selectedRowId === stops[stops.length - 1]?.id ? '' : "Move down"}>
                                        <IconButton
                                            className="me-4"
                                            style={{width: 35.5, height: 35.5}}
                                            disabled={!selectedRowId || stops.length === 1 || selectedRowId === stops[stops.length - 1]?.id}
                                            onClick={() => {
                                                setStops(moveRows(stops, stops?.map(e => e?.id).indexOf(selectedRowId), stops?.map(e => e?.id).indexOf(selectedRowId) + 1))
                                            }}
                                        >
                                            <ArrowDownwardIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => setOpenAddNewStopModal(true)}
                                    >
                                        New Stop
                                    </button>
                                </div>
                                <div className="col-12">
                                    <MaterialTable
                                        style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                                        columns={columns}
                                        data={stops}
                                        options={{
                                            thirdSortClick: false,
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            search: false,
                                            paging: false,
                                            filtering: false,
                                            searchFieldAlignment: 'left',
                                            draggable: false,
                                            rowStyle: (data, idx) => idx%2 !== 0 ? {
                                                backgroundColor: '#f4f4f4',
                                            } : {
                                                backgroundColor: '#ffff',
                                            },
                                            actionsCellStyle: {
                                                display:"flex",
                                                justifyContent: "center",
                                                alignItems: 'center',
                                                width: '100%'
                                            }
                                        }}
                                        actions={[
                                            (rowData: any) => ({
                                                icon: () => <DeleteIcon style={{color: '#F1416C', width: 21, height: 21}}/>,
                                                tooltip: 'Delete',
                                                onClick: (event, rowData: any) => {
                                                    setOpenDeleteStopConfirmModal(true)
                                                    setRowData(rowData)
                                                }
                                            })
                                        ]}
                                        components={{
                                            Container: (props) => <Paper className={classesTable.table} {...props}/>,
                                            Row: rowProps => <MTableBodyRowStyled
                                                                {...rowProps}
                                                                onRowClick={(e) => {
                                                                    if(selectedRowId === rowProps.data.id){
                                                                        setSelectedRowId('')
                                                                    }else{
                                                                        setSelectedRowId(rowProps.data.id)
                                                                    }
                                                                }}
                                                                selected={selectedRowId === rowProps.data.id}
                                                             />,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                    </DivPaperStyled>
                </Fade>
            </ModalStyled>
            <AddNewStopModal
                open={openAddNewStopModal}
                setOpen={setOpenAddNewStopModal}
                stops={stops}
                setStops={setStops}
            />
            <DeleteStopConfirmModal
                open={openDeleteStopConfirmModal}
                setOpen={setOpenDeleteStopConfirmModal}
                stops={stops}
                setStops={setStops}
                rowData={rowData}
            />
        </>
    )
}

export default StopsModal