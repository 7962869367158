import { Checkbox, ListItemText, MenuItem, Select, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStyles } from '../../LTLandVolumeTLPages/SelectIconStyles'
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { ShowHideColumnsProps } from '../../../pages/quotes-grid/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import axios from 'axios';
import { actionTypes } from '../../../modules/auth/redux/PagesRedux';

const DEFAULTS = '/applet/v1/defaults'

const ShowHideColumns = ({columns, setColumns, disabled, columnsFor, defaults}: ShowHideColumnsProps) => {
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number 
    let classes = useStyles({disabled: disabled, fontSize: fontSize})
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [columnsChunks, setColumnsChunks] = useState([columns.slice(0, 9), columns.slice(9, 18), columns.slice(18)])

    const menuProps = {
        classes: {
            list: classes.list
        }
    }    

    const handleShowColumn = (e) => {
        let newColumns

        if(columnsFor !== 'Tracking Grid'){
            newColumns = columns.map(column => {
                if(column.title === e.target.value[0]){
                    return {...column, hidden: !column.hidden}
                }
    
                return column
            })
            
            setColumns(newColumns)
        }else{
            newColumns = columns.map(column => {
                if(column.title === e.title){
                    return {...column, hidden: !column.hidden}
                }
    
                return column
            })
        }
    
        axios.post(DEFAULTS, {
            ...defaults,
            "bookings": newColumns.map((column, index) => (
                {
                  "name": column.field,
                  "order": index,
                  "hidden": column.hidden,
                }
            ))
        })

        dispatch({type: actionTypes.SetDefaults, payload: {...defaults, bookings: newColumns.map((column, index) => (
            {
              "name": column.field,
              "order": index,
              "hidden": column.hidden,
            }
        ))}})
        setColumns(newColumns)
    }
 
    useEffect(() => {
        setColumnsChunks([columns.slice(0, 11), columns.slice(11, 22), columns.slice(22, 31)])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns])

    return (
        <Tooltip title="Show Columns" open={tooltipOpen}>
            <Select
                IconComponent={ViewColumnIcon}
                className={classes.select}
                style={{ cursor: 'pointer' }}
                variant='standard'
                displayEmpty
                onChange={(e) => {
                    if(columnsFor !== 'Tracking Grid'){
                        handleShowColumn(e)
                    }
                }}
                value={[]}
                multiple
                MenuProps={menuProps}
                onMouseEnter={() => setTooltipOpen(true)}
                onMouseLeave={() => setTooltipOpen(false)}
                onOpen={() => setTooltipOpen(false)}
                disabled={disabled}
            >
                {
                    columnsFor !== 'Tracking Grid' ? 
                    columns.map((column) => (
                        <MenuItem key={column.title} value={column.title}>
                            <Checkbox checked={!column.hidden}/>
                            <ListItemText primary={column.title} />
                        </MenuItem>
                    ))
                    : <div style={{width: fontSize === 19 ? 800 : 600, overflow: 'hidden'}}>
                        <div className="row">
                            {
                                columnsChunks.map((columnChunk, index) => (
                                    <div className="col-4" key={index}>
                                        {
                                            columnChunk.map((column) => (
                                                <MenuItem
                                                    key={column.title}
                                                    value={column.title}
                                                    onClick={() => handleShowColumn(column)}
                                                >
                                                    <Checkbox
                                                        checked={!column.hidden}
                                                    />
                                                    <ListItemText primary={column.title} />
                                                </MenuItem>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </Select>
        </Tooltip>
    ) 
}

export default ShowHideColumns