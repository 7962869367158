import { AccessorialButton } from './Accesorials.styled';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { AccessorialsColumnsProps } from '../../../pages/ltl/models';
import AccessorialsModal from '../../AccessorialsModal';

const AccessorialsColumns = ({accessorials, handleDelete, selectAcc, accessorialType, selectedAcc, openAccModal, setOpenAccModal, setAccessorial, setSearchAccessorials, setOpenInsuranceModal, formikValues, setOpenClearRateToEditModal, setOpen, forDefaults}: AccessorialsColumnsProps) => {
    return (
        <>
            <div className="col-12 col-sm-4 ps-0 mb-5 mb-sm-0">
                <div className="col-12 col-form-label ps-5 pt-5">{accessorialType}</div>
                {
                    accessorials?.filter(acc => acc.category === accessorialType).length === 0
                    && <div style={{padding: '5.2px 0px 5.2px 16.25px', color: "#a1a5b6"}}>No accessorials</div>
                }
                {accessorials?.map((accessorial, index) => {
                    return (
                        accessorial.category === accessorialType && (
                            <AccessorialButton
                                key={index}
                                onClick={() => {
                                    if(formikValues && formikValues?.carrierInformation?.name){
                                        setOpenClearRateToEditModal(true)
                                        setOpen(false)
                                    }else{
                                        if(accessorial?.code === 'INS' && !forDefaults){
                                            setOpenInsuranceModal(true)
                                        }
                                        setSearchAccessorials('')
                                        const accValueRequired = accessorial?.valueRequired
                                        if(accValueRequired?.required){
                                            setAccessorial(accessorial)
                                            setOpenAccModal(true)
                                        }
                                        selectAcc(accessorial)
                                    }
                                }}
                                disabled={selectedAcc?.filter(acc => acc.id === accessorial.id).length > 0}
                                isSelected={selectedAcc?.filter(acc => acc.id === accessorial.id).length > 0}
                            >
                                <div className="row align-items-center">
                                    <div className="col-10 col-sm-9">
                                        {accessorial.nameShort}
                                    </div>
                                    {selectedAcc?.filter(acc => acc.id === accessorial.id).length > 0 &&
                                        <div className="col-2 col-sm-2 justify-content-end">
                                            <IconButton className="p-0" onClick={() => {
                                                handleDelete(accessorial)
                                            }}>
                                                <ClearIcon fontSize="small"/>
                                            </IconButton>
                                        </div>
                                    }
                                </div>
                                <AccessorialsModal
                                    open={openAccModal}
                                    setOpen={setOpenAccModal}
                                    data={accessorial?.valueRequired}
                                />
                            </AccessorialButton>
                        )
                        
                    );
                })}
            </div> 
        </>
    )
}

export default AccessorialsColumns