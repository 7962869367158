import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import moment from 'moment'

const PickupAndDelivery = ({title}) => {
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any

    return (
        <>
            <div className="col-6 col-sm-4 d-flex align-items-center mt-2 mt-sm-0">
                <label className='col-form-label me-3 p-0'>{title}</label>
                <div className='me-3'>
                    {title.includes('Pickup')
                    ? tracking?.pickupDate?.date ? moment(tracking?.pickupDate?.date).format('MM/DD/YY') : '-'
                    : tracking?.deliveryDate?.date ? moment(tracking?.deliveryDate?.date).format('MM/DD/YY') : '-'}
                </div>
            </div>
            <div className="col-6 col-sm-8 d-flex align-items-center mt-2 mt-sm-0">
                <label className='col-form-label me-3 p-0'>From</label>
                <div className='me-3'>
                    {title.includes('Pickup')
                    ? tracking?.pickupDate?.from ? moment(tracking?.pickupDate?.from, "HH:mm:ss").format('HH:mm') : '-'
                    : tracking?.deliveryDate?.from ? moment(tracking?.deliveryDate?.from, "HH:mm:ss").format('HH:mm') : '-'}
                </div>
                <label className='col-form-label me-3 p-0'>To</label>
                <div>
                    {title.includes('Pickup')
                    ? tracking?.pickupDate?.to ? moment(tracking?.pickupDate?.to, "HH:mm:ss").format('HH:mm') : '-'
                    : tracking?.deliveryDate?.to ? moment(tracking?.deliveryDate?.to, "HH:mm:ss").format('HH:mm') : '-'}
                </div>
            </div>
        </>
    )
}

export default PickupAndDelivery