import { markupCalculations } from "../markupCalculations"

export const getCheaper = (newArray, index, markups) => {
    let cheaper = newArray[index].Rates.length && newArray[index].Rates?.reduce((prev, current) => { 
        return (prev.total < current.total) ? prev : (current.total > 0 ? current : prev)
    })

    newArray[index].Rate = cheaper
    newArray[index].Carrier = cheaper.name
    newArray[index].Transit = cheaper.transitDays
    newArray[index].Total = cheaper.total

    if(cheaper?.billTo?.total){
        newArray[index].BillTo = cheaper?.billTo?.total
    }else{
        let markup = markups.filter(markup => markup.carrier?.name === cheaper.name)[0]
        let allVendorsMarkup = markups.filter(markup => markup.carrier?.name === 'All Vendors')[0]
        let min = parseInt(markup?.min?.replace(",", ""))
        let max = parseInt(markup?.max?.replace(",", ""))
        let rate = parseInt(markup?.rate?.replace(",", ""))
        markupCalculations(allVendorsMarkup, min, max, rate, cheaper, markup, newArray, index)
    }

    if(!newArray[index]?.Class){
        if(cheaper?.freightInfoReclassed?.length > 1){
            cheaper.freightInfoReclassed.forEach((freightInfo, idx) => {
                newArray[index].FreightInfo[idx].Class = freightInfo.class
            })
            newArray[index].Class = Math.max(...newArray[index].FreightInfo.map(freight => freight.Class || 0)) || ""
        }else{
            newArray[index].Class = cheaper.freightInfoReclassed.length && cheaper.freightInfoReclassed[0]?.class
        }
    }else{
        cheaper?.freightInfoReclassed?.forEach((freightInfo, idx) => {
            if(newArray[index]?.FreightInfo?.length){
                if(!newArray[index]?.FreightInfo[idx]?.Class){
                    newArray[index].FreightInfo[idx].Class = freightInfo.class
                }
            }
        })
    }
}