import moment from 'moment'
import { TimeInputProps } from './models'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import { TextFieldStyled } from './TimeInput.styled'

const TimeInput = ({data, setData, name, setFormikData, className, error, errorLocal, setErrorLocal, time}: TimeInputProps) => {
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
  const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
  const classes = useStyles({props: {}})
/*   const [errorLocal, setErrorLocal] = useState(false) */

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          disabled={newBookingValues.dispatched}
          ampm={false}
          value={data}
          renderInput={(params) => {
            return (
              <TextFieldStyled
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: {fontSize: 14, fontFamily: 'Poppins', fontWeight: 500, color:"#5E6278 !important", height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)', border: 'red !important' },
                }}
                className={`${className} ${classes.timePickerMUI}`}
                error={error ? error : errorLocal[time]}
              />
            )
          }}
          onChange={(e: any) => {
            let regex = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
            if(e !== null && !regex.test(moment(e).format('HH:mm'))){
              setErrorLocal({...errorLocal, [time]: true})
            }else if(regex.test(moment(e).format('HH:mm')) || e === null){
              setErrorLocal({...errorLocal, [time]: false})
            }

            setData(e || null)
            setFormikData(name, e ? moment(e).format('HH:mm') : '')
          }}
        />
      </LocalizationProvider>
    </>
  )
}

export default TimeInput