import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { NewBooking } from './NewBooking/NewBooking';
import { UserModel } from '../../modules/auth/models/UserModel';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import { BatchBooking } from './BatchBooking';

export const Book = () => {

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <Routes>
      {
        user?.allowBooking ? 
          <Route
            path='new-booking'
            element={<NewBooking/>}
          />
        : null
      }
      {
        user?.allowBatchBooking ?
          <Route
            path='batch-booking'
            element={<BatchBooking/>}
          />
        : null
      }
    </Routes> 
  )
}
