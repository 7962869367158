import { useEffect, useState } from 'react'
import { ModalStyled } from '../../Modals.styled'
import { Backdrop, Fade } from '@mui/material'
import { KTSVG } from '../../../../_metronic/helpers'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup' 
import { modalStyles } from '../../ModalStyles'
import { DocumentStyled } from './DocumentsModal.styled'
import _ from 'lodash';
import { DocumentsModalProps } from '../../../pages/track/models'
import { NoOptionsText } from '../../LTLandVolumeTLPages/LTLAndVolumeTLPages.styled'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils';
import { isTokenExp } from '../../../helpers/isTokenExp'
import { actionTypes } from '../../../modules/auth/redux/PagesRedux'
import EmailDocuments from './EmailDocuments'
import UploadDocument from './UploadDocument'
import FileViewer from 'react-file-viewer';
import { saveAs } from "file-saver";
import { UserModel } from '../../../modules/auth/models/UserModel'

const DocumentsModal = ({open, setOpen, documents, forInvoices, forQuotes, selectedRow, setDocuments}: DocumentsModalProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any
    const classesModal = modalStyles({fontSize: fontSize})
    const [selectedDocument, setSelectedDocument] = useState({} as {url: string} | any)
    const [openEmailDocumentsModal, setOpenEmailDocumentsModal] = useState(false)
    const [openUploadDocumentsModal, setOpenUploadDocumentsModal] = useState(false)
    const dispatch = useDispatch()    

    const saveAsLocal = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = 'test'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleClose = () => {
        setOpen(false)
        setSelectedDocument({})
    }

    const downloadAll = () => {
        const zip = new JSZip();
        let count = 0;

        documents.forEach(function(document){
            JSZipUtils.getBinaryContent(document.url, function (error, data) {
                if(error){
                    if(error?.response?.status === 401){
                        isTokenExp()
                    }else{
                        dispatch({type: actionTypes.SetAxiosErrors, payload: error?.response?.data?.error?.message})
                    }
                }

                zip.file(`${documents.filter(doc => doc.documentExtension === document.documentExtension && doc.type === document.type).length > 1 ? `${document.type}${count}` : document.type}.${document.documentExtension}`, data, {binary:true})
                count = count + 1;

                if (count === documents.length) {
                    zip.generateAsync({type:'blob'}).then(function(content) {
                        saveAs(content, 'Documents.zip');
                    });
                }
            });
            
        });
    }

    const handleDocumentOver = (document) => {
        setSelectedDocument(document)
    }

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classesModal.paperDocumentsModal} style={{visibility: 'visible', opacity: '100%'}}>
                        <div className="row modal-header p-0 pb-2 mb-5">
                            <div className="col-8">
                                {
                                    forQuotes
                                    ? <h5 className="modal-title">{`Documents for Quote #${selectedRow?.quoteNumber}`}</h5>
                                    : <h5 className="modal-title">{!forInvoices ? `Documents for BOL #${tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber}` : 'Shipment Invoices'}</h5>
                                }
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <label className='col-form-label py-0 mb-3'>System</label>
                                    {   
                                        forQuotes && documents.map(doc => (
                                            <DocumentStyled key={doc.url} className='d-flex align-items-center mb-1' onClick={() => saveAsLocal(doc.url)} onMouseOver={() => handleDocumentOver(doc)}>
                                                <KTSVG className="svg-icon svg-icon-2 ms-1 me-2" style={{color: '#0095E8'}} path='/media/icons/bootstrap/file-earmark-pdf-fill.svg'/>
                                                <div>Customer Quote</div>
                                            </DocumentStyled>
                                        ))
                                    }
                                    {
                                        !forQuotes ? 
                                        <>
                                            {documents.filter(doc => doc.type === 'LBL' || doc.type === 'BOL' || doc.type ===  'QUO' || doc.type === 'INV' || doc.type === 'CLBL').length === 0 && <NoOptionsText style={{marginLeft: 5}}>No documents</NoOptionsText>}
                                            {
                                                documents.filter(doc => doc.type === 'LBL' || doc.type === 'BOL' || doc.type ===  'QUO' || doc.type === 'CLBL').map(doc => (
                                                    <DocumentStyled key={doc.url} className='d-flex align-items-center mb-1' onClick={() => saveAsLocal(doc?.url)} onMouseOver={() => handleDocumentOver(doc)}>
                                                        <KTSVG className="svg-icon svg-icon-2 ms-1 me-2" style={{color: '#0095E8'}} path='/media/icons/bootstrap/file-earmark-pdf-fill.svg'/>
                                                        <div>{doc.name}</div>
                                                    </DocumentStyled>
                                                ))
                                            }
                                            <label className='col-form-label py-0 mt-6 mb-3'>Additional</label>
                                            {documents.filter(doc => doc.type !== 'LBL' && doc.type !== 'BOL' && doc.type !==  'QUO' && doc.type !== 'INV' && doc.type !== 'CLBL').length === 0 && <NoOptionsText style={{marginLeft: 5}}>No documents</NoOptionsText>}
                                            {
                                                documents.filter(doc => doc.type !== 'LBL' && doc.type !== 'BOL' && doc.type !==  'QUO' && doc.type !==  'INV' && doc.type !== 'CLBL').map(doc => (
                                                    <DocumentStyled key={doc.url} className='d-flex align-items-center mb-1' onClick={() => saveAsLocal(doc?.url)} onMouseOver={() => handleDocumentOver(doc)}>
                                                        <KTSVG className="svg-icon svg-icon-2 ms-1 me-2" style={{color: '#0095E8'}} path='/media/icons/bootstrap/file-earmark-pdf-fill.svg'/>
                                                        <div>{doc.name}</div>
                                                    </DocumentStyled>
                                                ))
                                            }
                                            {
                                                user?.displayInvoiceInformation ?
                                                <>
                                                    <label className='col-form-label py-0 mt-6 mb-3'>Invoices</label>
                                                    {documents.filter(doc => doc.type === 'INV').length === 0 && <NoOptionsText style={{marginLeft: 5}}>No documents</NoOptionsText>}
                                                    {
                                                        documents.filter(doc => doc.type === 'INV').map(doc => (
                                                            <DocumentStyled key={doc.url} className='d-flex align-items-center mb-1' onClick={() => saveAsLocal(doc?.url)} onMouseOver={() => handleDocumentOver(doc)}>
                                                                <KTSVG className="svg-icon svg-icon-2 ms-1 me-2" style={{color: '#0095E8'}} path='/media/icons/bootstrap/file-earmark-pdf-fill.svg'/>
                                                                <div>{doc.name}</div>
                                                            </DocumentStyled>
                                                        ))
                                                    }
                                                </>
                                                : null
                                            }
                                        </>
                                        : null
                                    }
                                </div>
                                <div className="col-8" style={{maxWidth: '459.66px', maxHeight: '517px', minHeight: '517px', overflow: 'auto', paddingRight: 0}}>
                                    { _.isEmpty(selectedDocument) === false 
                                        ? documents.map((doc, index) => (
                                            (selectedDocument === doc) &&
                                            <div style={{'height': '505px', width: '430.16px'}}>
                                                <FileViewer
                                                    key={doc.url}   
                                                    fileType={doc.documentExtension ? doc.documentExtension : 'pdf'}
                                                    filePath={doc.url}
                                                />
                                            </div>
                                        ))
                                        : <>
                                            <div style={{'height': '505px', width: '430.16px', backgroundColor: '#E4E6EF', borderRadius: '0.475rem'}} className='d-flex align-items-center justify-content-center'>
                                                No Preview Available
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="row mb-3 mt-5">
                                <div className="col-12 d-none d-sm-flex justify-content-end">
                                    {
                                        !forInvoices &&
                                        <>
                                            {  
                                                !forQuotes &&
                                                <button
                                                    className="btn btn-primary me-4"
                                                    onClick={() => downloadAll()}
                                                >
                                                    Download All
                                                </button>
                                            }
                                            <button
                                                className="btn btn-primary me-4"
                                                onClick={() => setOpenEmailDocumentsModal(true)}
                                            >
                                                Email Documents
                                            </button>
                                            {  
                                                !forQuotes &&
                                                <button
                                                    className="btn btn-primary me-4"
                                                    onClick={() => setOpenUploadDocumentsModal(true)}
                                                >
                                                    Upload Document
                                                </button>
                                            }
                                        </>
                                    }
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => handleClose()}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className="col-12 d-sm-none justify-content-end">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-end">
                                            {
                                                !forInvoices &&
                                                <>
                                                    <button
                                                        className="btn btn-primary me-4"
                                                        onClick={() => downloadAll()}
                                                    >
                                                        Download All
                                                    </button>
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => setOpenEmailDocumentsModal(true)}
                                                    >
                                                        Email Documents
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12  d-flex align-items-center justify-content-end">
                                            {
                                                !forInvoices &&
                                                <button
                                                    className="btn btn-primary me-4"
                                                    onClick={() => setOpenUploadDocumentsModal(true)}
                                                >
                                                    Upload Document
                                                </button>

                                            }
                                            <button
                                                className="btn btn-secondary"
                                                onClick={() => handleClose()}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </Fade>
            </ModalStyled>
            <EmailDocuments
                open={openEmailDocumentsModal}
                setOpen={setOpenEmailDocumentsModal}
                documents={documents}
                selectedRow={selectedRow}
            />
            <UploadDocument
                open={openUploadDocumentsModal}
                setOpen={setOpenUploadDocumentsModal}
                selectedRow={selectedRow}
                setDocuments={setDocuments}
            />
        </>
    ) 
}

export default DocumentsModal