import MaterialTable from '@material-table/core'
import { CircularProgress, IconButton, Menu, MenuItem, Paper, Select, TablePagination, Tooltip } from '@mui/material'
import { tableStyles } from '../../LTLandVolumeTLPages/Tables/TableStyles'
import { KTSVG } from '../../../../_metronic/helpers';
import React, { useEffect, useRef, useState } from 'react';
import ConfirmReRunQuote from '../Modals/ConfirmReRunQuote';
import { TableProps } from '../../../pages/quotes-grid/models';
import EmailIcon from '@mui/icons-material/Email';
import RequestInformationModal from '../../ShippingItemsPage/RequestInformationModal';
import axios from 'axios';
import TrackingModal from '../../TrackingGridPage/TrackingModal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { RootState } from '../../../../setup';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { actionTypes } from '../../../modules/auth/redux/PagesRedux';
import { TypeOptions } from '../../../data/TypeOptions';
import { CountryOptions } from '../../../data/CountryOptions';
import ViewDataModal from '../../TrackingGridPage/ViewDataModal';
import { CircularProgressStyled, MTableBodyRowStyled } from './Table.styled';
import { useNavigate } from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import moment from 'moment';
import DocumentsModal from '../../TrackingGridPage/DocumentsModal/DocumentsModal';
import BookIcon from '@mui/icons-material/Book';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStyles } from '../../LTLandVolumeTLPages/SelectIconStyles';
import ConfirmDeleteShipmentModal from './ConfirmDeleteShipmentModal';
import ConfirmDuplicateShipmentModal from './ConfirmDuplicateShipmentModal';

const GET_TRACKINGS = '/applet/v1/book'
const HISTORYTRACKINGS = '/api/v1/track'
const DEFAULTS = '/applet/v1/defaults'
const GET_BOOk = '/applet/v1/book'
const DOCUMENTS = '/applet/v1/document'
const GET_QUOTES = '/api/v1/database/quote'

const Table = ({columns, setColumns, data, loading, setLoading, setPageNumber, rowsPerPage, setRowsPerPage, pageNumber, totalResults, tableFor, defaults, filters, filtering, defaultCall, updateFilters}: TableProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const dispatch = useDispatch()
    const classes = tableStyles()
    const navigate = useNavigate()
    const [loadingTrack, setLoadingTrack] = useState(false)
    const [openReRunModal, setOpenReRunModal] = useState(false)
    const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false)
    const [openTrackingModal, setOpenTrackingModal] = useState(false)
    const [openViewDataModal, setOpenViewDataModal] = useState(false)
    const [historyTrackings, setHistoryTrackings] = useState([])
    const [columnsOrder, setColumnsOrder] = useState([])
    const [rowData, setRowData] = useState({} as any)
    const [loadingDocuments, setLoadingDocuments] = useState(false)
    const [documents, setDocuments] = useState([])
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState({BOLId: null, BOLNumber: ''})
    const [openConfirmDeleteShipmentModal, setOpenConfirmDeleteShipmentModal] = useState(false)
    const [openConfirmDuplicateShipmentModal, setOpenConfirmDuplicateShipmentModal] = useState(false)
    const [duplicateShipmentInfo, setDuplicateShipmentInfo] = useState({
      copyBOLSpecialInstructions: false,
      copyExternalNotes: false
    })
    const classesSelect = useStyles({disabled: false, fontSize: fontSize})

    const tableRef = useRef();

    const handleColumnDrag = (e) => {
      let newArray = [...columns];

      newArray.sort(function(a, b){return a.tableData.columnOrder-b.tableData.columnOrder})

      setColumnsOrder(newArray)
    }

    const saveAsLocal = (url) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = 'test'
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    const viewOnlyShipment = (rowData) => {
      let tracking = {}

      axios.get(`${GET_TRACKINGS}/${rowData.BOLId}`)
      .then((results) => {
        setSelectedRow(rowData)
        setLoading(false)
        setOpenViewDataModal(true)
        let data = results?.data?.data?.results
        data.UOM = 'US'
        tracking = {
          thirdParty: data.thirdParty,
          rowData: rowData,
          accessorials: [...data.accessorials, ...data.accessorialsWithData.map(acc => acc.code)],
          additionalInformation: data.additionalInformation,
          brokerInformation: data.brokerInformation,
          specialInstructions: data?.BOLInstructions,
          shipmentNotes: data?.BOLRemarks,
          vendorId: data?.vendor?.id,
          freightInfo: [...data.freightInfo.map((freight) => ({
            id: uuidv4(),
            qty: freight.qty,
            dimType: TypeOptions.filter(t => t.value === freight?.dimType)[0],
            STC: freight.STC,
            weight: freight.weight,
            each: freight.weightType === 'each' ? 'Each' : 'Total',
            L: freight?.length,
            W: freight?.width,
            H: freight?.height,
            class: freight?.class?.toString(),
            commodity: freight?.commodity,
            nmfc:  freight?.nmfc,
            weightus: data.UOM === 'US' ? freight?.weight : freight?.weight / 0.45359237,
            Lus: data.UOM === 'US' ? freight?.length : freight?.length / 2.54,
            Wus: data.UOM === 'US' ? freight?.width : freight?.width / 2.54,
            Hus: data.UOM === 'US' ? freight?.height : freight?.height / 2.54,
            totalCft: freight.qty*(freight.length*freight.width*freight.height/1728),
            hazmat: freight.UN ? true : false,
            UN: freight.UN
          }))],
          freightInfoUOM: {
            value: data.UOM,
            oldValue: ''
          },
          shipper: {
            address: data.shipper.address1,
            address1: data.shipper.address2,
            country: data?.shipper?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === data?.shipper?.country)?.Code || "US" : data?.shipper?.country,
            city: rowData.originCity,
            name: rowData.shipperName,
            state: rowData.originState,
            zipcode: rowData.originZipcode,
            emails: data.shipper?.email,
            phone: data.shipper?.phone,
            contactPhone: data.shipper?.contactPhone,
            contact: data.shipper?.contact,
            referenceNumber: rowData.shipperReferenceNumber
          },
          consignee: {
            address: data.consignee.address1,
            address1: data.consignee.address2,
            country: data?.consignee?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === data?.consignee?.country)?.Code || "US" : data?.consignee?.country,
            city: rowData.destinationCity,
            name: rowData.consigneeName,
            state: rowData.destinationState,
            zipcode: rowData.destinationZipcode,
            phone: data.consignee?.phone,
            emails: data.consignee?.email,
            contactPhone: data.consignee?.contactPhone,
            contact: data.consignee?.contact,
            referenceNumber: rowData.consigneeReferenceNumber,
            isPort: rowData.consignee?.isPort,
            isZipCode: rowData.consignee?.isZipcode,
          },
          pickupDate: {
            type: 'PickupOn',
            date: rowData.estimatedPickupDate,
            from: data.pickupInformation.timeFrom,
            to: data.pickupInformation.timeTo,
          },
          deliveryDate: {
            type: 'DeliveryBy',
            date: rowData.estimatedDeliveryDate,
            from: data.deliveryInformation.timeFrom,
            to: data.deliveryInformation.timeTo,
          },
          quoteNumber: rowData.customerQuoteNumber,
          url: data.BOLDocumentURL,
          carrierInformation: {
            name: rowData.carrierName,
            total: rowData.totalDue !== 'N/A' ? parseFloat(rowData.totalDue.replace('* ', '')) : 'N/A',
            transitDays: data.vendor.transitDays,
            serviceLevel: rowData.carrierServiceLevel,
            rateRemarks: [data?.accountingInformation?.remarks],
            iconUrl: data.vendor.icon
          }
        }
        dispatch({type: actionTypes.SetTracking, payload: tracking})
      }).catch(error => {
        setLoading(false)
        checkErrorAPICall(error, dispatch, 'Get Tracking')
      })
    }

    const selectBOL = (result, duplicate) => {
      let bookCall = {} as any
      let book = result
      
      axios.get(`${GET_BOOk}/${result.BOLId}`)
      .then((results) => {
        bookCall = results.data.data.results
  
        let payload = {      
          thirdParty: bookCall.thirdParty,
          BOLDocumentURL: bookCall.BOLDocumentURL,
          BOLId: !duplicate ? bookCall.BOLId : '',
          vendorSCAC: !duplicate ? bookCall?.vendor?.SCAC : '',
          vendorId: !duplicate ? bookCall?.vendor?.id : '',
          BOLNumber: !duplicate ? book.BOLNumber : '',
          BOLPrefix: !duplicate ? bookCall.BOLPrefix : '',
          PRONmbr: !duplicate ? book.carrierPRO : '',
          mode: bookCall.category,
          emergencyContact: bookCall.emergencyContact,
          emergencyPhone: bookCall.emergencyPhone,
          thirdPartyReferenceNumber: book?.billPartyReferenceNumber,
          quoteNumber: !duplicate ? bookCall?.accountingInformation?.customerQuoteNumber : '',
          fromSearch: true,
          additionalInformation: bookCall.additionalInformation,
          brokerInformation: bookCall.brokerInformation,
          specialInstructions: duplicate ? (duplicateShipmentInfo?.copyBOLSpecialInstructions ? bookCall?.BOLInstructions : '') : bookCall?.BOLInstructions,
          shipmentNotes: duplicate ? (duplicateShipmentInfo?.copyExternalNotes ? bookCall?.BOLRemarks : '') : bookCall?.BOLRemarks,
          carrierInformation: !duplicate ? {
            name: book.carrierName || '',
            total: book.totalDue !== 'N/A' ? parseFloat(book.totalDue.replace('* ', '')) : 'N/A',
            transitDays: bookCall.vendor.transitDays || '',
            serviceLevel: book.carrierServiceLevel || '',
            rateRemarks: [bookCall?.accountingInformation?.remarks],
            iconUrl: bookCall.vendor.icon || '',
            rateId: bookCall?.vendor?.id?.toString(),
            vendorId: bookCall?.vendor?.id
          } : {},
          pickupDate: {
            type: bookCall?.pickupInformation?.type === 'PO' ? 'PickupOn' : 'PickupBy',
            date: bookCall?.pickupInformation?.date ? new Date(bookCall?.pickupInformation?.date?.replace(/-/g, '/')) : "",
            from: bookCall?.pickupInformation?.timeFrom ? moment(bookCall?.pickupInformation?.timeFrom, "HH:mm:ss").format("HH:mm") : "",
            to: bookCall?.pickupInformation?.timeTo ? moment(bookCall?.pickupInformation?.timeTo, "HH:mm:ss").format("HH:mm") : ""
          },
          deliveryDate: {
            type: bookCall?.deliveryInformation?.type === 'DO' ? 'DeliveryOn' : 'DeliveryBy',
            date: bookCall?.deliveryInformation?.date ? new Date(bookCall?.deliveryInformation?.date?.replace(/-/g, '/')) : "",
            from: bookCall?.deliveryInformation?.timeFrom ? moment(bookCall?.deliveryInformation?.timeFrom, "HH:mm:ss").format("HH:mm") : "",
            to: bookCall?.deliveryInformation?.timeTo ? moment(bookCall?.deliveryInformation?.timeTo, "HH:mm:ss").format("HH:mm") : ""
          },
          dispatched: !duplicate ? book.dispatched : false,
          accessorials: [...bookCall.accessorials, ...bookCall.accessorialsWithData.map(acc => acc.code)],
          freightInfoUOM: {
            value: bookCall.UOM,
            oldValue: ''
          },
          freightInfo: bookCall?.freightInfo?.map(freight => {
            return {
              ...freight,
              dimType: TypeOptions.find(type => type.value === freight.dimType),
              L: freight.length || null,
              W: freight.width || null,
              H: freight.height || null,
              weightus: bookCall.UOM === 'US' ? freight?.weight : freight?.weight / 0.45359237,
              Lus: bookCall.UOM === 'US' ? freight?.length : freight?.length / 2.54,
              Wus: bookCall.UOM === 'US' ? freight?.width : freight?.width / 2.54,
              Hus: bookCall.UOM === 'US' ? freight?.height : freight?.height / 2.54,
              class: freight?.class?.toString(),
              each: freight.weightType === 'each' ? 'Each' : 'Total',
            }
          }),
          shipper: {
            address: bookCall.shipper.address1,
            address1: bookCall.shipper.address2,
            country: bookCall?.shipper?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === bookCall?.shipper?.country)?.Code || "US" : bookCall?.shipper?.country,
            city: bookCall?.shipper?.city,
            name: bookCall?.shipper?.name,
            state: bookCall?.shipper?.state,
            zipcode: bookCall?.shipper?.zipCode,
            email: bookCall.shipper?.email,
            phone: bookCall.shipper?.phone,
            contactPhone: bookCall.shipper?.contactPhone,
            contact: bookCall.shipper?.contact,
            referenceNumber: bookCall?.shipper?.referenceNumber,
            id: bookCall?.shipper?.id,
          },
          consignee: {
            address: bookCall.consignee.address1,
            address1: bookCall.consignee.address2,
            country: bookCall?.consignee?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === bookCall?.consignee?.country)?.Code || "US" : bookCall?.consignee?.country,
            city: bookCall?.consignee?.city,
            name: bookCall?.consignee?.name,
            state: bookCall?.consignee?.state,
            zipcode: bookCall?.consignee?.zipCode,
            phone: bookCall.consignee?.phone,
            email: bookCall.consignee?.email,
            contactPhone: bookCall.consignee?.contactPhone,
            contact: bookCall.consignee?.contact,
            referenceNumber: bookCall?.consignee?.referenceNumber,
            id: bookCall?.consignee?.id,
            isZipcode: bookCall.category ===  "Drayage" ? false : true,
            isPort: bookCall.category ===  "Drayage" ? true : false,
          },
          commodityInsurance: bookCall?.accountingInformation?.commodityInsurance,
          insuranceAmount: bookCall?.accountingInformation?.insuranceAmount,
          insuranceAddOn: bookCall?.accessorialsWithData?.find(acc => acc.code === 'INS')?.add10Per || false,
          insuranceFreight: bookCall?.accessorialsWithData?.find(acc => acc.code === 'INS')?.addCost || false,
        }
        
        dispatch({type: actionTypes.SetQuoteValues, payload: []})
        dispatch({type: actionTypes.SetNewBookingValues, payload: payload})
        navigate('/book/new-booking')
        setLoading(false)
      })
    }

    const openShipment = (e, rowData) => {
      switch (e.detail) {
        case 1:
          break;
        case 2:
          if(rowData.dispatched){
            setLoading(true)
            viewOnlyShipment(rowData)
          }else{
            if(user.allowBooking){
              setLoading(true)
              selectBOL(rowData, false)
            }
          }
          break;
      }
    }

    const getDocuments = (rowData) => {
      if(tableFor === 'Quotes'){
        setDocuments([{url: rowData.url, documentExtension: 'pdf', name: 'Customer Quote', quoteNumber: rowData?.quoteNumber}])
        setOpenDocumentsModal(true)
      }else{
        setLoadingDocuments(true)
  
        axios.get(`${DOCUMENTS}/${rowData?.BOLId}`).then(results => {
          setDocuments(results.data.data.results)
          setLoadingDocuments(false)
          setOpenDocumentsModal(true)
        }).catch(error => {
          setLoadingDocuments(false)
          checkErrorAPICall(error, dispatch, 'Get Documents')
        })
      }
    }

    useEffect(() => {
      if(columnsOrder.length){
        axios.post(DEFAULTS, {
          ...defaults,
          "bookings": columnsOrder.map((column, index) => (
              {
                "name": column.field,
                "order": index,
                "hidden": column.hidden,
              }
          ))
        })
        dispatch({type: actionTypes.SetDefaults, payload: {...defaults, bookings: columnsOrder.map((column, index) => (
          {
            "name": column.field,
            "order": index,
            "hidden": column.hidden,
          }
        ))}})
        setColumns(columnsOrder)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsOrder])

    const bookRate = (rowData) => {
      setLoading(true)
      axios.get(`${GET_QUOTES}/${rowData.quoteId}`).then(result => {
        setLoading(false)
        let quote = result.data.data.results

        let accessorials = []
        quote.accessorialsList.forEach(accessorial => {
          let acc = user.enabledAccessorials.filter(enabledAcc => enabledAcc.code === accessorial)
          accessorials.push(...acc)
        })

        dispatch({
          type: actionTypes.SetQuoteValues,
          payload: {
            freightInfoReclassed: [],
            freightInfo: quote?.freightInfo?.map(freight => {
              return {
                ...freight,
                dimType: TypeOptions.find(type => type.value === freight.dimType),
                L: freight.length || null,
                W: freight.width || null,
                H: freight.height || null,
                weightus: quote.UOM === 'US' ? freight?.weight : freight?.weight / 0.45359237,
                Lus: quote.UOM === 'US' ? freight?.length : freight?.length / 2.54,
                Wus: quote.UOM === 'US' ? freight?.width : freight?.width / 2.54,
                Hus: quote.UOM === 'US' ? freight?.height : freight?.height / 2.54,
                class: freight?.class?.toString(),
                each: freight.weightType === 'each' ? 'Each' : 'Total',
              }
            }),
            shipper: {
              ...quote.shipper,
              address: quote.shipper.address1,
              address1: quote.shipper.address2
            },
            consignee: {
              ...quote.consignee,
              address: quote.consignee.address1,
              address1: quote.consignee.address2
            },
            accessorials: {
              origin: accessorials.filter(acc => acc.category === 'Origin'),
              destination: accessorials.filter(acc => acc.category === 'Destination'),
              other: accessorials.filter(acc => acc.category === 'Other')
            },
            freightInfoUOM: {
              value: quote.UOM,
              oldValue: ''
            },
            carrierInformation: {
              ...rowData.vendor,
              total: quote.total,
              rateBreakdown: quote.totalBreakdown
            },
            origin: {
              ...quote.shipper,
              Country: CountryOptions.filter(con => con.CodePrimus === quote.shipper.country)[0].Code,
              City: quote.shipper.city,
              State: quote.shipper.state,
              Zipcode: quote.shipper.zipCode,
              Name: quote.shipper.name
            },
            destination: {
              ...quote.consignee,
              Country: CountryOptions.filter(con => con.CodePrimus === quote.consignee.country)[0].Code,
              City: quote.consignee.city,
              State: quote.consignee.state,
              Zipcode: quote.consignee.zipCode,
              Name: quote.consignee.name
            },
            quoteNumber: rowData.quoteNumber,
          }
        })
        navigate('/book/new-booking')
      }).catch((error) => {
        setLoading(false)
        checkErrorAPICall(error, dispatch, 'Book rate')
      })
    }

    const menuProps = {
      classes: {
        list: classesSelect.list
      }
    }

    const actionsRender = (rowDataAction) => {
      return (
        <div className='d-flex align-items-center justify-content-center'>
          {user?.allowCarrierTracking && <Tooltip title='Track'>
            <IconButton
              onClick={() => {
                setRowData(rowDataAction)
                setLoadingTrack(true)
                axios.get(`${HISTORYTRACKINGS}/${rowDataAction.BOLId}`).then(results => {
                  setLoadingTrack(false)
                  const resultsWithID = results.data.data.results.map(result => {
                    return ({
                      ...result,
                      id: uuidv4()
                    })
                  })
                  setHistoryTrackings(resultsWithID)
                  setOpenTrackingModal(true)
                }).catch(error => {
                  setLoadingTrack(false)
                  checkErrorAPICall(error, dispatch, 'History Tracking')
                })
              }}
            >
              {!loadingTrack ? <KTSVG className="svg-icon svg-icon-2" style={{color: '#7239EA'}} path='/media/icons/bootstrap/geo-alt-fill.svg'/> : rowDataAction.BOLId === rowData.BOLId ? <CircularProgressStyled style={{height: 16, width: 16}}/> : <KTSVG className="svg-icon svg-icon-2" style={{color: '#7239EA'}} path='/media/icons/bootstrap/geo-alt-fill.svg'/>}
            </IconButton>
          </Tooltip>}
          <Tooltip title='Documents'>
            <IconButton
              onClick={() => {
                setSelectedRow(rowDataAction)
                getDocuments(rowDataAction)
              }}
            >
              {!loadingDocuments ? <FileCopyIcon style={{color: '#0095E8'}}/> : rowDataAction?.BOLId === selectedRow?.BOLId ? <CircularProgress style={{height: 16, width: 16, color: '#0095E8'}}/> : <FileCopyIcon style={{color: '#0095E8'}}/>}
            </IconButton>
          </Tooltip>
          <IconButton>
            <Select
              IconComponent={MoreVertIcon}
              className={classesSelect.selectTable}
              style={{ cursor: 'pointer' }}
              variant='standard'
              displayEmpty
              value=""
              MenuProps={menuProps}
            >
              <MenuItem onClick={() => {
                setRowData(rowDataAction)
                setOpenRequestInfoModal(true)
              }}>
                <EmailIcon style={{color: '#47BE7D'}} className='me-1'/> Contact
              </MenuItem>
              {
                user.allowBooking ?
                <MenuItem
                  onClick={() => {
                    setRowData(rowDataAction)
                    setOpenConfirmDuplicateShipmentModal(true)
                  }}
                >
                  <ControlPointDuplicateIcon style={{color: '#fd7e14'}} className='me-1'/> Duplicate
                </MenuItem>
                : null
              }
              {
                !rowDataAction?.dispatched ?
                <MenuItem
                  onClick={() => {
                    setRowData(rowDataAction)
                    setOpenConfirmDeleteShipmentModal(true)
                  }}
                >
                  <DeleteIcon style={{color: '#F1416C'}} className='me-1'/> Delete
                </MenuItem>
                : null
              }
            </Select>
          </IconButton>
        </div>
      )
    }

    return (
        <>
          <MaterialTable
            isLoading={loading}
            tableRef={tableRef}
            sx={{
              '& .MuiTableCell-head:last-child > div': {                    
                display: 'flex',
                justifyContent: 'flex-end'
              },
            }}
            style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
            columns={tableFor === 'Trackings' ?
              [...columns, {title: 'Actions', field: 'actions', render: actionsRender, cellStyle: {width: 124, minWidth: 124, alignItems: 'center'}, headerStyle: {textAlign: 'center', alignItems: 'center', justifyContent: 'center', padding: '40px !important'}, sortable: false}]
              : columns
            }
            data={data}
            options={{
              thirdSortClick: false,
              actionsColumnIndex: -1,
              toolbar: false,
              filtering: false,
              draggable: tableFor === 'Trackings' ? true : false,
              rowStyle: (data, idx) => idx%2 !== 0 ? {
                backgroundColor: '#f4f4f4',
              } : {
                backgroundColor: '#ffff',
              },
              pageSizeOptions:[10,25,50,100],
              pageSize: rowsPerPage,
              headerStyle: { position: 'sticky', top: 0, backgroundColor: "#eceff4", verticalAlign: 'baseline' },
              maxBodyHeight: fontSize >= 13 ? `${window.innerHeight - 355}px` : fontSize === 9 ? `${window.innerHeight - 330}px` : `${window.innerHeight - 340}px`
            }}
            onColumnDragged={handleColumnDrag}
            localization={{
              pagination: {
                labelRows: tableFor.toLowerCase(),
                labelRowsPerPage: `${tableFor} per page:`,
              },
              body:{ emptyDataSourceMessage:<p style={{marginTop:'60px', textAlign:'center', fontSize: 13}}>No records to display</p> }
            }}
            components={{
              Container: (props) => <Paper className={classes.tableQuotesGrid} {...props} />,
              Row: rowProps => <MTableBodyRowStyled {...rowProps} onRowClick={(e) => tableFor === 'Trackings' && openShipment(e, rowProps.data)} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,
              Pagination: props =>  <TablePagination
                                      {...props}
                                      count={totalResults}
                                      page={pageNumber}
                                      onPageChange={(e, page) => {
                                        setPageNumber(page)

                                        if(tableFor === 'Quotes' || tableFor === 'Trackings'){
                                          if((filters?.search || filters?.from || filters?.to || filters.type.length[0] !== 'ALL' || filters.bookedOnly) && filtering){
                                            updateFilters({pageNumber: page})
                                          }else{
                                            if(filtering){
                                              defaultCall({from: new Date().setMonth(new Date().getMonth() - 1), to: new Date()})
                                            }else{
                                              defaultCall({})
                                            }
                                          }
                                        }
                                      }}
                                    />
            }}
            onPageChange={(page) => {
              setPageNumber(page)
            }}
            onRowsPerPageChange={(number) => {
              setPageNumber(0)
              setRowsPerPage(number)

              if(tableFor === 'Quotes' || tableFor === 'Trackings'){
                if((filters.search || filters.from || filters.to || filters.type.length[0] !== 'ALL' || filters.bookedOnly) && filtering){
                  updateFilters({pageNumber: 0})
                }else{
                  if(filtering){
                    defaultCall({from: new Date().setMonth(new Date().getMonth() - 1), to: new Date()})
                  }else{
                    defaultCall({})
                  }
                }
              }
            }}
            actions={tableFor !== 'Trackings' ? [
              tableFor === 'Quotes' ? {
                icon: () => !loadingDocuments ? <FileCopyIcon style={{color: '#0095E8'}}/> : rowData?.BOLId === selectedRow?.BOLId ? <CircularProgress style={{height: 16, width: 16, color: '#0095E8'}}/> : <FileCopyIcon style={{color: '#0095E8'}}/>,
                tooltip: 'Documents',
                onClick: (event, rowData: any) => {
                  setSelectedRow(rowData)
                  getDocuments(rowData)
                }
              } : null,
              tableFor === 'Quotes' ?
              {
                icon: () => <KTSVG className="svg-icon svg-icon-1 mt-1" style={{color: '#47BE7D'}} path='/media/icons/bootstrap/arrow-clockwise.svg'/>,
                tooltip: 'Refresh',
                onClick: (event, rowData) => {
                  setRowData(rowData)
                  setOpenReRunModal(true)
                }
              } : null,
              tableFor === 'Invoices' ? {
                icon: () => <EmailIcon style={{color: '#47BE7D', width: 21, height: 21}}/>,
                tooltip: 'Request Information',
                onClick: (event, rowData) => {
                  setRowData(rowData)
                  setOpenRequestInfoModal(true)
                }
              } : null,
              tableFor === 'Quotes' ? (rowData: any) => ({
                icon: () => <BookIcon style={{color: '#F1416C'}}/>,
                tooltip: 'Book',
                onClick: (event, rowData: any) => {
                  bookRate(rowData)
                }
              }) : null,
            ] : []}
          />
          <ConfirmReRunQuote
            open={openReRunModal}
            setOpen={setOpenReRunModal}
            rowData={rowData}
          />
          <RequestInformationModal
            open={openRequestInfoModal}
            setOpen={setOpenRequestInfoModal}
            rowData={rowData}
            inquiryFor={tableFor}
          />
          <TrackingModal
            open={openTrackingModal}
            setOpen={setOpenTrackingModal}
            historyTrackings={historyTrackings}
            setHistoryTrackings={setHistoryTrackings}
            rowData={rowData}
          />
          <ViewDataModal
            open={openViewDataModal}
            setOpen={setOpenViewDataModal}
            selectedRow={selectedRow}
          />
          <DocumentsModal
            open={openDocumentsModal}
            setOpen={setOpenDocumentsModal}
            documents={documents}
            setDocuments={setDocuments}
            selectedRow={selectedRow}
            forQuotes={tableFor === 'Quotes'}
          />
          <ConfirmDeleteShipmentModal
            open={openConfirmDeleteShipmentModal}
            setOpen={setOpenConfirmDeleteShipmentModal}
            BOLId={rowData.BOLId}
            updateFilters={updateFilters}
            setLoading={setLoading}
          />
          <ConfirmDuplicateShipmentModal
            open={openConfirmDuplicateShipmentModal}
            setOpen={setOpenConfirmDuplicateShipmentModal}
            duplicateShipmentInfo={duplicateShipmentInfo}
            setDuplicateShipmentInfo={setDuplicateShipmentInfo}
            rowData={rowData}
            selectBOL={selectBOL}
          />
        </>
    )
}

export default Table