import SelectExportType from '../LTLandVolumeTLPages/SelectExportType/SelectExportType'
import ShowHideColumns from '../QuotesGridPage/Table/ShowHideColumns'
import SelectStatus from './SelectStatus'
import { ToolbarProps } from '../../pages/invoices/models';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ToolbarStyled } from '../Toolbar.styled';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { InputStyled } from '../Inputs.styled';
import { RootState } from '../../../setup';

let cancelToken
const GET_INVOICES = '/applet/v1/invoice'

const Toolbar = ({filters, setFilters, columns, setColumns, invoices, setInvoices, setLoading, rowsPerPage, pageNumber, setPageNumber, setTotalResults}: ToolbarProps) => {
    const dispatch = useDispatch()
    const [clicked, setClicked] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleSearch = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
    }

    const defaultCall = () => {
        setLoading(true)
    
        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.')
        }
    
        cancelToken = axios.CancelToken.source()

        axios.get(GET_INVOICES, {
            cancelToken: cancelToken.token,
            params: {
                page: pageNumber + 1,
                limit: rowsPerPage,
            }
        }).then((results) => {
            setTotalResults(results.data.data.pagingDetails.totalResults)
            setInvoices(results.data.data.results)
            setLoading(false)
        }).catch(error => {
          setLoading(false)
          checkErrorAPICall(error, dispatch, 'Invoices')
        })
    }

    useEffect(() => {
        if((filters.invoiceNumber || filters.BOLNumber || filters.type !== 'All') && filtering){
            updateFilters()
        }else{
            defaultCall()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, rowsPerPage])

    const updateFilters = () => {
        if(clicked){

        }else {
            setLoading(true)

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('Operation canceled due to new request.')
            }
        
            //Save the cancel token for the current request
            cancelToken = axios.CancelToken.source()
        
            axios.get(GET_INVOICES, {
                cancelToken: cancelToken.token,
                params: {
                    page: pageNumber + 1,
                    limit: rowsPerPage,
                    invoiceNumber: filters.invoiceNumber.trim(),
                    BOLNumber: filters.BOLNumber.trim(),
                    paid: filters.status === 'Paid' ? true : filters.status === 'Unpaid' ? false : null,
                }
            }).then((results) => {
                setTotalResults(results.data.data.pagingDetails.totalResults)
                setInvoices(results.data.data.results)
                setLoading(false)

                if(results.data.data.results.length){
                    let newSearch = {id: uuidv4(), ...filters, hashId: `${JSON.stringify(filters.invoiceNumber)}${JSON.stringify(filters.BOLNumber)}${JSON.stringify(filters.status)}`}
                    let existingSearches = JSON.parse(localStorage.getItem("historyInvoicesGrid")) || []
                    let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
                
                    if(isDuplicated){
                
                    }else{
                        existingSearches.unshift(newSearch);
                        localStorage.setItem("historyInvoicesGrid", JSON.stringify(existingSearches));
                    }
                }
            }).catch(error => {
                setLoading(false)
                checkErrorAPICall(error, dispatch, 'Invoices')
            })
        }
    }

    const updateFiltersFirstCall = () => {
        setPageNumber(0)
        setLoading(true)

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel('Operation canceled due to new request.')
        }
    
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
    
        axios.get(GET_INVOICES, {
            cancelToken: cancelToken.token,
            params: {
                page: pageNumber + 1,
                limit: rowsPerPage,
                invoiceNumber: filters.invoiceNumber.trim(),
                BOLNumber: filters.BOLNumber.trim(),
                paid: filters.status === 'Paid' ? true : filters.status === 'Unpaid' ? false : null,
            }
        }).then((results) => {
            setInvoices(results.data.data.results)
            setTotalResults(results.data.data.pagingDetails.totalResults)
            setClicked(false)
            setLoading(false)

            if(results.data.data.results.length){
                let newSearch = {id: uuidv4(), ...filters, hashId: `${JSON.stringify(filters.invoiceNumber)}${JSON.stringify(filters.BOLNumber)}${JSON.stringify(filters.status)}`}
                let existingSearches = JSON.parse(localStorage.getItem("historyInvoicesGrid")) || []
                let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
            
                if(isDuplicated){
            
                }else{
                    existingSearches.unshift(newSearch);
                    localStorage.setItem("historyInvoicesGrid", JSON.stringify(existingSearches));
                }
            }
        }).catch(error => {
            setLoading(false)
            checkErrorAPICall(error, dispatch, 'Invoices')
        })
    }

    const filter = () => {
        setClicked(true)
        setFiltering(true)
        setPageNumber(0)
        updateFiltersFirstCall()
    }

    return (
        <ToolbarStyled className="row mb-8">
            <div className="col-12 col-sm-6 mb-5 mb-sm-0">
                <div className="row align-items-center">
                    <div className="col-8 d-flex align-items-center pe-0">
                        <InputStyled
                            name='invoiceNumber'
                            value={filters.invoiceNumber}
                            onChange={handleSearch}
                            placeholder='Invoice#'
                            className='form-control me-3'
                            autoComplete='off'
                            fontSize={fontSize}
                            onKeyPress={(e) => {
                                if(e.code === "Enter"){
                                  handleSearch(e)
                                  filter()
                                }
                            }}
                        />
                        <InputStyled
                            name='BOLNumber'
                            value={filters.BOLNumber}
                            onChange={handleSearch}
                            placeholder='BOL#'
                            className='form-control'
                            autoComplete='off'
                            fontSize={fontSize}
                            onKeyPress={(e) => {
                                if(e.code === "Enter"){
                                  handleSearch(e)
                                  filter()
                                }
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <SelectStatus
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </div>
                </div>
            </div>
            <div className="col-11 col-sm-5 d-flex align-items-center pe-0">
                <div
                    className='btn btn-secondary me-3'
                    onClick={() => {
                        setFilters({
                            invoiceNumber: '',
                            BOLNumber: '',
                            status: 'All',
                        })
                        setClicked(true)
                        setFiltering(false)
                        setPageNumber(0)
                        defaultCall()
                    }}
                >
                    Clear
                </div>
                <div
                    className='btn btn-primary'
                    onClick={() => {
                        filter()
                    }}
                >
                    Filter
                </div>
            </div>
            <div className="col-1 d-flex align-items-center justify-content-end pt-5 pt-xl-0">
                <div className="me-3">
                    <ShowHideColumns
                        columns={columns}
                        setColumns={setColumns}
                        disabled={invoices?.length ? false : true}
                    />
                </div>
                <SelectExportType
                    fileName="Invoices"
                    columns={columns.filter(column => column.hidden === false)}
                    dataColumns={invoices}
                    disabled={invoices?.length ? false : true}
                    setLoading={setLoading}
                />
            </div>
        </ToolbarStyled>
    )
}

export default Toolbar