import { Backdrop, Fade } from '@mui/material'
import { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format";
import { KTSVG } from '../../_metronic/helpers'
import { AccessorialsModalProps } from '../pages/ltl/models';
import { DivPaperStyled, ModalStyled } from './Modals.styled';
import { modalStyles } from './ModalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../setup';

const AccessorialsModal = ({open, setOpen, data, weight, setWeight}: AccessorialsModalProps) => {
    const classes = modalStyles({});
    const [error, setError] = useState(false)
    const [localWeight, setLocalWeight] = useState('')
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
        setError(false)
    }

    useEffect(() => {
        if(open){
            setLocalWeight(weight || data?.value || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="modal-header p-0 pb-2 row align-items-center">
                        <div className="col-6 d-flex align-items-center">
                            <h5>{data?.name}</h5>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary"
                                onClick={handleClose}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="row mb-6 mt-6 d-flex align-items-center">
                            <div className="col-12 d-flex align-items-center">
                                <label className='w-25'>
                                    {data?.label}
                                </label>
                                <NumericFormat
                                    className='form-control w-75'
                                    style={{
                                        borderColor: error ? '#F1416C' : '#E4E6EF'
                                    }}
                                    placeholder={data?.emptyText}
                                    autoComplete="off"
                                    value={localWeight}
                                    name='qty'
                                    onChange={(e) => {
                                        setLocalWeight(e.target.value)
                                        if(e.target.value.length){
                                            setError(false)
                                        }
                                    }}
                                    thousandSeparator={','}
                                    decimalScale={0}
                                    isAllowed={(values) => {
                                        const { formattedValue, floatValue } = values;
                                        return formattedValue === '' || (floatValue >= data.min && floatValue <= data.max)
                                    }}
                                />
                            </div>
                            {error
                                ?
                                <div className="col-12 d-flex">
                                    <div className='w-25'></div>
                                    <small className="text-danger ms-2 mt-1 w-75">Required</small>
                                </div>
                                : null
                            }
                            
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                        if(localWeight === ''){
                                            setError(true)
                                        }else{
                                            if(!weight && !setWeight){
                                                data.value = localWeight
                                            }else{
                                                setWeight(localWeight)
                                            }
                                            handleClose()
                                        }
                                    }}
                                >
                                    Send
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default AccessorialsModal