import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTable } from 'react-table'
import { IconButton, TablePagination, TableSortLabel, Tooltip } from '@mui/material';
import ProgressBarRender from '../ProgressBarRender/ProgressBarRender';
import QuoteBreakdownModal from '../Modals/QuoteBreakdownModal/QuoteBreakdownModal';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { ContainerStyled } from './Table.styled';
import TableContainer from '@mui/material/TableContainer';
import { DivStyled } from '../Toolbar/Toolbar.styled';
import { TableProps } from '../../pages/book/models';
import { tableStyles } from '../TableStyles';
import CarrierRender from './CarrierRender';
import TransitRender from './TransitRender';
import TotalRender from './TotalRender';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TypeOptions } from '../../data/TypeOptions';
import { CountryOptions } from '../../data/CountryOptions';
import ClearIcon from '@mui/icons-material/Clear';
import BOLRender from './BOLRender';
import EditIconRender from './EditIconRender';

const GET_BOOK = '/applet/v1/book'

const Table = ({columnsTable, dataTable, setDataTable, loadingRates, totalRows, setTotalRows, uploadedRows, setUploadedRows}: TableProps) => {
    const classesTable = tableStyles()
    const [quoteBreakdownModalData, setQuoteBreakdownModalData] = useState({})
    const [openQuoteBreakdownModal, setOpenQuoteBreakdownModal] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(50);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [data, setData] = useState([])
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)
    const navigate = useNavigate()
    const dispatch = useDispatch() 

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    const columns = useMemo(
        () => [...columnsTable?.map(column => {
            return {
                Header: column.title,
                accessor: column.field,
                minWidth: column.minWidth || '',
                width: column.width || '',
                maxWidth: column.maxWidth || ''
            }
        }), {Header: 'Actions', accessor: 'actions', minWidth: '71px', width: '71px', maxWidth: '71px'}],
        [columnsTable]
    ) as any
    
    const {
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
    )

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const deleteRow = (id) => {
        setData(data.filter(singleData => singleData.id !== id))
        setDataTable(dataTable.filter(singleData => singleData.id !== id))
        setTotalRows(totalRows - 1)
        setUploadedRows(uploadedRows - 1)
    }

    const editShipment = (book, BOLData) => {
        axios.get(`${GET_BOOK}/${BOLData.BOLId}`)
        .then((results) => {
            let bookCall = results.data.data.results
    
            let payload = {      
                quoteNumber: bookCall?.accountingInformation?.customerQuoteNumber,
                BOLDocumentURL: bookCall.BOLDocumentURL,
                BOLId: bookCall.BOLId,
                vendorSCAC: bookCall?.vendor?.SCAC,
                vendorId: bookCall?.vendor?.id,
                BOLNumber: BOLData.BOL,
                BOLPrefix: bookCall.BOLPrefix,
                PRONmbr: book.carrierPRO,
                thirdPartyReferenceNumber: book?.billPartyReferenceNumber,
                fromSearch: true,
                additionalInformation: bookCall.additionalInformation,
                brokerInformation: bookCall.brokerInformation,
                specialInstructions: bookCall?.BOLInstructions,
                carrierInformation: {
                    name: bookCall.vendor.name || '',
                    transitDays: bookCall.vendor.transitDays || '',
                    total: book.Rate.total !== 'N/A' ? typeof book?.Rate?.total === 'string' ? book?.Rate?.total?.includes('*') ? parseFloat(book?.Rate?.total?.replace('* ', '')) : book.Rate?.total :  book.Rate?.total : 'N/A',
                    serviceLevel: bookCall.vendor.serviceLevel || '',
                    rateRemarks: [],
                    iconUrl: bookCall.vendor.icon || '',
                    rateId: bookCall?.vendor?.id?.toString(),
                },
                pickupDate: {
                    type: bookCall?.pickupInformation?.type === 'PO' ? 'PickupOn' : 'PickupBy',
                    date: bookCall?.pickupInformation?.date ? new Date(bookCall?.pickupInformation?.date?.replace(/-/g, '/')) : "",
                    from: bookCall?.pickupInformation?.timeFrom ? moment(bookCall?.pickupInformation?.timeFrom, "HH:mm:ss").format("HH:mm") : "",
                    to: bookCall?.pickupInformation?.timeTo ? moment(bookCall?.pickupInformation?.timeTo, "HH:mm:ss").format("HH:mm") : ""
                },
                deliveryDate: {
                    type: bookCall?.deliveryInformation?.type === 'DO' ? 'DeliveryOn' : 'DeliveryBy',
                    date: bookCall?.deliveryInformation?.date ? new Date(bookCall?.deliveryInformation?.date?.replace(/-/g, '/')) : "",
                    from: bookCall?.deliveryInformation?.timeFrom ? moment(bookCall?.deliveryInformation?.timeFrom, "HH:mm:ss").format("HH:mm") : "",
                    to: bookCall?.deliveryInformation?.timeTo ? moment(bookCall?.deliveryInformation?.timeTo, "HH:mm:ss").format("HH:mm") : ""
                },
                dispatched: book.dispatched,
                accessorials: bookCall.accessorials,
                freightInfoUOM: {
                    value: bookCall.UOM,
                    oldValue: ''
                },
                freightInfo: bookCall?.freightInfo?.map(freight => {
                    return {
                    ...freight,
                    dimType: TypeOptions.find(type => type.value === freight.dimType),
                    L: freight.length || null,
                    W: freight.width || null,
                    H: freight.height || null,
                    weightus: bookCall.UOM === 'US' ? freight?.weight : freight?.weight / 0.45359237,
                    Lus: bookCall.UOM === 'US' ? freight?.length : freight?.length / 2.54,
                    Wus: bookCall.UOM === 'US' ? freight?.width : freight?.width / 2.54,
                    Hus: bookCall.UOM === 'US' ? freight?.height : freight?.height / 2.54,
                    class: freight?.class?.toString(),
                    each: freight.weightType === 'each' ? 'Each' : 'Total',
                    }
                }),
                shipper: {
                    address: bookCall.shipper.address1,
                    address1: bookCall.shipper.address2,
                    country: bookCall?.shipper?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === bookCall?.shipper?.country)?.Code || "US" : bookCall?.shipper?.country,
                    city: bookCall?.shipper?.city,
                    name: bookCall?.shipper?.name,
                    state: bookCall?.shipper?.state,
                    zipcode: bookCall?.shipper?.zipCode,
                    email: bookCall.shipper?.email,
                    phone: bookCall.shipper?.phone,
                    contactPhone: bookCall.shipper?.contactPhone,
                    contact: bookCall.shipper?.contact,
                    referenceNumber: bookCall?.shipper?.referenceNumber,
                    id: bookCall?.shipper?.id,
                },
                consignee: {
                    address: bookCall.consignee.address1,
                    address1: bookCall.consignee.address2,
                    country: bookCall?.consignee?.country?.length > 2 ? CountryOptions?.find(c => c.CodePrimus === bookCall?.consignee?.country)?.Code || "US" : bookCall?.consignee?.country,
                    city: bookCall?.consignee?.city,
                    name: bookCall?.consignee?.name,
                    state: bookCall?.consignee?.state,
                    zipcode: bookCall?.consignee?.zipCode,
                    phone: bookCall.consignee?.phone,
                    email: bookCall.consignee?.email,
                    contactPhone: bookCall.consignee?.contactPhone,
                    contact: bookCall.consignee?.contact,
                    referenceNumber: bookCall?.consignee?.referenceNumber,
                    id: bookCall?.consignee?.id,
                },
            }
            
            dispatch({type: actionTypes.SetQuoteValues, payload: []})
            dispatch({type: actionTypes.SetNewBookingValues, payload: payload})
            navigate('/book/new-booking')
        })
    }

    const renderCells = (rowData, header, cell) => {
        let billTo = data.find(singleData => singleData.BillTo)

        if(header === 'BOL'){
            return (
                <TableCell>
                    <BOLRender rowData={rowData} id={rowData.id}/>
                </TableCell>
            )
        }else if(header === 'Shipper' || header === 'Consignee'){
            return (
                <TableCell>
                    <div className='d-flex justify-content-center'><b>{rowData[`${header}Name`]}</b></div>
                    <div className='d-flex justify-content-center'>{rowData[`${header}City`]}{rowData[`${header}City`] ? ',' : ''} {rowData[`${header}State`]} {rowData[`${header}Zipcode`]}</div>
                </TableCell>
            )
        }else if(header === 'Pieces'){
            return (
                <TableCell>
                    <div>{rowData.Pieces} Pc @ {rowData.Weight} lbs ea. {rowData.Length ? `${rowData.Length}${rowData.Width ? 'x' : ''}` : ''}{rowData.Width ? `${rowData.Width}${rowData.Height ? 'x' : ''}` : ''}{rowData.Height ? rowData.Height : ''} {(rowData.Length || rowData.Width || rowData.Height) ? 'in.' : ''} CL {rowData.Class}</div>
                    <div>{rowData.Commodity}</div>
                </TableCell>
            )
        }else if(header === 'Progress'){
            return (
                <TableCell align='center'>
                    <ProgressBarRender rowData={rowData} id={rowData.id}/>
                </TableCell>
            )
        }else if(header === 'Carrier'){
            return (
                <TableCell>
                    <CarrierRender
                        rowData={rowData}
                        loadingRates={loadingRates}
                    />
                </TableCell>
            )
        }else if(header === 'Transit'){
            return (
                <TableCell>
                    <TransitRender rowData={rowData} loadingRates={loadingRates}/>  
                </TableCell>
            )
        }else if(header === 'Total' || header === 'Bill To'){
            if(!billTo && header === 'Bill To'){
                return null
            }else{
                return (
                    <TableCell align='right'>
                        <TotalRender
                            rowData={rowData}
                            header={header}
                            setQuoteBreakdownModalData={setQuoteBreakdownModalData}
                            setOpenQuoteBreakdownModal={setOpenQuoteBreakdownModal}
                            loadingRates={loadingRates}
                        />
                    </TableCell>
                )
            }
        }
        else if(header === 'Actions'){
            return (
                <TableCell align='center'>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 20}}>
                        {/* <Tooltip
                            title={['', undefined].indexOf(rowData.BOL) > -1 ? '' : 'Edit Shipment'}
                        >
                            <IconButton
                                disabled={['', undefined].indexOf(rowData.BOL) > -1 ? true : false}
                                onClick={() => {
                                    editShipment(rowData)
                                }}
                            >
                                <EditIcon
                                    style={{color: ['', undefined].indexOf(rowData.BOL) > -1 ? '#81C5FB' : '#0095E8'}}
                                />
                            </IconButton>
                        </Tooltip> */}
                        <EditIconRender editShipment={editShipment} rowData={rowData}/>
                        <Tooltip
                            title={loadingRates ? '' : 'Remove this row'}
                        >
                            <IconButton
                                disabled={loadingRates}
                                onClick={() => {
                                    deleteRow(rowData.id)
                                }}
                            >
                                <ClearIcon
                                    style={{color: loadingRates ? '#f081a2' : '#F1416C'}}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
            )
        }else{
            return (
                <TableCell>
                    {cell?.render('Cell')}
                </TableCell>
            )
        }
    }

    useEffect(() => {
        setData((dataTable).sort((a, b) => {
            let orderByWithoutSpaces = orderBy.replace(/\s/g, '')
            if(order === 'asc'){
                return a[orderByWithoutSpaces] < b[orderByWithoutSpaces] ? -1 : a[orderByWithoutSpaces] > b[orderByWithoutSpaces] ? 1 : 0
            }else{
                return a[orderByWithoutSpaces] > b[orderByWithoutSpaces] ? -1 : a[orderByWithoutSpaces] < b[orderByWithoutSpaces] ? 1 : 0
            }
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTable, order, orderBy, page, rowsPerPage])

    useEffect(() => {
        setData((dataTable).map(singleData => ({...singleData, actions: ''})).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTable])

    return (
        <div className="row">
            <ContainerStyled className="col-12" fontSize={fontSize}>
                <TableContainer
                    style={{boxShadow: 'none'}}
                    className={classesTable.table}
                >
                    <TableMui>
                        <TableHead>
                            <TableRow>
                                {headerGroups?.map(headerGroup => (
                                    headerGroup?.headers?.map((column, index) => {
                                        let billTo = data.find(singleData => singleData.BillTo)
                                        
                                        if(!billTo && column.Header === 'Bill To'){
                                            return null
                                        }else{
                                            return (
                                                <TableCell style={{ width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth }} key={index} align={column.Header === 'Total' || column.Header === 'Bill To' ? 'right' : column.Header === 'Progress' || column.Header === 'Actions' || column.Header === 'Shipper' || column.Header === 'Consignee' ? 'center' : 'left'}>
                                                    {
                                                        (column.Header === 'Progress' || column.Header === 'Actions' || column.Header === 'Pieces')
                                                        ? column?.render('Header')
                                                        : <TableSortLabel
                                                            active={
                                                                orderBy === (column.Header === 'Shipper' || column.Header === 'Consignee'
                                                                ? `${column.Header}Name`
                                                                : column.Header)
                                                            }
                                                            direction={order as any}
                                                            onClick={() => {
                                                                setOrder(order === 'asc' ? 'desc' : 'asc')
                                                                setOrderBy(
                                                                    (column.Header === 'Shipper' || column.Header === 'Consignee'
                                                                    ? `${column.Header}Name`
                                                                    : column.Header) as any
                                                                )
                                                            }}
                                                        >
                                                            {column?.render('Header')}
                                                        </TableSortLabel>
                                                    } 
                                                </TableCell>
                                            )
                                        }
                                    })
                                ))}
                            </TableRow>
                        </TableHead>
                        {
                            rows.length ?
                                <TableBody>
                                    {rows?.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <TableRow
                                                key={index}
                                                style={{
                                                    backgroundColor: index%2 !== 0 ? '#FAFAFA' : '#ffff',
                                                    borderBottom: '1px solid black'
                                                }}
                                            >
                                                {row?.cells?.map((cell, index) => {
                                                    let rowData = cell.row.original
                                                    let header = cell.column.Header
                                                    return <Fragment key={index}>{renderCells(rowData, header, cell)}</Fragment>
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            : <TableBody>
                                <TableRow>
                                    <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-sizeMedium text-center" colSpan={11}>
                                        No records to display
                                    </td>
                                </TableRow>
                            </TableBody>
                        }
                    </TableMui>
                </TableContainer>
                <DivStyled className='d-flex align-items-center justify-content-end bg-white'>
                    {
                        (dataTable.length) > 0 ?
                            <div style={{color: '#3F4254', fontSize: fontSize}}>Page {page + 1}-{Math.ceil((dataTable.length) / 50)}</div>
                        : null
                    }
                    <TablePagination
                        component="div"
                        count={dataTable.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[50]}
                        sx={{
                            height: '35.5px',
                            overflow: 'hidden !important',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#3F4254',
                            '.MuiTablePagination-selectLabel': {
                                display: 'none'
                            },
                            '.MuiTablePagination-toolbar': {
                                backgroundColor: 'white',
                                paddingLeft: 0
                            },
                            '.MuiInputBase-root': {
                                display: 'none'
                            },
                            '.MuiTablePagination-displayedRows': {
                                display: 'none'
                            },
                            '.MuiTablePagination-actions': {
                                paddingRight: '10px',
                            },
                            '.MuiTablePagination-root': {
                                overflow: 'hidden !important',
                            },
                        }}
                    />       
                </DivStyled>
            </ContainerStyled>     
            <QuoteBreakdownModal
                open={openQuoteBreakdownModal}
                setOpen={setOpenQuoteBreakdownModal}
                data={quoteBreakdownModalData}
            />
        </div>
    )
}

export default memo(Table)