export const accessorialsBatchQuoting = data => {
    let accessorials = []

    if (["y", "yes"].indexOf(data.LiftgateOrigin?.toLowerCase()) > -1) {
        accessorials.push('LFO')
    }

    if (["y", "yes"].indexOf(data.LiftgateDestination?.toLowerCase()) > -1) {
        accessorials.push('LFD')
    } 

    if (["y", "yes"].indexOf(data.InsideOrigin?.toLowerCase()) > -1) {
        accessorials.push('INO')
    } 

    if (["y", "yes"].indexOf(data.InsideDestination?.toLowerCase()) > -1) {
        accessorials.push('IND')
    } 

    if (["y", "yes"].indexOf(data.ResidentialOrigin?.toLowerCase()) > -1) {
        accessorials.push('RSO')
    } 
    
    if (["y", "yes"].indexOf(data.ResidentialDestination?.toLowerCase()) > -1) {
        accessorials.push('RSD')
    }

    data.Accessorials = accessorials
    data.AccessorialsInFile = accessorials
}