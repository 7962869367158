import { CountryDialCode } from "../data/CountryDialCode";
import { CountryOptions } from "../data/CountryOptions";

export const formatPhoneUSA = (phoneNumberString, country) => {
    if(phoneNumberString !== null){
        let countryCode = CountryOptions?.find(c => c.CodePrimus === country)?.Code || 'US'
        phoneNumberString = phoneNumberString?.replace(CountryDialCode.find(countryOne => countryOne.code === countryCode)?.dial_code, '')
        var cleaned = ('' + phoneNumberString)?.replace(/\D/g, '');

        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }        
    return null;
}