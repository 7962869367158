import { useEffect } from 'react'
import SelectExportType from '../LTLandVolumeTLPages/SelectExportType/SelectExportType'
import DateInput from '../QuotesGridPage/DateInput'
import ShowHideColumns from '../QuotesGridPage/Table/ShowHideColumns'
import SearchInputToolbar from '../LTLandVolumeTLPages/SearchInputToolbar';
import SelectType from '../QuotesGridPage/Table/SelectType'
import { ToolbarProps } from '../../pages/track/models';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { ToolbarStyled } from '../Toolbar.styled'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'
import { RootState } from '../../../setup';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import { columnsTrackingGrid } from './columnsTrackingGrid';

let cancelToken
const GET_TRACKINGS = '/applet/v1/book'

const Toolbar = ({filters, setFilters, columns, setColumns, trackings, setTrackings, setLoading, rowsPerPage, setPageNumber, setTotalResults, defaultCall, setClicked, setFiltering, defaults}: ToolbarProps) => {
    const dispatch = useDispatch()
    const trackingsFilter = useSelector<RootState>(({pages}) => pages.trackingsFilter) as any

    const handleSearch = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        if(!trackingsFilter?.search && !trackingsFilter?.type?.length && !trackingsFilter?.dateFrom && !trackingsFilter?.dateTo){
            setFilters({
                ...filters,
                from: new Date(new Date().setMonth(new Date().getMonth() - 1)),
                to: new Date()
            })
            defaultCall({from: new Date().setMonth(new Date().getMonth() - 1), to: new Date()})
            setFiltering(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateFiltersFirstCall = () => {
        setPageNumber(0)

        if(isNaN(filters.from) || isNaN(filters.to)){

        }else{
            setLoading(true)

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('Operation canceled due to new request.')
            }
        
            //Save the cancel token for the current request
            cancelToken = axios.CancelToken.source()
        
            axios.get(GET_TRACKINGS, {
                cancelToken: cancelToken.token,
                params: {
                    page: 1,
                    limit: rowsPerPage,
                    search: filters.search.trim(),
                    dateFrom: filters.from && format(filters.from, 'yyyy-MM-dd'),
                    dateTo: filters.to && format(filters.to, 'yyyy-MM-dd'),
                    pickedUp: filters.type.includes('PICKED UP') ? true : filters.type.includes('NOT DISPATCHED') ? false : null,
                    inTransit: filters.type.includes('IN TRANSIT') ? true : null,
                    dispatched: filters.type.includes('DISPATCHED') ? true : filters.type.includes('NOT DISPATCHED') ? false : null,
                    delivered: filters.type.includes('DELIVERED') ? true : filters.type.includes('NOT DISPATCHED') ? false : null,
                    BOLNumber: [filters.bol],
                    'columns[]': [...columnsTrackingGrid.map(col => col.field === 'shipmentMode' ? 'shipmentModeName' : col.field), 'customerQuoteAmount', 'BOLId'].join('|'),
                    'lastStatus[]': filters?.type?.length && filters?.type[0] === 'DELAYED/EXCEPTION' ? 'DLY|EXPT' : ''
                }
            }).then((results) => {
                setTrackings(results.data.data.results)
                setTotalResults(results.data.data.pagingDetails.totalResults)
                setClicked(false)
                setLoading(false)

                if(results.data.data.results.length){
                    let newSearch = {id: uuidv4(), ...filters, hashId: `${JSON.stringify(filters.search)}${JSON.stringify(filters.from ? filters.from.toDateString() : '')}${JSON.stringify(filters.to ? filters.to.toDateString() : '')}${JSON.stringify(filters.type)}${JSON.stringify(filters.bol)}`}
                    let existingSearches = JSON.parse(localStorage.getItem("historyTrackingGrid")) || []
                    let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
                
                    if(isDuplicated){
                
                    }else{
                        existingSearches.unshift(newSearch);
                        localStorage.setItem("historyTrackingGrid", JSON.stringify(existingSearches));
                    }
                }
            }).catch(error => {
                setLoading(false)
                checkErrorAPICall(error, dispatch, 'Trackings')
            })
        }
    }

    const filter = () => {
        setClicked(true)
        setFiltering(true)
        setPageNumber(0)
        updateFiltersFirstCall()
    }

    return (
        <ToolbarStyled className="row mb-2">
            <div className="col-12 col-sm-3 col-xl-2 d-flex align-items-center">
                <SearchInputToolbar
                    searchTable={filters.search}
                    handleSearch={handleSearch}
                    filter={filter}
                />
            </div>
            <div className="col-12 col-sm-9 col-xl-4 d-flex align-items-center pe-3 p-lg-0 mt-4 mt-sm-0">
                <div className="me-3">
                    From
                </div>
                <div className="me-3 w-100">
                    <DateInput
                        data={filters}
                        setData={setFilters}
                        dateFor='from'
                    />
                </div>
                <div className="me-3">
                    To
                </div>
                <div className='w-100'>
                    <DateInput
                        data={filters}
                        setData={setFilters}
                        dateFor='to'
                    />
                </div>
            </div>
            <div className="col-10| col-sm-6 col-lg-5 col-xl-4 d-flex mt-4 mt-xl-0 d-flex align-items-center">
                <SelectType
                    filters={filters}
                    setFilters={setFilters}
                    types={[
                        {value: 'ALL', name: 'All'},
                        {value: 'NOT DISPATCHED', name: 'Not Dispatched'},
                        {value: 'DISPATCHED', name: 'Dispatched'},
                        {value: 'PICKED UP', name: 'Picked Up'},
                        {value: 'IN TRANSIT', name: 'In Transit'},
                        {value: 'DELIVERED', name: 'Delivered'},
                        {value: 'DELAYED/EXCEPTION', name: 'Delayed/Exception'}
                    ]}
                    className='me-3 w-100 w-xl-50'
                />
                <div
                    className='btn btn-secondary me-3'
                    onClick={() => {
                        dispatch({type: actionTypes.SetTrackingsFilter, payload: []})
                        setFilters({
                            search: '',
                            from: null,
                            to: null,
                            type: ['ALL'],
                            bol: ''
                        })
                        setClicked(true)
                        setFiltering(false)
                        setPageNumber(0)
                        defaultCall({emptyValues: true})
                    }}
                >
                    Clear
                </div>
                <div
                    className='btn btn-primary'
                    onClick={() => {
                        filter()
                    }}
                >
                    Filter
                </div>
            </div>
            <div className="col-2 col-sm-6 col-lg-7 col-xl-2 d-flex align-items-center justify-content-end mt-5 mt-xl-0">
                <div className="me-3">
                    <ShowHideColumns
                        columns={columns}
                        setColumns={setColumns}
                        disabled={trackings.length ? false : true}
                        columnsFor='Tracking Grid'
                        defaults={defaults}
                    />
                </div>
                <SelectExportType
                    fileName="Trackings"
                    columns={columns.filter(column => column?.hidden === false)}
                    dataColumns={trackings}
                    disabled={trackings.length ? false : true}
                    setLoading={setLoading}
                />
            </div>
        </ToolbarStyled>
    )
}

export default Toolbar