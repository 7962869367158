import { Backdrop, Fade } from '@mui/material'
import { modalStyles } from '../../ModalStyles'
import { ModalStyled } from '../../Modals.styled'
import { KTSVG } from '../../../../_metronic/helpers'
import { RootState } from '../../../../setup'
import { useSelector } from 'react-redux'

const EquipmentServiceModal = ({open, setOpen, formik, getRates}) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paperEquipmentService} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row modal-header p-0 pb-2">
                        <div className="col-8">
                            <h5 className="modal-title">Equipment Selection</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <div style={{maxHeight: 615, overflowY: 'scroll', overflowX: 'hidden'}}>
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <label className='form-label'>Please select the equipment:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="equipment"
                                                    value="Van"
                                                    id="DRYVAN"
                                                    onChange={(e) => formik.setFieldValue('equipment', e.target.value)}
                                                    checked={formik.values.equipment === "Van"}
                                                />
                                                <label className="form-check-label" htmlFor="DRYVAN">
                                                    Dry Van
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="equipment"
                                                    value="Flatbed"
                                                    id="FLATBED"
                                                    onChange={(e) => formik.setFieldValue('equipment', e.target.value)}
                                                    checked={formik.values.equipment === "Flatbed"}
                                                />
                                                <label className="form-check-label" htmlFor="FLATBED">
                                                    Flatbed
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3 mt-7">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                        setOpen(false)
                                        getRates()
                                    }}
                                >
                                    Get Rates
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default EquipmentServiceModal