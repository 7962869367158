import { useEffect} from 'react';
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
/* import {Toolbar} from './components/toolbar/Toolbar' */
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'
import { isTokenExp } from '../../app/helpers/isTokenExp';
import store from '../../setup/redux/Store';
import { AppStyled } from './MasterLayout.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../setup';

const MasterLayout = () => {
  const location = useLocation()
  const state = store.getState() as any
  const expTokenLS = JSON.parse(localStorage.getItem('primus-key'))?.expToken
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    if(expTokenLS ? JSON.parse(expTokenLS) : state.expToken) {
        if (new Date() < new Date(expTokenLS ? JSON.parse(expTokenLS) : state.expToken)) {
          isTokenExp()
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageDataProvider>
      <AppStyled fontSize={fontSize} className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid' style={{background: '#ECEFF4', paddingTop: location.pathname.includes('reports') ? 15 : 30}}>
            <div className='post d-flex flex-column-fluid' id='kt_post' style={{maxWidth: location.pathname === '/quote/grid-quotes' || location.pathname === '/trackings' || location.pathname === '/invoices' || location.pathname === '/quote/ltl' || location.pathname === '/quote/volume-tl' || location.pathname === '/quote/drayage' || location.pathname === '/book/new-booking' ? '' : 1450}}>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </AppStyled>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
