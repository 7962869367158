import axios from 'axios'
import { disableSplashScreen } from '../../../../_metronic/layout/core'

export const GET_USER_BY_ACCESSTOKEN_URL = '/applet/v1/profile'
export const LOGIN_URL = '/api/v1/login'
export const REGISTER_URL = '/register'
export const REQUEST_PASSWORD_URL = '/forgot_password'

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken(token:string) {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {'Authorization': 'Bearer ' + token}
  }).then(response => {
    // disableSplashScreen()
    return (response.data.data.results)
  })
}
