import { modalStyles } from '../ModalStyles';
import { Modal, Fade, Backdrop } from '@mui/material';
import { BigSubtitle, FooterStyled, PaperModal, Title } from '../Modals.styled';
import { ExportCsv } from '@material-table/exporters';
import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { ErrorsinDataModalProps } from '../../../pages/book/models';

const ErrorsInDataModal = ({open, setOpen, errors, setOpenConfirm, data, allData, setFileName}: ErrorsinDataModalProps) => {
    const classes = modalStyles();
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })
    
    const handleClose = () => {
        setOpen(false)
        if(data.length && !errors.length){
            setTimeout(() => {
                setOpenConfirm(true)
            }, 200);
        }
    }

    const exportFileWithInvalidRows = () => {
        let newErrorsArray = []

        newErrorsArray = errors.map(error => {
            return {
                row: parseInt(error.slice(error.indexOf('in row ') + 'in row '.length)) - 1,
                message: error.slice(0, error.indexOf(' in row'))
            }
        })
        
        let allDataCopy = [...allData]

        allDataCopy = allDataCopy.map(data => ({...data, Class: data.Class ? data.Class : "", Error: ""}))

        newErrorsArray.forEach(error => {
            if(allDataCopy[error.row - 1]){
                allDataCopy[error.row - 1].Error = `${allDataCopy[error.row - 1].Error && allDataCopy[error.row - 1].Error + ','} ${error.message}`
            }
        })

        let keys = ['Shipper Name', 'Shipper Address', 'Shipper City', 'Shipper State', 'Shipper Zipcode', 'Shipper Phone', 'Shipper Contact', 'Shipper Ref #', 'Consignee Name', 'Consignee Address', 'Consignee City', 'Consignee State', 'Consignee Zipcode', 'Consignee Phone', 'Consignee Contact', 'Consignee Ref #', 'Pieces', 'Weight', 'NMFC', 'Commodity' ,'Class', 'Length', 'Width', 'Height', 'Pickup Date', 'Pickup Time From', 'Pickup Time To', 'Liftgate Origin', 'Liftgate Destination', 'Inside Origin', 'Inside Destination', 'Residential Origin', 'Residential Destination', 'Hazmat', 'Error']

        ExportCsv(
            keys.map(key => ({field: key.replace(/\s+/g, ''), title: key})),
            allDataCopy,
            'ShipmentsErrors'
        )
    } 

    useEffect(() => {
      if(open){
        setFileName('')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/x-circle.svg' className='svg-icon svg-icon-3x text-danger'/>
                                </div>
                                <div className="col-12 mt-5 d-flex justify-content-center">
                                    <Title fontSize={fontSize}>Error processing file</Title>
                                </div>
                                <div className="col-12 mt-3 d-flex justify-content-center">
                                    <BigSubtitle fontSize={fontSize}>Download the errors report and try again</BigSubtitle>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5">
                                <FooterStyled fontSize={fontSize} className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={exportFileWithInvalidRows}
                                    > 
                                        Download
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </FooterStyled>
                            </div>
                        </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default ErrorsInDataModal