import { createSumArray } from "./createSumArray"
import _ from 'lodash' 

export const getFreightInfoTotals = (freightInfoTotals, freightInfo, freightInfoUOM) => {
    let arrayOfQty = []
    let arrayOfWeight = freightInfo?.map(freight => {
        if(freight.each === 'Each' || !freight.each){
            return Number(freight.weight) * Number(freight.qty)
        }else{
            return freight.weight
        }
    })
    let arrayOfCft = []
    let finalQty, finalWeight, finalCFT

    freightInfo?.forEach(object => {
        if (object.qty) {
            arrayOfQty.push(parseInt(object.qty))
        }
        
        if (object.L || object.W || object.H) {
            arrayOfCft.push(parseFloat(object.totalCft))
        }
    })

    finalQty = createSumArray(arrayOfQty)
    finalWeight = createSumArray(arrayOfWeight)
    finalCFT = createSumArray(arrayOfCft)

    return {
        ...freightInfoTotals,
        totalPieces: finalQty || 0,
        totalWeight: finalWeight || 0,
        totalCFT: finalCFT || 0,
        totalDensity:
        (finalWeight / finalCFT) === Number.POSITIVE_INFINITY || isNaN(finalWeight / finalCFT)
            ? 0 
            : (
                isNaN(finalCFT)
                ? 0
                : _.ceil(
                    freightInfoUOM.value === 'METRIC' || freightInfoUOM.value === 'MIXED'
                    ? ((finalWeight / 0.45359237) / finalCFT)
                    : (finalWeight / finalCFT), 2
                )
            )
    }
}