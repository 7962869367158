import React, { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux';
import { UserModel } from '../../../modules/auth/models/UserModel'
import PhoneInput from '../../ShippingLocationsPage/PhoneInput'
import { RootState } from '../../../../setup/redux/RootReducer';
import { CountryOptions } from '../../../data/CountryOptions';
import ZipcodesWithCountry from '../../LTLandVolumeTLPages/ZipcodesWithCountry';
import { FormsEditProps } from '../../../pages/vendors/models';
import { InputStyled } from '../../Inputs.styled';

const BillToInformation = ({formik}: FormsEditProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [zipcode, setZipcode] = useState('')
    const [city, setCity] = useState({
        City: formik.values.billToInformation?.city,
        Country: CountryOptions.filter(country => country.CodePrimus === formik.values.billToInformation?.country)[0]?.Code,
        Name: "",
        State: formik.values.billToInformation?.state,
        Zipcode: formik.values.billToInformation?.zipcode,
        isSL: false,
        isZipcode: true,
        id: null
    })
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    return (
        <>
            <div className="row align-items-center">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Name</label>
                </div>
                <div className="col-12 col-sm-10 col-md-5 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('billToInformation.name')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Account#</label>
                </div>
                <div className="col-12 col-sm-10 col-md-5">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('billToInformation.account')}
                        fontSize={fontSize}
                    />
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Address</label>
                </div>
                <div className="col-7 col-sm-6 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='new-password'
                        {...formik.getFieldProps('billToInformation.address')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-5 col-sm-4 col-md-2 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='new-password'
                        {...formik.getFieldProps('billToInformation.address1')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>C&#8204;ity</label>
                </div>
                <div className="col-12 col-sm-10 col-md-5">
                    <ZipcodesWithCountry
                        zipcodeLocal={zipcode}
                        setZipcodeLocal={setZipcode}
                        setDataFormik={formik?.setFieldValue}
                        data={city}
                        setData={setCity}
                        countryOptions={CountryOptions}
                        userData={user}
                        error={''}
                        touched={{}}
                        setFieldTouched={() => {}}
                        editVendor
                        valueName='billToInformation'
                    />
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Phone</label>
                </div>
                <div className="col-12 col-sm-10 col-md-4 col-lg-3">
                    <PhoneInput
                        value={formik.values.billToInformation.phone}
                        setDataFormik={formik.setFieldValue}
                        name='billToInformation.phone'
                        countryCode={formik.values.billToInformation.country}
                        deleteSelectCountry={false}
                    />
                </div>
                <div className="d-none d-lg-flex col-lg-2"></div>
                <div className="col-12 col-md-7 col-lg-5 mt-5 mt-md-0 d-flex align-items-center">
                    <div className="row">
                        <div className="col-12 col-sm-9 col-lg-10 d-flex align-items-center">
                            <label className='form-label me-4 mb-0'>PDF Branding</label>
                            <div className="form-check form-check-custom form-check-solid me-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioInquiry"
                                    id='company'
                                    value='company'
                                    onChange={(e) => formik.setFieldValue('billToInformation.PDFBranding', e.target.value)}
                                    checked={formik.values.billToInformation.PDFBranding === 'company'}
                                />
                                <label className="form-check-label" htmlFor='company'>
                                    Company
                                </label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid me-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioInquiry"
                                    id='carrier'
                                    value='carrier'
                                    onChange={(e) => formik.setFieldValue('billToInformation.PDFBranding', e.target.value)}
                                    checked={formik.values.billToInformation.PDFBranding === 'carrier'}
                                />
                                <label className="form-check-label" htmlFor='carrier'>
                                    Carrier
                                </label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid me-4">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="radioInquiry"
                                    id='none'
                                    value='none'
                                    onChange={(e) => formik.setFieldValue('billToInformation.PDFBranding', e.target.value)}
                                    checked={formik.values.billToInformation.PDFBranding === 'none'}
                                />
                                <label className="form-check-label" htmlFor='none'>
                                    None
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-sm-3 col-lg-2 mt-5 mt-sm-0">
                            <button className='btn btn-primary'>
                                Preview
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillToInformation