import { Backdrop, CircularProgress, Fade } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { EditModalProps } from '../../../pages/vendors/models'
import { modalStyles } from '../../ModalStyles'
import AdditionalInformation from './AdditionalInformation'
import APIIntegration from './APIIntegration'
import BillToInformation from './BillToInformation'
import CarrierRemarks from './CarrierRemarks'
import MainInformation from './MainInformation'
import * as Yup from 'yup';
import axios from 'axios'
import { actionTypes } from '../../../modules/auth/redux/PagesRedux'
import { useDispatch, useSelector } from 'react-redux'
import { CountryOptions } from '../../../data/CountryOptions'
import { ModalStyled } from '../../Modals.styled'
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall'
import { RootState } from '../../../../setup'

const TEST_CONNECTION = '/applet/v1/rate/test'
const GET_VENDORS = '/api/v1/database/vendor'

const EditModal = ({open, setOpen, rowSelected, setRowSelected, getData}: EditModalProps) => {
    const classes = modalStyles({})
    const dispatch = useDispatch()
    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            setRowSelected({})
            formik.handleReset({})
        }, 500);
    }
    const [vendorsTypes, setVendorsTypes] = useState([])
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [testingConnection, setTestingConnection] = useState(false)
    const [testingConnectionSave, setTestingConnectionSave] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const initialValues = {
        mainInformation: {
            name: '',
            terms: '',
            billTo: '',
            address: '',
            address1: '',
            city: '',
            state: '',
            zipcode: '',
            country: 'US',
            phone: '',
            contactPerson: '',
            email: '',
            vendorType: '',
            shipmentMode: {},
            remarks: '',
            equipments: [],
            serviceArea: []
        },
        APIIntegration: {
            APIList: '',
            username: '',
            password: '',
            account: '',
            accessCode: '',
            extraField: '',
            restrictions: {
                maximums: {
                    pieces: 0,
                    pieceWeight: 4,
                    pieceLength: 0,
                    pieceWidth: 0,
                    pieceHeight: 0,
                    totalWeight: 0,
                    CFT: 0
                },
                minimums: {
                    pieces: 0,
                    pieceWeight: 4,
                    pieceLength: 0,
                    pieceWidth: 0,
                    pieceHeight: 0,
                    totalWeight: 0,
                    CFT: 0
                }
            }
        },
        carrierRemarks: {
           messageQuotes: '',
           displayMessageQuotes: false,
           messageBOL: '',
           displayMessageBOL: false,
        },
        billToInformation: {
            name: '',
            account: '',
            address: '',
            address1: '',
            city: '',
            state: '',
            zipcode: '',
            country: 'US',
            phone: '',
            PDFBranding: 'company'
        },
        additionalInformation: {
            'MC#': '',
            'EIN#': '',
            APCode: '',
            'USBond#': '',
            SCAC: '',
            'DOT#': ''
        }
    }

    const validationSchema = Yup.object().shape({
        mainInformation: Yup.object({
          email: Yup.string().email(),
        })
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: () => {
            testConnection(true)
        }
    })
    
    const editVendor = () => {
        setSaving(true)
        axios.put(`${GET_VENDORS}/${rowSelected.id}`, {
            ...rowSelected,
            code: formik.values.additionalInformation.APCode,
            name: formik.values.mainInformation.name,
            type: [{id: vendorsTypes.filter(e => e.name === formik.values.mainInformation.vendorType)[0]?.id}],
            quoteRemarks: formik.values.carrierRemarks.messageQuotes,
            quoteRemarksForce: formik.values.carrierRemarks.displayMessageQuotes,
            bookingRemarks: formik.values.carrierRemarks.messageBOL,
            bookingRemarksForce: formik.values.carrierRemarks.displayMessageBOL,
            shipmentMode: formik.values.mainInformation.shipmentMode,
            APICredentials: {
                ...rowSelected.APICredentials,
                terms: formik.values.mainInformation.terms,
                billTo: formik.values.mainInformation.billTo,
                API: formik.values.APIIntegration.APIList,
                username: formik.values.APIIntegration.username,
                password: formik.values.APIIntegration.password,
                account: formik.values.APIIntegration.account,
                accessCode: formik.values.APIIntegration.accessCode,
                extraField: formik.values.APIIntegration.extraField,
                restrictions: {
                    ...rowSelected?.APICredentials.restrictions,
                    originType: 'ALL',
                    destinationType: 'ONLY',
                    maximums: {
                        pieces: formik.values.APIIntegration.restrictions.maximums.pieces || 0,
                        pieceWeight: formik.values.APIIntegration.restrictions.maximums.pieceWeight || 0,
                        pieceLength: formik.values.APIIntegration.restrictions.maximums.pieceLength || 0,
                        pieceWidth: formik.values.APIIntegration.restrictions.maximums.pieceWidth || 0,
                        pieceHeight: formik.values.APIIntegration.restrictions.maximums.pieceHeight || 0,
                        totalWeight: formik.values.APIIntegration.restrictions.maximums.totalWeight || 0,
                        CFT: formik.values.APIIntegration.restrictions.maximums.CFT || 0
                    },
                    minimums: {
                        pieces: formik.values.APIIntegration.restrictions.minimums.pieces || 0,
                        pieceWeight: formik.values.APIIntegration.restrictions.minimums.pieceWeight || 0,
                        pieceLength: formik.values.APIIntegration.restrictions.minimums.pieceLength || 0,
                        pieceWidth: formik.values.APIIntegration.restrictions.minimums.pieceWidth || 0,
                        pieceHeight: formik.values.APIIntegration.restrictions.minimums.pieceHeight || 0,
                        totalWeight: formik.values.APIIntegration.restrictions.minimums.totalWeight || 0,
                        CFT: formik.values.APIIntegration.restrictions.minimums.CFT || 0
                    }
                }
            },
            contactInformation: {
                ...rowSelected.contactInformation,
                address: formik.values.mainInformation.address,
                address1: formik.values.mainInformation.address1,
                city: formik.values.mainInformation.city,
                state: formik.values.mainInformation.state,
                zipcode: formik.values.mainInformation.zipcode,
                contacts: rowSelected.contactInformation.contacts.map((contact, index) => {
                    if(index === 0){
                        return {
                            name: formik.values.mainInformation.contactPerson,
                            email: formik.values.mainInformation.email,
                            phone: formik.values.mainInformation.phone,
                            fax: '',
                            type: "Main"
                        }
                    }else {
                        return contact
                    }
                }),
                remarks: formik.values.mainInformation.remarks
            },
            equipment: formik.values.mainInformation.equipments,
            serviceArea: formik.values.mainInformation.serviceArea,
            billTo: {
                ...rowSelected.billTo,
                name: formik.values.billToInformation.name,
                account: formik.values.billToInformation.account,
                address: formik.values.billToInformation.address,
                address1: formik.values.billToInformation.address1,
                city: formik.values.billToInformation.city,
                state: formik.values.billToInformation.state,
                zipcode: formik.values.billToInformation.zipcode,
                country: CountryOptions.filter(country => country.Code === formik.values.billToInformation?.country)[0]?.CodePrimus,
                phone: formik.values.billToInformation.phone,
            },
            headerBranding: {
                ...rowSelected.headerBranding,
                logo: formik.values.billToInformation.PDFBranding
            },
            MCNumber: formik.values.additionalInformation['MC#'],
            EINNumber: formik.values.additionalInformation['EIN#'],
            USBondNumber: formik.values.additionalInformation['USBond#'],
            SCAC: formik.values.additionalInformation.SCAC,
            DOTNumber: formik.values.additionalInformation['DOT##']
        }).then(() => {
            setSaving(false)
            setSaved(true)
            setTimeout(function(){
                setSaved(false)
            }, 2000);
            getData({})
        }).catch(error => {
            setSaving(false)
            checkErrorAPICall(error, dispatch, 'Edit vendor')
        })
    }

    useEffect(() => {
        if(rowSelected?.id){
            // console.log(rowSelected.billTo?.country)
            formik.setValues({
                mainInformation: {
                    name: rowSelected?.name,
                    terms: rowSelected.APICredentials?.terms,
                    billTo: rowSelected.APICredentials?.billTo,
                    address: rowSelected.contactInformation?.address,
                    address1: rowSelected.contactInformation?.address1,
                    city: rowSelected.contactInformation?.city,
                    state: rowSelected.contactInformation?.state,
                    zipcode: rowSelected.contactInformation?.zipcode,
                    country: '',
                    phone: rowSelected.contactInformation?.contacts?.filter(contact => contact.type === 'Main')[0]?.phone,
                    contactPerson: rowSelected.contactInformation?.contacts?.filter(contact => contact.type === 'Main')[0]?.name,
                    email: rowSelected.contactInformation?.contacts?.filter(contact => contact.type === 'Main')[0]?.email,
                    vendorType: '',
                    shipmentMode: {},
                    remarks: rowSelected.contactInformation?.remarks,
                    equipments: rowSelected.equipment || [],
                    serviceArea: rowSelected.serviceArea || []
                },
                APIIntegration: {
                    APIList: '',
                    username: rowSelected.APICredentials?.username,
                    password: rowSelected.APICredentials?.password,
                    account: rowSelected.APICredentials?.account,
                    accessCode: rowSelected.APICredentials?.accessCode,
                    extraField: rowSelected.APICredentials?.extraField,
                    restrictions: {
                        maximums: {
                            pieces: rowSelected.APICredentials?.restrictions?.maximums?.pieces,
                            pieceWeight: rowSelected.APICredentials?.restrictions?.maximums?.pieceWeight,
                            totalWeight: rowSelected.APICredentials?.restrictions?.maximums?.totalWeight,
                            pieceLength: rowSelected.APICredentials?.restrictions?.maximums?.pieceLength,
                            pieceWidth: rowSelected.APICredentials?.restrictions?.maximums?.pieceWidth,
                            pieceHeight: rowSelected.APICredentials?.restrictions?.maximums?.pieceHeight,
                            CFT: rowSelected.APICredentials?.restrictions?.maximums?.CFT,
                        },
                        minimums: {
                            pieces: rowSelected.APICredentials?.restrictions?.minimums?.pieces,
                            pieceWeight: rowSelected.APICredentials?.restrictions?.minimums?.pieceWeight,
                            totalWeight: rowSelected.APICredentials?.restrictions?.minimums?.totalWeight,
                            pieceLength: rowSelected.APICredentials?.restrictions?.minimums?.pieceLength,
                            pieceWidth: rowSelected.APICredentials?.restrictions?.minimums?.pieceWidth,
                            pieceHeight: rowSelected.APICredentials?.restrictions?.minimums?.pieceHeight,
                            CFT: rowSelected.APICredentials?.restrictions?.minimums?.CFT,
                        }
                    }
                },
                carrierRemarks: {
                   messageQuotes: rowSelected.quoteRemarks,
                   displayMessageQuotes: rowSelected.quoteRemarksForce,
                   messageBOL: rowSelected.bookingRemarks,
                   displayMessageBOL: rowSelected.bookingRemarksForce,
                },
                billToInformation: {
                    name: rowSelected.billTo?.name,
                    account: rowSelected.billTo?.account,
                    address: rowSelected.billTo?.address,
                    address1: rowSelected.billTo?.address1,
                    city: rowSelected.billTo?.city,
                    state: rowSelected.billTo?.state,
                    zipcode: rowSelected.billTo?.zipcode,
                    country: rowSelected.billTo?.country || '',
                    phone: rowSelected.billTo?.phone,
                    PDFBranding: rowSelected.headerBranding?.logo || 'company'
                },
                additionalInformation: {
                    'MC#': rowSelected.MCNumber || '',
                    'EIN#': rowSelected.EINNumber || '',
                    APCode: rowSelected.code || '',
                    'USBond#': rowSelected.USBondNumber || '',
                    SCAC: rowSelected.SCAC || '',
                    'DOT#': rowSelected.DOTNumber || ''
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelected])

    const testConnection = (toSave) => {
        if(!toSave){
            setTestingConnection(true)
        }else{
            setTestingConnectionSave(true)
        }
        axios.get(TEST_CONNECTION, {
            params: {
                vendorInformation: {
                    method: formik.values.APIIntegration.APIList === 'none' ? '' : formik.values.APIIntegration.APIList || '',
                    account: formik.values.APIIntegration.account || '',
                    username: formik.values.APIIntegration.username || '',
                    password: formik.values.APIIntegration.password || '',
                    accessCode: formik.values.APIIntegration.accessCode || '',
                    extraField: formik.values.APIIntegration.extraField || '',
                    terms: formik.values.mainInformation.terms || '',
                    billTo: formik.values.mainInformation.billTo || ''
                }
            }
        })
        .then((results) => {
            setTestingConnection(false)
            setTestingConnectionSave(false)
            if(results?.data?.data?.results?.success){
                dispatch({type: actionTypes.SetAxiosSuccess, payload: 'Carrier API connected succesfully'})
                if(toSave){
                    editVendor()
                }
            }
        }).catch(error => {
            setTestingConnection(false)
            setTestingConnectionSave(false)
            checkErrorAPICall(error, dispatch, 'Test Connection')
        })
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paperEditModalSI} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row modal-header p-0 pb-2">
                        <div className="col-8">
                            <h5 className="modal-title">Vendor Details</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <div style={{maxHeight: 615, overflowY: 'scroll', overflowX: 'hidden'}}>
                            <div className='row mt-5 mb-7'>
                                <div className="col-12">
                                    <h6>Main Information</h6>
                                </div>
                            </div>
                            <MainInformation
                                formik={formik}
                                rowSelected={rowSelected}
                                vendorsTypes={vendorsTypes}
                                setVendorsTypes={setVendorsTypes}
                            />

                            <div className='row mt-10 mb-7'>
                                <div className="col-12">
                                    <h6>API Integration</h6>
                                </div>
                            </div>
                            <APIIntegration
                                formik={formik}
                                testConnection={testConnection}
                                testingConnection={testingConnection}
                                rowSelected={rowSelected}
                            />

                            <div className='row mt-10 mb-7'>
                                <div className="col-12">
                                    <h6>Carrier Remarks</h6>
                                </div>
                            </div>
                            <CarrierRemarks
                                formik={formik}
                            />

                            <div className='row mt-10 mb-7'>
                                <div className="col-12">
                                    <h6>Bill To Information</h6>
                                </div>
                            </div>
                            <BillToInformation
                                formik={formik}
                            />

                            <div className='row mt-10 mb-7'>
                                <div className="col-12">
                                    <h6>Additional Information</h6>
                                </div>
                            </div>
                            <AdditionalInformation
                                formik={formik}
                            />
                        </div>

                        <div className="row mb-3 mt-7">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-secondary me-4"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                                <button
                                    className={`btn ${saved ? 'btn-success' : 'btn-primary'} d-flex align-items-center`}
                                    style={{
                                        cursor: saved ? 'auto' : 'pointer',
                                        transition: 'background-color 0.5s ease'
                                    }}
                                    type='submit'
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}
                                >
                                    {saving ? 'Saving' : testingConnectionSave ? 'Testing Connection' : saved ? 'Saved' : 'Save'}{(saving || testingConnectionSave) && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                </button>
                            </div>
                        </div>
                    </>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default EditModal