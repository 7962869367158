import React, { useEffect, useState } from 'react'
import { ModalStyled } from '../../Modals.styled'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { modalStyles } from '../../ModalStyles'
import { Backdrop, CircularProgress, Fade, IconButton, Tooltip } from '@mui/material'
import { KTSVG } from '../../../../_metronic/helpers'
import axios from 'axios'
import { useFormik } from 'formik'
import { ReactMultiEmailStyled } from '../../NewBookingPage/OriginAndDestination.styled'
import { ChipStyled } from '../../LTLandVolumeTLPages/AccesorialsComponent/Accesorials.styled'
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall'
import * as Yup from 'yup';
import { EmailDocumentsProps } from '../../../pages/track/models'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { LookupContactsModal } from './LookupContactsModal'

const EMAIL_DOCUMENT = '/applet/v1/document/email'

const EmailDocuments = ({open, setOpen, documents, selectedRow}: EmailDocumentsProps) => {
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [loading, setLoading] = useState(false)
    const classesModal = modalStyles({fontSize: fontSize})
    let numberOfChips = window.innerWidth >= 1700 ? 4 : window.innerWidth <= 475 ? 1 : 3
    const dispatch = useDispatch()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [openLookupContactsModal, setOpenLookupContactsModal] = useState(false)

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            if(tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber){
                formik.setValues({...initialValues, subject: `Documents for BOL #${tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber}`})
            }
        }, 500);
    }

    const email = (values) => {
        setLoading(true)
        axios.post(EMAIL_DOCUMENT, {
            "from": values.from,
            "to": values.to,
            "subject": values.subject,
            "body": values.body,
            "attachmentsLinks": values.documents,
            "bccMe": values.BCC
        }).then(results => {
            setLoading(false)
            handleClose()
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Shipping Items')
            setLoading(false)
        })
    }

    const validationSchema = Yup.object().shape({
        to: Yup.array().of(Yup.string()).required('To is required')
    })

    const initialValues = {
        from: '',
        to: '',
        BCC: false,
        subject: '',
        body: '',
        documents: []
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            email(values)
        },
    })

    useEffect(() => {
        if(tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber){
            formik.setFieldValue('subject', `Documents for BOL #${tracking?.rowData?.BOLNumber || selectedRow?.BOLNumber}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracking, selectedRow])

    useEffect(() => {
        if(open){
            if(documents[0]?.name === 'Customer Quote'){
                formik?.setFieldValue('documents', [documents[0]?.url])
                formik.setFieldValue('subject', `Documents for Quote #${documents[0]?.quoteNumber}`)
            }
            formik?.setFieldValue('from', user?.email)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classesModal.paperEmailDocumentsModal} style={{visibility: 'visible', opacity: '100%'}}>
                        <div className="row modal-header p-0 pb-2 mb-5">
                            <div className="col-8">
                                <h5 className="modal-title">Email Documents</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <>
                            <div className="row">
                                <div className="col-12 col-sm-8">
                                    <div className="row mb-3">
                                        <div className="col-2">
                                            <label className='col-form-label'>From</label>
                                        </div>
                                        <div className="col-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-9">
                                                    <input
                                                        className='form-control'
                                                        autoComplete='off'
                                                        {...formik.getFieldProps('from')}
                                                    /> 
                                                </div>
                                                <div className="col-3">
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <input className="form-check-input" type="checkbox" id="BCC" {...formik.getFieldProps('BCC')}/>
                                                        <label className="form-check-label" htmlFor="BCC">
                                                            BCC me
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-2">
                                            <label className='col-form-label'>To</label>
                                        </div>
                                        <div className="col-10">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-9">
                                                    <ReactMultiEmailStyled
                                                        onBlur={() => formik.setFieldTouched('to', true)}
                                                        propsEmails={formik.values?.to || []}
                                                        onChange={(_emails: string[]) => {
                                                            formik.setFieldValue('to', _emails)
                                                        }}
                                                        error={formik.errors?.to && formik.touched?.to ? true : false}
                                                        getLabel={(email, index, removeEmail) => (
                                                            index === numberOfChips ? '...'  : index > numberOfChips ? null :
                                                            <ChipStyled
                                                                chipforemail="true"
                                                                key={index}
                                                                tabIndex={-1}
                                                                onDelete={() => removeEmail(index)}
                                                                label={email}
                                                                className='me-1 mb-1'
                                                                style={{height: fontSize < 13 && 25}}
                                                            />
                                                        )}
                                                    />
                                                    {formik.errors?.to && formik.touched?.to
                                                        ? <small className="text-danger ms-1">{formik.errors?.to}</small>
                                                        : null
                                                    }
                                                </div>
                                                <div className="col-3">
                                                    <Tooltip title='Look up contacts'>
                                                        <IconButton
                                                            onClick={() => setOpenLookupContactsModal(true)}
                                                        >
                                                            <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon svg-icon-4' style={{ color: "#5E6278"}}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-2">
                                            <label className='col-form-label'>Subject</label>
                                        </div>
                                        <div className="col-10">
                                            <input
                                                className='form-control'
                                                autoComplete='off'
                                                {...formik.getFieldProps('subject')}
                                            />  
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <label className='col-form-label'>Body</label>
                                        </div>
                                        <div className="col-10">
                                            <textarea
                                                className="form-control"
                                                style={{height: 130}}
                                                autoComplete='off'
                                                {...formik.getFieldProps('body')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 mt-5 mt-sm-0" style={{paddingTop: 15}}>
                                    {
                                        documents?.map((doc) => (
                                            <div key={doc} className='d-flex align-items-center mb-3'>
                                                <div className="form-check form-check-custom form-check-solid">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={doc.name}
                                                        checked={formik.values?.documents?.filter(url => url === doc?.url).length ? true : false}
                                                        onChange={(e) => {
                                                            let documents = formik.values.documents
                                                            
                                                            if(!documents.includes(doc.url)){
                                                                documents.push(doc.url)
                                                            }else{
                                                                documents = documents.filter(url => url !== doc.url)
                                                            }
                                                            
                                                            formik.setFieldValue('documents', documents)
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={doc.name}>
                                                        Attach {doc.name}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="row mb-3 mt-5">
                                <div className="col-12 d-flex justify-content-end">
                                    <button
                                        className="btn btn-secondary me-4"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => formik.handleSubmit()}
                                    >
                                        {loading ? 'Sending' : 'Send'}{loading && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </Fade>
            </ModalStyled>
            <LookupContactsModal
                open={openLookupContactsModal}
                setOpen={setOpenLookupContactsModal}
                setFormikValues={formik.setFieldValue}
                emails={formik.values.to}
            />
        </>
    )
}

export default EmailDocuments