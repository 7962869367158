import React from 'react'
import { modalStyles } from '../ModalStyles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { RootState } from '../../../setup';
import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled';
import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../_metronic/helpers';
import { DeleteSLModalProps } from '../../pages/shipping-locations/models';

const SHIPPINGITEMS = '/applet/v1/database/shippingitem'

const DeleteSIModal = ({open, setOpen, rowSelected, results, pageNumber, setPageNumber, getData}: DeleteSLModalProps) => {
    const classes = modalStyles({});
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const deleteShippingItem = () => {
        axios.delete(`${SHIPPINGITEMS}/${rowSelected.id}`,)
        .then(() => {
            if(results.length === 1){
                setPageNumber((pageNumber - 1) >= 0 ? (pageNumber - 1) : 0)
                getData({
                    pageNumberProp: 0
                })
            }else{
                getData({})
            }
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Delete Shipping Item')
        })
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <Title>Are you sure you want to delete this shipping item?</Title>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            deleteShippingItem()
                                            setOpen(false)
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-danger me-4"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default DeleteSIModal