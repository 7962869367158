import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import { useDispatch } from 'react-redux'
import { actionTypes } from '../../../../app/modules/auth/redux/PagesRedux'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  iconColor?: object,
  onClick?: () => void
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  iconColor,
  onClick
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const dispatch = useDispatch()

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', {active: isActive})}
        onClick={(e) => {
          if(to?.includes('new-booking') && pathname?.includes('new-booking')){
            dispatch({type: actionTypes.SetQuoteValues, payload: []})
            dispatch({type: actionTypes.SetTracking, payload: []})
            dispatch({type: actionTypes.SetResetValues, payload: true})
          }else if(to === '/trackings' && pathname === '/trackings'){
            dispatch({type: actionTypes.SetTrackingsFilter, payload: {search: '', type: ['ALL'], dateFrom: null, dateTo: null, pageNumber: 0}})
          }else if(to === 'quote/grid-quotes' && pathname === '/quote/grid-quotes'){
            dispatch({type: actionTypes.SetResetValues, payload: true})
          }else if(to?.includes('ltl') && pathname?.includes('ltl')){
            dispatch({type: actionTypes.SetResetValues, payload: true})
          }else if(to === 'quote/volume-tl' && pathname === '/quote/volume-tl'){
            dispatch({type: actionTypes.SetResetValues, payload: true})
          }else if(to === 'quote/drayage' && pathname === '/quote/drayage'){
            dispatch({type: actionTypes.SetResetValues, payload: true})
          }

          if(onClick){
            onClick()
          }
        }}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className={title === 'Log Out' ? 'menu-icon menu-icon-logOut' : 'menu-icon'}>
            <KTSVG path={icon} className='svg-icon-3'/>
          </span>
        )}
        {
          fontIcon && aside.menuIcon === 'font' &&
          <i className={clsx(title === 'Log Out' ? 'menu-fontIconLogOut me-2 bi fs-3' : 'menu-fontIcon me-2 bi fs-3', fontIcon)}></i>
        }
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
