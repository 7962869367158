import { MenuItem, TextField } from '@mui/material'
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateInput from '../LTLandVolumeTLPages/DateInput';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import TimeInput from '../TimeInput/TimeInput';
import moment from 'moment';
import { stylesMUIInput } from '../stylesMUIInput';

const PickupAndDelivery = ({date, handleChange, setDataFormik, error, values, formik, touched, setTouched, formikDispatch, dispatchShip}) => {
    const classes = useStyles({props: {}})
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any
    const [time, setTime] = useState({             
        from: null,     
        to: null
    })
    const [errorLocal, setErrorLocal] = useState({
        from: false,
        to: false
    })
    const resetValues = useSelector<RootState>(({pages}) => pages?.resetValues) as boolean
    const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    
    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    useEffect(() => {
        if(date === 'Pickup'){
            setTime({
                from: newBookingValues?.pickupDate?.from ? moment(newBookingValues?.pickupDate?.from, "HH:mm") : null,
                to: newBookingValues?.pickupDate?.to ? moment(newBookingValues?.pickupDate?.to, "HH:mm") : null
            })
        }else{
            setTime({
                from: newBookingValues?.deliveryDate?.from ? moment(newBookingValues?.deliveryDate?.from, "HH:mm") : null,
                to: newBookingValues?.deliveryDate?.to ? moment(newBookingValues?.deliveryDate?.to, "HH:mm") : null
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBookingValues, resetValues])

    useEffect(() => {
        if(isNaN(formik?.values?.pickupDate?.date)){
            formik.setFieldError('pickupDate.date', 'must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).')
        }else{
            formik.setFieldError('pickupDate.date', '')
        }

        if(isNaN(formik?.values?.deliveryDate?.date)){
            formik.setFieldError('deliveryDate.date', 'must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).')
        }else{
            formik.setFieldError('deliveryDate.date', '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.pickupDate.date, formik.values.deliveryDate.date])

    useEffect(() => {
        if(tracking.length !== 0){
            if(date === 'Pickup'){
                setTime({
                    from: tracking?.pickupDate?.from ? moment(tracking?.pickupDate?.from, "HH:mm") : null,
                    to: tracking?.pickupDate?.to ? moment(tracking?.pickupDate?.to, "HH:mm") : null
                })
            }else{
                setTime({
                    from: tracking?.deliveryDate?.from ? moment(tracking?.deliveryDate?.from, "HH:mm") : null,
                    to: tracking?.deliveryDate?.to ? moment(tracking?.deliveryDate?.to, "HH:mm") : null
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracking])

    let errorLet = error?.date ? error?.date : (formikDispatch.errors[`${date.toLowerCase()}Date`]?.date && dispatchShip) ? formikDispatch.errors[`${date.toLowerCase()}Date`]?.date : ''
    let touchedLet = touched[date.toLowerCase()] || dispatchShip
    
    return (
        <>
            <div className={`row mt-7 mt-sm-0`}>
                <div className="col-6 col-sm-3">
                    <TextField
                        disabled={newBookingValues.dispatched}
                        select
                        label=" "
                        value={values.type || ""}
                        onChange={handleChange}
                        name={`${date.toLowerCase()}Date.type`}
                        className={classes.selectMUI}
                        InputProps={{
                            style: stylesMUIInput(fontSize),
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowDownIcon,
                            MenuProps: menuProps
                        }}
                        InputLabelProps={{shrink: false}}
                    >
                        <MenuItem value={`${date}On`}>{date} On</MenuItem>
                        <MenuItem value={`${date}By`}>{date} By</MenuItem>
                    </TextField>
                </div>
                <div className="col-6 col-sm-3">
                    <div>
                        <DateInput
                            setDataFormik={setDataFormik}
                            pickupDate={values?.date}
                            error={error?.date ? error?.date : (formikDispatch.errors[`${date.toLowerCase()}Date`]?.date && dispatchShip) ? formikDispatch.errors[`${date.toLowerCase()}Date`]?.date : ''}
                            touched={touched[date.toLowerCase()] || dispatchShip}
                            setTouched={(boolean) => setTouched({...touched, [date.toLowerCase()]: boolean})}
                            name={`${date.toLowerCase()}Date.date`}
                            allowPastDates={true}
                            minDate={date === 'Delivery' ? formik.values?.deliveryDateAllowed : null}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-6 d-flex align-items-center mt-2 mt-sm-0">
                    <div className='me-2'>From</div>
                    <TimeInput
                        error={error?.from ? error?.from : (formikDispatch.errors[`${date.toLowerCase()}Date`]?.from && dispatchShip) ? formikDispatch.errors[`${date.toLowerCase()}Date`]?.from : ''}
                        data={time?.from}
                        setData={(value) => setTime({...time, from: value})}
                        name={`${date.toLowerCase()}Date.from`}
                        setFormikData={formik.setFieldValue}
                        className="me-4 normalFont"
                        errorLocal={errorLocal}
                        setErrorLocal={setErrorLocal}
                        time='from'
                    />
                    <div className='me-2'>To</div>
                    <TimeInput
                        error={error?.to ? error?.to : (formikDispatch.errors[`${date.toLowerCase()}Date`]?.to && dispatchShip) ? formikDispatch.errors[`${date.toLowerCase()}Date`]?.to : ''}
                        data={time?.to}
                        setData={(value) => setTime({...time, to: value})}
                        name={`${date.toLowerCase()}Date.to`}
                        setFormikData={formik.setFieldValue}
                        errorLocal={errorLocal}
                        setErrorLocal={setErrorLocal}
                        time='to'
                    />
                </div>
            </div>
            <div className='row d-none d-sm-flex'>
                <div className="col-6 col-sm-3"></div>
                <div className="col-6 col-sm-3">
                    {(errorLet && touchedLet) && <small className="text-danger ms-1">
                        {errorLet.includes('must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).') && 'Invalid date'}
                        {errorLet === 'Pickup date cannot be in the past' && errorLet}
                        {!values?.date && 'Required'}
                    </small>}
                </div>
                <div className="col-3">
                    <small className="text-danger" style={{marginLeft: 43}}>{((error?.from ? error?.from : (formikDispatch.errors[`${date.toLowerCase()}Date`]?.from && dispatchShip) ? formikDispatch.errors[`${date.toLowerCase()}Date`]?.from : '') || errorLocal?.from) && 'Invalid time'}</small>
                </div>
                <div className="col-3">
                    <small className="text-danger" style={{marginLeft: 33}}>{((error?.to ? error?.to : (formikDispatch.errors[`${date.toLowerCase()}Date`]?.to && dispatchShip) ? formikDispatch.errors[`${date.toLowerCase()}Date`]?.to : '') || errorLocal?.to) && 'Invalid time'}</small>
                </div>
            </div>
        </>
    )
}

export default PickupAndDelivery