import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const TransitRender = ({rowData, loadingRates}) => {
    const cheaperCarriers = useSelector<RootState>(({pages}) => pages.cheaperCarriers, shallowEqual) as any
    
    return (
        <span>
            {rowData.progress !== 100 ? (cheaperCarriers?.length && cheaperCarriers?.find(carrier => carrier.id === rowData.id)?.rate?.Transit) || '' : rowData.Transit}
        </span>
    )
}

export default TransitRender