import React, {useEffect, useState} from 'react'
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ChipStyled, PaperStyled, TextFieldStyled } from './Accesorials.styled';
import AccessorialsColumns from './AccessorialsColumns';
import { AccessorialsProps } from '../../../pages/ltl/models';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import AccessorialsModal from '../../AccessorialsModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/redux/RootReducer';
import InsuranceModal from './InsuranceModal';

const Accessorials = ({selectedAcc, setSelectedAcc, accessorialsList, forNewBooking, formikValues, setOpenClearRateToEditModal, setFormikValues, forDefaults, actionClicked, forBatchBooking, accessorials, setAccessorials}: AccessorialsProps) => {
    const [open, setOpen] = useState(false);
    const [openAccModal, setOpenAccModal] = useState(false);
    const [accessorial, setAccessorial] = useState({} as {
        category: string,
        code: string,
        id: number,
        name: string,
        nameShort: string,
        valueRequired?: any
    });
    const [accessorialsFiltered, setAccessorialsFiltered] = useState([])
    const [searchAccessorials, setSearchAccessorials] = useState('')
    const [openInsuranceModal, setOpenInsuranceModal] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any

    const useStyles = makeStyles({
        chip: {
            '& .MuiChip-label': {
                paddingRight: '7px'
            }
        }
    })

    const classes = useStyles()

    const handleDelete = (acc) => {
        let newArray = selectedAcc?.filter((selected) => selected.id !== acc.id);
        setSelectedAcc(newArray);
    };

    const selectAcc = (acc) => {
        if (selectedAcc?.filter(selected => selected.id === acc.id)?.length === 0) {
            setSelectedAcc([...selectedAcc, acc])
        }
    };
    
    let numberOfChips = forNewBooking ? window?.innerWidth >= 1700 ? 6 : window?.innerWidth <= 475 ? 2 : 3 : window?.innerWidth >= 1700 ? 8 : window.innerWidth <= 475 ? 3 : 5

    useEffect(() => {
        if(openAccModal){
            setOpen(false)
        }
    }, [openAccModal])

    useEffect(() => {
      if(!open && searchAccessorials){
        setOpen(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchAccessorials])

    useEffect(() => {
      setOpen(false)
    }, [actionClicked])

    return (
        <>
            <TextFieldStyled
                autoComplete='off'
                disabled={newBookingValues.dispatched}
                variant='standard'
                placeholder={!selectedAcc?.length ? "Click to select" : ""}
                value={searchAccessorials}
                onChange={e => {
                    setSearchAccessorials(e.target.value)
                    let search = e.target.value.toLowerCase()
                    let filtered = accessorialsList.filter(acc => {
                        return acc.code.toLowerCase().includes(search) ||
                        acc.name.toLowerCase().includes(search) ||
                        acc.nameShort.toLowerCase().includes(search)
                    })
                    setAccessorialsFiltered(filtered)
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{ margin: 0 }}
                        >
                            <IconButton 
                                onClick={() => {
                                    if(!newBookingValues?.dispatched){
                                        setOpen(!open)
                                    }
                                }}
                                size="small"
                                disabled={newBookingValues.dispatched}
                            >
                                {!open ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                            </IconButton>
                        </InputAdornment>
                    ),
                    startAdornment: selectedAcc?.map((acc, i) => (
                        i === numberOfChips ? '...'  : i > numberOfChips ? null :
                        <ChipStyled
                            disabled={newBookingValues.dispatched}
                            key={i}
                            tabIndex={-1}
                            label={
                                <>
                                    <b className='me-2' style={{fontSize: fontSize > 13 ? 12 : fontSize === 13 ? 10.52 : (fontSize - 2)}}>{forNewBooking ? `${acc?.nameShort} ${acc?.category !== 'Other' ? acc?.category : ''}` : acc?.code}</b>
                                    {
                                        acc.code === 'INS' && !forDefaults && 
                                        <Tooltip title='Edit values'>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    setOpen(false)
                                                    setOpenInsuranceModal(true)
                                                }}
                                            >
                                                <EditIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        selectedAcc?.filter(selected => selected?.code === acc?.code).length && acc?.valueRequired?.required
                                        && <>
                                            <label className='me-1' style={{fontStyle: 'italic'}}>{acc?.valueRequired?.value}</label>
                                            <Tooltip title='Edit values'>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenAccModal(true)
                                                        setAccessorial(acc)
                                                    }}
                                                >
                                                    <EditIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    }
                                </>
                            }
                            onDelete={() => {
                                if(formikValues && formikValues?.carrierInformation?.name){
                                    setOpenClearRateToEditModal(true)
                                    setOpen(false)
                                }else{
                                    handleDelete(acc)
                                }
                            }}
                            className={`${classes.chip} m-1`}
                            style={{height: fontSize < 13 && 25}} 
                        />
                    )),
                    style: {
                        overflow: 'hidden',
                        border: '1px solid #E4E6EF',
                        height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
                        fontSize: 14,
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        backgroundColor: newBookingValues.dispatched ? '#EFF2F5' : 'white',
                        borderRadius: '0.475rem',
                        paddingRight: 2,
                        paddingLeft: 14,
                    }
                }}
                onClick={() => {
                    if(!newBookingValues?.dispatched){
                        setOpen(!open)
                    }
                }}
                className="w-100"
            />
            {open && (
                <PaperStyled
                    elevation={3}
                >
                    <div className="row">
                        <AccessorialsColumns
                            accessorials={searchAccessorials ? accessorialsFiltered : accessorialsList}
                            selectAcc={selectAcc}
                            handleDelete={handleDelete}
                            accessorialType='Origin'
                            selectedAcc={selectedAcc}
                            openAccModal={openAccModal}
                            setOpenAccModal={setOpenAccModal}
                            setAccessorial={setAccessorial}
                            setSearchAccessorials={setSearchAccessorials}
                            setOpenInsuranceModal={setOpenInsuranceModal}
                            formikValues={formikValues}
                            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                            setOpen={setOpen}
                            forDefaults={forDefaults}
                        />
                        <AccessorialsColumns
                            accessorials={searchAccessorials ? accessorialsFiltered : accessorialsList}
                            selectAcc={selectAcc}
                            handleDelete={handleDelete}
                            accessorialType='Destination'
                            selectedAcc={selectedAcc}
                            openAccModal={openAccModal}
                            setOpenAccModal={setOpenAccModal}
                            setAccessorial={setAccessorial}
                            setSearchAccessorials={setSearchAccessorials}
                            setOpenInsuranceModal={setOpenInsuranceModal}
                            formikValues={formikValues}
                            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                            setOpen={setOpen}
                            forDefaults={forDefaults}
                        />
                        <AccessorialsColumns
                            accessorials={searchAccessorials ? accessorialsFiltered : accessorialsList}
                            selectAcc={selectAcc}
                            handleDelete={handleDelete}
                            accessorialType='Other'
                            selectedAcc={selectedAcc}
                            openAccModal={openAccModal}
                            setOpenAccModal={setOpenAccModal}
                            setAccessorial={setAccessorial}
                            setSearchAccessorials={setSearchAccessorials}
                            setOpenInsuranceModal={setOpenInsuranceModal}
                            formikValues={formikValues}
                            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                            setOpen={setOpen}
                            forDefaults={forDefaults}
                        />
                    </div>
                </PaperStyled>
            )}
            <AccessorialsModal
                open={openAccModal}
                setOpen={setOpenAccModal}
                data={accessorial?.valueRequired}
            />
            <InsuranceModal
                open={openInsuranceModal}
                setOpen={setOpenInsuranceModal}
                formikValues={formikValues}
                setFormikValues={setFormikValues}
            />
        </>
    )
}

export default Accessorials