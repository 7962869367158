import React, { useEffect, useState } from 'react';
import { Fade, Backdrop } from '@mui/material'
import { KTSVG } from '../../../../_metronic/helpers';
import axios from 'axios';
import { Pulse } from './SaveModal.styled';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SaveModalProps } from '../../../pages/ltl/models';
import { modalStyles } from '../../ModalStyles';
import { ModalStyled } from '../../Modals.styled';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { RootState } from '../../../../setup';
import EmailDocuments from '../../TrackingGridPage/DocumentsModal/EmailDocuments';
import { UserModel } from '../../../modules/auth/models/UserModel';

const SAVE_QUOTE = '/applet/v1/rate/save'

export const SaveModal = ({open, setOpen, selectedRow, origin, destination, quote, setQuote, bookRate}: SaveModalProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const dispatch = useDispatch()
    const classes = modalStyles({})
    const [loading, setLoading] = useState(false)
    const [errorInSaveQuote, setErrorInSaveQuote] = useState(false)
    const [openEmailDocumentsModal, setOpenEmailDocumentsModal] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleCloseModal = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(open){
            setLoading(true)
            axios.post(SAVE_QUOTE, {
                rateId: selectedRow?.id,
                originShippingLocationId: origin?.isSL ? origin?.id : 0,
                destinationShippingLocationId: destination?.isSL ? destination?.id : 0
            }).then(result => {
                setQuote([...(quote[0].quoteId ? quote : []), {
                    rateId: selectedRow?.id,
                    quoteId: result.data.data.results.quoteId,
                    quoteNumber: result.data.data.results.quoteNumber,
                    url: result.data.data.results.url
                }])
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                setErrorInSaveQuote(true)
                checkErrorAPICall(error, dispatch, 'Save Quote')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paperSaveQuote} style={{visibility: 'visible', opacity: '100%'}}>
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-end">
                                <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={handleCloseModal}>
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x"
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            loading 
                            ? <div className="row justify-content-center align-items-center" style={{height: '150px'}}>
                                <div className="col-3 text-center">
                                    <span className='spinner-border text-primary mb-3'/>
                                    <Pulse>Saving...</Pulse>
                                </div>
                            </div>
                            : (!errorInSaveQuote
                                ? <>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center">
                                            <KTSVG path='/media/icons/bootstrap/check-circle.svg' className='svg-icon svg-icon-3x text-success'/>
                                        </div>
                                        <div className="col-12 mt-5 d-flex justify-content-center">
                                            <p className="mb-0" style={{fontSize: 18}}>Quote #{quote.filter(a => a.rateId === selectedRow.id)[0]?.quoteNumber} Saved!</p>
                                        </div>
                                    </div>
                                    <div className="row mt-6 mb-3">
                                        <div className="col-12 d-sm-flex d-none justify-content-center">
                                            <a className="btn btn-primary me-4" style={{color: 'white'}} href={quote.filter(a => a.rateId === selectedRow.id)[0]?.url}>Download Quote</a>
                                            { user?.allowBooking ? <button
                                                className="btn btn-primary me-4"
                                                onClick={() => bookRate(selectedRow)}
                                            >
                                                Book Shipment
                                            </button> : null}
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {   
                                                    setOpen(false)
                                                    setOpenEmailDocumentsModal(true)
                                                }}
                                            >
                                                Email Documents
                                            </button>
                                        </div>
                                        <div className="col-12 d-sm-none d-flex justify-content-center">
                                        <a className={`btn btn-primary ${user?.allowBooking ? 'me-4' : ''}`} style={{color: 'white'}} href={quote.filter(a => a.rateId === selectedRow.id)[0]?.url}>Download Quote</a>
                                            { user?.allowBooking ? <button
                                                className="btn btn-primary me-0 me-sm-4"
                                                onClick={() => bookRate(selectedRow)}
                                            >
                                                Book Shipment
                                            </button> : null }
                                        </div>
                                        <div className="col-12 d-sm-none d-flex justify-content-center mt-4">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {   
                                                    setOpen(false)
                                                    setOpenEmailDocumentsModal(true)
                                                }}
                                            >
                                                Email Documents
                                            </button>
                                        </div>
                                    </div>
                                </>
                                : <>
                                    <div className="row align-items-center" style={{height: '120px'}}>
                                        <div className="col-12 d-flex justify-content-center">
                                            <KTSVG path='/media/icons/bootstrap/x-circle.svg' className='svg-icon svg-icon-3x text-danger'/>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <p className="mb-0" style={{fontSize: 18, textAlign: 'center'}}>
                                                Quote could not be saved<br/>
                                                Please contact your sales rep for assistance
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-3">
                                        <div className="col-12 d-flex justify-content-end">
                                            <button className="btn btn-secondary" onClick={handleCloseModal}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
        
                </Fade>
            </ModalStyled>
            <EmailDocuments 
                open={openEmailDocumentsModal}
                setOpen={setOpenEmailDocumentsModal}
                documents={[{
                    name: 'Customer Quote',
                    url: quote.filter(a => a.rateId === selectedRow.id)[0]?.url,
                    quoteNumber: quote.filter(a => a.rateId === selectedRow.id)[0]?.quoteNumber
                }]}
            />
        </>
    );
}
