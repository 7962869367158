import { useEffect, useState } from 'react'
import { LinearProgress, Paper, Rating } from '@mui/material';
import MaterialTable from '@material-table/core';
import SaveIcon from '@mui/icons-material/Save';
import BookIcon from '@mui/icons-material/Book';
import { Column } from '@material-table/core';
import { tableStyles } from '../TableStyles';
import Toolbar from './Toolbar';
import { SaveModal } from '../../Modals/SaveModal';
import { RatesTableProps, RowTableObject } from '../../../../pages/ltl/models';
import QuoteBreakdownModal from '../../Modals/QuoteBreakdownModal';
import { KTSVG } from '../../../../../_metronic/helpers';
import { dollarUSLocale } from '../../../../helpers/dollarUSLocale';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { checkErrorAPICall } from '../../../../helpers/checkErrorAPICall';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../../setup';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { actionTypes } from '../../../../modules/auth/redux/PagesRedux';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import EmailDocuments from '../../../TrackingGridPage/DocumentsModal/EmailDocuments';

const SAVE_QUOTE = '/applet/v1/rate/save'

export const RatesTable = ({vendorsFiltered, vendorsProcessed, tableFor, linearProgress, rates, origin, destination, source, ratesCheaper, quoteIsCheaper, setLoadQuotes, ratesStopped, setRatesStopped, loadQuotes, ratesLTL, values, selectedAcc}: RatesTableProps) => {
  const navigate = useNavigate();
  const classes = tableStyles()
  const dispatch = useDispatch()
  const [rateTypes, setRateTypes] = useState([])
  const [rateTypeSelected, setRateTypeSelected] = useState('ALL RATES')
  const [filteredRates, setFilteredRates] = useState([])
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [openQuoteBreakdownModal, setOpenQuoteBreakdownModal] = useState(false)
  const [quoteBreakdownModalData, setQuoteBreakdownModalData] = useState({})
  const [openEmailDocumentsModal, setOpenEmailDocumentsModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({} as any)
  const [searchTable, setSearchTable] = useState("")
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [quote, setQuote] = useState([{
    rateId: '',
    quoteId: null,
    quoteNumber: '',
    url: ''
  }])
  const [saving, setSaving] = useState(false)

  const carrierRender = (rowData) => {
    return (
      <>
        <img alt="icon" src={rowData.iconUrl} style={{width: 70}}/>
        <p style={{fontWeight: 500, marginBottom: 3}}>{rowData.name}</p>
        <Rating value={rowData.rating} precision={0.5} readOnly />
      </>
    )
  }

  const serviceLevelRender = (rowData) => {
    return (
      <>
        <p className="mb-3" style={{fontWeight: 500}}>{rowData?.serviceLevel}</p>

        {rowData.rateRemarks?.length && rowData.rateRemarks[0]
          ? <>
            {!rowData?.rateRemarks[0]?.includes('href') && !rowData?.rateRemarks[0]?.includes('<br>')  && <KTSVG path='/media/icons/bootstrap/exclamation-triangle-fill.svg' className='svg-icon svg-icon-6 text-danger me-1'/>}
            {rowData.rateRemarks.map(remark => {
              if(remark.includes('href') || remark.includes('<br>') || remark.includes('&nbsp;') || remark.includes('span')){
                return <div key={remark} dangerouslySetInnerHTML={{ __html: remark }} />
              }else{
                return <span key={remark}>{remark}</span>
              }
            })}
          </>
          : null
        }
      </>
    )
  }

  const totalRender = (rowData) => {
    return (
      <button
        style={{
            backgroundColor: 'transparent',
            border: 'none',
            padding: 0,
            textDecoration: 'underline'
        }}
        onClick={() => {
          setQuoteBreakdownModalData(rowData)
          setOpenQuoteBreakdownModal(true)
        }}
      >
        {dollarUSLocale.format(rowData.total)}
      </button>
    )
  }

  const serviceLevelCustomSort = (a, b) => (
    a.serviceLevel.trim()?.localeCompare(b.serviceLevel.trim())
  )

  const transitCustomSort = (a, b) => (
    (a.transitDays === "-" ? 99 : a.transitDays) - (b.transitDays === '-' ? 99 : b.transitDays)
  )

  const [columns] = useState([
    { title: 'Carrier', field: 'name', render: carrierRender },
    { title: 'Service Level', field: 'serviceLevel', width: "50%", render: serviceLevelRender, customSort: serviceLevelCustomSort},
    { title: 'Transit', field: 'transitDays', customSort: transitCustomSort },
    { title: 'Total', field: 'total', defaultSort: 'asc', type: 'currency',  render: totalRender}
  ] as Column<Array<RowTableObject>>[])

  useEffect(() => {
    rates.forEach(rate => {
      if(!(rateTypes.filter(function(e) { return e.value === rate?.rateType; }).length > 0)){
        let stringLowerCase = rate.rateType?.toLowerCase()
        let stringFirstCharUpperCase = stringLowerCase?.charAt(0)?.toUpperCase() + stringLowerCase?.slice(1)

        setRateTypes([...rateTypes, {value: rate?.rateType, name: rate?.rateType?.length > 3 ? stringFirstCharUpperCase : rate?.rateType}])
      }
    })

    if(!(rates.length >= 1)){
      setRateTypeSelected('ALL RATES')
      setSearchTable('')
      setRateTypes([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates])

  useEffect(() => {
    if(loadQuotes === true){
      ratesLTL?.forEach(rate => {
        if(!(rateTypes.filter(function(e) { return e.value === rate.rateType; }).length > 0)){
          let stringLowerCase = rate.rateType?.toLowerCase()
          let stringFirstCharUpperCase = stringLowerCase.charAt(0).toUpperCase() + stringLowerCase.slice(1)
  
          setRateTypes([...rateTypes, {value: rate.rateType, name: rate.rateType.length > 3 ? stringFirstCharUpperCase : rate.rateType}])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadQuotes])

  useEffect(() => {
    let newRatesArray
    if(rateTypeSelected === 'ALL RATES'){
      newRatesArray = rates.filter(rate => {
        return rate.name?.toLowerCase().trim().includes(searchTable?.toLowerCase().trim()) || rate.serviceLevel?.toLowerCase().trim().includes(searchTable?.toLowerCase().trim())
      })
    }else{
      newRatesArray = rates.filter(rate => {
        return rate.rateType === rateTypeSelected
      })
      newRatesArray = newRatesArray.filter(rate => {
        return rate.name?.toLowerCase().trim().includes(searchTable?.toLowerCase().trim()) || rate.serviceLevel?.toLowerCase().trim().includes(searchTable?.toLowerCase().trim())
      })
    }

    if(rateTypeSelected === 'ALL RATES' && searchTable === ''){
      setFilteredRates([])
    }

    setFilteredRates(newRatesArray)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateTypeSelected, searchTable, rates])

  const handleSearch = (e) => {
    setSearchTable(e.target.value)
  }

  const cancelCalls = () => {
    source.cancel('Operation canceled by the user.');
  }

  const bookRate = (rowData) => {
    if(!quote.filter(a => a.rateId === rowData.id)?.length){
      setSaving(true)
      axios.post(SAVE_QUOTE, {
        rateId: rowData?.id,
        originShippingLocationId: origin?.isSL ? origin?.id : 0,
        destinationShippingLocationId: destination?.isSL ? destination?.id : 0
      }).then(result => {
        setQuote([...quote, {
          rateId: rowData?.id,
          quoteId: result.data.data.results.quoteId,
          quoteNumber: result.data.data.results.quoteNumber,
          url: result.data.data.results.url
        }])
        setSaving(false)

        dispatch({
          type: actionTypes.SetQuoteValues,
          payload: {
            ...values,
            mode: tableFor === 'Volume/TL' ? 'Volume' : tableFor,
            freightInfoReclassed: rowData.freightInfoReclassed,
            carrierInformation: rowData,
            quoteNumber: result.data.data.results.quoteNumber,
            url: result.data.data.results.url,
            accessorials: selectedAcc || values.accessorials
          }
        })
        navigate('/book/new-booking')
      }).catch(error => {
        setSaving(false)
        checkErrorAPICall(error, dispatch, 'Save Quote')
      })
    }else{
      dispatch({
        type: actionTypes.SetQuoteValues,
        payload: {
          ...values,
          mode: tableFor === 'Volume/TL' ? 'Volume' : tableFor,
          freightInfoReclassed: rowData.freightInfoReclassed,
          carrierInformation: rowData,
          quoteNumber: quote.filter(q => q?.rateId === rowData?.id)[0]?.quoteNumber
        }
      })
      navigate('/book/new-booking')
    }
  }  

  return (
    <>
      <Toolbar
        tableFor={tableFor}
        searchTable={searchTable}
        handleSearch={handleSearch}
        rateTypeSelected={rateTypeSelected}
        setRateTypeSelected={setRateTypeSelected}
        rateTypes={rateTypes}
        columns={columns}
        dataColumns={filteredRates.length >= 1 || searchTable.length >= 1 ? filteredRates : rates}
        cancelCalls={cancelCalls}
        linearProgress={linearProgress}
        ratesStopped={ratesStopped}
        setRatesStopped={setRatesStopped}
        ratesCheaper={dollarUSLocale.format(ratesCheaper)}
        quoteIsCheaper={quoteIsCheaper}
        setLoadQuotes={setLoadQuotes}
      />

      <div className='d-flex justify-content-center mt-5'>
        <b>{linearProgress >= 100 || ratesStopped ? 'Rating finished' : 'Loading carriers...'}</b>
      </div>
      <div className='d-flex justify-content-center'>
        {`${linearProgress > 0 ? vendorsProcessed : '0'} of ${vendorsFiltered?.length} processed - ${rates?.length} ${rates?.length === 1 ? 'rate' : 'rates'} found`}
      </div>

      <div className="mt-3">
        <LinearProgress
          variant="determinate"
          value={linearProgress}
          style={{backgroundColor: '#94d8ff'}}
          className={classes.linearProgress}
        />
      </div>

      <div className="mt-5">
        <MaterialTable
          style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
          columns={columns}
          data={filteredRates?.length >= 1 || searchTable?.length >= 1 ? filteredRates : rates}
          title=""
          actions={[
            (rowData: any) => ({
              icon: () => !quote.filter(a => a.rateId === rowData.id)?.length ? <SaveIcon style={{color: '#0095E8'}}/> : <KTSVG className="svg-icon svg-icon-3 d-flex align-items-center justify-content-center" style={{color: '#0095E8'}} path='/media/icons/bootstrap/file-earmark-pdf-fill.svg'/>,
              tooltip: !quote.filter(a => a.rateId === rowData.id)?.length ? 'Save Rate' : 'Download PDF',
              onClick: (event, rowData: any) => {
                setSelectedRow(rowData)
                if(!quote.filter(a => a.rateId === rowData.id)?.length){
                  setOpenSaveModal(true)
                  setSelectedRow(rowData)
                }else{
                  saveAs(quote.filter(a => a.rateId === selectedRow.id)[0]?.url)
                }
              }
            }),
            (rowData: any) => (quote.filter(a => a.rateId === rowData.id)?.length && {
              icon: () => quote.filter(a => a.rateId === rowData.id)?.length && <EmailIcon style={{color: '#F1416C', width: 21, height: 21}}/>,
              tooltip: quote.filter(a => a.rateId === rowData.id)?.length ? 'Email Documents' : '',
              onClick: (event, rowData: any) => {
                setOpenEmailDocumentsModal(true)
              }
            }),
            (rowData: any) => ( user.allowBooking ? {
              icon: () => (saving && rowData.id === selectedRow.id) ? <span className='spinner-border spinner-border-sm align-middle' style={{color: '#47BE7D'}}></span> : <BookIcon style={{color: '#47BE7D'}}/>,
              tooltip: 'Book Rate',
              hidden: user?.lockedBOL,
              onClick: (event, rowData: any) => {
                setSelectedRow(rowData)
                bookRate(rowData)
              }
            } : null ),
          ]}
          options={{
            thirdSortClick: false,
            actionsColumnIndex: -1,
            toolbar: false,
            paging: false,
            filtering: false,
            searchFieldAlignment: 'left',
            draggable: false,
            rowStyle: (data, idx) => idx%2 !== 0 ? {
              backgroundColor: '#f4f4f4',
            } : {
              backgroundColor: '#ffff',
            },
          }}
          components={{
            Container: (props) => <Paper className={classes.table} {...props}/>,
          }}
        />
      </div>
      <SaveModal
        open={openSaveModal}
        setOpen={setOpenSaveModal}
        selectedRow={selectedRow}
        origin={origin}
        destination={destination}
        quote={quote}
        setQuote={setQuote}
        bookRate={bookRate}
      />
      <EmailDocuments
        open={openEmailDocumentsModal}
        setOpen={setOpenEmailDocumentsModal}
        documents={[{
            name: 'Customer Quote',
            url: quote.filter(a => a.rateId === selectedRow.id)[0]?.url,
            quoteNumber: quote.filter(a => a.rateId === selectedRow.id)[0]?.quoteNumber
        }]}
      />
      <QuoteBreakdownModal
        open={openQuoteBreakdownModal}
        setOpen={setOpenQuoteBreakdownModal}
        data={quoteBreakdownModalData}
      />
    </>
  )
}
