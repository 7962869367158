import { initialValues } from "../../../helpers/createNewBookingFormik"

export const actionTypes = {
    SetBookingOrInvoice: '[Set Selected] Action',
    SetAxiosErrors: '[Set AxiosErrors] Action',
    SetAxiosSuccess: '[Set AxiosSuccess] Action',
    SetDrayagePorts: '[Set Ports] Action',
    SetQuoteValues: '[Set QuoteValues] Action',
    SetDefaults: '[Set Defaults] Action',
    SetNewBookingValues: '[Set New Booking Values] Action',
    SetInitialNewBooking: '[Set Initial New Booking] Action',
    SetFontSize: '[Set Font Size] Action',
    SetTrackingsFilter: '[Set Trackings Filter] Action',
    SetTracking: '[Set Tracking] Action',
    SetSeeMoreReportsClicked: '[Set See More Reports Clicked] Action',
    SetVendors: '[Set Vendors] Action',
    SetMinimizedMenu: '[Set Minimized Menu] Action',
    SetOpenDispatchedModal: '[Set Open Dispatched Modal] Action',
    SetDocuments: '[Set Documents] Action',
    SetResetValues: '[Set Reset Values] Action',
    SetProgress: '[Set Progress] Action',
    SetCheaperCarriers: '[Set Cheaper Carriers] Action',
    SetBOLS: '[Set BOLS] Action',
    SetDispachedErrorMessage: '[Set Error Message after dispatch] Action',
    SetHistoryUsed: '[Set History Used] Action',
}

export const initialStatePagesReducer = {
    bookingOrInvoice : {},
    axiosError: '',
    axiosSuccess: '',
    drayagePorts: [],
    quoteValues: [],
    defaults: [],
    newBookingValues: initialValues,
    fontSize: 13,
    trackingsFilter: [],
    tracking: [],
    seeMoreReportsClicked: false,
    vendors: [],
    minimizedMenu: undefined,
    initialNewBooking: initialValues,
    openDispatchedModal: false,
    documents: {},
    resetValues: false,
    progress: [],
    cheaperCarriers: '',
    dispatchedErrorMessage: '',
    BOLS: [],
    historyUsed: false
}

export const pagesReducer = (state = initialStatePagesReducer, action) => {
    switch (action.type) {
        case actionTypes.SetBookingOrInvoice: {
            return {...state, bookingOrInvoice: action.payload}
        }

        case actionTypes.SetAxiosErrors: {
            return {...state, axiosError: action.payload}
        }

        case actionTypes.SetAxiosSuccess: {
            return {...state, axiosSuccess: action.payload}
        }

        case actionTypes.SetDrayagePorts: {
            return {...state, drayagePorts: action.payload}
        }

        case actionTypes.SetQuoteValues: {
            return {...state, quoteValues: action.payload}
        }

        case actionTypes.SetDefaults: {
            return {...state, defaults: action.payload}
        }

        case actionTypes.SetNewBookingValues: {
            return {...state, newBookingValues: action.payload}
        }

        case actionTypes.SetInitialNewBooking: {
            return {...state, initialNewBooking: action.payload}
        }

        case actionTypes.SetFontSize: {
            return {...state, fontSize: action.payload}
        }

        case actionTypes.SetTrackingsFilter: {
            return {...state, trackingsFilter: action.payload}
        }

        case actionTypes.SetTracking: {
            return {...state, tracking: action.payload}
        }

        case actionTypes.SetSeeMoreReportsClicked: {
            return {...state, seeMoreReportsClicked: action.payload}
        }

        case actionTypes.SetVendors: {
            return {...state, vendors: action.payload}
        }

        case actionTypes.SetMinimizedMenu: {
            return {...state, minimizedMenu: action.payload}
        }

        case actionTypes.SetOpenDispatchedModal: {
            return {...state, openDispatchedModal: action.payload}
        }

        case actionTypes.SetDocuments: {
            return {...state, documents: action.payload}
        }

        case actionTypes.SetResetValues: {
            return {...state, resetValues: action.payload}
        }

        case actionTypes.SetProgress: {
            return {...state, progress: action.payload}
        }

        case actionTypes.SetCheaperCarriers: {
            return {...state, cheaperCarriers: action.payload}
        }

        case actionTypes.SetBOLS: {
            return {...state, BOLS: action.payload}
        }

        case actionTypes.SetDispachedErrorMessage: {
            return {...state, dispatchedErrorMessage: action.payload}
        }

        case actionTypes.SetHistoryUsed: {
            return {...state, historyUsed: action.payload}
        }
        
        default:
            return state
    }
}
  