import React, {useState, useEffect} from 'react'
import MaterialTable from '@material-table/core';
import { Paper, TablePagination } from '@mui/material';
import Toolbar from '../../ShippingLocationsPage/Toolbar';
import { KTSVG } from '../../../../_metronic/helpers';
import { useStyles } from './UNTableStyles';
import axios from 'axios'; 
import { useDispatch, useSelector } from 'react-redux';
import { UNTableProps } from '../../../pages/ltl/models';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { RootState } from '../../../../setup';
import { MTableBodyRowStyled } from '../../QuotesGridPage/Table/Table.styled';

let cancelToken

const UNTable = ({open, setUNNumber, setSelectedUn, setOpen, setOpenHazContactModal, handleClose}: UNTableProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [tableResults, setTableResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [columns, setColumns] = useState([
        {title: 'UN Number', field: 'UNNumber'},
        {title: 'Description', field: 'description', cellStyle: {width: 400, minWidth: 400}, headerStyle: {width: 400, minWidth: 400}},
        {title: 'HAZ Class', field: 'HAZClass'},
        {title: 'PKG Group', field: 'PKGGroup'},
        {title: 'Labels', field: 'labels', cellStyle: {width: 200, minWidth: 200}, headerStyle: {width: 200, minWidth: 200}},
    ])
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number    
    const [totalResults, setTotalResults] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    
    const selectUNNumber = (e, rowData) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                setUNNumber(rowData.UNNumber)
                    if(setSelectedUn) {
                        setSelectedUn(rowData)
                    }
                    setOpen(false)
                    setTimeout(() => {
                        setOpenHazContactModal(true)
                    }, 100);
                break;
        }
       
    }

    const getResults = (page, unNumber) => {
        let GET_UNTABLE = `/api/v1/database/untable?`
        if(unNumber) {
            page = 1
            GET_UNTABLE +=  `&search=${unNumber}`
        }
        GET_UNTABLE += `&page=${page}&limit=${rowsPerPage}`
        axios.get(GET_UNTABLE).then(response => {
            setTableResults(response.data.data.results)
            setTotalResults(response.data.data.pagingDetails.totalResults)
            // setPageNumber(response.data.data.pagingDetails.currentPage)
            // if(response.data.data.results.length < rowsPerPage) {
            //     setRowsPerPage(response.data.data.results.length)
            // }
            // else {
            //     setRowsPerPage(25)
            // }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            checkErrorAPICall(error, dispatch, 'UN Numbers')
        })
    }

    useEffect(() => {
        // console.log("rows",rowsPerPage)
        if(open){
            setLoading(true)

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel('Operation canceled due to new request.')
            }
        
            cancelToken = axios.CancelToken.source()            
            getResults(pageNumber, search)

        }else{
            setTableResults([])
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, pageNumber, search, rowsPerPage])

    return (
        <div>
            <div className='bg-secondary rounded-top py-2 px-4 mb-4'>
                <div className="row d-flex justify-content-between p-0 border-bottom border-light my-2">
                    <div className="col-8">
                        <h5 className="modal-title">UN Lookup</h5>
                    </div>                
                    <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                        <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                        />
                    </div>
                </div>
                <Toolbar
                    search={search}
                    setSearch={setSearch}
                    columns={columns}
                    setColumns={setColumns}
                    results={tableResults}
                    setPageNumber={setPageNumber}
                    setLoading={setLoading}
                    tableFor='UNNumber'            
                    forModal={true}
                    notStylized={false}
                />
            </div>
            <MaterialTable
                isLoading={loading}
                data={tableResults}
                columns={columns}
                style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                options={{
                    thirdSortClick: false,
                    actionsColumnIndex: -1,                    
                    emptyRowsWhenPaging: false,
                    toolbar: false,
                    draggable: false,
                    filtering: false,
                    rowStyle: (data, idx) => idx%2 !== 0 ? {
                        backgroundColor: '#f4f4f4',
                    } : {
                        backgroundColor: '#ffff',
                    },
                    pageSizeOptions: [10,25,50,100],
                    pageSize: rowsPerPage,
                    maxBodyHeight: fontSize >= 13 ? `${window.innerHeight - 355}px` : fontSize === 9 ? `${window.innerHeight - 330}px` : `${window.innerHeight - 340}px`,
                    headerStyle: { position: 'sticky', top: 0, backgroundColor: "#eceff4" },
                }}
                localization={{
                    pagination: {
                        labelRows: '',
                        labelRowsPerPage: 'UNNumber per page:'
                    }
                }}
                onPageChange={(page) => setPageNumber(page)}
                onRowsPerPageChange={(number) => setRowsPerPage(number)}
                components={{
                    Container: (props) => <Paper className={classes.table} {...props}/>,
                    Row: rowProps => <MTableBodyRowStyled {...rowProps} onRowClick={(e) => selectUNNumber(e, rowProps.data)} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,                    
                    Pagination: props =>  <TablePagination
                    {...props}
                    count={totalResults}
                    page={pageNumber}
                    onPageChange={(e, page) => {
                      setPageNumber(page)                     
                      getResults(page, null)
                    }}
                  />
                }}
            />
        </div>
    )
}

export default UNTable