import styled from 'styled-components';
import { Chip, Paper, TextField } from '@mui/material';

export const TextFieldStyled = styled(TextField)`
    & ::before {
        border: none !important
    };
    & ::after {
        border: none !important
    };
    & .MuiInputBase-input {
        color: #5E6278 !important;
        margin-left: 3.25px
    };
`

export const AccessorialButton = styled.div`
    background: ${props => props.isSelected ? 'rgba(25, 118, 210, 0.08)' : 'white'};
    &:hover {
        background: ${props => props.isSelected ? '#e5eefa' : 'rgba(0, 0, 0, 0.04)'}
    };
    cursor: ${props => !props.isSelected && 'pointer'};
    padding: 5.20px 0px 5.20px 16.25px
`

export const ChipStyled = styled(Chip)`
    font-family: Poppins !important;
    font-weight: 500 !important;
    background: rgb(228, 230, 239) !important;
    & .MuiChip-deleteIcon {
        display: ${props => !props.chipforemail ? !props?.disabled ? 'flex' : 'none' : 'flex'};
    }
    height: 22px !important;
    margin: 2px !important;
`

export const PaperStyled = styled(Paper)`
    border-radius: 4px;
    z-index: 99;
    position: absolute;
    overflow: scroll;
    padding: 0px 20px 15px 20px;
    font-family: Poppins !important;
    color: #5E6278 !important;
    max-height: 160px;
    left: 10px;
    right: 10px
`