import { ModalStyled } from '../Modals.styled';
import { modalStyles } from '../ModalStyles';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { Backdrop, CircularProgress, Fade } from '@mui/material';
import { KTSVG } from '../../../_metronic/helpers';
import { UserModel } from '../../modules/auth/models/UserModel';
import { useState } from 'react';

const PickupDatePastModal = ({open, setOpen, dispatchAction}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [loading, setLoading] = useState(false)

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/x-circle.svg' className='svg-icon svg-icon-3x text-danger'/>
                                </div>
                                <div className="col-12 mt-5 d-flex justify-content-center">
                                    <p style={{fontSize: 18, fontWeight: 500, textAlign: 'center'}}>The pickup date is in the past so this shipment could not be as dispatched</p>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-secondary me-4"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-primary align-items-center"
                                        onClick={() => {
                                            setLoading(true)
                                            dispatchAction({
                                                makeEDI: user?.allowEDI,
                                                forceDispatch: true
                                            }, setLoading)
                                        }}
                                    >
                                        Set As Dispatched {loading && <CircularProgress color="inherit" size={15} />}
                                    </button>
                                </div>
                            </div>
                        </>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default PickupDatePastModal