export const CountryOptions = [
    {'Name': 'Afghanistan', 'Code': 'AF', 'CodePrimus': 'AFG'},
    {'Name': 'Åland Islands', 'Code': 'AX', 'CodePrimus': 'ALA'},
    {'Name': 'Albania', 'Code': 'AL', 'CodePrimus': 'ALB'},
    {'Name': 'Algeria', 'Code': 'DZ', 'CodePrimus': 'DZA'},
    {'Name': 'American Samoa', 'Code': 'AS', 'CodePrimus': 'ASM'},
    {'Name': 'Andorra', 'Code': 'AD', 'CodePrimus': 'AND'},
    {'Name': 'Angola', 'Code': 'AO', 'CodePrimus': 'AGO'},
    {'Name': 'Anguilla', 'Code': 'AI', 'CodePrimus': 'AIA'},
    {'Name': 'Antarctica', 'Code': 'AQ', 'CodePrimus': 'ATA'},
    {'Name': 'Antigua and Barbuda', 'Code': 'AG', 'CodePrimus': 'ATG'},
    {'Name': 'Argentina', 'Code': 'AR', 'CodePrimus': 'ARG'},
    {'Name': 'Armenia', 'Code': 'AM', 'CodePrimus': 'ARM'},
    {'Name': 'Aruba', 'Code': 'AW', 'CodePrimus': 'ABW'},
    {'Name': 'Australia', 'Code': 'AU', 'CodePrimus': 'AUS'},
    {'Name': 'Austria', 'Code': 'AT', 'CodePrimus': 'AUT'},
    {'Name': 'Azerbaijan', 'Code': 'AZ', 'CodePrimus': 'AZE'},
    {'Name': 'Bahamas', 'Code': 'BS', 'CodePrimus': 'BHS'},
    {'Name': 'Bahrain', 'Code': 'BH', 'CodePrimus': 'BHR'},
    {'Name': 'Bangladesh', 'Code': 'BD', 'CodePrimus': 'BGD'},
    {'Name': 'Barbados', 'Code': 'BB', 'CodePrimus': 'BRB'},
    {'Name': 'Belarus', 'Code': 'BY', 'CodePrimus': 'BLR'},
    {'Name': 'Belgium', 'Code': 'BE', 'CodePrimus': 'BEL'},
    {'Name': 'Belize', 'Code': 'BZ', 'CodePrimus': 'BLZ'},
    {'Name': 'Benin', 'Code': 'BJ', 'CodePrimus': 'BEN'},
    {'Name': 'Bermuda', 'Code': 'BM', 'CodePrimus': 'BMU'},
    {'Name': 'Bhutan', 'Code': 'BT', 'CodePrimus': 'BTN'},
    {'Name': 'Bolivia', 'Code': 'BO', 'CodePrimus': 'BOL'},
    {'Name': 'Bosnia and Herzegovina', 'Code': 'BA', 'CodePrimus': 'BIH'},
    {'Name': 'Botswana', 'Code': 'BW', 'CodePrimus': 'BWA'},
    {'Name': 'Bouvet Island', 'Code': 'BV', 'CodePrimus': 'BVT'},
    {'Name': 'Brazil', 'Code': 'BR', 'CodePrimus': 'BRA'},
    {'Name': 'British Indian Ocean Territory', 'Code': 'IO', 'CodePrimus': 'IOT'},
    {'Name': 'Brunei Darussalam', 'Code': 'BN', 'CodePrimus': 'BRN'},
    {'Name': 'Bulgaria', 'Code': 'BG', 'CodePrimus': 'BGR'},
    {'Name': 'Burkina Faso', 'Code': 'BF', 'CodePrimus': 'BFA'},
    {'Name': 'Burundi', 'Code': 'BI', 'CodePrimus': 'BDI'},
    {'Name': 'Cambodia', 'Code': 'KH', 'CodePrimus': 'KHM'},
    {'Name': 'Cameroon', 'Code': 'CM', 'CodePrimus': 'CMR'},
    {'Name': 'Canada', 'Code': 'CA', 'CodePrimus': 'CAN'},
    {'Name': 'Cape Verde', 'Code': 'CV', 'CodePrimus': 'CPV'},
    {'Name': 'Cayman Islands', 'Code': 'KY', 'CodePrimus': 'CYM'},
    {'Name': 'Central African Republic', 'Code': 'CF', 'CodePrimus': 'CAF'},
    {'Name': 'Chad', 'Code': 'TD', 'CodePrimus': 'TCD'},
    {'Name': 'Chile', 'Code': 'CL', 'CodePrimus': 'CHL'},
    {'Name': 'China', 'Code': 'CN', 'CodePrimus': 'CHN'},
    {'Name': 'Christmas Island', 'Code': 'CX', 'CodePrimus': 'CXR'},
    {'Name': 'Cocos (Keeling) Islands', 'Code': 'CC', 'CodePrimus': 'CCK'},
    {'Name': 'Colombia', 'Code': 'CO', 'CodePrimus': 'COL'},
    {'Name': 'Comoros', 'Code': 'KM', 'CodePrimus': 'COM'},
    {'Name': 'Congo', 'Code': 'CG', 'CodePrimus': 'COG'},
    {'Name': 'Congo, The Democratic Republic of the', 'Code': 'CD', 'CodePrimus': 'COD'},
    {'Name': 'Cook Islands', 'Code': 'CK', 'CodePrimus': 'COK'},
    {'Name': 'Costa Rica', 'Code': 'CR', 'CodePrimus': 'CRI'},
    {'Name': 'Cote D\'Ivoire', 'Code': 'CI', 'CodePrimus': 'CIV'},
    {'Name': 'Croatia', 'Code': 'HR', 'CodePrimus': 'HRV'},
    {'Name': 'Cuba', 'Code': 'CU', 'CodePrimus': 'CUB'},
    {'Name': 'Cyprus', 'Code': 'CY', 'CodePrimus': 'CYP'},
    {'Name': 'Czech Republic', 'Code': 'CZ', 'CodePrimus': 'CZE'},
    {'Name': 'Denmark', 'Code': 'DK', 'CodePrimus': 'DNK'},
    {'Name': 'Djibouti', 'Code': 'DJ', 'CodePrimus': 'DJI'},
    {'Name': 'Dominica', 'Code': 'DM', 'CodePrimus': 'DMA'},
    {'Name': 'Dominican Republic', 'Code': 'DO', 'CodePrimus': 'DOM'},
    {'Name': 'Ecuador', 'Code': 'EC', 'CodePrimus': 'ECU'},
    {'Name': 'Egypt', 'Code': 'EG', 'CodePrimus': 'EGY'},
    {'Name': 'El Salvador', 'Code': 'SV', 'CodePrimus': 'SLV'},
    {'Name': 'Equatorial Guinea', 'Code': 'GQ', 'CodePrimus': 'GNQ'},
    {'Name': 'Eritrea', 'Code': 'ER', 'CodePrimus': 'ERI'},
    {'Name': 'Estonia', 'Code': 'EE', 'CodePrimus': 'EST'},
    {'Name': 'Ethiopia', 'Code': 'ET', 'CodePrimus': 'ETH'},
    {'Name': 'Falkland Islands (Malvinas)', 'Code': 'FK', 'CodePrimus': 'FLK'},
    {'Name': 'Faroe Islands', 'Code': 'FO', 'CodePrimus': 'FRO'},
    {'Name': 'Fiji', 'Code': 'FJ', 'CodePrimus': 'FJI'},
    {'Name': 'Finland', 'Code': 'FI', 'CodePrimus': 'FIN'},
    {'Name': 'France', 'Code': 'FR', 'CodePrimus': 'FRA'},
    {'Name': 'French Guiana', 'Code': 'GF', 'CodePrimus': 'GUF'},
    {'Name': 'French Polynesia', 'Code': 'PF', 'CodePrimus': 'PYF'},
    {'Name': 'French Southern Territories', 'Code': 'TF', 'CodePrimus': 'ATF'},
    {'Name': 'Gabon', 'Code': 'GA', 'CodePrimus': 'GAB'},
    {'Name': 'Gambia', 'Code': 'GM', 'CodePrimus': 'GMB'},
    {'Name': 'Georgia', 'Code': 'GE', 'CodePrimus': 'GEO'},
    {'Name': 'Germany', 'Code': 'DE', 'CodePrimus': 'DEU'},
    {'Name': 'Ghana', 'Code': 'GH', 'CodePrimus': 'GHA'},
    {'Name': 'Gibraltar', 'Code': 'GI', 'CodePrimus': 'GIB'},
    {'Name': 'Greece', 'Code': 'GR', 'CodePrimus': 'GRC'},
    {'Name': 'Greenland', 'Code': 'GL', 'CodePrimus': 'GRL'},
    {'Name': 'Grenada', 'Code': 'GD', 'CodePrimus': 'GRD'},
    {'Name': 'Guadeloupe', 'Code': 'GP', 'CodePrimus': 'GLP'},
    {'Name': 'Guam', 'Code': 'GU', 'CodePrimus': 'GUM'},
    {'Name': 'Guatemala', 'Code': 'GT', 'CodePrimus': 'GTM'},
    {'Name': 'Guernsey', 'Code': 'GG', 'CodePrimus': 'GGY'},
    {'Name': 'Guinea', 'Code': 'GN', 'CodePrimus': 'GIN'},
    {'Name': 'Guinea-Bissau', 'Code': 'GW', 'CodePrimus': 'GNB'},
    {'Name': 'Guyana', 'Code': 'GY', 'CodePrimus': 'GUY'},
    {'Name': 'Haiti', 'Code': 'HT', 'CodePrimus': 'HTI'},
    {'Name': 'Heard Island and Mcdonald Islands', 'Code': 'HM', 'CodePrimus': 'HMD'},
    {'Name': 'Holy See (Vatican City State)', 'Code': 'VA', 'CodePrimus': 'VAT'},
    {'Name': 'Honduras', 'Code': 'HN', 'CodePrimus': 'HND'},
    {'Name': 'Hong Kong', 'Code': 'HK', 'CodePrimus': 'HKG'},
    {'Name': 'Hungary', 'Code': 'HU', 'CodePrimus': 'HUN'},
    {'Name': 'Iceland', 'Code': 'IS', 'CodePrimus': 'ISL'},
    {'Name': 'India', 'Code': 'IN', 'CodePrimus': 'IND'},
    {'Name': 'Indonesia', 'Code': 'ID', 'CodePrimus': 'IDN'},
    {'Name': 'Iran, Islamic Republic Of', 'Code': 'IR', 'CodePrimus': 'IRN'},
    {'Name': 'Iraq', 'Code': 'IQ', 'CodePrimus': 'IRQ'},
    {'Name': 'Ireland', 'Code': 'IE', 'CodePrimus': 'IRL'},
    {'Name': 'Isle of Man', 'Code': 'IM', 'CodePrimus': 'IMN'},
    {'Name': 'Israel', 'Code': 'IL', 'CodePrimus': 'ISR'},
    {'Name': 'Italy', 'Code': 'IT', 'CodePrimus': 'ITA'},
    {'Name': 'Jamaica', 'Code': 'JM', 'CodePrimus': 'JAM'},
    {'Name': 'Japan', 'Code': 'JP', 'CodePrimus': 'JPN'},
    {'Name': 'Jersey', 'Code': 'JE', 'CodePrimus': 'JEY'},
    {'Name': 'Jordan', 'Code': 'JO', 'CodePrimus': 'JOR'},
    {'Name': 'Kazakhstan', 'Code': 'KZ', 'CodePrimus': 'KAZ'},
    {'Name': 'Kenya', 'Code': 'KE', 'CodePrimus': 'KEN'},
    {'Name': 'Kiribati', 'Code': 'KI', 'CodePrimus': 'KIR'},
    {'Name': 'Korea, Democratic Republic of', 'Code': 'KP', 'CodePrimus': 'PRK'},
    {'Name': 'Korea, Republic of', 'Code': 'KR', 'CodePrimus': 'KOR'},
    {'Name': 'Kuwait', 'Code': 'KW', 'CodePrimus': 'KWT'},
    {'Name': 'Kyrgyzstan', 'Code': 'KG', 'CodePrimus': 'KGZ'},
    {'Name': 'Lao Democratic Republic', 'Code': 'LA', 'CodePrimus': 'LAO'},
    {'Name': 'Latvia', 'Code': 'LV', 'CodePrimus': 'LVA'},
    {'Name': 'Lebanon', 'Code': 'LB', 'CodePrimus': 'LBN'},
    {'Name': 'Lesotho', 'Code': 'LS', 'CodePrimus': 'LSO'},
    {'Name': 'Liberia', 'Code': 'LR', 'CodePrimus': 'LBR'},
    {'Name': 'Libyan Arab Jamahiriya', 'Code': 'LY', 'CodePrimus': 'LBY'},
    {'Name': 'Liechtenstein', 'Code': 'LI', 'CodePrimus': 'LIE'},
    {'Name': 'Lithuania', 'Code': 'LT', 'CodePrimus': 'LTU'},
    {'Name': 'Luxembourg', 'Code': 'LU', 'CodePrimus': 'LUX'},
    {'Name': 'Macao', 'Code': 'MO', 'CodePrimus': 'MAC'},
    {'Name': 'Macedonia', 'Code': 'MK', 'CodePrimus': 'MKD'},
    {'Name': 'Madagascar', 'Code': 'MG', 'CodePrimus': 'MDG'},
    {'Name': 'Malawi', 'Code': 'MW', 'CodePrimus': 'MWI'},
    {'Name': 'Malaysia', 'Code': 'MY', 'CodePrimus': 'MYS'},
    {'Name': 'Maldives', 'Code': 'MV', 'CodePrimus': 'MDV'},
    {'Name': 'Mali', 'Code': 'ML', 'CodePrimus': 'MLI'},
    {'Name': 'Malta', 'Code': 'MT', 'CodePrimus': 'MLT'},
    {'Name': 'Marshall Islands', 'Code': 'MH', 'CodePrimus': 'MHL'},
    {'Name': 'Martinique', 'Code': 'MQ', 'CodePrimus': 'MTQ'},
    {'Name': 'Mauritania', 'Code': 'MR', 'CodePrimus': 'MRT'},
    {'Name': 'Mauritius', 'Code': 'MU', 'CodePrimus': 'MUS'},
    {'Name': 'Mayotte', 'Code': 'YT', 'CodePrimus': 'MYT'},
    {'Name': 'Mexico', 'Code': 'MX', 'CodePrimus': 'MEX'},
    {'Name': 'Micronesia', 'Code': 'FM', 'CodePrimus': 'FSM'},
    {'Name': 'Moldova, Republic of', 'Code': 'MD', 'CodePrimus': 'MDA'},
    {'Name': 'Monaco', 'Code': 'MC', 'CodePrimus': 'MCO'},
    {'Name': 'Mongolia', 'Code': 'MN', 'CodePrimus': 'MNG'},
    {'Name': 'Montserrat', 'Code': 'MS', 'CodePrimus': 'MSR'},
    {'Name': 'Morocco', 'Code': 'MA', 'CodePrimus': 'MAR'},
    {'Name': 'Mozambique', 'Code': 'MZ', 'CodePrimus': 'MOZ'},
    {'Name': 'Myanmar', 'Code': 'MM', 'CodePrimus': 'MMR'},
    {'Name': 'Namibia', 'Code': 'NA', 'CodePrimus': 'NAM'},
    {'Name': 'Nauru', 'Code': 'NR', 'CodePrimus': 'NRU'},
    {'Name': 'Nepal', 'Code': 'NP', 'CodePrimus': 'NPL'},
    {'Name': 'Netherlands', 'Code': 'NL', 'CodePrimus': 'NLD'},
    {'Name': 'New Caledonia', 'Code': 'NC', 'CodePrimus': 'NCL'},
    {'Name': 'New Zealand', 'Code': 'NZ', 'CodePrimus': 'NZL'},
    {'Name': 'Nicaragua', 'Code': 'NI', 'CodePrimus': 'NIC'},
    {'Name': 'Niger', 'Code': 'NE', 'CodePrimus': 'NER'},
    {'Name': 'Nigeria', 'Code': 'NG', 'CodePrimus': 'NGA'},
    {'Name': 'Niue', 'Code': 'NU', 'CodePrimus': 'NIU'},
    {'Name': 'Norfolk Island', 'Code': 'NF', 'CodePrimus': 'NFK'},
    {'Name': 'Northern Mariana Islands', 'Code': 'MP', 'CodePrimus': 'MNP'},
    {'Name': 'Norway', 'Code': 'NO', 'CodePrimus': 'NOR'},
    {'Name': 'Oman', 'Code': 'OM', 'CodePrimus': 'OMN'},
    {'Name': 'Pakistan', 'Code': 'PK', 'CodePrimus': 'PAK'},
    {'Name': 'Palau', 'Code': 'PW', 'CodePrimus': 'PLW'},
    {'Name': 'Palestinian Territory, Occupied', 'Code': 'PS', 'CodePrimus': 'PSE'},
    {'Name': 'Panama', 'Code': 'PA', 'CodePrimus': 'PAN'},
    {'Name': 'Papua New Guinea', 'Code': 'PG', 'CodePrimus': 'PNG'},
    {'Name': 'Paraguay', 'Code': 'PY', 'CodePrimus': 'PRY'},
    {'Name': 'Peru', 'Code': 'PE', 'CodePrimus': 'PER'},
    {'Name': 'Philippines', 'Code': 'PH', 'CodePrimus': 'PHL'},
    {'Name': 'Pitcairn', 'Code': 'PN', 'CodePrimus': 'PCN'},
    {'Name': 'Poland', 'Code': 'PL', 'CodePrimus': 'POL'},
    {'Name': 'Portugal', 'Code': 'PT', 'CodePrimus': 'PRT'},
    {'Name': 'Puerto Rico', 'Code': 'PR', 'CodePrimus': 'PRI'},
    {'Name': 'Qatar', 'Code': 'QA', 'CodePrimus': 'QAT'},
    {'Name': 'Reunion', 'Code': 'RE', 'CodePrimus': 'REU'},
    {'Name': 'Romania', 'Code': 'RO', 'CodePrimus': 'ROU'},
    {'Name': 'Russian Federation', 'Code': 'RU', 'CodePrimus': 'RUS'},
    {'Name': 'RWANDA', 'Code': 'RW', 'CodePrimus': 'RWA'},
    {'Name': 'Saint Helena', 'Code': 'SH', 'CodePrimus': 'SHN'},
    {'Name': 'Saint Kitts and Nevis', 'Code': 'KN', 'CodePrimus': 'KNA'},
    {'Name': 'Saint Lucia', 'Code': 'LC', 'CodePrimus': 'LCA'},
    {'Name': 'Saint Pierre and Miquelon', 'Code': 'PM', 'CodePrimus': 'SPM'},
    {'Name': 'Saint Vincent and the Grenadines', 'Code': 'VC', 'CodePrimus': 'VCT'},
    {'Name': 'Samoa', 'Code': 'WS', 'CodePrimus': 'WSM'},
    {'Name': 'San Marino', 'Code': 'SM', 'CodePrimus': 'SMR'},
    {'Name': 'Sao Tome and Principe', 'Code': 'ST', 'CodePrimus': 'STP'},
    {'Name': 'Saudi Arabia', 'Code': 'SA', 'CodePrimus': 'SAU'},
    {'Name': 'Senegal', 'Code': 'SN', 'CodePrimus': 'SEN'},
    {'Name': 'Serbia', 'Code': 'RS', 'CodePrimus': 'SRB'},
    {'Name': 'Seychelles', 'Code': 'SC', 'CodePrimus': 'SYC'},
    {'Name': 'Sierra Leone', 'Code': 'SL', 'CodePrimus': 'SLE'},
    {'Name': 'Singapore', 'Code': 'SG', 'CodePrimus': 'SGP'},
    {'Name': 'Slovakia', 'Code': 'SK', 'CodePrimus': 'SVK'},
    {'Name': 'Slovenia', 'Code': 'SI', 'CodePrimus': 'SVN'},
    {'Name': 'Solomon Islands', 'Code': 'SB', 'CodePrimus': 'SLB'},
    {'Name': 'Somalia', 'Code': 'SO', 'CodePrimus': 'SOM'},
    {'Name': 'South Africa', 'Code': 'ZA', 'CodePrimus': 'ZAF'},
    {'Name': 'South Georgia and the South Sandwich Islands', 'Code': 'GS', 'CodePrimus': 'SGS'},
    {'Name': 'Spain', 'Code': 'ES', 'CodePrimus': 'ESP'},
    {'Name': 'Sri Lanka', 'Code': 'LK', 'CodePrimus': 'LKA'},
    {'Name': 'Sudan', 'Code': 'SD', 'CodePrimus': 'SDN'},
    {'Name': 'SuriName', 'Code': 'SR', 'CodePrimus': 'SUR'},
    {'Name': 'Svalbard and Jan Mayen', 'Code': 'SJ', 'CodePrimus': 'SJM'},
    {'Name': 'Swaziland', 'Code': 'SZ', 'CodePrimus': 'SWZ'},
    {'Name': 'Sweden', 'Code': 'SE', 'CodePrimus': 'SWE'},
    {'Name': 'Switzerland', 'Code': 'CH', 'CodePrimus': 'CHE'},
    {'Name': 'Syrian Arab Republic', 'Code': 'SY', 'CodePrimus': 'SYR'},
    {'Name': 'Taiwan, Province of China', 'Code': 'TW', 'CodePrimus': 'TWN'},
    {'Name': 'Tajikistan', 'Code': 'TJ', 'CodePrimus': 'TJK'},
    {'Name': 'Tanzania, United Republic of', 'Code': 'TZ', 'CodePrimus': 'TZA'},
    {'Name': 'Thailand', 'Code': 'TH', 'CodePrimus': 'THA'},
    {'Name': 'Timor-Leste', 'Code': 'TL', 'CodePrimus': 'TLS'},
    {'Name': 'Togo', 'Code': 'TG', 'CodePrimus': 'TGO'},
    {'Name': 'Tokelau', 'Code': 'TK', 'CodePrimus': 'TKL'},
    {'Name': 'Tonga', 'Code': 'TO', 'CodePrimus': 'TON'},
    {'Name': 'Trinidad and Tobago', 'Code': 'TT', 'CodePrimus': 'TTO'},
    {'Name': 'Tunisia', 'Code': 'TN', 'CodePrimus': 'TUN'},
    {'Name': 'Turkey', 'Code': 'TR', 'CodePrimus': 'TUR'},
    {'Name': 'Turkmenistan', 'Code': 'TM', 'CodePrimus': 'TKM'},
    {'Name': 'Turks and Caicos Islands', 'Code': 'TC', 'CodePrimus': 'TCA'},
    {'Name': 'Tuvalu', 'Code': 'TV', 'CodePrimus': 'TUV'},
    {'Name': 'Uganda', 'Code': 'UG', 'CodePrimus': 'UGA'},
    {'Name': 'Ukraine', 'Code': 'UA', 'CodePrimus': 'UKR'},
    {'Name': 'United Arab Emirates', 'Code': 'AE', 'CodePrimus': 'ARE'},
    {'Name': 'United Kingdom', 'Code': 'GB', 'CodePrimus': 'GBR'},
    {'Name': 'United States', 'Code': 'US', 'CodePrimus': 'USA'},
    {'Name': 'Uruguay', 'Code': 'UY', 'CodePrimus': 'URY'},
    {'Name': 'Uzbekistan', 'Code': 'UZ', 'CodePrimus': 'UZB'},
    {'Name': 'Vanuatu', 'Code': 'VU', 'CodePrimus': 'VUT'},
    {'Name': 'Venezuela', 'Code': 'VE', 'CodePrimus': 'VEN'},
    {'Name': 'Viet Nam', 'Code': 'VN', 'CodePrimus': 'VNM'},
    {'Name': 'Virgin Islands, British', 'Code': 'VG', 'CodePrimus': 'VGB'},
    {'Name': 'Virgin Islands, U.S.', 'Code': 'VI', 'CodePrimus': 'VIR'},
    {'Name': 'Wallis and Futuna', 'Code': 'WF', 'CodePrimus': 'WLF'},
    {'Name': 'Western Sahara', 'Code': 'EH', 'CodePrimus': 'ESH'},
    {'Name': 'Yemen', 'Code': 'YE', 'CodePrimus': 'YEM'},
    {'Name': 'Zambia', 'Code': 'ZM', 'CodePrimus': 'ZMB'},
    {'Name': 'Zimbabwe', 'Code': 'ZW', 'CodePrimus': 'ZWE'}
]