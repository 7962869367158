import { IconButton, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CancelProcressModal from '../Modals/CancelProcessModal/CancelProcressModal';
import { DivStyled } from './Toolbar.styled';
import DownloadIcon from '@mui/icons-material/Download';
import SelectExportType from '../LTLandVolumeTLPages/SelectExportType/SelectExportType';
import Accessorials from '../LTLandVolumeTLPages/AccesorialsComponent/Accessorials';
import { UserModel } from '../../modules/auth/models/UserModel';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const Toolbar = ({loadingRates, setOpenUploadFileModal, data, setData, lastCopy, source, setOpenWarningCallsModal, getRates, invalidZipcodes, accessorials, setAccessorials}) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [openCancelProcessModal, setOpenCancelProcessModal] = useState(false)
    const [fontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    const handleLocalStorage = () => {
        window.localStorage.setItem("fontSize", fontSize.toString());
        window.dispatchEvent(new Event("storage"));
    };
    
    useEffect(() => {
        handleLocalStorage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fontSize])

    const [columnsFile] = useState([
        { title: 'BOL', field: 'BOL'},
        { title: 'Shipper Name', field: 'ShipperName'},
        { title: 'Consignee Name', field: 'ConsigneeName'},
        { title: 'Pieces', field: 'Pieces'},
        { title: 'Carrier', field: 'Carrier'},
        { title: 'Transit', field: 'Transit'},
        { title: 'Total', field: 'Total'},
    ])

    return (
        <>
            <DivStyled className="row align-items-center mb-8" fontSize={fontSize} style={{borderBottom: '1px solid #0095E8', paddingBottom: '20px'}}>
                <div className="col-3 col-sm-2 d-flex align-items-center">
                    <Tooltip title="Upload file">
                        <IconButton disabled={loadingRates} onClick={() => setOpenUploadFileModal(true)}>
                            <UploadFileIcon sx={{fontSize: 24, color: loadingRates ? 'rgba(0, 0, 0, 0.26)' : '#3F4254'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Paste data from clipboard">
                        <IconButton disabled={loadingRates} onClick={() => lastCopy()}>
                            <ContentPasteIcon sx={{fontSize: 23, color: loadingRates ? 'rgba(0, 0, 0, 0.26)' : '#3F4254'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download Templates">
                        <a href='https://shipprimus.com/templates/TemplatesBatchBookV2.zip' download>
                            <IconButton disabled={loadingRates}>
                                <DownloadIcon sx={{fontSize: 25, color: loadingRates ? 'rgba(0, 0, 0, 0.26)' : '#3F4254'}}/>
                            </IconButton>
                        </a>
                    </Tooltip>
                </div>
                <label className={`col-2 col-xl-1 col-form-label d-none d-sm-flex`}>Accessorials</label>
                <div className={`col-sm-5 col-xl-6 d-none d-sm-block`} style={{position: 'relative'}}>
                    <Accessorials forBatchBooking={true} selectedAcc={accessorials} setSelectedAcc={setAccessorials} accessorialsList={user?.enabledAccessorials} />
                </div>
                <div className="col-9 col-sm-3 d-flex justify-content-end">
                    {
                        data.length ?
                            loadingRates
                            ? <button
                                className='btn btn-danger d-none d-xl-flex ms-6 align-items-center me-6'
                                onClick={() => setOpenCancelProcessModal(true)}
                            >
                                Cancel
                            </button>
                            : <button
                                className='btn btn-primary d-none d-xl-flex ms-6 align-items-center me-6'
                                onClick={() => {
                                    if(data.length > 500){
                                        setOpenWarningCallsModal(true)
                                    }else{
                                        getRates()
                                    }
                                }}
                            >
                                Process
                            </button>
                        : null
                    }
                    <SelectExportType
                        fileName={'BatchBooking'}
                        columns={columnsFile}
                        dataColumns={data.map(singleData => {
                            const separated = singleData.Accessorials?.length && singleData.Accessorials?.reduce((prev, current) => `${prev}, ${current}`)
                            return {
                                ...singleData,
                                BillTo: parseInt(singleData?.BillTo)?.toFixed(2),
                                AccessorialsTogether: separated || ''
                            }
                        })}
                    />
                </div>
                <div className="col-12 d-flex d-sm-none">
                    <label className={`col-3 col-form-label`}>Accessorials</label>
                    <div className={`col-9`} style={{position: 'relative'}}>
                        <Accessorials forBatchBooking={true} selectedAcc={accessorials} setSelectedAcc={setAccessorials} accessorialsList={user?.enabledAccessorials} />
                    </div>
                </div>
            </DivStyled>
            <DivStyled className="row align-items-center d-flex d-xl-none pb-2" fontSize={fontSize}>
                <div className="col-12 d-flex d-xl-none mt-5">
                    {
                        data.length ?
                            loadingRates
                            ? <button
                                className='btn btn-danger d-flex d-xl-none align-items-center justify-content-center w-100 mb-5'
                                onClick={() => setOpenCancelProcessModal(true)}
                            >
                                Cancel
                            </button>
                            :   <button
                                    className='btn btn-primary d-flex d-xl-none align-items-center justify-content-center w-100 mb-5'
                                    onClick={() => {
                                        if(data.length > 500){
                                            setOpenWarningCallsModal(true)
                                        }else{
                                            getRates()
                                        }
                                    }}
                                >
                                    Process
                            </button>
                        : null
                    }
                </div>
            </DivStyled>
            <CancelProcressModal
                open={openCancelProcessModal}
                setOpen={setOpenCancelProcessModal}
                source={source}
                data={data}
                setData={setData}
                invalidZipcodes={invalidZipcodes}
            />
        </>
    )
}

export default Toolbar