import { Backdrop, Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { modalStyles } from '../ModalStyles';
import MinMaxForm from './MinMaxForm';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RestrictionsModalProps } from '../../pages/vendors/models';
import { DivPaperStyled, ModalStyled } from '../Modals.styled';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { RootState } from '../../../setup';

const GET_VENDOR = '/api/v1/database/vendor'

const RestrictionsModal = ({open, setOpen, selectedRow, getVendors, editVendor, setDataFormik}: RestrictionsModalProps) => {
    const classes = modalStyles({});
    const dispatch = useDispatch()
    const [descriptions] = useState([
        'Pieces',
        'Piece Weight',
        'Total Weight',
        'Length',
        'Width',
        'Height',
        'CFT'
    ])
    const [min, setMin] = useState({
        pieces: null,
        pieceWeight: null,
        totalWeight: null,
        length: null,
        width: null,
        height: null,
        CFT: null,
    })
    const [max, setMax] = useState({
        pieces: null,
        pieceWeight: null,
        totalWeight: null,
        length: null,
        width: null,
        height: null,
        CFT: null,
    })
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const saveMaxMin = () => {
        axios.put(`${GET_VENDOR}/${selectedRow?.id}`, {
            ...selectedRow,
            APICredentials: {
                ...selectedRow?.APICredentials,
                restrictions: {
                    ...selectedRow?.APICredentials.restrictions,
                    maximums: {
                        pieces: max.pieces || 0,
                        pieceWeight: max.pieceWeight || 0,
                        pieceLength: max.length || 0,
                        pieceWidth: max.width || 0,
                        pieceHeight: max.height || 0,
                        totalWeight: max.totalWeight || 0,
                        CFT: max.CFT || 0
                    },
                    minimums: {
                        pieces: min.pieces || 0,
                        pieceWeight: min.pieceWeight || 0,
                        pieceLength: min.length || 0,
                        pieceWidth: min.width || 0,
                        pieceHeight: min.height || 0,
                        totalWeight: min.totalWeight || 0,
                        CFT: min.CFT || 0
                    }
                }
            }
        }).then(() => {
            getVendors()
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Vendor')
        })
    }

    useEffect(() => {
        if(open){
            const maximums = editVendor ? selectedRow?.maximums : selectedRow?.APICredentials?.restrictions?.maximums
            const minimums = editVendor ? selectedRow?.minimums : selectedRow?.APICredentials?.restrictions?.minimums

            setMax({
                pieces: maximums?.pieces || null,
                pieceWeight: maximums?.pieceWeight || null,
                totalWeight: maximums?.totalWeight || null,
                length: maximums?.pieceLength || null,
                width: maximums?.pieceWidth || null,
                height: maximums?.pieceHeight || null,
                CFT: maximums?.CFT || null,
            })

            setMin({
                pieces: minimums?.pieces || null,
                pieceWeight: minimums?.pieceWeight || null,
                totalWeight: minimums?.totalWeight || null,
                length: minimums?.pieceLength || null,
                width: minimums?.pieceWidth || null,
                height: minimums?.pieceHeight || null,
                CFT: minimums?.CFT || null,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paperEditModalRestrictions}>
                    <div className="row modal-header p-0 pb-2">
                        <div className="col-8">
                            <h5 className="modal-title">Vendor Restrictions</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={() => setOpen(false)}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <div className="row mt-5">
                            <div className="col-4">
                                <h6 className='mb-5'>Description</h6>
                                {descriptions.map(description => {
                                    return (
                                        <div key={description} style={{height: fontSize === 9 ? 30 : fontSize === 11 ? 40 : 53.13}} className='d-flex align-items-center mb-3'><label className='form-label m-0'>{description}</label></div>
                                    )
                                })}
                            </div>
                            <div className="col-4">
                                <h6 className='mb-5'>Min</h6>
                                <MinMaxForm
                                    data={min}
                                    setData={setMin}
                                />
                            </div>
                            <div className="col-4">
                                <h6 className='mb-5'>Max</h6>
                                <MinMaxForm
                                    data={max}
                                    setData={setMax}
                                />
                            </div>
                        </div>
                        <div className="row mt-5 mb-3">
                            <div className="col-6">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <button
                                    className="btn btn-danger me-4"
                                    onClick={() => {
                                        setMin({
                                            pieces: '',
                                            pieceWeight: '',
                                            totalWeight: '',
                                            length: '',
                                            width: '',
                                            height: '',
                                            CFT: '',
                                        })
                                        setMax({
                                            pieces: '',
                                            pieceWeight: '',
                                            totalWeight: '',
                                            length: '',
                                            width: '',
                                            height: '',
                                            CFT: '',
                                        })
                                    }}
                                >
                                    Clear
                                </button>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setOpen(false)
                                        if(!editVendor){
                                            saveMaxMin()
                                        }else{
                                            setDataFormik('APIIntegration.restrictions.maximums', {
                                                pieces: max.pieces || 0,
                                                pieceWeight: max.pieceWeight || 0,
                                                pieceLength: max.length || 0,
                                                pieceWidth: max.width || 0,
                                                pieceHeight: max.height || 0,
                                                totalWeight: max.totalWeight || 0,
                                                CFT: max.CFT || 0
                                            })

                                            setDataFormik('APIIntegration.restrictions.minimums', {
                                                pieces: min.pieces || 0,
                                                pieceWeight: min.pieceWeight || 0,
                                                pieceLength: min.length || 0,
                                                pieceWidth: min.width || 0,
                                                pieceHeight: min.height || 0,
                                                totalWeight: min.totalWeight || 0,
                                                CFT: min.CFT || 0
                                            })
                                        }
                                    }}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default RestrictionsModal