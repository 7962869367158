import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import { KTSVG } from '../../../_metronic/helpers';
import { AddOrDeleteFreightProps } from '../../pages/drayage/models';
import { DivPointer } from './DrayagePage.styled';

const AddOrDeleteFreight = ({setDataFormik, freightInfo, index, freight}: AddOrDeleteFreightProps) => {
    const addNewFreight = () => {
        const newData = {
            id: uuidv4(),
            container: 20,
            qty: '',
            weightus: '',
            weight: '',
            UOM: {
              value: 'US',
              oldValue: ''
            },
            overweight: false,
            accessorials: [],
        }
        const freightNewArray = [...freightInfo, newData]
        setDataFormik('freightInfo', freightNewArray)
    }

    const removeFreight = (id) => {
        let newArray = freightInfo.filter(data => data.id !== id)
        setDataFormik('freightInfo', newArray)
    }

    return (
        <>
            <DivPointer onClick={() => addNewFreight()} className={index === 0 ? "me-0 me-sm-2" : "me-2"}>
                <KTSVG path='/media/icons/bootstrap/plus-circle-fill.svg' className='svg-icon svg-icon-1 text-primary'/>
            </DivPointer>
            {
                index > 0 ?
                <DivPointer onClick={() => removeFreight(freight.id)} className='col-1 col-sm-3'>
                    <KTSVG path='/media/icons/bootstrap/dash-circle-fill.svg' className='svg-icon svg-icon-1 text-danger'/>
                </DivPointer>
                : <div className='col-3 d-none d-sm-flex'>
                    
                </div>
            }
        </>
    )
}

export default AddOrDeleteFreight