import { accessorialsBatchQuoting } from "./accessorialsBatchQuoting"

let US = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");

export const normalizeCell = (cell) => {
    accessorialsBatchQuoting(cell)
    cell.OriginZipcode = (cell.OriginZipcode?.toString().length < 5 && US.test(cell.OriginZipcode?.toString().padStart(5, '0'))) ? cell.OriginZipcode?.toString().padStart(5, '0') : cell.OriginZipcode
    cell.DestinationZipcode = (cell.DestinationZipcode?.toString().length < 5 && US.test(cell.DestinationZipcode?.toString().padStart(5, '0'))) ? cell.DestinationZipcode?.toString().padStart(5, '0') : cell.DestinationZipcode
    cell.Pieces = parseInt(cell.Pieces)
    cell.Weight = parseFloat(cell.Weight)
    cell.Length = parseFloat(cell.Length)
    cell.Width = parseFloat(cell.Width)
    cell.Height = parseFloat(cell.Height)
    cell.Class = parseFloat(cell.Class)
    cell.OriginState = cell.OriginState?.toUpperCase()
    cell.OriginCity = cell.OriginCity?.toUpperCase()
    cell.DestinationState = cell.DestinationState?.toUpperCase()
    cell.DestinationCity = cell.DestinationCity?.toUpperCase()
}