/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../../setup/redux/RootReducer';
import { ButtonStyled } from './HeaderWrapper.styled';
import { IconButton, Tooltip } from '@mui/material';
import { actionTypes } from '../../../../app/modules/auth/redux/PagesRedux';

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const customerInfo = useSelector(({auth}: any) => auth.customerInfo)  

  const handleLocalStorage = () => {
    window.localStorage.setItem("fontSize", fontSize.toString());
    window.dispatchEvent(new Event("storage"));
  };

  useEffect(() => {
    handleLocalStorage()
    dispatch({type: actionTypes.SetFontSize, payload: parseInt(localStorage.getItem('fontSize'))})
  }, [fontSize])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/home' className='d-lg-none'>            
              <img alt='Logo' src={customerInfo?.portalMainLogo ? customerInfo?.portalMainLogo : process.env.PUBLIC_URL+'/media/logos/ShipprimusLogo.png'} className='h-30px'/>
            </Link>
          </div>
        )}
        {/* end::Logo */}
        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/home' className='d-lg-none'>
              {
                customerInfo && <img alt='Logo' src={customerInfo?.portalMainLogo ? customerInfo?.portalMainLogo : process.env.PUBLIC_URL+'/media/logos/favicon.ico'} className='h-30px'/>
              }
            </Link>
          </div>
        )}
        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          <div className='d-flex align-items-center' id='kt_header_nav'>
            <DefaultTitle />
            <span className='h-20px border-gray-300 border-start ms-3 mx-2'></span>
            <div className='d-flex align-items-end'>
              <Tooltip title="Decrease Font Size">
                <ButtonStyled
                  className={`d-flex justify-content-center`}
                  onClick={() => {
                    setFontSize(fontSize > 9 ? fontSize - 2 : fontSize)
                  }}
                  variant="text"
                >
                  <span className="d-flex align-items-end font-normal">A</span>
                </ButtonStyled>
              </Tooltip>
              <Tooltip title="Increase Font Size">
                <ButtonStyled
                  className={`d-flex justify-content-center`}
                  onClick={() => {
                    setFontSize(fontSize < 19 ? fontSize + 2 : fontSize)
                  }}
                  variant="text"
                >
                  <span className="d-flex align-items-end font-big">A</span>
                </ButtonStyled>
              </Tooltip>
              <Tooltip title="Reset Font Size">
                <IconButton
                  onClick={() => setFontSize(13)}
                >
                  <KTSVG className="svg-icon svg-icon-2 mt-1" style={{color: '#181C32'}} path='/media/icons/bootstrap/arrow-counterclockwise.svg'/>
                </IconButton>
              </Tooltip>
            </div>
            <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
          </div>
          <div className='d-flex align-items-stretch flex-shrink-0'>
            {/* {
              location.pathname === '/book/new-booking' && <div className="d-none d-lg-flex align-items-center me-15">
                <h5 className='mb-0 me-2' style={{fontSize: 14}}>Bill To</h5> <h5 className='mb-0' style={{fontSize: 14, fontWeight: 400}}>{user?.billToInformation.name}</h5>
              </div>
            } */}
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
