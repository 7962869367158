import { MenuItem, Select } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { shallowEqual, useSelector } from "react-redux";
import { CountryOptions } from "../../data/CountryOptions";
import { UserModel } from "../../modules/auth/models/UserModel";
import { RootState } from '../../../setup/redux/RootReducer';
import { useEffect, useState } from "react";
import { PhoneInputProps } from "../../pages/shipping-locations/models";
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { PhoneInputReactStyled } from '../Inputs.styled';

const PhoneInput = ({value, setDataFormik, name, countryCode, selectSmall, className, deleteSelectCountry, disabled, error}: PhoneInputProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const [country, setCountry] = useState(CountryOptions?.find(c => c.CodePrimus === countryCode)?.Code || 'US')
    const [focused, setFocused] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classesSelect = makeStyles({
        select: {
            backgroundColor: '#EFF2F5',
            border: '1px solid #E4E6EF',
            borderRight: 'none',
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius:'0px !important',
            height: fontSize === 9 ? '30px' : fontSize === 11 ? '40px' : 'var(--input-height)',
            minWidth: !selectSmall ? window.innerWidth < 576 ? '74px' : '87.50px' : '',
            width: '100%',
            '& fieldset': {
                border: 'none',
            },
        },
        list: {
            "& li": {
                fontFamily: 'Poppins',
                color: '#63627D',
                fontWeight: 500
            },
        }
    })
    const classesCountrySelect = classesSelect()

    const menuProps = {
        classes: {
            list: classesCountrySelect.list
        }
    }

    const getCountryOptionsAllowed = () => {
        let defaultCountries = user.defaultCountries
        let countriesArrayLocal = []
        defaultCountries?.forEach(country => {
            let allowedCountry = CountryOptions?.find(c => c.CodePrimus === country)
            countriesArrayLocal?.push(allowedCountry)
        })
        return countriesArrayLocal
    }

    useEffect(() => {
        if(countryCode?.length) {
            setCountry(countryCode.length === 3 ? CountryOptions?.find(c => c.CodePrimus === countryCode)?.Code || 'US' : countryCode)
        }
    }, [countryCode])
    
    return (
        <div className="row">
            <div className={`col-12 d-flex ${className}`}>
                {!deleteSelectCountry ?
                    <div style={{width: !selectSmall ? '100px' : ''}}>
                        <Select
                            onChange={(e) => {
                                setCountry(e.target.value)
                                setDataFormik(name, null)
                            }}
                            defaultValue={'US' || (getCountryOptionsAllowed().length > 0
                                            ? (getCountryOptionsAllowed().find(country => country.Code === 'US') ? 'US' : getCountryOptionsAllowed()[0].Code)
                                            : 'US')}
                            renderValue={() => <img className='m-0' alt='flag' src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`} width="24"/>}
                            className={classesCountrySelect.select}
                            MenuProps={menuProps}
                            inputProps={{ tabIndex: 1 }}
                            value={country}
                        >
                            {
                                getCountryOptionsAllowed().length > 0
                                ? getCountryOptionsAllowed()?.map((country, index) => {
                                    return (<MenuItem key={index} value={country.Code}>{country.Name}</MenuItem>)
                                })
                                : CountryOptions?.map((country, index) => {
                                    return (<MenuItem key={index} value={country.Code}>{country.Name}</MenuItem>)
                                })
                            }
                        </Select>
                    </div>
                    : null
                }
                <PhoneInputReactStyled 
                    international={false}
                    country={country as string}
                    value={value || null}
                    onChange={(value,) => setDataFormik(name, value || null)}
                    placeholder='Phone number'
                    autoComplete='new-password'
                    className='form-control'
                    style={{
                        borderTopLeftRadius: !deleteSelectCountry ? 0 : '0.475rem',
                        borderBottomLeftRadius: !deleteSelectCountry ? 0 : '0.475rem',
                        borderColor: (value && !isPossiblePhoneNumber(value) && focused) || error ? '#F1416C' : '#E4E6EF',
                        color: disabled && 'rgba(0, 0, 0, 0.38)'
                    }}
                    onBlur={() => setFocused(true)}
                    fontSize={fontSize}
                    disabled={disabled}
                    maxLength="14"
                />
            </div>
            
            {
                value && !isPossiblePhoneNumber(value) && focused &&
                <small className='text-danger' style={{paddingLeft: !deleteSelectCountry ? selectSmall ? '80px' : '100px' : '12px'}}>Invalid phone number</small>
            }
        </div>
    )
}

export default PhoneInput