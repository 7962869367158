export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  let url = (window.location.origin === 'https://appletv2dev.shipprimus.com' || window.location.origin === 'http://localhost:3011') ? 'https://sandbox-api-applet.shipprimus.com' : window.location.origin?.replace("portal", "api") 
  axios.defaults.baseURL = url
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()
      const accessTokenLS = JSON.parse(localStorage.getItem('primus-key'))?.accessToken
      config.headers['App-Id'] = 'PortalV2'

      if (accessTokenLS ? JSON.parse(accessTokenLS) : accessToken) {
        config.headers.Authorization = `Bearer ${accessTokenLS ? JSON.parse(accessTokenLS) : accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
