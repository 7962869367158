import { Backdrop, Fade } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { KTSVG } from '../../../../_metronic/helpers';
import { ActivateModalProps } from '../../../pages/shipping-locations/models';
import { modalStyles } from '../../ModalStyles';
import { DivPaperStyled, ModalStyled, Title } from '../../Modals.styled';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { RootState } from '../../../../setup';

const SHIPPINGLOCATION = '/api/v1/database/shippinglocation'

const ActivateModal = ({open, setOpen, rowSelected, getData}: ActivateModalProps) => {
    const classes = modalStyles({});
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const setToActive = () => {
        axios.put(`${SHIPPINGLOCATION}/${rowSelected.id}`, {
            code: rowSelected.code,
            name: rowSelected.name,
            address: rowSelected.address,
            address1: rowSelected.address1,
            phone: rowSelected.phone,
            fax: rowSelected.fax,
            email: rowSelected.email,
            city: rowSelected.city,
            state: rowSelected.state,
            country: rowSelected.country,
            zipcode: rowSelected.zipcode,
            active: true,
            contact: rowSelected.contact,
            contactPhone: rowSelected.contactPhone,
            opentime: rowSelected.opentime,
            closetime: rowSelected.closetime,
            remarks: rowSelected.remarks,
            alertUser: rowSelected.alertUser
        }).then(() => {
            getData()
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Set to active Shipping Location')
        })
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <Title>Are you sure you want to enable this location?</Title>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            setToActive()
                                            setOpen(false)
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ActivateModal