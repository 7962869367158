import React, { memo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const CarrierRender = ({rowData, loadingRates}) => {
    const cheaperCarriers = useSelector<RootState>(({pages}) => pages.cheaperCarriers, shallowEqual) as any
    let carrier = cheaperCarriers?.length && cheaperCarriers?.find(carrier => carrier.id === rowData.id)?.rate?.Carrier

    return (
        carrier === "No rates" ?
        <div className='text-muted'>{carrier}</div> :
        <span
            className='d-flex align-items-center mb-0'
        >
            {rowData.progress !== 100 ? ((cheaperCarriers?.length && cheaperCarriers?.find(carrier => carrier.id === rowData.id)?.rate?.Carrier) || '') : rowData.Carrier }
        </span>
    )
}

export default memo(CarrierRender)