/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { useSelector } from 'react-redux'
import Favicon from 'react-favicon'

const AuthLayout = () => {
  const customerInfo = useSelector(({auth}: any) => auth.customerInfo)

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])



  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={customerInfo?.portalBackground.includes('#') ? {
        backgroundSize: 'cover',
        backgroundColor: customerInfo?.portalBackground
      } : {
        backgroundImage: customerInfo?.portalBackground,
        backgroundSize: 'cover',
      }}
    >
      {customerInfo.customFavIcon && (
        <Favicon url={customerInfo.customFavIcon} animated={false}/>
      )}
      {/* begin::Content */}
      <div className={`d-flex flex-center flex-column flex-column-fluid ${window.innerHeight < 676 ? 'p-4' : 'p-10 pb-lg-20'}`}>
        {/* begin::Logo */}
        <div className={`${window.innerHeight < 676 ? 'mb-2' : 'mb-12'}`}>
          <img alt='Logo' src={toAbsoluteUrl(customerInfo?.portalMainLogo)}/>
        </div>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className={`w-lg-500px bg-white rounded shadow-sm mx-auto ${window.innerHeight < 676 ? 'p-7' : 'p-10 p-lg-15'}`}>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <div className="text-white">Powered by</div> <img alt='Shipprimus logo' src={process.env.PUBLIC_URL+'/media/logos/favicon.ico'} className='h-30px'/> <a href='https://www.shipprimus.com' target="_blank" rel="noopener noreferrer" className='text-white text-hover-primary'>Shipprimus.com</a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
