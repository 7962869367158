import { MenuItem, TextField } from '@mui/material'
import React from 'react'
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectStatusProps } from '../../pages/invoices/models';
import { stylesMUIInput } from '../stylesMUIInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const SelectStatus = ({filters, setFilters}: SelectStatusProps) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classes = useStyles({fontSize: fontSize})

    const menuProps = {
        classes: {
            list: classes.selectPaperList
        }
    }

    const onChangeType = (e) => {
        setFilters({...filters, status: e.target.value})
    }

    return (
        <TextField
            select
            label=" "
            value={filters.status}
            onChange={onChangeType}
            className={`${classes.selectMUI} w-100`}
            InputProps={{
                style: stylesMUIInput(fontSize),
            }}
            SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
                MenuProps: menuProps
            }}
            InputLabelProps={{shrink: false}}
        >
            <MenuItem value="All">Paid/Unpaid</MenuItem>
            <MenuItem value="Unpaid">Unpaid</MenuItem>
            <MenuItem value="Paid">Paid</MenuItem>
        </TextField>
    )
}

export default SelectStatus