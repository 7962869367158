import { useEffect, useState } from 'react'
import { ModalStyled } from '../Modals.styled'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { modalStyles } from '../ModalStyles'
import { Backdrop, Fade, LinearProgress, Tooltip } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers'
import RatesTable from './RatesTable'
import ClearIcon from '@mui/icons-material/Clear';

const RatesModal = ({open, setOpen, linearProgress, rates, setFormikValues, ratesStopped, setRatesStopped, source, setVendorName, vendorsFiltered, vendorsProcessed}) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [deleteToolbar, setDeleteToolbar] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(ratesStopped === true){
            setTimeout(() => {
                setDeleteToolbar(true)
            }, 2500);
        }else{
            setDeleteToolbar(false)
        }
    }, [ratesStopped])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paperRatesModal} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row modal-header p-0 pb-2 mb-5">
                        <div className="col-8">
                            <h5 className="modal-title">Rates</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x" 
                            />
                        </div>
                    </div>
                    <>
                        <div className="row">
                            <RatesTable
                                rates={rates}
                                setFormikValues={setFormikValues}
                                handleClose={handleClose}
                                linearProgress={linearProgress}
                                deleteToolbar={deleteToolbar}
                                ratesStopped={ratesStopped}
                                setRatesStopped={setRatesStopped}
                                source={source}
                                setVendorName={setVendorName}
                                vendorsFiltered={vendorsFiltered}
                                vendorsProcessed={vendorsProcessed}
                            />
                        </div>
                    </>
                    <div className="row mb-3 mt-5">
                        <div className="col-12 d-flex justify-content-end">
                            <button
                                className="btn btn-secondary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default RatesModal