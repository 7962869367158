import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import sessionStorage from 'redux-persist/es/storage/session'
import {put, takeLatest, select} from 'redux-saga/effects'
import {UserModel} from '../models/UserModel'
import {getUserByToken} from "./AuthCRUD";
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

const accessTokenLS = JSON.parse(localStorage.getItem('primus-key'))?.accessToken

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetRemember: '[Set Remember] Action',
  isTokenExp: '[Token Exp] Action',
  setCustomerInfo: '[Set Customer Info] Action'
}

const initialAuthState: IAuthState = {
  rememberMe: undefined,
  user: undefined,
  accessToken: accessTokenLS ? accessTokenLS : undefined,
  expToken: undefined,
  isTokenExp: false,
  customerInfo: {
    portalMainLogo: '',
    portalBackground: ''
  }
}

export interface IAuthState {
  rememberMe?: boolean
  user?: UserModel
  accessToken?: string,
  expToken: number,
  isTokenExp: boolean,
  customerInfo: object
}

export const reducer = persistReducer(
  {storage: sessionStorage, key: 'primus-key', whitelist: ['user', 'accessToken', 'expToken', 'rememberMe']},

  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.setCustomerInfo: {
        return {...state, customerInfo: action.payload}
      }

      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const expToken = action.payload?.expToken
        if(action.payload.rememberMe){
          let accessTokenObject = {'accessToken': JSON.stringify(accessToken), 'expToken': JSON.stringify(expToken)}
          localStorage.setItem('primus-key', JSON.stringify(accessTokenObject))
        }else{
          if(localStorage.getItem('primus-key')){
            localStorage.removeItem('primus-key')
          }
        }
        return {...state, accessToken, expToken, user: undefined}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        localStorage.removeItem('primus-key')
        /* return initialAuthState */
        return {
          rememberMe: undefined,
          user: undefined,
          accessToken: undefined,
          expToken: undefined,
          isTokenExp: false,
          customerInfo: state.customerInfo
        }
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.isTokenExp: {
        return {...state, isTokenExp: action.payload}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, rememberMe: boolean, expToken: number) => ({type: actionTypes.Login, payload: {accessToken, rememberMe, expToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  isTokenExp: (boolean) => ({type: actionTypes.isTokenExp, payload: boolean})
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const tokenLS = JSON.parse(localStorage.getItem('primus-key'))?.accessToken

    // @ts-ignore
    const getToken = (state) => state.auth.accessToken;
    // @ts-ignore
    let token = yield select(getToken)
    const user = yield getUserByToken(tokenLS ? JSON.parse(tokenLS) : token)
    yield put(actions.fulfillUser(user))
   })
}
