import Chart from 'react-google-charts';
import styled from 'styled-components';

export const ChartStyled = styled(Chart)`
    rect {
        fill: white;
    };
    text {
        font-family: 'Poppins' !important;
        font-size: 9.5px !important
    };
`

export const LinkStyled = styled.div`
    cursor: ${props => !props.disabled ? 'pointer' : 'default'};
    color: ${props => !props.disabled ? '#181c32' : '#5d6070'};
    display: flex;
    align-items: center;
    height: 40px;
    width: ${props => props.fontSize > 15 ? '400px' : '330px'};
    padding: 10px;
    background-color: ${props => !props.disabled ? 'white' : '#f2f2f2'};
    margin-bottom: 10px;
    border-radius: 0.475rem;
    &:hover {
        background-color: #f2f2f2;
    }
`
