import { Backdrop, Fade } from '@mui/material'
import { useState } from 'react'
import { DivPaperStyled, ModalStyled } from '../../../../app/components/Modals.styled'
import { modalStyles } from '../../../../app/components/ModalStyles'
import { KTSVG } from '../../../helpers'
import * as Yup from 'yup';
import { FormikState, useFormik } from 'formik'
import clsx from 'clsx'
import { DivRelative, IconButtonStyled } from '../../../../app/modules/auth/components/Login.styled'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

const RESET_PASSWORD = '/applet/v1/password/reset'

const ChangePasswordModal = ({open, setOpen}) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        reNewPassword: false
    })
    const [updated, setUpdated] = useState(false)

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required('Current Password is required'),
        newPassword: Yup.string()
            .min(8, 'Minimum 8 characters')
            .max(50, 'Maximum 50 characters')
            .required('New Password is required')
            .matches(
                /(?=.*[a-z])/,
                "Must contain one lower case character"
            )
            .matches(
                /(?=.*\d)|(?=.*\W)/,
                "Must contain one symbol or number"
            ),
        reNewPassword: Yup.string()
            .required('Re-enter New Password is required')
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    })
      
    const initialValues = {
        currentPassword: '',
        newPassword: '',
        reNewPassword: ''
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            axios.put(RESET_PASSWORD, {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
            })
            .then(({data}) => {
                setLoading(false)
                setUpdated(true)
                setTimeout(function(){
                    setUpdated(false)
                    handleClose()
                }, 2000);
            })
            .catch((error) => {
                setStatus(error?.response?.data?.error?.message)
                setLoading(false) 
                setSubmitting(false)
            })
        },
    })

    const handleClose = () => {
        setOpen(false)
        setTimeout(function(){
            formik.resetForm(initialValues as Partial<FormikState<{ currentPassword: string; newPassword: string; reNewPassword: string; }>>)
            setShowPassword({
                currentPassword: false,
                newPassword: false,
                reNewPassword: false
            })
        }, 500);
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <DivPaperStyled className={classes.paper}>
                        <div className="row p-5 pt-0">
                            <form
                                className='form w-100'
                                onSubmit={formik.handleSubmit}
                                noValidate
                                id='kt_login_signin_form'
                            >
                                <div className='text-center mb-10'>
                                    <h1 className='text-dark mb-3'>Change Password</h1>
                                </div>

                                {formik.status && (
                                    <div className='mb-lg-7 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )
                                }

                                <DivRelative className='fv-row mb-10'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>Current Password</label>
                                    <input
                                        {...formik.getFieldProps('currentPassword')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.currentPassword && formik.errors.currentPassword},
                                            {
                                                'is-valid': formik.touched.currentPassword && !formik.errors.currentPassword,
                                            }
                                        )}
                                        type={showPassword.currentPassword ? 'text' : 'password'}
                                        name='currentPassword'
                                        autoComplete='off'
                                        style={{
                                            backgroundPosition: 'right 40px center'
                                        }}
                                    />
                                        <IconButtonStyled
                                            onClick={() => setShowPassword({...showPassword, currentPassword: !showPassword.currentPassword})}
                                            size='small'
                                        >
                                            <KTSVG path={`/media/icons/bootstrap/${showPassword.currentPassword ? 'eye-slash-fill.svg' : 'eye-fill.svg'}`} className='svg-icon svg-icon-2'/>
                                        </IconButtonStyled>

                                        {formik.touched.currentPassword && formik.errors.currentPassword && (
                                        <div className='fv-plugins-message-container'>
                                            <span role='alert' className='text-danger'>{formik.errors.currentPassword}</span>
                                        </div>
                                    )}
                                </DivRelative>

                                <DivRelative className='fv-row mb-10'>
                                    <div className='d-flex justify-content-between mt-n5'>
                                        <div className='d-flex flex-stack mb-2'>
                                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
                                        </div>
                                    </div>
                                    <input
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        autoComplete='off'
                                        name='newPassword'
                                        {...formik.getFieldProps('newPassword')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                                            },
                                            {
                                                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                                            }
                                        )}
                                        style={{
                                            backgroundPosition: 'right 40px center'
                                        }}
                                    />

                                    <IconButtonStyled
                                        onClick={() => setShowPassword({...showPassword, newPassword: !showPassword.newPassword})}
                                        size='small'
                                    >
                                        <KTSVG path={`/media/icons/bootstrap/${showPassword.newPassword ? 'eye-slash-fill.svg' : 'eye-fill.svg'}`} className='svg-icon svg-icon-2'/>
                                    </IconButtonStyled>

                                    {formik.touched.newPassword && formik.errors.newPassword && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>{formik.errors.newPassword}</span>
                                            </div>
                                        </div>
                                    )}
                                </DivRelative>

                                <DivRelative className='fv-row mb-8'>
                                    <div className='d-flex justify-content-between mt-n5'>
                                        <div className='d-flex flex-stack mb-2'>
                                            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Re-enter New Password</label>
                                        </div>
                                    </div>
                                    <input
                                        type={showPassword.reNewPassword ? 'text' : 'password'}
                                        autoComplete='off'
                                        name='reNewPassword'
                                        {...formik.getFieldProps('reNewPassword')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.reNewPassword && formik.errors.reNewPassword,
                                            },
                                            {
                                                'is-valid': formik.touched.reNewPassword && !formik.errors.reNewPassword,
                                            }
                                        )}
                                        style={{
                                            backgroundPosition: 'right 40px center'
                                        }}
                                    />

                                    <IconButtonStyled
                                        onClick={() => setShowPassword({...showPassword, reNewPassword: !showPassword.reNewPassword})}
                                        size='small'
                                    >
                                        <KTSVG path={`/media/icons/bootstrap/${showPassword.reNewPassword ? 'eye-slash-fill.svg' : 'eye-fill.svg'}`} className='svg-icon svg-icon-2'/>
                                    </IconButtonStyled>

                                    {formik.touched.reNewPassword && formik.errors.reNewPassword && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>{formik.errors.reNewPassword}</span>
                                            </div>
                                        </div>
                                    )}
                                </DivRelative>

                                <div className='text-center'>
                                    <button
                                        type='submit'
                                        id='kt_sign_in_submit'
                                        className={`${updated ? 'btn-success' : 'btn-primary'} btn btn-lg w-100 mb-5`}
                                        style={{
                                            cursor: updated ? 'auto' : 'pointer',
                                            transition: 'background-color 0.5s ease'
                                        }}
                                        disabled={loading || !formik.isValid}
                                    >
                                    {!loading && !updated ?
                                        <span className='indicator-label'>Update Password</span>
                                        : !loading && <span className='indicator-label'>Password Updated</span>
                                    }
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </DivPaperStyled>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default ChangePasswordModal