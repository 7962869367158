import { Backdrop, CircularProgress, Fade, IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'
import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles';
import { modalStyles } from '../ModalStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UNLookupModal from '../LTLandVolumeTLPages/UNLookupModal/UNLookupModal';
import axios from 'axios';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { EditModalProps } from '../../pages/shipping-items/models';
import { ButtonStyled } from './ShippingItemsPage.styled';
import { DivPaperStyled, ModalStyled, Scroll } from '../Modals.styled';
import * as Yup from 'yup';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { stylesMUIInput } from '../stylesMUIInput';
import { InputStyled, NumberFormatStyled } from '../Inputs.styled';
import { RootState } from '../../../setup';
import { NumericFormat } from 'react-number-format';
import { PatternFormatStyled } from './EditOrAddModal.styled';
import { UserModel } from '../../modules/auth/models/UserModel';
import InputMask from 'react-input-mask';
import NMFCLookupModal from '../LTLandVolumeTLPages/NMFCLookupModal/NMFCLookupModal';
import RequireClassITModal from '../LTLandVolumeTLPages/NMFCTable/RequireClassITModal';

const SHIPPINGITEM = '/applet/v1/database/shippingitem'
const GET_NMFCS = 'api/v1/database/nmfc/search'

const EditOrAddModal = ({open, setOpen, rowSelected, setRowSelected, getData, addOrEdit, results}: EditModalProps) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const dispatch = useDispatch()
    const classesFreightInfo = useStyles({props: {}})
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState(false)
    const [densityOptions, setDensityOptions] = useState([])
    const [openUNModal, setOpenUNModal] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [currentIdRowToEdit, setCurrentIdRowToEdit] = useState()
    const [addOrEditLocal, setAddOrEditLocal] = useState(addOrEdit)
    const [loadingNMFC, setLoadingNMFC] = useState(false)
    const [openRequireClassITModal, setOpenRequireClassITModal] = useState(false)
    const [openNMFCModal, setOpenNMFCModal] = useState(false)
    const classes = modalStyles({fontSize: fontSize})

    const menuProps = {
        classes: {
            list: classesFreightInfo.selectPaperList
        }
    }

    const handleClose = () => {
        setOpen(false)
        setTimeout(() => {
            if(addOrEdit === 'edit'){
                setRowSelected({})
            }else{
                formik.handleReset(initialValues)
            }
        }, 500);
    }

    const editOrAddSI = (values) => {
        setSaving(true)
        let currentSelected = rowSelected ? rowSelected?.id : currentIdRowToEdit
        axios({
            method: addOrEditLocal === 'add' ? 'post' : 'put',
            url: addOrEditLocal === 'add' ? SHIPPINGITEM : `${SHIPPINGITEM}/${currentSelected}`,
            data: {
                code: values.code || null,
                name: values.name || null,
                reference1: values.reference1 || null,
                reference2: values.reference2 || null,
                description: values.description || null,
                class: values.class,
                hazmat: values.UN.length ? true : false,
                UN: values.UN || null,
                weight: parseInt(values.weight.replace(',', '')) || null,
                NMFC: values.NMFC,
                UOM: values.UOM,
                length: parseInt(values.length.replace(',', '')) || null,
                width: parseInt(values.width.replace(',', '')) || null,
                height: parseInt(values.height.replace(',', '')) || null,
            }
        }).then((response) => {
            setAddOrEditLocal('edit')
            if(response?.data?.data) {
                setCurrentIdRowToEdit(response?.data?.data?.results?.id)
            }
            setSaving(false)
            setSaved(true)
            setTimeout(function(){
                setSaved(false)
            }, 2000);
            getData({})
        }).catch(error => {
            setSaving(false)
            checkErrorAPICall(error, dispatch, `${addOrEdit} Shipping Item`)
        })
    }

    const initialValues = {
        name: '',
        code: '',
        description: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        NMFC: '',
        UOM: 'US',
        class: '',
        UN: '',
        haz: false,
        reference1: '',
        reference2: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        NMFC: Yup.string().test('length', 'Invalid NMFC', (value) => {
            if(value && /\s/g.test(value)){
              return false
            }else{
              return true
            }
        }),
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            editOrAddSI(values)
        }
    })

    useEffect(() => {
        let arrayOfDensities = user?.densityRules?.map(density => density.class)
        setDensityOptions(arrayOfDensities.sort((a, b) => parseFloat(a) - parseFloat(b)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.densityRules])

    useEffect(() => {
        if(addOrEdit === 'edit'){
            formik.setValues({
                name: rowSelected.name,
                code: rowSelected.code,
                description: rowSelected.description,
                length: rowSelected.length,
                width: rowSelected.width,
                height: rowSelected.height,
                weight: rowSelected.weight,
                NMFC: rowSelected.NMFC,
                class: rowSelected.class,
                UOM: rowSelected.UOM,
                haz: rowSelected.hazmat,
                UN: rowSelected.UN,
                reference1: rowSelected.reference1,
                reference2: rowSelected.reference2,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelected])

    useEffect(() => {
        if(!open) {
            setAddOrEditLocal(addOrEdit)            
        }
    }, [open])

    const hasChanged = (property) => {
        let value = formik?.values[property]
        let initialValue = formik?.initialValues[property]

        return _.isEqual(value, initialValue)
    }

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <DivPaperStyled className={classes.paperEditModalSL}>
                        <div className="row modal-header p-0 pb-2">
                            <div className="col-8">
                                <h5 className="modal-title">{addOrEdit === 'add' ? 'Add New Shipping Item' : 'Edit Shipping Item'}</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                            <>
                                <Scroll>
                                    <div className="row align-items-center mb-7 mt-5">
                                        <div className="col-sm-2">
                                            <label className='form-label'>Name</label>
                                        </div>
                                        <div className="col-sm-6 mb-7 mb-sm-0">
                                            <InputStyled
                                                type="text"
                                                className="form-control"
                                                autoComplete='off'
                                                {...formik.getFieldProps('name')}
                                                style={{ 
                                                    borderColor: formik?.errors?.name && formik?.touched?.name ? '#F1416C' : '#E4E6EF'
                                                }}
                                                fontSize={fontSize}
                                            />
                                            {
                                                formik?.errors?.name && formik?.touched?.name &&
                                                <small className='text-danger'>{formik?.errors.name}</small>
                                            }
                                        </div>
                                        <div className="col-sm-1">
                                            <label className='form-label'>Code</label>
                                        </div>
                                        <div className="col-sm-3">
                                            <InputStyled
                                                type="text"
                                                className="form-control"
                                                autoComplete='off'
                                                {...formik.getFieldProps('code')}
                                                fontSize={fontSize}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-7 mt-5">
                                        <div className="col-sm-2">
                                            <label className='form-label'>Description</label>
                                        </div>
                                        <div className="col-sm-10">
                                            <InputStyled
                                                type="text"
                                                className="form-control"
                                                autoComplete='off'
                                                {...formik.getFieldProps('description')}
                                                fontSize={fontSize}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-7 mt-5">
                                        <div className="col-sm-2">
                                            <label className='form-label'>Dimensions</label>
                                        </div>
                                        <div className="col-sm-10">
                                            <div className="row align-items-center">
                                                <div className="col-2 col-sm-1">
                                                    <label className='form-label'>Length</label>
                                                </div>
                                                <div className="col-4 col-sm-2">
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label=" "
                                                        InputLabelProps={{shrink: false}}
                                                        autoComplete="off"
                                                        className={classesFreightInfo.textFieldMUI}
                                                        allowNegative={false}
                                                        {...formik.getFieldProps('length')}
                                                        thousandSeparator={','}
                                                        decimalScale={0}
                                                        isAllowed={(values) => {
                                                            const { formattedValue, floatValue } = values;
                                                            return formattedValue === '' || (floatValue >= 0)
                                                        }}
                                                        InputProps={{
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-2 col-sm-1">
                                                    <label className='form-label'>Width</label>
                                                </div>
                                                <div className="col-4 col-sm-2">
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label=" "
                                                        InputLabelProps={{shrink: false}}
                                                        autoComplete="off"
                                                        className={classesFreightInfo.textFieldMUI}
                                                        allowNegative={false}
                                                        {...formik.getFieldProps('width')}
                                                        thousandSeparator={','}
                                                        decimalScale={0}
                                                        isAllowed={(values) => {
                                                            const { formattedValue, floatValue } = values;
                                                            return formattedValue === '' || (floatValue >= 0)
                                                        }}
                                                        InputProps={{
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-2 col-sm-1 mt-7 mt-sm-0">
                                                    <label className='form-label'>Height</label>
                                                </div>
                                                <div className="col-4 col-sm-2 mt-7 mt-sm-0">
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label=" "
                                                        InputLabelProps={{shrink: false}}
                                                        autoComplete="off"
                                                        className={classesFreightInfo.textFieldMUI}
                                                        allowNegative={false}
                                                        {...formik.getFieldProps('height')}
                                                        thousandSeparator={','}
                                                        decimalScale={0}
                                                        isAllowed={(values) => {
                                                            const { formattedValue, floatValue } = values;
                                                            return formattedValue === '' || (floatValue >= 0)
                                                        }}
                                                        InputProps={{
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-2 col-sm-1 mt-7 mt-sm-0">
                                                    <label className='form-label'>UOM</label>
                                                </div>
                                                <div className="col-4 col-sm-2 mt-7 mt-sm-0">
                                                    <TextField
                                                        select
                                                        label=" "
                                                        {...formik.getFieldProps('UOM')}
                                                        className={classesFreightInfo.selectMUI}
                                                        InputProps={{
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                        SelectProps={{
                                                            IconComponent: KeyboardArrowDownIcon,
                                                            MenuProps: menuProps
                                                        }}
                                                        InputLabelProps={{shrink: false}}
                                                    >
                                                        <MenuItem value={'US'}>lbs</MenuItem>
                                                        <MenuItem value={'METRIC'}>kg</MenuItem>
                                                    </TextField>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-7 mt-5">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-10">
                                            <div className="row align-items-center">
                                                <div className="col-2 col-sm-1">
                                                    <label className='form-label'>Weight</label>
                                                </div>
                                                <div className="col-4 col-sm-2">
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label=" "
                                                        InputLabelProps={{shrink: false}}
                                                        autoComplete="off"
                                                        className={classesFreightInfo.textFieldMUI}
                                                        allowNegative={false}
                                                        {...formik.getFieldProps('weight')}
                                                        thousandSeparator={','}
                                                        decimalScale={0}
                                                        isAllowed={(values) => {
                                                            const { formattedValue, floatValue } = values;
                                                            return formattedValue === '' || (floatValue >= 0)
                                                        }}
                                                        InputProps={{
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-2 col-sm-1">
                                                    NMFC
                                                </div>
                                                <div className="col-4 col-sm-2">
                                                    <InputMask
                                                        onChange={formik.handleChange}
                                                        value={formik.values.NMFC}
                                                        mask="999999-99"
                                                        maskChar=" "
                                                    >
                                                        {() => <TextField
                                                            name={`NMFC`}
                                                            autoComplete="off"
                                                            className={formik.errors?.NMFC ? classesFreightInfo.textFieldError : classesFreightInfo.textFieldMUIWithoutBorder}
                                                            placeholder="NMFC"
                                                            onKeyDown={(e) => {
                                                                if(e.key === 'Enter' || e.key === 'Tab'){
                                                                    e.preventDefault()
                                                                    setLoadingNMFC(true)
                                                                    formik.setFieldTouched(`nmfc`, true)

                                                                    if(user?.allowClassIT){
                                                                        axios.get(GET_NMFCS, {
                                                                            params: {
                                                                                keyword: e.target.value
                                                                            }
                                                                        }).then(response => {
                                                                            let NMFC = response.data.data.results?.NMFCCodes[0]
                                                                            if(NMFC?.itemNumber === e.target.value){
                                                                                formik.setFieldValue(`NMFC`, NMFC?.itemNumber)
                                                                                formik.setFieldValue(`class`, NMFC?.itemClass)
                                                                            }
                                                                        }).catch(error => {
                                                                            if(error?.response?.data?.error?.message === 'Empty classIT credentials.'){
                                                                                setOpenRequireClassITModal(true)
                                                                                setLoadingNMFC(false)
                                                                            }else{  
                                                                                checkErrorAPICall(error, dispatch, 'NMFC Numbers')
                                                                            }
                                                                        }).finally(() => {
                                                                            setLoadingNMFC(false)
                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <>
                                                                        {
                                                                            user?.allowClassIT ? 
                                                                            <InputAdornment position="end" style={{margin: 0}}>
                                                                                <IconButton
                                                                                    disabled={loadingNMFC}
                                                                                    onClick={(e) => {
                                                                                        formik.setFieldValue(`nmfcSearch`, formik.values?.NMFC)
                                                                                        setOpenNMFCModal(true)
                                                                                    }}>
                                                                                    {loadingNMFC ? <div className='d-flex align-items-center'><CircularProgress color="inherit" size={15} /></div> : <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: 'rgba(0, 0, 0, 0.23)'}}/>}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                            : null
                                                                        }
                                                                    </>
                                                                ),
                                                                style: stylesMUIInput(fontSize)
                                                            }}
                                                        />}
                                                    </InputMask>
                                                    {
                                                        formik?.errors?.NMFC &&
                                                        <small className='text-danger'>{formik?.errors?.NMFC}</small>
                                                    }
                                                </div>
                                                <div className="col-2 col-sm-1 mt-7 mt-sm-0">
                                                    <label className='form-label'>Class</label>
                                                </div>
                                                <div className="col-4 col-sm-2 mt-7 mt-sm-0">
                                                    <TextField
                                                        select
                                                        label=" "
                                                        {...formik.getFieldProps('class')}
                                                        className={classesFreightInfo.selectMUI}
                                                        InputProps={{
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                        SelectProps={{
                                                            IconComponent: KeyboardArrowDownIcon,
                                                            MenuProps: menuProps
                                                        }}
                                                        InputLabelProps={{shrink: false}}
                                                    >
                                                        {
                                                            densityOptions?.map((density, index) => {
                                                                return <MenuItem key={index} value={density}>{density}</MenuItem>
                                                            })
                                                        }
                                                    </TextField>
                                                </div>
                                                <div className="col-6 col-sm-3 mt-7 mt-sm-0 d-flex align-items-center">
                                                    <label className='form-label me-4'>Haz</label>
                                                    <div className="form-check form-check-custom form-check-solid form-check-sm me-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                formik.setFieldValue('haz', e.target.checked)
                                                                e.target.checked === false && formik.setFieldValue('UN', "")
                                                            }}
                                                            checked={formik.values.haz}
                                                        />
                                                    </div>
                                                    <NumberFormatStyled
                                                        {...formik.getFieldProps('UN')}
                                                        disabled={!formik.values.haz}
                                                        customInput={TextField}
                                                        isAllowed={(values) => {
                                                            const { formattedValue, floatValue } = values;
                                                            return formattedValue === '' || (floatValue >= 0 && floatValue <= 9999)
                                                        }}
                                                        allowNegative={false}
                                                        autoComplete="off"
                                                        className={classesFreightInfo.textFieldMUI}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end" style={{margin: 0}}>
                                                                    <IconButton disabled={!formik.values.haz} onClick={() => setOpenUNModal(true)}>
                                                                        <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon fs-9' style={{ color: formik.values.haz ? "#5E6278" : 'rgba(0, 0, 0, 0.23)'}}/>
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            style: stylesMUIInput(fontSize)
                                                        }}
                                                        placeholder="UN#"
                                                        fontSize={fontSize}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="row align-items-center mb-7 mt-5">
                                        <div className="col-sm-2">
                                            <label className='form-label'>Reference#s</label>
                                        </div>
                                        <div className="col-7 col-sm-5">
                                            <InputStyled
                                                type="text"
                                                className="form-control"
                                                autoComplete='off'
                                                {...formik.getFieldProps('reference1')}
                                                fontSize={fontSize}
                                            />
                                        </div>
                                        <div className="col-5">
                                            <InputStyled
                                                type="text"
                                                className="form-control"
                                                autoComplete='off'
                                                {...formik.getFieldProps('reference2')}
                                                fontSize={fontSize}
                                            />
                                        </div>
                                    </div>
                                </Scroll>
                                <div className="row mb-3">
                                    <div className="col-12 d-flex justify-content-end pt-7 pt-sm-0">
                                        <button
                                            className="btn btn-secondary me-4"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                        <ButtonStyled
                                            className={`btn ${saved ? 'btn-success' : 'btn-primary'} d-flex align-items-center`}
                                            saved={saved}
                                            type='submit'
                                            onClick={() => {
                                                formik.handleSubmit()
                                            }}
                                        >
                                            {saving ? 'Saving' : saved ? 'Saved' : 'Save'}{saving && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                        </ButtonStyled>
                                    </div>
                                </div>
                            </>
                    </DivPaperStyled>
                </Fade>
            </ModalStyled>
            <UNLookupModal
                open={openUNModal}
                setOpen={setOpenUNModal}
                setUNNumber={value => formik.setFieldValue('UN', value)}
            />
            <NMFCLookupModal
                open={openNMFCModal}
                setOpen={setOpenNMFCModal}
                setFormikValues={formik.setFieldValue}
                searchFormik={formik.values.nmfcSearch}
            />
            <RequireClassITModal
                open={openRequireClassITModal}
                setOpen={setOpenRequireClassITModal}
            />
        </>
    )
}

export default EditOrAddModal