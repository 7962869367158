import { useEffect, useState } from 'react'
import { ModalStyled } from '../Modals.styled'
import { modalStyles } from '../ModalStyles'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { Backdrop, CircularProgress, Fade } from '@mui/material'
import { KTSVG } from '../../../_metronic/helpers'
import OriginAndDestination from './OriginAndDestination'
import CarrierInformation from '../../pages/book/NewBooking/CarrierInformation'
import FreightInformation from './FreightInformation'
import PickupAndDelivery from './PickupAndDelivery'
import Tabs from './Tabs'
import { FreightTotals } from '../LTLandVolumeTLPages/FreightInfo/FreightTotals'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import TrackingModal from './TrackingModal'
import { UserModel } from '../../modules/auth/models/UserModel'
import DocumentsModal from './DocumentsModal/DocumentsModal'

const HISTORYTRACKINGS = '/api/v1/track'
const DOCUMENTS = '/applet/v1/document'

const ViewDataModal = ({open, setOpen, selectedRow}) => {
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classesModal = modalStyles({fontSize: fontSize})
    const tracking = useSelector<RootState>(({pages}) => pages.tracking) as any
    const [freightTotals, setFreightTotals] = useState({})
    const [historyTrackings, setHistoryTrackings] = useState([])
    const [openTrackingModal, setOpenTrackingModal] = useState(false)
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false)
    const [loadingTracking, setLoadingTracking] = useState(false)
    const [loadingDocuments, setLoadingDocuments] = useState(false)
    const [documents, setDocuments] = useState([])
    const [vendorName, setVendorName] = useState('')
    const dispatch = useDispatch()

    const handleClose = () => {
        setOpen(false)
    }

    const trackingAction = () => {
        setLoadingTracking(true)
        axios.get(`${HISTORYTRACKINGS}/${tracking.rowData.BOLId}`).then(results => {
            setLoadingTracking(false)
            const resultsWithID = results.data.data.results.map(result => {
                return ({
                    ...result,
                    id: uuidv4()
                })
            })
            setHistoryTrackings(resultsWithID)
            setOpenTrackingModal(true)
        }).catch(error => {
            setLoadingTracking(false)
            checkErrorAPICall(error, dispatch, 'History Tracking')
        })
    }

    const getDocuments = () => {
        axios.get(`${DOCUMENTS}/${tracking.rowData.BOLId}`).then(results => {
            setDocuments(results.data.data.results)
            setLoadingDocuments(false)
            setOpenDocumentsModal(true)
        }).catch(error => {
            setLoadingDocuments(false)
            checkErrorAPICall(error, dispatch, 'Get Documents')
        })
    }

    useEffect(() => {
        if(open){
            const vendorName = user.enabledVendors.find(vendor => vendor.id === tracking?.vendorId)?.name
            if(vendorName && user?.allowVendorDisplay) {
              setVendorName(vendorName);
            }
        }
    }, [open, tracking])
    
    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                fontSize={fontSize}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classesModal.paperViewShipmentModal} style={{visibility: 'visible', opacity: '100%'}}>
                        <div className="row modal-header p-0 pb-2 mb-5">
                            <div className="col-6">
                                <h5 className="modal-title">Shipment Info</h5>
                            </div>
                            <div className="col-5 d-flex justify-content-end">
                                <h5 className="modal-title">BOL #{tracking?.rowData?.BOLNumber} {tracking?.rowData?.carrierPRO && `/ PRO #${tracking?.rowData?.carrierPRO}`}</h5>
                            </div>
                            <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <>
                            <div style={{maxHeight: window.innerHeight < 550 ? 300 : window.innerHeight < 580 ? 380 : window.innerHeight < 645 ? 450 : 500, overflowY: 'scroll', overflowX: 'hidden'}}>
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        <div className="col-12 d-flex mb-2">
                                            <h5 className='mb-0 me-2' style={{fontSize: 14}}>Bill To</h5> <h5 className='mb-0' style={{fontSize: 14, fontWeight: 400}}>{user?.billToInformation.name}</h5>
                                        </div>
                                        <div className="col-12">
                                            <label className='col-form-label me-1'>Reference#</label> {tracking?.rowData?.billPartyReferenceNumber || '-'}
                                        </div>
                                        <div className="col-12">
                                            <label className='col-form-label me-1'>Email</label> {tracking?.thirdParty?.email.replaceAll(',', ', ')}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className='col-form-label py-0'>Shipper</label>
                                        <hr style={{color: '#a7adce'}} className='mb-4'/>
                                        <OriginAndDestination
                                            data={tracking.shipper}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <label className='col-form-label py-0'>Consignee</label>
                                        <hr style={{color: '#a7adce'}} className='mb-4'/>
                                        <OriginAndDestination
                                            data={tracking.consignee}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <FreightInformation/>
                                </div>
                                <div className="row p-3">
                                    <FreightTotals
                                        freightInfoTotals={{...freightTotals, symbol: tracking?.freightInfoUOM?.value === 'US' ? 'lbs' : 'kg'} || {}}
                                        freightInfo={tracking?.freightInfo}
                                        freightInfoUOM={tracking?.freightInfoUOM}
                                        setData={setFreightTotals}
                                        forViewShipment={true}
                                    />
                                </div>
                                {
                                    tracking?.accessorials?.length ?
                                    <div className="row">
                                        <div className="col-12">
                                            <label className='col-form-label me-2'>Accessorials</label>
                                        </div>
                                        <div className="col-12 d-flex align-items-center">
                                            {tracking?.accessorials?.join(", ")}
                                        </div>
                                    </div>
                                    : null
                                }
                                <div className="row">
                                    <CarrierInformation
                                        values={tracking}
                                        styleForBox={{margin: 1}}
                                        setOpenQuoteBreakdownModal={() => {}}
                                        vendorName={vendorName} 
                                    />
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 col-md-6">
                                        <label className='col-form-label'>Pickup and Delivery</label>
                                        <div className="row" style={{paddingTop: 10.75}}>
                                            <PickupAndDelivery title={'Pickup On'}/>
                                            <PickupAndDelivery title={'Delivery By'}/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mt-8 mt-md-0">
                                        <Tabs/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3 mt-5">
                                <div className="col-12 d-none d-sm-flex justify-content-end">
                                    <button
                                        className="btn btn-primary me-4 d-flex align-items-center"
                                        onClick={() => getDocuments()}
                                    >
                                        Documents {loadingDocuments && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                    </button>
                                    {
                                        (user?.allowTrack && tracking?.rowData?.dispatched) ?
                                        <button
                                            className="btn btn-primary me-4 d-flex align-items-center"
                                            onClick={trackingAction}
                                        >
                                            Tracking {loadingTracking && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                        </button>
                                        : null
                                    }
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className="col-12 d-flex d-sm-none justify-content-end">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-end">
                                            <button
                                                className="btn btn-primary me-4 d-flex align-items-center"
                                                onClick={() => getDocuments()}
                                            >
                                                Documents {loadingDocuments && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                            </button>
                                            {
                                                (user?.allowTrack && tracking?.rowData?.dispatched) ?
                                                <button
                                                    className="btn btn-primary d-flex align-items-center"
                                                    onClick={trackingAction}
                                                >
                                                    Tracking {loadingTracking && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                                                </button>
                                                : null
                                            }
                                        </div>
                                        <div className="col-12 d-flex align-items-center justify-content-end mt-4">
                                            <button
                                                className="btn btn-secondary"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </Fade>
            </ModalStyled>
            <TrackingModal
                open={openTrackingModal}
                setOpen={setOpenTrackingModal}
                historyTrackings={historyTrackings}
                setHistoryTrackings={setHistoryTrackings}
                rowData={tracking.rowData}
            />
            <DocumentsModal
                open={openDocumentsModal}
                setOpen={setOpenDocumentsModal}
                documents={documents}
                setDocuments={setDocuments}
                selectedRow={selectedRow}
            />
        </>
    )
}

export default ViewDataModal