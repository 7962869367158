import { Backdrop, CircularProgress, Fade } from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KTSVG } from '../../../../../_metronic/helpers'
import { checkErrorAPICall } from '../../../../helpers/checkErrorAPICall'
import { EquipmentServiceModalProps } from '../../../../pages/vendors/models'
import { DivPaperStyled, ModalStyled, Scroll } from '../../../Modals.styled'
import { modalStyles } from '../../../ModalStyles'
import Equipments from './Equipments'
import { RootState } from '../../../../../setup'

const GET_TRUCKTYPES = '/api/v1/system/trucktype'

const EquipmentModal = ({open, setOpen, formik}: EquipmentServiceModalProps) => {
    const classes = modalStyles({})
    const dispatch = useDispatch()
    const [truckTypes, setTruckTypes] = useState([])
    const [equipmentsSelected, setEquipmentsSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(open && truckTypes.length === 0){
            setLoading(true)
            axios.get(GET_TRUCKTYPES)
            .then((results) => {
                setTruckTypes(results.data.data.results)
                setLoading(false)
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Truck Types')
            })
        }
        if(open){
            setEquipmentsSelected(formik.values.mainInformation.equipments)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paperEditModalSL}>
                    <div className="row modal-header p-0 pb-2">
                        <div className="col-8">
                            <h5 className="modal-title">Equipment</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <Scroll>
                            <div className={`row mb-7 ${loading ? 'mt-5' : ''}`}>
                                {
                                    loading &&
                                    <div className="col-12 d-flex justify-content-center">
                                        <CircularProgress color="primary" style={{color: '#009EF7'}}/>
                                    </div>
                                }
                            </div>
                            <Equipments
                                truckTypes={truckTypes}
                                equipmentsSelected={equipmentsSelected}
                                setEquipmentsSelected={setEquipmentsSelected}
                            />
                        </Scroll>
                        <div className="row mb-3 mt-7">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className='btn btn-primary d-flex align-items-center me-4'
                                    type='submit'
                                    onClick={() => {
                                        formik.setFieldValue('mainInformation.equipments', equipmentsSelected)
                                        handleClose()
                                    }}
                                >
                                    Save
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default EquipmentModal