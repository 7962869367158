export const serviceArea = [
    {
        state: 'AL',
        ubication: 'southeast'
    },
    {
        state: 'AR',
        ubication: 'southeast'
    },
    {
        state: 'AZ',
        ubication: 'southwest'
    },
    {
        state: 'CA',
        ubication: 'west'
    },
    {
        state: 'CO',
        ubication: 'west'
    },
    {
        state: 'CT',
        ubication: 'northeast'
    },
    {
        state: 'DC',
        ubication: 'northeast'
    },
    {
        state: 'DE',
        ubication: 'northeast'
    },
    {
        state: 'FL',
        ubication: 'southeast'
    },
    {
        state: 'GA',
        ubication: 'southeast'
    },
    {
        state: 'IA',
        ubication: 'midwest'
    },
    {
        state: 'ID',
        ubication: 'west'
    },
    {
        state: 'IL',
        ubication: 'midwest'
    },
    {
        state: 'IN',
        ubication: 'midwest'
    },
    {
        state: 'KS',
        ubication: 'midwest'
    },
    {
        state: 'KY',
        ubication: 'southeast'
    },
    {
        state: 'LA',
        ubication: 'southeast'
    },
    {
        state: 'MA',
        ubication: 'northeast'
    },
    {
        state: 'MD',
        ubication: 'northeast'
    },
    {
        state: 'ME',
        ubication: 'northeast'
    },
    {
        state: 'MI',
        ubication: 'midwest'
    },
    {
        state: 'MN',
        ubication: 'midwest'
    },
    {
        state: 'MO',
        ubication: 'midwest'
    },
    {
        state: 'MS',
        ubication: 'southeast'
    },
    {
        state: 'MT',
        ubication: 'west'
    },
    {
        state: 'NC',
        ubication: 'southeast'
    },
    {
        state: 'ND',
        ubication: 'midwest'
    },
    {
        state: 'NE',
        ubication: 'midwest'
    },
    {
        state: 'NH',
        ubication: 'northeast'
    },
    {
        state: 'NJ',
        ubication: 'northeast'
    },
    {
        state: 'NM',
        ubication: 'southwest'
    },
    {
        state: 'NV',
        ubication: 'west'
    },
    {
        state: 'NY',
        ubication: 'northeast'
    },
    {
        state: 'OH',
        ubication: 'midwest'
    },
    {
        state: 'OK',
        ubication: 'southwest'
    },
    {
        state: 'OR',
        ubication: 'west'
    },
    {
        state: 'PA',
        ubication: 'northeast'
    },
    {
        state: 'RI',
        ubication: 'northeast'
    },
    {
        state: 'SC',
        ubication: 'southeast'
    },
    {
        state: 'SD',
        ubication: 'midwest'
    },
    {
        state: 'TN',
        ubication: 'southeast'
    },
    {
        state: 'TX',
        ubication: 'southwest'
    },
    {
        state: 'UT',
        ubication: 'west'
    },
    {
        state: 'VA',
        ubication: 'southeast'
    },
    {
        state: 'VT',
        ubication: 'northeast'
    },
    {
        state: 'WA',
        ubication: 'west'
    },
    {
        state: 'WI',
        ubication: 'midwest'
    },
    {
        state: 'WV',
        ubication: 'southeast'
    },
    {
        state: 'WY',
        ubication: 'west'
    },
    {
        state: 'AK',
        ubication: ''
    },
    {
        state: 'HI',
        ubication: ''
    }
]