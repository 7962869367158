import { Modal } from '@mui/material';
import styled from 'styled-components';

export const DivPaperStyled = styled.div`
    visibility: visible;
    opacity: 100%;
`

export const Title = styled.p`
    font-size: 18px !important;
    font-weight: 500;
    text-align: center
`

export const Paragraph = styled.p`
    font-size: 14px;
    text-align: center
`

export const SmallTextItalic = styled.small`
    font-style: italic;
    color: #8f9193
`

export const Scroll = styled.div`
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden
`

export const ModalStyled = styled(Modal)`
    font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    span {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    button {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    p {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    }
    div {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    label {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    }; 
    span {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    input {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    textarea {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    ul {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    li {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    th {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    td {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    h5 {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    h6 {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    };
    a {
        font-size: ${props => props.fontSize !== 13 && `${props.fontSize}px !important`}
    }
`