import { ModalStyled } from '../Modals.styled';
import { RootState } from '../../../setup';
import { modalStyles } from '../ModalStyles';
import { useSelector } from 'react-redux';
import { Backdrop, Fade } from '@mui/material';
import { KTSVG } from '../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';

const SaveChangesModal = ({open, setOpen, updateShipment}) => {
    const classes = modalStyles({});
    const navigate = useNavigate()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    
    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper} style={{visibility: 'visible', opacity: '100%'}}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 mt-5 d-flex justify-content-center">
                                    <p style={{fontSize: 18, fontWeight: 500}}>Save changes?</p>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            updateShipment()
                                            navigate('/trackings')
                                            setOpen(false)
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            navigate('/trackings')
                                            setOpen(false)
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default SaveChangesModal