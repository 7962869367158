import React from 'react'
import { serviceArea } from '../../../../data/ServiceArea'
import { ServiceAreaProps } from '../../../../pages/vendors/models'

const ServiceArea = ({statesSelected, setStatesSelected}: ServiceAreaProps) => {
  return (
    <div className="row pe-1">
        {
            serviceArea.map((service) => {
                return (
                    <div className="col-3 col-sm-2 col-lg-1 mb-6" key={service.state}>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={service.state}
                                id={service.state}
                                checked={statesSelected?.filter(e => e === service.state).length > 0}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        setStatesSelected([...statesSelected, service.state])
                                    }else{
                                        setStatesSelected(statesSelected?.filter(e => e !== service.state))
                                    }
                                }}
                            />
                            <label className="form-check-label" htmlFor={service.state}>
                                {service.state}
                            </label>
                        </div>
                    </div>
                )
            })
        }
    </div>
  )
}

export default ServiceArea