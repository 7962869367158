import { useEffect, useState } from 'react'
import { dollarUSLocale } from '../../helpers/dollarUSLocale'
import { KTSVG } from '../../../_metronic/helpers'
import { CircularProgress, LinearProgress, MenuItem, Paper, Rating, TextField, Tooltip } from '@mui/material'
import MaterialTable, { Column } from '@material-table/core'
import { RowTableObject } from '../../pages/ltl/models'
import { tableStyles } from '../TableStyles'
import QuoteBreakdownModal from '../LTLandVolumeTLPages/Modals/QuoteBreakdownModal'
import axios from 'axios'
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall'
import { MTableBodyRowStyled } from '../QuotesGridPage/Table/Table.styled'
import { stylesMUIInput } from '../stylesMUIInput'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx'
import { useStyles } from '../LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles'
import ClearIcon from '@mui/icons-material/Clear';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'

const RatesTable = ({rates, setFormikValues, handleClose, linearProgress, deleteToolbar, ratesStopped, setRatesStopped, source, setVendorName, vendorsFiltered, vendorsProcessed}) => {
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const classesInput = useStyles({props: {}})
    const classes = tableStyles()
    const dispatch = useDispatch()
    const [quoteBreakdownModalData, setQuoteBreakdownModalData] = useState({})
    const [openQuoteBreakdownModal, setOpenQuoteBreakdownModal] = useState(false)
    const [loadingSaveRate, setLoadingSaveRate] = useState(false)
    const [filteredRates, setFilteredRates] = useState([])
    const [rateTypeSelected, setRateTypeSelected] = useState('ALL RATES')
    const [rateTypes, setRateTypes] = useState([])
    const [buttonColor, setButtonColor] = useState('btn-danger')
    const [selectedId, setSelectedId] = useState(false)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const selectRate = (e, rowData) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                if(ratesStopped){
                    setLoadingSaveRate(true)
                    axios.post('/applet/v1/rate/save', {
                        rateId: rowData?.id,
                        originShippingLocationId: 0,
                        destinationShippingLocationId: 0
                    }).then(results => {
                        setFormikValues(`quoteNumber`, results.data.data.results?.quoteNumber)
                        setFormikValues(`vendorId`, rowData?.vendorId)
                        setFormikValues(`vendorSCAC`, rowData?.SCAC)
    					const vendorName = user.enabledVendors.find(vendor => vendor.id === rowData?.vendorId)?.name
                        if(vendorName && user?.allowVendorDisplay) {
                            setVendorName(vendorName);
                        }
                                            
                        setFormikValues(`carrierInformation`, {
                            name: rowData?.name || '',
                            total: parseFloat(rowData?.total) || '',
                            transitDays: rowData?.transitDays || '',
                            serviceLevel: rowData?.serviceLevel || '',
                            rateRemarks: rowData?.rateRemarks,
                            iconUrl: rowData?.iconUrl || '',
                            rateBreakdown: rowData?.rateBreakdown,
                            rateId: rowData?.id
                        })
    
                        setLoadingSaveRate(false)
                        handleClose()
                    }).catch(error => {
                        setLoadingSaveRate(false)
                        checkErrorAPICall(error, dispatch, 'Save Rate')
                    })
                }
                break;
        }
    }

    const carrierRender = (rowData) => {
        return (
          <>
            <img alt="icon" src={rowData.iconUrl} style={{width: 70}}/>
            <p style={{fontWeight: 500, marginBottom: 3}}>{rowData.name}</p>
            <Rating value={rowData.rating} precision={0.5} readOnly />
          </>
        )
    }
         
    const serviceLevelRender = (rowData) => {
        return (
          <>
            <p className="mb-3" style={{fontWeight: 500}}>{rowData?.serviceLevel}</p>
    
            {rowData.rateRemarks?.length && rowData.rateRemarks[0]
              ? <>
                {!rowData?.rateRemarks[0]?.includes('href') && !rowData?.rateRemarks[0]?.includes('<br>') && <KTSVG path='/media/icons/bootstrap/exclamation-triangle-fill.svg' className='svg-icon svg-icon-6 text-danger me-1'/>}
                {rowData.rateRemarks.map(remark => {
                    if(remark.includes('href') || remark.includes('<br>')){
                        return <div key={remark} dangerouslySetInnerHTML={{ __html: remark }} />
                    }else{
                        return <span key={remark}>{remark}</span>
                    }
                })}
              </>
              : null
            }
          </>
        )
    }
    
    const totalRender = (rowData) => {
        return (
            <button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    textDecoration: 'underline'
                }}
                onClick={() => {
                    setQuoteBreakdownModalData(rowData)
                    setOpenQuoteBreakdownModal(true)
                }}
            >
                {dollarUSLocale.format(rowData.total)}
            </button>
        )
    }

    const serviceLevelCustomSort = (a, b) => (
        a.serviceLevel.trim()?.localeCompare(b.serviceLevel.trim())
    )

    const transitCustomSort = (a, b) => (
        (a.transitDays === "-" ? 99 : a.transitDays) - (b.transitDays === '-' ? 99 : b.transitDays)
    )

    const [columns] = useState([
        { title: 'Carrier', field: 'name', render: carrierRender },
        { title: 'Service Level', field: 'serviceLevel', width: "50%", render: serviceLevelRender, customSort: serviceLevelCustomSort},
        { title: 'Transit', field: 'transitDays', customSort: transitCustomSort },
        { title: 'Total', field: 'total', defaultSort: 'asc', type: 'currency',  render: totalRender}
    ] as Column<Array<RowTableObject>>[])

    useEffect(() => {
        rates.forEach(rate => {
          if(!(rateTypes.filter(function(e) { return e.value === rate?.rateType; }).length > 0)){
            let stringLowerCase = rate.rateType?.toLowerCase()
            let stringFirstCharUpperCase = stringLowerCase?.charAt(0)?.toUpperCase() + stringLowerCase?.slice(1)
    
            setRateTypes([...rateTypes, {value: rate?.rateType, name: rate?.rateType?.length > 3 ? stringFirstCharUpperCase : rate?.rateType}])
          }
        })
    
        if(!(rates.length >= 1)){
          setRateTypeSelected('ALL RATES')
          setRateTypes([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rates])    

    useEffect(() => {
        let newRatesArray
        if(rateTypeSelected === 'ALL RATES'){
          newRatesArray = rates
        }else{
          newRatesArray = rates.filter(rate => {
            return rate.rateType === rateTypeSelected
          })
        }
    
        if(rateTypeSelected === 'ALL RATES'){
          setFilteredRates([])
        }
    
        setFilteredRates(newRatesArray)
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [rateTypeSelected, rates])

    const menuProps = {
        classes: {
            list: classesInput.selectPaperList
        }
    }

    return (
        <>
            <div className="col-3 d-flex align-items-center">
                <TextField
                    select
                    label=" "
                    value={rateTypeSelected}
                    onChange={(e) => {
                        setRateTypeSelected(e.target.value)
                    }}
                    className={clsx(classesInput.selectMUI)}
                    InputProps={{
                        style: stylesMUIInput(fontSize),
                    }}
                    SelectProps={{
                        IconComponent: KeyboardArrowDownIcon,
                        MenuProps: menuProps
                    }}
                    InputLabelProps={{shrink: false}}
                >
                    <MenuItem value='ALL RATES'>All Rates</MenuItem>
                    {
                        rateTypes.sort((a, b) => a.value?.localeCompare(b.value)).map((rateType, index) => (
                            <MenuItem key={index} value={rateType.value}>{rateType.name}</MenuItem>
                        ))
                    }
                </TextField>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
                {!loadingSaveRate ?
                    <Tooltip title={linearProgress >= 100 || ratesStopped ? "" : "Stop Get Rates"}>
                        <button
                            style={{
                                cursor: linearProgress >= 100 || ratesStopped ? 'auto' : 'pointer',
                                opacity: linearProgress >= 100 || ratesStopped ? '0' : '1',
                                transition: (linearProgress >= 100 && "opacity 0.5s ease-in") || (ratesStopped && "opacity 2.5s ease-in")
                            }}
                            className={`btn ${buttonColor} ${ratesStopped ? 'disabled' : ''} d-flex justify-content-center align-items-center`}
                            onClick={() => {
                                source.cancel('Operation canceled by the user.')
                                setRatesStopped(true)
                                setButtonColor('btn-success')
                                setTimeout(() => {
                                    setButtonColor('btn-danger')
                                }, 2550);
                            }}
                        >
                            {ratesStopped ? 'Stopped!' : <><ClearIcon fontSize="small" className="me-1"/>Stop</>}
                        </button>
                    </Tooltip>
                :  <div className='d-flex justify-content-center align-items-center'><CircularProgress style={{color: '#009EF7'}} size={30} /></div>}  
            </div>
            <div className="col-3">

            </div>

            <div className='d-flex justify-content-center mt-5'>
                <b>{linearProgress >= 100 || ratesStopped ? 'Rating finished' : 'Loading carriers...'}</b>
            </div>
            <div className='d-flex justify-content-center'>
                {`${linearProgress > 0 ? vendorsProcessed : '0'} of ${vendorsFiltered?.length} processed - ${rates?.length} ${rates?.length === 1 ? 'rate' : 'rates'} found`}
            </div>

            <div className="col-12 mt-3">
                <LinearProgress
                    variant="determinate"
                    value={linearProgress}
                    style={{backgroundColor: '#94d8ff'}}
                    className={classes.linearProgress}
                />
            </div>
            <div className="col-12">
                <div style={{maxHeight: 550, overflow: 'scroll'}}>
                    <MaterialTable
                        style={{boxShadow: 'none', fontFamily: 'Poppins !important'}}
                        columns={columns}
                        data={filteredRates.length >= 1 ? filteredRates : rates}
                        title=""
                        options={{
                            thirdSortClick: false,  
                            actionsColumnIndex: -1,
                            toolbar: false,
                            paging: false,
                            filtering: false,
                            searchFieldAlignment: 'left',
                            draggable: false,
                            rowStyle: (data, idx) => idx%2 !== 0 ? {
                                backgroundColor: '#f4f4f4',
                            } : {
                                backgroundColor: '#ffff',
                            },
                        }}   
                        components={{
                            Container: (props) => <Paper className={classes.table} {...props}/>,
                            Row: rowProps => <MTableBodyRowStyled {...rowProps} onRowClick={(e) => selectRate(e, rowProps.data)} onMouseEnter={(e) => e.target.parentElement.classList.add("hoverRow")} onMouseLeave={(e) => e.target.parentElement.classList.remove("hoverRow")} />,
                        }}
                    />
                </div>
            </div>
            <QuoteBreakdownModal
                open={openQuoteBreakdownModal}
                setOpen={setOpenQuoteBreakdownModal}
                data={quoteBreakdownModalData}
            />
        </>
    )
}

export default RatesTable