import styled from 'styled-components';

export const Title = styled.span`
    font-weight: 500
`

export const Total = styled.span`
    padding-right: 15px
`

export const Remarks = styled.small`
    font-style: italic;
    color: #8f9193 !important;
    font-size: ${props => `${props.fontSize >= 13 ? '' : props.fontSize}px`}
`

export const RowStyled = styled.div`
    max-height: 450px;
    overflow: auto
`