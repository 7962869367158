import { useEffect, useState } from 'react'
import { PageTitle, usePageData } from '../../../_metronic/layout/core'
import Table from '../../components/QuotesGridPage/Table/Table'
import { dollarUSLocale } from '../../helpers/dollarUSLocale'
import CircleIcon from '@mui/icons-material/Circle';
import Toolbar from '../../components/InvoicesGridPage/Toolbar'
import InvoiceBreakdownModal from '../../components/ShippingItemsPage/InvoiceBreakdownModal'
import HistorySelect from '../../components/QuotesGridPage/History/HistorySelect';

export const InvoicesGrid = () => {
  let {setHistoryElement} = usePageData()
  const [loading, setLoading] = useState(false)
  const [totalResults, setTotalResults] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [invoices, setInvoices] = useState([])
  const [filters, setFilters] = useState({
    invoiceNumber: '',
    BOLNumber: '',
    status: 'All',
  })
  const [openInvoiceBreakdownModal, setOpenInvoiceBreakdownModal] = useState(false)
  const [rowSelected, setRowSelected] = useState({})

  const totalRender = (rowData) => {
    return (
      <button
        style={{ 
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
          textDecoration: 'underline'
        }}
        onClick={() => {
          setRowSelected(rowData)
          setOpenInvoiceBreakdownModal(true)
        }}
      >
        {dollarUSLocale.format(rowData.total)}
      </button>
    )
  }

  const paidRender = (rowData) => {
    return (
      <div style={{textAlign: 'center'}}>
        <CircleIcon style={{color: rowData.paid ? '#47BE7D' : '#F1416C'}}/>
      </div>
    )
  }
  
  const referenceRender = (rowData) => {
    return (
      <div>
        {rowData.shipperReferenceNumber && `${rowData.shipperReferenceNumber} -`}{rowData.shipperReferenceNumber && `${rowData.consigneeReferenceNumber} -`}{rowData.billPartyReferenceNumber}
      </div>
    )
  }

  const [columns, setColumns] = useState([
    { title: 'Invoice#', field: 'invoiceNumber', hidden: false},
    { title: 'Due', field: 'dueDate', type: 'date', dateSetting: { locale: "en-US" }, hidden: false},
    { title: 'BOL#', field: 'BOLNumber', hidden: false},
    { title: 'PRO#', field: 'carrierPRO', hidden: false},
    { title: 'Reference#', field: 'shipperReferenceNumber', render: referenceRender, hidden: false},
    { title: 'Total', field: 'total', type: 'currency', render: totalRender, hidden: false},
    { title: 'Paid', field: 'paid', render: paidRender, sorting: false, hidden: false, headerStyle: {textAlign: 'center'}}
  ])

  useEffect(() => {
    setHistoryElement(
      <HistorySelect
        setFilters={setFilters}
        historyFor='InvoicesGrid'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle>Invoices Grid</PageTitle>
      <Toolbar
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        setColumns={setColumns}
        invoices={invoices}
        setInvoices={setInvoices}
        setLoading={setLoading}
        rowsPerPage={rowsPerPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setTotalResults={setTotalResults}
      />
      <Table
        columns={columns}
        data={invoices}
        loading={loading}
        setPageNumber={setPageNumber}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        pageNumber={pageNumber}
        totalResults={totalResults}
        tableFor='Invoices'
      />
      <InvoiceBreakdownModal
        open={openInvoiceBreakdownModal}
        setOpen={setOpenInvoiceBreakdownModal}
        data={rowSelected}
      />
    </>
  )
}