import { Fade, Backdrop } from '@mui/material';
import { KTSVG } from '../../../../_metronic/helpers'
import { NMFCLookupModalProps } from '../../../pages/ltl/models';
import { modalStyles } from '../../ModalStyles';
import { ModalStyled } from '../../Modals.styled';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import NMFCTable from '../NMFCTable/NMFCTable';

const NMFCLookupModal = ({open, setOpen, setFormikValues, searchFormik, index}: NMFCLookupModalProps) => {  
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    const selectNMFC = (rowData) => {
        if(index){
            setFormikValues(`freightInfo[${index}].nmfc`, rowData?.itemNumber)
            setFormikValues(`freightInfo[${index}].class`, rowData?.itemClass)
            setFormikValues(`freightInfo[${index}].commodity`, rowData?.itemDescription)
        }else{
            setFormikValues(`NMFC`, rowData?.itemNumber)
            setFormikValues(`class`, rowData?.itemClass)
        }
        
        handleClose()
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paperNMFCLookup} style={{visibility: 'visible', opacity: '100%'}}>
                    <>
                        <div className='row'>
                            <NMFCTable
                                selectNMFC={selectNMFC}
                                open={open}
                                handleClose={handleClose}
                                searchFormik={searchFormik || ''}
                            />
                        </div>
                    </>
                    <div className="row mb-3 mt-5">
                        <div className="col-12 d-flex justify-content-end">
                            <button
                                className="btn btn-secondary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default NMFCLookupModal