import { Backdrop, Fade } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { KTSVG } from '../../../_metronic/helpers';
import { modalStyles } from '../ModalStyles';
import axios from 'axios';
import { ActivateDesactivateModalProps } from '../../pages/vendors/models';
import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled';
import { checkErrorAPICall } from '../../helpers/checkErrorAPICall';
import { RootState } from '../../../setup';

const GET_VENDOR = '/api/v1/database/vendor'

const ActivateDesactivateModal = ({open, setOpen, selectedRow, getVendors}: ActivateDesactivateModalProps) => {
    const classes = modalStyles({});
    const dispatch = useDispatch()
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const activateDesactivate = () => {
        axios.put(`${GET_VENDOR}/${selectedRow.id}`, {
            ...selectedRow,
            enabled: !selectedRow.enabled
        }).then(() => {
          getVendors()
        }).catch(error => {
            checkErrorAPICall(error, dispatch, 'Vendor')
        })
    }

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <Title>Are you sure you want to {selectedRow.enabled ? 'deactivate' : 'activate'} this vendor?</Title>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className="col-12 d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary me-4"
                                        onClick={() => {
                                            setOpen(false)
                                            activateDesactivate()
                                        }}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ActivateDesactivateModal