export const container = [
    {
        name: "20'",
        value: 20
    },
    {
        name: "28'",
        value: 28
    },
    {
        name: "40'",
        value: 40
    },
    {
        name: "45'",
        value: 45
    },
    {
        name: "48'",
        value: 48
    },
    {
        name: "53'",
        value: 53
    }
]