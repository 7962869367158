import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled'
import { KTSVG } from '../../../_metronic/helpers'
import { Backdrop, Fade } from '@mui/material'
import { modalStyles } from '../ModalStyles'
import { RootState } from '../../../setup'
import { useSelector } from 'react-redux'

const ShipmentDispatchedModal = ({open, setOpen}) => {
    const classes = modalStyles({});
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const documents = useSelector<RootState>(({pages}) => pages.documents) as {BOLNumber: string, BOLDocumentURL: string, shipmentLabel: string, carrierLabel: string}
    const dispatchedErrorMessage = useSelector<RootState>(({pages}) => pages.dispatchedErrorMessage) as any

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
          <Fade in={open}>
                <DivPaperStyled className={classes.paperShipmentDispatched}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path={`/media/icons/bootstrap/${dispatchedErrorMessage?.data?.data?.results?.success ? 'check-circle.svg': 'x-circle.svg'}`} className={`svg-icon svg-icon-3x ${dispatchedErrorMessage?.data?.data?.results?.success ? 'text-success' : 'text-danger'}`}/> 
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <Title style={{margin: 0}}>
                                        {dispatchedErrorMessage?.data?.data?.results?.success ? 'Shipment successfully dispatched' : dispatchedErrorMessage?.data?.data?.message}
                                    </Title>
                                </div>
                                <div className="col-12 d-flex justify-content-center">
                                    <Title>
                                        BOL #{documents?.BOLNumber}
                                    </Title>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className={`col-12 d-none d-sm-flex ${dispatchedErrorMessage?.data?.data?.results?.success ? 'justify-content-center' : 'justify-content-end'}`}>
                                    {dispatchedErrorMessage?.data?.data?.results?.success && (
                                        <>
                                            <a className="btn btn-primary me-4" href={documents?.BOLDocumentURL}>
                                                Download BOL PDF
                                            </a>
                                            {
                                                (documents?.shipmentLabel || documents?.carrierLabel) &&
                                                <a className="btn btn-primary me-4" href={documents?.carrierLabel || documents?.shipmentLabel}>
                                                    {documents?.carrierLabel ? 'Download Carrier Labels' : 'Download Shipping Labels'}
                                                </a>
                                            }
                                        </>
                                    )}
                                    <>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        >
                                            Close
                                        </button>
                                    </>
                                </div>
                                <div className="col-12 d-flex d-sm-none justify-content-center mb-4">
                                    <a className="btn btn-primary me-4" href={documents?.BOLDocumentURL}>
                                        Download BOL PDF
                                    </a>
                                    {
                                        (documents?.shipmentLabel || documents?.carrierLabel) &&
                                        <a className="btn btn-primary" href={documents?.carrierLabel || documents?.shipmentLabel}>
                                            {documents?.carrierLabel ? 'Download Carrier Labels' : 'Download Shipping Labels'}
                                        </a>
                                    }
                                </div>
                                <div className="col-12 d-flex d-sm-none justify-content-center">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
      </ModalStyled>
    )
}

export default ShipmentDispatchedModal