import { call } from "./call"
import { make3PLCalls } from "./make3PLCalls"

export const makeCalls = async(rows, vendors, markups, vendorsToUse, setData, callsDid, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers) => {
    let maxCalls = 100
    let allCallsNoOrder = []

    if(rows?.length){
        rows.filter(row => row?.progress !== 100).forEach((row, rowIndex) => {
            vendors.forEach(vendor => {
                allCallsNoOrder.push({
                    shipmentRow: row,
                    shipmentIndex: rows.filter(row => row?.progress === 100).length ? rowIndex + rows.filter(row => row?.progress === 100).length : rowIndex,
                    vendor: vendor
                })
            })
        })
    }

    if(allCallsNoOrder.length > 500){
        allCallsNoOrder = allCallsNoOrder?.filter(call => {
            let weight = call.shipmentRow?.FreightInfo?.length
            ? call.shipmentRow.FreightInfo?.map(freight => parseInt(freight?.Weight)).reduce((partialSum, a) => partialSum + a, 0)
            : parseInt(call.shipmentRow?.Weight)

            if(weight < 1000){
                if(call.vendor?.rateType === 'VOLUME'){
                    return false
                }
            }

            if(weight > 400){
                if(call.vendor?.rateType === 'SMALL PACKAGE'){
                    return false
                }
            }
    
            return true
        })
    }

    allCallsNoOrder = allCallsNoOrder.sort((a,b) => a.vendor.name < b.vendor.name ? -1 : a.vendor.name > b.vendor.name ? 1 : 0)
    let allCalls = allCallsNoOrder

    const is3PL = allCallsNoOrder.filter(call => call.vendor.is3PL)
    const directContracts = allCallsNoOrder.filter(call => !call.vendor.is3PL)

    const chunkSize = maxCalls
    let finalArrayDirectContracts = []
    let finalArray3PL = []

    for (let i = 0; i < is3PL.length; i += chunkSize) {
        const chunk = is3PL.slice(i, i + chunkSize);
        finalArray3PL.push(chunk)
    }

    for (let i = 0; i < directContracts.length; i += chunkSize) {
        const chunk = directContracts.slice(i, i + chunkSize);
        finalArrayDirectContracts.push(chunk)
    }
    
    for (let i = 0; i < finalArrayDirectContracts.length; i++) {
        const groupOfCalls = finalArrayDirectContracts[i].map(async(callSingle, index) => {
            return call(callSingle.shipmentRow, rows, callSingle.vendor, markups, vendorsToUse, setData, callsDid, allCalls, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers)
        })

        await Promise.all(groupOfCalls)

        continue;
    }

    let callsToDo = []

    directContracts.forEach((call) => {
        if(!invalidZipcodes.includes(call.shipmentRow.OriginZipcode) && !invalidZipcodes.includes(call.shipmentRow.DestinationZipcode)){
            callsToDo.push(call)
        }
    })

    if(callsDid.length === callsToDo.length){
        make3PLCalls(finalArray3PL, rows, markups, vendorsToUse, setData, callsDid, allCalls, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers)
    }
}