import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

//Pages
import { Home } from '../pages/home/Home'
import { Quote } from '../pages/quote/Quote'
import { Book } from '../pages/book/Book'
import { Reports } from '../pages/reports/Reports';
import { Settings } from '../pages/settings/Settings';
import { UserModel } from '../modules/auth/models/UserModel';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../setup/redux/RootReducer';
import SessionExpiredModal from '../components/SessionExpiredModal';
import { TrackingGrid } from '../pages/track/TrackingGrid';
import { InvoicesGrid } from '../pages/invoices/InvoicesGrid'
import { LandingPage } from '../data/LandingPage'
import ReportsDetailed from '../pages/reports/ReportsDetailed'
import Favicon from 'react-favicon'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const defaults = useSelector<RootState>(({pages}) => pages.defaults) as any
  const customerInfo = useSelector(({auth}: any) => auth.customerInfo)

  return (
    <>
      {customerInfo.customFavIcon && (
        <Favicon url={customerInfo.customFavIcon} animated={false}/>
      )}
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to={LandingPage.find(page => page.value === defaults.landingPage || 'Dashboard')?.path || '/home'}/>} />

          {/* Pages */}
          <Route path='home' element={ <Home/> } />
          <Route path='quote/*' element={ <Quote/> } />
          { user?.allowBooking ? <Route path='book/*' element={ <Book/> } /> : null }
          <Route path='trackings' element={ user ? user?.allowTrack ? <TrackingGrid/> : <Navigate to='/home'/> : <TrackingGrid/> } />
          <Route path='invoices' element={ user ? user?.displayInvoiceInformation ? <InvoicesGrid/> : <Navigate to='/error/404'/> : <InvoicesGrid/> } />
          <Route path='reports' element={ <Reports/> } />

          {/* Reports */}
          <Route path='reports/carrier-performance' element={ <ReportsDetailed/> } />
          <Route path='reports/shipment-mode-breakdown' element={ <ReportsDetailed/> } />
          <Route path='reports/shipments-by-Carrier' element={ <ReportsDetailed/> } />
          <Route path='reports/open-claims' element={ <ReportsDetailed/> } />
          <Route path='reports/credit-info' element={ <ReportsDetailed/> } />

          <Route path='lookups/*' element={ <Settings/> } />

          {/* Lazy Modules */}
          <Route
            path='/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
      <SessionExpiredModal/>
    </>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
