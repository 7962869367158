import axios from "axios"
import { isTokenExp } from "./isTokenExp"
import { v4 as uuidv4 } from 'uuid';

const GET_RATES = '/applet/v1/rate'
const GET_RATES_SUB_VENDOR = '/applet/v1/rate/p2p'

export const getRates = (values, selectedAcc, enabledVendorsFiltered, enabledVendorsLTL, enabledVendorsVOL, setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, setShowTable, setRates, setRatesVOL, setLinearProgress, setRatesStopped, setVOLQuoteIsCheaper, setRatesVOLCheaper, setLoadVOLQuotes, source, normalizeParams, history) => {
  let array = []
  let arrayVOL = []
  let progress = 0
  let callsFinishedLocal = 0
  let vendorsProcessedLocal = []
  let vendorsTimedOutLocal = []

  setShowTable(true)
  setRates([])
  setRatesVOL([])
  setLinearProgress(0)
  setRatesStopped(false)
  setVOLQuoteIsCheaper(false)
  setRatesVOLCheaper(0)
  setLoadVOLQuotes(undefined)

  let enabledVendorsFilteredLocal = []

  enabledVendorsFiltered.forEach(vendor => {
    if(vendor?.subVendors?.length){
      let subVendors = vendor?.subVendors?.filter(subVendor => subVendor?.carrierType === vendor?.type)
      
      if(vendor?.type !== 'LTL' && values.LTL){
        subVendors.push(...vendor?.subVendors?.filter(subVendor => subVendor?.carrierType === 'LTL'))
      }

      if(vendor?.type !== 'VOL' && (values.freightInfoTotals.totalWeight > (values.freightInfoTotals.symbol === 'lbs' ? 5000 : 2268) || values.freightInfoTotals.totalCFT > 200)){
        subVendors.push(...vendor?.subVendors?.filter(subVendor => subVendor?.carrierType === 'VOL'))
      }

      if(vendor?.type !== 'SP' && values.courier){
        subVendors.push(...vendor?.subVendors?.filter(subVendor => subVendor?.carrierType === 'SP'))
      }

      if(vendor?.type !== 'INTL' && values.AIR){
        subVendors.push(...vendor?.subVendors?.filter(subVendor => subVendor?.carrierType === 'INTL'))
      }

      subVendors?.forEach(subVendor => {
        enabledVendorsFilteredLocal.push({...subVendor, id: vendor.id})
      })
    }else{
      enabledVendorsFilteredLocal.push(vendor)
    }
  })
  setEnabledVendors(enabledVendorsFilteredLocal)

  enabledVendorsFilteredLocal?.forEach(vendor => {
    axios.get(!vendor?.carrierCode ? GET_RATES : GET_RATES_SUB_VENDOR, {
      params: !vendor?.carrierCode ? normalizeParams(vendor.id) : {carrierCode: vendor?.carrierCode, ...normalizeParams(vendor.id)} ,
      timeout: vendor?.rateTimeout ? parseInt(`${vendor?.rateTimeout}000`) : 30000,
      cancelToken: source.token
    }).then(response => {
      response.data.data.results?.filter(result => !result.error).forEach((newRate) => {
          if(vendor.type === 'VOL'){
            if(newRate.SCAC && user.SCACFilteringEnabled){
              let duplicated = arrayVOL.find(oldRate => oldRate.SCAC === newRate.SCAC && oldRate.serviceLevel.toLowerCase() === newRate.serviceLevel.toLowerCase() && oldRate.id !== newRate.id) || {}

              let vendorNew = enabledVendorsVOL?.find(vendor => vendor?.id === newRate?.vendorId)
              let vendorDuplicated = duplicated?.vendorId ? enabledVendorsVOL.find(vendor => vendor.id === duplicated?.vendorId) : {id: '', is3PL: false, isDirectAccount: false}

              let eliminateDuplicated = arrayVOL.filter(rate => JSON.stringify(rate) !== JSON.stringify(duplicated))
              let eliminateNew = arrayVOL.filter(rate => JSON.stringify(rate) !== JSON.stringify(newRate))

              if(vendorNew?.id && vendorDuplicated?.id){
                if(vendorNew.is3PL && (!vendorDuplicated.is3PL && duplicated.total >= newRate.total)){
                  arrayVOL = [...eliminateDuplicated, newRate]
                }else if(vendorNew.is3PL && vendorDuplicated.is3PL){
                  if(newRate.total >= duplicated.total){
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }else{
                    arrayVOL = [...eliminateDuplicated, newRate]
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.isDirectAccount){
                  if(newRate.total >= duplicated.total){
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }else{
                    arrayVOL.push(newRate)
                    arrayVOL = eliminateDuplicated
                  }
                }else if(vendorNew.is3PL && vendorDuplicated.isDirectAccount){
                  if(duplicated.total > (newRate.total + parseFloat(user.SCACFilteringVarianceUSD)) && duplicated.total > (newRate.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    arrayVOL.push(newRate)
                    arrayVOL = eliminateDuplicated
                  }else{
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.is3PL){
                  if(newRate.total <= (duplicated.total + parseFloat(user.SCACFilteringVarianceUSD)) && newRate.total <= (duplicated.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    arrayVOL.push(newRate)
                    arrayVOL = eliminateDuplicated
                  }else{
                    arrayVOL.push(duplicated)
                    arrayVOL = eliminateNew
                  }
                }
              }else{
                arrayVOL.push(newRate)
              }
            }else{
              arrayVOL.push(newRate)
            }
          }else{
            
            if(newRate.SCAC && user.SCACFilteringEnabled){
              let duplicated = array.find(oldRate => oldRate.SCAC === newRate.SCAC && oldRate.serviceLevel.toLowerCase() === newRate.serviceLevel.toLowerCase() && oldRate.id !== newRate.id) || {}

              let vendorNew = enabledVendorsLTL.find(vendor => vendor.id === newRate.vendorId)
              let vendorDuplicated = duplicated.vendorId ? enabledVendorsLTL.find(vendor => vendor.id === duplicated.vendorId) : {id: '', is3PL: false, isDirectAccount: false}
    
              let eliminateDuplicated = array.filter(rate => JSON.stringify(rate) !== JSON.stringify(duplicated))
              let eliminateNew = array.filter(rate => JSON.stringify(rate) !== JSON.stringify(newRate))
    
              if(vendorNew.id && vendorDuplicated.id){
                if(vendorNew.is3PL && (!vendorDuplicated.is3PL && duplicated.total >= newRate.total)){
                  array = [...eliminateDuplicated, newRate]
                }else if(vendorNew.is3PL && vendorDuplicated.is3PL){
                  if(newRate.total >= duplicated.total){
                    array.push(duplicated)
                    array = eliminateNew
                  }else{
                    array = [...eliminateDuplicated, newRate]
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.isDirectAccount){
                  if(newRate.total >= duplicated.total){
                    array.push(duplicated)
                    array = eliminateNew
                  }else{
                    array.push(newRate)
                    array = eliminateDuplicated
                  }
                }else if(vendorNew.is3PL && vendorDuplicated.isDirectAccount){
                  if(duplicated.total > (newRate.total + parseFloat(user.SCACFilteringVarianceUSD)) && duplicated.total > (newRate.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    array.push(newRate)
                    array = eliminateDuplicated
                  }else{
                    array.push(duplicated)
                    array = eliminateNew
                  }
                }else if(vendorNew.isDirectAccount && vendorDuplicated.is3PL){
                  if(newRate.total <= (duplicated.total + parseFloat(user.SCACFilteringVarianceUSD)) && newRate.total <= (duplicated.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                    array.push(newRate)
                    array = eliminateDuplicated
                  }else{
                    array.push(duplicated)
                    array = eliminateNew
                  }
                }
              }else{
                array.push(newRate)
              }
            }else{
              array.push(newRate)
            }
          }
        }
      )

      if(vendor.type === 'VOL'){
        setRatesVOL([
          ...arrayVOL
        ])
      }else{
        setRates([
          ...array
        ])
      }

      if(history.includes('history')){
        let freightInfo = values.freightInfo.map(values => {
          return {...values, id: ''}
        })
  
        let newSearch = {...values, selectedAcc, id: uuidv4(), hashId: `${JSON.stringify(selectedAcc)}${JSON.stringify(values.destination)}${JSON.stringify(values.origin)}${JSON.stringify(freightInfo)}${values.insuranceAmount}${values.insuranceAddOn}${values.insuranceFreight}${values.commodityInsurance}`}
        let existingSearches = JSON.parse(localStorage.getItem(history)) || []
        let isDuplicated = existingSearches.find(search => search.hashId === newSearch.hashId)
  
        if(isDuplicated){
  
        }else{
          existingSearches.unshift(newSearch);
          localStorage.setItem(history, JSON.stringify(existingSearches));
        }
      }

      progress = progress + (100 / enabledVendorsFilteredLocal.length)
      setLinearProgress(progress)
      vendorsProcessedLocal.push(vendor)
      setVendorsProcessed(vendorsProcessedLocal.length.toString())
    }).catch(error => {
      if(error.toString().includes('timeout')){
        vendorsTimedOutLocal = [...vendorsTimedOutLocal, vendor]
        setVendorsTimedOut(vendorsTimedOutLocal)
      }else if(error.message !== 'Operation canceled by the user.'){
        progress = progress + (100 / enabledVendorsFilteredLocal.length)
        setLinearProgress(progress)
        vendorsProcessedLocal.push(vendor)
        setVendorsProcessed(vendorsProcessedLocal.length.toString())
      }

      if(error?.response?.data?.error?.message.length){
        if(error?.response?.status === 401){
          setRatesStopped(true)
          isTokenExp()
        }
      }
    }).finally(() => {
      callsFinishedLocal = callsFinishedLocal + 1
      if(callsFinishedLocal === enabledVendorsFilteredLocal.length){
        if(vendorsTimedOutLocal?.length){
          setOpenConfirmTimedOutModal(true)
        }
        setRatesStopped(true)
      }
    })
  })
}