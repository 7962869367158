import { Autocomplete, CircularProgress, IconButton, InputAdornment, Paper, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import RestrictionsModal from '../RestrictionsModal';
import { FormsEditProps } from '../../../pages/vendors/models';
import { KTSVG } from '../../../../_metronic/helpers';
import ClearIcon from '@mui/icons-material/Clear';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import { stylesMUIInput } from '../../stylesMUIInput';
import { InputStyled } from '../../Inputs.styled';
import { RootState } from '../../../../setup';

const GET_AVAILABLEAPIS = '/api/v1/system/availableapis'

const APIIntegration = ({formik, testConnection, testingConnection, rowSelected}: FormsEditProps) => {
    const dispatch = useDispatch()
    const [searchApi, setSearchAPI] = useState('')
    const [availableApis, setAvailableApis] = useState([])
    const [openRestrictionsModal, setOpenRestrictionsModal] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const useStyles = makeStyles({
        textField: {
            backgroundColor: 'white',
            border: '1px solid #E4E6EF !important',
            borderRadius: '0.475rem',
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: 'none'
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#5E6278"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 'none',
            },
            "& .MuiOutlinedInput-root":{
                color: "#5E6278"
            }
        },
        height: {
            height: 53.13,
        }
    })
    const classesAutocomplete = useStyles()

    useEffect(() => {
        if(availableApis.length === 0){
            axios.get(GET_AVAILABLEAPIS)
            .then((results) => {
                setAvailableApis(results.data.data.results)
            }).catch(error => {
                checkErrorAPICall(error, dispatch, 'Get available Apis')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(availableApis.length > 0){
            formik.setFieldValue('APIIntegration.APIList', rowSelected.APICredentials?.API || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableApis])

    return (
        <>
            <div className="row align-items-center">
                <div className="col-12 col-sm-2 col-md-1 mb-0 mb-sm-5 mb-md-0">
                    <label className='form-label'>API List</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <Autocomplete
                        disablePortal
                        isOptionEqualToValue={(option, value) => option?.APIName === value?.APIName}
                        autoComplete={false}
                        className="dropdownContainer w-100"
                        getOptionLabel={(option) => option?.APIName}
                        options={availableApis}
                        handleHomeEndKeys
                        inputValue={searchApi || ''}
                        value={availableApis.filter(api => api.APICode === formik.values.APIIntegration.APIList)[0] || null}
                        clearOnBlur={false}
                        onInputChange={(e, value) =>  {
                            setSearchAPI(value || '')
                        }}
                        onChange={(event, value) => {
                            formik.setFieldValue('APIIntegration.APIList', value?.APICode || '')
                        }}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    fullWidth
                                    autoComplete='new-password'
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    formik.values.APIIntegration.APIList &&
                                                    <IconButton
                                                        size='small'
                                                        className='me-1'
                                                        onClick={() => {
                                                            setSearchAPI('')
                                                            formik.setFieldValue('APIIntegration.APIList', '')
                                                        }}
                                                    >
                                                        <ClearIcon style={{color: '#a1a5b7'}} />
                                                    </IconButton>
                                                }
                                                <KTSVG path='/media/icons/bootstrap/search.svg' className='svg-icon svg-icon-6' style={{color: '#a1a5b7'}}/>
                                            </InputAdornment>
                                        ),
                                        className: classesAutocomplete.height,
                                        style: stylesMUIInput(fontSize)
                                    }}
                                    className={classesAutocomplete.textField}
                                />
                            );
                        }}
                        PaperComponent={({ children }) => (
                            <Paper style={{ fontFamily: "Poppins", color:"#5E6278", fontSize: 14, fontWeight: 500 }}>
                                {children}
                            </Paper>
                        )}
                        noOptionsText={<div style={{fontWeight: 500, color: "#a1a5b6"}}>No options</div>}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Username</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3 mb-5 mb-sm-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('APIIntegration.username')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Password</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('APIIntegration.password')}
                        fontSize={fontSize}
                    />
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-12 col-sm-2 col-md-1 mb-0 mb-sm-5 mb-md-0">
                    <label className='form-label'>Account#</label>
                </div>
                <div className="col-12 col-sm-10 col-md-3 mb-5 mb-md-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('APIIntegration.account')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Access Code</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3 mb-5 mb-sm-0">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('APIIntegration.accessCode')}
                        fontSize={fontSize}
                    />
                </div>
                <div className="col-12 col-sm-2 col-md-1">
                    <label className='form-label'>Extra Field</label>
                </div>
                <div className="col-12 col-sm-4 col-md-3">
                    <InputStyled
                        type="text"
                        className="form-control"
                        autoComplete='off'
                        {...formik.getFieldProps('APIIntegration.extraField')}
                        fontSize={fontSize}
                    />
                </div>
            </div>

            <div className="row align-items-center mt-5">
                <div className="col-1"></div>
                <div className="col-3"></div>
                <div className="col-1"></div>
                <div className="col-12 col-md-3 mb-5 mb-md-0">
                    <button
                        className='btn btn-primary w-100'
                        onClick={() => setOpenRestrictionsModal(true)}
                    >
                        Set Restrictions
                    </button>
                </div>
                <div className="col-1"></div>
                <div className="col-12 col-md-3">
                    <button
                        className='btn btn-primary w-100 d-flex justify-content-center align-items-center'
                        onClick={() => testConnection(false)}
                    >
                        Test Connection{testingConnection && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                    </button>
                </div>
            </div>
            <RestrictionsModal
                open={openRestrictionsModal}
                setOpen={setOpenRestrictionsModal}
                editVendor={true}
                selectedRow={formik.values.APIIntegration?.restrictions}
                setDataFormik={formik.setFieldValue}
            />
        </>
    )
}

export default APIIntegration