export const TypeOptions = [
    {
        name: "PALLET",
        value: "PLT"
    },
    {
        name: "CARTON",
        value: "CTN"
    },
    {
        name: "CRATE",
        value: "CRT"
    },
    {
        name: "DRUM",
        value: "DRM"
    },
    {
        name: "CONTAINER",
        value: "CON"
    },
    {
        name: "BOX",
        value: "BOX"
    },
    {
        name: "BUNDLE",
        value: "BDL"
    },
    {
        name: "ENVELOPE",
        value: "ENV"
    },
    {
        name: "CYLINDER",
        value: "CYL"
    },
    {
        name: "TOTES",
        value: "TOT"
    },
    {
        name: "TRUCKLOAD",
        value: "TRUCK LOAD"
    },
    {
        name: "CASES",
        value: "CAS"
    },
    {
        name: "OTHER",
        value: "OTH"
    }
]