import { deleteSpacesInKeys } from "./deleteSpacesInKeys"

export const fileHasAllKeysBooking = (column) => {
    let oneMissed = false
    if (column) {
      deleteSpacesInKeys(column)
      const requiredKeys = ['ShipperZipcode', 'ConsigneeZipcode', 'Pieces', 'Weight', 'Class']

      requiredKeys.forEach(key => {
          if (column?.hasOwnProperty(key)) {

          } else {
              oneMissed = true
          }
      })
    }

    if (oneMissed) {
        return false
    } else {
        return true
    }
}