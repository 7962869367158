import { makeStyles } from "@mui/styles";

export const tableStyles = makeStyles({
    circularProgress: { 
        "& .MuiCircularProgress-svg": {
            color: 'white !important'
        },
        height: 16,
        width: 16
    },
    table: {
        "& .MuiTable-root":{
            backgroundColor: 'white'
        },
        "&:hover .hoverRow":{
            filter: `brightness(96%)`
        },
        "& .MuiTableCell-root":{
            fontFamily: 'Poppins',
            fontSize: '12px',
           /*  color: "#3F4254 !important", */
            border: 'none',
            padding: '6px 10px !important',
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            fontSize: '13px',
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        "& .Mui-active": {
           /*  color: "#3F4254 !important", */
        },
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            margin: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        }
    },
    tableQuoteBreakdown: {
        "& .MuiTableCell-root":{
            fontFamily: 'Poppins',
            fontSize: '12px',
            /* color: "#3F4254 !important", */
            border: 'none',
            padding: '6px 10px !important',
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            paddingTop: '14px !important',
            paddingBottom: '14px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        "& .Mui-active": {
           /*  color: "#3F4254 !important", */
        },
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            margin: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        }
    },
    tableQuotesGrid: {
        "& .MuiTableCell-root":{
            fontFamily: 'Poppins',
            fontSize: '12px',
            /* color: "#3F4254 !important", */
            border: 'none',
            padding: '6px 9px',
        },
        "& .MuiTableCell-root.MuiTableCell-body": {
            paddingTop: '5px !important',
            paddingBottom: '5px !important'
        },
        "& .MuiFormControl-root":{
            paddingLeft: '0px !important'
        },
        "& .MuiToolbar-root": {
            width: '80px !important',
            paddingLeft: 0,
            paddingRight: 18
        },
        /* "& .Mui-active": {
            color: "#3F4254 !important",
        }, */
        "& .MuiTablePagination-selectLabel": {
            fontSize: '12px',
            marginLeft: 9,
            marginBottom: 0,
            fontFamily: 'Poppins'
        },
        "& .MuiSelect-select": {
            fontFamily: 'Poppins'
        },
        "& .MuiTablePagination-displayedRows": {
            fontSize: '12px',
            marginBottom: 0,
            marginRight: 7,
            fontFamily: 'Poppins'
        },
        "& .MuiIconButton-root": {
            width: '35px !important',
            height: '35px !important'
        },
        "&:hover .hoverRow":{
            filter: `brightness(96%)`
        },
        "& .MuiCircularProgress-svg": {
            color: '#009EF7'
        }
    },
    linearProgress: {
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#009EF7 !important"
        },
        "& .MuiLinearProgress-root": {
            display: "flex !important",
        }
    },
    linearProgressFinished: {
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#47BE7C !important"
        }
    },
    currentPageText: {
        
    }
})