import axios from "axios"
import { v4 as uuidv4 } from 'uuid';

const API_URL = process.env.REACT_APP_API_URL
const ZIPCODEBATCH = `${API_URL}/api/v1/tools/zipcodesbatch`

const addOriginOrDestinationMissing = (shipment, resultsData, originOrDestination) => {
    if(!shipment[`${originOrDestination}City`]){
        if(resultsData[shipment[`${originOrDestination}Zipcode`]]?.length){
            shipment[`${originOrDestination}City`] = resultsData[shipment[`${originOrDestination}Zipcode`]][0]?.city
        }
    }
    if(!shipment[`${originOrDestination}State`]){
        if(resultsData[shipment[`${originOrDestination}Zipcode`]]?.length){
            shipment[`${originOrDestination}State`] = resultsData[shipment[`${originOrDestination}Zipcode`]][0]?.stateCode
        }
    }
}

export const searchCityAndStateMissing = (dataValid, data, setData, addOrReplace, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setOpenWarningCallsModal, setOpenConfirmModal, setNumberOfZipcodesMissing, vendorsToUse, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes) => {
    let originAndDestinationMissing = []

    dataValid.forEach((shipment) => {
        if(!shipment.ShipperCity || !shipment.ShipperState){
            if(!originAndDestinationMissing.includes(shipment.ShipperZipcode)){
                originAndDestinationMissing.push(shipment.ShipperZipcode)
            }
        }
        
        if(!shipment.ConsigneeCity || !shipment.ConsigneeState){
            if(!originAndDestinationMissing.includes(shipment.ConsigneeZipcode)){
                originAndDestinationMissing.push(shipment.ConsigneeZipcode)
            }
        }
    })

    if(originAndDestinationMissing?.length){
        let freightsWithShipment = []
        let rowsWithInvalidZipcodeArray = []
        let notFound = []
        setNumberOfZipcodesMissing(originAndDestinationMissing?.length || null)

        let newArray = addOrReplace === 'add' ? [...(data?.length ? data : []), ...dataValid] : [...dataValid]
        let regexCanada = /[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/;
        let zipcodesCanada = originAndDestinationMissing.filter(o => regexCanada.test(o) === true)
        setSearchingMissingData(true)
        
        axios.post(`${ZIPCODEBATCH}?countryCode=US&searchBy=zipcode`, {
            search: originAndDestinationMissing.filter(o => regexCanada.test(o) !== true),
            onlyValidate: false
        }).then((results) => {
            if(zipcodesCanada.length){
                axios.post(`${ZIPCODEBATCH}?countryCode=CA&searchBy=zipcode`, {
                    search: zipcodesCanada,
                    onlyValidate: false
                }).then((resultsCanada) => {
                    setSearchingMissingData(false)
                    let resultsCA = originAndDestinationMissing.filter(o => regexCanada.test(o) !== true).length ? {...results?.data?.data?.results, ...resultsCanada?.data?.data?.results} : resultsCanada?.data?.data?.results

                    newArray.forEach((shipment, index) => {
                        //Origin
                        addOriginOrDestinationMissing(shipment, resultsCA, 'Shipper')
                        //Destination
                        addOriginOrDestinationMissing(shipment, resultsCA, 'Consignee')
        
                        if(resultsCanada?.data?.data?.errors?.notFound || (originAndDestinationMissing.filter(o => regexCanada.test(o) !== true)?.length && results?.data?.data?.errors?.notFound?.length)){
                            notFound = originAndDestinationMissing.filter(o => regexCanada.test(o) !== true)?.length ? [...(results?.data?.data?.errors?.notFound?.length ? results?.data?.data?.errors?.notFound : []), ...(resultsCanada?.data?.data?.errors?.notFound?.length ? resultsCanada?.data?.data?.errors?.notFound : [])] : resultsCanada?.data?.data?.errors?.notFound
        
                            if(shipment?.FreightInfo?.length){
                                freightsWithShipment.push(...shipment.FreightInfo)
                            }
                        
                            if(notFound.includes(shipment.ShipperZipcode.toString()) || notFound.includes(shipment.ConsigneeZipcode.toString())){
                                if(!rowsWithInvalidZipcodeArray.includes(index)){
                                    rowsWithInvalidZipcodeArray.push(index)
                                }
                            }
                        
                            setRowsWithInvalidZipcode(rowsWithInvalidZipcodeArray)
                        }
                    })

                    setInvalidZipcodes(addOrReplace === 'replace' ? notFound : [...invalidZipcodes, ...notFound])
    
                    setData(newArray.map((singleData) => {
                        return {
                            ...singleData,
                            id: uuidv4()
                        }
                    }))
        
                    if(rowsWithInvalidZipcodeArray?.length){
                        setOpenInvalidZipcodesModal(true)
                    }else if(newArray?.length > 500){
                        setOpenWarningCallsModal(true)
                    }else{
                        setOpenConfirmModal(true)
                    }
                })
            }else if(originAndDestinationMissing.filter(o => regexCanada.test(o) !== true).length && !zipcodesCanada.length){
                setSearchingMissingData(false)
                let resultsData = results?.data?.data?.results
    
                newArray.forEach((shipment, index) => {
                    //Origin
                    addOriginOrDestinationMissing(shipment, resultsData, 'Shipper')
                    //Destination
                    addOriginOrDestinationMissing(shipment, resultsData, 'Consignee')
    
                    if(results?.data?.data?.errors?.notFound){
                        notFound = results?.data?.data?.errors.notFound
    
                        if(shipment?.FreightInfo?.length){
                            freightsWithShipment.push(...shipment.FreightInfo)
                        }
                    
                        if(notFound.includes(shipment.ShipperZipcode.toString()) || notFound.includes(shipment.ConsigneeZipcode.toString())){
                            if(!rowsWithInvalidZipcodeArray.includes(index)){
                                rowsWithInvalidZipcodeArray.push(index)
                            }
                        }
                    
                        setRowsWithInvalidZipcode(rowsWithInvalidZipcodeArray)
                    }
                })
                
                setInvalidZipcodes(addOrReplace === 'replace' ? notFound : [...invalidZipcodes, ...notFound])
    
                setData(newArray.map((singleData) => {
                    return {
                        ...singleData,
                        id: uuidv4()
                    }
                }))
    
                if(rowsWithInvalidZipcodeArray?.length){
                    setOpenInvalidZipcodesModal(true)
                }else if(newArray?.length > 500){
                    setOpenWarningCallsModal(true)
                }else{
                    setOpenConfirmModal(true)
                }
            }
        }).catch(() => {
            setSearchingMissingData(false)
        })
    }else{
        let newArray = addOrReplace === 'add' ? [...(data?.length ? data : []), ...dataValid] : [...dataValid]

        if(newArray?.length > 500){
            setOpenWarningCallsModal(true)
        }else{
            setOpenConfirmModal(true)
        }
    }
}   