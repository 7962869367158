/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1 d-flex align-items-center'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <div className='text-gray-800'>
            Powered by <img alt='Shipprimus logo' src={process.env.PUBLIC_URL+'/media/logos/favicon.ico'} className='h-20px'/> <a href='https://www.shipprimus.com' target="_blank" rel="noopener noreferrer" className='text-white text-hover-primary'> </a><a href='https://www.shipprimus.com' target="_blank" rel="noopener noreferrer" className='text-gray-800 text-hover-primary'>Shipprimus.com</a>
          </div>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
