import { ModalStyled } from '../Modals.styled'
import { Backdrop, Fade } from '@mui/material'
import { modalStyles } from '../ModalStyles'
import { useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import SLTable from './SLTable'
import { useEffect, useState } from 'react'
import { formatPhone } from '../../helpers/formatPhone'
import { ShippingLocationsModalProps } from './models'
import { CountryOptions } from '../../data/CountryOptions'

const ShippingLocationsModal = ({open, setOpen, setData, setFormikValues, formikValues, setShippingLocationLocal, formFor, forStops, setOpenClearRateToEditModal, setNewValuesWhenRateCleared, data}: ShippingLocationsModalProps) => {
    const classes = modalStyles({})
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
    const [shippingLocation, setShippingLocation] = useState({}) as any

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(shippingLocation.name){
            if(formFor){
                setData(shippingLocation)
                setShippingLocationLocal(shippingLocation?.name)
                if(!forStops){
                    setFormikValues(`${formFor}.name`, shippingLocation?.name || "")
                    setFormikValues(`${formFor}.address`, shippingLocation?.address || "")
                    setFormikValues(`${formFor}.address1`, shippingLocation?.address1 || "")
                    setFormikValues(`${formFor}.contact`, shippingLocation?.contact || "")
                    setFormikValues(`${formFor}.emails`, shippingLocation?.email?.includes(';') ? shippingLocation?.email?.split(';') : shippingLocation?.email?.split()[0] ? shippingLocation?.email?.split(', ') : [] || "")
                    setFormikValues(`${formFor}.id`, shippingLocation?.id || "")
                    setFormikValues(`${formFor}.city`, shippingLocation?.city || "")
                    setFormikValues(`${formFor}.country`, shippingLocation?.country || "US")
                    setFormikValues(`${formFor}.state`, shippingLocation?.state || "")
                    setFormikValues(`${formFor}.zipcode`, shippingLocation?.zipcode || "")
                    setFormikValues(`${formFor}.phone`, formatPhone(shippingLocation?.phone, shippingLocation.country) || "")
                    setFormikValues(`${formFor}.contactPhone`, formatPhone(shippingLocation?.contactPhone, shippingLocation.country) || "")
                }
            }else{
                setData({
                    'Zipcode': shippingLocation?.zipcode,
                    'State': shippingLocation?.state,
                    'City': shippingLocation?.city,
                    'Country': shippingLocation?.country?.length === 3 ? CountryOptions.filter(c => c.CodePrimus === shippingLocation.country)[0].Code : shippingLocation.country || "US",
                    'isSL': true,
                    'isZipcode': false,
                    'isPort':false,
                    'Name': shippingLocation?.name,
                    'id': shippingLocation?.id
                })
            }

            if(forStops){
                setData(shippingLocation)
                setShippingLocationLocal(shippingLocation?.name)
                setFormikValues(`name`, shippingLocation?.name || "")
                setFormikValues(`address`, shippingLocation?.address || "")
                setFormikValues(`address1`, shippingLocation?.address1 || "")
                setFormikValues(`contact`, shippingLocation?.contact || "")
                setFormikValues(`id`, shippingLocation?.id || "")
                setFormikValues(`city`, shippingLocation?.city || "")
                setFormikValues(`country`, shippingLocation?.country || "US")
                setFormikValues(`state`, shippingLocation?.state || "")
                setFormikValues(`zipcode`, shippingLocation?.zipcode || "")
                setFormikValues(`phone`, formatPhone(shippingLocation?.phone, shippingLocation.country) || "")
                setFormikValues(`contactPhone`, formatPhone(shippingLocation?.contactPhone, shippingLocation.country) || "")
            }
            
            setOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shippingLocation])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paperEditModalSL} style={{visibility: 'visible', opacity: '100%'}}>
                    <>
                        <SLTable
                            setShippingLocation={setShippingLocation}
                            open={open}
                            setOpen={setOpen}
                            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                            setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
                            formikValues={formikValues}
                            formFor={formFor}
                            handleClose={handleClose}
                            notStylized={true}
                            data={data}
                        />
                    </>
                    <div className="row mb-3 mt-5">
                        <div className="col-12 d-flex justify-content-end">
                            <button
                                className="btn btn-secondary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </ModalStyled>
    )
}

export default ShippingLocationsModal