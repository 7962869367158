import { useSelector } from "react-redux"
import { RootState } from "../../../setup"

export const SwitchLTL = ({title, ...props}) => {
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

  return (
    <div className="form-check form-switch form-check-custom form-check-solid">
        <input className={`form-check-input ${fontSize >= 13 ? '' : 'h-20px w-40px'}`} type="checkbox" {...props} checked={props.value} id={title}/>
        <label className="form-check-label" htmlFor={title}>
            {title}
        </label>
    </div>
  )
}
