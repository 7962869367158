import { useState } from 'react'
import { modalStyles } from '../ModalStyles';
import { Modal, Fade, Backdrop } from '@mui/material';
import { BigSubtitle, FooterStyled, PaperModal, Title } from '../Modals.styled';
import { StartRatingProps } from '../../../pages/book/models';
import { KTSVG } from '../../../../_metronic/helpers';

const StartRatingModal = ({open, setOpen, setStartRating}: StartRatingProps) => {
    const classes = modalStyles();
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal fontSize={fontSize} widthWhenFontSmall={400} className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <KTSVG path='/media/icons/bootstrap/check-circle.svg' className='svg-icon svg-icon-3x text-success'/>
                            </div>
                            <div className="col-12 mt-5 d-flex justify-content-center">
                                <Title fontSize={fontSize}>Shipment data uploaded</Title>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <BigSubtitle fontSize={fontSize}>Would you like to start rating?</BigSubtitle>
                            </div>
                        </div>
                        <FooterStyled fontSize={fontSize} className="row mt-5 mb-5">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                        setStartRating(true)
                                        setOpen(false)
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setStartRating(false)
                                        setOpen(false)
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </FooterStyled>
                    </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default StartRatingModal