import { IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';

const EditIconRender = ({editShipment, rowData}) => {
    const BOLS = useSelector<RootState>(({pages}) => pages.BOLS, shallowEqual) as any

    return (
        <Tooltip
            title={!(rowData.BOL ? rowData.BOL : BOLS.find(BOL => BOL.id === rowData.id)?.BOL) ? '' : 'Edit Shipment'}
        >
            <IconButton
                disabled={!(rowData.BOL ? rowData.BOL : BOLS.find(BOL => BOL.id === rowData.id)?.BOL) ? true : false}
                onClick={() => {
                    editShipment(rowData, BOLS.find(BOL => BOL.id === rowData.id))
                }}
            >
                <EditIcon
                    style={{color: !(rowData.BOL ? rowData.BOL : BOLS.find(BOL => BOL.id === rowData.id)?.BOL) ? '#81C5FB' : '#0095E8'}}
                />
            </IconButton>
        </Tooltip>
    )
}

export default EditIconRender