import React, { useEffect, useState } from 'react'
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../setup/redux/RootReducer';
import { dataTable } from './models';
import { actionTypes } from '../../modules/auth/redux/PagesRedux';
import NoInfoFoundModal from '../../components/Modals/NoInfoFoundModal/NoInfoFoundModal';
import ErrorsInDataModal from '../../components/Modals/ErrorsInDataModal/ErrorsInDataModal';
import InvalidDataModal from '../../components/Modals/InvalidDataModal/InvalidDataModal';
import UploadFileModal from '../../components/Modals/UploadFileModal/UploadFileModal';
import StartRating from '../../components/Modals/StartRatingModal/StartRatingModal';
import AppendDataModal from '../../components/Modals/AppendDataModal/AppendDataModal';
import { v4 as uuidv4 } from 'uuid';
import Footer from '../../components/Footer/Footer';
import { makeCalls } from '../../helpers/BatchQuotes/makeCalls';
import WarningCallsModal from '../../components/Modals/WarningCallsModal/WarningCallsModal';
import { useSnackbar } from 'notistack';
import { uploadFileBooking } from '../../helpers/uploadFileBooking';
import { uploadFileByPasteBooking } from '../../helpers/uploadFileByPasteBooking';
import MissingDataModal from '../../components/Modals/MissingDataModal/MissingDataModal';
import InvalidZipcodesModal from '../../components/Modals/InvalidZipcodesModal/InvalidZipcodesModal';
import { PageTitle } from '../../../_metronic/layout/core';
import Toolbar from '../../components/Toolbar/Toolbar';
import Table from '../../components/Table/Table';
import { UserModel } from '../../modules/auth/models/UserModel';

let CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const BatchBooking = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [vendors, setVendors] = useState([])
  const [vendorsToUse, setVendorsToUse] = useState([])
  const [data, setData] = useState([] as Array<dataTable>)
  const [totalRows, setTotalRows] = useState(0)
  const [uploadedRows, setUploadedRows] = useState(0)
  const [fileName, setFileName] = useState("")
  const [fileError, setFileError] = useState('')
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [startRating, setStartRating] = useState(false)
  const [loadingRates, setLoadingRates] = useState(false)
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false)
  const [openInvalidDataModal, setOpenInvalidDataModal] = useState(false)
  const [openErrorsInDataModal, setOpenErrorsInDataModal] = useState(false)
  const [openNoInfoFoundModal, setOpenNoInfoFoundModal] = useState(false)
  const [errors, setErrors] = useState([])
  const [openAppendDataModal, setOpenAppendDataModal] = useState(false)
  const [newData, setNewData] = useState([])
  const [fileNameToUse, setFileNameToUse] = useState("")
  const [markups, setMarkups] = useState([
    {
      id: uuidv4(),
      carrier: '' as any,
      rate: '',
      min: '',
      max: '',
      type: ''
    }
  ])
  const [allData, setAllData] = useState([])
  const [openWarningCallsModal, setOpenWarningCallsModal] = useState(false)
  const [columns] = useState([
    { title: 'BOL', field: 'BOL'},
    { title: 'Shipper', field: 'ShipperName'},
    { title: 'Consignee', field: 'ConsigneeName'},
    { title: 'Pieces', field: 'Pieces'},
    { title: 'Carrier', field: 'Carrier'},
    { title: 'Transit', field: 'Transit'},
    { title: 'Total', field: 'Total'},
    { title: 'Progress', field: 'progress', minWidth: 70, width: 120, maxWidth: 200}
  ])
  const [rowsWithInvalidZipcode, setRowsWithInvalidZipcode] = useState([])
  const [searchingMissingData, setSearchingMissingData] = useState(false)
  const [numberOfZipcodesMissing, setNumberOfZipcodesMissing] = useState(null)
  const [openInvalidZipcodesModal, setOpenInvalidZipcodesModal] = useState(false)
  const [invalidZipcodes, setInvalidZipcodes] = useState([])
  const hiddenFileInput = React.useRef(null);
  const [accessorials, setAccessorials] = useState([])
  const handleDataOnChange = React.useCallback(
    (value) => {
      setData(value)
    },
    []
  );

  let progress = []
  let carriers = []

  const handleUploadFileClick = event => {
    hiddenFileInput.current.click();
  };

  const handleUploadFileChange = e => {
    setStartRating(false)
    setErrors([])
    const inputFile = e.target.files[0];
    const reader = new FileReader()
    const name = e.target.files[0].name
    setFileNameToUse(e.target.files[0].name)

    if(inputFile?.type === "text/csv"){
      reader.onload = ({ target }) => {
        const csv = Papa.parse(target.result, { skipEmptyLines: true, header: true });
        let columns = csv?.data;
        setAllData(columns)
        let allShipmentsArray = columns

        uploadFileBooking(data, setData, columns, setFileError, setErrors, setOpenErrorsInDataModal, allShipmentsArray, setOpenUploadFileModal, setOpenAppendDataModal, setFileName, setFileNameToUse, setNewData, setTotalRows, setUploadedRows, vendorsToUse, setOpenWarningCallsModal, setOpenConfirmModal, setOpenInvalidDataModal, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setNumberOfZipcodesMissing, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes, name)
      };

      reader.readAsText(inputFile);
    }else if(inputFile?.type === "application/vnd.ms-excel" || inputFile?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      reader.readAsArrayBuffer(inputFile);

      reader.onload = (e) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, {type: 'buffer'})
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        let columns = XLSX.utils.sheet_to_json(ws, {defval: ''}) as any
        setAllData(columns)
        let allShipmentsArray = columns
        
        uploadFileBooking(data, setData, columns, setFileError, setErrors, setOpenErrorsInDataModal, allShipmentsArray, setOpenUploadFileModal, setOpenAppendDataModal, setFileName, setFileNameToUse, setNewData, setTotalRows, setUploadedRows, vendorsToUse, setOpenWarningCallsModal, setOpenConfirmModal, setOpenInvalidDataModal, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setNumberOfZipcodesMissing, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes, name)
      }
    }else{
      setFileError('.csv, .xlsx, .xls files only')
      setFileName("")
    }
  }

  const lastCopy = async() => {
    setStartRating(false)
    setErrors([])

    const text = await navigator?.clipboard?.readText();
    let columns = Papa.parse(text, { skipEmptyLines: true, header: true }).data
    setAllData(columns)
    let allShipmentsArray = columns

    uploadFileByPasteBooking(data, setData, allData, columns, setOpenNoInfoFoundModal, setErrors, setOpenErrorsInDataModal, allShipmentsArray, setOpenUploadFileModal, setOpenAppendDataModal, setFileName, setFileNameToUse, setNewData, setTotalRows, setUploadedRows, vendorsToUse, setOpenWarningCallsModal, setOpenConfirmModal, setOpenInvalidDataModal, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setNumberOfZipcodesMissing, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes)
  }

  let callsDidArray = []

  let resetValuesRedux = () => {
    dispatch({
      type: actionTypes.SetProgress,
      payload: progress.map((p) => {
          return({
              ...p, progress: 0
          })
      })
    })
    dispatch({type: actionTypes.SetCheaperCarriers, payload: []})
    dispatch({type: actionTypes.SetBOLS, payload: []})
  }

  const getRates = () => {
    callsDidArray = []
    source = CancelToken.source()

    setLoadingRates(true)
    let Rates = data.map(singleData => singleData.Carrier === 'No rates' ? {total: 'none'} : singleData.Rate)
    let RatesProgress = data.filter((progress, index) => !rowsWithInvalidZipcode.includes(index)).map(singleData => singleData.progress)

    data?.forEach((singleData: any, index) => {
      let newArray = [...data]

      if(data.filter((a, index) => rowsWithInvalidZipcode.includes(index)).length && !Rates.includes(undefined)){
        if(!rowsWithInvalidZipcode.includes(index) && singleData.progress === 100){
          newArray[index].Carrier = ""
          newArray[index].Total = ""
          newArray[index].Transit = ""
          newArray[index].progress = 0
          newArray[index].Rates = []
          newArray[index].Rate = []
          newArray[index].BOL = ''
          newArray[index].BOLId = ''
          resetValuesRedux()
        }  
      }
      
       if(singleData.progress !== 0 && singleData.progress !== 100){
        newArray[index].Carrier = ""
        newArray[index].Total = ""
        newArray[index].Transit = ""
        newArray[index].progress = 0
        newArray[index].Rates = []
        newArray[index].Rate = []
        newArray[index].BOL = ''
        newArray[index].BOLId = ''
        resetValuesRedux()
      }

      if(Rates.includes(undefined) && Rates.filter(rate => rate?.total)?.length > 0){
        if(singleData.progress === 0){
          newArray[index].Carrier = ""
          newArray[index].Total = ""
          newArray[index].Transit = ""
          newArray[index].progress = 0
          newArray[index].Rates = []
          newArray[index].Rate = []
          newArray[index].BOL = ''
          newArray[index].BOLId = ''
          resetValuesRedux()
        }
      }

      if(RatesProgress.filter(progress => progress !== 100).length === 0){
        newArray[index].Carrier = ""
        newArray[index].Total = ""
        newArray[index].Transit = ""
        newArray[index].progress = 0
        newArray[index].BillTo = 0
        newArray[index].Profit = 0
        newArray[index].Rates = []
        newArray[index].Rate = []
        newArray[index].BOL = ''
        newArray[index].BOLId = ''
        resetValuesRedux()
      }

      if(!vendorsToUse?.length){
        setLoadingRates(false)
        newArray[index].progress = 100
        dispatch({
          type: actionTypes.SetProgress,
          payload: progress.map((p) => {
              return({
                  ...p, progress: 100
              })
          })
        })
        newArray[index].Carrier = 'No rates'
        setData(newArray)
      }
      setData(newArray)
    })

    makeCalls(data, vendorsToUse, markups, vendorsToUse, setData, callsDidArray, enqueueSnackbar, closeSnackbar, source, setLoadingRates, rowsWithInvalidZipcode, invalidZipcodes, handleDataOnChange, progress, carriers)
  }

  const deleteAllTableData = () => {
    setMarkups([{
      id: uuidv4(),
      carrier: '',
      rate: '',
      min: '',
      max: '',
      type: ''
    }])
    setData([])
    setErrors([])
    setUploadedRows(0)
    setTotalRows(0)
    setFileName('')
    setFileError('')
  }

  useEffect(() => {
    if(startRating && data.length) {
      getRates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startRating])

  useEffect(() => {
    let arrayOfIds = []
    data.forEach((shipment, index) => {
        if(invalidZipcodes?.includes(shipment?.OriginZipcode?.toString()) || invalidZipcodes?.includes(shipment?.DestinationZipcode?.toString())){
          arrayOfIds?.push(shipment?.id)
        }
    })

    const allEqual = arr => arr.filter((v, index) => !arrayOfIds?.includes(v.id)).every( v => v?.progress === 100 )
    if(data?.length > 0 && allEqual(data)){
      setLoadingRates(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  let ctrlDown = false
  let ctrlCode = 'ControlLeft'
  let cmdCode = 'MetaLeft'
  let vCode = 'KeyV'

  document.onkeydown = function(e) {
    if (e.code === ctrlCode || e.code === cmdCode) ctrlDown = true
    if (ctrlDown && (e.code === vCode)) lastCopy();
  };

  document.onkeyup = function(e) {
    if (e.code === ctrlCode || e.code === cmdCode) ctrlDown = false
  }

  useEffect(() => {
    let newVendors = (vendors.length ? vendors : [])?.map(vendor => {
      return {
        ...vendor,
        type: vendor?.type[0]?.code === undefined ? 'Other Vendors' : vendor?.type[0]?.code
      }
    })
    setVendors(newVendors?.sort((a,b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch({type: actionTypes.SetVendors, payload: user.enabledVendors})
    setVendors(user.enabledVendors)
    setVendorsToUse(user.enabledVendors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setData(data.map((singleData) => {
      return ({
        ...singleData,
        Accessorials: [...singleData?.AccessorialsInFile, ...accessorials.map((acc) => acc?.code)]
      })
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorials])

  return (
    <>
      <PageTitle>Batch Booking</PageTitle>
      <Toolbar
        loadingRates={loadingRates}
        setOpenUploadFileModal={setOpenUploadFileModal}
        data={data}
        setData={setData}
        lastCopy={lastCopy}
        source={source}
        setOpenWarningCallsModal={setOpenWarningCallsModal}
        getRates={getRates}
        invalidZipcodes={invalidZipcodes}
        accessorials={accessorials}
        setAccessorials={setAccessorials}
      />

      <Table
        columnsTable={columns}
        dataTable={data}
        setDataTable={setData}
        loadingRates={loadingRates}
        totalRows={totalRows}
        setTotalRows={setTotalRows}
        uploadedRows={uploadedRows}
        setUploadedRows={setUploadedRows}
      />
      
      <Footer
        loadingRates={loadingRates}
        totalRows={totalRows}
        uploadedRows={uploadedRows}
        deleteAllTableData={deleteAllTableData}
      />

      <StartRating
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        setStartRating={setStartRating}
      />

      <UploadFileModal
        open={openUploadFileModal}
        setOpen={setOpenUploadFileModal}
        fileError={fileError}
        setFileError={setFileError}
        fileName={fileName}
        handleUploadFileClick={handleUploadFileClick}
        hiddenFileInput={hiddenFileInput}
        loadingRates={loadingRates}
        handleUploadFileChange={handleUploadFileChange}
      />

      <InvalidDataModal
        open={openInvalidDataModal}
        setOpen={setOpenInvalidDataModal}
      />

      <ErrorsInDataModal
        errors={errors}
        open={openErrorsInDataModal}
        setOpen={setOpenErrorsInDataModal}
        setOpenConfirm={setOpenConfirmModal}
        data={data}
        allData={allData}
        setFileName={setFileName}
      />

      <NoInfoFoundModal
        open={openNoInfoFoundModal}
        setOpen={setOpenNoInfoFoundModal}
      />

      <AppendDataModal
        open={openAppendDataModal}
        setOpen={setOpenAppendDataModal}
        setOpenConfirmModal={setOpenConfirmModal}
        newData={newData}
        setData={setData}
        data={data}
        setErrors={setErrors}
        setOpenErrorsInDataModal={setOpenErrorsInDataModal}
        setUploadedRows={setUploadedRows}
        totalRows={totalRows}
        setTotalRows={setTotalRows}
        setFileName={setFileName}
        fileNameToUse={fileNameToUse}
        vendorsToUse={vendorsToUse}
        setOpenWarningCallsModal={setOpenWarningCallsModal}
        rowsWithInvalidZipcode={rowsWithInvalidZipcode}
        setRowsWithInvalidZipcode={setRowsWithInvalidZipcode}
        setSearchingMissingData={setSearchingMissingData}
        setNumberOfZipcodesMissing={setNumberOfZipcodesMissing}
        setOpenInvalidZipcodesModal={setOpenInvalidZipcodesModal}
        invalidZipcodes={invalidZipcodes}
        setInvalidZipcodes={setInvalidZipcodes}
      />

      <WarningCallsModal
        open={openWarningCallsModal}
        setOpen={setOpenWarningCallsModal}
        vendors={vendors}
      />

      <MissingDataModal
        open={searchingMissingData}
        setOpen={setSearchingMissingData}
        numberOfZipcodesMissing={numberOfZipcodesMissing}
      />

      <InvalidZipcodesModal
        open={openInvalidZipcodesModal}
        setOpen={setOpenInvalidZipcodesModal}
        data={data}
        setOpenWarningCallsModal={setOpenWarningCallsModal}
        setOpenConfirmModal={setOpenConfirmModal}
        allData={allData}
        newData={newData}
      />
    </>
  )
}