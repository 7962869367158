import { useFormik } from 'formik'
import { Fragment, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core'
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import FreightInformation from '../../../components/LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformation';
import { FreightTotals } from '../../../components/LTLandVolumeTLPages/FreightInfo/FreightTotals';
import SelectUOM from '../../../components/LTLandVolumeTLPages/FreightInfo/SelectUOM';
import Accessorials from '../../../components/LTLandVolumeTLPages/AccesorialsComponent/Accessorials';
import PickupAndDelivery from '../../../components/NewBookingPage/PickupAndDelivery';
import Tabs from '../../../components/NewBookingPage/Tabs';
import OriginAndDestination from '../../../components/NewBookingPage/OriginAndDestination';
import QuoteBreakdownModal from '../../../components/LTLandVolumeTLPages/Modals/QuoteBreakdownModal';
import CarrierInformation from './CarrierInformation';
import ConfirmClearRateModal from './ConfirmClearRateModal';
import { initialValues, validationSchema} from '../../../helpers/createNewBookingFormik';
import { marginSize } from '../../../helpers/marginSize';
import { portsModel, quoteValuesModel } from '../../../components/PagesModels';
import axios from 'axios';
import { actionTypes } from '../../../modules/auth/redux/PagesRedux';
import { isTokenExp } from '../../../helpers/isTokenExp';
import RatesModal from '../../../components/NewBookingPage/RatesModal';
import { format } from 'date-fns';
import { CircularProgress, IconButton, MenuItem, Tooltip } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { checkErrorAPICall } from '../../../helpers/checkErrorAPICall';
import TrackingModal from '../../../components/TrackingGridPage/TrackingModal';
import DocumentsModal from '../../../components/TrackingGridPage/DocumentsModal/DocumentsModal';
import ShipmentCreatedOrUpdatedModal from '../../../components/NewBookingPage/ShipmentCreatedOrUpdatedModal';
import { useNavigate } from 'react-router-dom';
import ClearRateToEditModal from '../../../components/NewBookingPage/ClearRateToEditModal';
import SaveChangesModal from '../../../components/NewBookingPage/SaveChangesModal';
import HazContactModal from '../../../components/NewBookingPage/HazContactModal';
import PickupDatePastModal from '../../../components/NewBookingPage/PickupDatePastModal';
import { InputStyled, SelectStyled } from '../../../components/Inputs.styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { stylesMUIInput } from '../../../components/stylesMUIInput';
import { useStyles } from '../../../components/LTLandVolumeTLPages/FreightInfo/FreightInformation/FreightInformationStyles';
import Container from '../../../components/DrayagePage/Container';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { normalizeParams } from '../../../helpers/NewBooking/normalizeParams';
import { normalizeParamsShipment } from '../../../helpers/NewBooking/normalizeParamsShipment';
import { openMaps } from '../../../helpers/NewBooking/openMaps';
import { formatPhone } from '../../../helpers/formatPhone';
import { ReactMultiEmailStyled } from '../../../components/NewBookingPage/OriginAndDestination.styled';
import { ChipStyled } from '../../../components/LTLandVolumeTLPages/AccesorialsComponent/Accesorials.styled';
import EditIcon from '@mui/icons-material/Edit';
import ChangeBOLNumberModal from '../../../components/NewBookingPage/ChangeBOLNumberModal';
import AttachPRONumberModal from '../../../components/NewBookingPage/AttachPRONumberModal';
import ErrorsInPageModal from './ErrorsInPageModal';
import { getRates } from '../../../helpers/getRates';
import ConfirmTimedOutVendorsModal from '../../../components/LTLandVolumeTLPages/Modals/ConfirmTimedOutVendorsModal';
import NoVendorsSetUpModal from '../../../components/Modals/NoVendorsSetUpModal';
import InsuranceRequiredModal from './InsuranceRequiredModal';
import { CountryOptions } from '../../../data/CountryOptions';
import SmallPackageOptionsModal from '../../../components/NewBookingPage/SmallPackageOptionsModal';

interface freightErrors {
  qty: string,
  weight: string,
  nmfc: string,
  L: string,
  W: string,
  H: string
}

const GET_RATES = '/applet/v1/rate'
const GET_RATES_SUB_VENDOR = '/applet/v1/rate/p2p'
const HISTORYTRACKINGS = '/api/v1/track'
const DOCUMENTS = '/applet/v1/document'
const CREATE_SHIPMENT = '/applet/v1/book'
const DISPATCH_SHIPMENT = '/applet/v2/dispatch'
const CALCULATE_DVLDATE = '/api/v1/tools/calculatedeliverydate'
const SHIPMENT_MODES = '/api/v1/shipmentmodes'
const CHECK_CARRIERLABELS = '/api/v1/dispatch'

let cancelToken = axios.CancelToken
let source = cancelToken.source()

export const NewBooking = () => {
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const ports = useSelector<any>((store) => store.pages.drayagePorts) as portsModel
  const resetValues = useSelector<RootState>(({pages}) => pages?.resetValues) as boolean
  const initialNewBookingValues = useSelector<RootState>(({pages}) => pages.initialNewBooking) as any
  const navigate = useNavigate()
  const [selectedAcc, setSelectedAcc] = useState([])
  const [openQuoteBreakdownModal, setOpenQuoteBreakdownModal] = useState(false)
  const [touchedDate, setTouchedDate] = useState({
    pickup: false,
    delivery: false
  })
  const [openConfirmClearRateModal, setOpenConfirmClearRateModal] = useState(false)
  const quoteValues = useSelector<RootState>(({pages}) => pages.quoteValues) as quoteValuesModel
  const newBookingValues = useSelector<RootState>(({pages}) => pages.newBookingValues) as any
  const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
  const [rates, setRates] = useState([])
  const [linearProgress, setLinearProgress] = useState(0)
  const [ratesStopped, setRatesStopped] = useState(false)
  const [openRatesModal, setOpenRatesModal] = useState(false)
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [loadingTracking, setLoadingTracking] = useState(false)
  const [loadingCreateShipment, setLoadingCreateShipment] = useState(false)
  const [loadingDispatchShipment, setLoadingDispatchShipment] = useState(false)
  const [loadingUpdateShipment, setLoadingUpdateShipment] = useState(false)
  const [historyTrackings, setHistoryTrackings] = useState([])
  const [openTrackingModal, setOpenTrackingModal] = useState(false)
  const [documents, setDocuments] = useState([])
  const [openDocumentsModal, setOpenDocumentsModal] = useState(false)
  const [openShipmentCreatedModal, setOpenShipmentCreatedModal] = useState(false)
  const [openClearRateToEditModal, setOpenClearRateToEditModal] = useState(false)
  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false)
  const [openHazContactModal, setOpenHazContactModal] = useState(false)
  const [openPickupDatePastModal, setOpenPickupDatePastModal] = useState(false)
  const [openChangeBOLModal, setOpenChangeBOLModal] = useState(false)
  const [openAttachPROModal, setOpenAttachPROModal] = useState(false)
  const [openErrorsInPageModal, setOpenErrorsInPageModal] = useState(false)
  const [dispatchShip, setDispatchShip] = useState(false)
  const [createOrUpdate, setCreateOrUpdate] = useState('')
  const [mode, setMode] = useState({} as {shipmentCategory: string, name: string, id: number, ratingCode: string})
  const [modeLocal, setModeLocal] = useState('')
  const [modes, setModes] = useState([])
  const [actionClicked, setActionClicked] = useState(false)
  const [vendorName, setVendorName] = useState(null);
  const [selectedUn, setSelectedUn] = useState({})
  const [newValuesWhenRateCleared, setNewValuesWhenRateCleared] = useState({valuesFor: '', values: undefined})
  const [enabledVendors, setEnabledVendors] = useState([])
  const [vendorsProcessed, setVendorsProcessed] = useState('0')
  const [vendorsTimedOut, setVendorsTimedOut] = useState([])
  const [openConfirmTimedOutModal, setOpenConfirmTimedOutModal] = useState(false)
  const [openNoVendorsSetUpModal, setOpenNoVendorsSetUpModal] = useState(false)
  const [openInsuranceRequiredModal, setOpenInsuranceRequiredModal] = useState(false)
  const [openSmallPackageOptionsModal, setOpenSmallPackageOptionsModal] = useState(false)
  const [smallPackageOptions, setSmallPackageOptions] = useState({})
  
  const classes = useStyles({
    fontSize: fontSize,
  })

  const menuProps = {
    classes: {
      list: classes.selectPaperList
    }
  }

  const createShipment = () => {
    let params = {}

    if(!isNaN(formik?.values?.pickupDate?.date) && !isNaN(formik?.values?.deliveryDate?.date)){
      params = normalizeParamsShipment(formik.values, mode)
      let regex = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)

      if((regex.test(formik.values?.pickupDate?.from) || !formik.values?.pickupDate?.from) && (regex.test(formik.values.pickupDate.to) || !formik.values?.pickupDate?.to) && (regex.test(formik.values.deliveryDate.from) || !formik.values?.deliveryDate?.from) && (regex.test(formik.values.deliveryDate.to) || !formik.values?.deliveryDate?.to)){
        setLoadingCreateShipment(true)
        axios.post(CREATE_SHIPMENT, params).then(results => {
          setCreateOrUpdate('create')
          setLoadingCreateShipment(false)
          setOpenShipmentCreatedModal(true)
          formik.setFieldValue('BOLNumber', results.data.data?.results[0]?.BOLNmbr)
          formik.setFieldValue('BOLId', results.data.data?.results[0]?.BOLId)
          formik.setFieldValue('BOLPrefix', results.data.data?.results[0]?.BOLPrefix)
        }).catch(error => {
          setLoadingCreateShipment(false)
          checkErrorAPICall(error, dispatch, 'Create Shipment')
        })
      }
    }else{
      if(isNaN(formik?.values?.pickupDate?.date)){
        formik.setFieldError('pickupDate.date', 'must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).')
      }

      if(isNaN(formik?.values?.deliveryDate?.date)){
        formik.setFieldError('deliveryDate.date', 'must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).')
      }
    }
  }

  const updateShipment = () => {    
    let params = {}

    if(!isNaN(formik?.values?.pickupDate?.date) && !isNaN(formik?.values?.deliveryDate?.date)){
      params = normalizeParamsShipment(formik.values, mode)
      let regex = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)

      if((regex.test(formik.values?.pickupDate?.from) || !formik.values?.pickupDate?.from) && (regex.test(formik.values.pickupDate.to) || !formik.values?.pickupDate?.to) && (regex.test(formik.values.deliveryDate.from) || !formik.values?.deliveryDate?.from) && (regex.test(formik.values.deliveryDate.to) || !formik.values?.deliveryDate?.to)){
        setLoadingUpdateShipment(true)
        axios.put(`${CREATE_SHIPMENT}/${formik.values.BOLId}`, params).then(results => {
          setCreateOrUpdate('update')
          setLoadingUpdateShipment(false)
          setOpenShipmentCreatedModal(true)
        }).catch(error => {
          setLoadingUpdateShipment(false)
          checkErrorAPICall(error, dispatch, 'Update Shipment')
        })
      }
    }else{
      if(isNaN(formik?.values?.pickupDate?.date)){
        formik.setFieldError('pickupDate.date', 'must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).')
      }

      if(isNaN(formik?.values?.deliveryDate?.date)){
        formik.setFieldError('deliveryDate.date', 'must be a `date` type, but the final value was: `Invalid Date` (cast from the value `Invalid Date`).')
      }
    }
  }

  const dispatchAction = (params, setLoading) => {
    setLoadingDispatchShipment(true)
    axios.post(`${DISPATCH_SHIPMENT}/${formik.values.BOLId}`, {
      ...params
    }).then(results => {
      axios.get(`${DOCUMENTS}/${formik.values?.BOLId}`).then(resultsDoc => {
        dispatch({type: actionTypes.SetOpenDispatchedModal, payload: true})
        dispatch({type: actionTypes.SetDispachedErrorMessage, payload:  results});
        dispatch({
          type: actionTypes.SetDocuments,
          payload: {BOLNumber: formik.values.BOLNumber, BOLDocumentURL: resultsDoc?.data?.data?.results?.filter(d => d?.name === 'Bill Of Lading')[0]?.url || "", shipmentLabel: resultsDoc?.data?.data?.results?.filter(d => d?.name === 'Shipping Labels')[0]?.url || "", carrierLabel: resultsDoc?.data?.data?.results?.filter(d => d?.name === 'Carrier Labels')[0]?.url || ""}
        })
        if(user?.allowTrack) {
          navigate('/trackings')
        }
        else {
          navigate('/home')
        }
        setLoading(false)
        setOpenPickupDatePastModal(false)
      })
    }).catch(error => {
      setLoadingDispatchShipment(false)
      checkErrorAPICall(error, dispatch, 'Dispatch Shipment')
    })
  }

  const checkCarrierLabels = () => {
    axios.get(`${CHECK_CARRIERLABELS}/${formik?.values?.BOLId}/options`)
    .then(results => {
      let callResults = results.data.data.results

      if(callResults.dropToCarrier || callResults.pickupAlreadyScheduled){
        setLoadingDispatchShipment(false)
        setSmallPackageOptions({dropToCarrier: callResults.dropToCarrier, pickupAlreadyScheduled: callResults.pickupAlreadyScheduled})
        setOpenSmallPackageOptionsModal(true)
      }else{
        dispatchAction({makeEDI: user?.allowEDI}, setLoadingDispatchShipment)
      }
    }).catch(error => {
      setLoadingDispatchShipment(false)
    })
  }

  const dispatchShipment = () => {
    const params = normalizeParamsShipment(formik.values, mode)
    
    axios.put(`${CREATE_SHIPMENT}/${formik.values.BOLId}`, params).then(results => {
      if(formik.values.pickupDate?.date?.setHours(0,0,0,0) < new Date()?.setHours(0,0,0,0)){
        setLoadingDispatchShipment(false)
        setOpenPickupDatePastModal(true)
      }else{
        checkCarrierLabels()
      }
    }).catch(error => {
      setLoadingDispatchShipment(false)
      checkErrorAPICall(error, dispatch, 'Update Shipment')
    })
  }

  const getDocuments = () => {
    axios.get(`${DOCUMENTS}/${formik.values?.BOLId}`).then(results => {
      setDocuments(results.data.data.results)
      setLoadingDocuments(false)
      setOpenDocumentsModal(true)
    }).catch(error => {
      setLoadingDocuments(false)
      checkErrorAPICall(error, dispatch, 'Get Documents')
    })
  }

  const keepWaitingVendors = () => {
    let array = [...(rates || [])]
    let progress = linearProgress
    let callsFinishedLocal = 0
    const enabledVendorsFiltered = [...vendorsTimedOut] 
    let vendorsProcessedLocal = vendorsProcessed
    let vendorsTimedOutLocal = []

    setRatesStopped(false)
    source = cancelToken.source()

    enabledVendorsFiltered.forEach((vendor) => {
      axios.get(!vendor?.carrierCode ? GET_RATES : GET_RATES_SUB_VENDOR, {
        cancelToken: source.token,
        params: !vendor?.carrierCode ? normalizeParams(formik.values, vendor.id, mode, selectedUn) : {carrierCode: vendor?.carrierCode, ...normalizeParams(formik.values, vendor.id, mode, selectedUn)},
        timeout: 30000,
      }).then(response => {
        response.data.data.results?.filter(result => !result.error).forEach((newRate) => {
          if(newRate.SCAC && user.SCACFilteringEnabled){
            let duplicated = array.find(oldRate => oldRate?.SCAC === newRate?.SCAC && oldRate?.serviceLevel.toLowerCase() === newRate?.serviceLevel.toLowerCase() && oldRate.id !== newRate.id) || {} as any

            let vendorNew = enabledVendorsFiltered?.find(vendor => vendor?.id === newRate?.vendorId)
            let vendorDuplicated = duplicated.vendorId ? enabledVendorsFiltered.find(vendor => vendor?.id === duplicated?.vendorId) : {id: '', is3PL: false, isDirectAccount: false}
  
            let eliminateDuplicated = array.filter(rate => JSON.stringify(rate) !== JSON.stringify(duplicated))
            let eliminateNew = array.filter(rate => JSON.stringify(rate) !== JSON.stringify(newRate))
  
            if(vendorNew?.id && vendorDuplicated?.id){
              if(vendorNew.is3PL && (!vendorDuplicated.is3PL && duplicated.total >= newRate.total)){
                array = [...eliminateDuplicated, newRate]
              }else if(vendorNew.is3PL && vendorDuplicated.is3PL){
                if(newRate.total >= duplicated.total){
                  array.push(duplicated)
                  array = eliminateNew
                }else{
                  array = [...eliminateDuplicated, newRate]
                }
              }else if(vendorNew.isDirectAccount && vendorDuplicated.isDirectAccount){
                if(newRate.total >= duplicated.total){
                  array.push(duplicated)
                  array = eliminateNew
                }else{
                  array.push(newRate)
                  array = eliminateDuplicated
                }
              }else if(vendorNew.is3PL && vendorDuplicated.isDirectAccount){
                if(duplicated.total > (newRate.total + parseFloat(user.SCACFilteringVarianceUSD)) && duplicated.total > (newRate.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                  array.push(newRate)
                  array = eliminateDuplicated
                }else{
                  array.push(duplicated)
                  array = eliminateNew
                }
              }else if(vendorNew.isDirectAccount && vendorDuplicated.is3PL){
                if(newRate.total <= (duplicated.total + parseFloat(user.SCACFilteringVarianceUSD)) && newRate.total <= (duplicated.total * (1 + (parseFloat(user.SCACFilteringVariancePercentage) / 100)))){
                  array.push(newRate)
                  array = eliminateDuplicated
                }else{
                  array.push(duplicated)
                  array = eliminateNew
                }
              }
            }else{
              array.push(newRate)
            }
          }else{
            array.push(newRate)
          }
        })

        setRates([
          ...array
        ])
        
        progress = progress + (100 / enabledVendors.length)
        setLinearProgress(progress)
        vendorsProcessedLocal = (parseInt(vendorsProcessedLocal) + 1).toString()
        setVendorsProcessed(vendorsProcessedLocal)
      }).catch(error => {
        if(error.toString().includes('timeout')){
          vendorsTimedOutLocal = [...vendorsTimedOutLocal, vendor]
          setVendorsTimedOut(vendorsTimedOutLocal)
        }else if(error.message !== 'Operation canceled by the user.'){
          progress = progress + (100 / enabledVendors.length)
          setLinearProgress(progress)
          vendorsProcessedLocal = (parseInt(vendorsProcessedLocal) + 1).toString()
          setVendorsProcessed(vendorsProcessedLocal)
        }

        if(error?.response?.data?.error?.message.length){
          if(error?.response?.status === 401){
            setRatesStopped(true)
            isTokenExp()
          }
        }
      }).finally(() => {
        callsFinishedLocal = callsFinishedLocal + 1
        if(callsFinishedLocal === (enabledVendorsFiltered.length)){
          if(vendorsTimedOutLocal?.length){
            setOpenConfirmTimedOutModal(true)
          }
          setRatesStopped(true)
        }
      })
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: () => validationSchema({dispatchShipment: false, mode: mode, requireDimensions: user.requireDimensions}),
    validateOnChange: false,
    onSubmit: (values, formikBag) => {
      formikBag.setErrors({})
      setOpenErrorsInPageModal(false)

      if([...formik?.values?.accessorials?.origin, ...formik?.values?.accessorials?.destination, ...formik?.values?.accessorials?.other].filter(a => a.code === 'INS')?.length && (!formik.values.commodityInsurance || !formik.values.insuranceAmount)){
        setOpenInsuranceRequiredModal(true)
      }else{
	      if(values.pickupDate.date){
	        const enabledVendorsFiltered = user.enabledVendors.filter(vendor => vendor?.type === (mode?.ratingCode || 'LTL'))

	        if(enabledVendorsFiltered?.length){
	          setOpenRatesModal(true)
	          source = cancelToken.source()
	        
	          getRates(values, selectedAcc, enabledVendorsFiltered, enabledVendorsFiltered, [], setEnabledVendors, setVendorsProcessed, setVendorsTimedOut, setOpenConfirmTimedOutModal, user, () => {}, setRates, () => {}, setLinearProgress, setRatesStopped, () => {}, () => {}, () => {}, source, (vendorId) => normalizeParams(formik.values, vendorId, mode, selectedUn), '')
	        }else{
	          setOpenNoVendorsSetUpModal(true)
	        }
	      }else{
	        formik.setFieldError('pickupDate.date', "pickupDate.date must be a `date` type, but the final value was: `Invalid Date`.")
	      }
	   }   
    }
  })

  const formikDispatch = useFormik({
    initialValues: formik.values,
    validationSchema: () => validationSchema({dispatchShipment: true, mode: mode, requireDimensions: user.requireDimensions, ...formik.values}),
    validateOnChange: false,
    onSubmit: (values, formikBag) => {
      formikBag.setErrors({})
      setOpenErrorsInPageModal(false)

      setLoadingDispatchShipment(true)

      if([...formik?.values?.accessorials?.origin, ...formik?.values?.accessorials?.destination, ...formik?.values?.accessorials?.other].filter(a => a.code === 'INS')?.length && (!formik.values.commodityInsurance || !formik.values.insuranceAmount)){
        setOpenInsuranceRequiredModal(true)
      }else{
        dispatchShipment()
      }
    }
  })
  
  const trackingAction = () => {
    setLoadingTracking(true)
    axios.get(`${HISTORYTRACKINGS}/${formik.values?.BOLId}`).then(results => {
        setLoadingTracking(false)
        const resultsWithID = results.data.data.results.map(result => {
            return ({
                ...result,
                id: uuidv4()
            })
        })
        setHistoryTrackings(resultsWithID)
        setOpenTrackingModal(true)
    }).catch(error => {
        setLoadingTracking(false)
        checkErrorAPICall(error, dispatch, 'History Tracking')
    })
  }

  const getShipmentMode = (values) => {
    axios.get((SHIPMENT_MODES)).then((result) => {
      let shipmentMode
      let type
      if(values?.carrierInformation?.vendorId) {
        type = user.enabledVendors.find(vendor => vendor.id === values?.carrierInformation?.vendorId)?.type
        shipmentMode = result.data.data.results?.find(mode => mode.code === type)
      }
      else if(values.mode) {
        type = user.enabledVendors.find(vendor => vendor.shipmentMode.shipmentCategory === values?.mode)?.type
        shipmentMode = result.data.data.results?.find(mode => mode.code === type)
      }
      setModes(result.data.data.results)
      if(values.length !== 0){
        if(shipmentMode?.id === 0 || shipmentMode?.id){
          setMode(shipmentMode)
          setModeLocal(shipmentMode.name)
        }else{
          setMode(result.data.data.results?.find((mode) => mode.id === 0))
          setModeLocal(result.data.data.results?.find((mode) => mode.id === 0).name)
        }
      }else{
        setMode(result.data.data.results?.find((mode) => mode.id === 0))
        setModeLocal(result.data.data.results?.find((mode) => mode.id === 0).name)
      }
    })
  }

  useEffect(() => {
    formik.setFieldValue(`accessorials.origin`, selectedAcc?.filter(acc => acc.category === 'Origin'))
    formik.setFieldValue(`accessorials.destination`, selectedAcc?.filter(acc => acc.category === 'Destination'))
    formik.setFieldValue(`accessorials.other`, selectedAcc?.filter(acc => acc.category === 'Other'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAcc])

  useEffect(() => {
    if(quoteValues.length !== 0){
      formik.setFieldValue(`shipper`, {
        ...formik.values.shipper,
        country: quoteValues.origin?.isPort ? 'Port' : quoteValues?.origin?.Country || 'US',
        city: quoteValues?.origin?.City,
        state: quoteValues?.origin?.State,
        zipcode: quoteValues?.origin?.Zipcode,
        name: quoteValues?.origin?.Name || ''
      })
      formik.setFieldValue(`consignee`, {
        ...formik.values.consignee,
        country: quoteValues.destination?.isPort ? 'US' : quoteValues?.destination?.Country || 'US',
        city: quoteValues?.destination?.City,
        state: quoteValues?.destination?.State,
        zipcode: quoteValues?.destination?.Zipcode,
        name: quoteValues?.destination?.Name || ''
      })

      formik.setFieldValue(`commodityInsurance`, quoteValues?.commodityInsurance)
      formik.setFieldValue(`insuranceAddOn`, quoteValues?.insuranceAddOn)
      formik.setFieldValue(`insuranceAmount`, quoteValues?.insuranceAmount)
      formik.setFieldValue(`insuranceFreight`, quoteValues?.insuranceFreight)

      formik.setFieldValue(`freightInfo`, quoteValues?.freightInfo?.map((quote, index) => {
        if(quoteValues?.mode === 'Drayage'){
          return {
            ...quote,
            accessorials: quote?.accessorials?.length ? quote?.accessorials?.map((acc) => {
              if(acc === 'HAZ'){
                return 'Hazmat'
              }else if(acc === 'REEF'){
                return 'Reefer'
              }else if(acc === 'RESI'){
                return 'Residential Delivery'
              }else if(acc === 'HOGO'){
                return 'Household Goods'
              }else if(acc === 'OWT'){
                return 'Overweight'
              }else{
                return ''
              }
            }) : [],
            class: quoteValues?.freightInfoReclassed[index]?.class?.toString() || quote?.class
          }
        }else{
          return {
            ...quote,
            class: quoteValues?.freightInfoReclassed[index]?.class?.toString() || quote?.class
          }
        }
      }))
      formik.setFieldValue(`freightInfoUOM`, quoteValues?.freightInfoUOM)      
      formik.setFieldValue(`pickupDate.date`, quoteValues?.pickupDate || null)
      formik.setFieldValue(`pickupDate.from`, newBookingValues?.pickupDate?.from)
      formik.setFieldValue(`pickupDate.to`, newBookingValues?.pickupDate?.to)
      
      if(quoteValues?.accessorials?.origin?.length || quoteValues?.accessorials?.destination?.length || quoteValues?.accessorials?.other?.length){
        setSelectedAcc([...quoteValues?.accessorials?.origin, ...quoteValues?.accessorials?.destination, ...quoteValues?.accessorials?.other])
      }else if(quoteValues?.accessorials?.length){
        setSelectedAcc(quoteValues?.accessorials)
      }
      formik.setFieldValue(`carrierInformation`, quoteValues?.carrierInformation)
      formik.setFieldValue(`quoteNumber`, quoteValues?.quoteNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteValues])

  useEffect(() => {
    if(quoteValues.length === 0){      
      ['shipper', 'consignee'].forEach((location) => {        
        if(newBookingValues && newBookingValues[location] && (newBookingValues[location]['address2'] || newBookingValues[location]['address2'] === '')){
          newBookingValues[location]['address'] = newBookingValues[location]['address1'];
          newBookingValues[location]['address1'] = newBookingValues[location]['address2'];
          delete newBookingValues[location]['address2'];
        }

        formik.setFieldValue(`${location}.name`, newBookingValues[location]?.name || "")
        formik.setFieldValue(`${location}.address`, newBookingValues[location]?.address || "")
        formik.setFieldValue(`${location}.address1`, newBookingValues[location]?.address1 || "")
        formik.setFieldValue(`${location}.phone`, formatPhone(newBookingValues[location]?.phone, newBookingValues[location]?.country || "US") || "")
        formik.setFieldValue(`${location}.contact`, newBookingValues[location]?.contact || "")
        formik.setFieldValue(`${location}.contactPhone`, formatPhone(newBookingValues[location]?.contactPhone, newBookingValues[location]?.country || "US") || "")
        formik.setFieldValue(`${location}.emails`, newBookingValues[location]?.email ? newBookingValues[location]?.email?.includes(';') ? newBookingValues[location]?.email?.split(';') : newBookingValues[location]?.email?.split()[0] ? newBookingValues[location]?.email?.split(', ') : [] : newBookingValues[location]?.emails || "")
        formik.setFieldValue(`${location}.id`, newBookingValues[location]?.id || "")
        formik.setFieldValue(`${location}.city`, newBookingValues[location]?.city || "")
        formik.setFieldValue(`${location}.country`, newBookingValues[location]?.country || "US")
        formik.setFieldValue(`${location}.state`, newBookingValues[location]?.state || "")
        formik.setFieldValue(`${location}.zipcode`, newBookingValues[location]?.zipcode || newBookingValues[location]?.zipCode || "")
        formik.setFieldValue(`${location}.referenceNumber`, newBookingValues[location].referenceNumber)
      })

      formik.setFieldValue(`carrierInformation`, newBookingValues?.carrierInformation)

      formik.setFieldValue(`freightInfo`, newBookingValues?.freightInfo?.length
        ? newBookingValues?.freightInfo?.map((freight) => {          
          return {
            ...freight,
            stack: false,
            stackAmount: freight?.stackAmount > 1 ? freight?.stackAmount : null
          }
        })
        : [{
          id: uuidv4(),
          qty: '',
          dimType: {
          name: "PALLET",
          value: "PLT"
          },
          STC: '',
          weight: '',
          each: 'Each',
          L: '',
          W: '',
          H: '',
          class: '',
          commodity: '',
          nmfc: '',
          weightus: '',
          Lus: '',
          Wus: '',
          Hus: '',
          totalCft: '',
          hazmat: false,
          UN: ''
        }]
      )

      formik.setFieldValue(`freightInfoUOM`, newBookingValues?.freightInfoUOM || {
        value: 'US',
        oldValue: ''
      })
      formik.setFieldValue(`quoteNumber`, newBookingValues.quoteNumber)
      formik.setFieldValue(`dispatched`, newBookingValues.dispatched || false)
      formik.setFieldValue('BOLNumber', newBookingValues?.BOLNumber)
      formik.setFieldValue('PRONmbr', newBookingValues?.PRONmbr)
      formik.setFieldValue('BOLPrefix', newBookingValues?.BOLPrefix)
      formik.setFieldValue('BOLId', newBookingValues?.BOLId)
      formik.setFieldValue('billTo.referenceNumber', newBookingValues?.thirdPartyReferenceNumber)
      formik.setFieldValue('shipmentNotes', newBookingValues?.shipmentNotes)        
    
      if(!newBookingValues.BOLId) {
        formik.setFieldValue('billTo.emails', newBookingValues?.thirdParty?.email?.split(', '))
      }
  
      if(newBookingValues?.fromSearch){
        formik.setFieldValue(`specialInstructions`, newBookingValues?.specialInstructions)
        formik.setFieldValue(`otherInfo`, {
          ...formik?.values?.otherInfo,
          broker: {
            name: newBookingValues?.brokerInformation?.name,
            contact: newBookingValues?.brokerInformation?.contact,
            phone: newBookingValues?.brokerInformation?.phone,
            instructions: newBookingValues?.brokerInformation?.notes,
            ...formik?.values?.otherInfo?.broker,
          },
          BKGRefNumber: newBookingValues?.additionalInformation.BKGRef,
          BKGValue: newBookingValues?.brokerInformation.customValue,
          refNumber1: newBookingValues?.additionalInformation?.shipmentReference1,
          refNumber2: newBookingValues?.additionalInformation?.shipmentReference2,
          incoTerm: newBookingValues?.additionalInformation?.incoTerm
        }) 
      }

      let accessorial = []
      if(newBookingValues?.accessorials?.length){
        newBookingValues?.accessorials?.forEach((acc) => {
          if(user?.enabledAccessorials?.filter(enabledAcc => enabledAcc?.code === acc)[0]){
            accessorial.push(user?.enabledAccessorials?.filter(enabledAcc => enabledAcc?.code === acc)[0])
          }
        })
        setSelectedAcc(accessorial)
      }else{
        setSelectedAcc([...(newBookingValues?.accessorials?.origin ? newBookingValues?.accessorials?.origin : []), ...(newBookingValues?.accessorials?.destination ? newBookingValues?.accessorials?.destination : []), ...(newBookingValues?.accessorials?.other ? newBookingValues?.accessorials?.other : [])])
      }

      formik.setFieldValue(`pickupDate`, newBookingValues?.pickupDate)
      formik.setFieldValue(`deliveryDate`, newBookingValues?.deliveryDate)
      formik.setFieldValue(`commodityInsurance`, newBookingValues?.commodityInsurance)
      formik.setFieldValue(`insuranceAmount`, newBookingValues?.insuranceAmount)
      formik.setFieldValue(`insuranceAddOn`, newBookingValues?.insuranceAddOn || false)
      formik.setFieldValue(`insuranceFreight`, newBookingValues?.insuranceFreight || false)

      formik.setFieldValue(`emergencyContact`, newBookingValues?.emergencyContact)
      formik.setFieldValue(`emergencyPhone`, newBookingValues?.emergencyPhone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBookingValues, resetValues])

  useEffect(() => {
    if(!openRatesModal){
      source.cancel('Operation canceled by the user.')
    }
  }, [openRatesModal])

  useEffect(() => {
    if(formik.values.carrierInformation.name && formik.values.pickupDate?.date){
      axios.get(CALCULATE_DVLDATE, {
        params: {
          pickupDate: new Date(formik.values.pickupDate?.date) ? format(new Date(formik.values.pickupDate?.date) || null, 'yyyy-MM-dd') : '',
          transitDays: formik.values.carrierInformation?.transitDays
        }
      }).then((results) => {
        formik.setFieldValue('deliveryDate.date', new Date(results.data.data.results?.deliveryDate?.replace(/-/g, '/')))
        formik.setFieldValue('deliveryDateAllowed', new Date(results.data.data.results?.deliveryDate?.replace(/-/g, '/')))
      })
    }else{
      formik.setFieldValue('deliveryDateAllowed', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.carrierInformation.name, formik.values.pickupDate?.date])

  useEffect(() => {
    if(resetValues){
      const getCountryOptionsAllowed = () => {
        let defaultCountries = user?.defaultCountries
        let countriesArrayLocal = []
        defaultCountries?.forEach(country => {
            let allowedCountry = CountryOptions?.find(c => c.CodePrimus === country)
            countriesArrayLocal?.push(allowedCountry)
        })
        return countriesArrayLocal
      }

      let otherCountry = getCountryOptionsAllowed().find(c => c.CodePrimus === 'USA') ? 'USA' : getCountryOptionsAllowed()[0].CodePrimus
  
      formik.setFieldValue('otherInfo.BKGRefNumber', '')
      formik.setFieldValue('otherInfo.BKGValue', '')

      let initialNewBookingValuesLocal = {
        ...initialNewBookingValues,
        shipper: {
          ...initialNewBookingValues.shipper,
          country: initialNewBookingValues.shipper.country || otherCountry
        },
        consignee: {
          ...initialNewBookingValues.consignee,
          country: initialNewBookingValues.consignee.country || otherCountry
        }
      }
      dispatch({type: actionTypes.SetNewBookingValues, payload: initialNewBookingValuesLocal})
      dispatch({type: actionTypes.SetResetValues, payload: false})
      formik.setErrors({})
      formikDispatch.setErrors({})
      formik.setTouched({})
      formikDispatch.setTouched({})
      window.scrollTo(0, 0)
      setMode(modes.find(mode => mode.code === 'LTL'))
      setModeLocal(modes.find(mode => mode?.code === 'LTL')?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  useEffect(() => {
    if(newBookingValues?.mode) {
      getShipmentMode(newBookingValues)
    }
    else {
      getShipmentMode(quoteValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const vendorName = user.enabledVendors.find(vendor => vendor.id === (quoteValues?.carrierInformation?.vendorId || quoteValues?.carrierInformation?.id || newBookingValues?.vendorId))?.name
    if(vendorName && user?.allowVendorDisplay) {
      setVendorName(vendorName);
    }
  }, [])

  useEffect(() => {
    if(mode?.shipmentCategory === 'Drayage'){
      formik.setFieldValue('shipper.country', 'US')
      formik.setFieldValue('consignee.country', 'US')
      
      if(quoteValues.length === 0){
        if(newBookingValues.freightInfo.length){
          formik.setFieldValue('freightInfo', 
          newBookingValues.freightInfo.map((freight) => {
            return ({
              ...freight,
              container: 20,
              id: uuidv4(),
              UOM: {
                value: 'US',
                oldValue: ''
              },
              accessorials: [],
            })
          }))
        } else{
          formik.setFieldValue('freightInfo', [{
            id: uuidv4(),
            container: 20,
            qty: '',
            weightus: '',
            weight: '',
            UOM: {
              value: 'US',
              oldValue: ''
            },
            accessorials: [],
          }])
        }
      }

      formik.setFieldValue('shipper', formik.values.shipper)
      formik.setFieldValue('consignee', formik.values.consignee)

      if(!ports.length){
        axios.get('/api/v1/tools/ports').then(result => {
          let results = result.data.data.results
          const portsNormalized = results?.map(result => {
            return {
              country: result.country,
              name: result.name,
              postalCode: result.zipcode,
              stateCode: result.state,
              literal: `${result?.name}, ${result.state} ${result.zipcode}`,
              isPort: true,
              cityAlias: result.city,
              isZipcode: false,
              isSL: false
            }
          })
          dispatch({type: actionTypes.SetDrayagePorts, payload: portsNormalized})
        }).catch(error => {
          checkErrorAPICall(error, dispatch, 'Ports')
        })
      }
    }else{
      if(quoteValues.length === 0){        
        formik.setFieldValue(`freightInfo`, newBookingValues?.freightInfo.length
          ? newBookingValues?.freightInfo?.map((freight) => {
            return {
              ...freight,
              stack: false,
              stackAmount: freight?.stackAmount > 1 ? freight?.stackAmount : null
            }
          })
          : [{
            id: uuidv4(),
            qty: '',
            dimType: {
              name: "PALLET",
              value: "PLT"
            },
            STC: '',
            weight: '',
            each: 'Each',
            L: '',
            W: '',
            H: '',
            class: '',
            commodity: '',
            nmfc: '',
            weightus: '',
            Lus: '',
            Wus: '',
            Hus: '',
            totalCft: '',
            hazmat: false,
            UN: ''
          }]
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  let numberOfChips = window.innerWidth >= 1700 ? 4 : window.innerWidth <= 475 ? 1 : 3

  useEffect(() => {
    if(user.lockedBOL) {
      setOpenErrorsInPageModal(true)
    }
    
    if(newBookingValues.BOLId) {
      formik.setFieldValue('billTo.emails', newBookingValues?.thirdParty?.email ? newBookingValues?.thirdParty?.email?.includes(', ') ? newBookingValues?.thirdParty?.email?.split(', ') : newBookingValues?.thirdParty?.email?.split(',') : [])
    }
    else {
      formik.setFieldValue('billTo.emails', [user?.email]) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValues])

  useEffect(() => {
    if(Object.keys(formik.errors).length){
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openErrorsInPageModal])

  useEffect(() => {
    if(Object.keys(formikDispatch.errors).length){
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openErrorsInPageModal])

  useEffect(() => {    
    if(Object.keys(formikDispatch.errors).length && formikDispatch.dirty){
      setOpenErrorsInPageModal(true)
    }
    if(user.lockedBOL) {
      setOpenErrorsInPageModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikDispatch.errors])

  useEffect(() => {
    if(!formik?.values?.freightInfo[0]?.UN || !formik?.values?.freightInfo[0]?.hazmat || 
      formik?.values?.freightInfo[0]?.UN && formik?.values?.freightInfo[0]?.UN !== selectedUn?.UNNumber) {
      setSelectedUn({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.freightInfo]);

  return (
    <>
      <PageTitle>{formik.values?.BOLNumber ? 'Shipment Details' : 'New Booking'}</PageTitle>      
      <div className="row" style={{marginRight: window.innerWidth > 991 && (fontSize === 9 ? 200 : fontSize === 11 ? 100 : 0)}}>
        <div className={`col-12 col-md-12 d-flex ${formik.values?.BOLNumber ? '' : 'align-items-start'} mb-5 ps-3 pe-0`}>
          <div className="row w-100" style={{alignContent: formik.values?.BOLNumber ? 'space-between' : ''}}>
            <div className='col-12 col-md-6 d-flex pe-12 order-md-1 order-2'>
            {formik.values?.BOLNumber &&
              <div className="col-12">
                <div className={`row ${!formik.values?.PRONmbr ? 'mb-5 mb-md-0' : ''}`}>
                  <div className={`col-12 d-flex align-items-center`}>
                    <h2 className='mb-0'>BOL #{formik.values?.BOLNumber}</h2>
                    {
                      user?.allowBOLModify &&
                      <IconButton onClick={() => setOpenChangeBOLModal(true)} disabled={newBookingValues.dispatched}>
                        <EditIcon style={{width: 21, height: 21}}/>
                      </IconButton>
                    }
                  </div>
                  <div className="col-12 d-flex align-items-center">
                    <h5 className='mb-0' style={{fontWeight: 400}}>PRO #{formik.values?.PRONmbr}</h5>
                    {
                      user?.allowPROModify &&
                      <IconButton onClick={() => setOpenAttachPROModal(true)} disabled={newBookingValues.dispatched}>
                        <EditIcon style={{width: 15, height: 15}}/>
                      </IconButton>
                    }
                  </div>
                </div>
              </div>
            }
              </div>          
            <div className='col-12 col-md-6 d-flex mb-2 order-md-2 order-3 flex-wrap align-content-end'>
                <h5 className='mb-0 me-2' style={{fontSize: 14}}>Bill To</h5> <h5 className='mb-0' style={{fontSize: 14, fontWeight: 400}}>{user?.billToInformation.name}</h5>
            </div>
            
            <div className={`col-12 col-md-6 d-flex align-items-start order-md-3 order-1 align-content-start flex-wrap`}>
              <div className='col-3 col-md-2 mb-5'>
                <label className='col-form-label lh-1'>Mode</label>
              </div>
              <div className='col-9 col-md-5 p-0 ps-md-1'>
                <SelectStyled
                  value={modeLocal || ''}
                  disabled={newBookingValues.dispatched}
                  onChange={(e) => {
                    setModeLocal(e.target.value)
                    setMode(modes.find(m => m?.name === e.target.value))
                  }}
                  className={`w-100 ${classes.select}`}
                  displayEmpty={true}
                  inputProps={{
                    IconComponent: KeyboardArrowDownIcon,
                    MenuProps: menuProps,
                    style: stylesMUIInput(fontSize),
                  }}
                  style={{
                    color: '#5E6278',
                  }}
                  fontSize={fontSize}
                >
                  {
                    modes?.map((mode, index) => {
                      return <MenuItem key={index} value={mode.name}>{mode.name}</MenuItem>
                    })
                  }
                </SelectStyled>
              </div>
            </div>

            {/* <div className="col-3 d-flex mb-5">
              <h5 className='mb-0 me-2' style={{fontSize: 14}}>Bill To</h5> <h5 className='mb-0' style={{fontSize: 14, fontWeight: 400}}>{user?.billToInformation.name}</h5>
            </div> */}


            <div className='col-12 col-md-6 d-md-flex align-items-start order-md-4 order-4 flex-column' style={{backgroundColor: 'rgb(228,230,239)', borderRadius: '0.475rem', padding: '0.75rem'}}>
              
              <div className='col-12 col-md-12 col-xs-12 d-flex'>
                <div className="col-2 col-md-2 me-2">
                  <label className='col-form-label lh-1'>Reference#</label>
                </div>
                <div className="col-10 col-md-10 mb-2">
                  <InputStyled
                    type="text"
                    autoComplete='new-password'
                    {...formik.getFieldProps('billTo.referenceNumber')}
                    maxLength="120"
                    fontSize={fontSize}
                    className={`form-control ${newBookingValues.dispatched && 'disabled'}`}
                    disabled={newBookingValues.dispatched}
                  />
                </div>
              </div>
              <div className='col-12 col-md-12 col-xs-12 d-flex'>
                <div className="col-2 col-md-2 me-2">
                  <label className='col-form-label'>Email</label>
                </div>
                <div className="col-10 mb-5 mb-md-0">
                  <ReactMultiEmailStyled
                    disabled={newBookingValues.dispatched}
                    enable={(emailCnt) => !newBookingValues.dispatched}
                    propsEmails={formik.values.billTo.emails || []}
                    onChange={(_emails: string[]) => {
                        formik.setFieldValue('billTo.emails', _emails)
                    }}
                    getLabel={(email, index, removeEmail) => (
                      index === numberOfChips ? '...'  : index > numberOfChips ? null :
                      <ChipStyled
                        chipforemail="true"
                        disabled={newBookingValues.dispatched}
                        key={index}
                        tabIndex={-1}
                        onDelete={() => {
                            if(!newBookingValues.dispatched){
                                removeEmail(index)
                            }
                        }}
                        label={email}
                        className='me-1 mb-1'
                        style={{height: fontSize < 13 && 25}}
                      />
                    )}
                    onFocus={() => newBookingValues.dispatched && (document?.activeElement as any)?.blur()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-md-6 d-flex justify-content-start justify-content-md-end mb-5">
          <div className="row w-xl-75">
            <div className="col-12 d-flex mb-5">
              <h5 className='mb-0 me-2' style={{fontSize: 14}}>Bill To</h5> <h5 className='mb-0' style={{fontSize: 14, fontWeight: 400}}>{user?.billToInformation.name}</h5>
            </div>
            <div className="col-3 mb-5">
              <label className='col-form-label'>Reference#</label>
            </div>
            <div className="col-9 mb-5">
              <InputStyled
                type="text"
                autoComplete='new-password'
                {...formik.getFieldProps('billTo.referenceNumber')}
                maxLength="120"
                fontSize={fontSize}
                className={`form-control ${newBookingValues.dispatched && 'disabled'}`}
                disabled={newBookingValues.dispatched}
              />
            </div>
            <div className="col-3 mb-5 mb-md-0">
              <label className='col-form-label'>Email</label>
            </div>
            <div className="col-9 mb-5 mb-md-0">
              <ReactMultiEmailStyled
                disabled={newBookingValues.dispatched}
                enable={(emailCnt) => !newBookingValues.dispatched}
                propsEmails={formik.values.billTo.emails || []}
                onChange={(_emails: string[]) => {
                    formik.setFieldValue('billTo.emails', _emails)
                }}
                getLabel={(email, index, removeEmail) => (
                  index === numberOfChips ? '...'  : index > numberOfChips ? null :
                  <ChipStyled
                    chipforemail="true"
                    disabled={newBookingValues.dispatched}
                    key={index}
                    tabIndex={-1}
                    onDelete={() => {
                        if(!newBookingValues.dispatched){
                            removeEmail(index)
                        }
                    }}
                    label={email}
                    className='me-1 mb-1'
                    style={{height: fontSize < 13 && 25}}
                  />
                )}
                onFocus={() => newBookingValues.dispatched && (document?.activeElement as any)?.blur()}
              />
            </div>
          </div>
        </div> */}
        <div className="col-12 col-lg-6 mb-10 mb-lg-0">
          <label className='col-form-label py-0 me-1'>Shipper</label>
          <Tooltip title={!newBookingValues.dispatched && (formik.values.shipper.zipcode || formik.values.consignee.zipcode) ? "View in Google Maps" : ''}>
            <IconButton
              size="small"
              onClick={() => openMaps(newBookingValues, formik.values)}
              disabled={!newBookingValues.dispatched && (formik.values.shipper.zipcode || formik.values.consignee.zipcode) ? false : true}
            >
              <LocationOnIcon/>
            </IconButton>
          </Tooltip>
          <hr style={{color: '#a7adce'}} className='mb-4'/>
          <OriginAndDestination
            formFor='shipper'
            formik={formik}
            user={user}
            disabled={newBookingValues.dispatched}
            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
            newValuesWhenRateCleared={newValuesWhenRateCleared}
            setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
            formikDispatch={formikDispatch}
            dispatchShip={dispatchShip}
            mode={mode}
          />
        </div>
        <div className="col-12 col-lg-6">
          <label className='col-form-label py-0 me-1'>Consignee</label>
          <Tooltip title={!newBookingValues.dispatched && (formik.values.shipper.zipcode || formik.values.consignee.zipcode) ? "View in Google Maps" : ''}>
            <IconButton
              size="small"
              onClick={() => openMaps(newBookingValues, formik.values)}
              disabled={!newBookingValues.dispatched && (formik.values.shipper.zipcode || formik.values.consignee.zipcode) ? false : true}
            >
              <LocationOnIcon/>
            </IconButton>
          </Tooltip>
          <hr style={{color: '#a7adce'}} className='mb-4'/>
          <OriginAndDestination
            formFor='consignee'
            formik={formik}
            user={user}
            disabled={newBookingValues.dispatched}
            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
            newValuesWhenRateCleared={newValuesWhenRateCleared}
            setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
            formikDispatch={formikDispatch}
            dispatchShip={dispatchShip}
            mode={mode}
          />
        </div>
        <div className={`col-12 ${marginSize(fontSize, 'mt-10 mb-3', 'mt-4 mb-1')}`}>
          <label className='col-form-label'>Freight Info</label>
        </div>        
        <div className={`col-12 ${mode?.shipmentCategory === 'Drayage' ? '' : 'd-flex align-items-center'} mb-5 g-0`}>
          {mode?.shipmentCategory === 'Drayage' ? formik?.values?.freightInfo?.map((freight, index) => (
            <Fragment key={index}>
              <div className={`col-12 col-xl-11 ${marginSize(fontSize, 'mb-6', 'mb-1')}`} >              
              <Container
                index={index}
                freight={freight}
                handleChange={formik.handleChange}
                setData={formik.setFieldValue}
                freightInfo={formik.values.freightInfo}
                errors={formik.errors.freightInfo && (formik.errors.freightInfo[index])}
                touched={formik?.touched?.freightInfo && formik?.touched?.freightInfo[index]}
                setFieldTouched={formik.setFieldTouched}
                forNewBooking={true}
                setSelectedUn={setSelectedUn}
                selectedUn={selectedUn}
              />
              </div>
            </Fragment>
          ))
          : null}
          {
            mode?.shipmentCategory !== 'Drayage' ? 
            <div className="row g-0">                           
              {formik?.values?.freightInfo?.map((freight, index) => (
                <Fragment key={index}>
                  <FreightInformation
                    setDataFormik={formik.setFieldValue}
                    freightInfo={formik.values.freightInfo}
                    index={index}
                    freight={freight}
                    freightInfoUOM={formik.values.freightInfoUOM}
                    handleChange={formik.handleChange}
                    formik={formik}
                    errors={(formikDispatch.errors.freightInfo && (formikDispatch.errors.freightInfo[index] as freightErrors)) || (formik.errors.freightInfo && (formik.errors.freightInfo[index] as freightErrors))}
                    touched={(formikDispatch?.touched?.freightInfo && formikDispatch?.touched?.freightInfo[index]) || (formik?.touched?.freightInfo && formik?.touched?.freightInfo[index])}
                    disabled={newBookingValues.dispatched}
                    freightForNewBooking={true}
                    formikValues={formik.values}
                    setOpenClearRateToEditModal={setOpenClearRateToEditModal}
                    formikDispatch={formikDispatch}
                    dispatchShip={dispatchShip}
                    mode={mode}
                    setOpenHazContactModal={setOpenHazContactModal}
                    setSelectedUn={setSelectedUn}
                    selectedUn={selectedUn}
                  />
                </Fragment>
              ))}
            </div>
            : null
          }
        </div>
        <div className="row g-0">          
          <FreightTotals
            freightInfoTotals={formik?.values?.freightInfoTotals}
            freightInfo={formik?.values?.freightInfo}
            freightInfoUOM={formik?.values?.freightInfoUOM}
            setDataFormik={formik?.setFieldValue}
            forDrayage={mode?.shipmentCategory === 'Drayage'}
          />
          <SelectUOM
            freightInfoUOM={formik.values.freightInfoUOM}
            setDataFormik={formik.setFieldValue}
            freightInfo={formik.values.freightInfo}
            freightInfoTotals={formik.values.freightInfoTotals}
            disabled={newBookingValues.dispatched}
            hazContact={true}
            setOpenHazContactModal={setOpenHazContactModal}
          />
        </div>
        <div className={`col-12 ${marginSize(fontSize, 'mt-7 mb-3', 'mt-4 mb-1')}`}>
          <label className='col-form-label'>Accessorials</label>
        </div>
        <div className="col-xl-8 col-sm-12" style={{position: 'relative'}}>
          <Accessorials
            selectedAcc={selectedAcc}
            setSelectedAcc={setSelectedAcc}
            accessorialsList={user?.enabledAccessorials}
            formikValues={formik.values}
            setOpenClearRateToEditModal={setOpenClearRateToEditModal}
            setFormikValues={formik.setFieldValue}
            forNewBooking={true}
            actionClicked={actionClicked}
          />
        </div>
        <div className='ps-3 pe-3'>
          <hr style={{color: '#a7adce'}} className='mt-6 mb-6'/>
        </div>
        <div className='col-12 d-flex align-items-center justify-content-end'>
          <button
            className={`btn btn-secondary me-4 ${newBookingValues.dispatched ? 'disabled' : formik.values?.carrierInformation?.name ? '' : 'disabled'}`}
            onClick={() => {
              setActionClicked(!actionClicked)
              setOpenConfirmClearRateModal(true)
            }}
          >
            Clear Rate
          </button>
          <button
            className={`btn btn-primary ${newBookingValues.dispatched ? 'disabled' : ''}`}
            onClick={() => {
              setActionClicked(!actionClicked)
              setDispatchShip(false)
              setTouchedDate({
                ...touchedDate,
                pickup: true
              })
              formik.handleSubmit()

              if(Object.keys(formik.errors).length){
                setOpenErrorsInPageModal(true)
              }
            }}
            type="button"
          >
            Get Rates
          </button>
        </div>
        {formik?.values?.carrierInformation?.name &&
          <div className='mt-3'>
            <CarrierInformation
              vendorName={vendorName}
              values={formik?.values}
              setOpenQuoteBreakdownModal={setOpenQuoteBreakdownModal}
            />
          </div>
        }
        <div className={`col-12 col-lg-6 ${marginSize(fontSize, 'mt-12', 'mt-8')}`}>
          <label className='col-form-label'>Pickup and Delivery</label>
          <PickupAndDelivery
            date='Pickup'
            setDataFormik={formik.setFieldValue}
            handleChange={formik.handleChange}
            error={formik.errors?.pickupDate}
            values={formik.values.pickupDate}
            formik={formik}
            touched={touchedDate}
            setTouched={setTouchedDate}
            formikDispatch={formikDispatch}
            dispatchShip={dispatchShip}
          />
          <PickupAndDelivery
            date='Delivery'
            setDataFormik={formik.setFieldValue}
            handleChange={formik.handleChange}
            error={formik.errors?.deliveryDate}
            values={formik.values.deliveryDate}
            formik={formik}
            touched={touchedDate}
            setTouched={setTouchedDate}
            formikDispatch={formikDispatch}
            dispatchShip={dispatchShip}
          />
        </div>
        <div className={`col-12 col-lg-6 ${marginSize(fontSize, 'mt-12', 'mt-8')}`}>
          <Tabs
            formik={formik}
            mode={mode}
          />
        </div>
        <div className="row pe-0 mt-6 align-items-center justify-content-end">
          <div className="d-flex align-items-center justify-content-end pe-0" style={{flexWrap: 'wrap'}}>
            {user?.allowBooking && !formik?.values?.dispatched && !formik.values?.BOLId &&
              <button
                className={`btn btn-primary ${formik.values?.BOLId && 'me-4'} d-flex align-items-center justify-content-center`}
                onClick={() => {
                  setActionClicked(!actionClicked)
                  setDispatchShip(false)
                  
                  if([...formik?.values?.accessorials?.origin, ...formik?.values?.accessorials?.destination, ...formik?.values?.accessorials?.other].filter(a => a.code === 'INS')?.length && (!formik.values.commodityInsurance || !formik.values.insuranceAmount)){
                    setOpenInsuranceRequiredModal(true)
                  }else{
                    createShipment()
                  }
                }}
                disabled={user?.lockedBOL}
              >
                  Create Shipment {loadingCreateShipment && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
              </button>
            }
            {user?.allowEDI && !formik?.values?.dispatched && formik.values?.BOLId &&
              <button
                className={`btn btn-primary ${formik.values?.BOLId && 'me-0 me-sm-4'} d-flex align-items-center justify-content-center mb-2 mb-sm-0 ${window.innerWidth <= 576 && 'w-100'}`}
                disabled={user?.lockedBOL}
                onClick={() => {
                  setActionClicked(!actionClicked)
                  setDispatchShip(true)
                  formikDispatch.setValues(formik.values)
                  
                  setTimeout(() => {
                    formikDispatch.handleSubmit()
                  }, 1);
                }}
              >
                Ready to Dispatch {loadingDispatchShipment && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
              </button>
            }
            {user?.allowBooking && !formik?.values?.dispatched && formik.values?.BOLId &&
              <button
                disabled={user?.lockedBOL}
                className={`btn btn-primary ${formik.values?.BOLId && 'me-0 me-sm-4'} d-flex align-items-center justify-content-center mb-2 mb-sm-0 ${window.innerWidth <= 576 && 'w-100'}`}
                onClick={() => {
                  setActionClicked(!actionClicked)
                  setDispatchShip(false)
                  updateShipment()
                }}
              >
                  Update Shipment {loadingUpdateShipment && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
              </button>
            }
            <br/>
            {
              formik.values?.BOLId &&
              <>
                <button
                  className="btn btn-primary me-4 d-flex align-items-center"
                  onClick={() => {
                    setActionClicked(!actionClicked)
                    getDocuments()
                  }}
                >
                  Documents {loadingDocuments && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
                </button>
              </>
            }
            {
              (user?.allowTrack && formik.values?.BOLId) ?
              <button
                  className="btn btn-primary d-flex align-items-center me-0 me-sm-4"
                  onClick={() => {
                    setActionClicked(!actionClicked)
                    trackingAction()
                  }}
              >
                  Tracking {loadingTracking && <CircularProgress style={{height: 16, width: 16, color: 'white'}} className='ms-2'/>}
              </button>
              : null
            }
            {
              formik.values?.BOLNumber ?
              <button
                className="btn btn-secondary d-flex align-items-center mt-2 mt-sm-0 ms-4 ms-sm-0"
                onClick={() => {
                  setActionClicked(!actionClicked)
                  setOpenSaveChangesModal(true)
                }}
              >
                Close
              </button>
              : null
            }
          </div>
        </div>
      </div>
      <QuoteBreakdownModal
        open={openQuoteBreakdownModal}
        setOpen={setOpenQuoteBreakdownModal}
        data={formik?.values?.carrierInformation}
      />
      <ConfirmClearRateModal
        open={openConfirmClearRateModal}
        setOpen={setOpenConfirmClearRateModal}
        setFormikValues={formik.setFieldValue}
      />
      <RatesModal
        open={openRatesModal}
        setOpen={setOpenRatesModal}
        linearProgress={linearProgress}
        rates={rates}
        setFormikValues={formik.setFieldValue}
        ratesStopped={ratesStopped}
        setRatesStopped={setRatesStopped}
        source={source}
        setVendorName={setVendorName}
        vendorsFiltered={enabledVendors}
        vendorsProcessed={vendorsProcessed}
      />
      <TrackingModal
        open={openTrackingModal}
        setOpen={setOpenTrackingModal}
        historyTrackings={historyTrackings}
        setHistoryTrackings={setHistoryTrackings}
        rowData={formik.values}
      />
      <DocumentsModal
        open={openDocumentsModal}
        setOpen={setOpenDocumentsModal}
        documents={documents}
        selectedRow={{BOLNumber: formik.values.BOLNumber, BOLId: formik.values.BOLId}}
        setDocuments={setDocuments}
      />
      <ShipmentCreatedOrUpdatedModal
        open={openShipmentCreatedModal}
        setOpen={setOpenShipmentCreatedModal}
        values={formik.values}
        setFormikValues={formik.setFieldValue}
        createOrUpdate={createOrUpdate}
      />
      <ClearRateToEditModal
        open={openClearRateToEditModal}
        setOpen={setOpenClearRateToEditModal}
        setFormikValues={formik.setFieldValue}
        newValuesWhenRateCleared={newValuesWhenRateCleared}
        setNewValuesWhenRateCleared={setNewValuesWhenRateCleared}
      />
      <SaveChangesModal
        open={openSaveChangesModal}
        setOpen={setOpenSaveChangesModal}
        updateShipment={updateShipment}
      />
      <HazContactModal
        open={openHazContactModal}
        setOpen={setOpenHazContactModal}
        setFormikValues={formik.setFieldValue}
        formikValues={formik.values}
      />
      <PickupDatePastModal
        open={openPickupDatePastModal}
        setOpen={setOpenPickupDatePastModal}
        dispatchAction={dispatchAction}
      />
      <ChangeBOLNumberModal
        open={openChangeBOLModal}
        setOpen={setOpenChangeBOLModal}
        BOLId={formik.values.BOLId}
        BOLNumberProps={formik.values.BOLNumber}
        setFormikValues={formik.setFieldValue}
      />
      <AttachPRONumberModal
        open={openAttachPROModal}
        setOpen={setOpenAttachPROModal}
        BOLId={formik.values.BOLId}
        setFormikValues={formik.setFieldValue}
        formikValues={formik.values}
        PRONumberProps={formik.values.PRONmbr}
      />
      <ErrorsInPageModal
        open={openErrorsInPageModal}
        setOpen={setOpenErrorsInPageModal}
        lockedBOL={user.lockedBOL}
      />
       <ConfirmTimedOutVendorsModal
        open={openConfirmTimedOutModal}
        setOpen={setOpenConfirmTimedOutModal}
        vendorsTimedOut={vendorsTimedOut}
        keepWaitingVendors={() => {
          source = cancelToken.source()
          keepWaitingVendors()
        }}
        formikValues={formik.values}
      />
      <NoVendorsSetUpModal
        open={openNoVendorsSetUpModal}
        setOpen={setOpenNoVendorsSetUpModal}
      />
      <InsuranceRequiredModal
        open={openInsuranceRequiredModal}
        setOpen={setOpenInsuranceRequiredModal}
      />
      <SmallPackageOptionsModal
        open={openSmallPackageOptionsModal}
        setOpen={setOpenSmallPackageOptionsModal}
        smallPackageOptions={smallPackageOptions}
        dispatchAction={(params) => dispatchAction(params, setLoadingDispatchShipment)}
      />
    </>
  )
}