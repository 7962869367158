import { Backdrop, Fade } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { serviceArea } from '../../../../data/ServiceArea'
import { EquipmentServiceModalProps } from '../../../../pages/vendors/models'
import { DivPaperStyled, ModalStyled, Scroll } from '../../../Modals.styled'
import { modalStyles } from '../../../ModalStyles'
import ServiceArea from './ServiceArea'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'

const ServiceAreaModal = ({open, setOpen, formik}: EquipmentServiceModalProps) => {
    const classes = modalStyles({})
    const [statesSelected, setStatesSelected] = useState([])
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if(open){
            setStatesSelected(formik.values.mainInformation.serviceArea)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            fontSize={fontSize}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <DivPaperStyled className={classes.paperEditModalSL}>
                    <div className="row modal-header p-0 pb-2">
                        <div className="col-8">
                            <h5 className="modal-title">Service Area</h5>
                        </div>
                        <div className="col-1 d-flex btn btn-icon btn-sm btn-active-light-primary me-3" onClick={handleClose}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <>
                        <Scroll>
                            <div className="row mb-7 mt-5">
                                <div className="col-12 d-none d-md-flex justify-content-center">
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected(serviceArea.map(e => e.state))}
                                    >
                                        All States
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([])}
                                    >
                                        None
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'west').map(e => e.state)])}
                                    >
                                        West
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'midwest').map(e => e.state)])}
                                    >
                                        Midwest
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'southwest').map(e => e.state)])}
                                    >
                                        Southwest
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'northeast').map(e => e.state)])}
                                    >
                                        Northeast
                                    </button>
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'southeast').map(e => e.state)])}
                                    >
                                        Southeast
                                    </button>
                                </div>

                                <div className="col-12 d-flex d-md-none justify-content-center mb-2">
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected(serviceArea.map(e => e.state))}
                                    >
                                        All States
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([])}
                                    >
                                        None
                                    </button>
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'west').map(e => e.state)])}
                                    >
                                        West
                                    </button>
                                </div>
                                <div className="col-12 d-flex d-md-none justify-content-center mb-2">
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'midwest').map(e => e.state)])}
                                    >
                                        Midwest
                                    </button>
                                    <button
                                        className='btn btn-primary me-2'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'southwest').map(e => e.state)])}
                                    >
                                        Southwest
                                    </button>
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'northeast').map(e => e.state)])}
                                    >
                                        Northeast
                                    </button>
                                </div>
                                <div className="col-12 d-flex d-md-none justify-content-center">
                                    <button
                                        className='btn btn-primary'
                                        onClick={() => setStatesSelected([...statesSelected, ...serviceArea.filter(e => e.ubication === 'southeast').map(e => e.state)])}
                                    >
                                        Southeast
                                    </button>
                                </div>
                            </div>
                            <ServiceArea
                                statesSelected={statesSelected}
                                setStatesSelected={setStatesSelected}
                            />
                        </Scroll>

                        <div className="row mb-3 mt-7">
                            <div className="col-12 d-flex justify-content-end">
                                <button
                                    className='btn btn-primary d-flex align-items-center me-4'
                                    type='submit'
                                    onClick={() => {
                                        formik.setFieldValue('mainInformation.serviceArea', statesSelected)
                                        handleClose()
                                    }}
                                >
                                    Save
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
    )
}

export default ServiceAreaModal