import React, { useState } from 'react'
import { DivPaperStyled, ModalStyled, Title } from '../Modals.styled'
import { KTSVG } from '../../../_metronic/helpers'
import { Backdrop, Fade } from '@mui/material'
import { modalStyles } from '../ModalStyles'
import { RootState } from '../../../setup'
import { useSelector } from 'react-redux'
import ChangeBOLNumberModal from './ChangeBOLNumberModal'
import AttachPRONumberModal from './AttachPRONumberModal'

const ShipmentCreatedOrUpdatedModal = ({open, setOpen, values, setFormikValues, createOrUpdate}) => {
    const classes = modalStyles({});
    const [openChangeBOLModal, setOpenChangeBOLModal] = useState(false)
    const [openAttachPROModal, setOpenAttachPROModal] = useState(false)
    const fontSize = useSelector<RootState>(({pages}) => pages.fontSize) as number
  
    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                fontSize={fontSize}
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            <Fade in={open}>
                <DivPaperStyled className={classes.paper}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <KTSVG path='/media/icons/bootstrap/info-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                                </div>
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <Title>{createOrUpdate === 'update' ? 'The booking has been updated' : <>The booking was saved and is assigned <br/> BOL #{values.BOLNumber}</>} </Title>
                                </div>
                            </div>
                            <div className="row mt-1 mb-3">
                                <div className='col-12 d-flex justify-content-center'>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            setOpen(false)
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                </DivPaperStyled>
            </Fade>
        </ModalStyled>
        <ChangeBOLNumberModal
            open={openChangeBOLModal}
            setOpen={setOpenChangeBOLModal}
            BOLId={values.BOLId}
            BOLNumberProps={values.BOLNumber}
            setFormikValues={setFormikValues}
        />
        <AttachPRONumberModal
            open={openAttachPROModal}
            setOpen={setOpenAttachPROModal}
            BOLId={values.BOLId}
            formikValues={values}
            setFormikValues={setFormikValues}
        />
      </>
    )
}

export default ShipmentCreatedOrUpdatedModal