import { Backdrop, Fade, Modal } from '@mui/material';
import { deleteSpacesInKeys } from '../../../helpers/deleteSpacesInKeys';
import { normalizeCell } from '../../../helpers/normalizeCell';
import { BigSubtitle, FooterStyled, PaperModal, Title } from '../Modals.styled';
import { modalStyles } from '../ModalStyles';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { AppendDataModalProps } from '../../../pages/book/models';
import { searchCityAndStateMissing } from '../../../helpers/searchCityAndStateMissing';
import { validationsBooking } from '../../../helpers/validationsBooking';
import { autoCalculateClass } from '../../../helpers/autoCalculateClass';

const AppendDataModal = ({open, setOpen, setOpenConfirmModal, newData, setData, data, setErrors, setOpenErrorsInDataModal, setUploadedRows, totalRows, setTotalRows, setFileName, fileNameToUse, vendorsToUse, setOpenWarningCallsModal, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setNumberOfZipcodesMissing, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes}: AppendDataModalProps) => {
    const classes = modalStyles();
    const [fontSize, setFontSize] = useState(parseInt(localStorage.getItem('fontSize')) || 13)

    window.addEventListener('storage', () => {
        setFontSize(parseInt(localStorage.getItem('fontSize')))
    })

    const editData = (addOrReplace) => {
        setErrors([])
        let errorsArray = []
        let dataValid = []

        newData.forEach((cell, index) => {
            deleteSpacesInKeys(cell)
            autoCalculateClass(cell.Pieces, cell.Weight, cell.Length, cell.Width, cell.Height, cell)
            if(validationsBooking(cell, index, errorsArray)){
              setErrors(errorsArray)
              setOpenErrorsInDataModal(true)
            }else{
              normalizeCell(cell)
              if(isNaN(cell.Class)){
                dataValid.push({...cell, Class: ""})
              }else{
                dataValid.push(cell)
              }
            }
        });

        if(errorsArray.length === 0 && dataValid.length){
            if(addOrReplace === 'add'){
                searchCityAndStateMissing(dataValid, data, setData, addOrReplace, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setOpenWarningCallsModal, setOpenConfirmModal, setNumberOfZipcodesMissing, vendorsToUse, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes)
            }else{
                searchCityAndStateMissing(dataValid, data, setData, addOrReplace, rowsWithInvalidZipcode, setRowsWithInvalidZipcode, setSearchingMissingData, setOpenWarningCallsModal, setOpenConfirmModal, setNumberOfZipcodesMissing, vendorsToUse, setOpenInvalidZipcodesModal, invalidZipcodes, setInvalidZipcodes)
            }
        }
        
        if(addOrReplace === 'add'){
            setFileName(fileNameToUse)
            setTotalRows(totalRows + newData.length)
            setUploadedRows(data.length + dataValid.length)
            setData([...data, ...dataValid.map((singleData) => {
                return {
                    ...singleData,
                    id: uuidv4()
                }
            })]);
        }else{
            setFileName(fileNameToUse)
            setTotalRows(newData.length)
            setUploadedRows(dataValid.length)
            setRowsWithInvalidZipcode([])
            setData(dataValid.map((singleData) => {
                return {
                    ...singleData,
                    id: uuidv4()
                }
            }));
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <PaperModal fontSize={fontSize} widthWhenFontSmall={500} widthWhenFontBig={700} className={classes.paperBigger}>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="col-12 d-flex btn btn-icon btn-sm btn-active-light-primary" onClick={() => setOpen(false)}>
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                    </div>
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12 d-flex justify-content-center">
                                <KTSVG path='/media/icons/bootstrap/question-circle.svg' className='svg-icon svg-icon-3x text-primary'/>
                            </div>
                            <div className="col-12 mt-5 d-flex justify-content-center">
                                <Title fontSize={fontSize}>You are about to load new shipment information</Title>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <BigSubtitle fontSize={fontSize}>Please select one of the below options:</BigSubtitle>
                            </div>
                        </div>
                        <FooterStyled fontSize={fontSize} className="row mt-5 mb-5">
                            <div className="col-12 d-flex justify-content-center">
                                <button
                                    className="btn btn-primary me-4"
                                    onClick={() => {
                                        editData('add')
                                        setOpen(false)
                                    }}
                                >
                                    Add to current list
                                </button>
                                <button
                                    className="btn btn-danger me-4"
                                    onClick={() => {
                                        editData('replace')
                                        setOpen(false)
                                    }}
                                >
                                    Replace current list
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setOpen(false)
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </FooterStyled>
                    </>
                </PaperModal>
            </Fade>
        </Modal>
    )
}

export default AppendDataModal